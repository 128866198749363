<form [formGroup]="actionplanForm">


    <mat-card class="mb-3">
        <mat-card-content> 
            To retire an asset may result in some additional actions needing to be taken. You will need to
            complete the Retiring an Asset Action Plan before the request will be moved forward to the next
            step.
            <br><br>
            Please complete all required actions below, when finished submit for Analyst Review. The form may be
            completed iteratively as you work through the various actions. Entering the planned completion date
            will help the AMD system keep track of your request and provides prompts as the completion date
            approaches.
            <br><br>
            All fields need to be updated prior to selecting “Submit for Approval”. This includes identifying
            costs associated with retiring an asset, setting drop downs to reflect the work needed to complete
            the retirement, and verify emails.
            <br><br>
            When all necessary fields are completed, buttons will become available to move from Draft to Submit
            for Approval and to move from Submit for Approval to Tool Moved/Ready for Analyst Review.
            <br><br>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-toolbar color="primary" class="mb-3">
            <span>ASSET DETAILS</span>
        </mat-toolbar>

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Date Initiated:</mat-label>
                        <input matInput type="text" formControlName="INSERT_DATETIME" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Status:</mat-label>
                        <input matInput type="text" formControlName="Change_Status" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Change Type:</mat-label>
                        <input matInput type="text" formControlName="Change_Type" placeholder=""readonly >
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Asset Number:</mat-label>
                        <input matInput type="text" formControlName="Main_Asset_Number" placeholder=""readonly >
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Asset Description:</mat-label>
                        <input matInput type="text" formControlName="Old_Additional_Description" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>New Asset Description:</mat-label>
                        <input matInput type="text" formControlName="New_Asset_Description" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Asset Serial Number:</mat-label>
                        <input matInput type="text" formControlName="Old_Serial_Number" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>New Asset Serial:</mat-label>
                        <input matInput type="text" formControlName="New_Serial_Number" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Building:</mat-label>
                        <input matInput type="text" formControlName="Old_Building" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>New Building:</mat-label>
                        <input matInput type="text" formControlName="New_Building" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>LabFab Name:</mat-label>
                        <input matInput type="text" formControlName="Old_LabFab_Name" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>New Labfab Name:</mat-label>
                        <input matInput type="text" formControlName="New_LabFab" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Original Tool Owner:</mat-label>
                        <input matInput type="text" formControlName="Old_Owner" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>New Tool Owner:</mat-label>
                        <input matInput type="text" formControlName="New_Owner" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Cost Object Manager:</mat-label>
                        <input matInput type="text" formControlName="Old_Cost_Center_Manager" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>New Cost Object Manager:</mat-label>
                        <input matInput type="text" formControlName="New_Cost_Manager" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Cost Center Code:</mat-label>
                        <input matInput type="text" formControlName="Old_Cost_Center_Code" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>New Cost Center Code:</mat-label>
                        <input matInput type="text" formControlName="New_Cost_Center_Code" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Acquisition Value:</mat-label>
                        <input matInput type="text" formControlName="Acquisition_Value" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Net Book Value:</mat-label>
                        <input matInput type="text" formControlName="PE_Net_Book_Value" placeholder="" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Monthly Depreciation Value:</mat-label>
                        <input matInput type="text" formControlName="Depreciation_Terms_Useful_Life_in_Months"
                            placeholder="" readonly>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-card>


    <mat-card>
        <mat-toolbar color="primary"
            class="d-flex flex-column h-auto mat-primary align-items-start py-3 mb-3 text-left text-wrap w-100">
            <span>ACTION PLAN</span>
        </mat-toolbar>
        <mat-card-content>
            <small class="pt-3">Note:
                All items must have entries prior to submitting for approval<br>
                All email ids must be verified before Submitting.<br>
                Additional individuals emails can be added with separator semicolon ';'<br>
                ex: example@hp.com;example2@hp.com;<br>
                First Last Name
            </small>
        </mat-card-content>
        <mat-toolbar color="warning" class="mb-3">
            <span>Identify Cost Associated with Tool Move</span>
        </mat-toolbar>

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <mat-label>Planned Completion Date:</mat-label>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <input matInput [matDatepicker]="picker" (click)="picker.open()"
                            formControlName="planned_Completion_Date_Action_Plan">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-hint class="text-danger"
                            *ngIf="Submitted && actionplanForm.controls['planned_Completion_Date_Action_Plan'].errors">
                            <span
                                *ngIf=" Submitted && actionplanForm.controls['planned_Completion_Date_Action_Plan'].errors['required']">
                                Planned Completion Date is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-label>Are there other assets that will be part of this move:</mat-label>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select placeholder="select Yes or No"
                            formControlName="action_plan_other_Asset_Part_Of_Move"
                            (onSelectionChange)="otherAssetPartOfMove()" #action_plan_other_Asset_Part_Of_Move>
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                        <mat-hint class="text-danger"
                            *ngIf="Submitted && actionplanForm.controls['action_plan_other_Asset_Part_Of_Move'].errors"
                            class="invalid-feedback">
                            <span
                                *ngIf="Submitted && actionplanForm.controls['action_plan_other_Asset_Part_Of_Move'].errors['required']">
                                Select Yes or No
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <mat-label>Provide a list of additional asset #s that will be moved with this asset:</mat-label>
                    <br />
                    <mat-label>
                        You will need to complete a {{(actionplanForm.controls['Change_Type'].value!='Basic
                        Change')?'transfer':'basic change'}} request for each asset that is moving, however, you
                        may choose to calculate costs for all assets moving or each individual asset.
                    </mat-label>
                    <br />
                    <mat-label>
                        Obtaining Cost Center Owner approval for any movement of an asset (transfer, or retirement) is
                        required. The next question will help identify any costs and will initiate the necessary
                        approvals
                    </mat-label>

                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <input matInput formControlName="action_plan_List_Addition_Assets_Moved_with_Asset"
                            placeholder="Enter the Asset Numbers">
                        <mat-hint class="text-danger"
                            *ngIf="Submitted && actionplanForm.controls['action_plan_List_Addition_Assets_Moved_with_Asset'].errors">
                            <span
                                *ngIf="Submitted && actionplanForm.controls['action_plan_List_Addition_Assets_Moved_with_Asset'].errors['required']">
                                Enter the Asset Numbers that will be moved with this asset
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <mat-label>
                        Start by completing the
                        <a href={{costCalcFile}} class="text-decoration mr-2"> cost calculation worksheet</a>
                        <a href={{costCalcFile}}> <i class="fa fa-file-excel"></i></a>
                        and work with stakeholders (listed in the sheet) to determine any estimated total
                        cost to move this tool and any additional work that will be required. Once calculated, enter
                        the estimated cost into the field and attach the file to the Action Plan.
                    </mat-label>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <input matInput type="number" formControlName="estimatedCost"
                            placeholder="Enter the estimated cost">
                        <mat-icon matPrefix>attach_money</mat-icon>
                    </mat-form-field>
                    <app-file-upload-amd uploadFilePath="amd"
                        [uploadFileName]="'Cost_Calculation_Worksheet/'+ assetID +'/'"
                        [uploadFileNameMust]="uploadFileNameMust"
                        [uploadFileRequired]="!(actionplanForm.controls['Cost_Calculation_Worksheet_has_been_attached'].value)"
                        (childFormReady)="addChildForm('ActionPlan_costCalcFileData', $event)"
                        [ParentBtnSubmitted]="Submitted">
                    </app-file-upload-amd>
                    <mat-hint class="text-danger" *ngIf="Submitted && actionplanForm.controls['estimatedCost'].errors">
                        <span *ngIf="Submitted && actionplanForm.controls['estimatedCost'].errors['required']">
                            Estimated cost is required</span>
                    </mat-hint>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <mat-label>
                        {{(actionplanForm.controls['Change_Type'].value!='Basic Change')?
                        'Approvals':'Notifications'}} will be sent to the original Cost Center Owner listed in
                        Asset Details
                        section above.
                    </mat-label>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-75">
                        <input matInput type="text" placeholder="&lt;e-mail Addressess&gt;" rows="2" cols="2"
                            (onSelectionChange)="isEmailChange(1)" formControlName="approversEmailList">
                        <mat-icon matPrefix>contact_page</mat-icon>
                        <mat-icon matSuffix class="text-success" *ngIf="steps1==false">done</mat-icon>
                        <mat-icon matSuffix class="text-danger" *ngIf="steps1==true">close</mat-icon>
                        <mat-hint *ngIf="Submitted && actionplanForm.controls['approversEmailList'].errors"
                            class="text-danger">
                            <span *ngIf="Submitted && actionplanForm.controls['approversEmailList'].errors['required']">
                                Approvers email is required
                            </span>
                            <span *ngIf="actionplanForm.controls['approversEmailList'].errors['pattern']">
                                Should Be 'example@hp.com'
                            </span>

                        </mat-hint>
                    </mat-form-field>
                    <button matSuffix type="button" class="ml-3 btn btn-primary save-button" matTooltip="verify Changes"
                        (click)="validUserLdap(actionplanForm.controls['approversEmailList'].value,0,1)">
                        verify <i class="fa fa-spinner fa-spin" *ngIf="verifyEmailsLoading && steps==1"></i>
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <mat-label>
                        Notifications will be sent to the above listed Tool Owners (original and new). Add additional
                        individuals who should be notified, if needed.
                    </mat-label>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-75">
                        <input matInput type="text" formControlName="notificationsToolOwners"
                            class="form-control emailList" placeholder="&lt;e-mail Addressess&gt;"
                            (onSelectionChange)="isEmailChange(2)">
                        <mat-icon matPrefix>contact_page</mat-icon>
                        <mat-icon matSuffix class="text-success" *ngIf="steps2==false">done</mat-icon>
                        <mat-icon matSuffix class="text-danger" *ngIf="steps2==true">close</mat-icon>
                        <mat-hint *ngIf="Submitted && actionplanForm.controls['notificationsToolOwners'].errors"
                            class="text-danger">

                            <span
                                *ngIf="Submitted && actionplanForm.controls['notificationsToolOwners'].errors['required']">
                                Tool Owners email is required
                            </span>

                        </mat-hint>
                    </mat-form-field>
                    <button matSuffix type="button" class="ml-3 btn btn-primary save-button" matTooltip="verify Changes"
                        (click)="validUserLdap(actionplanForm.controls['notificationsToolOwners'].value,0,2)">
                        verify <i class="fa fa-spinner fa-spin" *ngIf="verifyEmailsLoading && steps==2"></i>
                    </button>
                </div>
            </div>
        </div>

        <mat-toolbar color="warning" class="mb-3">
            <span>Identify Work Required to Complete this Tool Move</span>
        </mat-toolbar>

        <mat-card-content class="mb-3">
            The Yes/No and automated drop downs must be completed for the “Submit for Approval” button to become
            actionable. It does not require that the drop-down actions be moved to the final state. For example,
            “Receiving area needs to be prepped” does not need to be moved to “Receiving area prepped”. However, it will
            need to be in its final state before the “Tool Move/Ready for Analyst Review” button becomes actionable.
        </mat-card-content>

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <mat-label>Does the receiving area need to be prepped?</mat-label>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select placeholder="select Yes or No" formControlName="needsToPrepped" #needsToPrepped
                            (onSelectionChange)="isAssetAreaPrepped()">
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                        <mat-hint class="text-danger"
                            *ngIf="Submitted && actionplanForm.controls['needsToPrepped'].errors" class="text-danger">
                            <span *ngIf="Submitted && actionplanForm.controls['needsToPrepped'].errors['required']">
                                Select Yes or No
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-label>
                        If yes, once the receiving area has been prepped update the drop down box to "Receiving area is
                        prepped"
                    </mat-label>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select placeholder="select Yes or No" formControlName="receivingAreaIsPrepped"
                            (onSelectionChange)="ChangePrepped()">
                            <mat-option [value]="item" *ngFor="let item of receivingAreaIsPreppedDp"> {{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-label>
                        Move asset to receiving location. When done. update the drop down box to "Asset has been moved"
                    </mat-label>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select placeholder="select Yes or No" formControlName="assetMovedStatus">
                            <mat-option value="Asset is waiting to be moved">Asset is waiting to be moved</mat-option>
                            <mat-option value="Asset has been moved">Asset has been moved</mat-option>
                        </mat-select>
                        <mat-hint class="text-danger"
                            *ngIf="Submitted && actionplanForm.controls['assetMovedStatus'].errors" class="text-danger">
                            <span *ngIf="Submitted && actionplanForm.controls['assetMovedStatus'].errors['required']">
                                Select Yes or No
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-label>
                        Will utilities need to be demo'd in the area the asset is being moved from?
                    </mat-label>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select placeholder="select Yes or No" formControlName="isAssetDemo" #isAssetDemo
                            (onSelectionChange)="isAssetDemoChange()">
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                        <mat-hint class="text-danger" *ngIf="Submitted && actionplanForm.controls['isAssetDemo'].errors"
                            class="text-danger">
                            <span *ngIf="Submitted && actionplanForm.controls['isAssetDemo'].errors['required']">
                                Select Yes or No
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-label>
                        Demo utilities of asset. When done, update the drop down to "All utilities have been demo'd"
                    </mat-label>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select formControlName="updatedUtilities" (onSelectionChange)="UtilitiesOfAsset()">
                            <mat-option value="NA">NA</mat-option>
                            <mat-option value="Utilities awaiting demo">Utilities awaiting demo</mat-option>
                            <mat-option value="All utilities have been demo'd">All utilities have been
                                demo'd</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-label>
                        Is any work required for E-waste or CyberSecurity ?
                    </mat-label>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select formControlName="actionPlan_Is_anyWork_Req_ewaste_CyberSecurity"
                            (onSelectionChange)="change_ewaste_CyberSecurity()">
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                        <mat-hint class="text-danger"
                            *ngIf="Submitted && actionplanForm.controls['actionPlan_Is_anyWork_Req_ewaste_CyberSecurity'].errors"
                            class="text-danger">
                            <span
                                *ngIf="Submitted && actionplanForm.controls['actionPlan_Is_anyWork_Req_ewaste_CyberSecurity'].errors['required']">
                                Select Yes or No
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <mat-toolbar color="warning" class="mb-3">
            <span>Notify Stakeholders of Planned Work</span>
        </mat-toolbar>

        <div class="container-fluid">

            <div class="row mb-3">
                <div class="col-md-6">
                    <mat-label>
                        Email will be sent to EHS and other associated stakeholders (note this will cover hook-up and
                        e-waste/cyber) stakeholder, to determine if actions must be taken on any checked items. Add
                        additional individuals who should be notified, if needed.
                    </mat-label>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-75">
                        <input matInput type="text" formControlName="emailToEHSStakeHolders"
                            placeholder="example2@hp.com;First Last Name" (change)="isEmailChange(3)">
                        <mat-icon matPrefix>contact_page</mat-icon>
                        <mat-icon matSuffix class="text-success" *ngIf="steps3 == false">done</mat-icon>
                        <mat-icon matSuffix class="text-danger" *ngIf="steps3 == true">close</mat-icon>
                        <mat-hint *ngIf="Submitted && actionplanForm.controls['notificationsToolOwners'].errors"
                            class="text-danger">

                            <span
                                *ngIf="Submitted && actionplanForm.controls['notificationsToolOwners'].errors['required']">
                                Tool Owners email is required
                            </span>

                        </mat-hint>
                    </mat-form-field>
                    <button matSuffix type="button" class="ml-3 btn btn-primary save-button" matTooltip="verify Changes"
                        (click)="validUserLdap(actionplanForm.controls['emailToEHSStakeHolders'].value,0,3)">
                        verify <i class="fa fa-spinner fa-spin" *ngIf="verifyEmailsLoading && steps==3"></i>
                    </button>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <mat-label>
                        When you have selected the applicable items above, check the box on the right to send a
                        notification
                        email to the EHS and other associated stakeholders.
                    </mat-label>
                </div>
                <div class="col-md-6">
                    <mat-checkbox formControlName="action_plan_EHS_ready_to_be_notified">
                        EHS/Other stakeholders ready to be notified
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <mat-toolbar color="warning" class="mb-3">
            <span>Tracking: {{(actionplanForm.controls['Change_Type'].value!='Basic Change')?
                'Approvals, ':''}} Notifications, Attachments</span>
        </mat-toolbar>

        <div class="container-fluid">
            <div class="row mb-3" *ngIf="
            actionplanForm.controls['Change_Type']?.value != '' &&
            actionplanForm.controls['Change_Type']?.value != 'Basic Change'">
                <div class="col-md-6">
                    <mat-label>
                        View status of approvals
                    </mat-label>
                </div>
                <div class="col-sm-6" *ngIf="
                actionplanForm.controls['Change_Type']?.value != '' &&
                actionplanForm.controls['Change_Type']?.value != 'Basic Change'">
                    <ng-container *ngFor="let item of View_Status_Of_Approvals; let i = index;">
                        <div class="card">
                            <div class="card-header">
                                {{ item?.email}}
                                <span class="RightTxt float-right viewStatus" (click)="viewStatus(i)">View
                                    Status:
                                    <span class="{{(item?.status=='Approved')?'green':'red'}}  font-weight-bold">
                                        {{item?.status}}</span>
                                </span>
                            </div>
                            <div class="card-body" *ngIf="visibleApprovals==i">
                                <p class="card-text" *ngIf="item?.commentList">
                                    Comments :
                                    <ng-container *ngFor="let comment of item?.commentList.split('\n')">
                                        {{comment}}<br>
                                    </ng-container>
                                </p>


                                <span class="RightTxt">
                                    ApprovedBy : {{ item?.approvedBy }}<br>
                                    Date : {{ item?.date }}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                    <mat-label>
                        View status of notifications (Tool Owner and EHS Stakeholders)
                    </mat-label>
                </div>
                <div class="col-sm-6">
                    <app-file-download-from-s4 [dowloadItemFileList]='Action_plan_viewStatusOfNotifiations_Owner_EHS'
                        [Sortkey]="'AMD'" [tableName]="'AMD_ITG'"></app-file-download-from-s4>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <mat-label>
                        View attached files
                    </mat-label>
                </div>
                <div class="col-sm-6">
                    <app-file-download-from-s4 [dowloadItemFileList]='viewAttachedFiles' [Asset_Number]="assetID"
                        [ShowDeleteIcon]="true" [DeletedFile_UpdateInDB_ColName]="'Action_plan_viewAttachedFiles'"
                        [tableName]="'AMD_ITG'" [Sortkey]="'AMD'"></app-file-download-from-s4>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-12" *ngIf="!ApprovalPageCheck">
                    <button class="btn btn-primary mr-2 mb-2" (click)="saveToDraft()" [disabled]="AllSubmitBtnDisabled">SAVE
                        DRAFT</button>
                    <button class="btn btn-primary mr-2 mb-2" [disabled]="approvalsSubmitBtn || AllSubmitBtnDisabled"
                        (click)="submitForApprovals()">
                        {{actionplanForm.controls['Change_Type'].value != 'Basic Change' ? 'Submit for Approval':'Submit for
                        Notification/Approval'}}
                    </button>
                    <button class="btn btn-primary mr-2 mb-2" (click)="submitForAnlyst()"
                        [disabled]="enableSubmit || AllSubmitBtnDisabled">
                        Tool Moved/Ready for Analyst Review
                    </button>
                    <button class="btn btn-primary mr-2 mb-2" (click)="backButton()">Back</button>
                </div>
            </div>
        </div>


        <ng-container *ngIf="actionplanForm.controls['Change_Type'].value!='Basic Change'">
            <app-approvers *ngIf="approversChildCompData['Action_Plan_Email_Approvers_List']"
                [actionPlanApproversData]="approversChildCompData" (sendChildValue)="valueFromChildapprovers($event)">
            </app-approvers>
        </ng-container>

    </mat-card>


</form>
<!-- For the cost object managers approvals template for transfer -->