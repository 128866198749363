<div class="row exportCCD">

          <div style="margin-left: 30px;">
            <button class="btn btn-primary" (click)="Import_Bulk_Stakeholder.click()">
                <i class="fa fa-upload fa-fade"></i> &nbsp;

                <ng-container *ngIf="loading">
                    <i class="fa fa-spinner fa-spin"></i>&nbsp;
                    {{progress}}% &nbsp;
                </ng-container>
                Import Stakeholders
            </button>
            <input class="btn btn-primary" id="Import_Bulk_Stakeholder" style="display: none" type="file"
                accept=".xlsx, .xls" multiple="false" (change)="importBulkStakeholders($event)"
                #Import_Bulk_Stakeholder>&nbsp;&nbsp;
        </div>

        <div>
            <app-export-bulk-data [Sheet_lineStart_Row]="'0'" [exportFileName]="'AMD_Bulk_Stakeholder'"
            [apiPath]="' '" [Sheet_Coloumns_Headers]="bulkCost_Stakeholder_Headers"
            [Sheet_rows_data]="stakeHolderData" [Button_Name]="'Export Stakeholders'"
            [Sheet_Name]="'AMD Bulk Stakeholder'"></app-export-bulk-data>
        </div>
</div>

<hr>

<mat-card class="my-3 mat-elevation-z3 p-3">
    <form [formGroup]="StakeholderForm">
        <div class="row">
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Site</mat-label>
                    <mat-select formControlName="site" (selectionChange) = "SearchBuilding()">
                        <mat-option value="" disabled selected>Select location</mat-option>
                        <mat-option *ngFor="let option of siteOption" [value]="option">{{option}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Building</mat-label>
                    <mat-select formControlName="building">
                        <mat-option *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-option>
                        <mat-option value="" disabled selected>Select lab name</mat-option>
                        <mat-option matInput *ngFor="let option of filteredBuilding" [value]="option">{{option}}</mat-option>

                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>LabFab Name</mat-label>
                    <input matInput formControlName="labFabName" placeholder="LabFab Name">
                </mat-form-field>
            </div>
            
        </div>
    </form>
    <div class="text-center">
        <button class="btn btn-primary mr-2 mb-2 text-center" (click)="fetchData()">
           Search
          </button>

    </div>
    
    
</mat-card>

<div *ngIf="searchClicked">
    <mat-form-field class="searchField">
        <mat-label>Search</mat-label>
        <input matInput [formControl]="searchControl" placeholder="FILTER TABLE DATA">
    </mat-form-field>
</div>

<div *ngIf="searchClicked" class="mat-elevation-z8 addScroll">
  

    <table mat-table [dataSource]="dataSource" class="custom-table">

        <!-- Dynamic Columns including ACTION -->
        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef class="text-white">
                <span>{{ column }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="column === 'ACTION'; else dataCell">
                    <button class="btn btn-primary text-center w-100" (click)="openEditDialog(element)">
                        {{ getActionButtonLabel() }}
                    </button>
                </ng-container>
                <ng-template #dataCell>
                    <span 
                        class="expand-column adjustRowData" 
                        [matTooltip]="getTooltip(element, column)" 
                        matTooltipPosition="above">
                        {{ getStatus(element, column) }}
                    </span>
                </ng-template>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-primary headerAtTop"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div *ngIf="dataSource.data.length === 0" class="no-records-message">
        No CAPEX records to display
    </div>
</div>





  