<section class="page_404">
    <div class="container">
        <div class="h-100 row">
            <div class="align-items-center col-sm-12 d-flex justify-content-center">
                <div class="p-4 shadow-lg text-center wrapper">
                    <div class="logo-wrapper">
                        <img src="{{imgPath + 'logo.png'}}" alt="AMD - CAPEX" class="w-100">
                    </div>
                    <div>
                        <h3>Asset Management Database - AMD</h3>
                        <hr class="dark">
                        <h3 class="h4">
                            {{loggedOutMsg}}
                        </h3>
                        <a href="javascript:void(0)" (click)="login()" class="link_404 bg-primary">Login</a>
                        <!-- <div class="full-loader" *ngIf="loader"><mat-spinner></mat-spinner></div>  -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>