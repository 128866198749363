import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'wbs-details',
  templateUrl: './wbs-details.component.html',
  styleUrls: ['./wbs-details.component.scss']
})
export class WbsDetailsComponent {
  @Output() wbsFormDataChange = new EventEmitter<any>();
  formattedDateCommit: string = '';
  formattedDateDep: string = '';
  formattedStartDate: string = '';
  wbsDetailsForm: FormGroup = this.fb.group({
    wbs: ['', Validators.required],
    originalWbsAmount: [{value: '', disabled: true}],
    adjustedWbsAmount: [''],
    actualCommitDate: [''],
    actualCommitMatDate: ['', Validators.required], 
    estimatedProjectEnd: [{value: '', disabled: true}],
    wbsStatus: ['', Validators.required],
    comments: [''],
    closeDate: [''],
    disposition: [''],
    justification: [''],
    depreciationStartDate: [''],
    depreciationStartMatDate: [''],
    statusNotes: [''],
    closureComments: [''],
    receivingWbs: [''],
    receivingWbsAmount: [''],
    projectStartDate: [''],
    projectStartMatDate: [''], 
  });


  assetClassOptions = ['Asset Class 1', 'Asset Class 2', 'Asset Class 3', 'Asset Class 4', 'Asset Class 5']
  wbsStatusOptions = ['Requested', 'Active', 'Closed'];
  wbsDispositionOptions = ['Asset Setup', 'Asset Transfer', 'Cancelled', 'Expensed']
  minDate = new Date();
  capexFormData: any;
  subscriptions: Subscription [] = []

  constructor(private common: CommonService, private fb: FormBuilder, 
    private router: Router, private toastr: ToastrService, private dialog: MatDialog) {
    }

  ngOnInit(){
    this.subscriptions.push(
        this.common.estimatedEndDate.subscribe(
          {
            next: (
              (res) => {
                this.wbsDetailsForm.controls['estimatedProjectEnd']?.setValue(res.estimatedDate);
              }
            ),
            error: (
              error => {
                this.common.errorHandler(error);
              }
            )
          }
        )
      )
  }

  onValueChange(event: any, fieldName: string) {
    console.log(event);
    if(this.wbsDetailsForm.valid){
      this.wbsFormDataChange.emit(this.wbsDetailsForm);
    }
    else{
      this.wbsFormDataChange.emit(null);
    }
  }

  quarterCalculation(selectedDate: any){
    let year = selectedDate.getFullYear().toString().substr(-2);
      let quarter = 'Q1';
      let fullYear = selectedDate.getFullYear();
      let nextQuarterMonth = 0;
      if ((selectedDate.getMonth() + 1) < 2) {
        quarter = 'Q1';
        nextQuarterMonth = 2;
        if ((selectedDate.getMonth() + 1) === 0) {
          year = (selectedDate.getFullYear() - 1).toString().substr(-2);
        }
      } else if ((selectedDate.getMonth() + 1) < 5) {
        quarter = 'Q2';
        nextQuarterMonth = 5;
      } else if ((selectedDate.getMonth() + 1) < 8) {
        quarter = 'Q3';
        nextQuarterMonth = 8;
      } else if ((selectedDate.getMonth() + 1) < 11) {
        quarter = 'Q4';
        nextQuarterMonth = 11;
      } else {
        quarter = 'Q1';
        year = (selectedDate.getFullYear() + 1).toString().substr(-2);
      }
      console.log("Year", year);
      return { year, quarter, fullYear, nextQuarterMonth};
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, fieldName: string, picker: MatDatepicker<Date>) {
    let selectedDate = event.value;
    let formattedSelectedDate;
    if (selectedDate) {
      formattedSelectedDate = this.formatSelectedDate(selectedDate);
    }
    let selectedField = fieldName;
    if (selectedDate) {
      switch (selectedField) {
        case 'depreciationStartDate':
          let yearDep = selectedDate.getFullYear();
          let resultMonthDep = (selectedDate.getMonth()+1).toString(); 
          let resultDateDep = selectedDate.getDate().toString(); 
          if(selectedDate.getDate()<10){
            resultDateDep = "0"+ resultDateDep;          
          }
          if((selectedDate.getMonth()+1)<10){
            resultMonthDep = "0"+ resultMonthDep          
          }

          let resultDep = `${resultMonthDep}-${resultDateDep}-${yearDep}`;

          
          //show formatted quarter Date - FY24 Q1
          this.wbsDetailsForm.controls['depreciationStartDate']?.setValue(resultDep, { emitEvent: false });
          this.wbsDetailsForm.controls['depreciationStartMatDate']?.setValue(formattedSelectedDate, { emitEvent: false });
          this.getEstimatedProjectEnd(selectedDate);
          picker.close();
          break;

        case 'actualCommitDate':
          let resultCommit = this.quarterCalculation(selectedDate);
          let yearCommit = resultCommit.year;
          let quarterCommit = resultCommit.quarter;
          let monthCommit = Math.abs((selectedDate.getMonth() + 1) - resultCommit.nextQuarterMonth)
          this.formattedDateCommit = `FY${yearCommit} ${quarterCommit}`;
          //show formatted quarter Date - FY24 Q1
          this.wbsDetailsForm.controls['actualCommitDate']?.setValue(this.formattedDateCommit, { emitEvent: false });

          //show formatted selected date - 2024-01-01 (send to db only)
          this.wbsDetailsForm.controls['actualCommitDateDB']?.setValue(formattedSelectedDate, { emitEvent: false });

          //show MatDatePicker Date
          this.wbsDetailsForm.controls['actualCommitMatDate']?.setValue(formattedSelectedDate, { emitEvent: false });
          this.getEstimatedProjectEnd(selectedDate);
          picker.close();
          break;

        case 'projectStartDate':
          let resultStartDate = this.quarterCalculation(selectedDate);
          let yearStartDate = resultStartDate.year;
          let quarterStartDate = resultStartDate.quarter;
          let monthStartDate= Math.abs((selectedDate.getMonth() + 1) - resultStartDate.nextQuarterMonth)
          this.formattedStartDate = `FY${yearStartDate} ${quarterStartDate}`;
          //show formatted quarter Date - FY24 Q1
          this.wbsDetailsForm.controls['projectStartDate']?.setValue(this.formattedStartDate, { emitEvent: false });

          //show formatted selected date - 2024-01-01 (send to db only)

          //show MatDatePicker Date
          this.wbsDetailsForm.controls['projectStartMatDate']?.setValue(formattedSelectedDate, { emitEvent: false });
          this.getEstimatedProjectEnd(selectedDate);
          picker.close();
          break;
      }
    this.wbsFormDataChange.emit(this.wbsDetailsForm);
    }
  }

  formatSelectedDate(selectedDate: Date): string{
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1; // getMonth() is zero-based; add 1 to get the correct month number
    const day = selectedDate.getDate();
  
    // Format month and day to ensure they are MM and DD format
    const formattedMonth = month < 10 ? `0${month}` : month.toString();
    const formattedDay = day < 10 ? `0${day}` : day.toString();
  
    // Concatenate into YYYY-MM-DD format
    return `${year}-${formattedMonth}-${formattedDay}`;      
}

  getEstimatedProjectEnd(selectedDate:Date){
    let estimatedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 6, selectedDate.getDate());
    let estimatedYear = estimatedDate.getFullYear().toString().substr(-2);
    let estimatedQuarter = 'Q1';

    if ((estimatedDate.getMonth() + 1) < 2) {
      estimatedQuarter = 'Q1';
      if ((estimatedDate.getMonth() + 1) === 0) {
        estimatedYear = (estimatedDate.getFullYear() - 1).toString().substr(-2);
      }
    } else if ((estimatedDate.getMonth() + 1) < 5) {
      estimatedQuarter = 'Q2';
    } else if ((estimatedDate.getMonth() + 1) < 8) {
      estimatedQuarter = 'Q3';
    } else if ((estimatedDate.getMonth() + 1) < 11) {
      estimatedQuarter = 'Q4';
    } else {
      estimatedQuarter = 'Q1';
      estimatedYear = (estimatedDate.getFullYear() + 1).toString().substr(-2);
    }
    // this.wbsDetailsForm.controls['estimatedProjectEnd']?.setValue(`FY${estimatedYear} ${estimatedQuarter}`);
  }

  dropdownClick(event:any, fieldName:string){

  }
  
  accessWbsDetails(data: any){
    this.capexFormData = data;
    let totalCost = Number(this.capexFormData?.projectCostDetails?.itemCost) + Number(this.capexFormData?.projectCostDetails?.hookCapitalCost);
    this.wbsDetailsForm.controls['originalWbsAmount']?.setValue(totalCost, { emitEvent: false });
    // this.wbsDetailsForm.controls['estimatedProjectEnd']?.setValue(adjustedEstimatedDate, { emitEvent: false });
  }
  getFormValue() {
    return this.wbsDetailsForm.getRawValue();
  }

  setFormValue(capexAllFields:any){
    console.log("wbs Draft data",capexAllFields);
    this.wbsDetailsForm.controls['wbs']?.setValue(capexAllFields?.WBS, { emitEvent: false });

    this.wbsDetailsForm.setValue({
    wbs: capexAllFields?.WBS ,
    originalWbsAmount: capexAllFields?.WBS_Amount,
    adjustedWbsAmount: capexAllFields?.Adjusted_WBS_Amount,
    actualCommitDate: capexAllFields?.Actual_Commit_Qtr,
    actualCommitMatDate: capexAllFields?.Actual_Commit_Date, 
    estimatedProjectEnd: capexAllFields?.Estimated_Project_End_Qtr,
    wbsStatus: capexAllFields?.WBS_Status,
    comments: capexAllFields?.WBS_Comments,
    closeDate: capexAllFields?.WBS_Close_Date,
    disposition: capexAllFields?.WBS_Disposition,
    justification: capexAllFields?.WBS_Justification,
    depreciationStartDate: capexAllFields?.Depreciation_Start_Date,
    depreciationStartMatDate: capexAllFields?.Depreciation_Start_Date_ISO,
    statusNotes: capexAllFields?.WBS_Status_Notes,
    closureComments: capexAllFields?.Closure_Comments,
    receivingWbs: capexAllFields?.Receiving_WBS,
    receivingWbsAmount: capexAllFields?.Receiving_WBS_Amount,
    projectStartDate: capexAllFields?.Project_Start_Date_or_Date_WBS_Received_Qtr,
    projectStartMatDate: capexAllFields?.Project_Start_Date_or_Date_WBS_Received,
    })
    console.log("Form values after setting", this.wbsDetailsForm.value);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
