import { Component } from '@angular/core';
import { version } from 'xlsx';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  displayedColumns: string[] = ['version', 'date', 'description'];

  donwloadFile() {
    window.open('assets/docs/AMD User Guide.pdf', '_blank');
    return false;
  }

  releaseNotes = [
    {
      version: '0.1.0',
      date: '2021-07-07',
      description: 'Application Go Live',
      notes: []
    },
    {
      version: '1.0.0',
      date: '2021-07-22',
      description: 'Minor bug fixes',
      notes: ['Minor Bug Fixes in the Fixed Asset Verification Page', 'Bug fixes in Comaprison Report and Asset Verification Report']
    },
    {
      version: '1.0.1',
      date: '2021-08-18',
      description: 'Changes on Fixed Asset Verification page',
      notes: ['Added "Serial Number" column in the Fixed Asset Verification data grid', 
        '"Serial Number" column added for Upload Spreadsheet and Download Spreadsheet functionality',
        'Added "Asset Class Code" column in the Display Comparison data grid',
        'Data Sorting implemented in Display Comparison data grid'
      ]
    },
    {
      version: '1.1.0',
      date: '2021-10-22',
      description: 'Release 1.1 Go live with bug fixes.',
      notes: []
    },
    {
      version: '4.0.0',
      date: '2021-11-22',
      description: 'New UI theme, CAP and CAPEX changes and a few bug fixes',
      notes: [
        'Designed a new responsive GUI for the application',
        'Migrated CAP to new UI theme', 'Added CAPEX module',
        'Made changes in the Organization page to show/edit the levels and program drivers',
        'Fixed an issue with Labfab mismatch report',
        'Added a new home page for the application',
        'Fixed a few issues with the new UI in CAP',

      ]
    }
  ];
}
