<app-common-cap-info (sendChildcapInfoValue)="valueFromChildCapInfo($event)"></app-common-cap-info>
<!-- Commented thid from because action plan has all focus area [FocusAreaName]="this.FAOriginalName" -->
<span class="capTitle">
    <h3 class="mb-0" class="card-body white-text orange-gradient text-white text-center"><strong><u>Action Plan</u></strong></h3>
</span>
  
<table  *ngIf="!loading" style="border: 1px solid grey;" cellspacing="5px">
    <thead class="headerAlign" style="z-index: 2">
        <tr class="appTableHeader">
            <th class="tableHeader" style="width:0%;">
                    <span>ID</span>
            </th>
            <th class="tableHeader" style="width:20%;">
                    <span>Task</span>
            </th>
            <th class="tableHeader width10">
                    <span>REQUESTER Focus</span>
            </th>
            <th class="tableHeader" style="width:5%;">
                    <span>Done</span>
            </th>
            <th class="tableHeader width10">               
                    <span>Comment</span>                
            </th>
        </tr>
    </thead>
    <tbody> 
        <ng-container  *ngFor = "let data of actionItemData; let queIndex = index">        
          <!--<tr  valign="top" height="1">
            <td *ngIf = "data.length > 0" class="table-head">-->
                <div *ngIf = "data.length > 0" class="table-head">
                    <strong><label style="margin: 0.5rem;" for="dateInitiated">{{this.actionItemData[queIndex][0]?.answer?.toUpperCase()}}</label></strong>
                </div>
            <!--  </td> 
        </tr>  -->
            <ng-container *ngFor = "let actionItem of data; let id = index">              
                <tr valign="top" height="1" class="{{ actionItem.procurement?'procurementBg':''}}">
                    <td class="table-head width10">
                        <div class="textBorder">
                            <label class="labelText {{actionItem.procurement?'font-weight-bold':''}}" for="dateInitiated">{{id+1}}<sup>{{actionItem.procurement?'P':''}}</sup></label>
                            <!-- <input class="width100" type="text" [value]="actionItem.question"> -->
                        </div>
                    </td>
                    <td class="table-head width10">
                        <div class="textBorder" style="text-align: left;">
                            <label class="labelText" for="dateInitiated" [innerHTML]="actionItem['question']"></label>
                            <!-- <input class="width100" type="text" [value]="actionItem.question"> -->
                        </div>
                    </td>
                    <td class="table-head width10">
                        <div class="textBorder">
                            <label class="labelText" for="dateInitiated">{{actionItem['focusAreaName']}}</label>
                                <!-- <input class="width100" type="text" [value]="actionItem.question"> -->
                        </div>
                    </td>
                    <td class="table-head width10">
                        <div class="textBorder">
                            <input type="checkbox" id="dateInitiated" [checked]="actionItem['isActionPlanCheckBoxDone']" #checkBoxDone 
                            [disabled]= "!this.enableForm || actionItem['disable']"
                            (change)="checkBoxChange(checkBoxDone.checked, actionItem)">
                            <!-- (change)="checkBoxChange($event,queIndex,id,checkBoxDone.checked)" -->
                                <!-- <input class="width100" type="text" [value]="actionItem.question"> -->
                        </div>
                    </td>
                    <td class="table-head width10">
                        <div class="textBorder">
                            <!-- <label class="labelText" for="dateInitiated">this.FAOriginalName</label> -->
                            <input class="width100 comment-box" type="text" style="border: 0px;"  [disabled]= "!this.enableForm"
                            [value]="actionItem.hasOwnProperty('actionPlanComment') ? actionItem.actionPlanComment : ''" (change)="commentBoxVal($event,actionItem)">
                        </div>
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </tbody>
</table>

<div *ngIf="!this.enableForm" class="row" style="margin:20px"></div>
<div *ngIf="!loading && this.enableForm" class="col-12" style="margin:1% 0; padding-left: 0px; padding-bottom: 1%">
    <div class="form-group appDetailSearch text-center">
        <button class="btn btn-primary"
         (click)="save()">Save
        </button>
        <button class="btn btn-primary" style="margin-left: 1%" [disabled] = "!this.showCancelBtn"
         (click)="cancel()">Cancel
        </button>
        <button class="btn btn-primary" style="margin-left: 1%" 
         (click)="back()">Back
        </button>
        <button class="btn btn-primary" style="margin-left: 1%" 
         (click)="submit()">Submit
        </button>
        
    </div>
</div>