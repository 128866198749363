<form [formGroup]="filledData">
    <mat-card class="capital-acquisition-card">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 offset-md-6">
                    <mat-form-field class="w-100">
                        <mat-label><strong>POR#</strong></mat-label>
                        <input matInput type="text" formControlName="PORNumber" readonly>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 offset-md-6">
                    <mat-form-field class="w-100">
                        <mat-label><strong>Description</strong></mat-label>
                        <input matInput type="text" formControlName="Description" readonly>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 offset-md-6">
                    <mat-form-field class="w-100">
                        <mat-label><strong>Capital Planner</strong></mat-label>
                        <input matInput type="text" formControlName="capitalPlanner" readonly>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 offset-md-6">
                    <mat-form-field class="w-100">
                        <mat-label><strong>Requester</strong></mat-label>
                        <input matInput type="text" formControlName="requesterName" readonly>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 offset-md-6">
                    <mat-form-field class="w-100">
                        <mat-label><strong>Status (Draft)</strong></mat-label>
                        <input matInput type="text" formControlName="PORStatus" readonly>
                    </mat-form-field>
                </div>
            </div>

            <!-- <div class="row" *ngIf="loading">
                <div class="col-md-12 d-flex align-items-center justify-content-center">
                   <mat-spinner diameter="20"></mat-spinner>
                   <span class="ml-2">Loading Data...</span>
                </div>
            </div> -->
        </div>
    </mat-card>
</form>

<form [formGroup]="initiateForm">
    <!-- <hr size="30" noshade> -->
    <div class="row">
        <div class="col-12">
            <div style="background-color:#d66000; text-align: center; color: white; padding: 10px;">
                <strong>Requester Information</strong>
            </div>
        </div>
    </div>    
    <br>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100">
                <mat-label>Secondary Requester</mat-label>
                <br>
                <div class="row">
                    <!-- Input Field -->
                    <mat-form-field class="col-md-4 w-100">
                        <input matInput formControlName="Secondary_Req" placeholder="example@hp.com / First Last Name" [disabled]="true" (keyup)="verifyName($event)">
                    </mat-form-field>
    
                    <!-- Verify Button -->
                    <div class="form-group">
                    <button type="button" class="btn btn-primary mr-2" matTooltip="Verify Changes" (click)="validUserLdap(initiateForm.controls['Secondary_Req'].value)">
                        Verify<!--  <mat-spinner *ngIf="userVerifyLoading" diameter="20"></mat-spinner> -->
                    </button>
                    </div>

                    <button mat-icon-button class="material-icons app-toolbar-menu cancel-button"
                        matTooltip="Invalid User" color="warn" *ngIf = "userInvalid"> 
                        <mat-icon>cancel</mat-icon>
                    </button>

                    <button mat-icon-button class="material-icons app-toolbar-menu save-button validUser"
                    matTooltip="valid User" color="green" *ngIf = "userVerify">
                      <mat-icon>check_circle</mat-icon>
                    </button>
                    
                </div>
                <mat-hint>Should be 'example@hp.com' or First Last Name (Dale L Marr)</mat-hint>
            </mat-form-field>
        </div>
    </div>
    
    <br>
    <div class="row">
        <div class="col-12">
            <div style="background-color:#d66000; text-align: center; color: white; padding: 10px;">
                <strong>Tool Information</strong>
            </div>
        </div>
    </div>
    <br>

    <div class="row">
        <div class="col-md-4">
            <mat-form-field class="w-100">
                <mat-label>Tool Name<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <input matInput formControlName="Tool_Name" placeholder="">
                <mat-error *ngIf="initiateForm.controls['Tool_Name'].hasError('required')">
                    Tool Name is required
                </mat-error>
            </mat-form-field>
        </div>
    
        <div class="col-md-4">
            <mat-form-field class="w-100">
                <mat-label>Tool Description<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <input matInput formControlName="Tool_Description" placeholder="">
                <mat-error *ngIf="initiateForm.controls['Tool_Description'].hasError('required')">
                    Tool Description is required
                </mat-error>
            </mat-form-field>
        </div>
    
        <div class="col-md-4">
            <mat-form-field class="w-100">
                <mat-label>Tool Intent<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <input matInput formControlName="Tool_Intent">
                <mat-error *ngIf="initiateForm.controls['Tool_Intent'].hasError('required')">
                    Tool Intent is required
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4">
            <mat-form-field class="w-100">
                <mat-label>Purpose<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <mat-select formControlName="Purpose">
                    <mat-option value="" disabled>--Select--</mat-option>
                    <mat-option *ngFor="let grpUser of purposeList" [value]="grpUser">
                        {{grpUser}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="initiateForm.controls['Purpose'].hasError('required')">
                    Purpose is required
                </mat-error>
            </mat-form-field>
        </div>
    
        <div class="col-md-4">
            <mat-form-field class="w-100">
                <mat-label>Tool History<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <mat-select formControlName="Tool_History">
                    <mat-option value="" disabled>--Select--</mat-option>
                    <mat-option *ngFor="let toolHistory of toolHistory" [value]="toolHistory">
                        {{toolHistory}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="initiateForm.controls['Tool_History'].hasError('required')">
                    Tool History is required
                </mat-error>
            </mat-form-field>
        </div>
    
        <!-- Tool Condition -->
        <div class="col-md-4">
            <mat-form-field class="w-100">
                <mat-label>Tool Condition<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <mat-select formControlName="Tool_Condition">
                    <mat-option value="" disabled>--Select--</mat-option>
                    <mat-option *ngFor="let toolCondition of toolCondition" [value]="toolCondition">
                        {{toolCondition}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="initiateForm.controls['Tool_Condition'].hasError('required')">
                    Tool Condition is required
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <!-- Build Option -->
        <div class="col-md-4">
            <mat-form-field class="w-100">
                <mat-label>Build Option<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <mat-select formControlName="Build_Option">
                    <mat-option value="" disabled>Select Build Option</mat-option>
                    <mat-option *ngFor="let buildOpt of buildOption" [value]="buildOpt">
                        {{buildOpt}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="initiateForm.controls['Build_Option'].hasError('required')">
                    Build Option is required
                </mat-error>
            </mat-form-field>
        </div>
    
        <!-- Site -->
        <div class="col-md-4">
            <mat-form-field class="w-100">
                <mat-label>Site<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <mat-select formControlName="Initiate_Site" (ngModelChange)="SearchProduct()">
                    <mat-option value="" disabled>Select Site</mat-option>
                    <mat-option *ngFor="let val of arr1" [value]="val">
                        {{val}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="initiateForm.controls['Initiate_Site'].hasError('required')">
                    Site is required
                </mat-error>
            </mat-form-field>
        </div>
    
        <!-- Building -->
        <div class="col-md-4">
            <mat-form-field class="w-100">
                <mat-label>Building<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <mat-select formControlName="Initiate_Building" (ngModelChange)="searchLabFabName()">
                    <mat-option value="" disabled>Select Building</mat-option>
                    <mat-option *ngFor="let val of arr2" [value]="val">
                        {{val}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="initiateForm.controls['Initiate_Building'].hasError('required')">
                    Building is required
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <!-- Lab/Fab install Location -->
        <div class="col-md-4">
            <mat-form-field class="w-100">
                <mat-label>Lab/Fab install Location<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <mat-select formControlName="Initiate_LabFab">
                    <mat-option value="" disabled>Select LabFabName</mat-option>
                    <mat-option *ngFor="let val of arr3" [value]="val">
                        {{val}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="initiateForm.controls['Initiate_LabFab'].hasError('required')">
                    Lab/Fab install Location is required
                </mat-error>
            </mat-form-field>
        </div>
    
        <!-- Other Lab/Fab install Location -->
        <div class="col-md-4">
            <mat-form-field class="w-100">
                <mat-label>Other Lab/Fab install Location</mat-label>
                <input matInput id="disableField" type="text" formControlName="Other_Lab" placeholder="">
                <mat-error *ngIf="initiateForm.controls['Other_Lab'].hasError('required')">
                    Asset Description is required
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <br>
    <div class="row">
        <div class="col-12">
            <div style="background-color:#d66000; text-align: center; color: white; padding: 10px;">
                <strong>Supplier Information</strong></div>
        </div>
    </div>
    <br>

    <div class="row">
        <div class="col-12">
            <!-- Supplier Name and Website -->
            <mat-form-field class="w-100">
                <mat-label>Supplier Name and Website (list all suppliers)<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <input matInput formControlName="Supplier_Name" placeholder="">
                <mat-error *ngIf="initiateForm.controls['Supplier_Name'].hasError('required')">
                    Supplier Name is required
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12">
            <!-- List of Suppliers Responsibilities and Expectations -->
            <mat-form-field class="w-100">
                <mat-label>List of Suppliers Responsibilities and Expectations<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <input matInput formControlName="List_Suppliers" placeholder="">
                <mat-error *ngIf="initiateForm.controls['List_Suppliers'].hasError('required')">
                    List of Suppliers Responsibilities and Expectations is required
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12">
            <!-- List of Equipment to be Purchased -->
            <mat-form-field class="w-100">
                <mat-label>List of Equipment to be Purchased (i.e., pumps, lights, etc.) or NA (if not applicable)<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                <input matInput formControlName="List_Equipment" placeholder="">
                <mat-error *ngIf="initiateForm.controls['List_Equipment'].hasError('required')">
                    List of Equipment is required
                </mat-error>
            </mat-form-field>
        </div>
    </div> 

    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100"> 
              <mat-label>Upload Supporting Files (i.e., RFQ, SOW, Specifications, etc.)</mat-label>
              <input matInput formControlName="upload_file" placeholder="">
                <app-file-upload-amd  class="custom-file-upload"
                    uploadFilePath="cap"
                    [uploadFileName]="sendStatusUpdate['por_number'] + '/'"
                    [uploadFileNameMust]="'any'"
                    [uploadFileRequired]="false"
                    (childFormReady)="addChildForm('capUpload', $event)"
                    [ParentBtnSubmitted]="false">
                </app-file-upload-amd>
            </mat-form-field>  
        </div>

        <div *ngIf="this.viewAttachedFiles" class="attached-files-container">
            <div class="col-12">
            <mat-label>View attached files</mat-label>
            <app-file-download-from-s4 
              [dowloadItemFileList]="viewAttachedFiles" 
              [tableName]="'CAP_AllPOR_ITG'" 
              [Sortkey]="'CAP'" 
              [Asset_Number]="sendStatusUpdate['por_number']" 
              [ShowDeleteIcon]="true" 
              [DeletedFile_UpdateInDB_ColName]="'capUpload'">
            </app-file-download-from-s4>
            </div>
        </div>

        <div *ngIf="this.showProgressBar" class="col-sm-3" style="float:right;position:relative;margin-bottom:3%;margin-top:-1%;">
            <div style="position:relative;font-size:13px">File upload is in progress...</div>
            <div class="progress fieldBorderBottom" style="width:100%;
                                        box-shadow: 0.1rem 0.1rem 0.2rem #6ec8ef; height: 20px;position: absolute;">
                <div id="progressBar" class="progress-bar progress-bar-striped progress-bar-animated appColor"
                    role="progressbar" value="5" max="100" style="width:1%">
                    <span id="status"
                        style="font-size:12px;padding-top:9px;position:fixed;color:white;font-weight:500;"></span>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-2">
            <div class="form-group">
                <button type="button" class="btn btn-primary form-control" 
                        [disabled]="disableFields" (click)="saveCapRequest('Save')">Save Draft</button>
            </div>            
        </div>
        <div class="col-2">
            <div class="form-group">
                <input type="button" class="btn btn-primary form-control" value="Initiate Request" 
                       [disabled]="disableFields" (click)="submitCapRequest('Submit')" />
            </div>
        </div>
        <div class="col-1">
            <div class="form-group">
                <input type="button" class="btn btn-primary form-control" value="Cancel"
                       (click)="cancel()" />
            </div>
        </div>
        <div class="col-1">
            <div class="form-group">
                <input type="button" class="btn btn-primary form-control" value="Back"
                       (click)="back()" />
            </div>
        </div>        
    </div>
</form>