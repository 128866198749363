
<ng-container *ngFor="let dowloadItemFile of dowloadItemFileList; index as i"> 
<div>
{{dowloadItemFile['Name'].split("/").slice(-1)}} 
<i class="fa fa-download" aria-hidden="true" style="cursor: pointer;margin-left: 0.6rem;" (click)="fileDownload(dowloadItemFile)"></i>
<i *ngIf="ShowDeleteIcon" class="fa fa-trash-o" aria-hidden="true" style="cursor: pointer;margin-left: 0.6rem;" (click)="fileDelete(dowloadItemFile,i)"></i>
<!-- 
<i class="fa fa-eye" *ngIf="getPreviewStatus(dowloadItemFile)"  style="cursor: pointer;" aria-hidden="true"></i>
                   <i class="fa fa-eye-slash" *ngIf="" aria-hidden="true"></i> 
                    <div [hidden]="!view" class="popup">
                            <div class="popup-content">
                                <span class="close" (click)="view = false">&times;</span><br>
                            <embed #displayPDF style="margin-left: 40px;" [src]="" type="application/pdf" width= "90%" height="90%" #displayPDF>
                            </div>
                    </div> -->
                </div>
                </ng-container>

 <div *ngIf="DeletingFileLoading"><i class="fa fa-spinner fa-spin"></i>&nbsp; Deleting the file......</div>
<div *ngIf="DownloadingFileLoading"><i class="fa fa-spinner fa-spin"></i>&nbsp; Downloading the file......</div>
