import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'src/app/services/common.service';
import { PlannerData } from './tableDialogData';
import { historyDetailsData } from '../../AssetDetails/history/historyDetailsData';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent {
  loading = true;
  tableDetailsArray: any = [];

  displayedColumns: string[] = ["Action", "UserName", "Email", "Section", "Role"];
  capexRole: any = ['Finance', 'Planner'];

  ELEMENT_DATA: PlannerData[] = [];
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  financeOptions: any;
  plannerOptions: any;
  editedRows: any[] = [];
  userVerifyLoading = false
  userVerify = false;
  userInvalid = false;
  
  RequestLdapValidation = {
    validateEmailDetails: "null",
    level: 0
  }
  showErrorMsgNewRow: boolean = false;
  submitEnable!: boolean;
  inputCapexRole!: boolean;
  constructor(
    private common: CommonService, private dialogRef: MatDialogRef<TableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  ngOnInit(): void {
    this.getTableData();
  }
  applyFilter(filterValue: any) {
    // filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.target.value.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue.target.value;
  }

  getTableData(){
    this.loading = true;
    this.common.getCapexAPI('capex-request-role').subscribe({
      next: (
        (data) => {
          this.loading = false;
          console.log("Role Data", data);
          this.financeOptions = data['Finance'].filter((a: any) => a['Capex_Request_Role'] == 'Finance');
          this.plannerOptions = data['Planner'].filter((a: any) => a['Capex_Request_Role'] == 'Planner');
          let FinancePlannerOptions = this.financeOptions.concat(this.plannerOptions);
          for (let i = 0; i < FinancePlannerOptions.length; i++) {
            let parts = FinancePlannerOptions[i]['Capex_Request_Role_Name'].split('-').map((part: string) => part.trim());
            const loc: PlannerData = {
              UserName: parts[0],
              Section: parts[1],
              Email: FinancePlannerOptions[i]['Email'],
              Capex_Request_Role: FinancePlannerOptions[i]['Capex_Request_Role'],
              Active: FinancePlannerOptions[i]['Active']
            }
            this.tableDetailsArray.push(loc);
          }
          this.ELEMENT_DATA = this.tableDetailsArray;
          this.dataSource = new MatTableDataSource<PlannerData>(this.ELEMENT_DATA);
        }),
      error: (
        (err) => {
          this.loading = false;
          console.error("Error occurred:", err);
        })      
    });
    console.log("Role Data", this.dataSource);
  }

  edit(element:any){
    let rowSelected = element;
    element.editing = !element.editing;
    console.log("Row Selected", rowSelected);
  }


  editField(element:any, fieldName: any){
    this.submitEnable = true
    //element.isUpdated = true
    if (fieldName == "Email") {
      this.userVerify = false;
      element.userVerified = false;
    }
    element.Capex_Request_Role_Name = element.UserName + " - " + element.Section;

    const index = this.editedRows.findIndex((x: any) => x.Email === element.Email && x.Capex_Request_Role === element.Capex_Request_Role);

    if (element.Email && element.UserName && element.Section && element.Capex_Request_Role) {
      // if (index === -1 && element.newRow) {
      //   this.editedRows.push(element);
      //   this.showErrorMsgNewRow = false;
      // } else 
      if (index === -1) {
        this.editedRows.push(element);
        // if(element.newRow){
        //   this.inputCapexRole = false;
        // }
        // else{
        //   // this.editedRows.push(element);
        //   this.inputCapexRole = true;
        // }

      }
      else {
        this.editedRows[index] = element;
      }
    }
    else{
      this.editedRows = this.editedRows.filter(row => row.Email !== '' && row.UserName !== '' 
      && row.Section !== '' && row.Capex_Request_Role !== '');
    }
   
  }

  validUserLdap(rowData: any) {
    let user = rowData.Email
    rowData.userVerifyLoading = true;
    rowData.userVerified = false
    rowData.userInvalid = false;
    // this.userVerify = false
    // this.userInvalid = false;
    this.RequestLdapValidation['validateEmailDetails'] = user.includes('.') ? (user.includes('@hp.com') ? user : user + '@hp.com') : user
    if (this.RequestLdapValidation['validateEmailDetails'] != '') {
      this.common.postAPI('amd-ldap-email-validation', this.RequestLdapValidation).subscribe((data: any) => {
        rowData.userVerifyLoading = false;
        rowData.Email = this.RequestLdapValidation['validateEmailDetails'];
        if (data?.statusCode == 200 && data?.body && data?.body?.length) {
          this.userVerify = true;
          rowData.userVerified = true;
          let emailString = "";
          let projectManagerEmail_Ldap = ''
          for (let x of data?.body) {
            if (x['email']) {
              emailString += x['email'] + ";"
            }
          }
          if (emailString.charAt(emailString.length - 1) == ';') {
            projectManagerEmail_Ldap = emailString.slice(0, -1)
          }
         // element.Email = projectManagerEmail_Ldap
          //this.requestDetailsForm.get('projectManagerEmail')?.setValue(projectManagerEmail_Ldap)
        }
        if (data?.errorMSg || data.body?.length == 0 || data.body?.userNotFound) {
          // this.userInvalid = true;
          rowData.userInvalid = true;
        }
        if (data.body?.userNotFound?.length == 0) {
          // this.userInvalid = false;
          // this.userVerify = true;
          rowData.userInvalid = false;
          rowData.userVerified = true;
        
        }
      });
    }
    else{
      rowData.userVerifyLoading = false;
    }
  }

  enterEmail(event: any){
    // this.userVerify = false;
    // this.userInvalid = false;
    console.log("Email Entered", event.target.value);
    // this.validUserLdap(this.capexForm.get('projectManagerEmail')?.value);
  }

  delete(row:any){

  }

  onSubmit(){
    console.log("Invalid", this.userInvalid);
    if (this.editedRows.length == 0) {
      this.common.show('error', 'Please enter row data before submitting.');
    }
    else if (this.editedRows.some(row => row.userVerified === false)) {
      this.common.show('error', 'Some users have not been verified. Please verify before submitting.');
    }
    else if(this.editedRows.some(row => row.newRow == true && (row.UserName === '' || row.Email === '' || row.Section === '' || row.Capex_Request_Role == '') )){
      this.common.show('error', 'Please fill all the fields before submitting.');
    }
    // else if(this.inputCapexRole == false){
    //   this.common.show('error', 'Please fill Capex Role as Planner or Finance before submitting.');
    // }
    else {
      this.common.postCapexAPI('capex-request-role', this.editedRows).subscribe({
        next: (
          (data: any) => {
            console.log("Submit Data", data);
            this.common.show('success', data.message);
            this.loading = false;
            this.dialogRef.close();
          }),
        error: (
          (err) => {
            this.loading = false;
            console.error("Error occurred:", err);
          })
      })
    }
  }

  addRow() {
    const newRow = {
      UserName: '',
      Section: '',
      Email: '',
      Capex_Request_Role: '',
      editing: true,
      newRow: true,
      Active: true
    };
    this.tableDetailsArray.push(newRow);
    this.ELEMENT_DATA = this.tableDetailsArray;
    this.dataSource = new MatTableDataSource<PlannerData>(this.ELEMENT_DATA);
  }

  deactivate(element: any, buttonName: any) {

    if(buttonName == "deactivate"){
      element.Active = false;
      element.editing = true;
    }
    else{
      element.Active = true;
    }
    //element.Active = !element.Active;
    element.Capex_Request_Role_Name = element.UserName + " - " + element.Section;
    const index = this.editedRows.findIndex((x: any) => x.Email === element.Email);
    if (index !== -1) {
      this.editedRows[index] = element;
    } else {
      this.editedRows.push(element);
    }
    this.onSubmit();
  }
  }
