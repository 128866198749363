<!-- Asset Detail accordion -->
    <form [formGroup]="assetDetailsForm" (ngSubmit)="onSubmit()">
        <div class="container-fluid pd-0">
            <div class="row">
                <mat-form-field class="col-md-4">
                    <mat-label class="label-text"># Assets</mat-label>
                    <input matInput placeholder="" class="disabledColor" formControlName="assets"
                        (input)="onValueChange($event, 'assets')">
                </mat-form-field>
                <mat-form-field class="col-md-4">
                    <mat-label class="label-text">Asset Useful Life (yrs)</mat-label>
                    <input matInput placeholder="" class="disabledColor" formControlName="assetUsefulLife"
                        (input)="onValueChange($event, 'assetUsefulLife')">
                </mat-form-field>
                <mat-form-field class="col-md-4">
                    <mat-label class="label-text">Asset Class(i)</mat-label>
                    <mat-select formControlName="assetClass" (selectionChange)="onAssetClassChange($event.value)">
                        <mat-option *ngFor="let option of assetClassOptions" [value]="option">{{option}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="row">
                <mat-form-field class="col-md-4">
                    <mat-label class="label-text">Asset Class Description(i)</mat-label>
                    <mat-select formControlName="assetDesc" (selectionChange)="onAssetClassDesChange($event.value)">
                        <mat-option *ngFor="let option of assetClassDescOptions" [value]="option">{{option}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-4">
                    <mat-label class="label-text">Total Cash Flow</mat-label>
                    <input matInput placeholder="" class="disabledColor" formControlName="totalCashFlow"
                        (input)="onValueChange($event, 'assets')">
                </mat-form-field>
                <mat-form-field class="col-md-4">
                    <mat-label class="label-text">Total Spend from Reconciliation Report</mat-label>
                    <input matInput placeholder="" class="disabledColor" formControlName="report"
                        (input)="onValueChange($event, 'assetUsefulLife')">
                </mat-form-field>
            </div>

            <div *ngIf="numberOfAssets.length > 0" style="margin-bottom: 2%;">
                <div class="row border-container">
                  <span class="text-over-border">Assets</span>
                </div>
                <div class="mat-table-container">
                  <mat-table [dataSource]="numberOfAssets.controls" class="mat-elevation-z8">
            
                    <!-- Depreciation Cost Center Column -->
                    <ng-container matColumnDef="deprCostCenter">
                      <mat-header-cell *matHeaderCellDef> Depreciation Cost Center </mat-header-cell>
                      <mat-cell *matCellDef="let row; let i = index">
                        <mat-form-field>
                          <mat-select [formControl]="row.get('deprCostCenter')" (selectionChange)="onManagerChange($event.value,i)">
                            <mat-option *ngFor="let option of deprCostCenOpt" [value]="option">{{ option }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>
            
                    <!-- Depreciation Cost Object Manager Column -->
                    <ng-container matColumnDef="deprCostObjManager">
                      <mat-header-cell *matHeaderCellDef> Depreciation Cost Center Manager </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <mat-form-field>
                        <input matInput type="text" [formControl]="row.get('deprCostObjManager')">
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>
            
                    <!-- Asset Class Column -->
                    <ng-container matColumnDef="assetClass2">
                      <mat-header-cell *matHeaderCellDef> Asset Class </mat-header-cell>
                      <mat-cell *matCellDef="let row; let i = index">
                        <mat-form-field>
                          <mat-select [formControl]="row.get('assetClass2')" (selectionChange)="assetClassRowChange($event.value,i)">
                            <mat-option *ngFor="let option of assetClassOptions" [value]="option">{{ option }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>
            
                    <!-- Asset Class Description Column -->
                    <ng-container matColumnDef="assetClassDescription">
                      <mat-header-cell *matHeaderCellDef> Asset Class Description </mat-header-cell>
                      <mat-cell *matCellDef="let row; let i = index">
                        <mat-form-field>
                          <mat-select [formControl]="row.get('assetClassDescription')">
                            <mat-option *ngFor="let option of assetClassDescOptionsRow[i] || assetClassDescOptions" [value]="option">{{ option }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>
            
                    <!-- Building Column -->
                    <ng-container matColumnDef="building">
                      <mat-header-cell *matHeaderCellDef> Building </mat-header-cell>
                      <mat-cell *matCellDef="let row; let i = index">
                        <mat-form-field>
                          <mat-select [formControl]="row.get('building')" (selectionChange)="SearchLabFabName($event.value, i)">
                            <mat-option *ngFor="let option of filteredBuilding" [value]="option">{{ option }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>
            
                    <!-- Lab/Fab Install Location Column -->
                    <ng-container matColumnDef="labfabInstallLocation">
                      <mat-header-cell *matHeaderCellDef> Lab/Fab Install Location </mat-header-cell>
                      <mat-cell *matCellDef="let row; let i = index">
                        <mat-form-field>
                          <mat-select [formControl]="row.get('labfabInstallLocation')" (selectionChange)="SearchtoolOwner($event.value,i)">
                            <mat-option *ngFor="let option of filteredLabFabNameRow[i] || filteredLabFabName" [value]="option">{{ option }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>
            
                    <!-- Tool Owner Column -->
                    <ng-container matColumnDef="toolOwner">
                      <mat-header-cell *matHeaderCellDef> Tool Owner </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <mat-form-field>
                          <input matInput type="text" [formControl]="row.get('toolOwner')">
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>
            
                    <!-- Asset Useful Life Column -->
                    <ng-container matColumnDef="assetUsefulLife2">
                      <mat-header-cell *matHeaderCellDef> Asset Useful Life </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <mat-form-field>
                          <input matInput type="number" [formControl]="row.get('assetUsefulLife2')">
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>
            
                    <!-- Asset Description Column -->
                    <ng-container matColumnDef="assetDescription">
                      <mat-header-cell *matHeaderCellDef> Asset Description </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <mat-form-field>
                          <input matInput type="text" [formControl]="row.get('assetDescription')">
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>
            
                    <!-- Asset Value Column -->
                    <ng-container matColumnDef="assetValue">
                      <mat-header-cell *matHeaderCellDef> Asset Value </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <mat-form-field>
                          <input matInput type="number" min = "5000" [formControl]="row.get('assetValue')" (blur)="validateItemCostControl()" (click)="validateItemCostControl()">
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>
            
                    <!-- Asset Number Column -->
                    <ng-container matColumnDef="assetNumber">
                      <mat-header-cell *matHeaderCellDef> Asset Number </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <mat-form-field>
                          <input matInput type="number" min="100000000000" max="999999999999"  [formControl]="row.get('assetNumber')" (blur)="validateAssetNumber()" (click)="validateAssetNumber()">
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>
            
                    <!-- Comments Column -->
                    <ng-container matColumnDef="assetComments">
                      <mat-header-cell *matHeaderCellDef> Comments </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <mat-form-field>
                          <input matInput type="text" [formControl]="row.get('assetComments')">
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>
            
                    <!-- Header and Row Definitions -->
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            
                  </mat-table>
                </div>

                <!-- <div *ngFor="let asset of numberOfAssets; let i = index">
                    <div class="row border-container">
                        <span class="text-over-border">Asset {{i+1}}</span>
                    </div>
                    <div class="row">
                        <mat-form-field class="col-md-4">
                            <mat-label class="label-text">Depreciation Cost Center</mat-label>
                            <input matInput placeholder="" class="disabledColor" formControlName="deprCostCenter"
                                (input)="onValueChange($event, 'deprCostCenter')">
                        </mat-form-field>
                        <mat-form-field class="col-md-4">
                            <mat-label class="label-text">Depreciation Cost Object Manager</mat-label>
                            <input matInput placeholder="" class="disabledColor" formControlName="deprCostObjManager"
                                (input)="onValueChange($event, 'deprCostObjManager')">
                        </mat-form-field>
                        <mat-form-field class="col-md-4">
                            <mat-label class="label-text">Asset Class</mat-label>
                            <input matInput placeholder="" class="disabledColor" formControlName="assetClass"
                                (input)="onValueChange($event, 'assetClass')">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="col-md-4">
                            <mat-label class="label-text">Asset Description</mat-label>
                            <input matInput placeholder="" class="disabledColor" formControlName="assetDescription"
                                (input)="onValueChange($event, 'assetDescription')">
                        </mat-form-field>
                        <mat-form-field class="col-md-4">
                            <mat-label class="label-text">Building</mat-label>
                            <input matInput placeholder="" class="disabledColor" formControlName="building"
                                (input)="onValueChange($event, 'building')">
                        </mat-form-field>
                        <mat-form-field class="col-md-4">
                            <mat-label class="label-text">Lab/Fab Install Location</mat-label>
                            <input matInput placeholder="" class="disabledColor" formControlName="labfabInstallLocation"
                                (input)="onValueChange($event, 'labfabInstallLocation')">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="col-md-4">
                            <mat-label class="label-text">Tool Owner</mat-label>
                            <input matInput placeholder="" class="disabledColor" formControlName="toolOwner"
                                (input)="onValueChange($event, 'toolOwner')">
                        </mat-form-field>
                        <mat-form-field class="col-md-4">
                            <mat-label class="label-text">Asset Useful Life (yrs)</mat-label>
                            <input matInput placeholder="" class="disabledColor" formControlName="assetUsefulLife"
                                (input)="onValueChange($event, 'assetUsefulLife')">
                        </mat-form-field>
                        <mat-form-field class="col-md-4">
                            <mat-label class="label-text">Asset Number</mat-label>
                            <input matInput placeholder="" class="disabledColor" formControlName="assetNumber"
                                (input)="onValueChange($event, 'assetNumber')">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="col-md-4">
                            <mat-label class="label-text">Comments</mat-label>
                            <input matInput placeholder="" class="disabledColor" formControlName="assetComments"
                                (input)="onValueChange($event, 'assetComments')">
                        </mat-form-field>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <button mat-raised-button class="itg-gradient font-size mr-2">Save Draft</button>   
                        <button mat-raised-button class="itg-gradient font-size mr-2">Submit for Analyst Review</button>
                        <button mat-raised-button class="itg-gradient font-size">Make Asset</button>
                    </div>
                </div>
            </div>

            <div class="row border-container">
                <span class="text-over-border">Asset Setup (Administator Use Only)</span>
            </div>
            <div class="row">
              <mat-form-field class="col-md-4">
              <mat-label class="label-text">Actual Setup Date</mat-label>
              <input matInput [matDatepicker]="actualPicker" [min]="minDate" placeholder="Choose a date"
                  class="disabledColor offscreen" formControlName="actualSetupMatDate"
                  (dateChange)="onDateChange($event, 'actualSetupDate', actualPicker)">
              <mat-datepicker-toggle matSuffix [for]="actualPicker"></mat-datepicker-toggle>
              <mat-datepicker #actualPicker></mat-datepicker>
              <input matInput placeholder="Choose a date" formControlName="actualSetupDate" class="disabledColor"
                  readonly>
              </mat-form-field>

                <mat-form-field class="col-md-4">
                    <mat-label class="label-text">Asset Setup Action</mat-label>
                    <input matInput type="number" placeholder="Find Items" class="disabledColor" formControlName="setupAction"
                        (input)="onValueChange($event, 'setupComment')">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="col-md-12">
                    <mat-label class="label-text">Asset Setup Comment</mat-label>
                    <input matInput type="text" placeholder="" class="disabledColor" formControlName="setupComment"
                        (input)="onValueChange($event, 'setupComment')">
                </mat-form-field>
            </div>


        </div>
        <button type="submit" class="btn btn-primary" >Submit</button>
    </form>
    