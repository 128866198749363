<button *ngIf= "this.showAssessmentPopup" mat-icon-button class="closeBtnAlign" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>

<app-common-cap-info *ngIf="isOutermost" [hidden] = "this.showAssessmentPopup" [FocusAreaName]="this.FAOriginalName" (sendChildcapInfoValue)="valueFromChildCapInfo($event)"></app-common-cap-info>

<span *ngIf="isOutermost" class="capTitle">
  <h3 class="card-body white-text orange-gradient text-white text-center"><strong><u>Assessment - {{this.FAOriginalName}}</u></strong></h3>
</span>

<table
    [ngClass]="[isOutermost ? 'table table-striped table-responsive-md btn-table bs-select tablecss tableDesign' : 'internalSubQue']"
    style="width: 100%; table-layout: fixed;">
    <thead class="custfont headerAlign white-text orange-gradient text-white text-center" style="z-index: 2" *ngIf="isOutermost">
        <tr class="appTableHeader">

            <th class="table-head">
                <div>
                    <span>Assessment Question</span>&nbsp;
                </div>
            </th>
            <th class="table-head">
                <div>
                    <span>Options</span>&nbsp;
                </div>
            </th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let questionData of questions; index as QuesIndex">
            <tr *ngIf = "!questionData?.conditionalQues && (questionData.type != 'statement')">
                <td class="width45">
                    <span [ngClass]="[isOutermost ? 'row alignLink': 'row aligninternalQue']" [innerHTML]="getQuestionName(questionData)">
                    </span>          
                </td>
                <td *ngIf="questionData.type != 'statement'" [ngClass]="[isOutermost ? '': 'internalQueWidth']" class="width45">
                    <mat-card class="result alignText" style="overflow-x: auto;">
                        <mat-card-content style="text-align: center !important">
                            <section class="example-section">
                                    <!-- Radio options Starts -->
                                    <mat-radio-group  *ngIf="questionData.type == 'radiogroup'" aria-labelledby="common-radio-group-label"
                                    [(ngModel)]="questionData['answer']" class="common-radio-group" [required]="questionData.required">
                                        <mat-radio-button class="common-radio-button" *ngFor="let option of questionData.choices" [value]="option"
                                            (change)="toggleChanges($event,questionData)" [disabled]="questionData.isDisable" [ngClass]="[questionData.required ? 'ss-item-required': '']">
                                            {{option}}
                                        </mat-radio-button> 
                                    </mat-radio-group>
                                    <!-- Radio options ENDS -->
                
                                    <!-- checkboxs start -->
                                    <ng-container *ngIf="questionData.type == 'checkbox'">
                                        <div class="form-check form-check-inline"   *ngFor="let option of questionData.choices; index as i">
                                            <label class="form-check-label" for="{{option.label}}"> 
                                                <input class="form-check-input" [required]="questionData.required" [ngClass]="[questionData.required ? 'ss-item-required': '']"
                                                   type="checkbox" id="{{option.label}}" [disabled]="questionData.isDisable" [(ngModel)]="option.checked" (change)="checkBoxChange(questionData)">
                                             </label>{{option.label}}
                                        </div>
                                    </ng-container>
                                    <!-- checkboxs ENDS -->
                
                                    <!-- File UPload Starts -->
                                    <ng-container *ngIf="questionData.type == 'upload'">
                                    <app-file-upload-amd uploadFilePath="cap"  [uploadFileName]="this.sendFormVar['POR'] + '/' + questionData['questionId'] + '/'"
                                        [uploadFileNameMust]='questionData.fileName' [uploadFileRequired]="(questionData.required? true : false)"
                                        (childFormReady)="addChildForm('capUpload',$event,questionData)"
                                        [ParentBtnSubmitted]="false" [disableOnSubmit]=questionData?.isDisable></app-file-upload-amd>
                                        <span class="redColor">Note: Only {{questionData.fileName}} are acceptable</span>  

                                        <ng-container *ngIf="questionData.answer.length>0">
                                            <p for="" style="padding-top: 15px; margin: 0px;" *ngIf="this.viewAttachedFiles">View attached files</p>
                                            <div>
                                                <app-file-download-from-s4 [dowloadItemFileList]="questionData['answer']"
                                                    [tableName]="'CAP_Assessment_Review_Itg'" [Sortkey]="'CAPAssessment'" [Asset_Number]="this.sendFormVar['POR'] + '/' + questionData['questionId']"
                                                    [ShowDeleteIcon]="questionData?.isDisable ? false : true"
                                                    [DeletedFile_UpdateInDB_ColName]="this.sendFormVar['POR'] + '/' + questionData['questionId'] + '/' + questionData['answer']"></app-file-download-from-s4><br>
                                            </div>
                                        </ng-container>    
                                    </ng-container>
                                    
                                    <!-- File UPload ENDS -->
                                    <!-- Dropdowns starts -->
                                    <mat-form-field appearance="fill" *ngIf="questionData.type == 'dropdown'">
                                        <mat-select  [ngClass]="[questionData.required ? 'ss-item-required': '']" [(value)]="questionData.answer" [(ngModel)]="questionData['answer']" [required]="questionData.required" (selectionChange)="textBoxVal($event,questionData)">{{questionData.answer}}
                                            <mat-option>Select the option</mat-option>
                                            <mat-option *ngFor="let option of questionData.choices" [value]="option"  [disabled]="questionData.isDisable">{{option}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                
                                    <!--  text multi field Starts -->
                                    <ng-container *ngIf="questionData.type == 'multiText'">
                                        <app-assessment-add-labels [parentDatamultiFeild]="questionData?.answer"  [tableSource]="questionData?.answer" [column]="questionData?.columnName"
                                            (childLabelFormReady)="addLabeltextChild($event, questionData)"></app-assessment-add-labels>
                                    </ng-container>
                                    <!-- Textarea Starts -->
                                    <ng-container *ngIf="questionData.type == 'textarea'">
                                        <textarea rows="2" type="textarea" [ngClass]="[questionData.required ? 'ss-item-required': '']" [required]="questionData.required" [(ngModel)]="questionData['answer']" 
                                                value="questionData['answer']" (change)="textBoxVal($event,questionData)" [disabled]="questionData.isDisable"></textarea>
                                    </ng-container>
                
                                    <!-- Text Starts -->
                                    <ng-container *ngIf="questionData.type == 'text'">
                                        <mat-form-field appearance="outline" *ngIf='questionData.istextBox'>
                                            <mat-label>Enter data</mat-label>
                                            <input type="text" matInput placeholder="Textarea" [ngClass]="[questionData.required ? 'ss-item-required': '']" [required]="questionData.required" [disabled]="questionData.isDisable" [(ngModel)]="questionData['answer']" 
                                            (change)="textBoxVal($event,questionData)" [disabled]="questionData.isDisable">
                                          </mat-form-field>
                                    </ng-container>
                              
                            </section>
                        </mat-card-content>
                    </mat-card>               
                </td>
            </tr>
            <tr *ngIf = "questionData.type == 'statement'">
                <td  colspan="2" class="width95">
                    <span [ngClass]="[isOutermost ? 'row alignLink': 'row aligninternalQue']" [innerHTML]="getQuestionName(questionData)">
                    </span>          
                </td>
            </tr>
                <tr *ngIf="questionData?.showSubQue && (questionData?.internalQuestions && questionData?.internalQuestions?.length > 0)">
                    <td colspan="2" style="padding: 0px;" class="width50">
                        <div class="internal-question">
                            <app-common-assessment-form
                                *ngIf="questionData?.showSubQue && (questionData?.internalQuestions && questionData?.internalQuestions?.length > 0)"
                                [questions]="questionData?.internalQuestions" (updatedTableData) = "emitUpdatedTableData($event)" [isOutermost]="false">
                            </app-common-assessment-form>
                        </div>
                    </td>
                </tr>
           
        </ng-container>  
    </tbody>
</table>
