import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportRoutingModule } from './report-routing.module';
import { ComparisonReportComponent } from './comparison-report/comparison-report.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { ReportComponent } from './report.component';
import { DisplayComparisonReportComponent } from './display-comparison-report/display-comparison-report.component';
import { MaterialModule } from 'src/app/modules/material.module';
import { LtpPorReportComponent } from './ltp-por-report/ltp-por-report.component';
import { LabfabMismatchComponent } from './labfab-mismatch/labfab-mismatch.component';
import { ExportActiveInactiveAssetsComponent } from './export-active-inactive-assets/export-active-inactive-assets.component';
import { RetiredReclassifiedAssetsComponent } from './retired-reclassified-assets/retired-reclassified-assets.component';


@NgModule({
  declarations: [
    ReportComponent,
    ComparisonReportComponent,
    DisplayComparisonReportComponent,
    LtpPorReportComponent,
    LabfabMismatchComponent,
    ExportActiveInactiveAssetsComponent,
    RetiredReclassifiedAssetsComponent
  ],
  imports: [
    CommonModule,
    ReportRoutingModule,
    SharedModule,
    MaterialModule
  ]
})
export class ReportModule { }
