import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'wbs-cashflow-summary',
  templateUrl: './wbs-cashflow-summary.component.html',
  styleUrls: ['./wbs-cashflow-summary.component.scss']
})
export class WbsCashFlowComponent {
  wbsCashFlowForm: FormGroup = this.fb.group({
    estimatedTotalCost: [{value: '', disabled: true}],
    currentWbsAmount: [{value: '', disabled: true}],
    totalReconcillation: [''],
    approvedPoCommits: [''],
    totalInvoicedPo: [''],
    totalAmountJvs: [''],
    TotalCashFlow: [''],
    estimatedTotalSpend: [''],
    estimatedRemainingSpend: [''],
    monthsStatic: [''],
    engrNreCost: [{value: '', disabled: true}],
    techNreCost: [{value: '', disabled: true}],
    totalNreCost: [{value: '', disabled: true}],
    engrNreBilled: [''],
    techNreBilled: [''],
    totalNreBilled: [''],
    remainingNre: [''],
    hookupCapitalized: [''],
    hookupBiiled: [''],
    remainingHookup: [''],
  });

  userRole = this.common.getUserRole();
  isRequester: boolean = this.userRole === 'Requester'? true : false;
  wbs: any;
  totalCashFlow: any;
  wbsAmount: any;
  approvedPOCommits: any;
  totalInvoicedPO: any;
  totalAmountJVs: any;
  total_amount_of_invoiced_po: any;
  total_amount_of_jv: any;

  constructor(private common: CommonService, private fb: FormBuilder, 
    private router: Router, private toastr: ToastrService, private dialog: MatDialog) {
    }

    capexFormData: any;

    ngOnInit(){
      if(this.isRequester){
        this.wbsCashFlowForm.disable();
      }
    }

  accessWbsCashFlow(data: any) {
    this.capexFormData = data;
    this.wbs = this.capexFormData?.wbsDetailsForm?.value?.wbs;
    console.log(data);
    // let totalCost = Number(this.capexFormData?.projectCostDetails?.itemCost) + Number(this.capexFormData?.projectCostDetails?.hookCapitalCost);
    let totalCost = Number(this.capexFormData?.projectCostDetails?.itemCost || 0) + Number(this.capexFormData?.projectCostDetails?.hookCapitalCost || 0) + Number(this.capexFormData?.NREtotalCost || 0);
    let engrCost = Number(this.capexFormData?.projectCostDetails?.engrNRECost || 0);
    let techCost = Number(this.capexFormData?.projectCostDetails?.techNRECost || 0);
    let totalNre = engrCost + techCost;
    //this.wbsCashFlowForm.controls['estimatedTotalCost']?.setValue(totalCost, { emitEvent: false });
    //this.wbsCashFlowForm.controls['estimatedTotalCost']?.setValue((Math.round(totalCost)).toFixed(2), { emitEvent: false });
    this.wbsCashFlowForm.controls['estimatedTotalCost']?.setValue(this.common.formatUSD(Math.round(totalCost)), { emitEvent: false });
    this.wbsCashFlowForm.controls['engrNreCost']?.setValue(this.common.formatUSD(engrCost), { emitEvent: false });
    this.wbsCashFlowForm.controls['techNreCost']?.setValue(this.common.formatUSD(techCost), { emitEvent: false });
    this.wbsCashFlowForm.controls['totalNreCost']?.setValue(this.common.formatUSD(totalNre), { emitEvent: false });
    //this.wbsCashFlowForm.controls['currentWbsAmount']?.setValue(totalCost, { emitEvent: false });
    // const value = this.common.adjustedWbsAmmount?.getValue();

    this.common.currentTotalPOAmount.subscribe(amount => {
      this.wbsCashFlowForm.controls['approvedPoCommits']?.setValue(this.common.formatUSD(amount), { emitEvent: false });
    });

    this.common.currentInvoicedTotalPOAmount.subscribe(value => {
      this.wbsCashFlowForm.controls['totalInvoicedPo']?.setValue(this.common.formatUSD(value), { emitEvent: false });
      this.total_amount_of_invoiced_po = value;
    });

    this.common.currentJVAmount.subscribe(value => {
      this.wbsCashFlowForm.controls['totalAmountJvs']?.setValue(this.common.formatUSD(value), { emitEvent: false });
      this.total_amount_of_jv = value;
    });

    this.totalCashFlow = this.total_amount_of_invoiced_po + this.total_amount_of_jv;
    this.wbsCashFlowForm.controls['TotalCashFlow']?.setValue(this.common.formatUSD(this.totalCashFlow), { emitEvent: false });

    if (this.wbs) {
      this.common.getCapexAPI('ltp-por/get-wbs-amount-from-reports?wbs=' + this.wbs).subscribe({
        next: (
          (data) => {
            this.wbsCashFlowForm.controls['totalReconcillation']?.setValue(this.common.formatUSD(data.recon.total_usd), { emitEvent: false });
           // this.wbsCashFlowForm.controls['approvedPoCommits']?.setValue(this.common.formatUSD(data.approved_po_commits), { emitEvent: false });
          //  this.wbsCashFlowForm.controls['totalInvoicedPo']?.setValue(this.common.formatUSD(data.total_amount_of_invoiced_po), { emitEvent: false });
           // this.wbsCashFlowForm.controls['totalAmountJvs']?.setValue(this.common.formatUSD(data.total_amount_of_jv), { emitEvent: false });
           // this.totalCashFlow = data.total_cash_flow;
            //this.wbsCashFlowForm.controls['TotalCashFlow']?.setValue(this.common.formatUSD(this.totalCashFlow), { emitEvent: false });
            //calculate the estimated remaining spend
            //this.wbsAmount = this.wbsCashFlowForm.controls['currentWbsAmount'].value || 0;
            const estimatedCost = this.wbsAmount - this.totalCashFlow || 0;
            this.wbsCashFlowForm.controls['estimatedRemainingSpend']?.setValue(this.common.formatUSD(estimatedCost), { emitEvent: false });  
          }),
        error: (
          (err) => {
            console.error("Error occurred:", err);
          })

      });
    }
    if (this.capexFormData.wbsDetailsForm.value.adjustedWbsAmount) {
      this.wbsCashFlowForm.controls['currentWbsAmount']?.setValue(this.common.formatUSD(this.capexFormData.wbsDetailsForm.value.adjustedWbsAmount), { emitEvent: false });
      this.wbsAmount = this.capexFormData.wbsDetailsForm.value.adjustedWbsAmount
    } else {
      //this.wbsCashFlowForm.controls['currentWbsAmount']?.setValue(Math.round(totalCost).toFixed(2), { emitEvent: false });
      this.wbsCashFlowForm.controls['currentWbsAmount']?.setValue(this.common.formatUSD(Math.round(totalCost)), { emitEvent: false });
      this.wbsAmount = Math.round(totalCost).toFixed(2);
    }

    // const wbsAmount = this.wbsCashFlowForm.controls['currentWbsAmount'].value || 0;
    // //const totalCashFlow = this.wbsCashFlowForm.controls['TotalCashFlow'].value || 0;
    
    // const estimatedCost = wbsAmount - this.totalCashFlow || 0;
    
    // this.wbsCashFlowForm.controls['estimatedRemainingSpend']?.setValue(estimatedCost, { emitEvent: false });    

    if(this.userRole == 'Planner' && data.wbsDetailsForm.value.wbsStatus == 'Closed'){
      this.wbsCashFlowForm.disable();
    }
  }

    setFormValue(capexAllFields:any){
      console.log("wbs Draft data",capexAllFields);
  
      this.wbsCashFlowForm.setValue({
      estimatedTotalCost: capexAllFields?.Estimated_Total_Cost || '',
      currentWbsAmount: capexAllFields?.Current_Wbs_Amount || '',
      totalReconcillation: capexAllFields?.Total_Reconcillation || '',
      approvedPoCommits: capexAllFields?.Approved_Po_Commits || '', 
      totalInvoicedPo: capexAllFields?.Total_Invoiced_Po || '',
      totalAmountJvs: capexAllFields?.Total_Amount_Jvs || '',
      TotalCashFlow: capexAllFields?.Total_Cash_Flow || '',
      estimatedTotalSpend: capexAllFields?.Estimated_Total_Spend || '',
      estimatedRemainingSpend: capexAllFields?.Estimated_Remaining_Spend || '',
      monthsStatic: capexAllFields?.Months_Static || '',
      engrNreCost: capexAllFields?.Engr_Nre_Cost || '',
      techNreCost: capexAllFields?.Tech_Nre_Cost || '',
      totalNreCost: capexAllFields?.Total_Nre_Cost || '',
      engrNreBilled: capexAllFields?.Engr_Nre_Billed || '',
      techNreBilled: capexAllFields?.Tech_Nre_Billed || '',
      totalNreBilled: capexAllFields?.Total_Nre_Billed || '',
      remainingNre: capexAllFields?.Remaining_Nre || '',
      hookupCapitalized: capexAllFields?.Hookup_Capitalized || '',
      hookupBiiled: capexAllFields?.Hookup_Biiled || '',
      remainingHookup: capexAllFields?.Remaining_Hookup || '',
      })
      console.log("Form values after setting", this.wbsCashFlowForm.value);
    }

    getFormValue() {
      return this.wbsCashFlowForm.getRawValue();
    }

    onTotalPOAmountChange(value: any) {
      console.log('Total PO Amount:', value);
    }



}