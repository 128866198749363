import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { ExportBulkDataComponent } from './export-bulk-data/export-bulk-data.component';
import { ReplaceUnderscorePipe } from '../Pipes/replace-underscore-pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CommonModule } from '@angular/common';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { FileUploadAMDComponent } from './file-upload-amd/file-upload-amd.component';
import { FileDownloadFromS4Component } from './file-download-from-s4/file-download-from-s4.component';

@NgModule({
  declarations: [
    DialogBoxComponent,
    ExportBulkDataComponent,
    ReplaceUnderscorePipe,
    FileUploadAMDComponent,
    FileDownloadFromS4Component
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    DialogBoxComponent,
    ExportBulkDataComponent,
    ReplaceUnderscorePipe,
    InfiniteScrollModule,
    NgxBarcode6Module,
    FileUploadAMDComponent,
    FileDownloadFromS4Component
  ],
  imports:[
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule
  ],
  providers: [ ]
})
export class SharedModule { }