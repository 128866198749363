<div class="row">
    <div class="col-12 text-center">
        <!-- <i class="fa fa-file-pdf" style="font-size:30px;"></i> -->
        To download the Userguide, click <a href (click)="donwloadFile()">here.</a>
    </div>
</div>
<table mat-table [dataSource]="releaseNotes" class="mat-elevation-z8 mt-3">

    <!-- Version Column -->
    <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Version </th>
        <td mat-cell *matCellDef="let element"> {{element.version}} </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Date </th>
        <td mat-cell *matCellDef="let element"> {{element.date}} </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Description </th>
        <td mat-cell *matCellDef="let element">
            <div class="font-weight-bold">{{element.description}}</div>
            <ul *ngIf="element.notes.length > 0">
                <li *ngFor="let note of element.notes">{{note}}</li>
            </ul>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['version', 'date', 'description']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['version', 'date', 'description'];"></tr>
</table>