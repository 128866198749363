import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssetComponent } from './asset.component';
import { AppDetailComponent } from './app-detail/app-detail.component';
import { SaveAsAssetComponent } from './save-as-asset/save-as-asset.component';
import { AssetRequestChangeComponent } from './asset-request-change/asset-request-change.component';
import { RetirementRequestComponent } from './retirement-request/retirement-request.component';
import { ActionPlanComponent } from './action-plan/action-plan.component';
import { LabFabDisplayComponent } from './lab-fab-display/lab-fab-display.component';
import { EditLabfabnameComponent } from './edit-labfabname/edit-labfabname.component';
import { ActionPlanRetireComponent } from './retirement-request/action-plan-retire/action-plan-retire.component';
import { DataVerificationComponent } from './data-verification/data-verification.component';
import { EditFixedAssetVerificationComponent} from './edit-fixed-asset-verification/edit-fixed-asset-verification.component';
import { PrintVerificationComponent} from './print-verification/print-verification.component';

const routes: Routes = [

  {
    path: '', component: AssetComponent,
    children: [
      { path: '', redirectTo: 'asset-detail', pathMatch: 'full' },
      { path: 'asset-detail', component: AppDetailComponent },
      { path: 'asset-request-change', component: AssetRequestChangeComponent },
      { path: 'retirement-request', component: RetirementRequestComponent },
      { path: 'action-plan', component: ActionPlanComponent },
      { path: 'labfab', component: LabFabDisplayComponent },
      { path: 'editlabfabname', component:EditLabfabnameComponent},
      { path: 'action-plan-retire', component: ActionPlanRetireComponent },
      { path: 'data-verification', component: DataVerificationComponent},
      { path: 'edit-fixed-asset-verification', component:EditFixedAssetVerificationComponent},
      { path: 'print-verification', component: PrintVerificationComponent}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AssetRoutingModule { }
