<button mat-icon-button class="closeBtnAlign" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<h2 mat-dialog-title>{{data.title}}</h2>

<mat-dialog-content *ngIf="data.showInput">
  <label class="textHeading">{{data.textHeading}}<span class="text-danger">*</span></label>
  <mat-form-field appearance="fill">
    <mat-label>{{data.inputLabel}}</mat-label>
    <input matInput [(ngModel)]="data.commentText"  (keyup)="valuechange($event)">
  </mat-form-field>
  <span class="text-danger">{{data.errorMessage}}</span>
</mat-dialog-content>

<mat-dialog-content  *ngIf="!data.showInput">
  <p [innerHTML]="data.message"> {{data['message']}} </p>
</mat-dialog-content>


<mat-dialog-actions class="dialogFooter mL6" *ngIf="data.showYesNoButton">
  <button class="btn btn-primary mr-2 mb-2" (click)="submitResponse('Yes')">Yes</button>
  <button class="btn btn-primary mr-2 mb-2"  (click)="submitResponse('No')">No</button>
</mat-dialog-actions>

<mat-dialog-actions class="dialogFooter mL6" *ngIf="!data.showYesNoButton">
  <button class="btn-submit" *ngIf="data.showInput" mat-button (click)="submit()">Submit</button>
  <button class="btn btn-primary mr-2 mb-2" *ngIf="!data.showInput" (click)="submitResponse('Ok')">OK</button>
</mat-dialog-actions>

