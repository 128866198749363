import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { DialogBoxComponent } from '../../modules/dialog-box/dialog-box.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'app-upload-report',
  templateUrl: './upload-report.component.html',
  styleUrls: ['./upload-report.component.scss']
})
export class UploadReportComponent {

  showUploadFile: boolean = false;
  loading: boolean = false;
  validUser: boolean = false;
  uploadFilePath: string = "";
  currentDate: string = "";
  testData: any;
  showProgressBar: boolean = false;
  // upload_File = new FormGroup({
  //   upload_file: new FormControl()
  // });
  testFileData: any;
  @ViewChild('fileUploader') fileUploader!:ElementRef;

  constructor(private common: CommonService, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {
    this.common.currentModule.next(this.activatedRoute.snapshot.data)
    this.common.bannerData.next({ title: 'Upload a Report' })
    const userRole = this.common.getUserRole();
    const username = atob(this.common.currentUser)
    if (userRole === 'Admin' || username === 'gcole@hp.com') {
      this.validUser = true;
    }
  }

  enableUploadButton(event: any) {
    this.testFileData = event.target.files;
    const document = this.testFileData.item(0);
    this.currentDate = new Date().toISOString().slice(0, 10);
    this.uploadFilePath = "capex" + "/" + this.currentDate;
    let fileData = { "Name": document.name, "Path": this.uploadFilePath }
    this.loading = true;
    this.common.postAPI('amd-cap-upload-file', fileData).subscribe((data: any) => {
      this.showUploadFile = true;
      this.loading = false
      this.testData = data;
    });
  }

  async onUpload(event: any) {
    event.preventDefault();
    if (this.testData) {
      this.showProgressBar = true;

      await this.sendDataDup("POST", this.testData.url)
        .then(function (datums) {
          window.localStorage.setItem("reqData", "success");

        })
        .catch(function (err) {
          window.localStorage.setItem("reqData", "fail");
        });
      let reqdata1 = window.localStorage.getItem("reqData");
      if (reqdata1 == 'success') {
        this.showProgressBar = false;
        const popUpTxt = ' File uploaded Successfully';
        this.openDialogMessage(popUpTxt, '');
        this.uploadFilePath = '';
        this.showUploadFile = false;
        this.testFileData = '';
        this.fileUploader.nativeElement.value = null;
      }
      else {
        this.showProgressBar = false;
        const popUpTxt2 = ' File not uploaded, please upload it again!!!';
        this.openDialogMessage(popUpTxt2, '');
      }
    }
  }

  async sendDataDup(method: any, url: any) {
    return await new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(this.testData.fields).forEach(key => {
        formData.append(key, this.testData?.fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("file", this.testFileData[0]);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.onload = function () {
        if (xhr.status == 204) {
          window.localStorage.setItem("reqData", "success");

          resolve((xhr.response));
        } else {
          window.localStorage.setItem("reqData", "");
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.upload.addEventListener("progress", progressHandler);
      xhr.send(formData);
    });
  }
  openDialogMessage(message: any, assetID: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      message: message,
      tabletemplate: false
    }

    this.dialog.open(DialogBoxComponent, dialogConfig);
  }
}

function progressHandler(event: any) {
  const app = document.getElementById("progressBar");
  if (app) {
    var percent = (event.loaded / event.total) * 100;
    if (percent < 6) {
      percent = 5;
    }
    $("#progressBar").css("width", percent + "%");
    app.innerHTML = '1%';
    app.innerHTML = '<span id="status" style="font-size:12px;color:white;font-weight:500;">' + (Math.round(percent) + "%") + '</span>';
  }
}
