<div class="export-assets-container">
  <!-- <h2 matDialogTitle>{{exportProp.title}}</h2> -->
  <mat-dialog-content>
    <p><em>{{exportProp.subTitle}}</em></p>
    <ng-container *ngFor="let item of exportProp.Data">
      <!--input radio button -->
      <div *ngIf="item.type=='radio'">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="item.value">
          <mat-radio-button class="mr-5" *ngFor='let option of item.options'
            value={{option}}>{{option}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <br>
      <!-- input date in dd/mm/yyyy form -->
      <div *ngIf="item.type=='date'">
        <mat-form-field>
          <mat-label>Select the Date</mat-label>
          <input matInput [matDatepicker]="dp1" class="hidden" [(ngModel)]="item.value">
          <mat-datepicker-toggle matSuffix [for]="dp1" style="font-size: 1.2rem;"></mat-datepicker-toggle>
          <mat-datepicker #dp1></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>
  </mat-dialog-content>

    <div class="button-container">
      <div class="form-group">
        <input type="button" class="btn btn-primary form-control" value="Submit" (click)="submit(exportProp)" />
      </div>
      <div class="form-group">
        <input type="button" class="btn btn-primary form-control" value="Reset" (click)="reset()" />
      </div>
    </div>
  <!-- <mat-dialog-actions align="end">
    <button mat-button color="primary" [mat-dialog-close]="exportProp" (click)="reset()">Reset</button>
    <button mat-button color="primary" [mat-dialog-close]="exportProp" (click)="submit(exportProp)">Submit</button>
  </mat-dialog-actions> -->


  <div class="MenuActivebtn" *ngIf="paramsExport">
    <app-export-bulk-data 
      (childMessage)="childMessage($event)" 
      [cssClass]="'MenuActivebtnID'" 
      [Sheet_lineStart_Row]="'0'" 
      [exportFileName]="'Export_AMD_Active_Inactive_Assets'" 
      [apiPath]="paramsExport" 
      [Sheet_Coloumns_Headers]="tableHeaderArray" 
      [Sheet_rows_data]="[]" 
      [Button_Name]="'Export Active & Inactive Assets'" 
      [Sheet_Name]="'AMD Active & Inactive Assets'">
    </app-export-bulk-data>
  </div>
  
</div>
