import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-common-cap-info',
  templateUrl: './common-cap-info.component.html',
  styleUrls: ['./common-cap-info.component.scss']
})
export class CommonCapInfoComponent implements OnInit {

  //sending the data to parent component action plan
  @Output() sendChildcapInfoValue: EventEmitter<any> = new EventEmitter<any>();
  @Input() FocusAreaName!: string;
  capInfoForm!: FormGroup;
  sendPORData!: { POR: any; };
  loading!: boolean;

  constructor(
        private fb: FormBuilder, 
        private common: CommonService,  
        private activatedRoute: ActivatedRoute
      ) {this.common.bannerData.next({ title: 'Cap Details'}); }

  ngOnInit(): void {
    this.capInfoForm = this.fb.group({
      POR: [''],
      focusAreaName: this.FocusAreaName,
      actionPlan: this.activatedRoute?.snapshot?.queryParams['actionPlan'] + " Action Plan",
      Site: [''],
      Building: [''],
      BuildOption: [''],
      Purpose: [''],
      LabFab_Install_Location: [''],
      ToolHistory: [''],
      ToolCondition: [''],
      ToolName: [''],
      POR_Status: ['']
    })

    this.sendPORData = {
      POR: this.activatedRoute.snapshot.queryParams['POR']
    }

    this.common.postCapAPI('itg/cap-update-por-status-itg',this.sendPORData).subscribe({
      next: (data) => {
        this.loading = true;
        let modifiedData = data.body
        this.capInfoForm.patchValue(modifiedData);
        this.loading = false;
        this.sendChildcapInfoValue.emit({ "commonInfo": modifiedData });

        console.log("Print data", modifiedData)
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }
}
