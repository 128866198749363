<form [formGroup]="wbsCashFlowDetailsForm" class="cash-flow-form">

  <div class="row border-container">
    <span class="text-over-border">Purchase Orders</span>
  </div>

  <div class="section">
    <table class="details-table styled-table">
      <thead>
        <tr>
          <th *ngFor="let header of PurchaseOrdersHeaders" style="color: white;">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody formArrayName="purchaseOrders">
        <tr *ngFor="let po of purchaseOrdersFormArray.controls; let i = index" 
            [formGroupName]="i" [class.alt-row]="i % 2 !== 0">
          <td *ngFor="let header of PurchaseOrdersHeaders">
            <!-- <ng-container *ngIf="isColumnEditable(header, 'purchaseOrders'); else readOnlyCell">
              <input type="text" [formControlName]="header" class="editable-input" />
            </ng-container> -->
            <ng-container *ngIf="isColumnEditable(header, 'purchaseOrders'); else readOnlyCell">

              <!-- If the column is 'PO date[Early Entry]', show a Material date picker -->
              <mat-form-field *ngIf="header === 'PO date[Early Entry]'" appearance="outline" class="small-datepicker custom-datepicker">
                <!-- <mat-label>Choose Date</mat-label> -->
                <input matInput [matDatepicker]="poDatePicker" [formControlName]="header" />
                <mat-datepicker-toggle matSuffix [for]="poDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #poDatePicker></mat-datepicker>
              </mat-form-field>

              <!-- Otherwise, show a normal text input -->
              <input *ngIf="header !== 'PO date[Early Entry]'" type="text" [formControlName]="header" class="editable-input" />
              
            </ng-container>          
            <ng-template #readOnlyCell>
              <span>{{ po.get(header)?.value }}</span>
            </ng-template>
          </td>
          <td>
            <button *ngIf="po.get('isNewRow')?.value" type="button" class="delete-btn" (click)="deleteRow('purchaseOrders', i)">
              <div class="bin">
                <div class="lid"></div>
                <div class="container">
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row border-container">
    <span class="text-over-border">Journal Vouchers</span>
  </div>

  <div class="section">
    <table class="details-table styled-table">
      <thead>
        <tr>
          <th *ngFor="let header of JournalVouchersHeaders" style="color: white;">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody formArrayName="journalVouchers">
        <tr *ngFor="let jv of journalVouchersFormArray.controls; let i = index" 
            [formGroupName]="i" [class.alt-row]="i % 2 !== 0">
          <td *ngFor="let header of JournalVouchersHeaders">
            <span>{{ jv.get(header)?.value }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row border-container">
    <span class="text-over-border">Invoices</span>
  </div>

  <div class="section">
    <table class="details-table styled-table">
      <thead>
        <tr>
          <th *ngFor="let header of InvoicesHeaders" style="color: white;">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody formArrayName="invoices">
        <tr *ngFor="let invoice of invoicesFormArray.controls; let i = index" 
            [formGroupName]="i" [class.alt-row]="i % 2 !== 0">
            <td *ngFor="let header of InvoicesHeaders">
              <ng-container *ngIf="isColumnEditable(header, 'invoices'); else readOnlyCell">
                <ng-container *ngIf="header === 'Service Type'; else textInput">
                  <select [formControlName]="header" class="editable-input">
                    <option *ngFor="let type of ServiceTypeOptions" [value]="type">{{ type }}</option>
                  </select>
                </ng-container>
                <!-- <ng-template #textInput>
                  <input type="text" [formControlName]="header" class="editable-input" />
                </ng-template> -->
                <!-- Inputs for Other Fields -->
                <ng-template #textInput>
                  <!-- Calendar Date Picker for Posting Date[Early Entry] -->
                  <mat-form-field *ngIf="header === 'Posting Date[Early Entry]'" appearance="outline" class="small-datepicker custom-datepicker">
                      <!-- <mat-label>Choose a Date</mat-label> -->
                      <input matInput [matDatepicker]="postingDatePicker" formControlName="{{header}}" />
                      <mat-datepicker-toggle matSuffix [for]="postingDatePicker"></mat-datepicker-toggle>
                      <mat-datepicker #postingDatePicker></mat-datepicker>
                  </mat-form-field>

                  <!-- Text Input for Other Columns -->
                  <input *ngIf="header !== 'Posting Date[Early Entry]'" type="text" [formControlName]="header" class="editable-input" />
              </ng-template>

              </ng-container>
              <ng-template #readOnlyCell>
                <span>{{ invoice.get(header)?.value }}</span>
              </ng-template>
            </td>            
          <td>
            <button *ngIf="invoice.get('isNewRow')?.value" type="button" class="delete-btn" (click)="deleteRow('invoices', i)">
              <div class="bin">
                <div class="lid"></div>
                <div class="container">
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</form>