<div class="row">
  <div class="col-12 text-center">

    <button class="btn btn-primary mr-2 mb-2" *ngIf="isadmin" (click)="exportModified()">
      <i class="fa fa-file-excel"></i>Export Basic Changes S4
    </button>

    <input class="btn btn-primary d-none" id="files" type="file" accept=".xlsx, .xls" multiple="false"
      (change)="importS4Asset($event)" #files>
    <button class="btn btn-primary mr-2 mb-2" *ngIf="isadmin" (click)="exportTransfer()">
      <i class="fa fa-file-excel"></i>Export Transfer S4
    </button>
    <button class="btn btn-primary mr-2 mb-2" (click)="exportRetiredReclassified()" *ngIf="userRole!='Requester'">
      <i class="fa fa-file-excel"></i>Export Retire/Reclass S4
    </button>
    <button class="btn btn-primary mr-2 mb-2" (click)="exportPendingApproval()" *ngIf="userRole!='Requester'">
      <i class="fa fa-file-excel"></i>Export Pending Approval
    </button>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12 text-center">
    <button class="btn btn-primary mr-2 mb-2" *ngIf="isadmin" (click)="files.click()">
      <i class="fa fa-upload"></i>Import S4
    </button>

    <button class="btn btn-primary mr-2 mb-2" *ngIf="isadmin" (click)="files1.click()">
      <i class="fa fa-upload"></i>Import Basic/Transfer Bulk Changes
    </button>
    <input class="btn btn-primary mr-2 mb-2 d-none" #Import_Bulk_Data id="files1" type="file" accept=".xlsx, .xls"
      multiple="false" (change)="importBulkBasic($event)" #files1>

    <button class="btn btn-primary mr-2 mb-2" *ngIf="isadmin" (click)="files2.click()">
      <i class="fa fa-upload"></i>Import Bulk Retirements
    </button>
    <input class="btn btn-primary mr-2 mb-2 d-none" id="files2" type="file" accept=".xlsx, .xls" multiple="false"
      (change)="importBulkRetire($event)" #files2>

  </div>
</div>

<mat-card *ngIf="show" class="my-3 mat-elevation-z3 p-3">
  <form [formGroup]="assetdetails1" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-4">
        <mat-form-field class="w-100">
          <mat-label>Building</mat-label>
          <mat-select formControlName="Asset_Location" (selectionChange)="SearchProduct()">
            <mat-option hidden value="" disabled selected>Select location </mat-option>
            <mat-option *ngFor="let val of arr1" value={{val}}>
              {{val}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100">
          <mat-label>LabFab Name</mat-label>
          <mat-select formControlName="Asset_Supplementary_Inventory_Specifications_Name">
            <mat-option hidden value="" disabled selected>Select lab name </mat-option>
            <mat-option *ngFor="let val of arr2" value={{val}}>{{val}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100">
          <mat-label>Asset Number</mat-label>
          <input type="text" formControlName="Main_Asset_Number" placeholder="" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100">
          <mat-label>Owner</mat-label>
          <input matInput type="text" formControlName="Additional_Description" placeholder="" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100">
          <mat-label>Cost Center Code </mat-label>
          <input matInput type="text" formControlName="Cost_Center_Code" placeholder="">
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100">
          <mat-label>Cost Object Manager</mat-label>
          <input matInput type="text" formControlName="Cost_Object_Manager_Email_ID" placeholder="">
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100">
          <mat-label>Depreciation Terms Start Date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="Depreciation_Terms_Start_Date"
            placeholder="MM/DD/YYYY">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12">
        <div class="text-center">
          <button type="submit" class="btn btn-primary mr-2">
            <i class="fa fa-search"></i>
            Search
          </button>
          <app-export-bulk-data [Sheet_lineStart_Row]="'0'" [exportFileName]="'Export_AMD_Data'" [apiPath]="' '"
            [Sheet_Coloumns_Headers]="tableHeaderArray" [Sheet_rows_data]="FinalAdminAssetData" [Button_Name]="'Export'"
            [Sheet_Name]="'AMD Assets'" [disabled]="pageLength.length != 1"></app-export-bulk-data>
        </div>
      </div>
    </div>
  </form>
</mat-card>

<div *ngIf="assetDetails">
  <span>Row Count : <b>{{rowCount}}</b></span>
  <ng-container *ngIf="assetDetails && pageLength.length != 1">
    <span>Page</span>
    <span *ngFor="let page of pageLength">
      <button class="btnPagination" (click)="pageNext(page)">
        <b *ngIf="page===currentPage" class="btnPaginationHover">{{page}}</b>
        <span *ngIf="page!==currentPage">{{page}}</span>
      </button>
    </span>
  </ng-container>
</div>


<div class="mat-elevation-z3 my-3">
  <div class="table-responsive dataTable">
    <table id="dtHorizontalExample" class="table table-striped" *ngIf="assetDetails && showGrid">
      <div class="search-results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="20"
        (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="false">
        <thead class="custfont">
          <tr class="appTableHeader ">
            <th class="bg-primary">
              <div *ngIf="userRole=='Admin'"><input class="checkBoxWhiteCOlor" type="checkbox"
                  [(ngModel)]="tableCheckAllBox" (change)="checkUncheckAll()"></div>
            </th>
            <th class="table-head bg-primary" *ngFor="let title of tableHeaderArray; index as i">
              <div *ngIf="title!='Checkbox_Data'" (click)="sortData(i,title)">
                <span class="text-white">{{title | replaceUnderscore }}</span>
                <i *ngIf="i==columnindex && ascendingsort==true" class="fa fa-arrow-up"></i>
                <i *ngIf="i==columnindex && ascendingsort==false" class="fa fa-arrow-down"></i>
              </div>
              <div *ngIf="title!='Checkbox_Data'">
                <input type="text" class="form-control" [(ngModel)]="filterText[i]"
                  (input)="filterValue(filterText, i, title)" placeholder="Enter Keyword">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="!noRecord">
            <tr *ngFor="let item of Updated_FinalAdminAssetData">
              <td>
                <input type="checkbox" class="checkBoxColor" [(ngModel)]="item.Checkbox_Data"
                  (click)="pritnItems(FinalAdminAssetData)" *ngIf="userRole=='Admin'">
                <input type="button"
                  *ngIf="(userRole!='Admin') && (item['Change_Status'] =='Draft' || item['Change_Status']=='Pending Analyst Review')"
                  class="btn btn-primary"
                  [routerLink]="[(item['Change_Type'] !='Retired') ? '../asset-request-change' :'../assetRequestChange/retrimentRequest']"
                  [queryParams]="{ assetid: item['Asset_Number'] }" value="Change in Progress"
                  [attr.disabled]="(userRole!='Admin') && (item['Change_Status'] !='' && item['Change_Status'] !='Approved' && item['Change_Status'] !='Cancel' && item['Change_Status'] !='Draft' && item['Change_Status'] != undefined) ? true:null"
                  [attr.readonly]="(userRole!='Admin') && (item['Change_Status'] !='' && item['Change_Status'] !='Approved' && item['Change_Status'] !='Cancel' && item['Change_Status'] !='Draft' && item['Change_Status'] != undefined) ? true:null" />
                <input type="button"
                  *ngIf="(userRole!='Admin') && (item['Change_Status'] =='' || item['Change_Status'] =='Approved' || item['Change_Status'] =='Cancel' || item['Change_Status'] == undefined)"
                  class="btn btn-primary" [routerLink]="['../asset-request-change']"
                  [queryParams]="{ assetid: item['Asset_Number'] }" value="Request Asset Change" />
                <input type="button" *ngIf="(userRole!='Admin') && (item['Change_Status'] =='Draft')"
                  class="btn btn-primary"
                  [routerLink]="[(item['Change_Type'] !='Retired') ? '../action-plan':'../action-plan-retire']"
                  [queryParams]="{ assetid: item['Asset_Number'] }" value="Action Plan"
                  [attr.disabled]="(item['Change_Status'] =='Draft') ? null:true"
                  [attr.readonly]="(item['Change_Status'] =='Draft') ? null:true" />
                <mat-menu #appAdminDetailsMenu="matMenu">
                  <ng-template matMenuContent>
                    <div class="btn text-center text-danger w-100"><i class="fa fa-laptop" aria-hidden="true"></i>
                      {{item['Asset_Number']}}</div>
                    <button mat-menu-item class="btn bg-primary text-white"
                      [routerLink]="['../asset-request-change']"
                      [queryParams]="{ assetid: item['Asset_Number'] }">
                      {{((item['Change_Type'] !='Retired') &&
                      (item['Change_Status'] =='Draft' || item['Change_Status']=='Pending Analyst Review')) ? 'Change in
                      Progress':'Request Asset Change'}}
                    </button>
                    <button mat-menu-item class="btn bg-primary text-white"
                      [routerLink]="['../retirement-request']"
                      [queryParams]="{ assetid: item['Asset_Number'] }">
                      {{((item['Change_Type'] =='Retired') && (item['Change_Status'] =='Draft' ||
                      item['Change_Status']=='Pending Analyst Review')) ? 'Change in Progress':'Retire an Asset'}}
                    </button>
                    <button
                      disabled="{{(item['Change_Status'] =='Draft' || item['Change_Status']=='Approved' || item['Change_Status']=='Pending Analyst Review') ? false:true}}"
                      mat-menu-item class="btn bg-primary text-white"
                      [routerLink]="[(item['Change_Type'] !='Retired') ? '../action-plan':'../action-plan-retire']"
                      [queryParams]="{ assetid: item['Asset_Number'] }">Action Plan </button>
                    <button mat-menu-item class="btn bg-primary text-white"
                      (click)="historyDialog(item['History'],item['Asset_Number'])">History</button>
                  </ng-template>
                </mat-menu>
                <mat-menu #appRequestMenu="matMenu">
                  <ng-template matMenuContent>
                    <div class="btn text-center text-danger w-100"><i class="fa fa-laptop" aria-hidden="true"></i>
                      {{item['Asset_Number']}}</div>
                    <div>
                      <button
                        *ngIf="(userRole!='Admin') && (item['Change_Type'] =='Retired') && (item['Change_Status'] =='Draft' || item['Change_Status']=='Pending Analyst Review')"
                        mat-menu-item class="btn bg-primary text-white"
                        [routerLink]="['../assetRequestChange/request-change-asset']"
                        [queryParams]="{ assetid: item['Asset_Number'] }">Request Asset Change</button>
                      <button mat-menu-item class="btn bg-primary text-white"
                        [routerLink]="['../assetRequestChange/retrimentRequest']"
                        [queryParams]="{ assetid: item['Asset_Number'] }">Retire an Asset
                      </button>
                      <button mat-menu-item class="btn bg-primary text-white"
                        (click)="historyDialog(item['History'],item['Asset_Number'])">History</button>
                    </div>
                  </ng-template>
                </mat-menu>
                <button mat-icon-button (click)="showHistoryDialog = true"
                  [matMenuTriggerFor]="(userRole=='Admin') ? appAdminDetailsMenu : appRequestMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </td>
              <ng-container *ngFor="let data of item | keyvalue: originalOrder">
                <td *ngIf="data.key!='Action_Plan_Status' && data.key!='History' && data.key !=='PartitionKey'">
                  <span *ngIf="data.key!='Checkbox_Data'">{{data.value}}</span>
                </td>
              </ng-container>
            </tr>
          </ng-container>
          <span *ngIf="noRecord">No AMD Records to Display!</span>
        </tbody>
      </div>
    </table>
  </div>
</div>

<div class="row">
  <div class="col-12 text-center mb-3">
    <button class="btn btn-primary mr-2 mb-2" (click)="saveAs()" *ngIf="userRole!='Requester'"
      [disabled]="printItem.length === 0">
      <i class="fa fa-download"></i>Download Labels for Printing
    </button>
    <button class="btn btn-primary mr-2 mb-2" (click)="downloadSpreadSheet(FinalAdminAssetData)"
      *ngIf="userRole!='Requester'" [disabled]="pageLength.length != 1">
      <i class="fa fa-download"></i>Download Bulk Basic/Transfer Template
    </button>

    <button class="btn btn-primary mr-2 mb-2" (click)="exportRetiredTemplate()" *ngIf="userRole!='Requester'">
      <i class="fa fa-download"></i>Download Bulk Retirement Template
    </button>

  </div>
</div>