import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
import { CommonService } from 'src/app/services/common.service';
import { ThemePalette } from '@angular/material/core';
import { DialogRef } from '@angular/cdk/dialog';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-common-assessment-form',
  templateUrl: './common-assessment-form.component.html',
  styleUrls: ['./common-assessment-form.component.scss']
})
 

export class CommonAssessmentFormComponent implements OnInit  {
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  questionArray2!: string[];
  SHReviewName!: string;
  actionItemArray!: string[];
  toggleRes!: string;
  SHReviewStatus!: FormGroup;
  emailDataDetails={};
  formResponse: any;
  sendFormVar : any;
  loading!: boolean;
  uniqueId!: string;
  userResponse: any;
  // answerArray: any;
  tableData: any;
  QAArray: any;
  fieldDisable!: boolean;
  showBtn: boolean = true;
  FAOriginalName: any;
  disableCancelBtn: boolean = true;
  showMandatePopup!: boolean;
  helpText!: string;
  LabfabTeams: any;
  SHNameData: any;
  commonInfo : any;
  popUpTxt!: string;
  abbreviatedSHName!: { key: string; value: string; }[];
  viewAttachedFiles:any;
  
  // questions coming as a Input from assessment-form-functionality
  @Input() questions!: any[];
  @Input() isOutermost: boolean = true;
  @Input() showAssessmentPopup!: boolean;
  // Sending the updated array to parent
  @Output() child_FormCommon_Assistment_Data: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() updatedTableData: EventEmitter<{userResponse: string[], commonInfo: string[]}>= new EventEmitter<{userResponse: string[], commonInfo: string[]}>();

 text_Multifeild_config: { textMultiField: {}[] } = {
    textMultiField: []
};
  editmode = false;
  sendDataToAssessment!: {};
  constructor(private common: CommonService, private fb: FormBuilder, private router: Router,
    private activatedRoute: ActivatedRoute, private dialog: MatDialog) {}
  

  ngOnInit(): void {
    this.userResponse =[];
    this.FAOriginalName = this.activatedRoute.snapshot.queryParams['FA'];
    //this.isOutermost = this.showpopup == true ? false : this.isOutermost
    console.log('show popup', this.showAssessmentPopup)
    this.SHReviewStatus = this.fb.group({
      focusAreaName: '',
      focusAreaStatus: ''
    });

    this.sendFormVar = {
      labName: localStorage.getItem('InitiateLabFab'),
      focusArea: this.FAOriginalName,
      POR: this.activatedRoute.snapshot.queryParams['POR']
    }

    console.log('QAArray', this.questions)

}
// selectedQue.internalQuestions[0]['answer'][0] = ({})
clearInternalQuestions(questionsArray:any){
  //console.log('Internal Question', questionsArray);
    if(questionsArray.internalQuestions && questionsArray.internalQuestions.length > 0){
       let selectedQue = questionsArray.internalQuestions
       selectedQue.forEach((obj: { answer: string | {}[]; type: string; hasOwnProperty: (arg0: string) => any; })=>{
        obj.answer = obj.type=='textarea'? '': [{}]
        if(obj.hasOwnProperty('internalQuestions')){
          this.clearInternalQuestions(obj);
        }
       })
      this.clearInternalQuestions(questionsArray.internalQuestions);
    }
  // })
}

toggleChanges($event: MatRadioChange, name:any) {
  //let arrName = (({question, ...otherEle})=> otherEle)(name);
  let arrName = name;
  arrName['answer'] = $event.value;
  // initationally the conditional flag hide all the internal questions
 
  this.hideAllInnerQuest(arrName);
 
  //Default behaviour if Yes and Conditionalshow flag is not given.
  //Show all internal Question on YES
  let showInternalQuestions = arrName?.internalQuestions?.some((element: { [x: string]: boolean; conditionalShow: any; }) => { 
    if (element?.conditionalShow === undefined && arrName['answer']=='Yes') {
      element['conditionalQues'] = false // show based on condistion flag quest
      arrName['showSubQue'] =  true
      arrName?.internalQuestions?.forEach((element: { [x: string]: boolean; }) => {
        element['conditionalQues'] = false 
      });
      //this.clearInternalQuestions(arrName);
      return true
    } 
     // Based on the conditional flag  internal questions willbe shown
    else if (element?.conditionalShow === arrName['answer']) {
      console.log(arrName['answer'])
      arrName['showSubQue'] =  true
      element['conditionalQues'] = false // show based on condistion flag quest
      //this.clearInternalQuestions(arrName);
      return true
    }
     else {
      element['conditionalQues'] = true // hide
      arrName['showSubQue'] =  false
      this.clearInternalQuestions(arrName);
      return false;
    }
  });

  // Based on parent answers show the sub question based on the key "conditionalShow"
  if(showInternalQuestions){
    arrName['showSubQue'] =  true
  }
  else {
    arrName['showSubQue'] =  false;
    this.clearInternalQuestions(arrName);
  }
  this.userResponse.push(arrName);
  this.userResponse = [...new Set(this.userResponse)]
  this.updatedTableData.emit({userResponse: this.userResponse, commonInfo: this.commonInfo})
}
// //Hide all internal questions for radio type
hideAllInnerQuest(arrName:any){
  arrName?.internalQuestions?.forEach((element: { [x: string]: boolean; internalQuestions: any; }) => {
    element['conditionalQues'] = true;
    if(element.internalQuestions){
      this.hideAllInnerQuest(element);
    }
  });
}
getQuestionName(item: any){
  const isQueRequired = (item['required'])? '<span class="redColor" aria-required="true" > * </span>': ''
  return `${item['question']}${isQueRequired}`
}

valueFromChildCapInfo(data:any){
  console.log("child data in ace",data)
  this.commonInfo = data['commonInfo']
  this.updatedTableData.emit({userResponse: this.userResponse, commonInfo: this.commonInfo})
}


emitUpdatedTableData(updatedTableData:any){
  console.log("Print table Data updated", updatedTableData);
  this.updatedTableData.emit({userResponse: this.userResponse, commonInfo: this.commonInfo})
}

textBoxVal($event:any, name:any) {
  let arrName = name;
  arrName['answer'] = $event.target?.value ? $event.target.value : $event.value;
  if (arrName['answer'] == "") {
    this.userResponse?.filter((ele: { [x: string]: any; }, index: any) => {
      if (ele['questionId'] == arrName['questionId']) {
        this.userResponse.splice(index, 1)
      }
    });
    this.tableData['UserResponse_Array']?.filter((ele: { [x: string]: any; }, index: any) => {
      if (ele['questionId'] == arrName['questionId']) {
        this.tableData['UserResponse_Array'].splice(index, 1)
      }
    });
  }
  else{
    this.userResponse.push(arrName);
    this.userResponse = [...new Set(this.userResponse)]
  }
  this.updatedTableData.emit({userResponse: this.userResponse, commonInfo: this.commonInfo})
}
//Checkboxes changes

checkBoxChange(name:any) {
  let arrName = name;
  //If any of the checkbox is checked then it not required else required
//  let ChekBoxReq = arrName?.choices?.some(item => item['checked'])
  // if(ChekBoxReq){
  //   arrName.required = false
  // }else{
  //   arrName.required = true
  // }

  let checkedOptions = arrName?.choices?.filter((item: { [x: string]: any; }) => item['checked'])
  if(checkedOptions){
    arrName['answer'] = checkedOptions
  }
 
    // Based on parent answers show the sub question based on the key "conditionalShow"  
  arrName['showSubQue'] =  true
    arrName?.internalQuestions?.forEach((obj: { [x: string]: boolean; conditionalShow: any; }) => {
      arrName?.choices?.some((item: { [x: string]: any; }) => {
        // if(item['checked']){
        //   arrName.required = false
        // }
        if(item['checked'] && item['label']==obj?.conditionalShow){
          obj['conditionalQues'] = false 
          //console.log(obj,item,"TRUE")
          return true;
        }
        else{
         // console.log(obj,item)
          obj['conditionalQues'] = true // hide
          // let clearObjArr = []
          // clearObjArr['internalQuestions'] = obj
          //this.clearInternalQuestions(clearObjArr);
          return false;
        }
      })
    })
    this.userResponse.push(arrName);
    this.userResponse = [...new Set(this.userResponse)]
    this.updatedTableData.emit({userResponse: this.userResponse, commonInfo: this.commonInfo})
}

//Multi Text label added in child component responses
addLabeltextChild(multiTextData:any, queData:any){
  let arrName = queData
  arrName['answer'] = multiTextData
  if (arrName['answer'] == "") {
    this.userResponse?.filter((ele: { [x: string]: any; }, index: any) => {
      if (ele['questionId'] == arrName['questionId']) {
        this.userResponse.splice(index, 1)
      }
    });
    this.tableData['UserResponse_Array']?.filter((ele: { [x: string]: any; }, index: any) => {
      if (ele['questionId'] == arrName['questionId']) {
        this.tableData['UserResponse_Array'].splice(index, 1)
      }
    });
  }
  else{
    this.userResponse.push(arrName);
    this.userResponse = [...new Set(this.userResponse)]
  }
  console.log(multiTextData)
  this.updatedTableData.emit({userResponse: this.userResponse, commonInfo: this.commonInfo})
}

// mutli text field start
addTextField() {
  this.text_Multifeild_config['textMultiField'].push({
    label:'',
    answer:''
  });

  console.log("added",this.text_Multifeild_config['textMultiField'])
}

removeTextField(index:number) {
  this.text_Multifeild_config['textMultiField'].splice(index, 1);
}
// mutli text field ENDS

editTextField(index:any){
  this.editmode = true;
}
saveTextField(){
  this.editmode = false;
}

cancelTextField(){
  this.editmode = false;
}

// add the child files 
//   addChildForm1(FileData: string, group: FormGroup, name) {
//    let fileCheckVal =  group['Name']?group['Name']:group.value['Name']
//   if (fileCheckVal!='') {
//     let arrName = name;
//     arrName['answer'].push(group);
//     this.userResponse.push(arrName);
//     this.userResponse = [...new Set(this.userResponse)]
//     this.updatedTableData.emit({userResponse: this.userResponse, commonInfo: this.commonInfo})
//   }
 
// }

// let arrName = name;
//   arrName['answer'] = $event.target?.value ? $event.target.value : $event.value;
//   if (arrName['answer'] == "") {
//     this.userResponse?.filter((ele, index) => {
//       if (ele['questionId'] == arrName['questionId']) {
//         this.userResponse.splice(index, 1)
//       }
//     });
//     this.tableData['UserResponse_Array']?.filter((ele, index) => {
//       if (ele['questionId'] == arrName['questionId']) {
//         this.tableData['UserResponse_Array'].splice(index, 1)
//       }
//     });
//   }
//   else{
//     this.userResponse.push(arrName);
//     this.userResponse = [...new Set(this.userResponse)]
//   }
//   this.updatedTableData.emit({userResponse: this.userResponse, commonInfo: this.commonInfo})

// add the child files 
addChildForm(capUpload: string, group: FormGroup, queData: { [x: string]: any[]; }) {
    let arrName = queData;
    console.log("group", group);
    this.viewAttachedFiles = [];
  
    if (queData['answer'].length > 0) {
      queData['answer'].forEach(ele => this.viewAttachedFiles.push(ele));
      this.viewAttachedFiles = [...new Set(this.viewAttachedFiles)];
    }
  
    // Access the 'Name' control from the FormGroup
    const groupName = group.controls['Name']?.value;
  
    if (groupName && groupName !== "") {
      this.viewAttachedFiles.push(group);
      console.log("array check", this.viewAttachedFiles, Array.isArray(this.viewAttachedFiles));
      
      this.viewAttachedFiles = this.viewAttachedFiles?.reduce((unique: any[], o: { Name: any; }) => {
        if (!unique.some(obj => obj?.Name === o?.Name)) {
          unique.push(o);
        }
        return unique;
      }, []);
      
      console.log("this.viewAttachedFiles", Array.isArray(this.viewAttachedFiles), this.viewAttachedFiles);
      arrName['answer'] = this.viewAttachedFiles;
      this.userResponse.push(arrName);
      this.userResponse = [...new Set(this.userResponse)];
      this.updatedTableData.emit({ userResponse: this.userResponse, commonInfo: this.commonInfo });
    }
  
    // Uncomment and handle file upload logic if needed
    // if (FileData == 'capUpload' && group['IsFileUploaded'] == true) {
    //   // alert('test upload')
    // }
  }
  

ChangeFields(event: any,data: any){
  console.log(data)
}

openDialogInputField() {
  this.helpText='';
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = false;
  dialogConfig.ariaLabelledBy = "text"
  dialogConfig.maxWidth = '35vw',
  dialogConfig.maxHeight = '55vh',
  dialogConfig.height = '85%',
  dialogConfig.width = '85%',
  dialogConfig.data = {
    showInput: true,
    title: 'Help Notification',
    // errorMessage: 'Please enter the assessment before click on Submit',
    commentText: this.helpText,
    inputLabel: 'Assessment Question',
    textHeading: 'Enter assessment question for which help is required'
  }
  const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
  dialogRefSubmit.componentInstance.onSubmit.subscribe({
    next: (result: string | undefined) => {
      if (result == undefined || result == "") {
        dialogConfig.data['errorMessage'] = 'Please enter the assessment before click on Submit';
      }
      else {
        dialogRefSubmit.close();
        this.helpText = result
        this.openDialogMessage('The Focus Area Stakeholder has been notified that you need help with this assessment. Please look for that notification in your email.', '')
      }
    },
    error: (error: any) => {
      console.error("Error occurred:", error);
      alert("Please connect with Asset Admin");
    }
  });

}

openDialogMessage(message:string, assetID:string) {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = false;
  dialogConfig.ariaLabelledBy = "text"
  dialogConfig.maxWidth = '35vw',
    dialogConfig.maxHeight = '40vh',
    dialogConfig.height = '85%',
    dialogConfig.width = '85%',
    dialogConfig.data = {
      showInput: false,
      message: message,
      tabletemplate: true,
    }
  // this.dialog.open(DialogBoxComponent, dialogConfig);
  const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
  dialogRefSubmit.afterClosed().subscribe({
    next: (result) => {
      console.log(`Dialog result: ${result}`);
      this.common.getById('amd-get-labfabbyid/',this.sendFormVar['labName'].replace('/', '&#47;')).subscribe({
        next: (data) => {
          console.log("LABFAB data", data);
          if (data) {
            this.LabfabTeams = data[0];
            let focusAreaName = 'Automated Equipment Control'
            this.SHNameData = this.LabfabTeams[focusAreaName.replace(/ /g, '_')];
            console.log('SHNameData', this.SHNameData)
            this.emailDataDetails = {
              FAPrimary: this.SHNameData['Primary'],
              FASecondary: this.SHNameData['Secondary'],
              FAWatcher: this.SHNameData['Watcher'],
              RequesterID: this.commonInfo['RequesterName'],
              SecondaryRequesterID: this.commonInfo['SecondaryReq'],
              comments: this.helpText,
              LabName: localStorage.getItem('InitiateLabFab'),
              FocusArea: localStorage.getItem('focusAreaName'),
              por_number: localStorage.getItem('POR'),
              functionName: 'Help',
              FocusAreaFullName: focusAreaName,
            }
          }
          this.common.postCapAPI('itg/cap-email-notification-itg',this.emailDataDetails).subscribe({
            next: (ele2: any) => {
              console.log('Print data', ele2);
            },
            error: (error: any) => {
              console.error("Error occurred:", error);
              alert("Please connect with Asset Admin");
            }
          })
        },
        error: (error: any) => {
          console.error("Error occurred:", error);
          alert("Please connect with Asset Admin");
        }
      });
    },
    error: (error) => {
      console.error("Error occurred:", error);
      alert("Please connect with Asset Admin");
    }
  })
}

closeDialog(){
  this.dialog.closeAll();
}

// printTable(){
//   window.print();
// }

}
