import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  token!: string;
  constructor(private injector: Injector, private common: CommonService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    this.token = environment.token;

    // const expiryDate = new Date(0); // The epoch time
    // const expTime = JSON.parse(atob(this.token.split('.')[1])).exp;
    // expiryDate.setUTCSeconds(expTime);

    // if (expiryDate < new Date()) {
    //   localStorage.setItem('tokenValidity', 'expired');
    //   this.common.show('error', 
    //     'Your session has expired. Please log in again.', '', 401); // Show a message to the user
    //   this.common.logout(); // If the token is expired, log out the user
    // }

    const tokenReq = req.clone({
      setHeaders: {
        authorization: this.token,
      }
    });

    return next.handle(tokenReq);
    // token: string;
    // constructor(private injector: Injector, private common: CommonService) {
    //   this.token = this.common.token;
    // }

    // intercept(req: HttpRequest<any>, next: HttpHandler): any {
    //   const tokenReq = req.clone({
    //     setHeaders: {
    //       authorization: this.token,
    //     }
    //   });
    //   return next.handle(tokenReq);
  }
}
