<mat-card>
  <mat-card-content>
    <form [formGroup]="capDetails">
      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>POR Status</mat-label>
            <!-- <mat-select formControlName="POR_Status" (selectionChange)="statusSelected($event)"> -->
            <mat-select formControlName="POR_Status">
              <mat-option value="">Select POR Status</mat-option>
              <mat-option *ngFor="let val of allPORStatus" [value]="val">{{val}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>POR Number</mat-label>
            <input matInput type="text" formControlName="POR_Num" [matAutocomplete]="auto"
              (change)="numberChange($event)" (click)="showPORList('PORNumber')">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
                (click)="showPORList('PORNumber')">
                {{option}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>Requestor</mat-label>
            <input matInput type="text" formControlName="requestor" (input)="sendNewValue('requestor',$event)">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>Planner</mat-label>
            <input matInput type="text" formControlName="planner" (input)="sendNewValue('planner',$event)">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>Project Manager</mat-label>
            <input matInput type="text" formControlName="projectManager"
              (input)="sendNewValue('projectManager',$event)">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>Commit Manager</mat-label>
            <input matInput type="text" formControlName="commitManager" (input)="sendNewValue('commitManager',$event)">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>Depreciation Manager</mat-label>
            <input matInput type="text" formControlName="depreciationManager"
              (input)="sendNewValue('depreciationManager',$event)">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>Commit Quarter</mat-label>
            <input matInput type="text" formControlName="commitQuarter" (input)="sendNewValue('commitQuarter',$event)">
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field *ngIf="this.showActiveFields" class="w-100">
            <mat-label>Requesting Site</mat-label>
            <input matInput type="text" formControlName="requestingSite"
              (input)="sendNewValue('requestingSite',$event)">
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field *ngIf="this.showActiveFields" class="w-100">
            <mat-label>Request Date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="requestDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="button-row">
        <div class="col text-center">
          <button class="btn btn-primary" (click)="submitPOR('submit')">Submit</button>
          <button class="btn btn-primary" (click)="submitPOR('showPOR')" [disabled]="true">Show all POR data</button>
          <button class="btn btn-primary" (click)="exportData()"><i class="fa fa-file-excel"></i> Export </button>
        </div>
      </div>

      <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px" class="button-row">
        <button class="btn btn-primary" (click)="submitPOR('submit')">Submit</button>
        <button class="btn btn-primary" (click)="submitPOR('showPOR')" [disabled]="true">Show all POR data</button>
        <button class="btn btn-primary" (click)="exportData()">
          <mat-icon>file_download</mat-icon> Export
        </button>
      </div> -->

      <!-- <div class="col-md-12" *ngIf="loading">
        <mat-spinner diameter="20"></mat-spinner>
        <span>Loading Data...</span>
      </div> -->

    </form>
  </mat-card-content>
</mat-card>


<!-- <input type="button" value = 'Show all POR data' class="btn btn-primary" style="margin-bottom: 1%; float:right"
 (click)="submitPOR('showPOR')" /> -->

<br>

<div class="mat-elevation-z8">
  <button class="btn btn-primary mt-3 mr-3" (click)="collapse()" style="margin-left: 1%; float:right; margin-bottom:1%">
    Collapse All </button>
  <div class="table-responsive dataTable">
    <table *ngIf="this.showAction == true" class="table table-striped table-responsive-md btn-table bs-select"
      style="max-height: 60vh; overflow:auto">

      <thead class="custfont orange-header">
        <tr class="appTableHeader">
          <th class="table-head">
            <div *ngIf="this.showAction == true">
              <span>Action</span>&nbsp;
            </div>
          </th>

          <!-- <ng-container *ngFor="let title of tableHeaderArray; index as i"> -->
          <ng-container>
            <th class="table-head">
              <div>
                <span>LTP#</span>&nbsp;
              </div>
            </th>
            <th class="table-head">
              <div>
                <span>LTP Status</span>&nbsp;
              </div>
            </th>
            <th class="table-head">
              <div>
                <span>POR#</span>&nbsp;
              </div>
            </th>
            <th class="table-head">
              <div>
                <span>POR Status</span>&nbsp;
              </div>
            </th>
            <th class="table-head">
              <div>
                <span>CAP#</span>&nbsp;
              </div>
            </th>
            <th class="table-head">
              <div>
                <span>CAP Status</span>&nbsp;
              </div>
            </th>

            <!-- WBS -->
            <th class="table-head">
              <span>
                <button type="submit" class="btn btn-primary btn-search upload-btn appColor"
                  style="padding: 0px 5px; border: 1px solid black !important; margin-right: 5%"
                  (click)="changeIcon('WBS')">
                  <i *ngIf="WBS" class="fa fa-plus" style="color:white; font-size:12px;"></i>
                  <i *ngIf="!WBS" class="fa fa-minus" style="color:white"></i>
                </button>WBS
              </span>
              <div>
                <span style="margin-left:18%;">WBS#</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!WBS">
              <div>
                <span>WBS Status</span>&nbsp;
              </div>
            </th>
            <!-- <th class="table-head" *ngIf="!WBS">
              <div>
                <span>WBS Amount</span>&nbsp;
              </div>
            </th> -->

            <!-- POR Info -->
            <th class="table-head">
              <span>
                <button type="submit" class="btn btn-primary btn-search upload-btn appColor"
                  style="padding: 0px 5px; border: 1px solid black !important; margin-right: 5%"
                  (click)="changeIcon('PORInfo')">
                  <i *ngIf="PORInfo" class="fa fa-plus" style="color:white; font-size:12px;"></i>
                  <i *ngIf="!PORInfo" class="fa fa-minus" style="color:white"></i>
                </button>POR Info
              </span>
              <div>
                <span style="margin-left:18%;">Planner</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!PORInfo">
              <div>
                <span>POR Description</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!PORInfo">
              <div>
                <span>POR Driver</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!PORInfo">
              <div>
                <span>POR Project Manager</span>&nbsp;
              </div>
            </th>

            <!-- Tool Information -->
            <th class="table-head">
              <span>
                <button type="submit" class="btn btn-primary btn-search upload-btn appColor"
                  style="padding: 1px 5px; border: 1px solid black !important" (click)="changeIcon('ToolInformation')">
                  <i *ngIf="ToolInformation" class="fa fa-plus" style="color:white"></i>
                  <i *ngIf="!ToolInformation" class="fa fa-minus" style="color:white"></i>
                </button>Tool Information
              </span>
              <div>
                <span>Tool Name</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!ToolInformation">
              <div>
                <span>Requester Email</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!ToolInformation">
              <div>
                <span>Secondary Requester</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!ToolInformation">
              <div>
                <span>Requesting Site</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!ToolInformation">
              <div>
                <span>Request Date</span>&nbsp;
              </div>
            </th>

            <!-- Asset -->
            <th class="table-head">
              <span>
                <button type="submit" class="btn btn-primary btn-search upload-btn appColor"
                  style="padding: 1px 5px; border: 1px solid black !important" (click)="changeIcon('Asset')">
                  <i *ngIf="Asset" class="fa fa-plus" style="color:white"></i>
                  <i *ngIf="!Asset" class="fa fa-minus" style="color:white"></i>
                </button>Asset
              </span>
              <div>
                <span>Depreciation Manager</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!Asset">
              <div>
                <span>Depreciation Location</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!Asset">
              <div>
                <span>Asset#</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!Asset">
              <div>
                <span>Asset Life (yrs)</span>&nbsp;
              </div>
            </th>

            <!-- Org Hierarchy -->
            <th class="table-head">
              <span>
                <button type="submit" class="btn btn-primary btn-search upload-btn appColor"
                  style="padding: 1px 5px; border: 1px solid black !important" (click)="changeIcon('OrgHierarchy')">
                  <i *ngIf="OrgHierarchy" class="fa fa-plus" style="color:white"></i>
                  <i *ngIf="!OrgHierarchy" class="fa fa-minus" style="color:white"></i>
                </button>Org Hierarchy
              </span>
              <div>
                <span>POR Commit Manager</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!OrgHierarchy">
              <div>
                <span>Commit Location</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!OrgHierarchy">
              <div>
                <span>Cost Object</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!OrgHierarchy">
              <div>
                <span>MRU</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!OrgHierarchy">
              <div>
                <span>Level2</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!OrgHierarchy">
              <div>
                <span>Level3</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!OrgHierarchy">
              <div>
                <span>Business Unit</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!OrgHierarchy">
              <div>
                <span>Organization</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!OrgHierarchy">
              <div>
                <span>Section</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!OrgHierarchy">
              <div>
                <span>Rollup</span>&nbsp;
              </div>
            </th>

            <!-- Schedule Information -->
            <th class="table-head">
              <span>
                <button type="submit" class="btn btn-primary btn-search upload-btn appColor"
                  style="padding: 1px 5px; border: 1px solid black !important"
                  (click)="changeIcon('ScheduleInformation')">
                  <i *ngIf="ScheduleInformation" class="fa fa-plus" style="color:white"></i>
                  <i *ngIf="!ScheduleInformation" class="fa fa-minus" style="color:white"></i>
                </button>Schedule Information
              </span>
              <div>
                <span>Commit(QTR)</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!ScheduleInformation">
              <!-- <input type="checkbox" [checked]="!Commit" (change)="Commit=!Commit"/>  -->
              <div>
                <span>RTE(QTR)</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!ScheduleInformation">
              <div>
                <span>RTO(QTR)</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!ScheduleInformation">
              <div>
                <span>Setup Due Date</span>&nbsp;
              </div>
            </th>

            <!-- Finance Information -->
            <th class="table-head">
              <span>
                <button type="submit" class="btn btn-primary btn-search upload-btn appColor"
                  style="padding: 1px 5px; border: 1px solid black !important"
                  (click)="changeIcon('FinanceInformation')">
                  <i *ngIf="FinanceInformation" class="fa fa-plus" style="color:white"></i>
                  <i *ngIf="!FinanceInformation" class="fa fa-minus" style="color:white"></i>
                </button>Finance Information
              </span>
              <div>
                <span>Total Cost($K)</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!FinanceInformation">
              <div>
                <span>Item Cost($K)</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!FinanceInformation">
              <div>
                <span>Hookup Cost($K)</span>&nbsp;
              </div>
            </th>
            <th class="table-head" *ngIf="!FinanceInformation">
              <div>
                <span>NRE Cost ($K)</span>&nbsp;
              </div>
            </th>
            <th class="table-head">
              <div>
                <span>Additional Cost($K)</span>&nbsp;
              </div>
            </th>
          </ng-container>
        </tr>
      </thead>

      <tbody>
        <ng-container>
          <!-- <div class="spinLoader" *ngIf="loading">
         <i class="fa fa-spinner fa-spin"></i>&nbsp;
         <span>Loading Data...</span>
       </div> -->
          <tr *ngFor="let item of FinalCapData">
            <div
              *ngIf="(this.userRole == 'Requester' && (item['RequesterName'] != this.userName)) &&
                 (item['PORStatus'] =='Active' || item['PORStatus'] =='Complete' || item['PORStatus'] =='Canceled'); else elseBlock">
              <input type="button" class="btn btn-primary m-Top" (click)="btnClick(item['PORStatus'], item)"
                value="View" />&nbsp;
            </div>
            <!-- *ngIf="this.userRole == 'StakeHolder' || (item['RequesterName'] == this.userName) || this.userRole == 'Admin' || item['PORStatus'] =='In Queue'" -->
            <ng-template #elseBlock>
              <input type="button" class="btn btn-primary m-Top ml-3" (click)="btnClick(item['PORStatus'], item)"
                value="Edit" />&nbsp;
            </ng-template>

            <ng-container>
              <td>
                <span>{{item['LTP']}}</span>
              </td>
              <td>
                <span>{{item['LTPStatus']}}</span>
              </td>
              <td>
                <span>{{item['POR']}}</span>
              </td>
              <td>
                <span>{{item['PORStatus']}}</span>
              </td>
              <td>
                <span>{{item['CAP']}}</span>
              </td>
              <td>
                <span>{{item['CapStatus']}}</span>
              </td>

              <!-- WBS -->
              <td>
                <span>{{item['WBS']}}</span>
              </td>
              <td *ngIf="!WBS">
                <span>{{item['WBSStatus']}}</span>
              </td>
              <td *ngIf="!WBS">
                <span>{{item['WBSAmount']}}</span>
              </td>

              <!-- POR Info -->
              <td>
                <span>{{item['Planner']}}</span>
              </td>
              <td *ngIf="!PORInfo">
                <span>{{item['Description']}}</span>
              </td>
              <td *ngIf="!PORInfo">
                <span>{{item['PORDriver']}}</span>
              </td>
              <td *ngIf="!PORInfo">
                <span>{{item['PORProjectManager']}}</span>
              </td>

              <!-- Tool Information -->
              <td>
                <span>{{item['ToolName']}}</span>
              </td>
              <td *ngIf="!ToolInformation">
                <span>{{item['RequesterName']}}</span>
              </td>
              <td *ngIf="!ToolInformation">
                <span>{{item['SecondaryReqEmail']}}</span>
              </td>
              <td *ngIf="!ToolInformation">
                <span>{{item['RequestingSite']}}</span>
              </td>
              <td *ngIf="!ToolInformation">
                <span>{{item['RequestDate']}}</span>
              </td>

              <!-- Asset -->
              <td>
                <span>{{item['DepreciationManager']}}</span>
              </td>
              <td *ngIf="!Asset">
                <span>{{item['DepreciationLocation']}}</span>
              </td>
              <td *ngIf="!Asset">
                <span>{{item['Asset']}}</span>
              </td>
              <td *ngIf="!Asset">
                <span>{{item['AssetLife']}}</span>
              </td>

              <!-- Org Hierarchy -->
              <td>
                <span>{{item['PORCommitManager']}}</span>
              </td>
              <td *ngIf="!OrgHierarchy">
                <span>{{item['CommitLocation']}}</span>
              </td>
              <td *ngIf="!OrgHierarchy">
                <span>{{item['CostObject']}}</span>
              </td>
              <td *ngIf="!OrgHierarchy">
                <span>{{item['MRU']}}</span>
              </td>
              <td *ngIf="!OrgHierarchy">
                <span>{{item['Level2']}}</span>
              </td>
              <td *ngIf="!OrgHierarchy">
                <span>{{item['Level3']}}</span>
              </td>
              <td *ngIf="!OrgHierarchy">
                <span>{{item['BusinessUnit']}}</span>
              </td>
              <td *ngIf="!OrgHierarchy">
                <span>{{item['Organization']}}</span>
              </td>
              <td *ngIf="!OrgHierarchy">
                <span>{{item['Section']}}</span>
              </td>
              <td *ngIf="!OrgHierarchy">
                <span>{{item['Rollup']}}</span>
              </td>

              <!-- Schedule Information -->
              <td>
                <span>{{item['Commit']}}</span>
              </td>
              <td *ngIf="!ScheduleInformation">
                <span>{{item['RTE']}}</span>
              </td>
              <td *ngIf="!ScheduleInformation">
                <span>{{item['RTO']}}</span>
              </td>
              <td *ngIf="!ScheduleInformation">
                <span>{{item['SetupDueDate']}}</span>
              </td>

              <!-- Finance Information -->
              <td>
                <span>{{item['TotalCost']}}</span>
              </td>
              <td *ngIf="!FinanceInformation">
                <span>{{item['ItemCost']}}</span>
              </td>
              <td *ngIf="!FinanceInformation">
                <span>{{item['HookupCost']}}</span>
              </td>
              <td *ngIf="!FinanceInformation">
                <span>{{item['NRECost']}}</span>
              </td>

              <!-- Additional Cost -->
              <td>
                <span>{{item['AdditionalCost']}}</span>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<!-- <app-material-table *ngIf="this.loadPORDetails" [tableData]="capPORData1" [columnHeader]="columnHeader1">
 </app-material-table>
 <app-material-table *ngIf="this.loadPORDetails" [tableData]="tableData" [columnHeader]="columnHeader">
 </app-material-table> -->