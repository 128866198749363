import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { adminAssetData } from 'src/app/models/adminAssetData';
import * as XLSX from 'xlsx';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { HistoryComponent } from '../history/history.component';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';
import { SaveAsAssetComponent } from '../save-as-asset/save-as-asset.component';
import { ExportS4Component } from '../export-s4/export-s4.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-app-detail',
  templateUrl: './app-detail.component.html',
  styleUrls: ['./app-detail.component.css']
})
export class AppDetailComponent implements OnInit {
  @ViewChild('Import_Bulk_Data') Import_Bulk_Data!: ElementRef;
  assetDetails: any;
  headers: any;
  dataSource: any;
  IsdisableEdit!: boolean;
  displayedColumns = ['userId'];
  buildings: any = [];
  arr1: any = [];
  arr2: any = [];
  arr3: any = [];
  adminAssetData: any;
  FinalAdminAssetData: any;
  Updated_FinalAdminAssetData: any = []
  flag = false;
  ascendingsort: boolean | null = true;
  submitted = false;
  columnindex: any;
  currentPage: any;
  tableCheckAllBox = false;
  checkItem = [];
  asset: any = [];
  num = 0;
  value!: number;
  count!: number;
  filterhappens = false;
  gridData: any = [];
  pageLength: any = [];
  filterText: any = [];
  filterKey: any = [];
  loadingExport = false;
  loaded: any;
  tableHeaderArray: any = [];
  rowCount: any;
  assetdetails1: FormGroup = this.fb.group({
    Main_Asset_Number: [''],
    Asset_Location: [''],
    Additional_Description: [''],
    Asset_Supplementary_Inventory_Specifications_Name: [''],
    Cost_Center_Code: [''],
    Cost_Object_Manager_Email_ID: [''],
    Depreciation_Terms_Start_Date: ['']
  });
  show!: boolean;
  showGrid!: boolean;
  noRecord: boolean = false;
  isadmin!: boolean;
  excelData: any = [];
  progressUpload = false;
  chunkCount: number = 0;
  chunkSaved: boolean = false;
  chunkSize: number = 200;
  progress: any;
  chunkCompleted = 0;
  startedImporting = false;
  filter: any = {};
  historyData: any = [];
  showHistoryDialog!: boolean;
  pageOffset = 0;
  pageLimit = 100;
  userRole = this.common.getUserRole();
  subscriptions: Subscription[] = [];
  printItem: any = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private common: CommonService,
    private toastr: ToastrService
  ) {
    this.common.bannerData.next({ title: 'AMD', subtitle: 'Fixed Asset Changes' });
  }
  ngOnInit() {
    this.showHistoryDialog = false;
    this.assetDetails = null;
    this.IsdisableEdit = true;
    this.showGrid = true;
    this.show = false;
    this.isadmin = (this.common.getUserRole()) == 'Admin';
    if (this.common.getUserRole() == 'User') {
      this.IsdisableEdit = false;
    }
    if (this.userRole == 'Admin' || this.userRole) {
      this.showGrid = true;
      this.show = true;
      this.getAMDbuildings();

      this.showGrid = false;
      this.show = true;
      if (this.common.assetFilter != null) {
        this.showGrid = true;
        for (let i in this.common.assetFilter) {
          if (this.common.assetFilter[i] != "null") {
            this.filter[i] = this.common.assetFilter[i]
          } else {
            this.filter[i] = '';
          }
        }
        this.assetdetails1.patchValue(this.filter)
      }
    }
    else {
      this.common.loader.next(true);
      const data = { "Username": atob(this.common.currentUser), "Role": this.common.getUserRole() };
      this.common.postAPI('amd-get-user-data/', data).subscribe({
        next: ((data: any) => {
          this.asset = this.getUpdatedArray(data);
          this.rowCount = data.length
          this.paginationData(this.asset);
          this.common.loader.next(false);
        }),
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });
    }
  }

  getAMDbuildings() {
    this.common.loader.next(true);
    this.subscriptions.push(
      this.common.getAPI('amd-get-building/').subscribe(
        {
          next: (
            (data: any) => {
              this.buildings = data;
              for (let i = 0; i < this.buildings.length; i++) {
                let tmp: any = this.buildings[i]['Asset_Location'];
                this.arr1.push(tmp);
              }
              this.arr1 = this.arr1.filter(function (elem: any, index: any, self: any) {
                return index === self.indexOf(elem);
              })

              this.arr1.sort();
              this.common.loader.next(false);
            }
          ),
          error: (
            error => {
              this.common.errorHandler(error);
              this.common.loader.next(false);
            }
          )
        })
    );
  }

  GetSortOrder(prop: any) {
    return function (a: any, b: any) {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    }
  }
  SearchProduct() {
    this.arr2 = [];
    {
      for (let i = 0; i < this.buildings.length; i++) {
        if (this.buildings[i]['Asset_Location'] == this.assetdetails1.get("Asset_Location")?.value) {
          let tmp = this.buildings[i]['Lab_name'];
          this.arr2.push(tmp);
        }
      }
      this.arr2 = this.arr2.filter(function (elem: any, index: any, self: any) {
        return index === self.indexOf(elem);
      })
      this.arr2.sort();
      this.assetdetails1.get("Asset_Supplementary_Inventory_Specifications_Name")?.setValue("")
    }
  }

  onSubmit() {
    this.assetDetails = null;
    this.showGrid = false;
    if ((this.assetdetails1.get('Main_Asset_Number')?.value == "" &&
      this.assetdetails1.get('Asset_Location')?.value == "" &&
      this.assetdetails1.get('Additional_Description')?.value == "" &&
      this.assetdetails1.get('Asset_Supplementary_Inventory_Specifications_Name')?.value == "" &&
      this.assetdetails1.get('Cost_Center_Code')?.value == "" &&
      this.assetdetails1.get('Cost_Object_Manager_Email_ID')?.value == "" &&
      this.assetdetails1.get('Depreciation_Terms_Start_Date')?.value == ""
    ) || !this.filter) {
      this.common.show('warning', 'Please fill atleast one field to continue');
    }else {
      // Trim values and replace empty strings with "null", but handle the date separately
      Object.keys(this.assetdetails1.controls).forEach(key => {
        let value = this.assetdetails1.get(key)?.value ?? '';
    
        // Special handling for the 'Depreciation_Terms_Start_Date' field
        if (key === 'Depreciation_Terms_Start_Date') {
          if (value === "") {
            this.assetdetails1.get(key)?.setValue("null");
          }
        } else {
          // Trim other values and set "null" for empty fields
          value = value.trim();
          if (value === "") {
            this.assetdetails1.get(key)?.setValue("null");
          } else {
            this.assetdetails1.get(key)?.setValue(value);
          }
        }
      });

      if (this.assetdetails1.get('Depreciation_Terms_Start_Date')?.value && this.assetdetails1.get('Depreciation_Terms_Start_Date')?.value != 'null') {
        this.assetdetails1.value['Depreciation_Terms_Start_Date'] = moment(this.assetdetails1.get('Depreciation_Terms_Start_Date')?.value, 'MM/DD/YYYY').format('M/D/YYYY')
      }

      this.common.assetFilter = this.assetdetails1.value;
      
      this.getAdminAsset();
      Object.keys(this.assetdetails1.controls).forEach(key => {
        if (this.assetdetails1.get(key)?.value == "null") {
          this.assetdetails1.get(key)?.setValue("");
        }
      });
      this.show = true;
      this.showGrid = true;
    }

  }

  getAdminAsset() {
    this.common.loader.next(true);
    this.common.postAPI('amd-get-admin-asset/', this.assetdetails1.value).subscribe({
      next: (
        (data: any) => {
          this.asset = data['body'];
          let arr1 = this.asset.filter((amd: any) => (amd.SortKey == 'AMD'));
          let arr2 = this.asset.filter((amd: any) => (amd.SortKey == 'S4'));
          let finalAdminAssetArray = []
          for (let i = 0; i < arr1.length; i++) {
            for (let j = 0; j < arr2.length; j++) {
              if (arr2[j]['Asset Active Listing'] == 'Active' && arr1[i]['Asset Active Listing'] == 'Active' || arr2[j]['Asset Active Listing'] == 'Inactive' && arr1[i]['Asset Active Listing'] == 'Active') {
              }
              else {
                continue;
              }
              if (arr1[i]['Asset_Number'] === arr2[j]['Asset_Number']) {
                const loc: adminAssetData = {
                  Asset_Active_Listing: arr1[i]['Asset Active Listing'],
                  Company_Code: arr1[i]['Company Code'],
                  Asset_Number: arr1[i]['Asset_Number'],
                  Original_Asset_Number: arr2[j]['Original Asset Number'],
                  Asset_Class_Code: arr1[i]['Asset Class Code'],
                  Asset_Description: arr1[i]['Asset Description'],
                  Requester: arr1[i]['Requestor_ID'],
                  Owner: arr1[i]['Owner'],
                  Serial_Number: arr1[i]['Serial Number'],
                  LabFab_Name: arr1[i]['LabFab_Name'],
                  Building: arr1[i]['Building'],
                  Change_Status: arr1[i]['Change Status'],
                  Change_Type: arr1[i]['Change Type'],
                  Ship_to_Customer_Name1: arr2[j]['Ship-to Customer Name1'],
                  Asset_Capitalization_Date: arr2[j]['Asset Capitalization Date'],
                  Cost_Center_Code: arr1[i]['Cost Center Code'],
                  Cost_Object_Manager: arr1[i]['Cost_Object_Manager'],
                  MRU_Code: arr1[i]['MRU Code'],
                  Profit_Center_Code: arr2[j]['Profit Center Code'],
                  Cost_Center_Hier_Desc_Level1: arr2[j]['Cost Center Hier Desc Level1'],
                  Asset_Super_Number: arr2[j]['Asset Super Number'],
                  Depreciation_Terms_Start_Date: arr2[j]['Depreciation Terms Start Date'],
                  Depreciation_Terms_Useful_Life_in_Years: arr2[j]['Depreciation Terms Useful Life in Years'],
                  Depreciation_Terms_Useful_Life_in_Months: arr2[j]['Depreciation Terms Useful Life in Months'],
                  Acquisition_Value: arr2[j]['Acquisition Value'],
                  PE_Net_Book_Value: arr2[j]['PE Net Book Value'],
                  PE_Depreciation_For_Posted_Period: arr2[j]['PE Depreciation For Posted Period'],
                  Period_Posted_Ordinary_Depreciation_Including_Value_Adjustments: arr2[j]['Period Posted Ordinary Depreciation Including Value Adjustments'],
                  WBS_Element_Maintenance_Project: arr2[j]['WBS Element Maintenance Project'],
                  INSERT_DATETIME: arr2[j]['INSERT_DATETIME'],
                  MODIFIED_DATETIME: arr1[i]['MODIFIED_DATETIME'],
                  LAST_MODIFIED_USER: arr1[i]['Last Modified User'],
                  Action_Plan_Status: arr1[i]['Action_Plan_Status'],
                  History: arr1[i]['History']
                }
                finalAdminAssetArray.push(loc);
              }
            }
          }
          this.FinalAdminAssetData = finalAdminAssetArray
          this.asset = this.getUpdatedArray(this.FinalAdminAssetData);
          this.asset.sort(this.GetSortOrder("MODIFIED_DATETIME"))
          this.rowCount = this.FinalAdminAssetData.length
          if (this.rowCount === 0) {
            this.noRecord = true
          } else {
            this.noRecord = false
          }
          this.paginationData(this.asset)
          this.pageOffset = 0
          this.Updated_FinalAdminAssetData = this.FinalAdminAssetData.slice(this.pageOffset, this.pageLimit + this.pageOffset)
          this.pageOffset = this.pageLimit + this.pageOffset;
          this.assetdetails1.get("Asset_Location")?.setValue("");
          this.assetdetails1.get('Depreciation_Terms_Start_Date')?.setValue("");
          this.common.loader.next(false);
        }
      ),
      error: (
        error => {
          this.common.errorHandler(error);
          this.common.loader.next(false);
        }
      )
    });
  }
  pageNext(pageNumber: any) {
    this.currentPage = pageNumber
    let assetData = this.gridData;
    if (pageNumber === this.pageLength.length) {
      let len = pageNumber * 500
      let index = len - 500
      this.assetDetails = assetData.slice(index, assetData.length);
      this.FinalAdminAssetData = assetData.slice(index, assetData.length);
      this.Updated_FinalAdminAssetData = this.FinalAdminAssetData
    } else {
      let len = pageNumber * 500
      let index = len - 500
      this.assetDetails = assetData.slice(index, len);
      this.FinalAdminAssetData = assetData.slice(index, assetData.length);
      this.Updated_FinalAdminAssetData = this.FinalAdminAssetData
    }
  }
  paginationData(element: any) {
    this.pageLength = []
    this.gridData = element;
    let length = element.length / 500;
    if (length == 0) {
      this.pageLength[0] = 1;
    } else {
      for (let index = 0; index < length; index++) {
        this.pageLength[index] = index + 1;
      }
    }
    this.pageNext(1)
  }
  sortData(index: any, value: any) {
    let columnData: any = []
    for (let i = 0; i < this.gridData.length; i++) {
      columnData.push(this.gridData[i][value])
    }
    if (this.columnindex === index) {
      this.ascendingsort = !this.ascendingsort
    } else {
      this.columnindex = index
      this.ascendingsort = true
    }
    this.switchSortMethod(columnData, value)
  }
  switchSortMethod(columnData: any = [], value: any) {
    if (this.ascendingsort) {
      columnData.sort((a: any, b: any) => {
        if (typeof a === 'string' && typeof b === 'string') {
          return a.toLowerCase().localeCompare(b.toLowerCase(), 'en', { numeric: true })
        } else {
          return a - b;
        }
      });
      let sortedAsset = this.getSortedData(columnData, value)
      this.paginationData(sortedAsset)
    } else {
      columnData.sort((a: any, b: any) => {
        if (typeof a === 'string' && typeof b === 'string') {
          return b.toLowerCase().localeCompare(a.toLowerCase(), 'en', { numeric: true })
        } else {
          return b - a;
        }
      });
      let sortedAsset = this.getSortedData(columnData, value)
      this.paginationData(sortedAsset)
    }
  }
  getSortedData(sortedData: any, value: any) {
    sortedData.forEach((mainData: any, index: any) => {
      for (let i = index + 1; i < sortedData.length; i++) {
        if (sortedData[index] === sortedData[i]) {
          sortedData.splice(i, 1)
          i = i - 1
        }
      }
    });
    let finalAssetDetails: any = []
    sortedData.forEach((data: any) => {
      for (let i = 0; i < this.gridData.length; i++) {
        if (this.gridData[i][value] === data) {
          finalAssetDetails.push(this.gridData[i])
        }
      }
    });
    return finalAssetDetails;
  }
  edit(assetnumber: number) {
    sessionStorage['flag'] = true;
    if (this.userRole == 'Admin')
      this.router.navigate(['editasset'], { queryParams: { assetid: assetnumber } });
    if (this.userRole != 'Admin')
      this.router.navigate(['../assetRequestChange/request-change-asset'], { queryParams: { assetid: assetnumber } });
  }
  filterValue(inputData: any, indexValue: any, keyValue: any) {
    let filterData = this.asset;
    this.filterKey[indexValue] = keyValue;
    for (let index = 0; index < inputData.length; index++) {
      let tempFilterData = []
      if (inputData[index] != undefined && inputData[index] != '') {
        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i][this.filterKey[index]] != undefined) {
            if (filterData[i][this.filterKey[index]].toLowerCase().includes(inputData[index].toLowerCase())) {
              tempFilterData.push(filterData[i]);
            }
          }
        }
        filterData = tempFilterData;
      }
    }
    this.ascendingsort = null;
    this.Updated_FinalAdminAssetData = filterData;
  }
  checkUncheckAll() {
    let len = this.FinalAdminAssetData.length;
    for (let index = 0; index < len; index++) {
      this.FinalAdminAssetData[index].Checkbox_Data = this.tableCheckAllBox
    }
    this.pritnItems(this.FinalAdminAssetData)
  }
  originalOrder = (a: any, b: any) => { return 0 }
  getUpdatedArray(assetArray: any) {
    let columnOrder = ["Asset_Active_Listing", "Company_Code", "Asset_Number", "Original_Asset_Number", "Asset_Class_Code",
      "Asset_Description", "Requester", "Owner", "Serial_Number", "LabFab_Name",
      "Building", "Ship_to_Customer_Name1", "Asset_Capitalization_Date", "Cost_Center_Code",
      "Cost_Object_Manager", "MRU_Code", "Profit_Center_Code", "Cost_Center_Hier_Desc_Level1",
      "Asset_Super_Number", "Depreciation_Terms_Start_Date", "Depreciation_Terms_Useful_Life_in_Months",
      "Acquisition_Value", "PE_Net_Book_Value", "PE_Depreciation_For_Posted_Period", "Period_Posted_Ordinary_Depreciation_Including_Value_Adjustments",
      "WBS_Element_Maintenance_Project", "INSERT_DATETIME", "MODIFIED_DATETIME", "LAST_MODIFIED_USER", "Change_Type", "Change_Status"]
    this.tableHeaderArray = columnOrder;
    let finalAssetArray = []
    for (let i = 0; i < assetArray.length; i++) {
      let tempAssetArray: any = {}
      for (let j = 0; j < columnOrder.length; j++) {
        tempAssetArray[columnOrder[j]] = assetArray[i][columnOrder[j]];
      }
      tempAssetArray['PartitionKey'] = assetArray[i]['Main Asset Number'];
      tempAssetArray['Action_Plan_Status'] = assetArray[i]['Action_Plan_Status'];
      tempAssetArray['History'] = assetArray[i]['History'];
      finalAssetArray.push(tempAssetArray);
    }
    return finalAssetArray
  }
  printData(assetData: any) {
    this.common.setAssetData(assetData);
    this.router.navigate(['printasset']);
  }
  exportData(assetData: any) {
    this.common.loader.next(true);
    if (this.common.getUserRole() == 'Admin') {
      this.common.getAPI('amd-get-all-assets/').subscribe({
        next: (
          (data: any) => {
            let workBook = XLSX.utils.book_new()
            let workSheet = XLSX.utils.json_to_sheet(this.getUpdatedArray(data))
            XLSX.utils.book_append_sheet(workBook, workSheet, "Assets")
            XLSX.writeFile(workBook, "ExportedAssets.xlsx");
            this.common.loader.next(false);
          }
        ),
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });
    } else {
      let workBook = XLSX.utils.book_new()
      let workSheet = XLSX.utils.json_to_sheet(this.getUpdatedArray(assetData))
      XLSX.utils.book_append_sheet(workBook, workSheet, "Assets")
      XLSX.writeFile(workBook, "ExportedAssets.xlsx");
      this.common.loader.next(false);
    }
  }
  exportModified() {
    // this.router.navigate(['export-s4']);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ExportS4Component, dialogConfig);
  }
  importS4Asset(evt: any) {
    this.common.loader.next(true);
    this.startedImporting = true;
    let jsonData = null;
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
      jsonData = wb.SheetNames.reduce((initial, name) => {
        if (name == "Asset Valuation") {
          const sheet = wb.Sheets[name];
          this.excelData = this.excelData.concat(XLSX.utils.sheet_to_json(sheet, { range: 1, defval: "null" }));
        }
        return initial;
      }, {});
      for (let i in this.excelData) {
        this.num++
        replace(this.excelData[i])
        function replace(myObj: { [x: string]: any; }) {
          Object.keys(myObj).forEach(function (key) {
            if (myObj[key] instanceof Date) {
              if (myObj[key]) {
                let date = new Date(myObj[key]);
                let offSet = myObj[key].getTimezoneOffset();
                if (offSet > 0) {
                  date = new Date(date.getTime() + (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
                else {
                  date = new Date(date.getTime() - (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
              }
              else {
                myObj[key] = "";
              }
            }
            else {
              typeof myObj[key] == 'object' ? replace(myObj[key]) : myObj[key] = String(myObj[key]);
            }
          });
        }
      }
      var tmp = [];
      for (let i = 0; i < this.excelData.length; i++) {
        if (this.excelData[i]['Main Asset Number']) {
          tmp.push(this.excelData[i]['Main Asset Number']);
        } else {
          this.common.show('warning', 'Main Asset Number not found');
        }
      }
      this.common.importedAsset = tmp;
      this.chunkSize = this.excelData.length;
      this.chunkCount = (this.chunkSize % 300 === 0 ? (this.chunkSize / 300) : (Math.floor(this.chunkSize / 300) + 1));
      this.chunkSaved = false;
      this.callApi(0, 300);
    }
    reader.readAsBinaryString(target.files[0]);
  }
  callApi(start: number, end: number) {
    if ((this.chunkSize - start) < 300) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = this.excelData.slice(start, end);
      const data = { "Data": param }
      this.common.postAPI('amd-import-asset/', data).subscribe({
        next: (
          (data: any) => {
            this.chunkSaved = false;
            if (data['statusCode'] == 200) {
              this.common.loader.next(false);
              if (end < this.chunkSize) {
                this.callApi(start + 300,
                  (this.chunkSize - start) < 300 ? ((this.chunkSize - start) + start) : (end + 300));
              } else {
                this.startedImporting = false;
                this.common.show('success', 'File Imported Sucessfull');
                this.router.navigate(['report/comparisonreport']);
              }
            } else {
              this.common.show('error', 'Failed to import asset');
              this.common.loader.next(false);
            }
          }
        ),
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });
      this.chunkCompleted++;
      this.progress = ((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
    }
  }
  editBulk(assetData: any) {
    this.common.setAssetData(assetData);
    this.router.navigate(['edit-bulk-asset']);
  }

  saveAs() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = '75%';
    dialogConfig.width = '75%';
    dialogConfig.data = this.printItem;
    const dialogRef = this.dialog.open(SaveAsAssetComponent, dialogConfig);
  }


  exportTransfer() {
    this.loadingExport = true;
    this.common.loader.next(true);
    this.common.getAPI('amd-export-transfer/').subscribe({
      next: (
        (data: any) => {
          if (data.length != 0) {
            let columnOrder = ["Asset #", "Serial #s", "", "Co.Code_Old", "Asset #", "Asset Class", "From CC", "To CC",
              "Co.Code_New", "Region", "Text_AMD", "Retirement Date", "Acq Cost USD", "NBV USD", "Sending Manager email",
              "receiveing Manager Email", "Old Location", "New Location", "Retires Doc #", "", "", "New Asset #",
              "17XX DCL", "18XX DCL", "NBV DCL", "17XX Local", "18XX Local", "NBV Local", "17XX Dollar", "18XX Dollar",
              "NBV Dollar", "Text"];
            let workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet('Sheet1');
            let DateObj = new Date();
            let currentMonth = ('0' + (DateObj.getUTCMonth() + 1)).slice(-2)
            let docname = "Fixed Asset Transfers_" + currentMonth + DateObj.getFullYear();
            let titleValues1 = [""];
            let titleValues2 = [""];
            worksheet.spliceRows(1, 0, titleValues1, titleValues2);
            let headerRow = worksheet.addRow(columnOrder);
            for (let x1 of data) {
              let x2 = columnOrder;
              let temp = []
              for (let y of x2) {
                temp.push(x1[y])
              }
              worksheet.addRow(temp)
            }
            worksheet.getCell('A2').value = 'Tr Out';
            worksheet.getCell('D2').value = 'Tr Out';
            worksheet.getCell('E2').value = 'Tr Out';
            worksheet.getCell('F2').value = 'Tr Out';
            worksheet.getCell('G2').value = 'Tr Out';
            worksheet.getCell('H2').value = 'Tr IN';
            worksheet.getCell('I2').value = 'Tr IN';
            worksheet.getCell('J2').value = 'Tr IN';
            worksheet.getCell('L2').value = 'Transfer Date';
            worksheet.getCell('Q2').value = 'Asset Location';
            worksheet.getCell('R2').value = 'Asset Location';
            worksheet.getCell('S2').value = 'Updated by FA Team';
            worksheet.getCell('V2').value = 'Transfer IN Asset#';
            worksheet.getCell('D3').value = 'Co.Code';
            worksheet.getCell('I3').value = 'Co.Code';
            worksheet.getCell('K3').value = 'Text';
            worksheet.getRow(3).font = {
              name: 'Calibri',
              size: 11,
              bold: true,
            };
            ['Q2', 'R2'].map(key => {
              worksheet.getCell(key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '007bff' },
              };
            });
            ['R2', 'Q2'].map(key => {
              worksheet.getCell(key).font = {
                name: 'HP Simplified',
                size: 9,
                bold: true,
                color: { argb: 'ffffff' }
              };
            });
            ['Q3', 'R3'].map(key => {
              worksheet.getCell(key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffff00' },
              };
            });
            workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
              let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              fs.saveAs(blob, docname + '.xlsx');
            });
            this.loadingExport = false;
            this.common.loader.next(false);
          }
          else {
            this.loadingExport = false;
            this.common.loader.next(false);
            this.common.show('warning', "No transfered assets");
          }
        }
      ),
      error: (
        error => {
          this.common.errorHandler(error);
          this.common.loader.next(false);
        }
      )
    });
  }
  exportRetiredReclassified() {
    this.loadingExport = true;
    this.common.loader.next(true);
    this.common.getAPI('amd-export-retired-reclassified/').subscribe({
      next: (
        (data: any) => {
          if (data.length != 0) {
            let columnOrder = ["Company code", "Asset Number", "", "Sub Number", "Transaction Type",
              "Accounting principle", "Depreciation area", "Document Date", "Posting Date", "Asset value date",
              "Amount Posted", "Quantity", "Unit", "Text", "Trading Partner", "Posting Period", "Document Type",
              "Offsetting accounting", "Reference", "Assignment", "Partner Business area", "Amount (Dep 01)",
              "Ord Dep 01 (PY)", "Ord Dep 01 (CY)", "Unplanned Dep 01 (PY)", "Unplanned Dep 01 (CY)", "Amount (Dep 02)",
              "Ord Dep 02 (PY)", "Ord Dep 02 (CY)", "Unplanned Dep 02 (PY)", "Unplanned Dep 02 (CY)", "Amount (Dep 66)",
              "Ord Dep 66 (PY)", "Ord Dep 66 (CY)", "Unplanned Dep 66 (PY)", "Unplanned Dep 66 (CY)", "Amount (Dep 31)",
              "Ord Dep 31 (PY)", "Ord Dep 31 (CY)", "Unplanned Dep 31 (PY)", "Unplanned Dep 31 (CY)", "Amount (Dep 32)",
              "Ord Dep 32 (PY)", "Ord Dep 32 (CY)", "Unplanned Dep 32 (PY)", "Unplanned Dep 32 (CY)", "Amount (Dep 40)",
              "Ord Dep 40 (PY)", "Ord Dep 40 (CY)", "Unplanned Dep 40 (PY)", "Unplanned Dep 40 (CY)", "Amount (Dep 41)",
              "Ord Dep 41 (PY)", "Ord Dep 41 (CY)", "Unplanned Dep 41 (PY)", "Unplanned Dep 41 (CY)"];
            let workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet('Disposition');
            let titleValues1 = [""];
            let titleValues2 = [""];
            worksheet.spliceRows(1, 0, titleValues1, titleValues2);
            let headerRow = worksheet.addRow(columnOrder);
            let DateObj = new Date();
            let date = ('0' + (DateObj.getMonth() + 1)).slice(-2) + '/' + ('0' + DateObj.getDate()).slice(-2) + '/' + DateObj.getFullYear();
            let date1 = ('0' + (DateObj.getMonth() + 1)).slice(-2) + '/' + "01" + '/' + DateObj.getFullYear();
            let currentMonth = ('0' + (DateObj.getUTCMonth() + 1)).slice(-2)
            let docname = "Fixed_Asset_Retirements_and_Reclassificatons_" + currentMonth + DateObj.getFullYear();
            let data1 = [];
            for (let d1 of data) {
              d1['Document Date'] = date;
              d1['Asset value date'] = date1;
              let retireMonth = d1['Retirement Date']?.substring(0, 2)
              if (retireMonth == currentMonth) {
                data1.push(d1);
              }
            }
            for (let x1 of data1) {
              let x2 = columnOrder;
              let temp = []
              for (let y of x2) {
                temp.push(x1[y])
              }
              worksheet.addRow(temp)
            }
            worksheet.getCell('A1').value = 'Initial Screen';
            worksheet.getCell('B1').value = 'To be filled in by Requestor';
            worksheet.getCell('F1').value = 'Transaction Data';
            worksheet.getCell('J1').value = 'BI report date';
            worksheet.getCell('O1').value = 'Additional Details';
            worksheet.getCell('V1').value = 'Line item';
            worksheet.getCell('A2').value = 'This row is the BI report headers';
            worksheet.getCell('B2').value = 'Main Asset Number';
            worksheet.getCell('C2').value = 'Asset Class Code';
            worksheet.getRow(2).height = 32;
            worksheet.getRow(3).font = {
              name: 'Calibri',
              size: 11,
            };
            worksheet.getRow(3).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'ffe685' }
            };
            ['B2', 'C2'].map(key => {
              worksheet.getCell(key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '007bff' },
              };
            });
            ['B2', 'C2'].map(key => {
              worksheet.getCell(key).font = {
                name: 'HP Simplified',
                size: 9,
                bold: true,
                color: { argb: 'ffffff' }
              };
            });
            ['B1', 'C1', 'E1', 'H1', 'J1', 'N1'].map(key => {
              worksheet.getCell(key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffff00' },
              };
            });
            workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
              let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              fs.saveAs(blob, docname + '.xlsx');
            });
            this.loadingExport = false;
            this.common.loader.next(false);
          }
          else {
            this.loadingExport = false;
            this.common.loader.next(false);
            this.common.show('warning', "No retired or reclassified assets");
          }
        }
      ),
      error: (
        error => {
          this.common.errorHandler(error);
          this.common.loader.next(false);
        }
      )
    });
  }

  exportPendingApproval() {
    this.loadingExport = true;
    this.common.loader.next(true);
    this.common.getAPI('amd-export-pending-approval/').subscribe({
      next: (
        (data: any) => {
          if (data.length != 0) {
            let columnOrder = ["Company Code", "Asset Number", "Requestor", "Change Status", "Change Type", "Planned Completion Date", "Comment", "Transfer/Retirement Date", "Region", "Original Asset Number",
              "Asset Class Code", "New Asset Class Code", "Asset Description", "Owner", "New Owner", "Serial Number", "LabFab Name", "New LabFab Name", "Building", "New Building", "Ship-to Customer Name1",
              "Asset Capitalization Date", "Cost Center Code", "New Cost Center Code", "Cost Center Manager", "New Cost Center Manager", "MRU Code", "Profit Center Code",
              "Cost Center Hier Desc Level1", "Asset Super Number", "Depreciation Terms Start Date", "Depreciation Terms Useful Life in Years",
              "Acquisition Value", "PE Net Book Value", "PE Depreciation For Posted Period", "Period Posted Ordinary Depreciation Including Value Adjustments",
              "WBS Element Maintenance Project", "INSERT DATETIME", "MODIFIED DATETIME", "LAST MODIFIED USER"];
            let workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet('Pending Approval');
            let headerRow = worksheet.addRow(columnOrder);
            for (let x1 of data) {
              let x2 = columnOrder;
              let temp = []
              for (let y of x2) {
                temp.push(x1[y])
              }
              worksheet.addRow(temp)
            }
            workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
              let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              fs.saveAs(blob, 'Pending Approval Assets.xlsx');
            });
            this.loadingExport = false;
            this.common.loader.next(false);
          }
          else {
            this.loadingExport = false;
            this.common.loader.next(false);
            this.common.show('warning', "No Pending Approval Assets");
          }
        }
      ),
      error: (
        error => {
          this.common.errorHandler(error);
          this.common.loader.next(false);
        }
      )
    });
  }
  downloadSpreadSheet(data: any) {
    let DateObj = new Date();
    let time = ('0' + (DateObj.getUTCHours())).slice(-2) + "-" + ('0' + (DateObj.getUTCMinutes())).slice(-2)
    let date = ('0' + (DateObj.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + DateObj.getUTCDate()).slice(-2) + '-' + DateObj.getUTCFullYear();
    let building = data[0]["LabFab_Name"]
    for (let d of data) {
      if (d["LabFab_Name"] == building) {
        building = d["LabFab_Name"]
      }
      else {
        building = "";
        break;
      }
    }
    let fileName = "Bulk_Changes_" + data[0]["Building"] + "_" + building + "_" + date + "_" + time;
    let columnOrder = ["Company_Code", "Asset_Number",
      "Asset_Description", "New_Asset_Description", "Owner", "New_Owner", "Serial_Number", "New_Serial_Number", "LabFab_Name", "New_LabFab_Name", "Building", "New_Building", "Cost_Center_Code",
      "New_Cost_Center_Code", "Cost_Object_Manager", "New_Cost_Object_Manager", "Region", "Transfer_Type", "Transfer Status"];
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Bulk Changes');
    let titleValues1 = [""];
    worksheet.addRow(titleValues1)
    let headerRow = worksheet.addRow(columnOrder);
    for (let x1 of data) {
      let x2 = columnOrder;
      let temp = []
      for (let y of x2) {
        if (x1[y] == "NULL" || x1[y] == "null") {
          x1[y] = ""
        }
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    worksheet.getRow(2).height = 50;
    worksheet.getRow(2).font = {
      name: 'HP Simplified',
      size: 9,
      bold: true,
      color: { argb: 'ffffff' }
    };
    worksheet.getRow(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '4FABE3' }
    };
    worksheet.getRow(1).alignment = {
      wrapText: true,
      vertical: 'top',
      horizontal: 'left'
    }
    worksheet.getRow(1).height = 120;
    worksheet.getCell('Q1').value = 'AMS, \r\n APJ, \r\n EMEA';
    worksheet.getCell('R1').value = 'Transfer, \r\n 275 Retirement by Transfer Out';
    worksheet.getCell('S1').value = 'Pending Approval;\r\n Approved';
    workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
      let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName + '.xlsx');
    });
  }
  importBulkBasic(evt: any) {
    this.startedImporting = true;
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
      let length = wb.SheetNames.length;
      const data = reader.result;
      let jsonData = null;
      jsonData = wb.SheetNames.reduce((initial, name) => {
        if (name == "Bulk Changes") {
          const sheet = wb.Sheets[name];
          this.excelData = this.excelData.concat(XLSX.utils.sheet_to_json(sheet, { range: 1, defval: "null" }));
        }
        return initial;
      }, {});
      let userName = atob(this.common.currentUser);
      for (let i in this.excelData) {
        this.num++
        this.excelData[i]['userName'] = userName;
        let text_CostCenterCode = '';
        let region = ''
        if (this.excelData[i]['New_Cost_Center_Code'] != 'null' && this.excelData[i]['New_Cost_Center_Code'] && this.excelData[i]['New_Cost_Center_Code'] != this.excelData[i]['Cost_Center_Code']) {
          text_CostCenterCode = (this.excelData[i]['New_Cost_Center_Code']).substring(0, 4);
          region = (this.excelData[i]['New_Cost_Center_Code']).substring(0, 2)
          if (this.excelData[i]['Transfer_Type'] == 'null' || !this.excelData[i]['Transfer_Type']) {
            if (this.excelData[i]['New_Cost_Center_Code'].includes('US31')) {
              this.excelData[i]['Transfer_Type'] = 'Transfer';
            } else {
              this.excelData[i]['Transfer_Type'] = '275 Retirement by Transfer Out';
            }
          }
        }
        else {
          text_CostCenterCode = (this.excelData[i]['Cost_Center_Code']).substring(0, 4)
          region = (this.excelData[i]['Cost_Center_Code']).substring(0, 2)
        }
        if (['ES', 'IL', 'DE'].includes(region)) {
          this.excelData[i]['Region'] = 'EMEA'
        }
        if (['IN', 'SG', 'MY'].includes(region)) {
          this.excelData[i]['Region'] = 'APJ'
        }
        if (['US', 'PR'].includes(region)) {
          this.excelData[i]['Region'] = 'AMS'
        }
        this.excelData[i]['Text'] = "TR-FR-" + (this.excelData[i]['Cost_Center_Code']).substring(0, 4) + "-" + this.excelData[i]['Asset_Number'] + "-TO-" + text_CostCenterCode
        replace(this.excelData[i])
        function replace(myObj: { [x: string]: any; }) {
          Object.keys(myObj).forEach(function (key) {
            if (myObj[key] instanceof Date) {
              if (myObj[key]) {
                let date = new Date(myObj[key]);
                let offSet = myObj[key].getTimezoneOffset();
                if (offSet > 0) {
                  date = new Date(date.getTime() + (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
                else {
                  date = new Date(date.getTime() - (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
              }
              else {
                myObj[key] = "";
              }
            }
            else {
              typeof myObj[key] == 'object' ? replace(myObj[key]) : myObj[key] = String(myObj[key]);
            }
          });
        }
      }
      this.chunkSize = this.excelData.length;
      this.chunkCount = (this.chunkSize % 100 === 0 ?
        (this.chunkSize / 100) :
        (Math.floor(this.chunkSize / 100) + 1));
      this.chunkSaved = false;
      this.callBasicBulkApi(0, 100);
    }
    reader.readAsBinaryString(target.files[0]);
  }
  callBasicBulkApi(start: number, end: number) {
    if ((this.chunkSize - start) < 100) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = this.excelData.slice(start, end);
      const data = { "Data": param };
      this.common.loader.next(true);
      this.common.postAPI('amd-import-bulk-changes/', data).subscribe({
        next: (
          (data: any) => {
            this.chunkSaved = false;
            if (data['statusCode'] == 200) {
              this.common.loader.next(false);
              if (end < this.chunkSize) {
                this.callApi(start + 100,
                  (this.chunkSize - start) < 100 ? ((this.chunkSize - start) + start) : (end + 100));
              } else {
                //alert(' Sucessfull File Imported ');
                this.toastr.success('Sucessfull File Imported','Success')
                this.startedImporting = false;
                this.resetFileUploader();
              }
            } else {
              //alert('Failed to import asset');
              this.toastr.error('Failed to import asset','Error')
              this.startedImporting = false;
              this.resetFileUploader();
              this.common.loader.next(false);
            }
          }
        ),
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });
      this.chunkCompleted++;
      this.progress = ((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
    }
  }
  importBulkRetire(evt: any) {
    this.excelData = []
    this.startedImporting = true;
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
      let length = wb.SheetNames.length;
      const data = reader.result;
      let jsonData = null;
      jsonData = wb.SheetNames.reduce((initial, name) => {
        if (name = "Bulk Retirements") {
          const sheet = wb.Sheets[name];
          this.excelData = this.excelData.concat(XLSX.utils.sheet_to_json(sheet, { range: 1, defval: "null" }));
        }
        return initial;
      }, {});
      let userName = atob(this.common.currentUser);
      for (let i in this.excelData) {
        this.num++;
        this.excelData[i]['userName'] = userName;
        replace(this.excelData[i])
        function replace(myObj: { [x: string]: any; }) {
          Object.keys(myObj).forEach(function (key) {
            if (myObj[key] instanceof Date) {
              if (myObj[key]) {
                let date = new Date(myObj[key]);
                let offSet = myObj[key].getTimezoneOffset();
                if (offSet > 0) {
                  date = new Date(date.getTime() + (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
                else {
                  date = new Date(date.getTime() - (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
              }
              else {
                myObj[key] = "";
              }
            }
            else {
              typeof myObj[key] == 'object' ? replace(myObj[key]) : myObj[key] = String(myObj[key]);
            }
          });
        }
      }
      this.chunkSize = 0;
      this.chunkCount = 0;
      this.progress = 0;
      this.chunkSize = this.excelData.length;
      this.chunkCount = (this.chunkSize % 100 === 0 ?
        (this.chunkSize / 100) :
        (Math.floor(this.chunkSize / 100) + 1));
      this.chunkSaved = false;
      this.callBulkRetires(0, 100);
    }
    reader.readAsBinaryString(target.files[0]);
  }
  callBulkRetires(start: number, end: number) {
    if ((this.chunkSize - start) < 100) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = this.excelData.slice(start, end);
      const data = { "Data": param };
      this.common.loader.next(true);
      this.common.postAPI('amd-import-bulk-retires/', data).subscribe({
        next: (
          (data: any) => {
            this.common.loader.next(false);
            this.chunkSaved = false;
            if (data['statusCode'] == 200) {
              if (end < this.chunkSize) {
                this.callApi(start + 100,
                  (this.chunkSize - start) < 100 ? ((this.chunkSize - start) + start) : (end + 100));
              } else {
                this.startedImporting = false;
                this.common.show('success', 'File Imported Sucessfull');
              }
            } else {
              this.common.show('error', 'Failed to import asset');
            }
          }
        ),
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });
      this.chunkCompleted++;
      this.progress = ((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
    }
  }
  resetFileUploader() {
    this.Import_Bulk_Data.nativeElement.value = null;
    this.chunkCompleted = 0;
    this.progress = 0;
    this.chunkCount = 0;
    this.excelData = [];
  }
  exportRetiredTemplate() {
    let columnOrder = ["Asset_Number", "Retirement_Type", "Change_Status"];
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Bulk Retirements');
    let DateObj = new Date();
    let currentMonth = ('0' + (DateObj.getUTCMonth() + 1)).slice(-2)
    let docname = "Bulk_Retirement_Template" + currentMonth + DateObj.getFullYear();
    let data1: any = [];
    for (let x1 of data1) {
      let x2 = columnOrder;
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    worksheet.getRow(1).height = 300;
    worksheet.getRow(1).alignment = {
      wrapText: true
    }
    worksheet.getRow(2).height = 50;
    worksheet.getRow(2).font = {
      name: 'HP Simplified',
      size: 9,
      bold: true,
      color: { argb: 'ffffff' }
    };
    worksheet.getRow(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '4FABE3' }
    };
    worksheet.getCell('C1').value = 'Please choose Change_Status as "Approved" if the retirement status directly needs to be changed to approved status.';
    worksheet.getCell('B1').value = 'Please choose Retirement_Type from one of the following: \r\n 1. 2Z2 Retirement by Lost \r\n 2. 2ZA Retirement by Donation \r\n 3. 2Z3 Retirement by Stolen \r\n 4. 2Z8 Retirement by Trade IN \r\n 5. 2ZD Retirement By Inventory Lost' +
      '\r\n 6. 2ZE Retirement By Inventory Scrap \r\n 7. 2ZF Retirement By Inventory Stolen \r\n 8. 260 Retirement with Revenue/Sale \r\n 9. ZS1 Retirement without Revenue \r\n 10. 2Z0 Retirement by Scrap \r\n If you dont put anything by default it will take "Retirement by Scrap".'
    workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
      let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, docname + '.xlsx');
    });
  }
  historyDialog(historyStatus: any, assetIdHistory: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
      dialogConfig.data = {
        tabletemplate: true,
        historyStatus: historyStatus,
        title: 'Asset History ' + assetIdHistory,
        assetID: assetIdHistory
      }
    const dialogRef = this.dialog.open(HistoryComponent, dialogConfig)
    dialogRef.afterClosed().subscribe({
      next: (
        (result) => {
          this.showHistoryDialog = false;
        }
      ),
      error: (
        error => {
          this.common.errorHandler(error);
          this.common.loader.next(false);
        }
      )
    });
  }
  onScroll() {
    if (this.FinalAdminAssetData) {
      this.Updated_FinalAdminAssetData = [...this.Updated_FinalAdminAssetData, ...this.FinalAdminAssetData.slice(this.pageOffset, this.pageLimit + this.pageOffset)]
      this.pageOffset = this.pageLimit + this.pageOffset;
    }
    else {
    }
  }

  pritnItems(data: any[]) {
    setTimeout(() => {
      this.printItem = data.filter(a => a['Checkbox_Data'])
    }, 100);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
