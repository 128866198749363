import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {
  constructor(private common: CommonService, private activatedRoute: ActivatedRoute){
    this.common.currentModule.next(this.activatedRoute.snapshot.data)
    this.common.bannerData.next({title: 'Release Notes'})
  }

}
