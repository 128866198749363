<form [formGroup]="assetdetails1" (ngSubmit)="onSubmit()">
    <mat-card>
        <mat-toolbar color="primary" class="mb-3 custom-text-color">
            <span>Edit Data For LabFab</span>
        </mat-toolbar>

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <mat-spinner *ngIf="loading"></mat-spinner>
                    <span *ngIf="loading">Fetching Labfab Data...</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Site  <span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                        <input matInput type="text" formControlName="SITE" placeholder="">
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Building </mat-label>
                        <input matInput type="text" formControlName="BUILDING" placeholder="" [ngClass]="{ 'is-invalid': submitted && formBuilder['BUILDING'].errors }" required readonly>
                        <div *ngIf="submitted && formBuilder['BUILDING'].errors" class="invalid-feedback">
                            <div *ngIf="formBuilder['BUILDING'].errors['required']">LabFab Name is required</div>
                        </div>
                    </mat-form-field>
                </div>
                

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>New Building</mat-label>
                        <input matInput type="text" maxlength="5" formControlName="New_BUILDING" placeholder="">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>LabFab Name</mat-label>
                        <input matInput type="text" formControlName="Lab_Fab_Name" placeholder="" [ngClass]="{ 'is-invalid': submitted && formBuilder['Lab_Fab_Name'].errors }" required readonly>
                        <div *ngIf="submitted && formBuilder['Lab_Fab_Name'].errors" class="invalid-feedback">
                            <div *ngIf="formBuilder['Lab_Fab_Name'].errors['required']">LabFab Name is required</div>
                        </div>
                    </mat-form-field>
                </div>                

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>New LabFab Name</mat-label>
                        <input matInput type="text" maxlength="15" formControlName="New_Lab_Fab_Name" placeholder="">
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Number of Assets in LabFab</mat-label>
                        <input matInput type="text" formControlName="Asset_Count" placeholder="" readonly>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>OWNER EMAIL</mat-label>
                        <input matInput type="text" formControlName="Lab_Owner" placeholder="" [ngClass]="{ 'is-invalid': submitted && formBuilder['Lab_Owner'].errors }" required readonly>
                        <div *ngIf="submitted && formBuilder['Lab_Owner'].errors" class="invalid-feedback">
                            <div *ngIf="formBuilder['Lab_Owner'].errors['required']">Owner Email is required</div>
                        </div>
                    </mat-form-field>
                </div>                

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>New OWNER EMAIL</mat-label>
                        <input matInput type="text" formControlName="New_Lab_Owner" placeholder="">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>SM EMAIL</mat-label>
                        <input matInput type="text" formControlName="SM_EMAIL" placeholder="">
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Scanner EMAIL</mat-label>
                        <input matInput type="text" formControlName="Lab_Scanner1" placeholder="" [ngClass]="{ 'is-invalid': submitted && formBuilder['Lab_Scanner1'].errors }" required>
                        <div *ngIf="submitted && formBuilder['Lab_Scanner1'].errors" class="invalid-feedback">
                            <div *ngIf="formBuilder['Lab_Scanner1'].errors['required']">Lab Scanner 1 is required</div>
                        </div>
                    </mat-form-field>
                </div>                

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Scanner II EMAIL</mat-label>
                        <input matInput type="text" formControlName="Lab_Scanner2" placeholder="">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <mat-form-field class="w-100">
                        <mat-label>Comment</mat-label>
                        <textarea matInput type="text" formControlName="Labfab_Comment"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Insert Date/Time</mat-label>
                        <input matInput type="text" formControlName="INSERT_DATETIME" readonly>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Last Modified Date/Time</mat-label>
                        <input matInput type="text" formControlName="MODIFIED_DATETIME" readonly>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Last Modified User Name</mat-label>
                        <input matInput type="text" formControlName="Last_MODIFIED_user" readonly>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                  <input type="button" class="btn btn-primary form-control" value="Save" (click)="onSubmit()" />
                </div>
                <div class="col-md-2">
                  <input type="button" class="btn btn-primary form-control" value="Cancel" (click)="close()" />
                </div>
            </div>
        </div>
    </mat-card>
</form>