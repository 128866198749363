import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-display-comparison-report',
  templateUrl: './display-comparison-report.component.html',
  styleUrls: ['./display-comparison-report.component.css']
})
export class DisplayComparisonReportComponent implements OnInit {
  exportHeaderArray: any = ['Source', 'Asset Active Listing', 'Asset Class Code', 'Asset_Number', 'Owner', 'LabFab', 'Building', 'Description', 'Serial', 'Cost Center Code', 'Cost Object Manager Email ID']
  assetDetails: any;
  amdassetDetails: any;
  headers: any;
  dataSource: any;
  IsdisableEdit!: boolean;
  displayedColumns = ['userId'];
  ascendingsort: boolean | null = true;
  submitted = false;
  columnindex: any;
  currentPage: any
  tableCheckAllBox = false;
  tableCheckAllBox1 = false;
  checkItem: any[] = [];
  asset: any[] = [];
  asset1: any[] = [];
  num = 0;
  value!: number;
  count!: number;
  filterhappens = false;
  gridData: any[] = [];
  gridData1: any[] = [];
  pageLength: any[] = [];
  filterText: any[] = [];
  filterKey: any[] = [];
  tableHeaderArray: any[] = [];
  tableHeaderArray1: any[] = [];
  rowCount: any;
  loading: any;
  loaded: any;
  assetdetails1!: FormGroup;
  show!: boolean;
  showGrid!: boolean;
  noRecord: boolean = false;
  isadmin!: boolean;
  excelData: any[] = [];
  s4checkall: boolean = false;
  progressUpload = false;
  chunkCount: number = 0;
  chunkSaved: boolean = false;
  chunkSize: number = 200;
  progress: any;
  isConvert!: boolean;
  chunkCompleted = 0;
  @Input() datavalue!: string;
  @Input() dataType!: any;
  @Output() backClick: EventEmitter<boolean> = new EventEmitter();
  startedImporting = false;
  s4AssetPool: any;
  amdAssetPool: any;
  exportDataArray: any = [];
  constructor(private common: CommonService, private router: Router) {
    this.common.bannerData.next({ title: 'Report', subtitle: 'Comparison Report For AMD & S4' });
  }
  ngOnInit() {
    this.showGrid = true;
    if (this.datavalue == 'New') {
      this.isConvert = true;
      this.common.loader.next(true);
      this.common.getAPI('amd-demo/').subscribe({
        next: (data: any) => {
          this.asset = this.getUpdatedArray(data['body']);
          this.s4AssetPool = this.getUpdatedArray(data['body']);
          this.rowCount = data['body']?.length
          if (this.rowCount === 0) {
            this.noRecord = true;
          } else {
            this.noRecord = false;
          }
          this.paginationData(this.asset);
          this.common.loader.next(false);
        },
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });
    }
    else if (this.datavalue == 'Inactive') {
      this.isConvert = true;
      this.common.loader.next(true);
      this.common.getAPI('amd-get-inactive-asset-data/').subscribe({
        next: (data: any) => {
          this.asset = this.getUpdatedArray(data['body']);
          this.s4AssetPool = this.getUpdatedArray(data['body']);
          this.rowCount = data['body']?.length;
          if (this.rowCount === 0) {
            this.noRecord = true
          } else {
            this.noRecord = false
          }
          this.paginationData(this.asset);
          this.common.loader.next(false);
        },
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });
    }
    else if (this.datavalue == 'Missing') {
      this.isConvert = false;
      this.amdassetDetails = this.getUpdatedArray(this.common.displayMissingAsset);
      this.amdAssetPool = this.getUpdatedArray(this.common.displayMissingAsset);
      this.paginationData1(this.amdassetDetails);
      this.assetDetails = true
      this.showGrid = true
      this.rowCount = this.amdassetDetails.length
      if (this.rowCount === 0) {
        this.noRecord = true
      } else {
        this.noRecord = false
      }
    }
    else if (this.datavalue == 'Discrepency') {
      this.isConvert = true;
      this.asset = this.getUpdatedArray(this.dataType['body']);
      this.s4AssetPool = this.getUpdatedArray(this.dataType['body']);
      this.rowCount = this.dataType['body']?.length
      this.amdassetDetails = this.getUpdatedArray(this.dataType['AMD'])
      this.amdAssetPool = this.getUpdatedArray(this.dataType['AMD']);
      if (this.rowCount === 0) {
        this.noRecord = true
      } else {
        this.noRecord = false
      }
      this.paginationData(this.asset)
      this.paginationData1(this.amdassetDetails)
      this.exportDataArray = (this.asset.map(item => ({ ...item, Source: 'S4' })).concat(this.amdassetDetails.map((item: any) => ({ ...item, Source: 'AMD' })))).sort((a, b) => a.Asset_Number - b.Asset_Number);
    }
    else {
      this.common.show('warning', 'Incorrect Display or Format...');
    }
    // });
  }
  pageNext(pageNumber: any) {
    this.currentPage = pageNumber
    let assetData = this.gridData;
    if (pageNumber === this.pageLength.length) {
      let len = pageNumber * 500
      let index = len - 500
      this.assetDetails = assetData.slice(index, assetData.length);
      this.asset = assetData.slice(index, assetData.length);
    } else {
      let len = pageNumber * 500
      let index = len - 500
      this.assetDetails = assetData.slice(index, len);
      this.asset = assetData.slice(index, assetData.length);
    }
  }
  pageNext1(pageNumber: any) {
    this.currentPage = pageNumber
    let assetData = this.gridData1;
    if (pageNumber === this.pageLength.length) {
      let len = pageNumber * 500
      let index = len - 500
      this.assetDetails = assetData.slice(index, assetData.length);
      this.amdassetDetails = assetData.slice(index, assetData.length);
    } else {
      let len = pageNumber * 500
      let index = len - 500
      this.assetDetails = assetData.slice(index, len);
      this.amdassetDetails = assetData.slice(index, assetData.length);
    }
  }
  paginationData(element: any) {
    this.pageLength = []
    this.gridData = element;
    let length = element.length / 500;
    if (length == 0) {
      this.pageLength[0] = 1;
    } else {
      for (let index = 0; index < length; index++) {
        this.pageLength[index] = index + 1;
      }
    }
    this.pageNext(1)
  }
  paginationData1(element: any) {
    this.pageLength = []
    this.gridData1 = element;
    let length = element.length / 500;
    if (length == 0) {
      this.pageLength[0] = 1;
    } else {
      for (let index = 0; index < length; index++) {
        this.pageLength[index] = index + 1;
      }
    }
    this.pageNext1(1)
  }
  sortData(index: any, value: any) {
    let columnData = []
    for (let i = 0; i < this.gridData.length; i++) {
      columnData.push(this.gridData[i][value])
    }
    if (this.columnindex === index) {
      this.ascendingsort = !this.ascendingsort
    } else {
      this.columnindex = index
      this.ascendingsort = true
    }
    this.switchSortMethod(columnData, value)
  }
  sortData1(index: any, value: any) {
    let columnData1: any = []
    for (let i = 0; i < this.gridData1.length; i++) {
      columnData1.push(this.gridData1[i][value])
    }
    if (this.columnindex === index) {
      this.ascendingsort = !this.ascendingsort
    } else {
      this.columnindex = index
      this.ascendingsort = true
    }
    this.switchSortMethod1(columnData1, value)
  }
  checkUncheckAll() {
    let len = this.asset.length;
    for (let index = 0; index < len; index++) {
      this.asset[index].Checkbox_Data = this.tableCheckAllBox
    }
  }
  switchSortMethod(columnData: any, value: any) {
    if (this.ascendingsort) {
      columnData.sort((a: any, b: any) => {
        if (typeof a === 'string' && typeof b === 'string') {
          return a.toLowerCase().localeCompare(b.toLowerCase(), 'en', { numeric: true })
        } else {
          return a - b;
        }
      });
      let sortedAsset = this.getSortedData(columnData, value)
      this.paginationData(sortedAsset)
    } else {
      columnData.sort((a: any, b: any) => {
        if (typeof a === 'string' && typeof b === 'string') {
          return b.toLowerCase().localeCompare(a.toLowerCase(), 'en', { numeric: true })
        } else {
          return b - a;
        }
      });
      let sortedAsset = this.getSortedData(columnData, value)
      this.paginationData(sortedAsset)
    }
  }
  switchSortMethod1(columnData1 = [], value: any) {
    if (this.ascendingsort) {
      columnData1.sort((a: any, b: any) => {
        if (typeof a === 'string' && typeof b === 'string') {
          return a.toLowerCase().localeCompare(b.toLowerCase(), 'en', { numeric: true })
        } else {
          return a - b;
        }
      });
      let sortedAsset = this.getSortedData1(columnData1, value)
      this.paginationData1(sortedAsset)
    } else {
      columnData1.sort((a: any, b: any) => {
        if (typeof a === 'string' && typeof b === 'string') {
          return b.toLowerCase().localeCompare(a.toLowerCase(), 'en', { numeric: true })
        } else {
          return b - a;
        }
      });
      let sortedAsset = this.getSortedData1(columnData1, value)
      this.paginationData1(sortedAsset)
    }
  }
  Converttoamd(assetdatavalue: any) {
    let pvalue = this.findpartitionsets(assetdatavalue);
    if (pvalue.length == 0) {
      this.common.show('warning', 'Select the Asset to Convert')
      return
    }
    this.startedImporting = true;
    this.convertbykey(pvalue);
  }
  findpartitionsets(assetdatavalue: any) {
    let partitionkeyset = []
    for (let index = 0, len = assetdatavalue.length; index < len; index++) {
      if (assetdatavalue[index].Checkbox_Data) {
        partitionkeyset.push({ "partitionkey": assetdatavalue[index].Asset_Number });
      }
    }
    return partitionkeyset;
  }
  convertbykey(partitionkeyset: any) {
    this.chunkSize = partitionkeyset.length;
    this.chunkCount = (this.chunkSize % 200 === 0 ?
      (this.chunkSize / 200) :
      (Math.floor(this.chunkSize / 200) + 1));
    this.chunkSaved = false;
    if (this.datavalue == 'New') {
      this.callApi(partitionkeyset, 0, 200);
    }
    else if (this.datavalue == 'Inactive') {
      this.callApiActive(partitionkeyset, 0, 200);
    }
    else if (this.datavalue == 'Missing') {
      this.common.show('error', 'Missing Data')
    }
    else if (this.datavalue == 'Discrepency') {
      this.callApiDiscrepancy(partitionkeyset, 0, 200);
    }
    else {
      this.common.show('warning', 'Incorrect Display or Format...');
    }
  }
  callApi(given: any, start: any, end: any) {
    if ((this.chunkSize - start) < 200) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = { "Data": given.slice(start, end) };
      this.common.loader.next(true);
      this.common.postAPI('amd-update-s4-data-to-amdbykey/', param).subscribe({
        next: (data: any) => {
          this.chunkSaved = false;
          if (data['statusCode'] == 200) {
            if (end < this.chunkSize) {
              this.callApi(given, start + 200,
                (this.chunkSize - start) < 200 ? ((this.chunkSize - start) + start) : (end + 200));
            } else {
              this.startedImporting = false;
              this.common.show('success', 'Conversion Sucessfull ');
              this.router.navigate(['/'])
            }
          } else {
            this.startedImporting = false;
            this.common.show('error', 'Failed to convert asset');
          }
          this.common.loader.next(false);
        },
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });
      this.chunkCompleted++;
      this.progress = ((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
    }
  }
  callApiActive(given: any, start: any, end: any) {
    if ((this.chunkSize - start) < 200) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = { "Data": given.slice(start, end) };
      this.common.postAPI('amd-convert-inactive-assets/', param).subscribe({
        next: (data: any) => {
          this.chunkSaved = false;
          if (data['statusCode'] == 200) {
            if (end < this.chunkSize) {
              this.callApiActive(given, start + 200,
                (this.chunkSize - start) < 200 ? ((this.chunkSize - start) + start) : (end + 200));
            } else {
              this.startedImporting = false;
              this.common.show('success', 'Conversion Sucessfull ');
              this.router.navigate(['/'])
            }
          } else {
            this.startedImporting = false;
            this.common.show('warning', 'Failed to convert asset');
          }
        },
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });
      this.chunkCompleted++;
      this.progress = ((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
    }
  }
  callApiDiscrepancy(given: any, start: any, end: any) {
    if ((this.chunkSize - start) < 200) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = { "Data": given.slice(start, end) };
      this.common.postAPI('amd-converting-discrepancies/', param).subscribe({
        next: (data: any) => {
          this.chunkSaved = false;
          if (data['statusCode'] == 200) {
            if (end < this.chunkSize) {
              this.callApiDiscrepancy(given, start + 200,
                (this.chunkSize - start) < 200 ? ((this.chunkSize - start) + start) : (end + 200));
            } else {
              this.startedImporting = false;
              this.common.show('success', 'Conversion Sucessfull ');
              this.router.navigate(['/'])
            }
          } else {
            this.startedImporting = false;
            this.common.show('error', 'Failed to convert asset');
          }
        },
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });
      this.chunkCompleted++;
      this.progress = ((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
    }
  }
  getSortedData(sortedData: any, value: any) {
    sortedData.forEach((mainData: any, index: any) => {
      for (let i = index + 1; i < sortedData.length; i++) {
        if (sortedData[index] === sortedData[i]) {
          sortedData.splice(i, 1)
          i = i - 1
        }
      }
    });
    let finalAssetDetails: any = [];
    sortedData.forEach((data: any) => {
      for (let i = 0; i < this.gridData.length; i++) {
        if (this.gridData[i][value] === data) {
          finalAssetDetails
            .push(this.gridData[i])
        }
      }
    });
    return finalAssetDetails
      ;
  }
  getSortedData1(sortedData: any, value: any) {
    sortedData.forEach((mainData: any, index: any) => {
      for (let i = index + 1; i < sortedData.length; i++) {
        if (sortedData[index] === sortedData[i]) {
          sortedData.splice(i, 1)
          i = i - 1
        }
      }
    });
    let finalAssetDetails: any = [];
    sortedData.forEach((data: any) => {
      for (let i = 0; i < this.gridData1.length; i++) {
        if (this.gridData1[i][value] === data) {
          finalAssetDetails
            .push(this.gridData1[i])
        }
      }
    });
    return finalAssetDetails
      ;
  }
  edit(assetnumber: number) {
    this.router.navigate(['editasset'], { queryParams: { assetid: assetnumber } });
  }
  filterValue(inputData: any, indexValue: any, keyValue: any) {
    let filterData = this.s4AssetPool;
    let amdfilterData = this.amdAssetPool;
    this.filterKey[indexValue] = keyValue;
    for (let index = 0; index < inputData.length; index++) {
      let tempFilterData = []
      let tempAMDFilterData = []
      if (inputData[index] != undefined && inputData[index] != '') {
        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i][this.filterKey[index]] != undefined) {
            if (filterData[i][this.filterKey[index]].toLowerCase().includes(inputData[index].toLowerCase())) {
              tempFilterData.push(filterData[i]);
              if (this.datavalue == 'Discrepency') {
                tempAMDFilterData.push(amdfilterData[i]);
              }
            }
          }
        }
        filterData = tempFilterData;
        if (this.datavalue == 'Discrepency') {
          amdfilterData = tempAMDFilterData;
        }
      }
    }
    this.ascendingsort = null;
    if (this.datavalue == 'Discrepency') {
      this.paginationData1(amdfilterData);
    }
    this.paginationData(filterData);
  }
  originalOrder = (a: any, b: any) => { return 0 }
  getUpdatedArray(assetArray: any) {
    let columnOrder = ["Asset Active Listing", "Asset Class Code", "Asset_Number", "Owner", "LabFab", "Building", "Description", "Serial", "Cost Center Code", "Cost Object Manager Email ID"]
    this.tableHeaderArray = columnOrder;
    let finalAssetArray = [];
    for (let i = 0; i < assetArray?.length; i++) {
      let tempAssetArray: any = {};
      for (let j = 0; j < columnOrder?.length; j++) {
        tempAssetArray[columnOrder[j]] = assetArray[i][columnOrder[j]];
      }
      finalAssetArray.push(tempAssetArray);
    }
    return finalAssetArray
  }
  originalOrder1 = (a: any, b: any) => { return 0 }
  cancel() {
    // this.location.back()
    this.backClick.emit(false);
  }
}
