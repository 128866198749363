import { Component, EventEmitter, Input, Output, Version, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, takeWhile } from 'rxjs/operators';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
import { CommonService } from 'src/app/services/common.service';
import { HistoryComponent } from '../../AssetDetails/history/history.component';
import { TableDialogComponent } from '../table-dialog/table-dialog.component';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { WbsDetailsComponent } from '../wbs-details/wbs-details.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AssetDetailsComponent } from '../asset-details/asset-details.component';
import { ExternalTableDialogComponent } from '../external-table-dialog/external-table-dialog.component';
import { WbsCashFlowComponent } from '../wbs-cashflow-summary/wbs-cashflow-summary.component';
import { WbsCashflowDetailsComponent } from '../wbs-cashflow-details/wbs-cashflow-details.component';
import { MatSelectChange } from '@angular/material/select';
import { CurrencyPipe } from '@angular/common';
import { forkJoin } from 'rxjs';

export const validOrDisabledValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const formGroup = control as FormGroup;
  const invalidControls = Object.keys(formGroup.controls).filter(key => {
    const control = formGroup.controls[key];
    return control.enabled && control.invalid;
  });

  return invalidControls.length > 0 ? { invalidControls: true } : null;
};

@Component({
  selector: 'app-capex-details',
  templateUrl: './capex-details.component.html',
  styleUrls: ['./capex-details.component.scss']
})

export class CapexDetailsComponent {
  panelFlag : boolean = true;
  userRole = this.common.getUserRole();
  requestTypeOptions: string[] = ['LTP (>1 year out)', 'POR(Current Fiscal Year)', 'Expense (CAP SH Review)'];
  projectTypeOptions: string[] = ['M&E', 'L&B'];
  justificationOptions: string[] = ['Health/Safety', 'Revenue Generating', 'Obsolescence/Refresh', 'Break/Fix', 'New Product Introduction', 'IT Capitalization'];
  impactOptions: string[] = ['Decreased capabilities for testing/product development', 'Business Continuity', 'Program Schedule Slip',
    'Assurance of Supply (AOS)', 'Revenue Loss', 'Time to Market'];
  externalOptions: string[] = [];
  buildSiteOptions: string[] = ['None','COR', 'ISL', 'SDG', 'SGP', 'Supplier'];
  cashFlowMethodOptions: string[] = ['Standard Terms (%)', 'Straight-Line ($)'];
  //extract both finace and planner options from the backend (made table accessible for asset admin)
  //financeOptions: string[] = ['Alan Loke – MTO', 'Amy Ghozeil – PWI', 'Guillermo Encarnacion – 3D'];
  // plannerOptions: string[] = ['Krista Lively – MEMS/BPI', 'Allie Plager – PWI', 'Mike Lee – 3D', 
  // 'Hongwei Xu – GCT', 'Stefanie Clark – SPTS', 'Mike Moffit – CNPI', 'Fereshteh Khorrami – FAS'];
  financeOptions: string[] = [];
  plannerOptions: string[] = [];
  buttonLabel!: string;
  buildings: any;
  loading: boolean = false;
  allSites: any[] = [];
  filteredBuilding: any[] = [];
  filteredLabFabName: any[] = [];
  siteName: any;
  version: number = 0;
  ltpNum: string = '';
  porNum: string = '';
  porStatus: string = '';
  buildingName: any;
  userVerifyLoading = false
  userVerify = false;
  userInvalid = false;
  financialCostCenterData: any;
  commmitCostCenterOptions: any;
  filteredCommitCCOptions: any;
  filteredProgramDriverOptions: any;
  filteredEngrNRECostObjOptions: any;
  filteredTechNRECostObjOptions: any;
  depreciateCostCenterOptions: any;
  filteredDepreciateCCOptions: any;
  financialProgramDriversData: any;
  allCostCenterOptions: any;
  selectedCCRow: any;
  isChecked: boolean = false;
  checkBoxName: any;
  warningShown: boolean = false;
  shouldUncheck: boolean = false;
  openPanels: string[] = [];
  isRequestDetailPanelOpen = false;
  isFinancialPanelOpen = false;
  checkTotalSum: boolean = false;
  userName = atob(this.common.currentUser);
  isWBSSectionFilled: boolean = false;
  isWBSCashFlowFilled: boolean = false;
  iswbsNumberExist: boolean = false;
  assetDraftFlag: boolean = false;
  editLTP: any;
  ltpStatus = '';
  nreCheckBox = 'Default';
  showDraftBtn: boolean = false;
  showInQueueBtn: boolean = false;
  showAnalystBtn: boolean = false;
  isRequester: boolean = false;
  defaultFlag: boolean = true;
  depreciationccManager: string = '';
  commentError: boolean = false;
  shouldDisplayEmailDiv: boolean = false;
  reqNum: string | null = null; 
  view: string | null = null;  
  approvalForm: FormGroup;
  approvalStatus: string = 'Pending';
  date: string = '';
  wbsNum: string = '';
  wbsStatus: string = '';
  Depreciation_Manager_Approval:string = '';
  totalCost_Email:string = '';
  isRtoDateChanged = false;
  monthsDifference: any;
  isWBSEmailSent: boolean = false;
  adjustedFinalQuarterMatDate: any;
  showSaveBtn: boolean = true;
  formattedItemCost: any;
  formattedHookupValue: any;
  shouldHide: boolean = true;
  hasCashFlowErrorMessage: boolean = false;	
  hasNREErrorMessage: boolean = false;
  hasHookupErrorMessage: boolean = false;
  isDetailsPanelExpanded : boolean = false;
  inputType: string = 'text'; // Default input type
  Is_Request_WBS_Email_Sent:boolean = false;
  // originalRtoDate: Date | null = null; 
  // adjustedRtoDate : Date | null = null;

  requestDetailsForm: FormGroup = this.fb.group({
    requestType: ['', Validators.required],
    projectType: ['', Validators.required],
    projectManagerEmail: ['', Validators.required],
    planner: [''],
    finance: ['', Validators.required],
    itemDescription: ['', Validators.required],
    justification: ['', Validators.required],
    impact: ['', Validators.required],
    external: [['None'], Validators.required],
    buildSite: [''],
    site: ['', Validators.required],
    building: ['', Validators.required],
    labfabInstallLocation: ['', Validators.required],
    assets: ['', Validators.required],
    assetUsefulLife: ['10', Validators.required],
    comment: [''],
  });

  financialDetailsForm: FormGroup = this.fb.group({
    commitCostCenter: ['', Validators.required],
    depreciateCostCenter: ['', Validators.required],
    deprCCManager: [{ value: '', disabled: true }],
    deprOrgL2: [{ value: '', disabled: true }],
    deprOrgL3: [{ value: '', disabled: true }],
    deprOrgL4: [{ value: '', disabled: true }],
    deprSection: [{ value: '', disabled: true }],
    deprMRU: [{ value: '', disabled: true }],
    deprSubMRU: [{ value: '', disabled: true }],
    commitCCManager: [{ value: '', disabled: true }],
    commitOrgL2: [{ value: '', disabled: true }],
    commitOrgL3: [{ value: '', disabled: true }],
    commitOrgL4: [{ value: '', disabled: true }],
    commitSection: [{ value: '', disabled: true }],
    commitMRU: [{ value: '', disabled: true }],
    commitSubMRU: [{ value: '', disabled: true }],
    programDriver: ['', Validators.required],
    category: [{ value: '', disabled: true }],
    rollUp: [{ value: '', disabled: true }],
    businessUnit: [{ value: '', disabled: true }],
    companyCode: [{ value: '', disabled: true }],
    region: [{ value: '', disabled: true }],
  });

  projectCostDetailsForm: FormGroup = this.fb.group({
    itemCost: ['', { validators: [Validators.required, Validators.min(this.getMinItemCost()), this.fourDigitValidator] }],
    hookCapitalCost: ['', Validators.required],
    hookExpenseCost: [''],
    defaultNRE: ['', Validators.required],
    engrNRECostCenter: [{ value: '', disabled: true }, Validators.required],
    engrNREFTE: [{ value: '', disabled: true }, [Validators.min(0)]],
    engrNRECost: [{ value: '', disabled: true }],
    techNRECostCenter: [{ value: '', disabled: true }, Validators.required],
    techNREFTE: [{ value: '', disabled: true }, [Validators.min(0)]],
    techNRECost: [{ value: '', disabled: true }],
    NREtotalCost: [{ value: '', disabled: true }],
    totalCost: [{ value: '', disabled: true }],
    estimatedQatrDepr: [{ value: '', disabled: true }]
  });

  eventQuarterForm: FormGroup = this.fb.group({
    commitQuarter: [''],
    commitQuarterMatDate: ['', Validators.required],
    factoryQuarter: [''],
    factoryQuarterMatDate: [''],
    siteQuarter: [''],
    siteQuarterMatDate: ['', Validators.required],
    finalQuarter: [''],
    finalQuarterMatDate: ['', Validators.required],
    estimatedProjectEnd: [{ value: '', disabled: true }],
    estimatedProjectEndMatDate: [{ value: '', disabled: true }]
  }, { validators: validOrDisabledValidator });


  adjustQuarterForm: FormGroup = this.fb.group({
    adjustedCommitQuarter: [{ value: '', disabled: true }],
    adjustedFactoryQuarter: [''],
    adjustedFactoryQuarterMatDate: [''],
    adjustedSiteQuarter: [''],
    adjustedSiteQuarterMatDate: [''],
    adjustedFinalQuarter: [''],
    adjustedFinalQuarterMatDate: [''],
    adjustedEstimatedProjectEnd: [{ value: '', disabled: true }],
    adjustedEstimatedProjectEndMatDate: [{ value: '', disabled: true }],
    monthsOverdue: [''],
    rtoProjectStatus: [''],
    rtoProjectNote: [''],
    rtoDateChangeReason: [''],
    rtoDateChangeComment: ['']

    // rtoDateChangeReason: ['', Validators.required],
    // rtoDateChangeComment: ['', Validators.required],
  })

  itemCashFlowForm: FormGroup = this.fb.group({
    itemCashFlowMethod: [this.cashFlowMethodOptions[0], Validators.required],
    itemCheckSum: [{ value: '', disabled: true }],
    itemCashFlowTerms: [{ value: '', disabled: true }],
    itemCashFlowCommit: ['', Validators.required],
    itemCashFlowFactory: [''],
    itemCashFlowSite: [''],
    itemCashFlowFinal: ['', Validators.required]
  })

  private cashFlowValidators: { [key: string]: ValidatorFn | ValidatorFn[] } = {
    itemCashFlowCommit: Validators.required,
    itemCashFlowFinal: Validators.required
  };

  NRECashFlowForm: FormGroup = this.fb.group({
    NRECashFlowMethod: [this.cashFlowMethodOptions[0], Validators.required],
    NRECheckSum: [{ value: '', disabled: true }],
    NRECashFlowTerms: [{ value: '', disabled: true }],
    NRECashFlowCommit: ['', Validators.required],
    NRECashFlowFactory: [''],
    NRECashFlowSite: [''],
    NRECashFlowFinal: ['', Validators.required]
  })

  private NRECashFlowValidators: { [key: string]: ValidatorFn | ValidatorFn[] } = {
    NRECashFlowCommit: Validators.required,
    NRECashFlowFinal: Validators.required,
    NRECashFlowSite: Validators.required
  }

  hookUpCashFlowForm: FormGroup = this.fb.group({
    hookUpCashFlowMethod: [this.cashFlowMethodOptions[0], Validators.required],
    hookUpCheckSum: [{ value: '', disabled: true }],
    hookUpCashFlowTerms: [{ value: '', disabled: true }],
    hookUpCashFlowCommit: ['', Validators.required],
    hookUpCashFlowFactory: [''],
    hookUpCashFlowSite: [''],
    hookUpCashFlowFinal: ['', Validators.required]
  })

  private hookUpCashFlowValidators: { [key: string]: ValidatorFn | ValidatorFn[] } = {
    hookUpCashFlowCommit: Validators.required,
    hookUpCashFlowFinal: Validators.required,
    hookUpCashFlowSite: Validators.required
  }

  private itemCashFlowValidators: { [key: string]: ValidatorFn | ValidatorFn[] } = {
    itemCashFlowCommit: Validators.required,
    itemCashFlowFinal: Validators.required,
    itemCashFlowSite: Validators.required
  }



  cashFlowDetailsForm: FormGroup = this.fb.group({
    eventQuarter: this.eventQuarterForm,
    adjustQuarter: this.adjustQuarterForm,
    itemCashFlow: this.itemCashFlowForm,
    NRECashFlow: this.NRECashFlowForm,
    hookUpCashFlow: this.hookUpCashFlowForm
  })

  capexForm: FormGroup = this.fb.group({
    ltpNumber: "",
    ltpStatus: this.ltpStatus,
    version: this.version,
    modifiedBy: this.userName,
    requestDetails: this.requestDetailsForm,
    financialDetails: this.financialDetailsForm,
    projectCostDetails: this.projectCostDetailsForm,
    cashFlowDetails: this.cashFlowDetailsForm
  })

  RequestLdapValidation = {
    validateEmailDetails: "null",
    level: 0
  }

  programDriverOptions: any;
  selectedProgramDriverRow: any;
  @ViewChild('trigger1', { read: MatAutocompleteTrigger }) trigger!: MatAutocompleteTrigger;
  inputFTEValue: any;
  companyCode: any;
  region!: string;
  popUpText!: string;
  selectedProjectTypeVal: any;
  showTableDialog!: boolean;
  formattedDateCommit: string = '';
  formattedDateFactory: string = '';
  formattedDateFinal: string = '';
  formattedDateSite: string = '';
  formattedDateAdjustedFactory: string = '';
  formattedDateAdjustedSite: string = '';
  formattedDateAdjustedFinal: string = '';
  minDate = new Date();
  minFactoryQuarterDate!: Date;
  minSiteQuarterDate!: Date;
  minFinalQuarterDate!: Date;
  maxFactoryQuarterDate!: Date;
  maxSiteQuarterDate!: Date;
  wbsDetailsForm: any;
  wbsCashFlowForm: any;
  assetDetailsForm: any;
  wbsCashFlowDetailsForm: any;
  siteOptions = [''];
  @ViewChild('wbsDetails') wbsDetails!: WbsDetailsComponent;
  @ViewChild('assetDetails') assetDetails!: AssetDetailsComponent;
  @ViewChild('wbsCashFlow') wbsCashFlow!: WbsCashFlowComponent;
  @ViewChild('wbsCashFlowDetails') wbsCashFlowDetails!: WbsCashflowDetailsComponent;


  adjustedEstimatedMonth!: number;
  estimatedMonth!: number;
  dateFilter = (date: Date | null): boolean => {
    return false;
  };
  minEstimatedProjectEndDate!: Date;
  minAdjEstimatedProjectEndDate!: Date;
  rtoProjectStatusOptions = ['Tool not qualified to be used as intended', 'Invoicing not complete', 'Complex custom tooling with multiple vendors and long lead times', 'Resources changed to higher priority items'];
  rtoDateChangeReasonOptions = [
    'Additional project scope',
    'Vendor commissioning delay',
    'Vendor equipment delay',
    'Construction PM constraints',
    'Trade constraints (construction delay)',
    'Tool installed but not qualified for RTO',
    'Invoicing not complete',
    'Complex custom tooling with long lead time',
    'Other'
  ];  						   
  // @Output() requestDetailsFormValid = new EventEmitter<any>();

  constructor(
    private common: CommonService, 
    private fb: FormBuilder,
    private router: Router, 
    private toastr: ToastrService, 
    private dialog: MatDialog, 
    private activatedRoute: ActivatedRoute,
    private currencyPipe: CurrencyPipe) {

    // const navigation = this.router.getCurrentNavigation();

    // const state = navigation?.extras.state;
    // if (state) {
    //   this.editLTP = state["ltp"];
    //   this.ltpStatus = state['ltpStatus'];

    // }
    // console.log("print state", state);
    this.common.bannerData.next({ title: 'CAPEX', subtitle: 'Submit/View LTP/POR Records' })
    // if(state.action == "submit"){
    //   this.common.bannerData.next({title: 'CAPEX', subtitle: 'Submit New LTP/POR Request'}) 
    // }
    // else if(state.action == "view"){
    //   this.common.bannerData.next({title: 'CAPEX', subtitle: 'View LTP/POR Record'}) 
    // }
    // else{
    //   this.common.bannerData.next({title: 'CAPEX', subtitle: 'Show LTP/POR Record'})
    // }
    // this.updateButtonLabel()
    this.approvalForm = this.fb.group({
      comment: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.reqNum = this.activatedRoute.snapshot.queryParamMap.get('reqNum');
    this.view = this.activatedRoute.snapshot.queryParamMap.get('view');

    // if(this.userRole=="Planner"){
    //   this.panelFlag = false;
    // }
    this.common.loader.next(true);
      setTimeout(() => {
        this.common.loader.next(false);
      }, 7000); // Adjust the time (in milliseconds) as needed

    this.showDraftBtn = true;
    this.showTableDialog = false;
    this.isRequester = this.userRole === 'Requester' ? true : false;
    this.activatedRoute.queryParams.subscribe(params => {
      this.editLTP = params['reqNum']
    });
    

    console.log("Edit LTP", this.editLTP);

    if(!this.editLTP){
      if(this.userRole === "Planner"){
        this.panelFlag = false;
      }
    }

    if (this.editLTP) {
      this.common.getCapexAPI('ltp-por?ltp=' + this.editLTP).subscribe({
        next: (
          (data) => {
           // this.common.loader.next(false);
            // console.log("Edit Data", data);
            this.ltpStatus = data['message'][0].LTP_Status;
            this.wbsStatus = data['message'][0].WBS_Status;
            if (this.userRole === "Planner") {
              if (this.wbsStatus === "Closed") {
                this.panelFlag = true;
                this.showSaveBtn = false;
              } else {
                this.panelFlag = false;
              }
            }            
            console.log("LTP Status", this.ltpStatus);
            if (this.ltpStatus === 'Pending Analyst Review' && this.userRole === 'Planner') {
              this.capexForm.disable();
              this.requestDetailsForm.disable();
              this.financialDetailsForm.disable();
              this.projectCostDetailsForm.disable();
              this.cashFlowDetailsForm.disable();

              // this.isDisabled = true;
            }
            if(this.ltpStatus === 'Draft'){
              this.showDraftBtn = true;
              this.showAnalystBtn = true;
            }
            if(this.ltpStatus === 'Pending Analyst Review'){
              if(this.userRole === 'Admin'){
                this.showDraftBtn = true;
                this.showInQueueBtn = true;
              }
              else if(this.userRole === 'Planner'){
                this.defaultFlag = false;
              }
            }
            if(this.isRequester){

              this.capexForm.disable();
              this.requestDetailsForm.disable();
              this.financialDetailsForm.disable();
              this.projectCostDetailsForm.disable();
              this.cashFlowDetailsForm.disable();

              this.showDraftBtn = false;
              this.showAnalystBtn = false;
              this.showInQueueBtn = false;
            }   

            this.fetchCapexForm(data['message'][0]);
          }),
        error: (
          (err) => {
            console.error("Error occurred:", err);
          })
      });

    }



    // this.common.getAPI('amd-get-labsite').subscribe({
    //   next: (
    //     (data) => {
    //       this.buildings = data;
    //       this.common.loader.next(false);
    //       for (let i = 0; i < this.buildings?.length; i++) {
    //         let tmp = this.buildings[i]['Location'];
    //         this.allSites.push(tmp);
    //       }
    //       this.allSites = this.allSites?.filter(function (elem, index, self) {
    //         return index === self.indexOf(elem);
    //       })
    //       this.allSites.sort();
    //       if (this.requestDetailsForm.get('building')?.value) {
    //         this.SearchBuilding();
    //         this.SearchLabFabName();
    //       }
    //       this.siteOptions = ['COR', 'ISL', 'SDG', 'SGP', 'Supplier']
    //     }
    //   ),
    //   error: (
    //     (error) => {
    //       console.error("Error occurred:", error);
    //       alert("Please connect with Asset Admin");
    //     })
    // });

    // this.common.getCapexAPI('capex-get-costcenter-data-itg').subscribe({
    //   next: (
    //     (data) => {
    //       this.financialCostCenterData = data['CostCenters']
    //       this.financialProgramDriversData = data['ProgramDrivers']
    //      // this.allCostCenterOptions = this.financialCostCenterData.map((a: any) => a['LAB_ID']);
    //       this.allCostCenterOptions = this.financialCostCenterData.map((a: any) => ({
    //       labId: a['LAB_ID'],
    //       costCenterOwner: a['Cost Center Owner'],
    //       orgL4: a['Level4'],
    //     }));
    //       this.commmitCostCenterOptions = this.financialCostCenterData?.filter((e: any) => e['Commit'] == true || e['Commit'] == 'true').map((a: any) => a['Cost Center']);
    //       this.depreciateCostCenterOptions = this.financialCostCenterData?.filter((e: any) => e['Depreciation'] == true || e['Depreciation'] == 'true').map((a: any) => a['Cost Center'])
    //       console.log("Cost Center Data", data);
    //     }),
    //   error: (
    //     (err) => {
    //       console.error("Error occurred:", err);
    //     })
    // });

    // this.common.getCapexAPI('capex-request-role').subscribe({
    //   next: (
    //     (data) => {
    //       this.financeOptions = data['Finance'].filter((a: any) => a['Capex_Request_Role'] == 'Finance' && a['Active'] == true).map((a: any) => a['Capex_Request_Role_Name']);
    //       this.plannerOptions = data['Planner'].filter((a: any) => a['Capex_Request_Role'] == 'Planner' && a['Active'] == true).map((a: any) => a['Capex_Request_Role_Name']);
    //     }),
    //   error: (
    //     (err) => {
    //       console.error("Error occurred:", err);
    //     })
    // });

    // this.common.getCapexAPI('ltp-por/external-funding').subscribe({
    //   next: (
    //     (data) => {
    //       this.externalOptions = data
    //         .map((user: { UserName: any; }) => user.UserName);
    //     }),
    //   error: (
    //     (err) => {
    //       console.error("Error occurred:", err);
    //     })
    // });

    forkJoin({
      labSite: this.common.getAPI('amd-get-labsite'),
      costCenterData: this.common.getCapexAPI('capex-get-costcenter-data-itg'),
      requestRole: this.common.getCapexAPI('capex-request-role'),
      externalFunding: this.common.getCapexAPI('ltp-por/external-funding')
    }).subscribe({
      next: ({ labSite, costCenterData, requestRole, externalFunding }) => {
        this.buildings = labSite;
        this.common.loader.next(false);
        for (let i = 0; i < this.buildings?.length; i++) {
          let tmp = this.buildings[i]['Location'];
          this.allSites.push(tmp);
        }
        this.allSites = this.allSites?.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        })
        this.allSites.sort();
        if (this.requestDetailsForm.get('building')?.value) {
          this.SearchBuilding();
          this.SearchLabFabName();
        }
        this.siteOptions = ['COR', 'ISL', 'SDG', 'SGP', 'Supplier'];
  
        this.financialCostCenterData = costCenterData['CostCenters'];
        this.financialProgramDriversData = costCenterData['ProgramDrivers'];
        this.allCostCenterOptions = this.financialCostCenterData.map((a: any) => ({
          labId: a['LAB_ID'],
          costCenterOwner: a['Cost Center Owner'],
          orgL4: a['Level4'],
        }));
        this.commmitCostCenterOptions = this.financialCostCenterData?.filter((e: any) => e['Commit'] == true || e['Commit'] == 'true').map((a: any) => a['Cost Center']);
        this.depreciateCostCenterOptions = this.financialCostCenterData?.filter((e: any) => e['Depreciation'] == true || e['Depreciation'] == 'true').map((a: any) => a['Cost Center']);
        console.log("Cost Center Data", costCenterData);
  
        this.financeOptions = requestRole['Finance'].filter((a: any) => a['Capex_Request_Role'] == 'Finance' && a['Active'] == true).map((a: any) => a['Capex_Request_Role_Name']);
        this.plannerOptions = requestRole['Planner'].filter((a: any) => a['Capex_Request_Role'] == 'Planner' && a['Active'] == true).map((a: any) => a['Capex_Request_Role_Name']);
  
        this.externalOptions = externalFunding.map((user: { UserName: any; }) => user.UserName);
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }

  formatDate(date: Date): string {
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are 0-based, so add 1
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}

  SubmitForAnalystReview() {
    this.common.loader.next(true);  

    // Mark all controls as touched to trigger validation
    this.requestDetailsForm.markAllAsTouched();
    this.financialDetailsForm.markAllAsTouched();
    this.projectCostDetailsForm.markAllAsTouched();
    this.cashFlowDetailsForm.markAllAsTouched();

    if(this.capexForm.valid){
    this.capexForm.value['requestDetails'] = this.requestDetailsForm.getRawValue();
    this.capexForm.value['financialDetails'] = this.financialDetailsForm.getRawValue();
    this.capexForm.value['projectCostDetails'] = this.projectCostDetailsForm.getRawValue();
    this.capexForm.value['cashFlowDetails'] = this.cashFlowDetailsForm.getRawValue();
    if(this.userRole == 'Admin'){
    this.capexForm.value['wbsDetails'] = this.wbsDetails.getFormValue();
    this.capexForm.value['wbsCashFlow'] = this.wbsCashFlow.getFormValue();
    this.capexForm.value['wbsCashFlowDetails'] = this.wbsCashFlowDetails.getFormValue();
    this.capexForm.value['assetDetails'] = this.assetDetails.getFormValue();
    }

    if (this.requestDetailsForm.valid) {
      this.capexForm.value['ltpStatus'] = 'Pending Analyst Review';
      if(this.isPORRequestType()){
        this.capexForm.value['por'] = this.porNum//this.ltpNum.replace('LTP', 'POR');
        this.capexForm.value['porStatus'] = 'Pending Analyst Review';
        }
      const payload = {
        ...this.capexForm.value,
        ltpNumber: this.ltpNum
      };
      this.common.postCapexAPI('ltp-por/save', payload).subscribe({
        next: (data) => {
          this.common.loader.next(false);
          this.ltpNum = data['message'];
          this.ltpStatus = 'Pending Analyst Review';
          if(this.isPORRequestType()){ 
            this.porStatus = 'Pending Analyst Review';
            this.common.show('success', this.porNum + ' has been submitted for analyst review');
          }
          else{
          this.common.show('success', this.ltpNum + ' has been submitted for analyst review');
          }
          this.showAnalystBtn = false;
          if (this.userRole === 'Planner'){
            this.showDraftBtn = false;
            this.defaultFlag = false;
            this.showAnalystBtn = false;
            this.showInQueueBtn = false;

            // Make all form controls non-editable
            this.capexForm.disable();
            this.requestDetailsForm.disable();
            this.financialDetailsForm.disable();
            this.projectCostDetailsForm.disable();
            this.cashFlowDetailsForm.disable();
          }

          if (this.userRole === 'Admin'){
            this.showDraftBtn = true;
            this.showInQueueBtn = true;
          }

          // Call the notification API to send email to AMD Admin
          const notificationBody = {
            notificationType: 'Pending Analyst Review',
            ltp: this.ltpNum,
            por: this.porNum,
            planner: this.capexForm.value.requestDetails.planner,
            projectManager: this.capexForm.value.requestDetails.projectManagerEmail,
            toolDescription: this.capexForm.value.requestDetails.itemDescription,
            building: this.capexForm.value.requestDetails.building,
            labFabInstallLocation: this.capexForm.value.requestDetails.labfabInstallLocation,
            commitQuarter: this.capexForm.value.cashFlowDetails.eventQuarter.commitQuarter
          };

          this.common.postCapexAPI('capex-notify', notificationBody).subscribe({
            next: (data) => {
              this.common.show('success', 'Email notification sent successfully.');
            },
            error: (err) => {
              console.error("Error sending email notification:", err);
              this.common.show('error', 'Failed to send email notification.');
            }
          });
        },
        error: (err) => {
          console.error("Error occurred:", err);
          this.common.loader.next(false);
          this.common.show('error', 'Error occurred while saving the data');
        }
      });
    } else {
      this.common.loader.next(false);
      this.common.show('warning', 'Please fill all the required fields');
    }
  }

  else{
    this.common.show('error', 'Please fill all the required fields');
    this.common.loader.next(false);
  }
  }

  SubmitInQueue() {

    this.common.loader.next(true);
    this.capexForm.value['requestDetails'] = this.requestDetailsForm.getRawValue();
    this.capexForm.value['financialDetails'] = this.financialDetailsForm.getRawValue();
    this.capexForm.value['projectCostDetails'] = this.projectCostDetailsForm.getRawValue();
    this.capexForm.value['cashFlowDetails'] = this.cashFlowDetailsForm.getRawValue();
    this.capexForm.value['wbsDetails'] = this.wbsDetails.getFormValue();
    this.capexForm.value['wbsCashFlow'] = this.wbsCashFlow.getFormValue();
    this.capexForm.value['wbsCashFlowDetails'] = this.wbsCashFlowDetails.getFormValue();
    this.capexForm.value['assetDetails'] = this.assetDetails.getFormValue();
    if (this.requestDetailsForm.valid) {
      this.capexForm.value['ltpStatus'] = 'In Queue';
      if(this.isPORRequestType()){
        this.capexForm.value['por'] = this.porNum
        this.capexForm.value['porStatus'] = 'In Queue';
        }
      const payload = {
        ...this.capexForm.value,
        ltpNumber: this.ltpNum
      };
      this.common.postCapexAPI('ltp-por/save', payload).subscribe({
        next: (
          (data) => {
            this.common.loader.next(false);
            this.ltpNum = data['message'];
            this.ltpStatus = 'In Queue';
            if(this.isPORRequestType()){ 
              this.porStatus = 'In Queue';
              this.common.show('success', 'Submitted to In Queue ' + this.porNum);
            }
            else{
            this.common.show('success', 'Submitted to In Queue ' + this.ltpNum);
            }
            this.showAnalystBtn = false;

            //this.showAnalystBtn = true;
            this.showInQueueBtn = false;

            // Call the notification API to send email to Planner
            // const notificationBody = {
            //   notificationType: 'In Queue',
            //   ltp: this.capexForm.value.ltpNumber
            // };
            // this.common.postCapexAPI('capex-notify', notificationBody).subscribe({
            //   next: (data) => {
            //     this.common.show('success', 'Email notification sent successfully.');
            //   },
            //   error: (err) => {
            //     console.error("Error sending email notification:", err);
            //     this.common.show('error', 'Failed to send email notification.');
            //   }
            // });

            if (this.isPORRequestType()) {
              //const estimatedProjectEndQtr = this.common.getQuarterFromDate(this.capexForm.value.wbsDetails.estimatedProjectEnd);
              const estimatedProjectEndQtr = this.capexForm.value.wbsDetails?.estimatedProjectEnd ? this.common.getQuarterFromDate(this.capexForm.value.wbsDetails.estimatedProjectEnd) : '';
              console.log("Estimated Project End Quarter:", estimatedProjectEndQtr);
              // Call the notification API to send email to AMD Admin
              const notificationBody = {
                notificationType: 'POR Generated',
                ltp: this.ltpNum,
                por: this.porNum,
                planner: this.capexForm.value.requestDetails.planner || '',
                projectManager: this.capexForm.value.requestDetails.projectManagerEmail || '',
                toolDescription: this.capexForm.value.requestDetails.itemDescription || '',
                commitQuarter: this.capexForm.value.cashFlowDetails.eventQuarter.commitQuarter || '',
                commitManager: this.capexForm.value.financialDetails.commitCCManager || '',
                estimatedProjectEnd: estimatedProjectEndQtr,
                depreciationManager: this.capexForm.value.financialDetails.deprCCManager || '',
                estimatedDepreciationCost: this.capexForm.value.projectCostDetails.estimatedQatrDepr || '',
                assets: this.capexForm.value.requestDetails.assets || '',
                assetLife: this.capexForm.value.requestDetails.assetUsefulLife || '',
                totalCost: this.projectCostDetailsForm.controls['totalCost'].value || '',
              };
              console.log(notificationBody);
              this.common.postCapexAPI('capex-notify', notificationBody).subscribe({
                next: (data) => {
                  this.common.show('success', 'Email notification sent successfully.');
                },
                error: (err) => {
                  console.error("Error sending email notification:", err);
                  this.common.show('error', 'Failed to send email notification.');
                }
              });
            }
          }),
        error: (
          (err) => {
            console.error("Error occurred:", err);
            this.common.loader.next(false);
            this.common.show('error', 'Error occurred while saving the data');
          })
      })
    }
    else {
      this.common.loader.next(false);
      this.common.show('warning', 'Please fill all the required fields');
    }
 }


  fetchCapexForm(capexAllFields: any) {
    let getCapexFieldsVal = capexAllFields;
    this.version = Number(getCapexFieldsVal?.Version);
    this.ltpNum = getCapexFieldsVal?.LTP;
    this.ltpStatus = getCapexFieldsVal?.LTP_Status;
    this.porNum = getCapexFieldsVal?.POR;
    this.porStatus = getCapexFieldsVal?.POR_Status;
    this.wbsNum = getCapexFieldsVal?.WBS;
    this.wbsStatus = getCapexFieldsVal?.WBS_Status;
    this.isWBSEmailSent = getCapexFieldsVal?.Is_WBS_Email_Sent || false;
    this.adjustedFinalQuarterMatDate = getCapexFieldsVal?.Adjusted_Final_Acceptance_Qtr_Date;
    this.Is_Request_WBS_Email_Sent = getCapexFieldsVal?.Is_Request_WBS_Email_Sent || false;
   // this.formattedItemCost = this.currencyPipe.transform(getCapexFieldsVal?.Item_Cost_$K, 'USD', 'symbol', '1.2-2');
   // this.originalRtoDate =  getCapexFieldsVal?.Acceptance_RTO_Qtr_Date;
    this.depreciationccManager = getCapexFieldsVal?.Depreciation_Cost_Center_Manager? `${getCapexFieldsVal.Depreciation_Cost_Center_Manager}@hp.com`: '';
    this.Depreciation_Manager_Approval = getCapexFieldsVal?.Depreciation_Manager_Approval?.Depreciation_Manager_Approval_Status;
    this.totalCost_Email = getCapexFieldsVal?.Total_Cost_$K;
      // Commit Quarter date check
      if (getCapexFieldsVal?.Commit_Qtr_Date && getCapexFieldsVal?.Commit_Qtr_Date < this.formatDate(this.minDate)) {
        this.minDate = getCapexFieldsVal?.Commit_Qtr_Date;
      }

      const localDate = new Date();
      //Factory Acceptance Quarter
      // if (getCapexFieldsVal?.Acceptance_RTO_Qtr_Date ) {
      //   this.minFactoryQuarterDate = getCapexFieldsVal?.Commit_Qtr_Date;
      // }

      this.minFactoryQuarterDate = getCapexFieldsVal?.Commit_Qtr_Date;
      this.maxFactoryQuarterDate = getCapexFieldsVal?.Delivery_RTE_Qtr_Date;

      //Site Acceptance Quarter
      if (getCapexFieldsVal?.Delivery_RTE_Qtr_Date) {
        if (getCapexFieldsVal?.Acceptance_RTO_Qtr_Date) {
          this.minSiteQuarterDate = getCapexFieldsVal?.Acceptance_RTO_Qtr_Date;
        }
        else {
          this.minSiteQuarterDate = getCapexFieldsVal?.Commit_Qtr_Date;
        }
        this.maxSiteQuarterDate = getCapexFieldsVal?.Final_Payment_Qtr_Date;
      }

      //Final Acceptance Quarter
      if (getCapexFieldsVal?.Final_Payment_Qtr_Date ) {
        this.minFinalQuarterDate = getCapexFieldsVal?.Delivery_RTE_Qtr_Date;
      }

    //Check default NRE value
    if(getCapexFieldsVal?.Default_NRE == "No NRE"){
      this.disableNREFields();
    }
    if(getCapexFieldsVal?.Hookup_Capitalized_Install_Costs_$K == 0){
      this.disableHookupFields();
    }

    this.checkVisibilityConditions();
    this.capexForm.setValue({
      ltpNumber: this.ltpNum,
      ltpStatus: getCapexFieldsVal?.LTP_Status,
      version: this.version,
      modifiedBy: this.userName,
      requestDetails: {
        requestType: getCapexFieldsVal?.Request_Type,
        projectType: getCapexFieldsVal?.Project_Type,
        projectManagerEmail: getCapexFieldsVal?.Project_Manager_Email,
        planner: getCapexFieldsVal?.Planner || '',
        finance: getCapexFieldsVal?.Finance,
        itemDescription: getCapexFieldsVal?.Tool_Name_Description,
        justification: getCapexFieldsVal?.Justification,
        impact: getCapexFieldsVal?.Impact_of_Not_Doing,
        external: getCapexFieldsVal?.External_Funding,
        buildSite: getCapexFieldsVal?.Build_Site_or_shipping_to_another_site || '',
        site: getCapexFieldsVal?.Site,
        building: getCapexFieldsVal?.Building,
        labfabInstallLocation: getCapexFieldsVal?.LabFab_Install_Location,
        assets: getCapexFieldsVal?.Number_of_Assets,
        assetUsefulLife: getCapexFieldsVal?.Asset_Useful_Life_in_yrs,
        comment: getCapexFieldsVal?.Comments || ''
      },
      financialDetails: {
        commitCostCenter: getCapexFieldsVal?.Commit_Cost_Center,
        depreciateCostCenter: getCapexFieldsVal?.Depreciation_Cost_Center,
        deprCCManager: getCapexFieldsVal?.Depreciation_Cost_Center_Manager || '',
        deprOrgL2: getCapexFieldsVal?.Depreciation_Org_L2,
        deprOrgL3: getCapexFieldsVal?.Depreciation_Org_L3,
        deprOrgL4: getCapexFieldsVal?.Depreciation_Org_L4,
        deprSection: getCapexFieldsVal?.Depreciation_Section,
        deprMRU: getCapexFieldsVal?.Depreciation_MRU,
        deprSubMRU: getCapexFieldsVal?.Depreciation_Sub_MRU,
        commitCCManager: getCapexFieldsVal?.Commit_Cost_Center_Manager,
        commitOrgL2: getCapexFieldsVal?.Commit_Org_L2,
        commitOrgL3: getCapexFieldsVal?.Commit_Org_L3,
        commitOrgL4: getCapexFieldsVal?.Commit_Org_L4,
        commitSection: getCapexFieldsVal?.Commit_Section,
        commitMRU: getCapexFieldsVal?.Commit_MRU,
        commitSubMRU: getCapexFieldsVal?.Commit_Sub_MRU,
        programDriver: getCapexFieldsVal?.Program_Driver,
        category: getCapexFieldsVal?.Category,
        rollUp: getCapexFieldsVal?.Rollup,
        businessUnit: getCapexFieldsVal?.Business_Unit,
        companyCode: getCapexFieldsVal?.Company_Code,
        region: getCapexFieldsVal?.Region
      },
      projectCostDetails: {
        itemCost: getCapexFieldsVal?.Item_Cost_$K,
        hookCapitalCost: getCapexFieldsVal?.Hookup_Capitalized_Install_Costs_$K,
        hookExpenseCost: getCapexFieldsVal?.Hookup_Expensed_Install_Costs_$K,
        //defaultNRE: getCapexFieldsVal?.Commit_Cost_Center,
        defaultNRE: getCapexFieldsVal?.Default_NRE || '',        
        engrNRECostCenter: getCapexFieldsVal?.Eng_NRE_Cost_Location,
        engrNREFTE: getCapexFieldsVal?.Engr_NRE_FTE_Days,
        engrNRECost: getCapexFieldsVal?.Eng_NRE_Cost_$K,
        techNRECostCenter: getCapexFieldsVal?.Tech_NRE_Cost_Location,
        techNREFTE: getCapexFieldsVal?.Tech_NRE_FTE_Days,
        techNRECost: getCapexFieldsVal?.Tech_NRE_Cost_$K,
        NREtotalCost: getCapexFieldsVal?.NRE_Total_Cost_$K,
        //totalCost: getCapexFieldsVal?.Total_Cost_$K,
        totalCost: getCapexFieldsVal ? (/\$\s*\d+(,\d{3})*(\.\d{2})?/.test(getCapexFieldsVal.Total_Cost_$K) ? getCapexFieldsVal.Total_Cost_$K : this.common.formatUSD(getCapexFieldsVal.Total_Cost_$K)) : undefined,
        //estimatedQatrDepr: getCapexFieldsVal?.Estimated_Quarterly_Depreciation,
        estimatedQatrDepr: getCapexFieldsVal ? (/\$\s*\d+(,\d{3})*(\.\d{2})?/.test(getCapexFieldsVal.Estimated_Quarterly_Depreciation) ? getCapexFieldsVal.Estimated_Quarterly_Depreciation : this.common.formatUSD(getCapexFieldsVal.Estimated_Quarterly_Depreciation)) : undefined,
      },
      cashFlowDetails: {
        eventQuarter: {
          commitQuarter: getCapexFieldsVal?.Commit_Qtr || '',
          commitQuarterMatDate: getCapexFieldsVal?.Commit_Qtr_Date,
          factoryQuarter: getCapexFieldsVal?.Acceptance_RTO_Qtr,
          factoryQuarterMatDate: getCapexFieldsVal?.Acceptance_RTO_Qtr_Date,
          siteQuarter: getCapexFieldsVal?.Delivery_RTE_Qtr,
          siteQuarterMatDate: getCapexFieldsVal?.Delivery_RTE_Qtr_Date,
          finalQuarter: getCapexFieldsVal?.Final_Payment_Qtr,
          finalQuarterMatDate: getCapexFieldsVal?.Final_Payment_Qtr_Date,
          estimatedProjectEnd: getCapexFieldsVal?.Estimated_Asset_Setup,
          estimatedProjectEndMatDate: getCapexFieldsVal?.Estimated_Asset_Setup_Date
        },
        adjustQuarter: {
          adjustedCommitQuarter: getCapexFieldsVal?.Adjusted_Commit_Qtr,
          adjustedFactoryQuarter: getCapexFieldsVal?.Adjusted_Fctory_Acceptance_Qtr,
          adjustedFactoryQuarterMatDate: getCapexFieldsVal?.Adjusted_Fctory_Acceptance_Qtr_Date,
          adjustedSiteQuarter: getCapexFieldsVal?.Adjusted_Site_Acceptance_Qtr,
          adjustedSiteQuarterMatDate: getCapexFieldsVal?.Adjusted_Site_Acceptance_Qtr_Date,
          adjustedFinalQuarter: getCapexFieldsVal?.Adjusted_Final_Acceptance_Qtr,
          adjustedFinalQuarterMatDate: getCapexFieldsVal?.Adjusted_Final_Acceptance_Qtr_Date,
          adjustedEstimatedProjectEnd: getCapexFieldsVal?.Adjusted_Estimated_Asset_Setup,
          adjustedEstimatedProjectEndMatDate: getCapexFieldsVal?.Adjusted_Estimated_Asset_Setup_Date,
          monthsOverdue: getCapexFieldsVal?.Months_Overdue,
          rtoProjectStatus: getCapexFieldsVal?.RTO_Project_Status,
          rtoProjectNote: getCapexFieldsVal?.RTO_Project_Note,
          rtoDateChangeReason: getCapexFieldsVal && getCapexFieldsVal.RTO_Date_Change_Reason ? getCapexFieldsVal.RTO_Date_Change_Reason : '',
          rtoDateChangeComment: getCapexFieldsVal && getCapexFieldsVal.RTO_Date_Change_Comment ? getCapexFieldsVal.RTO_Date_Change_Comment : '',
        },
        itemCashFlow: {
          itemCashFlowMethod: getCapexFieldsVal?.Item_Cash_Flow_Method,
          itemCheckSum: getCapexFieldsVal?.Item_Check_Sum,
          itemCashFlowTerms: getCapexFieldsVal?.Item_Cash_Flow_PO_Terms,
          itemCashFlowCommit: getCapexFieldsVal?.Item_Cash_Flow_Commit_Qtr,
          itemCashFlowFactory: getCapexFieldsVal?.Item_Cash_Flow_Factory_Acceptance_Qtr,
          itemCashFlowSite: getCapexFieldsVal?.Item_Cash_Flow_Site_Acceptance_Qtr,
          itemCashFlowFinal: getCapexFieldsVal?.Item_Cash_Flow_Final_Acceptance_Qtr,
        },
        NRECashFlow: {
          NRECashFlowMethod: getCapexFieldsVal?.NRE_Cash_Flow_Method,
          NRECheckSum: getCapexFieldsVal?.NRE_Check_Sum,
          NRECashFlowTerms: getCapexFieldsVal?.NRE_Cash_Flow_Terms,
          NRECashFlowCommit: getCapexFieldsVal?.NRE_Cash_Flow_Commit_Qtr,
          NRECashFlowFactory: getCapexFieldsVal?.NRE_Cash_Flow_Factory_Acceptance_Qtr,
          NRECashFlowSite: getCapexFieldsVal?.NRE_Cash_Flow_Site_Acceptance_Qtr,
          NRECashFlowFinal: getCapexFieldsVal?.NRE_Cash_Flow_Final_Acceptance_Qtr,
        },
        hookUpCashFlow: {
          hookUpCashFlowMethod: getCapexFieldsVal?.Hookup_Cash_Flow_Method,
          hookUpCheckSum: getCapexFieldsVal?.Hookup_Check_Sum,
          hookUpCashFlowTerms: getCapexFieldsVal?.Hookup_Cash_Flow_Terms,
          hookUpCashFlowCommit: getCapexFieldsVal?.Hookup_Cash_Flow_Commit_Qtr,
          hookUpCashFlowFactory: getCapexFieldsVal?.Hookup_Cash_Flow_Factory_Acceptance_Qtr,
          hookUpCashFlowSite: getCapexFieldsVal?.Hookup_Cash_Flow_Site_Acceptance_Qtr,
          hookUpCashFlowFinal: getCapexFieldsVal?.Hookup_Cash_Flow_Final_Acceptance_Qtr,
        }
      }
    });
    if(this.panelFlag){
      // if (this.porNum && !this.wbsStatus) {
      //   //this.wbsDetails.wbsDetailsForm.controls['wbsStatus'].value?.setValue('Requester');
      //   this.wbsDetails.wbsDetailsForm.controls['wbsStatus'].setValue('Requested');
      // }
      if(getCapexFieldsVal?.RTO_Date_Change_Comment && getCapexFieldsVal?.RTO_Date_Change_Reason){
        this.isRtoDateChanged = true;
      }
      if(getCapexFieldsVal?.WBS && getCapexFieldsVal.Actual_Commit_Date){
        this.isWBSSectionFilled = true;
      }
      this.wbsDetails.setFormValue(getCapexFieldsVal);
      this.wbsCashFlow.setFormValue(getCapexFieldsVal);
      this.wbsCashFlowDetails.setFormValue(getCapexFieldsVal);
      if (getCapexFieldsVal?.Assets_Data.length != 0) {
        this.assetDraftFlag = true;
        this.assetDetails.setFormValue(getCapexFieldsVal);
      }
    }
  }

  showTable(event: Event) {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    // dialogConfig.height = '65%';
    dialogConfig.width = '100%';
    dialogConfig.data = {
      tabletemplate: true,
      // historyStatus: historyStatus,
      title: 'Planner & Finance Data',
      // assetID: assetIdHistory
    }
    const dialogRef = this.dialog.open(TableDialogComponent, dialogConfig)
    dialogRef.afterClosed().subscribe({
      next: (
        (result) => {
          this.showTableDialog = false;
        }
      ),
      error: (
        error => {
          this.common.errorHandler(error);
          this.common.loader.next(false);
        }
      )
    });
  }

  showExternalTable(event: Event) {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    // dialogConfig.height = '65%';
    dialogConfig.width = '100%';
    dialogConfig.data = {
      tabletemplate: true,
      // historyStatus: historyStatus,
      title: 'External Funding Data',
      // assetID: assetIdHistory
    }
    const dialogRef = this.dialog.open(ExternalTableDialogComponent, dialogConfig)
    dialogRef.afterClosed().subscribe({
      next: (
        (result) => {
          this.showTableDialog = false;
        }
      ),
      error: (
        error => {
          this.common.errorHandler(error);
          this.common.loader.next(false);
        }
      )
    });
  }

  changeProjectType(event: any) {
    this.selectedProjectTypeVal = event.value;
    if (this.selectedProjectTypeVal === 'M&E') {
      this.requestDetailsForm.controls['assetUsefulLife'].setValue('10');
    }
    if (this.selectedProjectTypeVal === 'L&B') {
      this.popUpText = 'You have selected the Project Type of L&B (Land and Building). L&B which is associated with the purchase of land, basic building structure, or building outfit. Are you sure the item you are looking to capitalize is associated with L&B? Please select the appropriate option below. If you select No, the Project Type will be set to M&E (Machinery & Equipment).'
      this.openDialogMessage(this.popUpText, '');
      this.requestDetailsForm.controls['assetUsefulLife'].setValue('10');
    }
  }

  showCommitCostCenter() {
    this.filteredCommitCCOptions = this.financialDetailsForm.controls['commitCostCenter'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCommitCostCenter(value))
      );

    this.financialDetailsForm.controls['commitCostCenter'].valueChanges
      .subscribe((value) => {
        Object.keys(this.financialDetailsForm.controls).forEach(key => {
          if (key !== 'commitCostCenter' && key.includes('commit')) {
            this.financialDetailsForm.controls[key].reset();
          }
        })
        this.programDriverOptions = [];
        this.financialDetailsForm.controls['programDriver'].setValue('');
        this.selectedCCRow = this.financialCostCenterData.filter((e: any) => e['LAB_ID'] == value);
        if (this.selectedCCRow.length > 0) {
          this.financialDetailsForm.patchValue({
            'commitCCManager': this.selectedCCRow[0]['Cost Center Owner'],
            'commitOrgL2': this.selectedCCRow[0]['Level2'],
            'commitOrgL3': this.selectedCCRow[0]['Level3'],
            'commitOrgL4': this.selectedCCRow[0]['Level4'],
            'commitSection': this.selectedCCRow[0]['Section'],
            'commitMRU': this.selectedCCRow[0]['MRU Code'],
            'commitSubMRU': this.selectedCCRow[0]['Sub_MRU']
          });

          this.programDriverOptions = this.financialProgramDriversData?.filter((e: any) => e['Level3'] == this.financialDetailsForm.controls['commitOrgL3'].value).map((a: any) => a['ProgramDriver']);
        }
      })
  }

  showDepreciateCostCenter() {
    this.filteredDepreciateCCOptions = this.financialDetailsForm.controls['depreciateCostCenter'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterDepreciateCostCenter(value))
      );

    this.financialDetailsForm.controls['depreciateCostCenter'].valueChanges
      .subscribe((value) => {
        Object.keys(this.financialDetailsForm.controls).forEach(key => {
          if (key !== 'depreciateCostCenter' && key.includes('depr')) {
            this.financialDetailsForm.controls[key].reset();
          }
        })
        this.selectedCCRow = this.financialCostCenterData.filter((e: any) => e['LAB_ID'] == this.financialDetailsForm.controls['depreciateCostCenter'].value);
        if (this.selectedCCRow.length > 0) {
          this.financialDetailsForm.patchValue({
            'deprCCManager': this.selectedCCRow[0]['Cost Center Owner'],
            'deprOrgL2': this.selectedCCRow[0]['Level2'],
            'deprOrgL3': this.selectedCCRow[0]['Level3'],
            'deprOrgL4': this.selectedCCRow[0]['Level4'],
            'deprSection': this.selectedCCRow[0]['Section'],
            'deprMRU': this.selectedCCRow[0]['MRU Code'],
            'deprSubMRU': this.selectedCCRow[0]['Sub_MRU']
          });
        }
      })
  }

  showProgramDriversOptions() {
    if (this.financialDetailsForm.controls['commitCCManager'].value === null || this.financialDetailsForm.controls['commitCCManager'].value === '' || this.financialDetailsForm.controls['commitCCManager'].value === undefined) {
      this.common.show('warning', 'Please select Commit Cost Center to view Program Drivers');
      //return
    }
    this.filteredProgramDriverOptions = this.financialDetailsForm.controls['programDriver'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterProgramDrivers(value))
      );

    this.financialDetailsForm.controls['programDriver'].valueChanges
      .subscribe((value) => {
        Object.keys(this.financialDetailsForm.controls).forEach(key => {
          if (key !== 'programDriver') {
            this.financialDetailsForm.controls['category'].reset();
            this.financialDetailsForm.controls['rollUp'].reset();
            this.financialDetailsForm.controls['businessUnit'].reset();
            this.financialDetailsForm.controls['companyCode'].reset();
            this.financialDetailsForm.controls['region'].reset();
          }
        })
        this.selectedProgramDriverRow = this.financialProgramDriversData.filter((e: any) => e['ProgramDriver'] == this.financialDetailsForm.controls['programDriver'].value);
        if (this.selectedProgramDriverRow.length > 0) {
          this.companyCode = this.financialDetailsForm.controls['commitCostCenter'].value.substring(0, 4);
          this.getRegionValue();
          this.financialDetailsForm.controls['category'].setValue(this.selectedProgramDriverRow[0]['Category']);
          this.financialDetailsForm.controls['rollUp'].setValue(this.selectedProgramDriverRow[0]['RollUp']);
          this.financialDetailsForm.controls['businessUnit'].setValue(this.selectedProgramDriverRow[0]['BusinessUnit']);
          this.financialDetailsForm.controls['companyCode'].setValue(this.companyCode);
        }
      })
  }

  getRegionValue() {
    let first2DigitsCC = this.companyCode.substring(0, 2);
    if (first2DigitsCC == 'IL' || first2DigitsCC == 'ES' || first2DigitsCC == 'DE') {
      this.region = 'EMEA';
    }
    else if (first2DigitsCC == 'IN' || first2DigitsCC == 'SG' || first2DigitsCC == 'MY') {
      this.region = 'APJ';
    }
    else if (first2DigitsCC == 'US') {
      this.region = 'AMS';
    }
    this.financialDetailsForm.controls['region'].setValue(this.region);
  }

  showEngrNRECostObj() {
    this.filteredEngrNRECostObjOptions = this.projectCostDetailsForm.controls['engrNRECostCenter'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterNRECostObj(value))
      );
  }

  showTechNRECostObj() {
    this.filteredTechNRECostObjOptions = this.projectCostDetailsForm.controls['techNRECostCenter'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterNRECostObj(value))
      );
  }

  private _filterNRECostObj(val: string): string[] {
    const filterValue = val?.toLowerCase();
  //  return this.allCostCenterOptions?.filter((option: any) => option?.toLowerCase().includes(filterValue));

  return this.allCostCenterOptions
      ?.filter((option:any) =>
        option?.labId?.toLowerCase().includes(filterValue) //&& 
       // option?.type === type // Assuming there's a 'type' field to distinguish cost centers
      )
      // .map((option:any) => ({
      //   label: `${option.labId} (${option.costCenterOwner})`, // Format for display
      //   value: option.labId,
      // }));
      .map((option: any) => ({
        labId: option.labId,
        costCenterOwner: option.costCenterOwner,
        orgL4: option.orgL4
      }));
    // let commitCostCenter = this.financialDetailsForm.controls['commitCostCenter'].value;
    // let commitCostCenterPrefix = commitCostCenter ? commitCostCenter.slice(0, 2) : '';
    // return this.allCostCenterOptions
    // .filter((option: any) => option.toLowerCase().includes(filterValue) && option.startsWith(commitCostCenterPrefix));
  }

  // private _filterCommitCostCenter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   const commitCostCenter = this.financialDetailsForm.controls['depreciateCostCenter'].value;
  //   const commitCostCenterPrefix = commitCostCenter ? commitCostCenter.slice(0, 2) : '';
  //   this.projectCostDetailsForm.controls['engrNRECostCenter'].setValue('');
  //   this.projectCostDetailsForm.controls['techNRECostCenter'].setValue('');
  //   //return this.commmitCostCenterOptions.filter((option: any) => option.toLowerCase().includes(filterValue));
  //   return this.commmitCostCenterOptions
  //   ?.filter((option: any) => 
  //     option?.labId?.toLowerCase().includes(filterValue)
  //  )
  //   .map((option: any) => ({
  //     // commitCostCenter: option['LAB_ID'],
  //     // commitCCManager: option['Cost Center Owner'],
  //     // commitOrgL2: option['Level2']
  //     labId: option.labId,
  //     costCenterOwner: option.costCenterOwner,
  //     orgL4: option.orgL4
  //   }));
  // }

  private _filterCommitCostCenter(value: string): any[] {
    const filterValue = value?.toLowerCase() || ''; // Handle undefined/null case
    const commitCostCenter = this.financialDetailsForm.controls['depreciateCostCenter'].value;
    const commitCostCenterPrefix = commitCostCenter ? commitCostCenter.slice(0, 2) : '';
  
    // Reset dependent fields
    this.projectCostDetailsForm.controls['engrNRECostCenter'].setValue('');
    this.projectCostDetailsForm.controls['techNRECostCenter'].setValue('');
    console.log(this.commmitCostCenterOptions);
  
    return this.commmitCostCenterOptions
      ?.filter((option: any) => option?.labId?.toLowerCase().includes(filterValue))
      .map((option: any) => ({
        labId: option.labId,
        costCenterOwner: option.costCenterOwner,
        orgL4: option.orgL4
      }));
  }
  

  private _filterDepreciateCostCenter(val: string): string[] {
    const filterValue = val.toLowerCase();
    const depreciateCostCenter = this.financialDetailsForm.controls['commitCostCenter'].value;
    const depreciateCostCenterPrefix = depreciateCostCenter ? depreciateCostCenter.slice(0, 2) : '';
    return this.depreciateCostCenterOptions
      ?.filter((option: any) => option?.labId?.toLowerCase().includes(filterValue))
      .map((option: any) => ({
        labId: option.labId,
        costCenterOwner: option.costCenterOwner,
        orgL4: option.orgL4
      }));
  }

  private _filterProgramDrivers(value: string): string[] {

    const filterValue = this.programDriverOptions?.filter((option: any) => option.toLowerCase().includes(value.toLowerCase()));
    return filterValue?.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));

  }

  changeAssetLifeVal(val: any) {
    //this.capexForm.controls['assetUsefulLife'].setValue(val);
    this.requestDetailsForm.controls['assetUsefulLife'].valueChanges
      .subscribe((value) => {
        if (this.selectedProjectTypeVal == 'M&E') {
          if (value > 10 && value <= 40) {
            this.popUpText = 'The default useful life for M&E in MTO is 10 years. However, depending on the type of asset, it could have a useful life up to 40 years. Contact the asset administrator for further assistance.'
            if (this.dialog.openDialogs.length > 0) {
              //this.dialog.closeAll();
              this.dialog.openDialogs.forEach((dialog) => dialog.close());
            }
            this.openDialogMessage(this.popUpText, '');
          }
          else if (value > 40) {
            this.popUpText = 'The default useful life for M&E in MTO is 10 years. However, depending on the type of asset, it could have a useful life up to 40 years. Contact the asset administrator for further assistance.'
            if (this.dialog.openDialogs.length > 0) {
              //this.dialog.closeAll();
              this.dialog.openDialogs.forEach((dialog) => dialog.close());
            }
            this.openDialogMessage(this.popUpText, '');
          }
        }
        let quaterDepr = this.requestDetailsForm.controls['assetUsefulLife'].value !== 0 ? (this.projectCostDetailsForm.controls['totalCost'].value / this.requestDetailsForm.controls['assetUsefulLife'].value / 4) : 0;
      //  this.projectCostDetailsForm.controls['estimatedQatrDepr'].setValue((quaterDepr).toFixed(2));
      this.projectCostDetailsForm.controls['estimatedQatrDepr'].setValue(this.common.formatUSD(quaterDepr));
      });
  }

  downloadPdf(doctype: string) {
    let url
    if (doctype == 'extFunding'){
     url = 'https://hp.sharepoint.com/:b:/r/sites/ww/technical-learning-center/AMD/CapitalExternalFunding.pdf?csf=1&web=1'
    }
    else if (doctype == 'usefulLife'){
      url = 'https://hp.sharepoint.com/:b:/r/sites/ww/technical-learning-center/AMD/Asset Class Code.pdf?csf=1&web=1';
    }
    else if (doctype == 'paymentTerms'){
      url = 'assets/docs/Payment Terms.pdf';
    }
    else if (doctype == 'itemCost'){
      url = 'https://hp.sharepoint.com/:b:/r/sites/ww/technical-learning-center/AMD/Asset Capitalization.pdf?csf=1&web=1';
    }
    window.open(url, '_blank');
    return false;
  }

  // openPanel(panelName: string) {
  //   this.openPanels.push(panelName);
  //   switch (panelName) {
  //     case 'Request Panel':
  //       this.isRequestDetailPanelOpen = true;
  //       break;
  //     case 'Financial Panel':
  //       this.isFinancialPanelOpen = true;
  //       this.onFinancialPanelOpen();
  //       break;
  //   }
  // }


  onPanelClick(panelName: string) {
    let panelClicked = panelName;
    console.log("Request Detail Panel Opened");
    // const allRequestFieldsFilled = Object.keys(this.requestDetailsForm.controls).every(key =>
    // this.financialDetailsForm.get(key)?.value);
    if (this.requestDetailsForm.valid && this.financialDetailsForm.valid && this.projectCostDetailsForm.valid) {
      if(this.userRole == 'Planner' && this.wbsStatus === 'Closed'){
        this.requestDetailsForm.disable();
      }
      this.onCashFlowPanelOpen();
    }
    else if (this.requestDetailsForm.valid && this.financialDetailsForm.valid) {
      if(this.userRole == 'Planner' && this.wbsStatus === 'Closed'){
        this.requestDetailsForm.disable();
        this.onProjectCostPanelOpen();
      }
      else{
      this.onProjectCostPanelOpen();
      this.cashFlowDetailsForm.reset();
      this.cashFlowDetailsForm.disable();
      }
    }
    else if (this.requestDetailsForm.valid) {
      if(this.userRole == 'Planner' && this.wbsStatus === 'Closed'){
        this.requestDetailsForm.disable();
        this.onFinancialPanelOpen();
        this.common.setData(this.requestDetailsForm.value);
      }
      else{
      this.onFinancialPanelOpen();
      this.projectCostDetailsForm.reset();
      this.projectCostDetailsForm.disable();
      this.common.setData(this.requestDetailsForm.value);
      //this.requestDetailsFormValid.emit(this.requestDetailsForm.value);
      }
    }
    else {
      if(this.userRole == 'Planner' && this.wbsStatus === 'Closed'){
        this.requestDetailsForm.disable();
      }
      if(this.userRole == 'Planner' && this.ltpStatus === 'Pending Analyst Review'){
        this.requestDetailsForm.disable();
      }
      else{
      this.financialDetailsForm.reset();
      this.financialDetailsForm.disable();

      this.projectCostDetailsForm.reset();
      this.projectCostDetailsForm.disable();

      this.cashFlowDetailsForm.reset();
      this.cashFlowDetailsForm.disable();
      }
    }
  }

  onFinancialPanelOpen() {
    console.log("Financial Panel Opened");
    if(this.defaultFlag){
      if (this.requestDetailsForm.valid) {
        Object.keys(this.financialDetailsForm.controls).forEach(key => {
          const myControl = this.financialDetailsForm.get(key);
          if (myControl && myControl.disabled && myControl.validator) {
            this.financialDetailsForm.get(key)?.enable();
          }
        });
       // this.commmitCostCenterOptions = this.financialCostCenterData?.filter((e: any) => e['Commit'] == true).map((a: any) => a['LAB_ID']);
       this.commmitCostCenterOptions = this.financialCostCenterData
          ?.filter((e: any) => e['Commit'] == true || e['Commit'] == 'true')
          .map((a: any) => ({
            labId: a['LAB_ID'],
            costCenterOwner: a['Cost Center Owner'],
            orgL4: a['Level4']
          }));
       // this.depreciateCostCenterOptions = this.financialCostCenterData?.filter((e: any) => e['Depreciation'] == true).map((a: any) => a['LAB_ID']);
       this.depreciateCostCenterOptions = this.financialCostCenterData
        ?.filter((e: any) => e['Depreciation'] == true || e['Depreciation'] == 'true')
        .map((a: any) => ({
          labId: a['LAB_ID'],
          costCenterOwner: a['Cost Center Owner'],
          orgL4: a['Level4']
        }));
      }
      else if (this.wbsStatus === 'Closed'){
        Object.keys(this.financialDetailsForm.controls).forEach(key => {
          const myControl = this.financialDetailsForm.get(key);
          if (myControl && myControl.disabled && myControl.validator) {
            this.financialDetailsForm.get(key)?.enable();
          }
        });
       // this.commmitCostCenterOptions = this.financialCostCenterData?.filter((e: any) => e['Commit'] == true).map((a: any) => a['LAB_ID']);
       this.commmitCostCenterOptions = this.financialCostCenterData
          ?.filter((e: any) => e['Commit'] == true || e['Commit'] == 'true')
          .map((a: any) => ({
            labId: a['LAB_ID'],
            costCenterOwner: a['Cost Center Owner'],
            orgL4: a['Level4']
          }));
        //this.depreciateCostCenterOptions = this.financialCostCenterData?.filter((e: any) => e['Depreciation'] == true).map((a: any) => a['LAB_ID']);
        this.depreciateCostCenterOptions = this.financialCostCenterData
        ?.filter((e: any) => e['Depreciation'] == true || e['Depreciation'] == 'true')
        .map((a: any) => ({
          labId: a['LAB_ID'],
          costCenterOwner: a['Cost Center Owner'],
          orgL4: a['Level4']
        }));
      }
      else {
        this.commmitCostCenterOptions = [];
        this.depreciateCostCenterOptions = [];
        this.financialDetailsForm.reset();
        this.common.show('warning', 'Please fill all the required fields in Request Detail Section before proceeding to Financial Details');
        this.financialDetailsForm.disable();
      }
    }
    if(this.userRole == 'Planner' && this.wbsStatus == 'Closed'){
      this.financialDetailsForm.disable();
    }
    if(this.userRole == 'Planner' && this.ltpStatus === 'Pending Analyst Review'){
      this.financialDetailsForm.disable();
    }
  }

  onProjectCostPanelOpen() {
    console.log("Project Cost Panel Opened");
    if (this.defaultFlag) {
      if (this.financialDetailsForm.valid) {
        const defaultNREValue = this.projectCostDetailsForm.get('defaultNRE')?.value;

        Object.keys(this.projectCostDetailsForm.controls).forEach(key => {
          const myControl = this.projectCostDetailsForm.get(key);
          if (myControl && myControl.disabled && (myControl.validator)) {
            if (defaultNREValue) {
              this.onCheckboxChange(defaultNREValue);
            }
            else{
              this.projectCostDetailsForm.get(key)?.enable();
            }
          }
        });

        // Check if No NRE is selected and disable fields accordingly
        if (this.projectCostDetailsForm.get('defaultNRE')?.value === 'No NRE') {
          this.disableNREFields();
        }
        if (this.projectCostDetailsForm.get('hookCapitalCost')?.value == 0) {
          this.disableHookupFields();
        }
        else{
          this.enableHookupFields();
        }
      }
     else if (this.wbsStatus === 'Closed') {
      Object.keys(this.projectCostDetailsForm.controls).forEach(key => {
        const myControl = this.projectCostDetailsForm.get(key);
        if (myControl && myControl.disabled && (myControl.validator || myControl.enabled)) {
          this.projectCostDetailsForm.get(key)?.enable();
          this.onCheckboxChange(this.nreCheckBox);   
        }
      });
     } 
      else {
        this.projectCostDetailsForm.reset();
        this.common.show('warning', 'Please fill all the required fields in Financial Details Section before proceeding to Project Cost Details');
        this.projectCostDetailsForm.disable();
      }
    }
    if(this.userRole == 'Planner' && this.wbsStatus == 'Closed'){
      this.projectCostDetailsForm.disable();
    }
    if(this.userRole == 'Planner' && this.ltpStatus === 'Pending Analyst Review'){
      this.projectCostDetailsForm.disable();
    }
  }

  onCashFlowPanelOpen() {
    if(this.defaultFlag){
    console.log("Cash Flow Details Panel Opened");
    if (this.projectCostDetailsForm.valid) {
      Object.keys(this.cashFlowDetailsForm.controls).forEach(key => {
        let formGroup = this.cashFlowDetailsForm.controls[key] as FormGroup;
        Object.keys(formGroup.controls).forEach(innerKey => {
          let myControl = formGroup.controls[innerKey];
          if ((myControl.value == "" || myControl.value == null) && (innerKey == "itemCashFlowMethod" || innerKey == "NRECashFlowMethod" || innerKey == "hookUpCashFlowMethod")) {
            myControl.setValue("Standard Terms (%)")
          }
          if (myControl && myControl.disabled && (myControl.validator || myControl.enabled)) {
            myControl.enable();
          }
        });
      });

       // Check if No NRE is selected and disable fields accordingly
       if (this.projectCostDetailsForm.get('defaultNRE')?.value === 'No NRE') {
        this.disableNREFields();
      }
       // Check if No NRE is selected and disable fields accordingly
       if (this.projectCostDetailsForm.get('hookCapitalCost')?.value == 0) {
        this.disableHookupFields();
      }else{
        this.enableHookupFields();
      }
    }
    else if (this.wbsStatus === 'Closed') {
      Object.keys(this.cashFlowDetailsForm.controls).forEach(key => {
        let formGroup = this.cashFlowDetailsForm.controls[key] as FormGroup;
        Object.keys(formGroup.controls).forEach(innerKey => {
          let myControl = formGroup.controls[innerKey];
          if ((myControl.value == "" || myControl.value == null) && (innerKey == "itemCashFlowMethod" || innerKey == "NRECashFlowMethod" || innerKey == "hookUpCashFlowMethod")) {
            myControl.setValue("Standard Terms (%)")
          }
          if (myControl && myControl.disabled && (myControl.validator || myControl.enabled)) {
            myControl.enable();
          }
        });
      });
    }
    else {
      if(this.ltpStatus == "Converted"){
        this.cashFlowDetailsForm.disable();
      }
      else{
        this.cashFlowDetailsForm.reset();
        this.cashFlowDetailsForm.disable();
      }
      this.common.show('warning', 'Please fill all the required fields in Project Cost Details Section before proceeding to Cash Flow Details');
      //this.cashFlowDetailsForm.disable();
    }

    this.adjustQuarterForm.controls['adjustedEstimatedProjectEndMatDate'].disable();
    this.eventQuarterForm.controls['estimatedProjectEndMatDate'].disable();

    if(this.wbsStatus === 'Active' || this.wbsStatus === 'Closed'){
      this.eventQuarterForm.disable();
      this.adjustQuarterForm.controls['adjustedEstimatedProjectEndMatDate'].disable();

    }
  }

  if(this.userRole == 'Planner' && this.wbsStatus == 'Closed'){
    this.eventQuarterForm.disable();
    this.adjustQuarterForm.disable();
    this.itemCashFlowForm.disable();
    this.NRECashFlowForm.disable();
    this.hookUpCashFlowForm.disable();
  }
  if(this.userRole == 'Planner' && this.ltpStatus === 'Pending Analyst Review'){
    this.eventQuarterForm.disable();
    this.adjustQuarterForm.disable();
    this.itemCashFlowForm.disable();
    this.NRECashFlowForm.disable();
    this.hookUpCashFlowForm.disable();
  }
  }

  validateInput(fieldName: string, value: any) {
    let options;
    if (fieldName === 'commit') {
      options = this.commmitCostCenterOptions;
    }
    else if (fieldName === 'depreciate') {
      options = this.depreciateCostCenterOptions;
    }
    if (options.indexOf(value) === -1) {
      this.financialDetailsForm.controls[fieldName + 'CostCenterControl'].setValue('');
    }
  }

  SearchBuilding() {
    this.siteName = this.requestDetailsForm.get("site")?.value;
    if (this.siteName) {
      this.filteredBuilding = [];
      this.filteredLabFabName = [];
      for (let i = 0; i < this.buildings?.length; i++) {
        if (this.buildings[i]['Location'] == this.requestDetailsForm.get("site")?.value) {
          let tmp = this.buildings[i]['Lab_name'];
          this.filteredBuilding.push(tmp);
        }
      }
      this.filteredBuilding = this.filteredBuilding?.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.filteredBuilding.sort();
      this.common.buildingData.next({ buildings: this.buildings, filteredBuilding: this.filteredBuilding });
    }

  }

  onBuildSiteChange(event: MatSelectChange) {
    const selectedValue = event.value;
    if (selectedValue === 'None') {
      this.requestDetailsForm.controls['buildSite'].setValue(null);
    }
  }

  SearchLabFabName() {
    this.buildingName = this.requestDetailsForm.get("building")?.value
    this.filteredLabFabName = this.buildings?.filter((e: any) => e.Location == this.siteName && e.Lab_name == this.buildingName).map((a: any) => a.LabFab_Name)
    this.filteredLabFabName = this.filteredLabFabName?.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })
    this.filteredLabFabName?.sort();
  }

  validUserLdap(user: any) {
    this.userVerifyLoading = true;
    this.userVerify = false
    this.userInvalid = false;
    this.RequestLdapValidation['validateEmailDetails'] = user.includes('.') ? (user.includes('@hp.com') ? user : user + '@hp.com') : user
    if (this.RequestLdapValidation['validateEmailDetails'] != '') {
      this.common.postAPI('amd-ldap-email-validation', this.RequestLdapValidation).subscribe((data: any) => {
        this.userVerifyLoading = false;
        if (data?.statusCode == 200 && data?.body && data?.body?.length) {
          this.userVerify = true;
          let emailString = "";
          let projectManagerEmail_Ldap = ''
          for (let x of data?.body) {
            if (x['email']) {
              emailString += x['email'] + ";"
            }
          }
          if (emailString.charAt(emailString.length - 1) == ';') {
            projectManagerEmail_Ldap = emailString.slice(0, -1)
          }
          this.requestDetailsForm.get('projectManagerEmail')?.setValue(projectManagerEmail_Ldap)
        }
        if (data?.errorMSg || data.body?.length == 0 || data.body?.userNotFound) {
          this.userInvalid = true;
        }
        if (data.body?.userNotFound?.length == 0) {
          this.userInvalid = false;
          this.userVerify = true;
        }
      });
    }
  }

  enterEmail(event: any) {
    this.userVerify = false;
    this.userInvalid = false;
    // this.validUserLdap(this.capexForm.get('projectManagerEmail')?.value);
  }

  validateHookCapitalCostControl() {
    this.projectCostDetailsForm.controls['hookCapitalCost'].valueChanges.subscribe(val => {
      const value = this.common.parseCurrency(val); // Ensure parseCurrency returns a number
      if (value && value > 0) {
        // Check if the value is already formatted as USD
        // const isFormatted = /^\$\d{1,3}(,\d{3})*(\.\d{2})?$/.test(val); // Use 'val' to check if it's already formatted
  
        // if (!isFormatted) {
        //   const formattedValue = this.common.formatUSD(value); // Pass the number to formatUSD
        //   this.projectCostDetailsForm.controls['hookCapitalCost'].setValue(formattedValue, { emitEvent: false });
        // }
        this.enableHookupFields();
      } else {
        //this.projectCostDetailsForm.controls['hookCapitalCost'].setValue(this.common.formatUSD(0));
        this.disableHookupFields();
      }
      this.totalCostCalc(); // Call totalCostCalc whenever the value changes
    });
  }

  validateItemCostControl() {
    this.projectCostDetailsForm.controls['itemCost'].valueChanges.subscribe(value => {
      const parsedValue = this.common.parseCurrency(value);
      // let validationResult = this.fourDigitValidator(this.projectCostDetailsForm.controls['itemCost']);
      let validationResult = this.fourDigitValidator(new FormControl(parsedValue));

      if (!validationResult) {
        // let roundedValue = parseFloat((this.projectCostDetailsForm.controls['itemCost'].value * 0.1).toFixed(2));
        let roundedValue = parseFloat((parsedValue * 0.1).toFixed(2));
       // this.projectCostDetailsForm.controls['hookCapitalCost'].setValue((roundedValue).toFixed(2)); //float
       this.projectCostDetailsForm.controls['hookCapitalCost'].setValue(this.common.formatUSD(roundedValue));
        // if(this.warningShown == true){
        this.warningShown = false;
        //}
        this.totalCostCalc();
        this.enableItemFields();
        this.enableHookupFields();
      }
      else {
        if (value && !this.warningShown) {
          const itemcost = (this.requestDetailsForm.controls['assets'].value) * 5000;
          // let toast = this.toastr.warning('Item Cost for Capital must be >= $'+ itemcost + '. Please verify that you have entered the correct Item Cost. If the cost is < $'+ itemcost + ', please cancel this request and work with your management team to expense the item.', '', { onActivateTick: true, timeOut: 5000 });
          for (let controlName in this.projectCostDetailsForm.controls) {
            if (controlName !== 'itemCost' && controlName !== 'engrNRECostCenter' && controlName!== 'techNRECostCenter' && controlName!== 'engrNREFTE' && controlName!== 'engrNRECost' && controlName!== 'techNREFTE' && controlName!== 'techNRECost' && controlName!== 'defaultNRE') {
              this.projectCostDetailsForm.get(controlName)?.setValue(null);
            }
            // if (controlName === 'defaultNRE') {
            //   this.projectCostDetailsForm.get('defaultNRE')?.setValue('No Option Selected');
            // }
          };
          this.warningShown = true;
          //this.warningShown = false;
          // setTimeout(() => {
          // toast.onHidden.subscribe(() => {
          //this.capexForm.controls['itemCost'].reset();
          // this.capexForm.controls['hookCapitalCost'].reset();
          // this.capexForm.get('defaultNRE')?.setValue('No Option Selected');
          this.warningShown = false;
          //});
          //}, 8);
        }
      }
    });
  }

  itemCostClick(event: any) {
    if (event instanceof KeyboardEvent) {
      if (event.key !== 'Tab') {
        return
      }
    }
    this.validateItemCostControl();
  }

  fourDigitValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    let errors: { [key: string]: boolean } = {}; // Explicitly define the type of errors object
    if (value && value.toString().length < 4) {
      errors['fourDigit'] = true;
    }

    if (value && value < 5000) {
      errors['min'] = true;
    }
    return Object.keys(errors).length ? errors : null;
  }

  getMinItemCost() {
    // Access the value of the 'assets' control dynamically
    const assetsValue = (this.requestDetailsForm.controls['assets'].value);
    return assetsValue * 5000;
  }


  enterFTEDays(event: any, name: string) {
    this.inputFTEValue = (event.target as HTMLInputElement).value;
    if (name == "EngrNREFTE") {
      if (this.inputFTEValue <= 0) {
        this.projectCostDetailsForm.controls['engrNRECostCenter'].setValue(null);
        this.projectCostDetailsForm.controls['engrNRECostCenter'].disable();
      }
      else {
        this.projectCostDetailsForm.controls['engrNRECostCenter'].enable();
      }
      let costControl = this.inputFTEValue * 620.65;
     // this.projectCostDetailsForm.controls['engrNRECost'].setValue((costControl).toFixed(2));
     this.projectCostDetailsForm.controls['engrNRECost'].setValue(this.common.formatUSD(costControl));
    }

    if (name == "TechNREFTE") {
      if (this.inputFTEValue <= 0) {
        this.projectCostDetailsForm.controls['techNRECostCenter'].setValue(null);
        this.projectCostDetailsForm.controls['techNRECostCenter'].disable();
      }
      else {
        this.projectCostDetailsForm.controls['techNRECostCenter'].enable();
      }
      let costControl = this.inputFTEValue * 341.65;
    //  this.projectCostDetailsForm.controls['techNRECost'].setValue((costControl).toFixed(2));
    this.projectCostDetailsForm.controls['techNRECost'].setValue(this.common.formatUSD(costControl));
    }

    this.totalCostCalc();
    this.enableNREFields();
  }

  nullProjectCostDetailsFields() {
    for (let controlName in this.projectCostDetailsForm.controls) {
      if (controlName !== 'itemCost') {
        this.projectCostDetailsForm.get(controlName)?.setValue(null);
      }
    };
    this.common.show('warning', 'Please enter Item Cost before selecting NRE FTE values');
    setTimeout(() => {
      this.projectCostDetailsForm.controls['defaultNRE'].setValue('No Option Selected');
    }, 3);
  }

  disableNREFields(){
    Object.keys(this.NRECashFlowForm.controls).forEach(key => {
      const control = this.NRECashFlowForm.controls[key];
      control.disable();
      if (key !== 'NRECashFlowMethod') {
        control.setValue(0);
      }
    });
     // Set method to Standard Terms (%) and disable it
     this.NRECashFlowForm.controls['NRECashFlowMethod'].setValue('Standard Terms (%)');
     setTimeout(() => {
      this.NRECashFlowForm.controls['NRECashFlowMethod'].disable();
    }, 3);

     
    // Clear validators for NRE cash flow fields
    ['NRECashFlowCommit', 'NRECashFlowFinal'].forEach(key => {
      const control = this.NRECashFlowForm.get(key);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    });

    // Debugging statements
    console.log('NRECashFlowMethod value:', this.NRECashFlowForm.controls['NRECashFlowMethod'].value);
    console.log('NRECashFlowMethod disabled:', this.NRECashFlowForm.controls['NRECashFlowMethod'].disabled);

  }

  enableNREFields() {
    Object.keys(this.NRECashFlowForm.controls).forEach(key => {
      const control = this.NRECashFlowForm.controls[key];
      if (!control.disabled) {
        control.enable();
      }
    });
    // Set default values for NRE cash flow
    this.NRECashFlowForm.controls['NRECashFlowMethod'].setValue('Standard Terms (%)');
    this.showNRECashFlowValues();
    // Enable and set validators for specific fields
    ['NRECashFlowMethod', 'NRECashFlowCommit', 'NRECashFlowFactory', 'NRECashFlowSite', 'NRECashFlowFinal'].forEach(key => {
      const control = this.NRECashFlowForm.get(key);
      if (control) {
        if (!control.enabled) {
          control.enable();
        }
        if (this.NRECashFlowValidators && this.NRECashFlowValidators[key]) {
          control.setValidators(this.NRECashFlowValidators[key]);
          control.updateValueAndValidity();
        }
      }
    });
  }

  disableHookupFields(){
    Object.keys(this.hookUpCashFlowForm.controls).forEach(key => {
      const control = this.hookUpCashFlowForm.controls[key];
      control.disable();
      if (key !== 'hookUpCashFlowMethod') {
        control.setValue(0);
      }
    });
     // Set method to Standard Terms (%) and disable it
     this.hookUpCashFlowForm.controls['hookUpCashFlowMethod'].setValue('Standard Terms (%)');
     setTimeout(() => {
      this.hookUpCashFlowForm.controls['hookUpCashFlowMethod'].disable();
    }, 3);

     
    // Clear validators for NRE cash flow fields
    ['hookUpCashFlowCommit', 'hookUpCashFlowFinal'].forEach(key => {
      const control = this.hookUpCashFlowForm.get(key);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    });

    // Debugging statements
    console.log('hookUpCashFlowMethod value:', this.hookUpCashFlowForm.controls['hookUpCashFlowMethod'].value);
    console.log('hookUpCashFlowMethod disabled:', this.hookUpCashFlowForm.controls['hookUpCashFlowMethod'].disabled);
  }

  enableHookupFields(){
    Object.keys(this.hookUpCashFlowForm.controls).forEach(key => {
      const control = this.hookUpCashFlowForm.controls[key];
      if (!control.disabled) {
        control.enable();
      }
    });
    // Set default values for NRE cash flow
    this.hookUpCashFlowForm.controls['hookUpCashFlowMethod'].setValue('Standard Terms (%)');
    this.showHookupCashFlowValues();
    // Enable and set validators for specific fields
    ['hookUpCashFlowMethod', 'hookUpCashFlowCommit', 'hookUpCashFlowFactory', 'hookUpCashFlowSite', 'hookUpCashFlowFinal'].forEach(key => {
      const control = this.hookUpCashFlowForm.get(key);
      if (control) {
        if (!control.enabled) {
          control.enable();
        }
        if (this.hookUpCashFlowValidators && this.hookUpCashFlowValidators[key]) {
          control.setValidators(this.hookUpCashFlowValidators[key]);
          control.updateValueAndValidity();
        }
      }
    });
  }

  enableItemFields(){
    Object.keys(this.itemCashFlowForm.controls).forEach(key => {
      const control = this.itemCashFlowForm.controls[key];
      if (!control.disabled) {
        control.enable();
      }
    });
    // Set default values for NRE cash flow
    this.itemCashFlowForm.controls['itemCashFlowMethod'].setValue('Standard Terms (%)');
    this.showItemCashFlowValues();
    // Enable and set validators for specific fields
    ['itemCashFlowMethod', 'itemCashFlowCommit', 'itemCashFlowFactory', 'itemCashFlowSite', 'itemCashFlowFinal'].forEach(key => {
      const control = this.itemCashFlowForm.get(key);
      if (control) {
        if (!control.enabled) {
          control.enable();
        }
        if (this.itemCashFlowValidators && this.itemCashFlowValidators[key]) {
          control.setValidators(this.itemCashFlowValidators[key]);
          control.updateValueAndValidity();
        }
      }
    });
  }
  onCheckboxChange(event: MatRadioChange | string) {
    if (typeof event === 'string') {
      this.nreCheckBox = event; // If it's a string, use it directly
    } else {
      this.nreCheckBox = event.value; // If it's a MatRadioChange, extract the value
    }
    if (this.nreCheckBox === 'Yes') {
      this.enableNREFields();
      this.projectCostDetailsForm.controls['engrNRECost'].disable();
      this.projectCostDetailsForm.controls['techNRECost'].disable();
      this.projectCostDetailsForm.controls['engrNREFTE'].disable();
      this.projectCostDetailsForm.controls['techNREFTE'].disable();
      this.projectCostDetailsForm.controls['engrNRECostCenter'].enable();
      this.projectCostDetailsForm.controls['techNRECostCenter'].enable();
      if (this.projectCostDetailsForm.controls['itemCost'].value < 5000) {
        this.nullProjectCostDetailsFields();
      }
      else {
        let EngrFTEdays = (this.common.parseCurrency(this.projectCostDetailsForm.controls['itemCost'].value) / 1000) * 0.12;
        this.projectCostDetailsForm.controls['engrNREFTE'].setValue((EngrFTEdays).toFixed(2));

        let EngrCostControl = this.projectCostDetailsForm.controls['engrNREFTE'].value * 620.65;
       // this.projectCostDetailsForm.controls['engrNRECost'].setValue((EngrCostControl).toFixed(2));
        this.projectCostDetailsForm.controls['engrNRECost'].setValue(this.common.formatUSD(EngrCostControl));

        let TechFTEdays = (this.common.parseCurrency(this.projectCostDetailsForm.controls['itemCost'].value) / 1000) * 0.04;
        this.projectCostDetailsForm.controls['techNREFTE'].setValue((TechFTEdays).toFixed(2));

        let TechCostControl = this.projectCostDetailsForm.controls['techNREFTE'].value * 341.65;
       // this.projectCostDetailsForm.controls['techNRECost'].setValue((TechCostControl).toFixed(2));
       this.projectCostDetailsForm.controls['techNRECost'].setValue(this.common.formatUSD(TechCostControl));

        this.totalCostCalc();
      }

    } 

    // else if (this.nreCheckBox === 'Default') {
    //   this.disableNREFields();
    //   this.projectCostDetailsForm.controls['engrNRECostCenter'].disable();
    //   this.projectCostDetailsForm.controls['techNRECostCenter'].disable();
    //   this.projectCostDetailsForm.controls['engrNRECost'].disable();
    //   this.projectCostDetailsForm.controls['techNRECost'].disable();
    //   this.projectCostDetailsForm.controls['engrNREFTE'].disable();
    //   this.projectCostDetailsForm.controls['techNREFTE'].disable();
    // }

    else if (this.nreCheckBox === 'No') {
      this.enableNREFields();
      this.projectCostDetailsForm.controls['engrNRECost'].disable();
      this.projectCostDetailsForm.controls['techNRECost'].disable();
      this.projectCostDetailsForm.controls['engrNRECostCenter'].setValue(null);
      this.projectCostDetailsForm.controls['techNRECostCenter'].setValue(null);
      this.projectCostDetailsForm.controls['engrNRECostCenter'].disable();
      this.projectCostDetailsForm.controls['techNRECostCenter'].disable();
      if (this.projectCostDetailsForm.controls['itemCost'].value < 5000) {
        this.nullProjectCostDetailsFields();
      }
      else {
        this.projectCostDetailsForm.controls['engrNRECostCenter'].setValue(null);
        this.projectCostDetailsForm.controls['techNRECostCenter'].setValue(null);
        this.projectCostDetailsForm.controls['engrNRECostCenter'].disable();
        this.projectCostDetailsForm.controls['techNRECostCenter'].disable();
        this.projectCostDetailsForm.controls['engrNREFTE'].setValue(null);
        this.projectCostDetailsForm.controls['engrNRECost'].setValue(null);

        this.projectCostDetailsForm.controls['techNREFTE'].setValue(null);
        this.projectCostDetailsForm.controls['techNRECost'].setValue(null);

        this.projectCostDetailsForm.controls['engrNREFTE'].enable();
        this.projectCostDetailsForm.controls['techNREFTE'].enable();

        this.totalCostCalc();
      }

    } else if (this.nreCheckBox === 'No NRE') {
      this.disableNREFields();
      if (this.projectCostDetailsForm.controls['itemCost'].value < 5000) {
        this.nullProjectCostDetailsFields();
      }
      else {
        this.projectCostDetailsForm.controls['engrNRECostCenter'].setValue(null);
        this.projectCostDetailsForm.controls['techNRECostCenter'].setValue(null);
        this.projectCostDetailsForm.controls['engrNRECostCenter'].disable();
        this.projectCostDetailsForm.controls['techNRECostCenter'].disable();
        this.projectCostDetailsForm.controls['engrNREFTE'].disable();
        this.projectCostDetailsForm.controls['engrNRECost'].disable();

        this.projectCostDetailsForm.controls['techNREFTE'].disable();
        this.projectCostDetailsForm.controls['techNRECost'].disable();

        this.projectCostDetailsForm.controls['engrNREFTE'].setValue(null);
        this.projectCostDetailsForm.controls['engrNRECost'].setValue(null);

        this.projectCostDetailsForm.controls['techNREFTE'].setValue(null);
        this.projectCostDetailsForm.controls['techNRECost'].setValue(null);

        this.totalCostCalc();
      }
    }
  }


  totalCostCalc() {
    // let NRETotalCost = Number(this.projectCostDetailsForm.controls['engrNRECost'].value) + Number(this.projectCostDetailsForm.controls['techNRECost'].value);
    let NRETotalCost = this.common.parseCurrency(this.projectCostDetailsForm.controls['engrNRECost'].value || 0) + this.common.parseCurrency(this.projectCostDetailsForm.controls['techNRECost'].value || 0);
   // this.projectCostDetailsForm.controls['NREtotalCost'].setValue((NRETotalCost).toFixed(2));
   this.projectCostDetailsForm.controls['NREtotalCost'].setValue(this.common.formatUSD(NRETotalCost));

   // let totalCost = Number(this.projectCostDetailsForm.controls['itemCost'].value) + Number(this.projectCostDetailsForm.controls['hookCapitalCost'].value) + Number(this.projectCostDetailsForm.controls['NREtotalCost'].value);
   let totalCost = this.common.parseCurrency(this.projectCostDetailsForm.controls['itemCost'].value || 0) + this.common.parseCurrency(this.projectCostDetailsForm.controls['hookCapitalCost'].value || 0) + this.common.parseCurrency(this.projectCostDetailsForm.controls['NREtotalCost'].value || 0);
    //this.projectCostDetailsForm.controls['totalCost'].setValue((Math.round(totalCost)).toFixed(2));  //float
    this.projectCostDetailsForm.controls['totalCost'].setValue(this.common.formatUSD(totalCost));  

    //let quaterDepr = this.requestDetailsForm.controls['assetUsefulLife'].value !== 0 ? (this.projectCostDetailsForm.controls['totalCost'].value / this.requestDetailsForm.controls['assetUsefulLife'].value / 4) : 0;
      let quaterDepr = this.requestDetailsForm.controls['assetUsefulLife'].value !== 0 ? this.common.parseCurrency(this.projectCostDetailsForm.controls['totalCost'].value || 0) / this.requestDetailsForm.controls['assetUsefulLife'].value / 4 : 0;
   // this.projectCostDetailsForm.controls['estimatedQatrDepr'].setValue((quaterDepr).toFixed(2));
   this.projectCostDetailsForm.controls['estimatedQatrDepr'].setValue(this.common.formatUSD(quaterDepr));
  }

  openDialogMessage(message: any, assetID: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '50%';

    dialogConfig.data = {
      message: message,
      tabletemplate: false,
      title: this.selectedProjectTypeVal == "L&B" ? 'L&B Project Type' : 'M&E Project Type',
      titleHeight: '30px',
      showYesNoButton: this.selectedProjectTypeVal == "L&B" ? true : false
      // showOnlyOkButton: this.selectedProjectTypeVal == "M&E" ? true : false,
      //title: assetID ? 'Thank You' : "Action Plan"
    }
    //this.dialog.open(DialogBoxComponent, dialogConfig);
    // if (this.dialog.openDialogs.length > 0) {
    //   //this.dialog.closeAll();
    //   this.dialog.openDialogs.forEach((dialog) => dialog.close());
    // }
    // else {
    const dialogRef = this.dialog.open(DialogBoxComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (this.selectedProjectTypeVal == 'L&B' && result == 'No') {
        this.requestDetailsForm.controls['projectType'].setValue('M&E');
        this.selectedProjectTypeVal = 'M&E';
      }
      if (this.selectedProjectTypeVal == 'M&E' && this.requestDetailsForm.controls['assetUsefulLife'].value > 40 && result == 'Ok') {
        this.requestDetailsForm.controls['assetUsefulLife'].setValue(40, { emitEvent: false });
      }
    });
    //}
  }

  quarterCalculation(selectedDate: any) {
    let year = selectedDate.getFullYear().toString().substr(-2);
    let quarter = 'Q1';
    let fullYear = selectedDate.getFullYear();
    let nextQuarterMonth = 0;
    if ((selectedDate.getMonth() + 1) < 2) {
      quarter = 'Q1';
      nextQuarterMonth = 2;
      if ((selectedDate.getMonth() + 1) === 0) {
        year = (selectedDate.getFullYear() - 1).toString().substr(-2);
      }
    } else if ((selectedDate.getMonth() + 1) < 5) {
      quarter = 'Q2';
      nextQuarterMonth = 5;
    } else if ((selectedDate.getMonth() + 1) < 8) {
      quarter = 'Q3';
      nextQuarterMonth = 8;
    } else if ((selectedDate.getMonth() + 1) < 11) {
      quarter = 'Q4';
      nextQuarterMonth = 11;
    } else {
      quarter = 'Q1';
      year = (selectedDate.getFullYear() + 1).toString().substr(-2);
    }
    return { year, quarter, fullYear, nextQuarterMonth };
  }

  updateRtoDateChangeValidators(isRtoDateChanged: boolean) {
    const reasonControl = this.adjustQuarterForm.get('rtoDateChangeReason');
    const commentControl = this.adjustQuarterForm.get('rtoDateChangeComment');

    if (isRtoDateChanged) {
      reasonControl?.setValidators([Validators.required]);
      commentControl?.setValidators([Validators.required]);
    } else {
      reasonControl?.clearValidators();
      commentControl?.clearValidators();
    }

    reasonControl?.updateValueAndValidity();
    commentControl?.updateValueAndValidity();
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, fieldName: string, picker: MatDatepicker<Date>) {
    let selectedDate = event.value;
    let formattedSelectedDate;
    if (selectedDate) {
      formattedSelectedDate = this.formatSelectedDate(selectedDate);
    }
    let selectedField = fieldName;
    // const formattedSelectedDate = selectedDate ? `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate() ?? ''}` : '';
    if (selectedDate) {
      let localDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
      switch (selectedField) {
        case 'commitQuarter':
          let resultCommit = this.quarterCalculation(selectedDate);
          let yearCommit = resultCommit.year;
          let quarterCommit = resultCommit.quarter;
          
          this.minFactoryQuarterDate = selectedDate;
          this.minSiteQuarterDate = selectedDate;
          this.minFinalQuarterDate = selectedDate;

          this.formattedDateCommit = `FY${yearCommit} ${quarterCommit}`;

          //show formatted quarter Date - FY24 Q1
          this.eventQuarterForm.controls['commitQuarter']?.setValue(this.formattedDateCommit, { emitEvent: false });

          //show MatDatePicker Date
          //this.eventQuarterForm.controls['commitQuarterMatDate']?.setValue(formattedSelectedDate, { emitEvent: false })
          this.eventQuarterForm.controls['commitQuarterMatDate']?.setValue(localDate, { emitEvent: false })

          this.adjustQuarterForm.controls['adjustedCommitQuarter']?.setValue(this.formattedDateCommit);

          // Enable all future dates
          this.maxSiteQuarterDate = new Date(9999, 11, 31);
          this.maxFactoryQuarterDate = new Date(9999, 11, 31);

          //Clear other fields
          this.clearFactoryQuarterField();
          this.clearSiteQuarterField();
          this.clearFinalQuarterField();
          this.setCashFlowValueNull();

          //clear all check sum fields
          this.setCashFlowTerms();
          this.setNRECashFlowTerms();
          this.setHookupCashFlowTerms();
          this.totalSumCheck();

          picker.close();
          break;

        case 'factoryQuarter':
          // Calculate the first day of the next quarter
          let resultFactory = this.quarterCalculation(selectedDate);
          let yearFactory = resultFactory.year;
          let quarterFactory = resultFactory.quarter;

          this.minSiteQuarterDate = selectedDate;
          this.minFinalQuarterDate = selectedDate;

          this.formattedDateFactory = `FY${yearFactory} ${quarterFactory}`;
          this.eventQuarterForm.controls['factoryQuarter']?.setValue(this.formattedDateFactory, { emitEvent: false });
          // this.eventQuarterForm.controls['factoryQuarterMatDate']?.setValue(formattedSelectedDate, { emitEvent: false });
          this.eventQuarterForm.controls['factoryQuarterMatDate']?.setValue(localDate, { emitEvent: false });

          //assign default value to adjustedFactoryQuarter
          this.adjustQuarterForm.controls['adjustedFactoryQuarter']?.setValue(this.formattedDateFactory);
          picker.close();
          break;

        case 'siteQuarter':
          // Check if commitQuarter is filled
          if (!this.eventQuarterForm.controls['commitQuarter']?.value) {
            this.common.show('warning', 'Please fill Commit Quarter first');
            return;
          }

          let resultSite = this.quarterCalculation(selectedDate);
          let yearSite = resultSite.year;
          let quarterSite = resultSite.quarter;

          this.minFinalQuarterDate = selectedDate;
          this.maxFactoryQuarterDate = selectedDate;

          this.formattedDateSite = `FY${yearSite} ${quarterSite}`;
          this.eventQuarterForm.controls['siteQuarter']?.setValue(this.formattedDateSite, { emitEvent: false });
          // this.eventQuarterForm.controls['siteQuarterMatDate']?.setValue(formattedSelectedDate, { emitEvent: false });
          this.eventQuarterForm.controls['siteQuarterMatDate']?.setValue(localDate, { emitEvent: false });
          //assign default value to adjustedSiteQuarter
          this.adjustQuarterForm.controls['adjustedSiteQuarter']?.setValue(this.formattedDateSite);

          // Enable all future dates
          this.maxSiteQuarterDate = new Date(9999, 11, 31);

          //Clear other fields
          // if (this.eventQuarterForm.controls['factoryQuarter']?.value) {
          //   this.clearFactoryQuarterField();
          // }
          this.clearFinalQuarterField();
          this.setCashFlowValueNull();
          this.setCashFlowTerms();
          this.setNRECashFlowTerms();
          this.setHookupCashFlowTerms();
          this.totalSumCheck();
          picker.close();
          break;

        case 'finalQuarter':
          // Check if commitQuarter is filled
          if (!this.eventQuarterForm.controls['siteQuarter']?.value) {
            this.common.show('warning', 'Please fill Site Quarter first');
            return;
          }

          let resultFinal = this.quarterCalculation(selectedDate);
          let yearFinal = resultFinal.year;
          let quarterFinal = resultFinal.quarter;

          if (this.maxFactoryQuarterDate == undefined) {
            this.maxFactoryQuarterDate = selectedDate;
          }
          this.maxSiteQuarterDate = selectedDate;

          this.formattedDateFinal = `FY${yearFinal} ${quarterFinal}`;
          this.eventQuarterForm.controls['finalQuarter']?.setValue(this.formattedDateFinal, { emitEvent: false });
          // this.eventQuarterForm.controls['finalQuarterMatDate']?.setValue(formattedSelectedDate, { emitEvent: false });
          this.eventQuarterForm.controls['finalQuarterMatDate']?.setValue(localDate, { emitEvent: false });
          //assign default value to adjustedSiteQuarter
          this.adjustQuarterForm.controls['adjustedFinalQuarter']?.setValue(this.formattedDateFinal);
          this.getEstimatedProjectEnd(selectedDate);
          picker.close();
          break;

        case 'adjustedFactoryQuarter':
          let resultAdjusted = this.quarterCalculation(selectedDate);
          let yearAdjusted = resultAdjusted.year;
          let quarterAdjusted = resultAdjusted.quarter;
          this.formattedDateAdjustedFactory = `FY${yearAdjusted} ${quarterAdjusted}`;

          this.adjustQuarterForm.controls['adjustedFactoryQuarter']?.setValue(this.formattedDateAdjustedFactory, { emitEvent: false });
          // this.adjustQuarterForm.controls['adjustedFactoryQuarterMatDate']?.setValue(formattedSelectedDate, { emitEvent: false });
          this.adjustQuarterForm.controls['adjustedFactoryQuarterMatDate']?.setValue(localDate, { emitEvent: false });
          picker.close();
          break;

        case 'adjustedSiteQuarter':
          let resultAdjSite = this.quarterCalculation(selectedDate);
          let yearAdjSite = resultAdjSite.year;
          let quarterAdjSite = resultAdjSite.quarter;
          this.formattedDateAdjustedSite = `FY${yearAdjSite} ${quarterAdjSite}`;
          this.adjustQuarterForm.controls['adjustedSiteQuarter']?.setValue(this.formattedDateAdjustedSite, { emitEvent: false });
          // this.adjustQuarterForm.controls['adjustedSiteQuarterMatDate']?.setValue(formattedSelectedDate, { emitEvent: false });
          this.adjustQuarterForm.controls['adjustedSiteQuarterMatDate']?.setValue(localDate, { emitEvent: false });
          picker.close();
          break;

        case 'adjustedFinalQuarter':
          let resultAdjFinal = this.quarterCalculation(selectedDate);
          let yearAdjFinal = resultAdjFinal.year;
          let quarterAdjFinal = resultAdjFinal.quarter;
          this.formattedDateAdjustedFinal = `FY${yearAdjFinal} ${quarterAdjFinal}`;
          this.adjustQuarterForm.controls['adjustedFinalQuarter']?.setValue(this.formattedDateAdjustedFinal, { emitEvent: false });
          // this.adjustQuarterForm.controls['adjustedFinalQuarterMatDate']?.setValue(formattedSelectedDate, { emitEvent: false });
          this.adjustQuarterForm.controls['adjustedFinalQuarterMatDate']?.setValue(localDate, { emitEvent: false });
          
          // Check if RTO date is changed
          if (this.adjustQuarterForm.controls['adjustedFinalQuarterMatDate'].value) {
            this.isRtoDateChanged = true;
            this.updateRtoDateChangeValidators(true);
          } else {
            this.isRtoDateChanged = false;
            this.updateRtoDateChangeValidators(false);
          }

          this.getAdjustedEstimatedProjectEnd(selectedDate);
          picker.close();
          break;
      }

      if (this.eventQuarterForm.valid) {
        this.showCashFlowPercentCal('');
        // this.showNRECashFlowValues();
        //this.showHookupCashFlowValues();
      }
      if (this.eventQuarterForm.valid &&  this.projectCostDetailsForm.get('defaultNRE')?.value == "No NRE") {
        this.disableNREFields();
      }
    }
  }

  clearCommitQuarterField() {
    // Clear CommitQuarter field
    this.eventQuarterForm.controls['commitQuarter']?.setValue('');
    this.eventQuarterForm.controls['commitQuarterMatDate']?.setValue('');
    this.adjustQuarterForm.controls['adjustedCommitQuarter']?.setValue('');
  }

  clearFactoryQuarterField() {
    // Clear FactoryQuarter field
    this.eventQuarterForm.controls['factoryQuarter']?.setValue('');
    this.eventQuarterForm.controls['factoryQuarterMatDate']?.setValue('');
    this.adjustQuarterForm.controls['adjustedFactoryQuarter']?.setValue('');
    this.adjustQuarterForm.controls['adjustedFactoryQuarterMatDate']?.setValue('');
  }

  clearSiteQuarterField() {
    // Clear SiteQuarter field
    this.eventQuarterForm.controls['siteQuarter']?.setValue('');
    this.eventQuarterForm.controls['siteQuarterMatDate']?.setValue('');
    this.adjustQuarterForm.controls['adjustedSiteQuarter']?.setValue('');
    this.adjustQuarterForm.controls['adjustedSiteQuarterMatDate']?.setValue('');
  }

  clearFinalQuarterField() {
    // Clear FinalQuarter field
    this.eventQuarterForm.controls['finalQuarter']?.setValue('');
    this.eventQuarterForm.controls['finalQuarterMatDate']?.setValue('');
    this.adjustQuarterForm.controls['adjustedFinalQuarter']?.setValue('');
    this.adjustQuarterForm.controls['adjustedFinalQuarterMatDate']?.setValue('');
  }

  clearFactoryField(fieldName: string): void {
    this.eventQuarterForm.get(fieldName)?.setValue('');
    this.clearFactoryQuarterField();
    this.minSiteQuarterDate = this.minFactoryQuarterDate;
    if(this.projectCostDetailsForm.get('defaultNRE')?.value != "No NRE"){
      this.enableNREFields();
    }
    if(this.projectCostDetailsForm.get('hookUpCapitalCost')?.value != 0){
      this.enableHookupFields();
    }
    this.enableItemFields();
  }

  showItemCashFlowValues() {
    if ((this.eventQuarterForm.valid || this.eventQuarterForm.disabled) && this.eventQuarterForm.controls['factoryQuarterMatDate'].value == "") {
      this.itemCashFlowForm.controls['itemCashFlowCommit'].setValue('50');
      this.itemCashFlowForm.controls['itemCashFlowSite'].setValue('40');
      this.itemCashFlowForm.controls['itemCashFlowFinal'].setValue('10');
      this.itemCashFlowForm.controls['itemCashFlowFactory'].setValue('0');
      setTimeout(() => {
        this.itemCashFlowForm.controls['itemCashFlowFactory'].disable();
      }, 3);
    }
    else if ((this.eventQuarterForm.valid || this.eventQuarterForm.disabled) && this.eventQuarterForm.controls['factoryQuarterMatDate'].value !== "") {
      // this.NRECashFlowForm.controls['NRECashFlowFactory'].enable();
      this.itemCashFlowForm.controls['itemCashFlowCommit'].setValue('20');
      this.itemCashFlowForm.controls['itemCashFlowFactory'].setValue('30');
      this.itemCashFlowForm.controls['itemCashFlowSite'].setValue('40');
      this.itemCashFlowForm.controls['itemCashFlowFinal'].setValue('10');
    }
    let totalSum = Number(this.itemCashFlowForm.controls['itemCashFlowCommit'].value) +
      Number(this.itemCashFlowForm.controls['itemCashFlowFactory'].value) +
      Number(this.itemCashFlowForm.controls['itemCashFlowSite'].value) +
      Number(this.itemCashFlowForm.controls['itemCashFlowFinal'].value);

    this.itemCashFlowForm.controls['itemCheckSum'].setValue(totalSum);
    this.setCashFlowTerms();
  }

  showNRECashFlowValues() {
    if ((this.eventQuarterForm.valid || this.eventQuarterForm.disabled) && 
         this.eventQuarterForm.controls['factoryQuarterMatDate'].value == "" && 
         this.eventQuarterForm.controls['siteQuarterMatDate'].value == "") {
      this.NRECashFlowForm.controls['NRECashFlowCommit'].setValue('90');
      this.NRECashFlowForm.controls['NRECashFlowFinal'].setValue('10');
      // this.NRECashFlowForm.controls['NRECashFlowFactory'].disable();
      // this.NRECashFlowForm.controls['NRECashFlowSite'].disable();
    }
    else if ((this.eventQuarterForm.valid || this.eventQuarterForm.disabled) && this.eventQuarterForm.controls['factoryQuarterMatDate'].value == "") {
      this.NRECashFlowForm.controls['NRECashFlowCommit'].setValue('50');
      // this.itemCashFlowForm.controls['itemCashFlowFactory'].setValue('30');
      this.NRECashFlowForm.controls['NRECashFlowSite'].setValue('40');
      this.NRECashFlowForm.controls['NRECashFlowFinal'].setValue('10');
      this.NRECashFlowForm.controls['NRECashFlowFactory'].setValue('0');
      setTimeout(() => {
        this.NRECashFlowForm.controls['NRECashFlowFactory'].disable();
      }, 3);
      
    }
    else if ((this.eventQuarterForm.valid || this.eventQuarterForm.disabled) && this.eventQuarterForm.controls['factoryQuarterMatDate'].value !== "") {
      // this.NRECashFlowForm.controls['NRECashFlowFactory'].enable();
      this.NRECashFlowForm.controls['NRECashFlowCommit'].setValue('20');
      this.NRECashFlowForm.controls['NRECashFlowFactory'].setValue('30');
      this.NRECashFlowForm.controls['NRECashFlowSite'].setValue('40');
      this.NRECashFlowForm.controls['NRECashFlowFinal'].setValue('10');
    }
    let totalSum = Number(this.NRECashFlowForm.controls['NRECashFlowCommit'].value) +
      Number(this.NRECashFlowForm.controls['NRECashFlowFactory'].value) +
      Number(this.NRECashFlowForm.controls['NRECashFlowSite'].value) +
      Number(this.NRECashFlowForm.controls['NRECashFlowFinal'].value);

    this.NRECashFlowForm.controls['NRECheckSum'].setValue(totalSum);
    this.setNRECashFlowTerms();
  }

  showHookupCashFlowValues() {
    if ((this.eventQuarterForm.valid || this.eventQuarterForm.disabled) && this.eventQuarterForm.controls['factoryQuarterMatDate'].value == "" && this.eventQuarterForm.controls['siteQuarterMatDate'].value == "") {
      this.hookUpCashFlowForm.controls['hookUpCashFlowCommit'].setValue('90');
      this.hookUpCashFlowForm.controls['hookUpCashFlowFinal'].setValue('10');
      // this.NRECashFlowForm.controls['NRECashFlowFactory'].disable();
      // this.NRECashFlowForm.controls['NRECashFlowSite'].disable();
    }
    else if ((this.eventQuarterForm.valid || this.eventQuarterForm.disabled) && this.eventQuarterForm.controls['factoryQuarterMatDate'].value == "") {
      this.hookUpCashFlowForm.controls['hookUpCashFlowCommit'].setValue('50');
      // this.itemCashFlowForm.controls['itemCashFlowFactory'].setValue('30');
      this.hookUpCashFlowForm.controls['hookUpCashFlowSite'].setValue('40');
      this.hookUpCashFlowForm.controls['hookUpCashFlowFinal'].setValue('10');
      this.hookUpCashFlowForm.controls['hookUpCashFlowFactory'].setValue('0');
      setTimeout(() => {
        this.hookUpCashFlowForm.controls['hookUpCashFlowFactory'].disable();
      }, 3);
      
      // this.NRECashFlowForm.controls['NRECashFlowFactory'].disable();
    }
    else if ((this.eventQuarterForm.valid || this.eventQuarterForm.disabled) && this.eventQuarterForm.controls['factoryQuarterMatDate'].value !== "") {
      // this.NRECashFlowForm.controls['NRECashFlowFactory'].enable();
      this.hookUpCashFlowForm.controls['hookUpCashFlowCommit'].setValue('20');
      this.hookUpCashFlowForm.controls['hookUpCashFlowFactory'].setValue('30');
      this.hookUpCashFlowForm.controls['hookUpCashFlowSite'].setValue('40');
      this.hookUpCashFlowForm.controls['hookUpCashFlowFinal'].setValue('10');
    }
    let totalSum = Number(this.hookUpCashFlowForm.controls['hookUpCashFlowCommit'].value) +
      Number(this.hookUpCashFlowForm.controls['hookUpCashFlowFactory'].value) +
      Number(this.hookUpCashFlowForm.controls['hookUpCashFlowSite'].value) +
      Number(this.hookUpCashFlowForm.controls['hookUpCashFlowFinal'].value);

    this.hookUpCashFlowForm.controls['hookUpCheckSum'].setValue(totalSum);
    this.setHookupCashFlowTerms();
  }

  formatSelectedDate(selectedDate: Date): string {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1; // getMonth() is zero-based; add 1 to get the correct month number
    const day = selectedDate.getDate();

    // Format month and day to ensure they are MM and DD format
    const formattedMonth = month < 10 ? `0${month}` : month.toString();
    const formattedDay = day < 10 ? `0${day}` : day.toString();

    // Concatenate into YYYY-MM-DD format
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  getEstimatedProjectEnd(selectedDate: Date) {
    //let estimatedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 6, selectedDate.getDate());
    let estimatedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 6, 1);
    // Adjust the day if it exceeds the number of days in the resulting month
    const daysInMonth = new Date(estimatedDate.getFullYear(), estimatedDate.getMonth() + 1, 0).getDate();
    estimatedDate.setDate(Math.min(selectedDate.getDate(), daysInMonth));
	const formattedSelectedDate = this.formatSelectedDate(estimatedDate);
    let estimatedYear = estimatedDate.getFullYear().toString().substr(-2);
    let estimatedQuarter = 'Q1';
    this.estimatedMonth = estimatedDate.getMonth() + 1;
    this.minEstimatedProjectEndDate = estimatedDate;
    if ((estimatedDate.getMonth() + 1) < 2) {
      estimatedQuarter = 'Q1';
      if ((estimatedDate.getMonth() + 1) === 0) {
        estimatedYear = (estimatedDate.getFullYear() - 1).toString().substr(-2);
      }
    } else if ((estimatedDate.getMonth() + 1) < 5) {
      estimatedQuarter = 'Q2';
    } else if ((estimatedDate.getMonth() + 1) < 8) {
      estimatedQuarter = 'Q3';
    } else if ((estimatedDate.getMonth() + 1) < 11) {
      estimatedQuarter = 'Q4';
    } else {
      estimatedQuarter = 'Q1';
      estimatedYear = (estimatedDate.getFullYear() + 1).toString().substr(-2);
    }
    this.eventQuarterForm.controls['estimatedProjectEnd']?.setValue(`FY${estimatedYear} ${estimatedQuarter}`);
    // Setting Estimated End Date Value to send to WBS and Asset Component
    // this.common.estimatedEndDate.next({ estimatedDate: `FY${estimatedYear} ${estimatedQuarter}` });

   // this.common.estimatedEndDate.next({ estimatedDate: formattedSelectedDate });
   if(!this.wbsDetails.wbsDetailsForm.valid){
    this.common.estimatedEndDate.next({ estimatedDate: formattedSelectedDate });
   }

    this.eventQuarterForm.controls['estimatedProjectEndMatDate']?.setValue(formattedSelectedDate, { emitEvent: false });
    this.eventQuarterForm.get('estimatedProjectEnd')?.disable();
    //assign default value to adjustedEstimatedProjectEnd
    this.adjustQuarterForm.controls['adjustedEstimatedProjectEnd']?.setValue(`FY${estimatedYear} ${estimatedQuarter}`);
  }

  getAdjustedEstimatedProjectEnd(selectedDate: Date) {
     let estimatedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 6, 1);
    // Adjust the day if it exceeds the number of days in the resulting month
    const daysInMonth = new Date(estimatedDate.getFullYear(), estimatedDate.getMonth() + 1, 0).getDate();
    estimatedDate.setDate(Math.min(selectedDate.getDate(), daysInMonth));
    const formattedSelectedDate = this.formatSelectedDate(estimatedDate);
    let estimatedYear = estimatedDate.getFullYear().toString().substr(-2);
    let estimatedQuarter = 'Q1';
    this.adjustedEstimatedMonth = estimatedDate.getMonth() + 1;
    this.minAdjEstimatedProjectEndDate = estimatedDate;
    if ((estimatedDate.getMonth() + 1) < 2) {
      estimatedQuarter = 'Q1';
      if ((estimatedDate.getMonth() + 1) === 0) {
        estimatedYear = (estimatedDate.getFullYear() - 1).toString().substr(-2);
      }
    } else if ((estimatedDate.getMonth() + 1) < 5) {
      estimatedQuarter = 'Q2';
    } else if ((estimatedDate.getMonth() + 1) < 8) {
      estimatedQuarter = 'Q3';
    } else if ((estimatedDate.getMonth() + 1) < 11) {
      estimatedQuarter = 'Q4';
    } else {
      estimatedQuarter = 'Q1';
      estimatedYear = (estimatedDate.getFullYear() + 1).toString().substr(-2);
    }
    this.adjustQuarterForm.controls['adjustedEstimatedProjectEnd']?.setValue(`FY${estimatedYear} ${estimatedQuarter}`);
    this.adjustQuarterForm.controls['adjustedEstimatedProjectEndMatDate']?.setValue(formattedSelectedDate, { emitEvent: false });
     //this.adjustQuarterForm.controls['monthsOverdue']?.setValue(this.adjustedEstimatedMonth - this.estimatedMonth);
     this.calculateMonthsOverdue();
    if(this.wbsDetails.wbsDetailsForm.valid){
      this.common.estimatedEndDate.next({ estimatedDate: formattedSelectedDate });
     }
  }

  onSubmit(): void {

    const allFormData = this.capexForm.value;
    // Do something with all form data
  }

  onSave(buttonText: string) {
    //submit data for ltp form
    this.common.loader.next(true);
    //this.capexForm
    this.capexForm.value['requestDetails'] = this.requestDetailsForm.getRawValue();
    this.capexForm.value['financialDetails'] = this.financialDetailsForm.getRawValue();
    this.capexForm.value['projectCostDetails'] = this.projectCostDetailsForm.getRawValue();
    this.capexForm.value['cashFlowDetails'] = this.cashFlowDetailsForm.getRawValue();

     // Mark all controls as touched to trigger validation
     this.requestDetailsForm.markAllAsTouched();
     this.financialDetailsForm.markAllAsTouched();
     this.projectCostDetailsForm.markAllAsTouched();
     this.cashFlowDetailsForm.markAllAsTouched();

    if(this.panelFlag){
      this.capexForm.value['wbsDetails'] = this.wbsDetails.getFormValue();
      this.capexForm.value['wbsCashFlow'] = this.wbsCashFlow.getFormValue();
      this.capexForm.value['wbsCashFlowDetails'] = this.wbsCashFlowDetails.getFormValue();
      this.capexForm.value['assetDetails'] = this.assetDetails.getFormValue();
    }    
	  if(this.hasCashFlowErrorMessage || this.hasNREErrorMessage || this.hasHookupErrorMessage){
      this.common.loader.next(false);
      this.common.show('warning', 'The sum of all the fields should be equal to 100');
      return;
    } 		
 	   
    if (this.requestDetailsForm.valid) {

      let payload = '';
      if (buttonText == 'Save') {
        this.capexForm.value['ltpNumber'] = this.ltpNum;
        this.capexForm.value['ltpStatus'] = this.ltpStatus;
        if(this.isPORRequestType()){
        this.capexForm.value['por'] = this.ltpNum.replace('LTP', 'POR');
        this.capexForm.value['porStatus'] = 'Draft';
        }
        if (this.userRole === 'Admin') {
          if (this.capexForm.value['wbsDetails'].wbsStatus == 'Active') {
            if (this.porStatus === 'In Queue') {
              this.porStatus = 'Active'
            }
          }
        }

        if(this.porNum && this.capexForm.value['wbsDetails']?.wbs && this.capexForm.value['wbsDetails']?.actualCommitDate){
          if(this.wbsStatus == 'Requested' || this.wbsStatus == ''){
          this.wbsStatus = 'Active';
          this.capexForm.value['wbsDetails'].wbsStatus = 'Active'

          this.common.updateWbsStatus('Active');   
          }
        }

        payload = {
          ...this.capexForm.value,
          por: this.porNum,
          porStatus: this.porStatus,
          isWBSEmailSent: this.isWBSEmailSent
        };
      }
      else{
        if(this.isPORRequestType()){
        this.capexForm.value['por'] = this.ltpNum.replace('LTP', 'POR');
        this.capexForm.value['porStatus'] = 'Draft';
        }
        payload = {
          ...this.capexForm.value, 
          isWBSEmailSent: this.isWBSEmailSent 
        };
      }
      this.common.postCapexAPI('ltp-por/save', payload).subscribe({
        next: (
          (data) => {
            this.common.loader.next(false);
            this.ltpNum = data['message'];
            if(this.isPORRequestType())
            {
              this.porNum = this.ltpNum.replace('LTP', 'POR');
              this.common.show('success', 'Successfully updated ' + this.porNum);
            }
            else{
              this.common.show('success', 'Successfully updated ' + this.ltpNum);
            }
            this.wbsNum = this.capexForm.value.wbsDetails?.wbs ?? '';
            this.wbsStatus = this.capexForm.value.wbsDetails?.wbsStatus ?? '';
            if (buttonText == 'Save Draft'){
              this.ltpStatus = 'Draft';
              if(this.isPORRequestType())
              {
              this.porNum = this.ltpNum.replace('LTP', 'POR');
              this.porStatus = 'Draft';
              }
              this.showAnalystBtn = true;
              this.showInQueueBtn = false;
            }
            if (this.wbsStatus === 'Closed' && this.userRole === 'Planner') {
              this.capexForm.disable();
              this.requestDetailsForm.disable();
              this.financialDetailsForm.disable();
              this.projectCostDetailsForm.disable();
              this.cashFlowDetailsForm.disable();

              // this.isDisabled = true;
            }

            if (this.wbsNum && this.wbsStatus === 'Active' && this.isWBSEmailSent == false) {
              // this.isWBSEmailSent = true;
              console.log('WBS is Assigned');
              const notificationBody = {
                notificationType: 'WBS Assigned',
                ltp: this.ltpNum,
                por: this.porNum,
                wbs: this.wbsNum,
                version: this.version,
                isWBSEmailSent: true,
                planner: this.capexForm.value.requestDetails.planner || '',
                projectManager: this.capexForm.value.requestDetails.projectManagerEmail || '',
                toolDescription: this.capexForm.value.requestDetails.itemDescription || '',
                commitQuarter: this.capexForm.value.cashFlowDetails.eventQuarter.commitQuarter || '',
                finalAcceptanceQuarter: this.capexForm.value.cashFlowDetails.eventQuarter.finalQuarter || '',
                totalCost: this.projectCostDetailsForm.controls['totalCost'].value || '',
                labFabInstallLocation: this.capexForm.value.requestDetails.labfabInstallLocation || '',
                // depreciationStartDate: this.capexForm.value.wbsDetails.depreciationStartDate || '',
                // estimatedDepreciationCost: this.capexForm.value.projectCostDetails.estimatedQatrDepr || '',
                assets: this.capexForm.value.requestDetails.assets || '',
                assetLife: this.capexForm.value.requestDetails.assetUsefulLife || '',
                // depreciationSubMRU: this.capexForm.value.financialDetails.deprSubMRU || '',
                // commitSubMRU: this.capexForm.value.financialDetails.commitSubMRU || '',
                // isWBSEmailSent: this.isWBSEmailSent
              };

              console.log(notificationBody);
              this.common.postCapexAPI('capex-notify', notificationBody).subscribe({
                next: (notificationData) => {
                  this.common.show('success', 'Email notification sent successfully.');
                },
                error: (notificationErr) => {
                  console.error("Error sending email notification:", notificationErr);
                  this.common.show('error', 'Failed to send email notification.');
                }
              });
            }
          }),
        error: (
          (err) => {
            console.error("Error occurred:", err);
            this.common.loader.next(false);
            this.common.show('error', 'Error occurred while saving the data');
          })
      })
    }
    else {
      this.common.loader.next(false);
      this.common.show('warning', 'Please fill all the required fields');
    }
  }

  back() {
    this.router.navigate(['/capex/capexSearch'], { state: { action: 'back' } });
  }

  showPlannerFinanceDD() {
    this.common.getCapexAPI('capex-request-role').subscribe({
      next: (
        (data) => {
          this.financeOptions = data['Finance'].filter((a: any) => a['Capex_Request_Role'] == 'Finance' && a['Active'] == true).map((a: any) => a['Capex_Request_Role_Name']);
          this.plannerOptions = data['Planner'].filter((a: any) => a['Capex_Request_Role'] == 'Planner' && a['Active'] == true).map((a: any) => a['Capex_Request_Role_Name']);
        }),
      error: (
        (err) => {
          console.error("Error occurred:", err);
        })
    });
  }

  showExtFinanceDD() {
    this.common.getCapexAPI('ltp-por/external-funding').subscribe({
      next: (
        (data) => {
          this.externalOptions = data
            .map((user: { UserName: any; }) => user.UserName);
        }),
      error: (
        (err) => {
          console.error("Error occurred:", err);
        })
    });
  }






  showCashFlowMethod(val: any, fieldName: string) {
    let selectedValue = val;
    if (!this.eventQuarterForm.valid && !this.eventQuarterForm.disabled) {
      this.common.show('warning', 'Please fill all the required fields in Event Quarter Section before proceeding to Item Cash Flow Details');
    }
    else if (selectedValue == 'Straight-Line ($)') {
      this.inputType = 'text';
      if (fieldName == 'itemCashFlowMethod') {
        this.showCashFlowDollarCal();
      }
      if (fieldName == 'hookUpCashFlowMethod') {
        this.showHookupDollarCal();
      }
      if (fieldName == 'NRECashFlowMethod') {
        this.showNREDollarCal();
      }
    }
    else {
      this.showCashFlowPercentCal(fieldName);
      this.inputType = 'number';
    }
  }

  convertDollarToNumber(dollarValue: string): number {
    if (!dollarValue) {
      return 0;
    }
    // Remove dollar sign and commas
    const numericValue = dollarValue.replace(/[$,]/g, '');
    // Parse the remaining string as a float
    return parseFloat(numericValue);
  }

  showCashFlowDollarCal() {
    let itemTotalCost = this.convertDollarToNumber(this.projectCostDetailsForm.controls['itemCost']?.value);
    let fieldValueToKeep = this.itemCashFlowForm.controls['itemCashFlowMethod'].value;
    this.itemCashFlowForm.reset();
    this.itemCashFlowForm.controls['itemCashFlowMethod'].setValue(fieldValueToKeep);

    const keywords = ['commit', 'factory', 'site', 'final'];
    const eventQuarterControlNames = ['factoryQuarterMatDate', 'siteQuarterMatDate', 'commitQuarterMatDate', 'finalQuarterMatDate'];
    const formControlsWithValue = eventQuarterControlNames.filter(name => this.eventQuarterForm.controls[name].value);
    const numberOfFormControlsWithValue = formControlsWithValue.length;
    let eachCashFlowField = itemTotalCost / numberOfFormControlsWithValue;
    keywords.forEach(keyword => {
      formControlsWithValue.forEach(controlName => {
        if (controlName.toLowerCase().includes(keyword)) {
          ['itemCashFlowCommit', 'itemCashFlowFactory', 'itemCashFlowSite', 'itemCashFlowFinal'].forEach(name => {
            if (name.toLowerCase().includes(keyword)) {
              this.itemCashFlowForm.controls[name].setValue(this.common.formatUSD(eachCashFlowField));
            }
          })
        }
      })
    });
    this.itemCashFlowForm.controls['itemCheckSum'].setValue(this.common.formatUSD(itemTotalCost));
    this.itemCashFlowForm.controls['itemCashFlowTerms'].setValue("");

    //disable all fields when dollar amount is selected
    ['itemCashFlowCommit', 'itemCashFlowFactory', 'itemCashFlowSite', 'itemCashFlowFinal'].forEach(key => {
      const myControl = this.itemCashFlowForm.get(key);
      if (myControl && myControl.enabled) {
        myControl.clearValidators();
        myControl.updateValueAndValidity();
        this.itemCashFlowForm.get(key)?.disable();
      }
    });
  }

  showHookupDollarCal() {
    let hookUpTotalCost = this.convertDollarToNumber(this.projectCostDetailsForm.controls['hookCapitalCost']?.value);
    let fieldValueToKeep = this.hookUpCashFlowForm.controls['hookUpCashFlowMethod'].value;
    this.hookUpCashFlowForm.reset();
    this.hookUpCashFlowForm.controls['hookUpCashFlowMethod'].setValue(fieldValueToKeep);

    const keywords = ['commit', 'factory', 'site', 'final'];
    const eventQuarterControlNames = ['factoryQuarterMatDate', 'siteQuarterMatDate', 'commitQuarterMatDate', 'finalQuarterMatDate'];
    const formControlsWithValue = eventQuarterControlNames.filter(name => this.eventQuarterForm.controls[name].value);
    const numberOfFormControlsWithValue = formControlsWithValue.length;
    let eachCashFlowField = hookUpTotalCost / numberOfFormControlsWithValue;
    keywords.forEach(keyword => {
      formControlsWithValue.forEach(controlName => {
        if (controlName.toLowerCase().includes(keyword)) {
          ['hookUpCashFlowCommit', 'hookUpCashFlowFactory', 'hookUpCashFlowSite', 'hookUpCashFlowFinal'].forEach(name => {
            if (name.toLowerCase().includes(keyword)) {
              this.hookUpCashFlowForm.controls[name].setValue(this.common.formatUSD(eachCashFlowField));
            }
          })
        }
      })
    });
    this.hookUpCashFlowForm.controls['hookUpCheckSum'].setValue(this.common.formatUSD(hookUpTotalCost));
    this.hookUpCashFlowForm.controls['hookUpCashFlowTerms'].setValue("");

    //disable all fields when dollar amount is selected
    ['hookUpCashFlowCommit', 'hookUpCashFlowFactory', 'hookUpCashFlowSite', 'hookUpCashFlowFinal'].forEach(key => {
      const myControl = this.hookUpCashFlowForm.get(key);
      if (myControl && myControl.enabled) {
        myControl.clearValidators();
        myControl.updateValueAndValidity();
        this.hookUpCashFlowForm.get(key)?.disable();
      }
    });
  }

  showNREDollarCal() {
    let NRETotalCost = this.convertDollarToNumber(this.projectCostDetailsForm.controls['NREtotalCost']?.value);
    let fieldValueToKeep = this.NRECashFlowForm.controls['NRECashFlowMethod'].value;
    this.NRECashFlowForm.reset();
    this.NRECashFlowForm.controls['NRECashFlowMethod'].setValue(fieldValueToKeep);

    const keywords = ['commit', 'factory', 'site', 'final'];
    const eventQuarterControlNames = ['factoryQuarterMatDate', 'siteQuarterMatDate', 'commitQuarterMatDate', 'finalQuarterMatDate'];
    const formControlsWithValue = eventQuarterControlNames.filter(name => this.eventQuarterForm.controls[name].value);
    const numberOfFormControlsWithValue = formControlsWithValue.length;
    let eachCashFlowField = NRETotalCost / numberOfFormControlsWithValue;
    keywords.forEach(keyword => {
      formControlsWithValue.forEach(controlName => {
        if (controlName.toLowerCase().includes(keyword)) {
          ['NRECashFlowCommit', 'NRECashFlowFactory', 'NRECashFlowSite', 'NRECashFlowFinal'].forEach(name => {
            if (name.toLowerCase().includes(keyword)) {
              this.NRECashFlowForm.controls[name].setValue(this.common.formatUSD(eachCashFlowField));
            }
          })
        }
      })
    });
    this.NRECashFlowForm.controls['NRECheckSum'].setValue(this.common.formatUSD(NRETotalCost));
    this.NRECashFlowForm.controls['NRECashFlowTerms'].setValue("");

    //disable all fields when dollar amount is selected
    ['NRECashFlowCommit', 'NRECashFlowFactory', 'NRECashFlowSite', 'NRECashFlowFinal'].forEach(key => {
      const myControl = this.NRECashFlowForm.get(key);
      if (myControl && myControl.enabled) {
        myControl.clearValidators();
        myControl.updateValueAndValidity();
        this.NRECashFlowForm.get(key)?.disable();
      }
    });
  }

  showCashFlowPercentCal(fieldName: string) {
    if(this.eventQuarterForm.valid){
      switch(fieldName) {
        case 'itemCashFlowMethod':
          this.enableItemFields();
          break;
        case 'hookUpCashFlowMethod':
          this.enableHookupFields();
          break;
        case 'NRECashFlowMethod':
          this.enableNREFields();
          break;
        default:
          break;
      }
      if(fieldName == ""){
        this.enableItemFields();
        this.enableHookupFields();
        this.enableNREFields();
      }
      this.totalSumCheck();
    }
  }

  totalSumCheck() {
    if (this.eventQuarterForm.controls['factoryQuarterMatDate'].value == "" || this.eventQuarterForm.controls['factoryQuarterMatDate'].value == null) {
      this.itemCashFlowForm.controls['itemCashFlowFactory'].disable();
      this.NRECashFlowForm.controls['NRECashFlowFactory'].disable();
      this.hookUpCashFlowForm.controls['hookUpCashFlowFactory'].disable();
    }
    else{
      this.itemCashFlowForm.controls['itemCashFlowFactory'].enable();
      this.NRECashFlowForm.controls['NRECashFlowFactory'].enable();
      this.hookUpCashFlowForm.controls['hookUpCashFlowFactory'].enable();
    }
    let totalSum = Number(this.itemCashFlowForm.controls['itemCashFlowCommit'].value) +
      Number(this.itemCashFlowForm.controls['itemCashFlowFactory'].value) +
      Number(this.itemCashFlowForm.controls['itemCashFlowSite'].value) +
      Number(this.itemCashFlowForm.controls['itemCashFlowFinal'].value);
    this.itemCashFlowForm.controls['itemCheckSum'].setValue(totalSum);
    this.itemCashFlowForm.controls['itemCashFlowMethod'].value == "Standard Terms (%)" ? this.setCashFlowTerms() : '';

    let NRETotalSum = Number(this.NRECashFlowForm.controls['NRECashFlowCommit'].value) +
      Number(this.NRECashFlowForm.controls['NRECashFlowFactory'].value) +
      Number(this.NRECashFlowForm.controls['NRECashFlowSite'].value) +
      Number(this.NRECashFlowForm.controls['NRECashFlowFinal'].value);
    this.NRECashFlowForm.controls['NRECheckSum'].setValue(NRETotalSum);
    this.NRECashFlowForm.controls['NRECashFlowMethod'].value == "Standard Terms (%)" ? this.setNRECashFlowTerms() : '';

    let hookupTotalSum = Number(this.hookUpCashFlowForm.controls['hookUpCashFlowCommit'].value) +
      Number(this.hookUpCashFlowForm.controls['hookUpCashFlowFactory'].value) +
      Number(this.hookUpCashFlowForm.controls['hookUpCashFlowSite'].value) +
      Number(this.hookUpCashFlowForm.controls['hookUpCashFlowFinal'].value);
    this.hookUpCashFlowForm.controls['hookUpCheckSum'].setValue(hookupTotalSum);
    this.hookUpCashFlowForm.controls['hookUpCashFlowMethod'].value == "Standard Terms (%)" ? this.setHookupCashFlowTerms() : '';
  }

  setCashFlowValueNull() {
    this.itemCashFlowForm.controls['itemCashFlowCommit'].setValue('');
    this.itemCashFlowForm.controls['itemCashFlowFactory'].setValue('');
    this.itemCashFlowForm.controls['itemCashFlowSite'].setValue('');
    this.itemCashFlowForm.controls['itemCashFlowFinal'].setValue('');

    this.NRECashFlowForm.controls['NRECashFlowCommit'].setValue('');
    this.NRECashFlowForm.controls['NRECashFlowFactory'].setValue('');
    this.NRECashFlowForm.controls['NRECashFlowSite'].setValue('');
    this.NRECashFlowForm.controls['NRECashFlowFinal'].setValue('');

    this.hookUpCashFlowForm.controls['hookUpCashFlowCommit'].setValue('');
    this.hookUpCashFlowForm.controls['hookUpCashFlowFactory'].setValue('');
    this.hookUpCashFlowForm.controls['hookUpCashFlowSite'].setValue('');
    this.hookUpCashFlowForm.controls['hookUpCashFlowFinal'].setValue('');
  }

  formatCost(fieldName: string) {
    const itemCostControl = this.projectCostDetailsForm.get('itemCost');
    const hookExpenseCostControl = this.projectCostDetailsForm.get('hookExpenseCost');
    const hookCapitalCostControl = this.projectCostDetailsForm.get('hookCapitalCost');

    // Format itemCost field
    switch (fieldName) {
      case 'itemCost':  // Format itemCost field  
        if (itemCostControl) {
          const value = this.common.parseCurrency(itemCostControl.value);
          if (value !== null && value.toString() !== '') {
            //const formattedValue = parseFloat(value).toFixed(2);
            this.formattedItemCost = this.common.formatUSD(value);
            itemCostControl.setValue(this.formattedItemCost);
          }
        }
        break;
      case 'hookExpenseCost':  // Format hookExpenseCost field    
        if (hookExpenseCostControl) {
          const value = hookExpenseCostControl.value;
          if (value !== null && value !== '' && !isNaN(value)) {
            //const formattedValue = parseFloat(value).toFixed(2);
            const formattedValue = this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2');
            hookExpenseCostControl.setValue(formattedValue);

          }
        }
        break;
      case 'hookCapitalCost':  // Format hookCapitalCost field
        if (hookCapitalCostControl) {
          const value = this.common.parseCurrency(hookCapitalCostControl.value);
          if (value !== null && value.toString() !== '') {
            //const formattedValue = parseFloat(value).toFixed(2);
            this.formattedHookupValue = this.common.formatUSD(value);
            hookCapitalCostControl.setValue(this.formattedHookupValue);

          }
        }
        break;
      default:
        break;
    }
  }

  convertToNumber(value: any): number {
    return value != null ? Number(value) : 0;
  }

  onValueChange(event: any, fieldName: string) {
    let val = event.target.value;

    if (this.eventQuarterForm.valid || this.eventQuarterForm.disabled) {
      ['itemCashFlowCommit', 'itemCashFlowFactory', 'itemCashFlowSite', 'itemCashFlowFinal'].forEach(fieldName => {
        const control = this.itemCashFlowForm.get(fieldName);
        control?.valueChanges.subscribe(value => {
          this.checkTotalSum = true;
        });
        control?.setValue(control?.value);  // Trigger a change event
      });

      ['NRECashFlowCommit', 'NRECashFlowFactory', 'NRECashFlowSite', 'NRECashFlowFinal'].forEach(fieldName => {
        const control = this.NRECashFlowForm.get(fieldName);
        control?.valueChanges.subscribe(value => {
          this.checkTotalSum = true;
        });
        control?.setValue(control?.value);  // Trigger a change event
      });

      ['hookUpCashFlowCommit', 'hookUpCashFlowFactory', 'hookUpCashFlowSite', 'hookUpCashFlowFinal'].forEach(fieldName => {
        const control = this.hookUpCashFlowForm.get(fieldName);
        control?.valueChanges.subscribe(value => {
          this.checkTotalSum = true;
        });
        control?.setValue(control?.value);  // Trigger a change event
      });
    }
    else {
      Object.keys(this.itemCashFlowForm.controls).forEach(key => {
        if (key !== 'itemCashFlowMethod') {
          this.itemCashFlowForm.controls[key].reset();
        }
      });
      Object.keys(this.NRECashFlowForm.controls).forEach(key => {
        if (key !== 'NRECashFlowMethod') {
          this.NRECashFlowForm.controls[key].reset();
        }
      });
      Object.keys(this.hookUpCashFlowForm.controls).forEach(key => {
        if (key !== 'hookUpCashFlowMethod') {
          this.hookUpCashFlowForm.controls[key].reset();
        }
      });
      this.common.show('warning', 'Please fill all the mandatory fields in Event Quarter Section')
    }

    if (this.itemCashFlowForm.valid && this.checkTotalSum) {
      let totalSum = Number(this.itemCashFlowForm.controls['itemCashFlowCommit'].value) +
       Number(this.itemCashFlowForm.controls['itemCashFlowFactory'].value) +
       Number(this.itemCashFlowForm.controls['itemCashFlowSite'].value) +
       Number(this.itemCashFlowForm.controls['itemCashFlowFinal'].value);

      if (this.itemCashFlowForm.value['itemCashFlowMethod'] == "Standard Terms (%)" && totalSum !== 100) {
        this.common.show('warning', 'The sum of all the fields should be equal to 100');
        this.itemCashFlowForm.controls['itemCheckSum'].setValue(totalSum);
		    this.hasCashFlowErrorMessage = true;							
      }
      else {
        this.itemCashFlowForm.controls['itemCheckSum'].setValue(totalSum);
		    this.hasCashFlowErrorMessage = false;							 
      }
    }

    if (this.NRECashFlowForm.valid && this.checkTotalSum) {
      let totalSum = Number(this.NRECashFlowForm.controls['NRECashFlowCommit'].value) +
        Number(this.NRECashFlowForm.controls['NRECashFlowFactory'].value) +
        Number(this.NRECashFlowForm.controls['NRECashFlowSite'].value) +
        Number(this.NRECashFlowForm.controls['NRECashFlowFinal'].value);

      if (this.NRECashFlowForm.value['NRECashFlowMethod'] == "Standard Terms (%)" && totalSum !== 100) {
        this.common.show('warning', 'The sum of all the fields should be equal to 100');
        this.NRECashFlowForm.controls['NRECheckSum'].setValue(totalSum);
		    this.hasNREErrorMessage = true;							
      }
      else {
        this.NRECashFlowForm.controls['NRECheckSum'].setValue(totalSum);
		    this.hasNREErrorMessage = false;							 
      }
    }

    if (this.hookUpCashFlowForm.valid && this.checkTotalSum) {
      let totalSum = Number(this.hookUpCashFlowForm.controls['hookUpCashFlowCommit'].value) +
        Number(this.hookUpCashFlowForm.controls['hookUpCashFlowFactory'].value) +
        Number(this.hookUpCashFlowForm.controls['hookUpCashFlowSite'].value) +
        Number(this.hookUpCashFlowForm.controls['hookUpCashFlowFinal'].value);

      if (this.hookUpCashFlowForm.value['hookUpCashFlowMethod'] == "Standard Terms (%)" && totalSum !== 100) {
        this.common.show('warning', 'The sum of all the fields should be equal to 100');
        this.hookUpCashFlowForm.controls['hookUpCheckSum'].setValue(totalSum);
		    this.hasHookupErrorMessage = true;							
      }
      else {
        this.hookUpCashFlowForm.controls['hookUpCheckSum'].setValue(totalSum);
		    this.hasHookupErrorMessage = false;							 
      }
    }
    this.setCashFlowTerms();
    this.setNRECashFlowTerms();
    this.setHookupCashFlowTerms();

  }

  setCashFlowTerms() {
    this.itemCashFlowForm.controls['itemCashFlowTerms'].setValue(
      this.convertToNumber(this.itemCashFlowForm.controls['itemCashFlowCommit'].value) + "/" +
      this.convertToNumber(this.itemCashFlowForm.controls['itemCashFlowFactory'].value) + "/" +
      this.convertToNumber(this.itemCashFlowForm.controls['itemCashFlowSite'].value) + "/" +
      this.convertToNumber(this.itemCashFlowForm.controls['itemCashFlowFinal'].value)
    );
  }

  setNRECashFlowTerms() {
    this.NRECashFlowForm.controls['NRECashFlowTerms'].setValue(
      this.convertToNumber(this.NRECashFlowForm.controls['NRECashFlowCommit'].value) + "/" +
      this.convertToNumber(this.NRECashFlowForm.controls['NRECashFlowFactory'].value) + "/" +
      this.convertToNumber(this.NRECashFlowForm.controls['NRECashFlowSite'].value) + "/" +
      this.convertToNumber(this.NRECashFlowForm.controls['NRECashFlowFinal'].value)
    );
  }

  setHookupCashFlowTerms() {
    this.hookUpCashFlowForm.controls['hookUpCashFlowTerms'].setValue(
      this.convertToNumber(this.hookUpCashFlowForm.controls['hookUpCashFlowCommit'].value) + "/" +
      this.convertToNumber(this.hookUpCashFlowForm.controls['hookUpCashFlowFactory'].value) + "/" +
      this.convertToNumber(this.hookUpCashFlowForm.controls['hookUpCashFlowSite'].value) + "/" +
      this.convertToNumber(this.hookUpCashFlowForm.controls['hookUpCashFlowFinal'].value)
    );
  }

  onWBSPanelOpen() {
    const nreTotalCostValue = this.projectCostDetailsForm.controls['NREtotalCost'].value;
    this.common.setData(this.capexForm);
   // this.wbsDetails.accessWbsDetails(this.capexForm.value);
   this.wbsDetails.accessWbsDetails({
    pornumber: this.porNum,
    wbsstatus: this.wbsStatus,
    ...this.capexForm.value,
    NREtotalCost: nreTotalCostValue // Passing NREtotalCost along with other values
  });
  }

  onWBSCashPanelOpen() {
    this.isDetailsPanelExpanded = true;
    const nreTotalCostValue = this.projectCostDetailsForm.controls['NREtotalCost'].value;
    const engrCost = this.projectCostDetailsForm.controls['engrNRECost'].value;
    const techCost = this.projectCostDetailsForm.controls['techNRECost'].value;
    this.common.setData(this.capexForm);

    this.wbsCashFlow.accessWbsCashFlow({
      ...this.capexForm.value,
      ...this.wbsDetails,
      NREtotalCost: nreTotalCostValue, // Passing NREtotalCost along with other values
      engrCost: engrCost,
      techCost: techCost
    });
  }

  onWbsFormDataChange(data: any) {
    this.wbsDetailsForm = data;
    this.capexForm.controls['wbsDetails'] = this.wbsDetailsForm;
    if (this.wbsDetailsForm && this.wbsDetailsForm.valid) {
      this.isWBSSectionFilled = true
      //this.adjustQuarterForm.controls['monthsOverdue'].setValue(0);
    }
    else {
      this.isWBSSectionFilled = false
    }
  }

  onWbsCashFlowDataChange(data: any) {
    this.wbsCashFlowForm = data;
    this.capexForm.controls['wbsCashflow'] = this.wbsCashFlowForm;
    if (this.wbsCashFlowForm && this.wbsCashFlowForm.valid) {
      this.isWBSCashFlowFilled = true
    }
    else {
      this.isWBSCashFlowFilled = false
    }

  }

  onWBSCashFlowDetailsPanelOpen(){
    this.wbsCashFlowDetails.accessCashFlowDetails({
      ...this.capexForm.value,
      ...this.wbsDetails,
   });
  }

  onWBSCashFlowDetailsPanelClose(){
    this.wbsCashFlowDetails.closeCashFlowDetails();
  }

  onWBSCashFlowDetailsDataChange(data: any){

  }

  async onAssetPanelOpen() {
    console.log("asset panel Open")
    this.common.setData(this.capexForm);
   // this.assetDetails.accessAssetDetails(this.capexForm.value);

   await this.wbsCashFlowDetails.accessCashFlowDetails({
    ...this.capexForm.value,
    ...this.wbsDetails,
  });

    this.assetDetails.accessAssetDetails({
      ...this.capexForm.value,
      ...this.wbsDetails,
      ...this.wbsCashFlow,
      POR: this.porNum,
      PORStatus : this.porStatus,
      CommitSubMRU: this.financialDetailsForm.controls['commitSubMRU'].value,
      DepricationSubMRU: this.financialDetailsForm.controls['deprSubMRU'].value,
      estDepCost: this.projectCostDetailsForm.controls['estimatedQatrDepr'].value,
      totalCost: this.projectCostDetailsForm.controls['totalCost'].value,
      estimatedProjectEndDate: this.wbsDetails.wbsDetailsForm.controls['estimatedProjectEnd'].value || ''
      //depreciationManager: this.financialDetailsForm.controls['deprCCManager'].value
      //totalCostFlow: TotalCostFloValue // Passing NREtotalCost along with other values
    });

    if (this.editLTP) {
      this.assetDetails.onRequestDetailsFormValid(this.capexForm);
    }

    else {
      const wbsDetailsValue = this.wbsDetails.getFormValue();
      const wbsNumber = wbsDetailsValue["wbs"];
      // Start the loading indicator if you have one
      this.common.loader.next(true);
      this.common.getCapexAPI('ltp-por/validate-wbs?wbs=' + wbsNumber).subscribe({
        next: (data) => {
          this.iswbsNumberExist = data.message;
          // Perform conditional logic after receiving the response
          if (this.wbsDetailsForm && this.wbsDetailsForm.valid && !this.iswbsNumberExist) {
            this.assetDetails.onRequestDetailsFormValid(this.capexForm);
          }
          else if (this.iswbsNumberExist) {
            this.common.show('warning', 'The wbs already Exists');
          }
          else {
            this.assetDetails.onRequestDetailsFormValid('Warning message');
          }

          // Stop the loading indicator
          this.common.loader.next(false);
        },
        error: (err) => {
          // Handle the error
          this.common.loader.next(false);
          console.error("Error occurred:", err);
          // You might want to display an error message to the user here
        }
      });
    }
  }

  // onAssetPanelOpen(): void {
  //   console.log("asset panel Open")

  //   if (this.editLTP) {
  //     this.assetDetails.onRequestDetailsFormValid(this.capexForm);
  //   }

  //   else {
  //     const wbsDetailsValue = this.wbsDetails.getFormValue();
  //     const wbsNumber = wbsDetailsValue["wbs"];
  //     // Start the loading indicator if you have one
  //     this.common.loader.next(true);
  //     this.common.getCapexAPI('ltp-por/validate-wbs?wbs=' + wbsNumber).subscribe({
  //       next: (data) => {
  //         this.iswbsNumberExist = data.message;
  //         // Perform conditional logic after receiving the response
  //         if (this.wbsDetailsForm && this.wbsDetailsForm.valid && !this.iswbsNumberExist) {
  //           this.assetDetails.onRequestDetailsFormValid(this.capexForm);
  //         }
  //         else if (this.iswbsNumberExist) {
  //           this.common.show('warning', 'The wbs already Exists');
  //         }
  //         else {
  //           this.assetDetails.onRequestDetailsFormValid('Warning message');
  //         }

  //         // Stop the loading indicator
  //         this.common.loader.next(false);
  //       },
  //       error: (err) => {
  //         // Handle the error
  //         this.common.loader.next(false);
  //         console.error("Error occurred:", err);
  //         // You might want to display an error message to the user here
  //       }
  //     });
  //   }
  // }

  onAssetFormDataChange(assetDetailData: any) {
    this.assetDetailsForm = assetDetailData;
    this.capexForm.controls['assetDetails'] = this.assetDetailsForm;
    //this.capexForm.value['assetDetails'] = this.assetDetailsForm.getRawValue();
  }

  checkVisibilityConditions(): void {
    this.shouldDisplayEmailDiv = (
         this.reqNum === this.ltpNum &&
        this.view === 'approver' &&
        this.Depreciation_Manager_Approval !== 'Approved' &&
        this.Depreciation_Manager_Approval !== 'Rejected' && 
        this.userName === this.depreciationccManager
    );
}


  onApprove(): void {
    console.log(this.cashFlowDetailsForm.value.eventQuarter.estimatedProjectEndMatDate);
    if (this.approvalForm.valid) {
      const payload = {
        status: 'Approved',
        comment: this.approvalForm.value.comment,
        email: this.userName,
        date: new Date().toISOString(),
        por: this.porNum,
        projecttype: this.capexForm.value.requestDetails.projectType,
      };
  
      this.common.postCapexAPI('ltp-por/update-depreciation-approval', payload).subscribe({
        next: (data) => {
          console.log('Approval successful:', data);
          if (!this.isMandEProjectType()) {
            // Mark WBS status as requested since the request has been approved by depreciation manager
            this.wbsDetails.wbsDetailsForm.controls['wbsStatus'].setValue('Requested');
            this.wbsStatus = 'Requested';
            console.log('M&E Project Type:', this.isMandEProjectType());
          }
          this.approvalStatus = 'Approved';

          const estimatedEndDate = this.wbsDetails.wbsDetailsForm.controls['estimatedProjectEnd'].value
          const estimatedProjectEndQtr = this.common.getQuarterFromDate(estimatedEndDate);
          
          if(!this.isMandEProjectType() && this.Is_Request_WBS_Email_Sent !== true){
          const notificationBody = {
            notificationType: 'Ready For WBS',
             ltp: this.ltpNum,
             por: this.porNum,
             projectManager: this.capexForm.value.requestDetails.projectManagerEmail || '',
             toolDescription: this.capexForm.value.requestDetails.itemDescription || '',
            // commitQuarter: this.capexForm.value.cashFlowDetails.eventQuarter.commitQuarter || '',
             building: this.capexForm.value.requestDetails.building || '',
             labFabInstallLocation: this.capexForm.value.requestDetails.labfabInstallLocation || '',
             projectStartDate:this.wbsDetails.wbsDetailsForm.value.projectStartDate || '',
             estimatedProjectEnd: estimatedEndDate,
             estimatedProjectEndQtr: estimatedProjectEndQtr,
             projectType:this.capexForm.value.requestDetails.projectType || '',
             commitCostCenter: this.capexForm.value.financialDetails.commitCostCenter || '',
             finance: this.capexForm.value.requestDetails.finance || '',
             totalCost: this.totalCost_Email || '',
             isRequestWBSEmailSent: true 
          };
          console.log(notificationBody);
          this.common.postCapexAPI('capex-notify', notificationBody).subscribe({
            next: (notificationData) => {
              this.common.show('success', 'Email notification sent successfully.');
            },
            error: (notificationErr) => {
              console.error("Error sending email notification:", notificationErr);
              this.common.show('error', 'Failed to send email notification.');
            }
          });
        }

        },
        error: (err) => {
          console.error('Approval failed:', err);
        }
      });
    }
  }
  

  onReject(): void {
    console.log(this.capexForm.value.wbsDetails.depreciationStartDate);
    if (this.approvalForm.valid) {
      const payload = {
        status: 'Rejected',
        comment: this.approvalForm.value.comment,
        email: this.userName,
        date: new Date().toISOString(),
        por: this.porNum
      };

      this.common.postCapexAPI('ltp-por/update-depreciation-approval', payload).subscribe({
        next: (data) => {
          console.log('Rejection successful:', data);
          this.approvalStatus = 'Rejected';

          const notificationBody = {
            notificationType: 'Depreciation Rejected',
             ltp: this.ltpNum,
             por: this.porNum,
             planner: this.capexForm.value.requestDetails.planner || '',
             projectManager: this.capexForm.value.requestDetails.projectManagerEmail || '',
             toolDescription: this.capexForm.value.requestDetails.itemDescription || '',
             commitQuarter: this.capexForm.value.cashFlowDetails.eventQuarter.commitQuarter || '',
             building: this.capexForm.value.requestDetails.building || '',
             labFabInstallLocation: this.capexForm.value.requestDetails.labfabInstallLocation || '',
             commitManager: this.capexForm.value.financialDetails.commitCCManager || '',
             depreciationManager: this.depreciationccManager || '', 
             depreciationStartDate: this.capexForm.value.wbsDetails.depreciationStartDate || '',
             estimatedDepreciationCost: this.capexForm.value.projectCostDetails.estimatedQatrDepr || '',
             assets: this.capexForm.value.requestDetails.assets || '',
             assetLife: this.capexForm.value.requestDetails.assetUsefulLife || '',
             depreciationSubMRU: this.capexForm.value.financialDetails.deprSubMRU || '',
             commitSubMRU: this.capexForm.value.financialDetails.commitSubMRU || ''
          };
  
          console.log(notificationBody);
          this.common.postCapexAPI('capex-notify', notificationBody).subscribe({
            next: (notificationData) => {
              this.common.show('success', 'Email notification sent successfully.');
            },
            error: (notificationErr) => {
              console.error("Error sending email notification:", notificationErr);
              this.common.show('error', 'Failed to send email notification.');
            }
          });
        },
        error: (err) => {
          console.error('Rejection failed:', err);
        }
      });
    }
  }

  handlePorStatusUpdate(status: string) {
    this.porStatus = status;
  }

  handleAssetUsefulLife(assetuselife: string) {
    this.requestDetailsForm.controls['assetUsefulLife'].setValue(assetuselife);
  }
  

  // private onDateChangeListener(): void {
  //     this.adjustQuarterForm.get('adjustedFinalQuarterMatDate')?.valueChanges.subscribe(value => {
  //         this.isRtoDateChanged = !!value; // Mark true if RTO date is changed
  //         if (this.isRtoDateChanged) {
  //             this.adjustQuarterForm.get('rtoDateChangeReason')?.setValidators(Validators.required);
  //             this.adjustQuarterForm.get('rtoDateChangeComment')?.setValidators(Validators.required);
  //         } else {
  //             this.adjustQuarterForm.get('rtoDateChangeReason')?.clearValidators();
  //             this.adjustQuarterForm.get('rtoDateChangeComment')?.clearValidators();
  //         }
  //         this.adjustQuarterForm.get('rtoDateChangeReason')?.updateValueAndValidity();
  //         this.adjustQuarterForm.get('rtoDateChangeComment')?.updateValueAndValidity();
  //     });
  // }

  calculateMonthsOverdue() {
    const estimatedProjectEndMatDateValue = this.eventQuarterForm.controls['estimatedProjectEndMatDate'].value;
    //const adjustedEstimatedProjectEndMatDateValue = this.adjustedFinalQuarterMatDate;
    const adjustedEstimatedProjectEndMatDateValue = this.adjustQuarterForm.controls['adjustedEstimatedProjectEndMatDate'].value;
    // const estimatedProjectEndMatDateValue = "2026-03-27";
    // const adjustedEstimatedProjectEndMatDateValue = "2027-04-01";

    if (estimatedProjectEndMatDateValue && adjustedEstimatedProjectEndMatDateValue) {

      const estimatedProjectEndMatDate = new Date(estimatedProjectEndMatDateValue);
      const adjustedEstimatedProjectEndMatDate = new Date(adjustedEstimatedProjectEndMatDateValue);

      let monthsDifference =
        (adjustedEstimatedProjectEndMatDate.getFullYear() - estimatedProjectEndMatDate.getFullYear()) * 12 +
        (adjustedEstimatedProjectEndMatDate.getMonth() - estimatedProjectEndMatDate.getMonth());

      if (adjustedEstimatedProjectEndMatDate.getDate() < estimatedProjectEndMatDate.getDate()) {
        monthsDifference -= 1; 
      }
      this.adjustQuarterForm.controls['monthsOverdue'].setValue(Math.max(monthsDifference, 0));
    } else {

      this.adjustQuarterForm.controls['monthsOverdue'].setValue(0);
    }
  }  

  isPORRequestType(): boolean {
    const requestType = this.requestDetailsForm.get('requestType')?.value;
    return requestType === 'POR(Current Fiscal Year)';
  }

  isMandEProjectType(): boolean {
  const projectType = this.requestDetailsForm.get('projectType')?.value;
  return projectType === "M&E";
  }

  preventKeyPress(event: KeyboardEvent){
    event.preventDefault();
  }

  onExternalChange(event: MatSelectChange) {
    let selectedValue = event.value;
    if (selectedValue.includes('None') && selectedValue.length > 1) {
      selectedValue = selectedValue.filter((value: string) => value !== 'None');
      this.requestDetailsForm.get('external')?.setValue(selectedValue);
    }
  }
}