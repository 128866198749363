import { NgModule } from '@angular/core';
import { AssetComponent } from './asset.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/modules/material.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { AssetRoutingModule } from './asset-routing.module';
import { AppDetailComponent } from './app-detail/app-detail.component';
import { HistoryComponent } from './history/history.component';
import { SaveAsAssetComponent } from './save-as-asset/save-as-asset.component';
import { ExportS4Component } from './export-s4/export-s4.component';
import { AssetRequestChangeComponent } from './asset-request-change/asset-request-change.component';
import { RetirementRequestComponent } from './retirement-request/retirement-request.component';
import { ActionPlanComponent } from './action-plan/action-plan.component';
import { ApproversComponent } from './approvers/approvers.component';
import { LabFabDisplayComponent } from './lab-fab-display/lab-fab-display.component';
import { DataVerificationComponent } from './data-verification/data-verification.component';
import { EditLabfabnameComponent } from './edit-labfabname/edit-labfabname.component';
import { ExportHistoryComponent } from './export-history/export-history.component';
import { EditFixedAssetVerificationComponent } from './edit-fixed-asset-verification/edit-fixed-asset-verification.component';
import { PrintVerificationComponent } from './print-verification/print-verification.component';
import { ActionPlanRetireComponent } from './retirement-request/action-plan-retire/action-plan-retire.component';


@NgModule({
  declarations: [
    AssetComponent,
    AppDetailComponent,
    HistoryComponent,
    SaveAsAssetComponent,
    ExportS4Component,
    AssetRequestChangeComponent,
    RetirementRequestComponent,
    ActionPlanComponent,
    ApproversComponent,
    LabFabDisplayComponent,
    ActionPlanRetireComponent,
    DataVerificationComponent,
    EditLabfabnameComponent,
    ExportHistoryComponent,
    EditFixedAssetVerificationComponent,
    PrintVerificationComponent
  ],
  imports: [
    CommonModule,
    AssetRoutingModule,
    MaterialModule,
    SharedModule,
  ],

  providers: []
})
export class AssetModule { }
