<div class="row">
  <div class="col-12 text-center mb-3">
    <button class="btn btn-primary mr-2" (click)="newfab()">
      <i class="fa fa-fw fa-plus"></i>
      New LabFab
    </button>
    <button class="btn btn-primary mr-2" (click)="files.click()">
      <i class="fa fa-upload"></i>Import LabFab
    </button>
    <input class="btn btn-primary mr-2" id="files" style="display: none" type="file" accept=".xlsx, .xls"
      multiple="false" (change)="importLabFab($event)" #files>
    <button class="btn btn-primary" (click)="exportLabFab()">
      <i class="fa fa-file-excel-o"></i>Export LabFab
    </button>
  </div>
</div>

<form *ngIf="show" [formGroup]="assetdetails1" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-6">
      <mat-form-field class="w-100">
        <mat-label>Site</mat-label>
        <mat-select formControlName="Asset_Location" (selectionChange)="SearchProduct()">
          <mat-option hidden value="" disabled selected>Select Location </mat-option>
          <mat-option value="" selected>Select location</mat-option>
          <mat-option *ngFor="let val of arr1" [value]="val">{{val}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field class="w-100">
        <mat-label>Building</mat-label>
        <mat-select formControlName="Labname">
          <mat-option hidden value="" disabled selected>Select lab name </mat-option>
          <mat-option value="" selected>Select lab name</mat-option>
          <mat-option *ngFor="let val of arr2" [value]="val">{{val}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field class="w-100">
        <mat-label>LabFab Name</mat-label>
        <input matInput formControlName="LabFab_Name">
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field class="w-100">
        <mat-label>OWNER EMAIL</mat-label>
        <input matInput formControlName="OWNER_EMAIL">
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field class="w-100">
        <mat-label>Scanner EMAIL</mat-label>
        <input matInput formControlName="Scanner_EMAIL">
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field class="w-100">
        <mat-label>Scanner II EMAIL</mat-label>
        <input matInput formControlName="Scanner_II_EMAIL">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <button type="submit" class="btn btn-primary">Search</button>
    </div>
  </div>
</form>


<div *ngIf="assetDetails">
  <span>Row Count : <b>{{rowCount}}</b></span>
  <ng-container *ngIf="assetDetails && pageLength.length != 1">
    <span>Page</span>
    <span *ngFor="let page of pageLength">
      <button class="btnPagination" (click)="pageNext(page)">
        <b *ngIf="page===currentPage" class="btnPaginationHover">{{page}}</b>
        <span *ngIf="page!==currentPage">{{page}}</span>
      </button>
    </span>
  </ng-container>
</div>

<div class="table-responsive">
  <table id="dtHorizontalExample" class="table table-striped" *ngIf="assetDetails && showGrid">
    <thead class="custfont">
      <tr>
        <th class="bg-primary text-white">
        </th>
        <th class="bg-primary text-white" *ngFor="let title of tableHeaderArray; index as i">
          <div *ngIf="title!='Checkbox_Data'" (click)="sortData(i,title)">
            <span>{{title.split("_").join(" ")}}</span>
            <i *ngIf="i==columnindex && ascendingsort==true" class="fa fa-arrow-up"></i>
            <i *ngIf="i==columnindex && ascendingsort==false" class="fa fa-arrow-down"></i>
          </div>
          <div *ngIf="title!='Checkbox_Data'" style="padding-top: 5px;">
            <input type="text" class="form-cntrl" [(ngModel)]="filterText[i]"
              (input)="filterValue(filterText, i, title)" placeholder="Enter Keyword">
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="!noRecord">
        <tr *ngFor="let item of assetDetails">
          <td>
            <input type="button" *ngIf="IsdisableEdit" class="btn btn-primary" (click)="edit(item['LABFAB Name'])"
              value="Edit" />
          </td>
          <td *ngFor="let data of item | keyvalue: originalOrder">
            <span *ngIf="data.key!='Checkbox_Data'">{{data.value}}</span>
          </td>
        </tr>
      </ng-container>
      <span *ngIf="noRecord">No Records to Display!</span>
    </tbody>
  </table>
</div>