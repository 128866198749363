import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { filter } from "rxjs";

@Component({
    selector: 'app-homePage',
    templateUrl: './homePage.component.html',
    styleUrls: ['./homePage.component.scss']
  })
  export class HomePageComponent {

    isHomePage = false;
    imgPath = this.common.imgPath;

    constructor(private common : CommonService, private router: Router){
        this.common.bannerData.next({title: 'AMD', subtitle: 'Welcome to the Asset Management Database'})
        this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
            this.isHomePage = this.router.url === '/home'; // Change '/home' to your actual homepage route
        });
    }
   

  }