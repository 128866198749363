 <!-- WBS Details accordion-->
<form [formGroup]="wbsDetailsForm">
    <div class="container-fluid pd-0">
        <div class="row">
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">WBS#</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="wbs"
                    (input)="onValueChange($event, 'wbs')">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Original WBS Amount</mat-label>
                <input matInput type="number" placeholder="" class="disabledColor" formControlName="originalWbsAmount">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Adjusted WBS Amount</mat-label>
                <input matInput type="number" placeholder="" class="disabledColor" formControlName="adjustedWbsAmount"
                    (input)="onValueChange($event, 'adjustedWbsAmount')">
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Actual Commit Date/Project Start Date</mat-label>
                <input matInput [matDatepicker]="picker" [min]="minDate" placeholder="Choose a date"
                    class="disabledColor offscreen" formControlName="actualCommitMatDate"
                    (dateChange)="onDateChange($event, 'actualCommitDate', picker)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <input matInput placeholder="Choose a date" formControlName="actualCommitDate" class="disabledColor"
                    readonly>
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Estimated Project End/Asset Setup Date</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="estimatedProjectEnd">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">WBS Status</mat-label>
                <mat-select formControlName="wbsStatus">
                    <mat-option *ngFor="let option of wbsStatusOptions" [value]="option">{{option}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">WBS Status Comments</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="comments"
                    (input)="onValueChange($event, 'comments')">
            </mat-form-field>
        </div>

        <div class="row border-container">
            <span class="text-over-border">Receiving WBS Details</span>
        </div>
        <span style="color: red;">If receiving location for tool is in a different Region or Company Code, you must have
            a Receiving WBS that exists or is newly generated. This is to support IC Billing.</span>
        <div class="row">
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Receiving WBS#</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="receivingWbs"
                    (input)="onValueChange($event, 'receivingWbs')">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Receiving Project Start Date</mat-label>
                <input matInput [matDatepicker]="projectStartDatePicker" [min]="minDate" placeholder="Choose a date"
                    class="disabledColor offscreen" formControlName="projectStartMatDate"
                    (dateChange)="onDateChange($event, 'projectStartDate', projectStartDatePicker)">
                <mat-datepicker-toggle matSuffix [for]="projectStartDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #projectStartDatePicker></mat-datepicker>
                <input matInput placeholder="Choose a date" formControlName="projectStartDate" class="disabledColor"
                    readonly>
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Receiving WBS Amount</mat-label>
                <input matInput type="number" placeholder="" class="disabledColor" formControlName="receivingWbsAmount"
                    (input)="onValueChange($event, 'receivingWbsAmount')">
            </mat-form-field>
        </div>

        <div class="row border-container">
            <span class="text-over-border">WBS Closure</span>
        </div>
        <div class="row">
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">WBS Disposition</mat-label>
                <mat-select formControlName="disposition">
                    <mat-option *ngFor="let option of wbsDispositionOptions" [value]="option">{{option}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Depreciation Start Date</mat-label>
                <input matInput [matDatepicker]="depreciationPicker" [min]="minDate" placeholder="Choose a date"
                    class="disabledColor offscreen" formControlName="depreciationStartMatDate"
                    (dateChange)="onDateChange($event, 'depreciationStartDate', depreciationPicker)">
                <mat-datepicker-toggle matSuffix [for]="depreciationPicker"></mat-datepicker-toggle>
                <mat-datepicker #depreciationPicker></mat-datepicker>
                <input matInput placeholder="Choose a date" formControlName="depreciationStartDate" class="disabledColor"
                    readonly>
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">WBS Closure Comments</mat-label>
                <input matInput type="number" placeholder="" class="disabledColor" formControlName="closureComments"
                    (input)="onValueChange($event, 'closureComments')">
            </mat-form-field>
        </div>
    </div>
</form>


