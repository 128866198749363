import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'stakeholder-assessment',
  templateUrl: './stakeholder-assessment.component.html',
  styleUrls: ['./stakeholder-assessment.component.scss']
})
export class StakeholderAssessmentComponent implements OnInit {
  z:any;
  stakeHolderName!: any[];
  tabSelected: any;
  tabData:any;
  tabSelected1: any;
  tabSelected2: any;
  fieldData: any;
  detailsForm!: FormGroup;
  labFabDetails!: FormGroup;
  // capDetails: FormGroup;
  PORData: any;
  bstrData: any;
  matrixData: any[] = [];
  labFabMatrixData: any;
  matrixHeaders: any;
  indexArray: any[] = [];
  stakeHolderNamePath!: string;
  statusChange!: string;
  SHArray: {key: string, value: string, actionPlan: string, showSHActionPlan: boolean, showFocusArea: boolean, tempValue: string}[] = [];
  SHArray_Count=0
  procurementFlag:boolean=true;
  buildingLabFab!: FormGroup;
  dbFAName!: string;
  sendFormVar! :any;
  loading!: boolean;
  tableloading!: boolean
  questionArray1!: string[];
  answerArray: any;
  statusUpdate: any;
  stakeHolderData: any;
  focusAreaName: any;
  abbreviatedSHName!: { key: string; value: string; }[];
  sendStatusUpdate:any = {}
  capDetailsData: any;
  // fetchFAStatusData: { por_number: number; };
  viewAttachedFiles: any;
  showProgressBar!: boolean;
  LabfabTeams: any;
  FA_Available: any = [];
  FAOriginalName: any;
  assessmentData: any;
  combinedArray!: Array<string>
  loggedInUser!: string;
  hideOriginalStatus!: boolean;
  hideOriginalFAStatus: boolean = false;
  FAStatus: any;
  originalStatusVal: any;
  showRequester!: boolean;
  editMode!: boolean;
  FAArray!: Array<string>
  allStakeholders!: Set<string>;
  showActionPlanTab: boolean = false;
  LabFabRenaming!: { labfabFA: string; dbName: string;}[];
  showWaiveLink: boolean = false;
  userResponse: any;
  commonInfo: any;
  porstatus: any;
  PaasedData: any;
  
  constructor(private fb: FormBuilder, private common: CommonService, 
    private router: Router, private httpClient: HttpClient, private activatedRoute: ActivatedRoute) {
      this.common.bannerData.next({ title: 'StakeHolder Details'});
     }

  async ngOnInit(): Promise<void> {
    this.common.loader.next(true);
    this.loggedInUser = atob(this.common.currentUser);

    // Initialize combinedArray
    this.combinedArray = [];

    this.z=2;
    this.tabSelected1 = 'Tool Information';
    this.tabSelected2 = 'POR Information';
    this.statusChange = 'Not Available'
    this.FAOriginalName = this.activatedRoute.snapshot.queryParams['FA'];
    

    this.stakeHolderName = [
      'Asset Administration', 'Automated Equipment Control (AEC)', 'Cybersecurity', 'Environmental Health and Safety', 'Electrical', 
      'Ergonomics', 'FAB Environmental', 'Information Technology(IT)', 'Mechanical and HVAC', 
      'Micro-contamination and ESD', 'Operations', 'Process Systems', 'Procurement', 'SCADA Instramental Control', 'Site EHS',
      'Space Layout (Non-office)','Procurement'
    ]

    this.SHArray = [
      {key: 'Asset Administration', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Automated Equipment Control', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Cybersecurity', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Environmental Health and Safety', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Electrical', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Ergonomics', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'FAB Environmental', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Information Technology', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Mechanical and HVAC', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Microcontamination and ESD', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Operations', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Process Systems', value: 'Not Available', actionPlan: '',showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'SCADA Instramental Control', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Site EHS', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Space Layout Non-office', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''},
      {key: 'Procurement', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false, tempValue: ''}
      // ,{key:'Sample_Question_Fields', value: 'Not Available'}
    ]

    this.labFabDetails = this.fb.group({
      LabName: [''],
      Building: [''],
      FocusArea: [''],
      FAPrimary: [''],
      FASecondary: [''],
      FAWatcher: [''],
      por_number: ['']
    });

    this.common.passData.subscribe({
      next: (data) => {
        this.PaasedData = data;
        console.log('Function Data', data)
        this.capDetailsData = data;
        //this.capDetailsData['PORStatus'] = 'Active'
          if (this.PaasedData?.CapStatus && this.PaasedData.CapStatus !== 'Complete' && this.PaasedData.CapStatus !== 'Canceled') {
            this.capDetailsData['CAP_Status'] = 'Active';
          }
        console.log("inside passData", this.capDetailsData)
        this.formData(this.capDetailsData)
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    })

    this.sendStatusUpdate = {
      POR: this.capDetailsData['POR'] == undefined ? this.activatedRoute.snapshot.queryParams['POR'] : this.capDetailsData['POR'],
      PORStatus: this.capDetailsData['PORStatus'],
      CAPStatus: this.capDetailsData['CAP_Status']
    }
    console.log('Data to API',this.sendStatusUpdate);

    this.sendFormVar = {
      labName: localStorage.getItem('InitiateLabFab'),
      focusArea: this.FAOriginalName,
      POR: this.activatedRoute.snapshot.queryParams['POR']
    }
    
     //To fetch Focus Area name 
    this.common.focusAreaStatus.subscribe({
      next: (data) => {
        if (data && data.focusAreaName) {
        this.focusAreaName = data['focusAreaName']
        }
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });

    await this.updateStatusAndFetchFAStatus();
    await this.checkAllReviewsCompletedAndSendEmail();

  } // init ENDS


  async updateStatusAndFetchFAStatus() {
    try {
      // Update POR status in db
      const updateStatusResponse = await this.common.postCapAPI('itg/cap-update-por-status-itg', this.sendStatusUpdate).toPromise();
      this.capDetailsData = updateStatusResponse['body'];
      console.log("inside updateStatus", this.capDetailsData);
      this.labFabDetails.value['LabName'] = this.capDetailsData['LabFab_Install_Location'];
      this.labFabDetails.value['Building'] = this.capDetailsData['InitiateBuilding'];
      localStorage.setItem('InitiateLabFab', this.capDetailsData['LabFab_Install_Location']);
      localStorage.setItem('InitiateBuilding', this.capDetailsData['InitiateBuilding']);
      this.showRequester = this.capDetailsData['RequesterName'] == this.loggedInUser || this.capDetailsData['SecondaryReq'] == this.loggedInUser;
      await this.formData(this.capDetailsData);
      console.log("Print ele", updateStatusResponse);
  
      // Fetch FA Status
      this.tableloading = true;
      const fetchFAStatusResponse = await this.common.postCapAPI('itg/cap-fetch-fa-status-itg', this.sendStatusUpdate).toPromise();
      this.FAStatus = fetchFAStatusResponse['body'];
      console.log("Print ele", fetchFAStatusResponse);
  
      // Process FA Status
      for (let i = 0; i < this.FAStatus.length; i++) {
        this.focusAreaName = this.FAStatus[i]['Focus_Area'];
        this.showActionPlanTab = this.FAStatus.some((ele: { [x: string]: string; }) => ele['actionPlan_Status'] == 'Action Plan');
        this.SHArray.forEach(val => {
          if (val['key'] == this.focusAreaName) {
            if (this.FAStatus[i]['Status'] != 'Action Plan') {
              val['value'] = this.FAStatus[i]['Status'];
            }
            val['actionPlan'] = this.FAStatus[i]['actionPlan_Status'];
            let valueToCompare = this.FAStatus[i]['Status'];
            if (['SH Review', 'Waive', 'Action Plan', 'SH Review - Draft', 'SH Review - Complete', 'SH Review - Waive'].includes(valueToCompare)) {
              this.FA_Available.push(this.focusAreaName);
            }
          }
        });
      }
      await this.checkSHReview();
    } catch (error) {
      console.error("Error occurred:", error);
      alert("Please connect with Asset Admin");
    }
  }

  async checkAllReviewsCompletedAndSendEmail() {
    console.log('this.FAStatus', this.FAStatus.length)
    if (this.FAStatus.length > 0) {
      const allReviewsCompleted = this.FAStatus.every((status: any) =>
        status.Status === 'SH Review - Complete' || status.Status === 'Waive' || status.Status === 'SH Review - Waive'
      );

       console.log('approved', this.capDetailsData.Depreciation_Manager_Approval.Depreciation_Manager_Approval_Status);
      if (allReviewsCompleted == true && this.capDetailsData.Depreciation_Manager_Approval.Depreciation_Manager_Approval_Status === 'Approved') {
        if (this.capDetailsData.Is_Request_WBS_Email_Sent !== true) {
          await this.sendEmailNotification();
        }
      }
      if (allReviewsCompleted == true && (this.capDetailsData.Depreciation_Manager_Approval.Depreciation_Manager_Approval_Status === undefined || this.capDetailsData.Depreciation_Manager_Approval.Depreciation_Manager_Approval_Status === 'Pending')) {
        await this.sendReminderEmailNotification();
      }
    }

  }

  async sendEmailNotification() {
    const notificationBody = {
      notificationType: 'Ready For WBS',
      ltp: this.capDetailsData.LTP,
      por: this.capDetailsData.POR,
      projectManager: this.capDetailsData.Project_Manager_Email || '',
      toolDescription: this.capDetailsData.Tool_Name_Description || '',
      // commitQuarter: this.capexForm.value.cashFlowDetails.eventQuarter.commitQuarter || '',
      building: this.capDetailsData.Building || '',
      labFabInstallLocation: this.capDetailsData.LabFab_Install_Location || '',
      projectStartDate: this.capDetailsData.Project_Start_Date_or_Date_WBS_Received_Qtr || '',
      estimatedProjectEnd: this.capDetailsData.Estimated_Project_End_Qtr,
      projectType: this.capDetailsData.Project_Type || '',
      commitCostCenter: this.capDetailsData.Commit_Cost_Center || '',
      finance: this.capDetailsData.Finance || '',
      totalCost: this.capDetailsData.Total_Cost_$K || '',
      isRequestWBSEmailSent: true 
    };
    console.log(notificationBody);
    this.common.postCapexAPI('capex-notify', notificationBody).subscribe({
      next: (notificationData) => {
        this.common.show('success', 'Email notification sent successfully.');
      },
      error: (notificationErr) => {
        console.error("Error sending email notification:", notificationErr);
        this.common.show('error', 'Failed to send email notification.');
      }
    });
  }

  async sendReminderEmailNotification() {
    const estimatedProjectEndQtr = this.capDetailsData.Estimated_Project_End_Qtr ? this.common.getQuarterFromDate(this.capDetailsData.Estimated_Project_End_Qtr) : '';

    // Call the notification API to send email to AMD Admin
    const notificationBody = {
      notificationType: 'POR Generated',
      ltp: this.capDetailsData.LTP || '',
      por: this.capDetailsData.POR || '',
      planner: this.capDetailsData.Planner || '',
      projectManager: this.capDetailsData.Project_Manager_Email || '',
      toolDescription: this.capDetailsData.Tool_Name_Description || '',
      commitQuarter: this.capDetailsData.Commit_Qtr || '',
      commitManager: this.capDetailsData.Commit_Cost_Center_Manager || '',
      estimatedProjectEnd: estimatedProjectEndQtr || '',
      depreciationManager: this.capDetailsData.Depreciation_Cost_Center_Manager || '',
      estimatedDepreciationCost: this.capDetailsData.Estimated_Quarterly_Depreciation || '',
      assets: this.capDetailsData.Number_of_Assets || '',
      assetLife: this.capDetailsData.Asset_Useful_Life_in_yrs || '',
      totalCost: this.capDetailsData.Total_Cost_$K || '',
    };
    console.log(notificationBody);
    this.common.postCapexAPI('capex-notify', notificationBody).subscribe({
      next: (data) => {
        this.common.show('success', 'Email notification sent successfully.');
      },
      error: (err) => {
        console.error("Error sending email notification:", err);
        this.common.show('error', 'Failed to send email notification.');
      }
    });
  }

  formData(data:any){
    this.fieldData = data
    this.porstatus = this.fieldData['POR_Status'];
    if (data['capUpload']) {
      //this.viewAttachedFiles = typeof(data['capUpload']) == 'string' ? JSON.parse(data['capUpload']) : [data['capUpload']]
       const parsedFiles: FileAttachment[] = JSON.parse(data['capUpload']);
        this.viewAttachedFiles = parsedFiles?.reduce<FileAttachment[]>((unique, o) => {
            if (!unique.some(obj => obj['Name'] === o['Name'])) {
                unique.push(o);
        }
        return unique;
      }, []);
      console.log(this.viewAttachedFiles)
    }
    this.detailsForm = this.fb.group({
      Tool_Name: [{ value: this.fieldData['ToolName'], disabled: true }],
      //Tool_Description: [{ value: this.fieldData['Tool_Name_Description'], disabled: true }],
      Tool_Description: [{ value: this.fieldData['Tool_Description'] , disabled: true }],
      Tool_Intent: [{ value: this.fieldData['ToolIntent'], disabled: true }],
      Purpose: [{ value: this.fieldData['Purpose'], disabled: true }],
      Tool_History: [{ value: this.fieldData['ToolHistory'], disabled: true }],
      Tool_Condition: [{ value: this.fieldData['ToolCondition'], disabled: true }],
      Build_Option: [{ value: this.fieldData['BuildOption'], disabled: true }],
      //Initiate_Site: [{ value: this.fieldData['InitiateSite'], disabled: true }],
      Initiate_Site: [{ value: this.fieldData['Site'], disabled: true }],
      //Initiate_Building: [{ value: this.fieldData['InitiateBuilding'], disabled: true }],
      Initiate_Building: [{ value: this.fieldData['Building'], disabled: true }],
      Initiate_LabFab: [{ value: this.fieldData['LabFab_Install_Location'], disabled: true }],
      PORNumber: [{ value: this.fieldData['POR'], disabled: true }],
      POR_Status: [{ value: this.fieldData['POR_Status'], disabled: true }],
      Proj_Manager: [{ value: this.fieldData['Project_Manager_Email'], disabled: true }],
      Capital_Planner: [{ value: this.fieldData['Planner'], disabled: true }],
      Description: [{ value: this.fieldData['Tool_Name_Description'], disabled: true }],
      POR_Driver: [{ value: this.fieldData['Program_Driver'], disabled: true }],
      //POR_Commit_Manager: [{ value: this.fieldData['Commit_Cost_Center_Manager'], disabled: true }],
      //Commit_Location: [{ value: this.fieldData[''], disabled: true }],
      //cost_object: [{ value: this.fieldData['Commit_Cost_Center'], disabled: true }],
      //MRU: [{ value: this.fieldData['Commit_Sub_MRU'], disabled: true }],
      //level2: [{ value: this.fieldData['Commit_Org_L2'], disabled: true }],
      //level3: [{ value: this.fieldData['Commit_Org_L3'], disabled: true }],
      business_unit: [{ value: this.fieldData['Business_Unit'], disabled: true }],
      //organization: [{ value: this.fieldData['Commit_Org_L4'], disabled: true }],
      //section: [{ value: this.fieldData['Commit_Section'], disabled: true }],
      rollup: [{ value: this.fieldData['Rollup'], disabled: true }],
      commit: [{ value: this.fieldData['Adjusted_Commit_Qtr'], disabled: true }],
      RTE: [{ value: this.fieldData['Delivery_RTE_Qtr_Date'], disabled: true }],
      RTO: [{ value: this.fieldData['Final_Payment_Qtr_Date'], disabled: true }],
      setupDueDate: [{ value: this.fieldData['Estimated_Asset_Setup_Date'], disabled: true }],
      actualCommit: [{ value: this.fieldData['Actual_Commit_Date'], disabled: true }],
      //itemCost: [{ value: this.fieldData['Item_Cost_$K'], disabled: true }],
      //hookUpCost: [{ value: this.fieldData['Hookup_Capitalized_Install_Costs_$K'], disabled: true }],
      //NRE_cost: [{ value: this.fieldData['NRE_Total_Cost_$K'], disabled: true }],
      //totalCost: [{ value: this.fieldData['Total_Cost_$K'], disabled: true }],
      //WBS: [{ value: this.fieldData['WBS'], disabled: true }],
      //WBS_status: [{ value: this.fieldData['WBS_Status'], disabled: true }],
      //WBS_amount: [{ value: this.fieldData['WBS_Amount'], disabled: true }],
      capUpload: [{ value: this.fieldData['capUpload'], disabled: true }],
      pageName: [{ value: 'focusArea', disabled: true }],
      Requester_Email: [{ value: this.fieldData['RequesterName'], disabled: true }],
      Secondary_Requester: [{ value: this.fieldData['SecondaryReq'], disabled: true }],
      impact_of_not_doing: [{ value: this.fieldData['Impact_of_Not_Doing'], disabled: true }],
      number_of_assets: [{ value: this.fieldData['Number_of_Assets'], disabled: true }],
      asset_useful_life: [{ value: this.fieldData['Asset_Useful_Life_in_yrs'], disabled: true }],
      commit_cost_center: [{ value: this.fieldData['Commit_Cost_Center'], disabled: true }],
      commit_cost_center_manager: [{ value: this.fieldData['Commit_Cost_Center_Manager'], disabled: true }],
      commit_organization_L3: [{ value: this.fieldData['Commit_Org_L3'], disabled: true }],
      depreciation_cost_center: [{ value: this.fieldData['Depreciation_Cost_Center'], disabled: true }],
      depreciation_cost_center_manager: [{ value: this.fieldData['Depreciation_Cost_Center_Manager'], disabled: true }],
      depreciation_organization_L3: [{ value: this.fieldData['Depreciation_Org_L3'], disabled: true }],
      program_driver: [{ value: this.fieldData['Program_Driver'], disabled: true }],
      category: [{ value: this.fieldData['Category'], disabled: true }],
      company_code: [{ value: this.fieldData['Company_Code'], disabled: true }],
      region: [{ value: this.fieldData['Region'], disabled: true }],
      commit_quarter: [{ value: this.fieldData['Commit_Qtr'], disabled: true }],
      factory_acceptance_quarter: [{ value: this.fieldData['Acceptance_RTO_Qtr'], disabled: true }],
      site_acceptance_quarter: [{ value: this.fieldData['Delivery_RTE_Qtr'], disabled: true }],
      final_acceptance_quarter: [{ value: this.fieldData['Final_Payment_Qtr'], disabled: true }],
      estimated_project_end_date: [{ value: this.fieldData['Estimated_Asset_Setup'], disabled: true }],
    });    
  }

  async checkSHReview(): Promise<void>{
    let SHNameData:any = [];
    let SHNameArray:any = [];
    let combinedSHData:any = [];
    this.tableloading = true
    //this.allStakeholders = []
    this.hideOriginalFAStatus = false
      this.labFabDetails.value['LabName'] = this.capDetailsData['LabFab_Install_Location']
      this.labFabDetails.value['Building'] = this.capDetailsData['Building']
      this.labFabDetails.value['FocusArea'] = this.focusAreaName;
      let labfabname = this.capDetailsData['LabFab_Install_Location']
      let building = this.capDetailsData['Building']
      console.log("Check labfabName:", labfabname)
      console.log("CapDetailsData", this.capDetailsData)

    this.common.loader.next(true);
    this.common.getById('amd-get-labfabbyid/', labfabname?.replace('/', '&#47;')).subscribe({
                  next: (data) => {
              console.log("LABFAB data", data);
              if (data) {
                this.tableloading = false;
                  this.LabfabTeams = data[0];
                  // ENDABLE the Focus AREA
                  this.FocusAreaVisibility();
                  if (this.LabfabTeams['BUILDING'] == building) {
                      let arr = ['Primary', 'Secondary'];
                      for (const focusArea of this.FA_Available) {
                          this.focusAreaName = focusArea;
                          console.log("After API", this.focusAreaName);

                          // this.LabFabRenaming.filter(obj=> {
                          //   if(obj['labfabFA'] ==  this.focusAreaName){
                          //     this.focusAreaName = obj['dbName']
                          //   }
                          // })
                          let SHNameData = this.LabfabTeams[this.focusAreaName.replace(/ /g, '_')];

                          // Save stakeholderName in localstorage
                          localStorage.setItem('StakeHolderName', SHNameData);

                          const SHNameArray = arr.map((item) => SHNameData[item]?.split(';')).filter(Boolean);
                          console.log("SHNameArray------------------", SHNameArray);
                          this.combinedArray = [].concat(...SHNameArray);
                          combinedSHData = combinedSHData.concat(...SHNameArray);

                          console.log("combinedSHData------------------", combinedSHData);
                          console.log("combinedArray------------------", this.combinedArray);
                          //below variable will hide original status as logged in user doesn't match with SH
                          this.hideOriginalFAStatus = this.combinedArray.every(
                              (ele1) => ele1.trim() !== this.loggedInUser);
                          console.log("Hide Original Status-------------------------", this.hideOriginalFAStatus);
                          console.log("SHArray-------------------------", this.SHArray);
                          const foundItem = this.SHArray.find((val) => val['key'] === this.focusAreaName);
                          // if (foundItem && foundItem['value'] != 'Pending') {temporary
                          if (foundItem && (!(foundItem['value'] == 'Pending' || foundItem['value'] == 'Waive'))) {
                              if (this.hideOriginalFAStatus) {
                                  foundItem['showSHActionPlan'] = false;
                                  // Add a temporary property to foundItem
                                  foundItem['tempValue'] = "Available";
                                  console.log("Print status foundItem", foundItem);
                                  // this.FAArray = [].push(foundItem)
                                  // this.editMode = false ;
                              } else {
                                  const originalStatusVal = this.FAStatus.find((ele: { [x: string]: any; }) => ele['Focus_Area'] === this.focusAreaName);
                                  foundItem['value'] = originalStatusVal?.['Status'] || '';
                                  foundItem['showSHActionPlan'] = true;
                                  console.log("Print status foundItem", foundItem);
                                  //this.editMode = true ;           
                              }
                          }
                          else {
                            if(foundItem){
                                if (!this.hideOriginalFAStatus && foundItem['value'] == 'Waive') {
                                    foundItem['value'] = 'Waive';
                                }
                                else {
                                    foundItem['value'] = 'Waived';
                                }
                            }
                              
                          }
                      }
                  }
                  // // ENDABLE the Focus AREA
                  // this.FocusAreaVisibility()
                  this.allStakeholders = new Set(combinedSHData);

                  //If editMode is true, loggedinuser is Stakeholder & can access any Stakeholder form
                  this.editMode = Array.from(this.allStakeholders).some((ele) => {
                      return ele.trim() == this.loggedInUser;
                  });
                  this.common.loader.next(false)
              }
              else {
                this.tableloading = false;
                this.common.loader.next(false)
              }
          },
          error: (error) => {
              console.error("Error occurred:", error);
              alert("Please connect with Asset Admin");
          }
      });
    console.log('data to sets', this.labFabDetails.value)
  }
 
  
  tabChange1(tabName: { tab: { textLabel: any; }; }){
    console.log("Print-------------", tabName)
    this.tabSelected1 = tabName.tab.textLabel;
    this.common.changeTab1(this.tabSelected1);
  }

  tabChange2(tabName: { tab: { textLabel: any; }; }){
    console.log("Print-------------", tabName)
    this.tabSelected2 = tabName.tab.textLabel;
    this.common.changeTab2(this.tabSelected2);
  }

  statusClickReview(focusAreaName: any, statusValue: any){
    let checkClickedAction = statusValue;
    let originalSHName = focusAreaName['key']
    let currentFAStatus = focusAreaName['value']
    let url ;
    localStorage.setItem('PorStatus', this.capDetailsData['PORStatus'])
    localStorage.setItem('focusAreaName', focusAreaName)
    
    if(checkClickedAction == 'Review' ){
      url = 'cap/stakeHolderReview'+'?'+'POR='+this.capDetailsData['POR']+'&'+'FA='+originalSHName;
    }
    if(checkClickedAction == 'Requester ActionPlan'){
      let action = (this.showRequester == true) ? 'edit' : 'view';
      url = 'cap/capActionPlan'+'?'+'POR='+this.capDetailsData['POR']+'&'+'FA='+originalSHName+'&'+'mode='+action+'&'+'actionPlan='+'Requester';
    }

    if(checkClickedAction == 'Stakeholder ActionPlan'){
      let SHAction = (this.editMode == true ) ? 'edit' : 'view';
      url = 'cap/capActionPlan'+'?'+'POR='+this.capDetailsData['POR']+'&'+'FA='+originalSHName+'&'+'mode='+SHAction+'&'+'actionPlan='+'Stakeholder';
    }
    window.open(url, "_blank");
  }

  actionPlanClick(statusValue: string){
    let checkClickedAction = statusValue;
    let url ;
    localStorage.setItem('PorStatus', this.capDetailsData['PORStatus'])

    if(checkClickedAction == 'Requester ActionPlan'){
      let action = (this.showRequester == true) ? 'edit' : 'view';
      url = 'cap/capActionPlan'+'?'+'POR='+this.capDetailsData['POR']+'&'+'mode='+action+'&'+'actionPlan='+'Requester';
    }

    if(checkClickedAction == 'Stakeholder ActionPlan'){
      let SHAction = (this.editMode == true ) ? 'edit' : 'view';
      url = 'cap/capActionPlan'+'?'+'POR='+this.capDetailsData['POR']+'&'+'mode='+SHAction+'&'+'actionPlan='+'Stakeholder';
    }
    window.open(url, "_blank");
  }

  async fileReader() {
    return await new Promise(() => {
      this.common.getLabFabMatrix().subscribe({
        next: (data) => {
          let list = data.split('\r\n');
          this.matrixHeaders = list[0].split(',');
          list.forEach(e => {
            this.matrixData.push(e.split(','));
          });
          for (let i = 1; i < this.matrixData.length; i++) {
            if (this.matrixData[i][1] == this.capDetailsData['InitiateBuilding'] &&
              this.matrixData[i][3] == this.capDetailsData['LabFab_Install_Location']) {
              this.labFabMatrixData = this.matrixData[i];
            }
          }
          console.log('Headers', this.matrixHeaders)
          console.log("Print matrix", this.matrixData)
        },
        error: (error) => {
          console.error("Error occurred:", error);
          alert("Please connect with Asset Admin");
        }
      });
    })
  }

  emitUpdatedTableData(updatedTableData: { [x: string]: any; }){
    this.common.bannerData.next({ title: 'StakeHolder Details'});
    this.userResponse = updatedTableData['userResponse']
    this.commonInfo = updatedTableData['commonInfo']
    this.capDetailsData = updatedTableData['commonInfo']
    console.log("Print table Data updated", updatedTableData);
  }

  async focusArea(stakeHolderName: string){
    if(stakeHolderName == 'Automated Equipment Control (AEC)'){
       stakeHolderName = 'Automated Equipment Control'
    }
    this.stakeHolderFunc(stakeHolderName);
  }

  stakeHolderFunc(SHName: string){
    console.log('TestCAP6');
    let originalSHName = SHName
    if(SHName == 'Automated Equipment Control'){
      SHName = 'AEC'
    }
    if(SHName == 'Asset Administration'){
      SHName = 'AA'
    }
    if(SHName == 'EHS Equipment Design'){
      SHName = 'EHS'
    }
    if(SHName == 'FAB Environmental'){
      SHName = 'fabEnvironmental'
    }
    if(SHName == 'Information Technology'){
      SHName = 'IT'
    }
    if(SHName == 'Mechanical and HVAC'){
      SHName = 'Mechanical'
    }
    if(SHName == 'Micro-contamination and ESD'){
      SHName = 'microContamination'
    }
    if(SHName == 'Process Systems'){
      SHName = 'Process Systems'
    }
    if(SHName == 'instramental'){
      SHName = 'SCADA Instramental Control'
    }
    if(SHName == 'Site EHS'){
      SHName = 'siteEHS'
    }
    if(SHName == 'Space Layout (Non-office)'){
      SHName = 'spaceLayout'
    }
    localStorage.setItem('POR', this.activatedRoute.snapshot.queryParams['POR'])
    localStorage.setItem('focusAreaName', SHName)
    let url = 'cap/assessmentForm'+'?'+'POR='+this.capDetailsData['POR']+'&'+'FA='+originalSHName;
    window.open(url, "_blank");
  }

  // add the child files 
  addChildForm(FileData: string, group: FormGroup) {
    console.log("group", group)
    this.detailsForm.addControl(FileData, group);
    const groupWithName = group as { Name?: string }; 

    if (groupWithName.Name && groupWithName.Name !== "") {
      this.viewAttachedFiles.push(group)
      console.log("array check", this.viewAttachedFiles, Array.isArray(this.viewAttachedFiles))
      this.viewAttachedFiles = this.viewAttachedFiles?.reduce((unique: any[], o: { Name: any; }) => {
        if (!unique.some(obj => obj?.Name === o?.Name)) {
          unique.push(o);
        }
        return unique;
      }, []);
      console.log("this.viewAttachedFiles", Array.isArray(this.viewAttachedFiles), this.viewAttachedFiles)
      this.detailsForm.controls['capUpload'].setValue(this.viewAttachedFiles)

      if (this.detailsForm.controls['capUpload'].value && Array.isArray(this.detailsForm.controls['capUpload'].value)) {
        this.detailsForm.value['capUpload'] = JSON.stringify(this.detailsForm.controls['capUpload'].value)
      }
      this.common.postCapAPI('itg/cap-add-initiate-data-itg',this.detailsForm.value).subscribe({
        next: (data) => {     
        this.capDetailsData= data;
        console.log("inside addInitiateData", this.capDetailsData)
      },
       error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
      });
    }
   
    //when file uploaded successfully in S3
    // if (FileData == 'capUpload' && group['IsFileUploaded'] == true) {
    //   // alert('test upload')
    // }
  }

  goBack(){
    console.log('Back');
    this.common.changeMessage(this.capDetailsData);
    this.router.navigate(['cap/capDetail'], {queryParams: { POR: this.capDetailsData['POR']}});
  }

//To show or hide the specific / ALL focus AREA based on labfab primary or secondary
  FocusAreaVisibility() {
    if (this.SHArray) {
      console.log(this.LabfabTeams)
      this.SHArray.forEach((val, index) => {
        if (this.LabfabTeams) {
          for (let key in this.LabfabTeams) {
            let FA = key?.split('_').join(' ');
            if (FA.includes(val['key'])) {
              if ((this.LabfabTeams[key]?.Primary && this.LabfabTeams[key]?.Primary!='Null') || (this.LabfabTeams[key]?.Secondary && this.LabfabTeams[key]?.Secondary!='Null')) {
                  if(val['key']){
                    this.SHArray[index]['showFocusArea'] = true
                    this.SHArray_Count+=1
                    }
              } 
              else {
                console.log("NOOO FA", FA)
              }
            }
          }
        }
      });
      
      let ProcurementEnabled = this.check_FocusAreaStatus()
      if(ProcurementEnabled == 'showPending'){
        this.SHArray.filter(ele=>{
         ele.value = ele.key == "Procurement" ? 'Pending' : ele.value
         if(ele.value == "Pending"){
          ele.actionPlan = ''
         }
        })
      }
      else if(ProcurementEnabled == 'showOriginalVal'){
       return
      }
      else{
        this.SHArray.filter(ele=>{
          return
          // ele.value = ele.key == "Procurement" ? (ele.value != 'SH Review - Complete' ? 'Pending' : ele.value) : ele.value
       })
      }

     
    }
    console.log("this.SHArray_Count",this.SHArray_Count)

  }
  
  check_FocusAreaStatus(){
    const existingFA = new Set(this.FAStatus.map((ele: { Focus_Area: any; }) => ele.Focus_Area));
    for (const item of this.SHArray) {
      // Check if the value property is true and the key is not already in dataarray
      if (item.showFocusArea === true && !existingFA.has(item.key)) {
        // Create a new object and add it to dataarray with the key from abc
        this.FAStatus.push({ Focus_Area: item.key, Status: item.value, actionPlan_Status: item.actionPlan });
        existingFA.add(item.key); // Update the set of existing keys
      }
    }
    
    let completeReview = 0
    let checkProcurement = this.FAStatus.some((obj: { Focus_Area: string; }) => obj.Focus_Area == 'Procurement');
    if (checkProcurement) {
      for (const item of this.FAStatus) {
        if (item.Status == 'SH Review - Complete') {
          completeReview++
        }
        else if((item.Focus_Area == "Procurement" && (item.Status.startsWith('SH Review'))) && completeReview == (this.FAStatus.length - 1)){
          return 'showPending'
        }
        else{
          return
        }
      }
      
    }
    else{
      return 'showOriginalVal'
    }
    return ; 
  }
}

type FileAttachment = {
    [key: string]: any; // Allow any properties
};



