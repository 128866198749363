import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CapComponent } from './cap.component';
import { CapDetailComponent } from './cap-detail/cap-detail.component';
import { CommonCapInfoComponent } from './common-cap-info/common-cap-info.component';
import { InitiateCapRequestComponent } from './initiate-cap-request/initiate-cap-request.component';
import { ToolDetailsComponent } from './tool-details/tool-details.component';
import { AssessmentAddLabelsComponent } from './assessment-add-labels/assessment-add-labels.component';
import { AssessmentFormComponent } from './assessment-form-functionality/assessment-form-functionality';
import { StakeholderAssessmentComponent } from './stakeholder-assessment/stakeholder-assessment.component';
import { StakeHolderReviewComponent } from './stake-holder-review/stake-holder-review.component';
import { CapActionPlanComponent } from './cap-action-plan/cap-action-plan.component';

const routes: Routes = [
  {
    path: '', component: CapComponent,
    children: [
      {path:'capDetail',component:CapDetailComponent},
      {path:'capInitiateRequest', component: InitiateCapRequestComponent},
     {path:'focusArea', component: StakeholderAssessmentComponent},
     {path:'assessmentForm', component: AssessmentFormComponent},
      {path:'capInfo', component: CommonCapInfoComponent},
     {path:'stakeHolderReview', component: StakeHolderReviewComponent},
     {path:'capActionPlan', component: CapActionPlanComponent},

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CapRoutingModule { }
