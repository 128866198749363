import { Component, OnInit } from '@angular/core';
import * as fs from 'file-saver';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import * as Excel from 'exceljs';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';

@Component({
  selector: 'app-export-history',
  templateUrl: './export-history.component.html',
  styleUrls: ['./export-history.component.scss']
})
export class ExportHistoryComponent implements OnInit {
  chunkSaved: boolean = false;
  chunkCount = 0;
  chunksCompleted = 0;
  locloading = true;
  progress: any ;
  loading = false;
  allRecords: any = [];
  constructor(private common: CommonService, private dialog: MatDialog) { }

  ngOnInit() {
    if (sessionStorage.getItem('flag') == 'true') {
      this.locloading = false
    }
    sessionStorage.removeItem('flag')
  }

  exportHistoryReport() {
    console.log("history clicked")
    this.loading = true
    this.callAPI(0,1000);
  }



  callAPI(start:any, end:any) {
    let tempData: any = [];
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      this.common.postAPI('amd-history/', {"start": start,"end":end, assetID: 'ALL'}).subscribe({
        next: (data: any) => {
          tempData = data['body'];
        console.log(data['statusCode']);
        // console.log(tempData.length)
        this.chunkSaved = false;
        if (data['statusCode'] == 200) {
          if (tempData.length != 0) {
            this.allRecords = this.allRecords.concat(data['body']);
            this.callAPI(end, end + 1000); // change to 1000
            this.chunkCount = data['assetCount'];
            this.chunksCompleted = this.chunksCompleted + data['assetImported'];
            console.log(this.chunkCount);
            console.log(this.chunksCompleted);
            this.progress = Math.round((100 * this.chunksCompleted) / this.chunkCount);
          }
          else {
            this.createHistoryReport(this.allRecords);
            this.chunkCount = 0;
            this.chunksCompleted = 0;
            this.progress = 0;
            this.loading=false;
          }
        }
        else {
          this.loading=false;
          this.openDilaogeMessage('Failed to download file', '')
         
        }
        },
      });  
    }
  }

  createHistoryReport(data: any) {

    console.log(this.allRecords);
    let columnOrder = [
      "Original Asset Number",
      "Asset Number",
      "Acquisition Value",
      "Asset Active Listing",
      "Asset Capitalization Date",
      "Asset Class Code",
      "Asset Inventory Number",
      "Asset Last Inventory Date",
      "Asset Manufacturer of Asset Description",
      "Asset Scrap Value Currency Code",
      "Asset Super Number",
      "Asset Type Name",
      "Asset Value Date of the First Posting (Supplementary_Text)",
      "Change Status",
      "Change Type",
      "Company Code",
      "Cost Center Code",
      "Cost Center Hier Desc Level1",
      "Depreciation Method Code",
      "Depreciation Terms Start Date",
      "Depreciation Terms Useful Life in Months",
      "Depreciation Terms Useful Life in Years",
      "MRU Code",
      "Asset Description",
      "New Asset Description",
      "Building",
      "New Building",
      "LabFab Name",
      "New LabFab",
      "Cost Object Manager",
      "Owner",
      "New Owner",
      "Serial Number",
      "New Serial Number",
      "PE Depreciation For Posted Period",
      "PE Net Book Value",
      "Ship-to Customer Name1",
      "WBS Element Maintenance Project",
      "Period Posted total Depreciation Including Value Adjustments",
      "Personnel Number",
      "Profit Center Code",
      "Purchase Order Currency Key",
      "Real Depreciation Area",
      "Agreement Number",
      "Requestor ID",
      "Action Plan Email Approvers List",
      "Action Plan Status",
      "PickUp Cost to move asset and demo utilities",
     "Demo utilities of asset Status",
      "Estimated Cost",
      "planned Completion Date Action Plan",
      "receiving area Prepped Status",
      "Is Cost Associated To Asset",
      "Is Utilities Need Demo in Asset Moved From",
      "Is receiving area Prepped",
      "Notifications to listed tool owner Action Plan",
      "Asset Moved Status in Action plan",
      "INSERT DATETIME",
      "MODIFIED DATETIME",
      "Last Modified User"
    ]
    let workbook = new Excel.Workbook();

    let worksheet = workbook.addWorksheet('Asset Basic Changes History');

    let DateObj = new Date();
    let date = ('0' + (DateObj.getUTCMonth() + 1)).slice(-2) + ('0' + DateObj.getUTCDate()).slice(-2) + DateObj.getUTCFullYear();
    let docname = "Asset Basic Changes History " + date;

    let titleValues1 = [""];
    worksheet.addRow(titleValues1)

    let headerRow = worksheet.addRow(columnOrder);
    let data1 = [];
    for (let d1 of data) {

      data1.push(d1);
    }

    for (let x1 of data1) {
      let x2 = columnOrder;
      let temp = []
      for (let y of x2) {
        if (x1[y] == 'null' || x1[y] == 'NULL' || x1[y] == 'Null') {
          x1[y] = ''
        }
        temp.push(x1[y])

      }
      worksheet.addRow(temp)
    }

    worksheet.getRow(2).height = 40;
    worksheet.getRow(2).font = {
      name: 'HP Simplified',
      size: 9,
      bold: true,
      color: { argb: 'ffffff' }

    };
    worksheet.getRow(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0096d6' }
    };

    //action plan values
    ['AT2', 'AU2', 'AV2', 'AW2', 'AX2', 'AY2', 'AZ2', 'BA2', 'BB2', 'BC2', 'BD2', 'BE2', 'BF2', 'BG2', 'BH2', 'BI2', 'BJ2'].map(key => {
      worksheet.getCell(key).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00B050' }
      };
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, docname + '.xlsx');
    });
  }

  openDilaogeMessage(message: any, assetID:any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: message,
      tabletemplate: false,
      title: "Asset Basic Change History"
    }

    this.dialog.open(DialogBoxComponent, dialogConfig);
  }
}
