<br>
<!-- <button mat-raised-button color="primary" type="submit" class="w-100">Save</button> -->
<!-- <button mat-raised-button color="primary" (click)="back()">Back</button>&nbsp;&nbsp;
<button mat-raised-button color="primary" (click)="downloadImage()">Download</button><br><br> -->

<div class="row mt-4">
     <div class="col-2">
         <div class="form-group">
             <input type="button" class="btn btn-primary form-control" value="Back" (click)="back()" />
         </div>
     </div>

     <div class="col-2">
         <div class="form-group">
             <input type="button" class="btn btn-primary form-control" value="Download" (click)="downloadImage()" />
         </div>
     </div>
 </div>
<div id="{{asset['Asset_Number']}}" class="printasset" *ngFor="let asset of saveAsset; let i = index">
     <div class="printText">- HP ASSET -</div>
     <div class="printText"><u>{{asset['Asset_Description']}}</u></div>
     <div class="printText">SN: {{asset['Serial_Number']}}</div>
     <div class="printText">LOC: {{asset['Asset_Location']}}</div>
     <div class="printText">LAB: {{asset['LabFab_Name']}}</div>
     <div class="printText">OLD: {{asset['Original_Asset_Number']}}</div>
     <ngx-barcode6 [bc-value]="asset['Asset_Number']" [bc-display-value]="true" [bc-font-size]="40" [bc-width]="3">
     </ngx-barcode6><br>
</div><br>
