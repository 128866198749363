<form [formGroup]="assetdetails1">
    <div class="row">
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-label>Asset Number</mat-label>
                <input matInput placeholder="Asset Number" formControlName="Main_Asset_Number">
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-label>Status</mat-label>
                <input matInput placeholder="Status" formControlName="Change_Status">
            </mat-form-field>
        </div>
    </div>

    <!-- <hr> -->
    <!-- Basic Details -->
    <mat-card>
        <mat-toolbar color="primary" class="mb-3">
            <span>Basic Details</span>
        </mat-toolbar>

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Asset Description</mat-label>
                        <input matInput type="text" formControlName="Asset_Description" placeholder=""
                            (keyup)="isBasicChange()"
                            [ngClass]="{ 'is-invalid': submitted && assetdetails1.controls['Asset_Description'].errors }">
                        <mat-hint *ngIf="submitted && assetdetails1.controls['Asset_Description'].errors">
                            <span *ngIf="assetdetails1.controls['Asset_Description'].errors['required']"
                                class="text-danger">
                                Description is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Owner</mat-label>
                        <input matInput type="text" placeholder="'example@hp.com' or First Last Name(Dale L Marr)"
                            formControlName="Additional_Description"
                            [attr.disabled]="(nonStandardLabfad && nonStandardLabfad!= null) == true ? true : null"
                            [attr.readonly]="(nonStandardLabfad && nonStandardLabfad!= null) == true ? true : null"
                            [ngClass]="{ 'is-invalid':assetdetails1.controls['Additional_Description'].errors }">
                        <mat-hint *ngIf="assetdetails1.controls['Additional_Description'].errors">
                            <span class="text-danger"
                                *ngIf="assetdetails1.controls['Additional_Description'].errors['required']">
                                Owner is required
                            </span>
                            <span class="text-danger"
                                *ngIf="assetdetails1.controls['Additional_Description'].errors['pattern']">
                                Should Be 'example@hp.com'
                            </span>
                        </mat-hint>

                        <div matSuffix>
                            <button type="button" class="btn btn-primary btn-sm" matTooltip="verify Changes"
                                (click)="validUserLdap(assetdetails1.controls['Additional_Description'].value)">
                                {{userVerify ? 'Verified' : 'Verify'}}
                                <i class="fa fa-spinner fa-spin" *ngIf="userVerifyLoading"></i>
                                <i *ngIf="userVerify" class="fa fa-check"></i>
                                <i *ngIf="userInvalid" class="fa fa-close"></i>
                            </button>
                        </div>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Serial Number</mat-label>
                        <input matInput type="text" formControlName="Serial_Number" (keyup)="isBasicChange()">
                        <mat-hint *ngIf="submitted && assetdetails1.controls['Serial_Number'].errors">
                            <span *ngIf="assetdetails1.controls['Serial_Number'].errors['required']"
                                class="text-danger">
                                Serial Number is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>LabFab Name</mat-label>
                        <input #inputAutoComplete
                            [ngClass]="{ 'is-invalid': submitted && assetdetails1.controls['Asset_Supplementary_Inventory_Specifications_Name'].errors }"
                            formControlName="Asset_Supplementary_Inventory_Specifications_Name" [matAutocomplete]="auto"
                            #trigger="matAutocompleteTrigger" matInput type="text" value={{inputAutoComplete.value}}
                            required (change)="labfabChange(assetdetails1,$event,'input')" />

                        <div matSuffix>
                            <button (click)="clearInput($event)"
                                *ngIf="inputAutoComplete.value!==null && inputAutoComplete?.value!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon>clear</mat-icon>
                            </button>

                            <button (click)="openOrClosePanel($event, trigger)" aria-label="Clear" mat-icon-button
                                type="button">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>

                        <mat-autocomplete #auto="matAutocomplete" (closed)="arrowIconSubject.next('arrow_drop_down')"
                            (opened)="arrowIconSubject.next('arrow_drop_up')"
                            (optionSelected)="arrowIconSubject.next('arrow_drop_down');inputAutoComplete.value !=undefined && labfabChange(assetdetails1,$event,'inputAutoComplete')">
                            <mat-option class="multiline-mat-option" *ngFor="let option of filteredOptions | async"
                                [value]="option.Lab_name">
                                {{option.Lab_name}}
                                <div class="small"> {{option.Owner}} | {{option.Asset_Location}} </div>
                            </mat-option>
                        </mat-autocomplete>

                        <mat-hint>
                            <span *ngIf="!nonStandardLabfad && nonStandardLabfad!=null" class="text-danger">
                                * Non Standard LabFab
                            </span>
                            <span *ngIf="inputAutoComplete.value==''" class="text-danger">
                                LabFab Name is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Building</mat-label>
                        <mat-select name="Asset_Location" id="Asset_Location" formControlName="Asset_Location" [disabled]="true">
                            <mat-option *ngFor="let option of buildins" value={{option}}>{{option}}</mat-option>
                        </mat-select>
                        <mat-hint *ngIf="assetdetails1.controls['Asset_Location'].errors">
                            <span *ngIf="assetdetails1.controls['Asset_Location'].errors['required']"
                                class="text-danger">
                                Buliding Name is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Request Status</mat-label>
                        <mat-select name="basic_change_Status" id="basic_change_Status"
                            formControlName="basic_change_Status" [attr.disabled]="userRole!='Admin'?true:null"
                            [attr.readonly]="userRole!='Admin'?true:null" #requestStatus
                            (selectionChange)="request_Status_change()">
                            <mat-option value='Draft'>Draft</mat-option>
                            <mat-option value='Pending Analyst Review'>Pending Analyst Review</mat-option>
                            <mat-option value='Back to Draft'>Back to Draft</mat-option>
                            <mat-option value='Approved'>Approved</mat-option>
                            <mat-option value='Cancel'>Cancel</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

    </mat-card>

    <div class="jumbotron p-3 my-3">
        Below, you need to enter the New Cost Center Code. You must identify if the cost center code is
        changing this will determine if the change is an internal move (same cost center code) or a
        transfer (different cost center code). Work with the Owner to determine the Cost Center Code. Enter
        this code into the New Cost Center Code field. If the Cost Center Code did not change, then enter
        the original Cost Center Code as the New Cost Center Code.
    </div>

    <mat-card class="mb-3 pt-3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Cost Center Code</mat-label>
                        <input matInput type="text" formControlName="Cost_Center_Code" placeholder="" readonly>
                        <mat-hint *ngIf="assetdetails1.controls['Cost_Center_Code'].errors">
                            <span *ngIf="assetdetails1.controls['Cost_Center_Code'].errors['required']">
                                Cost Center Code is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-4" *ngIf="costCenter_filteredOptions">
                    <mat-form-field class="w-100">
                        <mat-label>New Cost Center Code</mat-label>
                        <input matInput #inputAutoCompleteCCCode
                            [ngClass]="{ 'is-invalid': submitted && assetdetails1.controls['New_Cost_Center_Code'].errors }"
                            formControlName="New_Cost_Center_Code" [matAutocomplete]="auto1"
                            #trigger1="matAutocompleteTrigger" matInput type="text"
                            value={{inputAutoCompleteCCCode.value}} required
                            (change)="costCenterCodeChange(assetdetails1,$event,'input')" />

                        <div matSuffix>
                            <button (click)="clearInputCCCode($event)"
                                *ngIf="inputAutoCompleteCCCode.value!==null && inputAutoCompleteCCCode?.value!==''"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon>clear</mat-icon>
                            </button>
                            <button (click)="openOrClosePanel_cOstcenter($event, trigger1)" aria-label="Clear"
                                mat-icon-button type="button">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                        <mat-autocomplete #auto1="matAutocomplete" (closed)="arrowIconSubject.next('arrow_drop_down')"
                            (opened)="arrowIconSubject.next('arrow_drop_up')"
                            (optionSelected)="arrowIconSubject.next('arrow_drop_down');inputAutoCompleteCCCode.value !=undefined && costCenterCodeChange(assetdetails1,$event,'inputAutoCompleteCCCode')">

                            <mat-option class="multiline-mat-option"
                                *ngFor="let option of costCenter_filteredOptions | async" [value]="option['LAB_ID']">
                                {{option['LAB_ID']}}
                                <div class="small">{{option['Cost Center Owner']}} | {{option['Cost Center
                                    Description']}} | {{option['Region']}}</div>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-hint *ngIf="inputAutoCompleteCCCode.value =='' " class="invalidError">
                            <span>New Cost Center Code is required</span>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Cost Center Manager</mat-label>
                        <input matInput type="text" readonly formControlName="Old_Cost_Center_Manager" readonly
                            [ngClass]="{ 'is-invalid': assetdetails1.controls['Old_Cost_Center_Manager'].errors }">
                        <mat-hint *ngIf="assetdetails1.controls['Old_Cost_Center_Manager'].errors"
                            class="invalid-feedback">
                            <span *ngIf="assetdetails1.controls['Old_Cost_Center_Manager'].errors['required']">
                                Cost Center Manager is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>New Cost Center Manager
                        </mat-label>
                        <input matInput type="text" formControlName="New_Cost_Manager" readonly
                            (focusout)="ChangeTransfer()"
                            [ngClass]="{ 'is-invalid': assetdetails1.controls['New_Cost_Manager'].errors }">
                        <mat-hint *ngIf="assetdetails1.controls['New_Cost_Manager'].errors" class="invalid-feedback">
                            <span *ngIf="assetdetails1.controls['New_Cost_Manager'].errors['required']">
                                * New Cost Center Manager is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Company Code </mat-label>
                        <input matInput type="text" formControlName="Old_Company_Code" readonly
                            [ngClass]="{ 'is-invalid': assetdetails1.controls['Old_Company_Code'].errors }">
                        <mat-hint *ngIf="assetdetails1.controls['Old_Company_Code'].errors" class="invalid-feedback">
                            <span *ngIf="assetdetails1.controls['Old_Company_Code'].errors['required']">
                                * Company Code is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>New Company Code </mat-label>
                        <input matInput type="text" formControlName="New_Company_Code" readonly
                            [ngClass]="{ 'is-invalid': assetdetails1.controls['New_Company_Code'].errors }">
                        <mat-hint *ngIf="assetdetails1.controls['New_Company_Code'].errors" class="invalid-feedback">
                            <span *ngIf="assetdetails1.controls['New_Company_Code'].errors['required']">
                                * New Company Code is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Region</mat-label>
                        <mat-select name="Old_Region" id="region_old" readonly
                            [ngClass]="{ 'is-invalid': assetdetails1.controls['Old_Region'].errors }"
                            formControlName="Old_Region" (focusout)="ChangeTransfer()">
                            <mat-option value=''> Select region</mat-option>
                            <mat-option value="AMS">AMS</mat-option>
                            <mat-option value="APJ">APJ</mat-option>
                            <mat-option value="EMEA">EMEA</mat-option>
                        </mat-select>
                        <mat-hint *ngIf="assetdetails1.controls['Old_Region'].errors" class="invalid-feedback">
                            <span *ngIf="assetdetails1.controls['Old_Region'].errors['required']">
                                * Region is required
                            </span>
                        </mat-hint>

                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>New Region</mat-label>
                        <mat-select name="Region" id="region_new" readonly
                            [ngClass]="{ 'is-invalid': assetdetails1.controls['Region'].errors }"
                            formControlName="New_Region" (focusout)="ChangeTransfer()">
                            <mat-option value=''> Select region</mat-option>
                            <mat-option value="AMS">AMS</mat-option>
                            <mat-option value="APJ">APJ</mat-option>
                            <mat-option value="EMEA">EMEA</mat-option>
                        </mat-select>
                        <mat-hint *ngIf="assetdetails1.controls['Region'].errors" class="invalid-feedback">
                            <span *ngIf="assetdetails1.controls['Region'].errors['required']">
                                * Region is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Text </mat-label>
                        <input matInput type="text" formControlName="Text" readonly>
                        <mat-hint class="invalid-feedback">
                            <span>Text is required</span>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Change Type </mat-label>
                        <mat-select name="Transfer_Type" id="Transfer_Type" formControlName="Transfer_Type"
                            (change)="ChangeTransfer()">
                            <mat-option value=""></mat-option>
                            <mat-option value="275 Retirement by Transfer Out">275 Retirement by Transfer
                                Out</mat-option>
                            <mat-option value="Basic Change">Basic Change</mat-option>
                            <mat-option value="Transfer">Transfer</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Requester Comment </mat-label>
                        <textarea rows="4" style="resize:none" matInput formControlName="requester_Comment"></textarea>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Administator Comment </mat-label>
                        <textarea rows="4" style="resize:none" matInput [attr.disabled]="userRole!='Admin'?true:null"
                            [attr.readonly]="userRole!='Admin'?true:null" formControlName="Admin_Comment"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-card>

    <mat-card class="mb-3 pt-3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Insert Date/Time </mat-label>
                        <input matInput type="text" formControlName="INSERT_DATETIME" placeholder="" readonly>
                        <div class="invalid-feedback">
                            <div>Insert Date/Time is required</div>
                        </div>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Last Modified Date/Time </mat-label>
                        <input matInput type="text" formControlName="MODIFIED_DATETIME" readonly>
                        <div class="invalid-feedback">
                            <div>Last Modified Date/Time is required</div>
                        </div>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Last Modified User Name </mat-label>
                        <input matInput type="text" formControlName="Last_Modified_User" readonly>
                        <div class="invalid-feedback">
                            <div>last Modified User Name is required</div>
                        </div>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-card>

    <div class="col-md-12">
        <!-- ADMIN  Section of reason comments for Back to Draft Starts -->
        <ng-container *ngIf="assetdetails1.controls['basic_change_Status'].value=='Back to Draft'">
            <ng-container *ngIf="userRole=='Admin'">
                <mat-label>
                    <p>Select the reason for request to move back to draft</p>
                </mat-label>
                <mat-radio-group formControlName="reason_Back_To_Draft_Status" name="backToDraft">
                    <mat-radio-button *ngFor="let reason of reason_Back_to_Draft let i=index;" [value]="reason">
                        {{reason}}
                        <mat-hint
                            *ngIf="i==2 && submitted && assetdetails1.controls['reason_Back_To_Draft_Status'].errors">
                            <span *ngIf="assetdetails1.controls['reason_Back_To_Draft_Status'].errors['required']">
                                Please select the reason
                            </span>
                        </mat-hint>
                    </mat-radio-button>
                </mat-radio-group>
            </ng-container>
            <!-- ADMIN Section of reason comments for Back to Draft ENDS -->
        </ng-container>

        <!-- Requestor view comments for Back to Draft Starts -->

        <ng-container *ngIf="assetdetails1.controls['reason_Back_To_Draft_Status'].value!='' && assetdetails1.controls['basic_change_Status'].value=='Draft'">
            <mat-label>Your request to move the asset has been moved back to draft
            </mat-label>
            <mat-radio-group>
                <mat-radio-button class="d-block" disabled checked="false">
                    {{assetdetails1.controls['reason_Back_To_Draft_Status'].value}}
                </mat-radio-button>
            </mat-radio-group>
        </ng-container>
        <!-- Requestor view comments for Back to Draft ENDS -->

        <!-- Cancel of the request STARTS-->
        <!-- ADMIN view comments for CANCEL Starts -->
        <ng-container *ngIf="assetdetails1.controls['basic_change_Status'].value=='Cancel'">
            <ng-container *ngIf="userRole=='Admin'">
                <mat-label class="radioLable">
                    <p>Select the reason for request to Cancel</p>
                </mat-label>
                <mat-radio-group formControlName="action_plan_reason_Cancel_Status">
                    <mat-radio-button class="d-block" *ngFor="let reason of reason_Cancel; let i=index;"
                        [value]="reason">
                        {{reason}}
                        <mat-hint
                            *ngIf="i==1 && submitted && assetdetails1.controls['action_plan_reason_Cancel_Status']?.errors">
                            <span
                                *ngIf="assetdetails1.controls['action_plan_reason_Cancel_Status'].errors?.['required']">
                                Please select the reason
                            </span>
                        </mat-hint>
                    </mat-radio-button>
                </mat-radio-group>
                <!-- <div class="form-check">
                    <label class="form-check-label" *ngFor="let reason of reason_Cancel;let i=index;">
                        <input type="radio" [value]="reason" class="checkBoxColor form-check-input"
                            formControlName="action_plan_reason_Cancel_Status"
                            [ngClass]="{ 'is-invalid': assetdetails1.controls['action_plan_reason_Cancel_Status'].errors }">
                        {{reason}}
                        <div *ngIf="i==1 && submitted && assetdetails1.controls['action_plan_reason_Cancel_Status']?.errors"
                            class="invalid-feedback">
                            <div
                                *ngIf="assetdetails1.controls['action_plan_reason_Cancel_Status'].errors?.['required']">
                                Please select the reason
                            </div>
                        </div>
                    </label>

                </div> -->
            </ng-container>
            <!-- ADMIN view comments for CANCEL ENDS -->
        </ng-container>

        <!-- Requestor view comments for CANCEL Starts -->
        <ng-container
            *ngIf="assetdetails1.controls['action_plan_reason_Cancel_Status'].value!='' && assetdetails1.controls['action_plan_reason_Cancel_Status'].value && assetdetails1.controls['Change_Status'].value=='Cancel'">
            <label class="radioLable">Your request to move the asset has been cancelled</label>
            <div class="form-check">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" disabled>
                    {{assetdetails1.controls['action_plan_reason_Cancel_Status'].value}}
                </label>
            </div>
        </ng-container>
        <!-- Requestor view comments for CANCEL ENDS -->
        <!-- Cancel of the request ENDS-->
    </div>

    <div class="row">
        <div class="col-2">
            <!-- *ngIf="userRole!='Admin' && assetdetails1.controls['Change_Status'].value!='Pending Analyst Review'" -->
            <div class="form-group">
                <input type="button"
                    [disabled]="requestStatus.value=='' || userRole!='Admin' && assetdetails1.controls['Change_Status'].value=='Pending Analyst Review'"
                    class="btn btn-primary form-control" value="Submit"
                    (click)="onSubmit('SubmitBtn',assetdetails1.controls['Main_Asset_Number'].value)" />
            </div>
        </div>
        <div class="col-2">
            <div class="form-group">
                <input type="button" class="btn btn-primary form-control" value="Back" (click)="close()" />
            </div>
        </div>
        <div class="col-4 actionPlanLink" *ngIf="requestStatus.value=='Draft'">
            <button (click)="edit(assetdetails1.controls['Main_Asset_Number'].value)" value="Action Plan">
                <i class="fa fa-link"></i>
                Asset Change Action Plan</button>
            <div class="spinLoader" *ngIf="actionPlanClicked">
                <i class="fa fa-spinner fa-spin"></i>&nbsp;
                <span>Redirecting to Action Plan...</span>
            </div>
        </div>
        <div class="spinLoader" *ngIf="submitLoading">
            <i class="fa fa-spinner fa-spin"></i>&nbsp;
            <span>Submitting the request...</span>
        </div>
    </div>
</form>