<form [formGroup]="wbsCashFlowDetailsForm" class="cash-flow-form">

  <div class="row border-container">
    <span class="text-over-border">Purchase Orders</span>
  </div>

  <div class="section">
    <table class="details-table styled-table">
      <thead>
        <tr>
          <th *ngFor="let header of PurchaseOrdersHeaders" style="color: white;">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody formArrayName="purchaseOrders">
        <tr *ngFor="let po of purchaseOrdersFormArray.controls; let i = index" 
            [formGroupName]="i" [class.alt-row]="i % 2 !== 0">
          <td *ngFor="let header of PurchaseOrdersHeaders">
            <ng-container *ngIf="isColumnEditable(header, 'purchaseOrders'); else readOnlyCell">
              <input type="text" [formControlName]="header" class="editable-input" />
            </ng-container>
            <ng-template #readOnlyCell>
              <span>{{ po.get(header)?.value }}</span>
            </ng-template>
          </td>
          <td>
            <button *ngIf="po.get('isNewRow')?.value" type="button" class="delete-btn" (click)="deleteRow('purchaseOrders', i)">
              <div class="bin">
                <div class="lid"></div>
                <div class="container">
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row border-container">
    <span class="text-over-border">Journal Vouchers</span>
  </div>

  <div class="section">
    <table class="details-table styled-table">
      <thead>
        <tr>
          <th *ngFor="let header of JournalVouchersHeaders" style="color: white;">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody formArrayName="journalVouchers">
        <tr *ngFor="let jv of journalVouchersFormArray.controls; let i = index" 
            [formGroupName]="i" [class.alt-row]="i % 2 !== 0">
          <td *ngFor="let header of JournalVouchersHeaders">
            <span>{{ jv.get(header)?.value }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row border-container">
    <span class="text-over-border">Invoices</span>
  </div>

  <div class="section">
    <table class="details-table styled-table">
      <thead>
        <tr>
          <th *ngFor="let header of InvoicesHeaders" style="color: white;">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody formArrayName="invoices">
        <tr *ngFor="let invoice of invoicesFormArray.controls; let i = index" 
            [formGroupName]="i" [class.alt-row]="i % 2 !== 0">
          <td *ngFor="let header of InvoicesHeaders">
            <ng-container *ngIf="isColumnEditable(header, 'invoices'); else readOnlyCell">
              <input type="text" [formControlName]="header" class="editable-input" />
            </ng-container>
            <ng-template #readOnlyCell>
              <span>{{ invoice.get(header)?.value }}</span>
            </ng-template>
          </td>
          <td>
            <button *ngIf="invoice.get('isNewRow')?.value" type="button" class="delete-btn" (click)="deleteRow('invoices', i)">
              <div class="bin">
                <div class="lid"></div>
                <div class="container">
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</form>