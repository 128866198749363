import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';

export interface User {
  LabFabName: string;
  SITE: string;
  BUILDING: string;
}

@Component({
  selector: 'app-cap-stakeholder',
  templateUrl: './cap-stakeholder.component.html',
  styleUrls: ['./cap-stakeholder.component.scss']
})
export class CapStakeholderComponent implements OnInit {
  StakeholderForm!: FormGroup;
  loading: boolean = false;
  progress: any = 0;
  siteName!: string;
  filteredBuilding: any;
  filteredLabFabName: any;
  searchClicked: boolean = false;
  userRole!: string;
  subscriptions: Subscription[] = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  noRecordFound: boolean = false;

  @ViewChild('Import_Bulk_Stakeholder')
  Import_Bulk_Stakeholder!: ElementRef;

  //table data
  chunkCount: number = 0;
  chunkSaved: boolean = false;
  chunkSize: number = 200;
  num = 0;
  chunkCompleted = 0;
  excelData: any = [];
  startedImporting = false;
  displayedColumns: string[] = ['ACTION', 'SITE', 'BUILDING', 'LABFAB NAME', 'Asset Administration', 'Automated Equipment Control', 'Environmental Health Safety (EHS)', 'Electrical', 'Ergonomics', 'Information Technology', 'Mechanical and HVAC', 'Microcontamination and ESD', 'Operations', 'Process Systems', 'Space Layout Non-office', 'Procurement', 'Cybersecurity', 'MODIFIED DATETIME', 'LAST MODIFIED USER'];
  dataSource = new MatTableDataSource<User>([]);
  searchControl = new FormControl();
  stakeHolderData: any;
  bulkCost_Stakeholder_Headers: any = ['SITE', 'BUILDING', 'LABFAB NAME', 'Asset Administration Primary', 'Asset Administration Secondary', 'Asset Administration Watchers', 'Automated Equipment Control Primary', 'Automated Equipment Control Secondary', 'Automated Equipment Control Watchers', 'Environmental Health Safety (EHS) Primary', 'Environmental Health Safety (EHS) Secondary', 'Environmental Health Safety (EHS) Watchers', 'Electrical Primary', 'Electrical Secondary', 'Electrical Watchers', 'Ergonomics Primary', 'Ergonomics Secondary', 'Ergonomics Watchers', 'Information Technology Primary', 'Information Technology Secondary', 'Information Technology Watchers', 'Mechanical and HVAC Primary', 'Mechanical and HVAC Secondary', 'Mechanical and HVAC Watchers', 'Microcontamination and ESD Primary', 'Microcontamination and ESD Secondary', 'Microcontamination and ESD Watchers', 'Operations Primary', 'Operations Secondary', 'Operations Watchers', 'Process Systems Primary', 'Process Systems Secondary', 'Process Systems Watchers', 'Space Layout Non-office Primary', 'Space Layout Non-office Secondary', 'Space Layout Non-office Watchers', 'Procurement Primary', 'Procurement Secondary', 'Procurement Watchers','Cybersecurity Primary', 'Cybersecurity Secondary', 'Cybersecurity Watchers', 'ModifyDm', 'Last Modified User'];


  siteOption = ['COR', 'SDG'];
  buildings: any;
  allSites: any[] = [];
  constructor(private fb: FormBuilder, private common: CommonService, private dialog: MatDialog, private router: Router,
    private toastr: ToastrService
  ) {
    this.common.bannerData.next({ title: 'CAP', subtitle: 'Stakeholders' });

  }

  ngOnInit(): void {
    this.userRole = this.common.getUserRole();

    this.StakeholderForm = this.fb.group({
      // Define your form controls here
      site: '',
      building: '',
      labFabName: '',
      // Add more controls as needed
    });
    // Filter data based on search input
    this.searchControl.valueChanges.subscribe(searchTerm => {
      this.dataSource.filter = (searchTerm ?? '').trim().toLowerCase();
    });

    this.subscriptions.push(
      this.common.postCapAPI('itg/cap-stakeholders-search-itg', { site: "COR" }).subscribe({
        next: (
          (res) => {
            if (!res || res.errorMessage) {
              this.noRecordFound = true;
              return;
            } else {
              this.noRecordFound = false;
              this.common.loader.next(false);
              const mainData = this.mapResponseToColumns(res['message']);
              this.stakeHolderData = mainData;
              this.dataSource = new MatTableDataSource(this.stakeHolderData);
              this.dataSource.paginator = this.paginator;
            }
          }),
        error: (
          error => {
            if ('message' in error.error) {
              this.toastr.error(error.error['message']);
            }
            else {
              this.toastr.error('Internal Server Error, please logout and try again. If issue persists, contact Admin')
            }
            console.log(error);
          })
      })
    );

    this.subscriptions.push(
    this.common.getAPI('amd-get-labsite').subscribe({
      next: (
        (data) => {
          this.buildings = data;
          this.loading = false;
          for (let i = 0; i < this.buildings?.length; i++) {
            let tmp = this.buildings[i]['Location'];
            this.allSites.push(tmp);
          }
          this.allSites = this.allSites?.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          this.allSites.sort();
          if (this.StakeholderForm.get('building')?.value) {
            this.SearchBuilding();
            //   this.SearchLabFabName();
          }
        }
      ),
      error: (
        (error) => {
          console.error("Error occurred:", error);
          alert("Please connect with Asset Admin");
        })
    })
    );
  }

  fetchData() {
    this.common.loader.next(true);
    this.subscriptions.push(
    this.common.postCapAPI('itg/cap-stakeholders-search-itg', this.StakeholderForm.value).subscribe({
      next: (data) => {
        console.log('Stakeholder API response', data);
        this.searchClicked = true;
        this.dataSource.data = data.message.map((item: any) => ({
          SITE: item.SITE,
          BUILDING: item['BUILDING'],
          'LABFAB NAME': item['PartitionKey'],
          'Asset Administration': item['Asset Administration'],
          'Automated Equipment Control': item['Automated Equipment Control'],
          'Environmental Health Safety (EHS)': item["Environmental Health and Safety"],
          'Electrical': item['Electrical'],
          'Ergonomics': item['Ergonomics'],
          'Information Technology': item['Information Technology'],
          'Mechanical and HVAC': item['Mechanical and HVAC'],
          'Microcontamination and ESD': item['Microcontamination and ESD'],
          'Operations': item.Operations,
          'Process Systems': item['Process Systems'],
          'Space Layout Non-office': item['Space Layout Non-office'],
          'Procurement': item['Procurement'],
          'Cybersecurity': item['Cybersecurity'],
          'MODIFIED DATETIME': item['ModifyDm'],
          'LAST MODIFIED USER': item['Last Modified User']
        }));
        this.common.loader.next(false);
      },
      error: (error: any) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    }));
  }

  getStatus(element: any, column: string): string {
    const value = element[column];
    if (column === 'LAST MODIFIED USER') {
      return value === 'null' || value === null ? '' : value;
    }
    if (column === 'SITE' || column === 'BUILDING' || column === 'LABFAB NAME' || column === 'MODIFIED DATETIME') {
      return value; // Return the actual value
    }

    if ((value == undefined) || (value.Primary === 'null' && value.Secondary === 'null') || (value.Primary == "" && value.Secondary == "") || (value.Primary == null && value.Secondary == null)) {
      return "Not Assigned"
    }
    else {
      return "Assigned"
    }
  }

  getTooltip(element: any, column: string): string {
    const value = element[column];

    // Provide specific tooltip details based on the column name
    if (column === 'Operations' && value) {
      return `Primary: ${element.Operations.Primary}\nSecondary: ${element.Operations.Secondary}\nWatchers: ${element.Operations.Watchers}`;
    } else if (column === 'Asset Administration' && value) {
      return `Primary: ${element['Asset Administration'].Primary}\nSecondary: ${element['Asset Administration'].Secondary}\nWatchers: ${element['Asset Administration'].Watchers}`;
    } else if (column === 'Automated Equipment Control' && value) {
      return `Primary: ${element['Automated Equipment Control'].Primary}\nSecondary: ${element['Automated Equipment Control'].Secondary}\nWatchers: ${element['Automated Equipment Control'].Watchers}`;
    } else if (column === 'Environmental Health Safety (EHS)' && value) {
      return `Primary: ${element['Environmental Health Safety (EHS)'].Primary}\nSecondary: ${element['Environmental Health Safety (EHS)'].Secondary}\nWatchers: ${element['Environmental Health Safety (EHS)'].Watchers}`;
    } else if (column === 'Electrical' && value) {
      return `Primary: ${element['Electrical'].Primary}\nSecondary: ${element['Electrical'].Secondary}\nWatchers: ${element['Electrical'].Watchers}`;
    } else if (column === 'Ergonomics' && value) {
      return `Primary: ${element['Ergonomics'].Primary}\nSecondary: ${element['Ergonomics'].Secondary}\nWatchers: ${element['Ergonomics'].Watchers}`;
    } else if (column === 'Information Technology' && value) {
      return `Primary: ${element['Information Technology'].Primary}\nSecondary: ${element['Information Technology'].Secondary}\nWatchers: ${element['Information Technology'].Watchers}`;
    } else if (column === 'Mechanical and HVAC' && value) {
      return `Primary: ${element['Mechanical and HVAC'].Primary}\nSecondary: ${element['Mechanical and HVAC'].Secondary}\nWatchers: ${element['Mechanical and HVAC'].Watchers}`;
    } else if (column === 'Microcontamination and ESD' && value) {
      return `Primary: ${element['Microcontamination and ESD'].Primary}\nSecondary: ${element['Microcontamination and ESD'].Secondary}\nWatchers: ${element['Microcontamination and ESD'].Watchers}`;
    } else if (column === 'Process Systems' && value) {
      return `Primary: ${element['Process Systems'].Primary}\nSecondary: ${element['Process Systems'].Secondary}\nWatchers: ${element['Process Systems'].Watchers}`;
    } else if (column === 'Space Layout Non-office' && value) {
      return `Primary: ${element['Space Layout Non-office'].Primary}\nSecondary: ${element['Space Layout Non-office'].Secondary}\nWatchers: ${element['Space Layout Non-office'].Watchers}`;
    } else if (column === 'Procurement' && value) {
      return `Primary: ${element['Procurement'].Primary}\nSecondary: ${element['Procurement'].Secondary}\nWatchers: ${element['Procurement'].Watchers}`;
    }
    else if (column === 'Cybersecurity' && value) {
      return `Primary: ${element['Cybersecurity'].Primary}\nSecondary: ${element['Cybersecurity'].Secondary}\nWatchers: ${element['Cybersecurity'].Watchers}`;
    }

    return ''; // Return empty if no details are available
  }

  getActionButtonLabel(): string {
    return this.userRole === 'Admin' ? 'Edit' : 'View';
  }

  getActionButtonClick(element: User): void {
    if (this.userRole === 'admin') {
      // Navigate to edit function or component
      console.log('Edit action for:', element);
      // You can implement navigation or a modal here for editing
    } else {
      // Navigate to view function or component
      console.log('View action for:', element);
      // Implement view functionality here
    }
  }

  openEditDialog(element:any): void {

    console.log(element['LABFAB NAME']);
    const stakeholderData = {
      'Asset Administration': element['Asset Administration'] || {},
      'Automated Equipment Control': element['Automated Equipment Control'] || {},
      'Environmental Health Safety (EHS)': element['Environmental Health Safety (EHS)'] || {},
      'Electrical': element['Electrical'] || {},
      'Ergonomics': element['Ergonomics'] || {},
      'Information Technology': element['Information Technology'] || {},
      'Mechanical and HVAC': element['Mechanical and HVAC'] || {},
      'Microcontamination and ESD': element['Microcontamination and ESD'] || {},
      'Operations': element['Operations'] || {},
      'Process Systems': element['Process Systems'] || {},
      'Space Layout Non-office': element['Space Layout Non-office'] || {},
      'Procurement': element['Procurement'] || {},
      'Cybersecurity': element['Cybersecurity'] || {},
    };
    const labBuilding = {
      'Site': element['SITE'],
      'Building': element['BUILDING'],
    }
    console.log("ye isi component la", stakeholderData);


    this.router.navigate(['stakeholder/edit-page', element['LABFAB NAME']], { state: { passedData: stakeholderData, labBuildingData: labBuilding } });
  }


  // addStakeHolder(): void {

  //   const stakeholderData = {
  //     'Asset Administration': '',
  //     'Automated Equipment Control': '',
  //     'Environmental Health Safety (EHS)':'',
  //     'Electrical': '',
  //     'Ergonomics':'',
  //     'Information Technology': '',
  //     'Mechanical and HVAC': '',
  //     'Microcontamination and ESD': '',
  //     'Operations': '',
  //     'Process Systems': '',
  //     'Space Layout Non-office': '',
  //     'Procurement': '',
  //   };
  //   const labBuilding = {
  //     'editFlag' : false,
  //     'Site' : '',
  //     'Building' : '',
  //   }
  //   console.log("ye isi component la", stakeholderData);


  //   this.router.navigate(['stakeholder/edit-page', ''], { state: { passedData: stakeholderData , labBuildingData: labBuilding } });
  // }




  SearchBuilding() {
    this.siteName = this.StakeholderForm.get("site")?.value;
    console.log("requestDetailsValue", this.siteName);
    if (this.siteName) {
      this.filteredBuilding = [];
      this.filteredLabFabName = [];
      console.log("Before Loop");
      for (let i = 0; i < this.buildings?.length; i++) {
        if (this.buildings[i]['Location'] == this.StakeholderForm.get("site")?.value) {
          let tmp = this.buildings[i]['Lab_name'];
          this.filteredBuilding.push(tmp);
        }
      }
      this.filteredBuilding = this.filteredBuilding?.filter(function (elem: any, index: any, self: string | any[]) {
        return index === self.indexOf(elem);
      });
      this.filteredBuilding.sort();
      this.common.buildingData.next({ buildings: this.buildings, filteredBuilding: this.filteredBuilding });
    }

  }

  mapResponseToColumns(data: any) {
    return data.map((item: { [x: string]: any; }) => ({
      'SITE': item['SITE'],
      'BUILDING': item['BUILDING'],
      'LABFAB NAME': item['PartitionKey'],
      'Asset Administration Primary': item['Asset Administration'] ? item['Asset Administration']['Primary'] : '',
      'Asset Administration Secondary': item['Asset Administration'] ? item['Asset Administration']['Secondary'] : '',
      'Asset Administration Watchers': item['Asset Administration'] ? item['Asset Administration']['Watchers'] : '',
      'Automated Equipment Control Primary': item['Automated Equipment Control'] ? item['Automated Equipment Control']['Primary'] : '',
      'Automated Equipment Control Secondary': item['Automated Equipment Control'] ? item['Automated Equipment Control']['Secondary'] : '',
      'Automated Equipment Control Watchers': item['Automated Equipment Control'] ? item['Automated Equipment Control']['Watchers'] : '',
      'Environmental Health Safety (EHS) Primary': item['Environmental Health Safety (EHS)'] ? item['Environmental Health Safety (EHS)']['Primary'] : '',
      'Environmental Health Safety (EHS) Secondary': item['Environmental Health Safety (EHS)'] ? item['Environmental Health Safety (EHS)']['Secondary'] : '',
      'Environmental Health Safety (EHS) Watchers': item['Environmental Health Safety (EHS)'] ? item['Environmental Health Safety (EHS)']['Watchers'] : '',
      'Electrical Primary': item['Electrical'] ? item['Electrical']['Primary'] : '',
      'Electrical Secondary': item['Electrical'] ? item['Electrical']['Secondary'] : '',
      'Electrical Watchers': item['Electrical'] ? item['Electrical']['Watchers'] : '',
      'Ergonomics Primary': item['Ergonomics'] ? item['Ergonomics']['Primary'] : '',
      'Ergonomics Secondary': item['Ergonomics'] ? item['Ergonomics']['Secondary'] : '',
      'Ergonomics Watchers': item['Ergonomics'] ? item['Ergonomics']['Watchers'] : '',
      'Information Technology Primary': item['Information Technology'] ? item['Information Technology']['Primary'] : '',
      'Information Technology Secondary': item['Information Technology'] ? item['Information Technology']['Secondary'] : '',
      'Information Technology Watchers': item['Information Technology'] ? item['Information Technology']['Watchers'] : '',
      'Mechanical and HVAC Primary': item['Mechanical and HVAC'] ? item['Mechanical and HVAC']['Primary'] : '',
      'Mechanical and HVAC Secondary': item['Mechanical and HVAC'] ? item['Mechanical and HVAC']['Secondary'] : '',
      'Mechanical and HVAC Watchers': item['Mechanical and HVAC'] ? item['Mechanical and HVAC']['Watchers'] : '',
      'Microcontamination and ESD Primary': item['Microcontamination and ESD'] ? item['Microcontamination and ESD']['Primary'] : '',
      'Microcontamination and ESD Secondary': item['Microcontamination and ESD'] ? item['Microcontamination and ESD']['Secondary'] : '',
      'Microcontamination and ESD Watchers': item['Microcontamination and ESD'] ? item['Microcontamination and ESD']['Watchers'] : '',
      'Operations Primary': item['Operations'] ? item['Operations']['Primary'] : '',
      'Operations Secondary': item['Operations'] ? item['Operations']['Secondary'] : '',
      'Operations Watchers': item['Operations'] ? item['Operations']['Watchers'] : '',
      'Process Systems Primary': item['Process Systems'] ? item['Process Systems']['Primary'] : '',
      'Process Systems Secondary': item['Process Systems'] ? item['Process Systems']['Secondary'] : '',
      'Process Systems Watchers': item['Process Systems'] ? item['Process Systems']['Watchers'] : '',
      'Space Layout Non-office Primary': item['Space Layout Non-office'] ? item['Space Layout Non-office']['Primary'] : '',
      'Space Layout Non-office Secondary': item['Space Layout Non-office'] ? item['Space Layout Non-office']['Secondary'] : '',
      'Space Layout Non-office Watchers': item['Space Layout Non-office'] ? item['Space Layout Non-office']['Watchers'] : '',
      'Procurement Primary': item['Procurement'] ? item['Procurement']['Primary'] : '',
      'Procurement Secondary': item['Procurement'] ? item['Procurement']['Secondary'] : '',
      'Procurement Watchers': item['Procurement'] ? item['Procurement']['Watchers'] : '',
      'Cybersecurity Primary': item['Cybersecurity'] ? item['Cybersecurity']['Primary'] : '',
      'Cybersecurity Secondary': item['Cybersecurity'] ? item['Cybersecurity']['Secondary'] : '',
      'Cybersecurity Watchers': item['Cybersecurity'] ? item['Cybersecurity']['Watchers'] : '',
      'ModifyDm': item['ModifyDm'],
      'Last Modified User': item['Last Modified User']
    }));
  }

  importBulkStakeholders(evt: any) {

    console.log("Bulk import uploading Cost Center Code")
    this.excelData = []
    this.startedImporting = true;
    // console.log("insert to S4 sort key")
    const target: DataTransfer = <DataTransfer>(evt.target);

    if (target?.files[0]?.name && target?.files[0]?.name != 'AMD_Bulk_Stakeholder.xlsx') {
      this.openDialogMessage('Uploading file must be "AMD_Bulk_Stakeholder.xlsx"', '');
      this.resetFileUploader();
      return
    }

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      this.loading = true
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
      console.log("sheetsNamw",wb.SheetNames);
      let length = wb.SheetNames.length;
      // console.log(length);
      const data = reader.result;
      let jsonData = null;
      // var sheet1 = wb.SheetNames[1];
      jsonData = wb.SheetNames.reduce((initial, name) => {
        console.log(name);
        if (name == "AMD Bulk Stakeholder") {
          const sheet = wb.Sheets[name];
          this.excelData = this.excelData.concat(XLSX.utils.sheet_to_json(sheet, { range: 1, defval: "null" }));
        }
        return initial;
      }, {});

      let userName = atob(this.common.currentUser);
      for (let i in this.excelData) {
        this.num++
        this.excelData[i]['userName'] = userName;

        replace(this.excelData[i])
        function replace(myObj: any) {
          Object.keys(myObj).forEach(function (key) {

            if (myObj[key] instanceof Date) {
              if (myObj[key]) {
                let date = new Date(myObj[key]);
                //console.log(myObj[key].getTimezoneOffset());
                let offSet = myObj[key].getTimezoneOffset();
                if (offSet > 0) {
                  date = new Date(date.getTime() + (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
                else {
                  date = new Date(date.getTime() - (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
                //myObj[key] = String((myObj[key].getUTCMonth()+1)+"/"+myObj[key].getUTCDate()+"/"+myObj[key].getUTCFullYear());
              }
              else {
                myObj[key] = "";
              }
            }
            else {
              typeof myObj[key] == 'object' ? replace(myObj[key]) : myObj[key] = String(myObj[key]);
            }
          });
        }
      }
      console.log("this is my data",this.excelData);
      this.chunkSize = 0;
      this.chunkCount = 0;
      this.progress = 0;
      this.chunkSize = this.excelData.length;
      //console.log(this.chunkSize%200);
      this.chunkCount = (this.chunkSize % 100 === 0 ?
        (this.chunkSize / 100) :
        (Math.floor(this.chunkSize / 100) + 1));
      this.chunkSaved = false;
      this.callBulkCostMangers(0, 99);
    }
    reader.readAsBinaryString(target.files[0]);
  }

  callBulkCostMangers(start: any, end: any) {

    if ((this.chunkSize - start) < 100) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = this.excelData.slice(start, end);
      this.subscriptions.push(
        this.common.postCapAPI('itg/cap-import-stakeholders', param).subscribe({
          next: (
            (data: any) => {
              this.chunkSaved = false;
              if (data['message']) {
                if (end < this.chunkSize) {
                  this.callBulkCostMangers(start + 100,
                    (this.chunkSize - start) < 100 ? ((this.chunkSize - start) + start) : (end + 100));
                } else {
                  //this.progress = 100;
                  this.startedImporting = false;
                  this.loading = false;
                  //alert('File Imported Sucessfull ');
                  this.openDialogMessage(' File Imported Sucessfull ', '');
                  this.resetFileUploader();

                }
              } else {
                this.loading = false;
                this.resetFileUploader();
                this.openDialogMessage('Failed to import asset', '');

              }

              this.chunkCompleted++;
              console.log("end: " + end, " this.excelData.length: " + this.excelData.length);
              this.progress = (end == 0 ? 100 : ((end / this.excelData.length) * 100).toFixed(2)); //((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
              console.log("chunkCount: " + this.chunkCount);
              console.log("chunksCompleted: " + this.chunkCompleted);
              console.log(this.progress + '%');
              console.log('if exit');
            }
          ),
          error: (
            error => {
              this.common.errorHandler(error);
              this.common.loader.next(false);
            }
          )
        })
      )
    }
    // Bulk import uploading Cost Center Code,Cost Object manager, Owners ENDS
  }
  //reset teh input file value
  resetFileUploader() {
    this.Import_Bulk_Stakeholder.nativeElement.value = null;
    this.chunkCompleted = 0;
    this.progress = 0;
    this.chunkCount = 0;
  }

  openDialogMessage(message: any, assetID: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      message: message,
      tabletemplate: false
    }
    this.dialog.open(DialogBoxComponent, dialogConfig);
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}


export interface User {
  'LABFAB NAME': string;
  SITE: string;
  BUILDING: string;
  PartitionKey: string;
  'Asset Administration'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Automated Equipment Control'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Environmental Health Safety (EHS)'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Electrical'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Ergonomics'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Information Technology'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Mechanical and HVAC'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Microcontamination and ESD'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Operations'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Process Systems'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Space Layout Non-office'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Procurement'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Cybersecurity'?: { Primary?: string; Secondary?: string; Watchers?: string };
  'Last Modified User': string;
  ModifyDm: string;

}

