<button class="btn btn-primary {{cssClass}}" (click)="exportBulkData()" type="button" [disabled]="disabled">
    <i class="fa fa-file-excel" *ngIf="!loading"></i>
    <ng-container *ngIf="chunksCompleted==0"> 
      <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>&nbsp;
      
       {{loading? 'Fetching Data': Button_Name}} 
      
    </ng-container>
    <span *ngIf="chunksCompleted>0">
      <i class="fa fa-download fa-fade"></i>
       {{progress}}% Downloading Data </span>
  </button>