import { Component } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { assetDetails } from 'src/app/models/assetDetails';

@Component({
  selector: 'app-edit-labfabname',
  templateUrl: './edit-labfabname.component.html',
  styleUrls: ['./edit-labfabname.component.scss']
})
export class EditLabfabnameComponent {

  labid!: string;
  UserName: any;
  // assetdetails1!: FormGroup;
  submitted = false;
  editable = true;
  loading = false;
  loaded : any;
  assetDetails = new assetDetails();
  formControlDate = new FormControl();
  formControlDate1 = new FormControl();
  formControlDate2 = new FormControl();
  formControlDate3 = new FormControl();
  assetdetails1: FormGroup = this.fb.group({
    LAB_ID:[''],
    Asset_Count:[''],
    New_Lab_Fab_Name:[''],
    Lab_Fab_Name:['',Validators.required],
    New_Lab_Owner:[''],
    Lab_Owner:['', Validators.required],
    Tool_Owner:[''],
    SM_EMAIL:[''],
    SITE:[''],
    New_BUILDING:[''],
    BUILDING:['',Validators.required],
    Section_Manager:[''],
    Lab_Scanner1:['', Validators.required],
    Lab_Scanner1_Shift:[''],
    Lab_Scanner2:[''],
    Lab_Scanner2_Shift:[''],
    Lab_Active:[''],
    Labfab_Comment:[''],
    INSERT_DATETIME: ['null'],
    MODIFIED_DATETIME: ['null'],
    Last_MODIFIED_user: ['null']
  });
  constructor(
    private activatedRoute: ActivatedRoute,
    private common: CommonService,
    private fb: FormBuilder,
    private route: Router,
    private dialog: MatDialog
  ) {
    // Request Change For a Fixed Asset
    this.common.bannerData.next({ title: 'AMD', subtitle: 'Edit Data For LabFab' });
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
        this.labid = params['LABFAB_Name'];
        if(this.labid != undefined){
          this.common.loader.next(true);
          this.getlabfabdetailsbyid(this.labid);
        }
        else{
          this.assetdetails1.get('Lab_Fab_Name')?.setValue("null");
          this.assetdetails1.get('Lab_Owner')?.setValue("null");
          this.assetdetails1.get('BUILDING')?.setValue("null");
        }
      });
    }

    get formBuilder() { 
      return this.assetdetails1.controls; 
    }
  
  getlabfabdetailsbyid(labid: string) {
    this.common.getAPI('amd-get-labfabbyid/' + labid).subscribe((data: any) => {
      //console.log(data[0]);
      this.assetdetails1.patchValue(data[0]);
      this.common.loader.next(false);
      //this.formControlDate = new FormControl(new Date(moment(data[0]['Asset_Capitalization_Date'], 'MM-DD-YYYY').format('YYYY-MM-DD')));
      //this.formControlDate1 = new FormControl(new Date(moment(data[0]['Depreciation_Terms_Start_Date'], 'MM-DD-YYYY').format('YYYY-MM-DD')));
      //this.formControlDate2 = new FormControl(new Date(moment(data[0]['Asset_Value_Date_of_the_First_Posting_Supplementary_Text'], 'MM-DD-YYYY').format('YYYY-MM-DD')));
      //this.formControlDate3 = new FormControl(new Date(moment(data[0]['Asset_Last_Inventory_Date'], 'MM-DD-YYYY').format('YYYY-MM-DD')));
      // if(atob(localStorage.getItem('userRole'))!="Admin"){

      if(this.common.getUserRole()!="Admin"){
        this.editable = false;
        //this.formControlDate.disable();
        //this.formControlDate1.disable();
        //this.formControlDate2.disable();
        //this.formControlDate3.disable();
    }
  });
}

  onSubmit() { 
    this.submitted = true;
    if (this.assetdetails1.invalid) {
      window.scroll(0,0);
  } else {
    if(this.labid == undefined){
      let building ;
      console.log("inside if")
      this.assetdetails1.get('Lab_Fab_Name')?.setValue(this.assetdetails1.get('New_Lab_Fab_Name')?.value);
      this.assetdetails1.get('New_Lab_Fab_Name')?.setValue("");
      this.assetdetails1.get('Lab_Owner')?.setValue(this.assetdetails1.get('New_Lab_Owner')?.value);
      this.assetdetails1.get('New_Lab_Owner')?.setValue("");
      this.assetdetails1.get('BUILDING')?.setValue(this.assetdetails1.get('New_BUILDING')?.value);
      this.assetdetails1.get('New_BUILDING')?.setValue("");
      building = this.assetdetails1.get('BUILDING')?.value.slice(0, 3);
      if(isNaN(building.charAt(2))){
        building = this.assetdetails1.get('BUILDING')?.value.slice(0, 3);
      }
      else{
        building = "3rd Party";
      }
      console.log(building);
      this.assetdetails1.get('SITE')?.setValue(building);
    
    this.UserName = atob(this.common.currentUser);
      this.assetdetails1.get('Last_MODIFIED_user')?.setValue(this.UserName)

    Object.keys(this.assetdetails1.controls).forEach(key => {
      if(this.assetdetails1.get(key)?.value==""){
        this.assetdetails1.get(key)?.setValue("NULL");
      }
    });
    //   this.assetDetailsService.addeditlabfabname(this.assetdetails1.value).subscribe(data => {
    //   if (data.statusCode == 200) {
    //     alert("Response Saved Successfully")
    //     this.route.navigate(['/labfab']);
    //   }
    // });

  }
  else{
    let building ;
    building = this.assetdetails1.get('BUILDING')?.value.slice(0, 3);
    console.log(building.charAt(2));
    if(isNaN(building.charAt(2))){
      building = this.assetdetails1.get('BUILDING')?.value.slice(0, 3);
    }
    else{
      building = "3rd Party";
    }
    console.log(building);
    console.log("Inside else")
    this.UserName = atob(this.common.currentUser);
      this.assetdetails1.get('Last_MODIFIED_user')?.setValue(this.UserName);
      this.assetdetails1.get('SITE')?.setValue(building);
    Object.keys(this.assetdetails1.controls).forEach(key => {
      if(this.assetdetails1.get(key)?.value==""){
        this.assetdetails1.get(key)?.setValue("NULL");
      }
    });
    // this.assetDetailsService.editlabfabname(this.assetdetails1.value).subscribe(data => {
    //   if (data.statusCode == 200) {
    //     alert("Response Saved Successfully")
    //     this.route.navigate(['/labfab']);
    //   }
    // });
    
  }
    }
  }

  close(){
    this.route.navigate(['/asset/labfab']);
  }

}