<section class="page_404">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 ">
                <div class="text-center">
                    <div class="four_zero_four_bg">
                        <h1 class="text-center ">404</h1>
                    </div>
                    <div class="contant_box_404">
                        <h3 class="h2">
                            Looks like you're lost
                        </h3>

                        <p>The page you are looking for is not available!</p>

                        <a href="/" class="link_404 bg-primary">Go to Home</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>