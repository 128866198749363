import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as XLSX from 'xlsx';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-lab-fab-display',
  templateUrl: './lab-fab-display.component.html',
  styleUrls: ['./lab-fab-display.component.scss']
})
export class LabFabDisplayComponent implements OnInit {

  assetDetails: any;
  userName: any;
  headers: any;
  dataSource: any;
  IsdisableEdit!: boolean;
  displayedColumns = ['userId']
  buildings: any = ['COR1#building1', 'COR1#building2', 'COR2#building2', 'COR3#building3'];
  arr1: any[] = [];
  arr2: any[] = [];
  arr3: any[] = [];
  loading = true;
  loaded: any;
  ascendingsort: boolean | null = false;
  submitted = false;
  columnindex: any;
  currentPage: any;
  tableCheckAllBox = false;
  checkItem: any = [];
  asset: any = [];
  num = 0;
  value!: number;
  count!: number;
  filterhappens = false;
  gridData: any = [];
  pageLength: any = [];
  filterText: any = [];
  filterKey: any = [];
  tableHeaderArray: any = [];
  rowCount: any;
  assetdetails1!: FormGroup;
  show !: boolean;
  showGrid!: boolean;
  noRecord: boolean = false;
  isadmin!: boolean;
  excelData: any = [];
  progressUpload = false;
  chunkCount: number = 0;
  chunkSaved: boolean = false;
  chunkSize: number = 300;
  progress: any;
  chunkCompleted = 0;
  startedImporting = false;
  subscriptions: Subscription[] = [];
  constructor(private fb: FormBuilder, private common: CommonService, private router: Router,private toastr: ToastrService) {
    this.common.bannerData.next({ title: 'AMD', subtitle: 'LabFab Changes' });
  }

  ngOnInit() {
    if (sessionStorage.getItem('flag') == 'true') {
      this.loading = false
    }
    sessionStorage.removeItem('flag')
    this.IsdisableEdit = true;
    this.showGrid = true;
    this.show = false;
    this.assetdetails1 = this.fb.group({
      LabFab_Name: [''],
      Asset_Location: [''],
      OWNER_EMAIL: [''],
      Labname: [''],
      Scanner_EMAIL: [''],
      Scanner_II_EMAIL: ['']
    });
    this.isadmin = (this.common.getUserRole() == 'Admin')

    if (this.common.getUserRole() == 'User') {

      this.IsdisableEdit = false;
    }
    if (this.common.getUserRole() == 'Admin') {
      this.showGrid = true;
      this.show = true;
      this.getSite();
      this.showGrid = false;
      if (this.common.assetFilter != null) {
        this.showGrid = true;
        this.getLabname();
      }
    }
  }

  getSite() {
    this.common.loader.next(true);
    this.subscriptions.push(
      this.common.getAPI('amd-get-labsite/').subscribe({
        next: (data: any) => {
          this.buildings = data;
          this.loading = false;
          this.common.loader.next(false);
          for (let i = 0; i < this.buildings.length; i++) {
            let tmp = this.buildings[i]['Location'];
            this.arr1.push(tmp);
          }
          this.arr1 = this.arr1.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          
          this.arr1.sort();
          
        },
        error:
          (error) => {
            this.common.loader.next(false);
            this.common.show('error', error.error.message);
          }
      })
    );
  }

  getLabname() {
    this.common.loader.next(true);
    this.subscriptions.push(
      this.common.postAPI('amd-get-labfabname/', this.common.assetFilter).subscribe({
        next: (data: any) => {
          this.asset = this.getUpdatedArray(data['body']);
          this.common.loader.next(false);
          this.rowCount = data['body'].length
          if (this.rowCount === 0) {
            this.noRecord = true
          } else {
            this.noRecord = false
          }
          this.gridData = this.asset;
          this.sortData(11, "MODIFIED DATETIME")
          
        },
        error:
          (error) => {
            this.common.loader.next(false);
            this.common.show('error', error.error.message);
          }
      })
    );
  }
  GetSortOrder(prop: any) {
    return function (a: any, b: any) {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    }
  }
  SearchProduct() {
    let value = this.assetdetails1.get("Asset_Location")?.value;
    this.arr2 = [];
    for (let i = 0; i < this.buildings.length; i++) {
      if (this.buildings[i]['Location'] == value) {
        let tmp = this.buildings[i]['Lab_name'];
        this.arr2.push(tmp);
      }
    }
    this.arr2 = this.arr2.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })
    this.arr2.sort();
  }
  

  onSubmit() {
    this.assetDetails = null
    this.showGrid = false
    this.common.loader.next(true);
    if (this.assetdetails1.get('LabFab_Name')?.value == "" &&
      this.assetdetails1.get('Asset_Location')?.value == "" &&
      this.assetdetails1.get('OWNER_EMAIL')?.value == "" &&
      this.assetdetails1.get('Labname')?.value == "" &&
      this.assetdetails1.get('Scanner_EMAIL')?.value == "" &&
      this.assetdetails1.get('Scanner_II_EMAIL')?.value == "") {
      //alert("Please Select Atleast One Field");
      this.toastr.warning('Please Select Atleast One Field','Warning')
    }
    Object.keys(this.assetdetails1.controls).forEach(key => {
      if (this.assetdetails1.get(key)?.value == "") {
        this.assetdetails1.get(key)?.setValue("null");
      }
    });
    this.common.assetFilter = this.assetdetails1.value;
    this.subscriptions.push(
      this.common.postAPI('amd-get-labfabname/', this.assetdetails1.value).subscribe(
        {
          next: (data: any) => {
            this.asset = this.getUpdatedArray(data['body']);
            this.common.loader.next(false);
            this.rowCount = data['body'].length;
            if (this.rowCount === 0) {
              this.noRecord = true;
            } else {
              this.noRecord = false;
            }
            

            
            this.gridData = this.asset;
            this.sortData(11, "MODIFIED DATETIME")
          },
          error:
            (error) => {
              this.common.loader.next(false);
              this.common.show('error', error.error.message);
            }
        },
      )
    );
    Object.keys(this.assetdetails1.controls).forEach(key => {
      this.assetdetails1.get(key)?.setValue("");
    });
    this.show = true;
    this.showGrid = true;
    this.arr2 = [];
  }


  pageNext(pageNumber: any) {
    this.currentPage = pageNumber
    let assetData = this.gridData;
    if (pageNumber === this.pageLength.length) {
      let len = pageNumber * 500
      let index = len - 500
      this.assetDetails = assetData.slice(index, assetData.length);
    } else {
      let len = pageNumber * 500
      let index = len - 500
      this.assetDetails = assetData.slice(index, len);
    }

  }

  paginationData(element: any) {
    this.pageLength = []
    this.gridData = element;
    let length = element.length / 500;
    if (length == 0) {
      this.pageLength[0] = 1;
    } else {
      for (let index = 0; index < length; index++) {
        this.pageLength[index] = index + 1;
      }
    }
    this.pageNext(1)
  }

  sortData(index: any, value: any) {
    let columnData = []
    for (let i = 0; i < this.gridData.length; i++) {
      columnData.push(this.gridData[i][value])
    }


    if (this.columnindex === index) {
      this.ascendingsort = !this.ascendingsort
    } else {
      this.columnindex = index
      this.ascendingsort = false
    }
    this.switchSortMethod(columnData, value)
  }

  switchSortMethod(columnData: any = [], value: any) {

    if (this.ascendingsort) {
      columnData.sort((a: any, b: any) => {
        if (value.toLowerCase() === "modified datetime") {
          var datea = Date.parse(a) || 0;
          var dateb = Date.parse(b) || 0;
          return <any>datea - <any>dateb

        }
        else {
          if (typeof a === 'string' && typeof b === 'string') {
            return a.toLowerCase().localeCompare(b.toLowerCase(), 'en', { numeric: true })
          } else {
            return a - b;
          }
        }
      });
      let sortedAsset = this.getSortedData(columnData, value)
      this.paginationData(sortedAsset)
    } else {
      columnData.sort((a: any, b: any) => {
        if (value.toLowerCase() === "modified datetime") {

          var datea = Date.parse(a) || 0;
          var dateb = Date.parse(b) || 0;
          return <any>dateb - <any>datea

        }
        else {
          if (typeof a === 'string' && typeof b === 'string') {
            return b.toLowerCase().localeCompare(a.toLowerCase(), 'en', { numeric: true })
          } else {
            return b - a;
          }
        }
      });
      let sortedAsset = this.getSortedData(columnData, value)
      this.paginationData(sortedAsset)
    }
  }

  getSortedData(sortedData: any, value: any) {
    sortedData.forEach((mainData: any, index: any) => {
      for (let i = index + 1; i < sortedData.length; i++) {
        if (sortedData[index] === sortedData[i]) {
          sortedData.splice(i, 1)
          i = i - 1
        }
      }
    });
    let finalAssetDetails: any = []
    sortedData.forEach((data: any) => {
      for (let i = 0; i < this.gridData.length; i++) {
        if (this.gridData[i][value] === data) {
          finalAssetDetails.push(this.gridData[i])
        }
      }
    });
    return finalAssetDetails;
  }
  edit(LABFAB_Name: any) {
    sessionStorage.setItem('flag', 'true');
    //this.router.navigate(['editlabfabname'], { queryParams: { LABFAB_Name: LABFAB_Name } });
    this.router.navigate(['/assetRequestChange/editlabfabname'], { queryParams: { LABFAB_Name: LABFAB_Name } });
  }

  newfab() {
    sessionStorage.setItem('flag', 'true');
   // this.router.navigate(['editlabfabname']);
    this.router.navigate(['/assetRequestChange/editlabfabname']);
  }  

  filterValue(inputData: any, indexValue: any, keyValue: any) {
    let filterData = this.asset;
    this.filterKey[indexValue] = keyValue;
    for (let index = 0; index < inputData.length; index++) {
      let tempFilterData = []
      if (inputData[index] != undefined && inputData[index] != '') {
        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i][this.filterKey[index]].toLowerCase().includes(inputData[index].toLowerCase())) {
            tempFilterData.push(filterData[i]);
          }
        }
        filterData = tempFilterData;
      }
    }
    this.ascendingsort = null;
    this.gridData = filterData;
    this.sortData(11, "MODIFIED DATETIME")
    
    
  }


  checkUncheckAll() {
    let len = this.asset.length;
    for (let index = 0; index < len; index++) {
      this.asset[index].Checkbox_Data = this.tableCheckAllBox
    }
  }

  originalOrder = (a: any, b: any) => { return 0 }

  getUpdatedArray(assetArray: any) {
    let columnOrder = ["SITE", "BUILDING", "SM EMAIL", "LABFAB Name", "OWNER EMAIL",
      "SCANNER EMAIL", "SCANNER II EMAIL", "MODIFIED DATETIME", "LAST MODIFIED USER"]

    this.tableHeaderArray = columnOrder;
    let finalAssetArray = []
    for (let i = 0; i < assetArray.length; i++) {
      let tempAssetArray: any = {}
      for (let j = 0; j < columnOrder.length; j++) {
        tempAssetArray[columnOrder[j]] = assetArray[i][columnOrder[j]];
      }
      finalAssetArray.push(tempAssetArray);
    }
    return finalAssetArray
  }

  importLabFab(evt: any) {
    
    this.startedImporting = true;
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
      let length = wb.SheetNames.length;
      const data = reader.result;
      let jsonData = null;
      
      jsonData = wb.SheetNames.reduce((initial, name) => {
        if (name == "Lab_Fab list US31") {
          const sheet = wb.Sheets[name];
          this.excelData = this.excelData.concat(XLSX.utils.sheet_to_json(sheet, { range: 4, defval: "Null" }));
        }
        return initial;
      }, {});
      this.userName = atob(this.common.currentUser);
      for (let i in this.excelData) {
        this.num++
        this.excelData[i]['userName'] = this.userName;
        replace(this.excelData[i])
        function replace(myObj: { [x: string]: any; }) {
          Object.keys(myObj).forEach(function (key) {

            if (myObj[key] instanceof Date) {
              if (myObj[key]) {
                let date = new Date(myObj[key]);
                let offSet = myObj[key].getTimezoneOffset();
                if (offSet > 0) {
                  date = new Date(date.getTime() + (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
                else {
                  date = new Date(date.getTime() - (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
                
              }
              else {
                myObj[key] = "";
              }
            }
            else {
              typeof myObj[key] == 'object' ? replace(myObj[key]) : myObj[key] = String(myObj[key]);
            }
          });
        }
      }
      
      var keys = Object.keys(this.excelData[0]);
      for (var obj in this.excelData) {
        for (var key of keys) {
          if (this.excelData[obj][key]?.length == 0 && key != 'userName') {
            this.excelData[obj][key] = " ";
          }
        }

      }



      


      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      

      
      this.chunkSize = this.excelData.length;
      this.chunkCount = (this.chunkSize % 300 === 0 ?
        (this.chunkSize / 300) :
        (Math.floor(this.chunkSize / 300) + 1));
      this.chunkSaved = false;
      this.callApi(0, 300);
    }
    reader.readAsBinaryString(target.files[0]);
  }
  callApi(start: any, end: any) {
    if ((this.chunkSize - start) < 300) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = this.excelData.slice(start, end);
      const inputData = { "Data": param };
      this.subscriptions.push(
        this.common.postAPI('amd-import-labfab', inputData).subscribe((data: any) => {
          this.chunkSaved = false;
          if (data['statusCode'] == 200) {
            if (end < this.chunkSize) {
              this.callApi(start + 300,
                (this.chunkSize - start) < 300 ? ((this.chunkSize - start) + start) : (end + 300));
            } else {
              
              this.startedImporting = false;
              //alert('File Imported Sucessfull ');
              this.toastr.success('File Imported Sucessfully','Success')
              let currentURL = this.router.url;
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate([currentURL]);
            }
          } else {
            //alert('Failed to import LabFab');
            this.toastr.error('Failed to import LabFab','Error')
          }
        })
      );
      this.chunkCompleted++;
      this.progress = ((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
    }

  }

  exportLabFab() {
    let data1: any = [];
    const inputData = { "nothing yet": "nothing" };
    this.subscriptions.push(
      this.common.postAPI('amd-export-labfab', inputData).subscribe((data: any) => {
        data1 = data['body'];
        if (data.length != 0) {
          let columnOrder = ["SITE", "BUILDING", "SM EMAIL", "LABFAB Name", "OWNER EMAIL", "Scanner EMAIL", "Scanner II EMAIL"];

          let workbook = new Excel.Workbook();

          
          let worksheet = workbook.addWorksheet('Lab_Fab list US31');


          let titleValues1 = [""];
          worksheet.addRow(titleValues1);
          worksheet.addRow(titleValues1);
          worksheet.addRow(titleValues1);
          worksheet.addRow(titleValues1);

          let headerRow = worksheet.addRow(columnOrder);
          let DateObj = new Date();
          let date = ('0' + (DateObj.getMonth() + 1)).slice(-2) + '/' + ('0' + DateObj.getDate()).slice(-2) + '/' + DateObj.getFullYear();
          let date1 = ('0' + (DateObj.getMonth() + 1)).slice(-2) + '/' + "01" + '/' + DateObj.getFullYear();
          let currentMonth = ('0' + (DateObj.getUTCMonth() + 1)).slice(-2)
          let docname = "Inventory_Lab_Fab_List_" + currentMonth + DateObj.getFullYear();


          for (let x1 of data1) {
            let x2 = columnOrder;
            let temp = []
            for (let y of x2) {
              if (x1[y] == 'null' || x1[y] == 'NULL' || x1[y] == 'Null') {
                x1[y] = ''
              }
              temp.push(x1[y])
            }
            worksheet.addRow(temp)
          }

          worksheet.getCell('D1').value = 'New Names for Lab/Fab';
          worksheet.getCell('E4').value = 'S4 Tool Owner';
          worksheet.getCell('E3').value = 'Additional Description';
          worksheet.getCell('D4').value = '15 Character max for S4';
          
          
          
          
          
          worksheet.getRow(5).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'BFBFBF' }
          };
          ['E4'].map(key => {
            worksheet.getCell(key).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFF00' },
            };
          });
          ['D4'].map(key => {
            worksheet.getCell(key).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF0000' },
            };
          });
          workbook.xlsx.writeBuffer().then((buffer: any) => {
            let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, docname + '.xlsx');
          });
        }

        else {
          //alert("No retired or reclassified assets");
          this.toastr.error('No retired or reclassified assets','Error')
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
