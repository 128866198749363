import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import * as XLSX from 'xlsx';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Observable, startWith, map } from 'rxjs';

@Component({
  selector: 'app-ltp-por-report',
  templateUrl: './ltp-por-report.component.html',
  styleUrls: ['./ltp-por-report.component.scss']
})
export class LtpPorReportComponent {
  isadmin!: boolean;
  requestTypeOptions: string[] = ['LTP (>1 year out)','POR(Current Fiscal Year)', 'Expense (CAP SH Review)'];
  sectionOptions: string[] = ['Section1', 'Section2', 'Section3'];
  plannerOptions: string[] = ['krista.lively@hp.com', 'reshu.jain@hp.com', 'michael.moffit@hp.com'];
  //ltpPorNumberList: string[] = ['1111', '1122', '1133', '1144', '1155', '1166', '1177', '1188', '1199', '1200'];
  ltpPorNumberList: string[] = [];
  searchRecords: FormGroup;
  filteredLTPPORNumber!: Observable<string[]>;
  excel_data: any;
  status: any[] = [];
  tableHeaderArray: any = [];
  capexSearchDetails: any;
  showGrid!: boolean;
  columnOrder: any[] = [];
  displayedColumns: string[] = [];
  dataSource = [];
  plannerDataSource = [];
  searchClicked = false;
  filters: any;
  initiallyVisibleColumns: string[] = [];
  columnVisibility: { [key: string]: boolean } = {};
  columnMapping: { [key: string]: string[] } = {};
  headers: string[] = [];
  showExportToExcel: boolean = false;
  loadingExport: boolean = false;
  dataForWorksheet: any;
  columnHeaders: { header: string; expandColumns: string[]; }[] = [];
  tableColumns: string[] = []
  expandedColumns: { [key: string]: boolean } = {};
  columnNames: string[] = [];
  buildings: any;
  loading: boolean = false;
  allSites: any[] = [];
  
  // @ViewChild(MatCheckbox) headerCheckBox!: MatCheckbox;
  constructor(private common: CommonService, private fb: FormBuilder, private router: Router, private http: HttpClient) { 
    this.common.bannerData.next({title: 'Report', subtitle: 'LTP/POR Report'})
    this.searchRecords = this.fb.group({
      requestType: [''],
      status: [''],
      ltpPorNumber: [''],
      planner: [''],
      section: [''],
      requestingSite: [''],
      projectManager: [''],
      commitManager: [''],
      depreciationManager: [''],
      commitQuarter: [''],
      requestDate: ['']
    })
  }

  ngOnInit() {
    this.isadmin = (this.common.getUserRole()) == 'Admin';
    this.showGrid = false;
    this.capexSearchDetails = null;
    this.expandedColumns = {};

    //dont need datakey for requestType and status
    this.filters = [
      { filterKey: 'requestType'},
      { filterKey: 'status'},
      { filterKey: 'ltpPorNumber', dataKey: 'LTP#' },
      { filterKey: 'planner', dataKey: 'Planner' },
      { filterKey: 'section', dataKey: 'Section' },
      { filterKey: 'requestingSite', dataKey: 'Site' },
      { filterKey: 'projectManager', dataKey: 'Project Manager Email' },
      { filterKey: 'commitManager', dataKey: 'Commit Cost Center Manager' },
      { filterKey: 'depreciationManager', dataKey: 'Depreciation Cost Center Manager' },
      { filterKey: 'commitQuarter'},
      { filterKey: 'requestDate'}
    ]

    // testing code start
    this.columnHeaders = [
      {
        header: 'headerCheckbox',
        expandColumns: [''] 
      },  
      { 
        header: 'hide1', 
        expandColumns: ['LTP#'] 
      },
      { 
        header: 'hide2', 
        expandColumns: ['LTP Status'] 
      },
      { 
        header: 'hide3', 
        expandColumns: ['POR#'] 
      },
      { 
        header: 'hide4', 
        expandColumns: ['POR Status'] 
      },
      { 
        header: 'hide5', 
        expandColumns: ['CAP#'] 
      },
      { 
        header: 'hide6', 
        expandColumns: ['CAP Status'] 
      },
      { 
        header: 'hide7', 
        expandColumns: ['AMR#'] 
      },
      { 
        header: 'hide8', 
        expandColumns: ['Site'] 
      },
      { 
        header: 'hide9', 
        expandColumns: ['Building'] 
      },
      { 
        header: 'hide10', 
        expandColumns: ['LabFab Install Location'] 
      },
      { 
        header: 'hide11', 
        expandColumns: ['Company Code' ] 
      },
      { 
        header: 'hide12', 
        expandColumns: ['Region'] 
      },
      { 
        header: 'hide13', 
        expandColumns: ['Comments'] 
      },
      { 
        header: 'hide14', 
        expandColumns: ['Actual Commit Date'] 
      },
      { 
        header: 'WBS', 
        expandColumns: ['WBS#', 'WBS Status', 'WBS Amount', 'Receiving WBS#', 'Receiving WBS Status', 'Receiving WBS Amount', 'Project Start Date (Date WBS Received)'] 
      },
      { 
        header: 'POR Info', 
        expandColumns: ['Tool Name/Description', 'Project Type', 'Planner', 'Project Manager Email', 'Justification', 'Impact of Not Doing', 'Program Driver', 'Category', 'Rollup', 'Businesss Unit', 'Build Site'] 
      },
      { 
        header: 'Depreciation', 
        expandColumns: ['Depreciation Cost Center Manager', 'Depreciation Cost Center', 'Depreciation Org - L2', 'Depreciation  Org - L3', 'Depreciation Org - L4', 'Depreciation Section', 'Depreciation MRU', 'Depreciation Sub-MRU'] 
      },
      { 
        header: 'Asset', 
        expandColumns: ['# of Assets', 'Asset Useful Life (yrs)' ] 
      },
      { 
        header: 'Commit', 
        expandColumns: ['Commit Cost Center', 'Commit Org - L2', 'Commit Org - L3', 'Commit Org - L4', 'Commit Section', 'Commit Section', 'Commit MRU', 'Commit Sub-MRU' ] 
      },
      { 
        header: 'Schedule Information', 
        expandColumns: ['Down Payment (Commit) Qtr', 'Delivery (RTE) Qtr', 'Acceptance (RTO) Qtr', 'Final Payment  Qtr', 'Asset Setup Due Date (2 qtrs from RTO)', 'Current Asset Setup Due Date (adjusted from the Original)', 'Actual Asset Setup Date' ] 
      },
      { 
        header: 'Finance Information', 
        expandColumns: ['Item Cash Flow Method', 'Item Cash Flow PO Terms', 'NRE Cash Flow Method', 'NRE Cash Flow Parameters', 'Total Cost ($K)', 'NRE Total Cost ($K)', 'Additional Cost ($K)', 'Item Cost ($K)', 'Hookup - Capitalized Install Costs ($K)', 'Hookup - Expensed Install Costs ($K)', 'Eng NRE Cost Location', 'Engr NRE (FTE Days)', 'Eng NRE Cost ($K)', 'Tech NRE Cost Location', 'Tech NRE (FTE Days)', 'Tech NRE Cost ($K)', 'Months Overdue', 'Est TTL Spend', 'PO Commits', 'PO Invoiced', 'Total JV', 'Total Cash Flow', 'Recon Total'] 
      },
      { 
        header: 'NRE', 
        expandColumns: ['NRE Charged', 'Remaining NRE', 'Est Remaining Cash Flow']
      },
      { 
        header: 'Quater', 
        expandColumns: ['Q1', 'Q2', 'Q3', 'Q4', 'Current Qtr NRE', 'Actual QTD (23Q1)', '23Q1', '23Q2', '23Q3', '23Q4']
      },
      // Add other columns similarly
    ];

    this.columnHeaders.forEach(column => {
      this.expandedColumns[column.header] = false;
  });

  this.columnNames = this.columnHeaders.map(column => column.header);
    // end

    this.http.get('http://localhost:5000/read_excel',  {responseType: 'text'}).subscribe(data => {
      data = data.replace(/NaN/g, "null");
      this.excel_data = JSON.parse(data);
      this.dataSource = this.excel_data;
      console.log(this.excel_data);
    }, error => {
      console.error(error);
    });
  }

  selectAllNewToggle(event: any) {
    // Implement select all logic here
  }

  submitNewRequest(){
    this.router.navigate(['/capex/capexDetails'], {state: {action: 'Submit'}});
  }

  convertRecords(){

  }

  showLtpPorNumber(){
    this.filteredLTPPORNumber = this.searchRecords.controls['ltpPorNumber'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.ltpPorNumberList.filter(option => option.toLowerCase().includes(filterValue));
  }

  onEmptyOptions(event: MatOptionSelectionChange, formName: string){
    console.log("Status Clicked");
    // console.log("Planner Clicked", event);
    // let selectedPlanners = event.value;
    // this.plannerDataSource = this.excel_data.filter((item: any) => selectedPlanners.includes(item['Planner']));
    // console.log("Selected Planner", this.dataSource );
    this.valueChange(formName);
  }

  onSelectChange(event: MatSelectChange, formName: string){
    console.log("Planner Clicked", event);
    let selectedPlanners = event.value;
    this.plannerDataSource = this.excel_data.filter((item: any) => selectedPlanners.includes(item['Planner']));
    console.log("Selected Planner", this.dataSource );
    //this.valueChange(formName);
    if( this.searchRecords.controls['planner'].value && formName == 'section'){
      return
    }
    else{
      this.valueChange(formName);
    }
  }

  valueChange(formName: any){
    console.log("Form Value", formName);
     // Clear other fields
     this.filters.forEach((otherFilter: any) => {
      if(otherFilter.filterKey != 'status' && otherFilter.filterKey != 'ltpPorNumber'){
        this.status = [];
        this.ltpPorNumberList = [];
      }
      if(otherFilter.filterKey != formName){
        this.searchRecords.controls[otherFilter.filterKey].setValue('');
      }
    })

  }

  toggleSelectAll(checked: boolean){
    console.log("Checked", checked);
    this.showExportToExcel = checked
    this.dataForWorksheet = this.dataSource
    if (Array.isArray(this.dataSource)) {
      this.dataSource.forEach((row: any) => {(row['selected'] = checked)});
    } else {
      console.error("dataSource is not an array.");
    }
  }

  toggleRowSelection(row: any, checked: boolean){
    console.log("Row Selected", row);  
    row['selected'] = checked;
    this.dataSource = [...this.dataSource]
    this.dataForWorksheet = this.dataSource.filter((item: any) => item['selected'] == true);
    this.showExportToExcel = this.dataSource.some((ele:any) => ele.selected == true)
  }

  toggleColumn(column: string) {
    this.expandedColumns[column] = !this.expandedColumns[column];
}

  collapse(){
    //this.displayedColumns = [...this.initiallyVisibleColumns]
    for (let key in this.expandedColumns) {
      this.expandedColumns[key] = false;
    }
  }

  exportExcel() {
    this.loadingExport = true;
    this.common.loader.next(true);
    for(let key in this.columnHeaders){
      let cleanedExpandColumns = this.columnHeaders[key]['expandColumns'].map(column => column.replace(/[\$\[\]"]/g, '')).filter(col => col != "" && col != null);
      this.columnOrder.push(...cleanedExpandColumns);
    }
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Pending Approval');
    let headerRow = worksheet.addRow(this.columnOrder);
    headerRow.eachCell((cell: any) => {
      cell.font = { bold: true };
    });
    for (let x1 of this.dataForWorksheet) {
      let x2 = this.columnOrder;
      let temp: any = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
      let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'LTP POR Records.xlsx');
    });
    this.loadingExport = false;
    this.common.loader.next(false);
  }

  recordHistory(row: any) {
    // Implement your first action here...
  }

}
