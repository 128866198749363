<nav class="navbar navbar-expand-sm shadow-sm fixed-top bg-white">
    <!-- Brand -->
    <div class="navbar-toggler d-block pl-0">
        <a href="javascript:void(0)" id="msbo" (click)="toggleSidebar()"><i class="ic fa fa-bars"></i></a>
    </div>
    <a class="navbar-brand" href="home">
        <span class="font-weight-bold mr-2">AMD -</span>
        <span class="small text-dark">{{envName}} {{version}}</span>
    </a>
    <!-- Toggler/collapsibe Button -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon h-auto"><i class="ic fa fa-bars"></i></span>
    </button>

    <!-- Navbar links -->
    <div class="collapse navbar-collapse" style="display: flex; align-items: center;" id="collapsibleNavbar">
        <span class="ml-auto mr-3"><i class="fas fa-user"></i> {{UserName}} </span>
        <span class="font-weight-bold mr-3">{{userRole}}</span>
        <!-- Testing CAPEX-Reshu -->
        <!-- <span class="ml-auto mr-3">Logged in : {{UserName}} 
            <mat-select [(value)] = "userRole" (selectionChange)="onRoleChange($event)" style="width: 100px;">
                <mat-option value="Admin">Admin</mat-option>
                <mat-option value="User">User</mat-option>
                <mat-option value="Requester">Requester</mat-option>
                <mat-option value="Planner">Planner</mat-option>
            </mat-select>
        </span> -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link btn btn-primary" href="javascript:void(0)"  (click)="logOut()"><i class="fas fa-power-off"></i> Logout</a>
            </li>
        </ul>
    </div>
</nav>