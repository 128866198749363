<app-common-assessment-form [questions]="this.QAArray" [showAssessmentPopup]="assessmentPopupClick" (updatedTableData) = "emitUpdatedTableData($event)"></app-common-assessment-form>

<div class="spinLoader" *ngIf="loading">
  <i class="fa fa-spinner fa-spin"></i>&nbsp;
  <span>Fetching Data...</span>
</div>
<mat-spinner *ngIf="submitLoading" diameter="20"></mat-spinner>
<div class="container col-12" style="margin:2%; padding-bottom: 1%;" [hidden] = "this.assessmentPopupClick">
  <div class="form-group appDetailSearch text-center">
    <button *ngIf="this.showBtn" class="btn btn-primary" style="margin-left: 1%"
      (click)="save(this.FAOriginalName)">Save Draft
    </button>
    <button *ngIf="this.showBtn" class="btn btn-primary" style="margin-left: 1%"
      (click)="submitAssessment(this.FAOriginalName)">Submit for Final Review
    </button>
    <button class="btn btn-primary" style="margin-left: 1%" (click)="back()">Back
    </button>
    <button *ngIf="this.showBtn" class="btn btn-primary" style="margin-left: 1%" 
      [disabled] = "this.disableCancelBtn" (click)="cancel()">Cancel
    </button>
    <button *ngIf="this.showBtn" id="cancelBtn" class="btn btn-primary" style="margin-left: 1%" 
      [disabled] = "this.disableResetBtn" (click)="reset()">Reset
    </button>
    <button class="btn btn-primary" style="margin-left: 1%" (click)="help()">Help
    </button>
    <button *ngIf="this.loggedInUser == 'reshu.jain@hp.com' " class="btn btn-primary" style="margin-left: 1%" (click)="cancel()">Delete
    </button>
    
  </div>
</div>
