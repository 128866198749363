<!-- For non-Pro Driver Form -->
<div class="editable-dialog" *ngIf="!isProDriverForm">
  <div class="dialog-header" *ngIf="editFlag">
    <h2 mat-dialog-title>Edit Cost Center</h2>
  </div>
  <div class="dialog-header" *ngIf="!editFlag">
    <h2 mat-dialog-title>Add Cost Center</h2>
  </div>

  <div class="dialog-content">
    <div class="editable-form">
      <form [formGroup]="formData" class="form-grid">
        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>Cost Center Code</mat-label>
            <input matInput formControlName="Cost Center Code">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Owner</mat-label>
            <input matInput formControlName="Cost Center Owner">
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>Description</mat-label>
            <input matInput formControlName="Cost Center Description">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Company Code</mat-label>
            <input matInput formControlName="Company Code">
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>Cost Center Name</mat-label>
            <input matInput formControlName="Cost Center Name">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Region</mat-label>
            <input matInput formControlName="Region">
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>MRU Code</mat-label>
            <input matInput formControlName="MRU Code">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Sub MRU</mat-label>
            <input matInput formControlName="Sub MRU">
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>Level 2</mat-label>
            <input matInput formControlName="Level 2">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Level 3</mat-label>
            <input matInput formControlName="Level 3">
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>Level 4</mat-label>
            <input matInput formControlName="Level 4">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Section</mat-label>
            <input matInput formControlName="Section">
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>ExpType</mat-label>
            <input matInput formControlName="ExpType">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Commit</mat-label>
            <mat-select formControlName="Commit">
              <mat-option [value]=true>True</mat-option>
              <mat-option [value]=false>False</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row">
          <!-- <mat-form-field appearance="fill">
          <mat-label>Inactive</mat-label>
          <input matInput formControlName="Inactive">
        </mat-form-field> -->

          <mat-form-field appearance="fill">
            <mat-label>Inactive</mat-label>
            <mat-select formControlName="Inactive">
              <mat-option [value]=true>True</mat-option>
              <mat-option [value]=false>False</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Depreciation</mat-label>
            <mat-select formControlName="Depreciation">
              <mat-option [value]=true>True</mat-option>
              <mat-option [value]=false>False</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div class="button-container">
          <button mat-raised-button color="primary" (click)="onSave()">Save</button>
          <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
        </div> -->
        <div class="button-container">
          <div class="form-group">
            <input type="button" class="btn btn-primary form-control" value="Save" (click)="onSave()" />
          </div>
          <div class="form-group">
            <input type="button" class="btn btn-primary form-control" value="Cancel" (click)="onCancel()" />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- For Pro Driver Form -->
<div class="editable-dialog" *ngIf="isProDriverForm">
  <div class="dialog-header">
    <h2 mat-dialog-title>Add Program Driver</h2>
  </div>

  <div class="dialog-content">
    <div class="editable-form">
      <form [formGroup]="formData" class="form-grid">
        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>Business Unit</mat-label>
            <input matInput formControlName="bUnit">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Engr NRE Loc</mat-label>
            <input matInput formControlName="ENRELoc">
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>Category</mat-label>
            <input matInput formControlName="category">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Program Driver</mat-label>
            <input matInput formControlName="programDriver">
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>Roll Up</mat-label>
            <input matInput formControlName="rollUp">
          </mat-form-field>

          <!-- <mat-form-field appearance="fill">
            <mat-label>Inactive</mat-label>
            <input matInput formControlName="inactive">
          </mat-form-field> -->
          <mat-form-field appearance="fill">
            <mat-label>Inactive</mat-label>
            <mat-select formControlName="Inactive">
              <mat-option [value]="true">True</mat-option>
              <mat-option [value]="false">False</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>Level 3</mat-label>
            <input matInput formControlName="level">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Tech NRE Loc</mat-label>
            <input matInput formControlName="techNreLoc">
          </mat-form-field>

        </div>

        <!-- <div class="button-container">
          <button mat-raised-button color="primary" (click)="onPDSave()">Save</button>
          <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
        </div> -->
        <div class="button-container">
          <div class="form-group">
            <input type="button" class="btn btn-primary form-control" value="Save" (click)="onPDSave()" />
          </div>
          <div class="form-group">
            <input type="button" class="btn btn-primary form-control" value="Cancel" (click)="onCancel()" />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>