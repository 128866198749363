<div class="text-center">
  <button class="btn btn-primary" (click)="Download()">
    <i class="fa fa-download"></i> &nbsp;&nbsp;Download Report
  </button>
</div>
<br>

<!-- <div class="col-md-12" *ngIf="loading else loaded">
    <mat-spinner diameter="20"></mat-spinner>
    <span>Loading Reports...</span>
</div> -->

<div class="row">
  <!-- AMD DATA Section -->
  <div class="col-7">
    <h5 class="text-danger text-center"><strong><u>AMD DATA</u></strong></h5>
    <div class="table-container">
      <table id="dtHorizontalExample" class="table table-striped table-responsive-md table-hover btn-table bs-select">
        <thead>
          <tr>
            <th scope="col">SL NO</th>
            <th scope="col">LOCATION</th>
            <th scope="col">LABNAME</th>
            <th scope="col">ASSET OWNER</th>
            <th scope="col">ASSET NUMBER</th>
            <th scope="col">ASSET DESCRIPTION</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of buildings; let i = index">
            <th scope="row">{{[i+1]}}</th>
            <td>{{ data.Location }}</td>
            <td>{{ data.Labname }}</td>
            <td>{{ data.Owner }}</td>
            <td>{{ data.PartitionKey }}</td>
            <td>{{ data.Description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- LABFAB DATA Section -->
  <div class="col-5">
    <h5 class="text-danger text-center"><strong><u>LABFAB DATA</u></strong></h5>
    <div class="table-container">
      <table id="excel-table" class="table table-striped table-responsive-md table-hover btn-table bs-select">
        <thead>
          <tr>
            <th scope="col">SL NO</th>
            <th scope="col">LOCATION</th>
            <th scope="col">LABNAME</th>
            <th scope="col">ASSET OWNER</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of labdata; let i = index">
            <th scope="row">{{[i+1]}}</th>
            <td>{{ data.BUILDING }}</td>
            <td>{{ data.LABFAB }}</td>
            <td>{{ data.Owner}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>