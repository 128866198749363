
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable()
export class AuthGuard {


  constructor(private common: CommonService, private router: Router) {
  }

  // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   if (localStorage.getItem('user')) {
  //     return true;
  //   }
  //   this._router.navigate(['login'], { queryParams: { returnUrl: state.url } });
  //   return false;
  // }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree> {
    // if (localStorage.getItem('returnUrl') != '/godzilla/home' && state.url != '/godzilla/home') {
    //   localStorage.setItem('returnUrl', state.url)
    // }
    localStorage.setItem('returnUrl', 'capex')
    let userAuthn = await this.common.userAuth()
    if (userAuthn) {
      return userAuthn
    } else {
      this.router.navigateByUrl('login');
      return false
    }
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

}
