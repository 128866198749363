import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { assetDetails } from 'src/app/models/assetDetails';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-fixed-asset-verification',
  templateUrl: './edit-fixed-asset-verification.component.html',
  styleUrls: ['./edit-fixed-asset-verification.component.scss']
})
export class EditFixedAssetVerificationComponent implements OnInit{

  company_Code!: string;
  assetid!: string;
  UserName: any;
  minDate!: Date;
  //assetdetails1: FormGroup;
  submitted = false;
  editable = true;
  loading = true;
  isReadOnly = true;
  loaded : any
  textvalue!: String
  assetnumber! : String
  cstcntr! : String
  newcstcntr!: String
  dataCount: any
  assetClassCode:any
  transferstatus:any
  tempassetclass:any
  assetDetails = new assetDetails();
  formControlDate = new FormControl();
  formControlDate1 = new FormControl();
  formControlDate2 = new FormControl();
  formControlDate3 = new FormControl();
  retirementType:any;
  tempRetirementType:any;
  userRole = this.common.getUserRole();

    assetdetails1: FormGroup = this.fb.group({
      //Company_Code:[''],
      Main_Asset_Number:['', Validators.required],
      Change_Status:[''],
      Change_Type:[''],
      Asset_Description:['', Validators.required],
      Additional_Description:['',Validators.required],
      Serial_Number:['', Validators.required],
      Asset_Supplementary_Inventory_Specifications_Name:['', Validators.required],
      Asset_Location:['',Validators.required],
      Asset_Class_Code:[''],
      New_Asset_Class_Code:[''],
      New_Owner:[''],
      Region:[''],
      Text:[''],
      Retirement_Date:[''],
      New_LabFab:[''],
      New_Building:[''],
      Cost_Center_Code:[''],
      New_Cost_Center_Code:[''],
      Retirement_Type:[''],
      Cost_Object_Manager_Email_ID:[''],
      New_Cost_Manager:[''],
      Retirement_Status:[''],
      Comment:[''],
      INSERT_DATETIME: [''],
      MODIFIED_DATETIME: [''],
      MODIFIED_Username: [''],
      Company_Code: [''],
    });
    constructor(
      private common: CommonService,
      private activatedRoute: ActivatedRoute,
      private fb: FormBuilder,
      private route: Router,
      private location: Location,
      private toastr: ToastrService,
      
    ) {
       this.common.bannerData.next({ title: 'AMD', subtitle: 'Edit Data For a Fixed Asset' });
    }

    ngOnInit() {
      this.minDate = new Date();
      this.activatedRoute.queryParams.subscribe(params => {
        this.assetid = params['assetid'];
        this.getassedtdetailsbyid(this.assetid);
      });
    }

    prevent(event:any){
      event.preventDefault();
    }
   
    ChangeText(event:any){
     this.cstcntr = this.assetdetails1.get('Cost_Center_Code')?.value
     this.newcstcntr = this.assetdetails1.get('New_Cost_Center_Code')?.value
     if(this.newcstcntr != "" && this.newcstcntr != "NULL"){
      this.textvalue = "TR-FR-" + (this.cstcntr).substring(0,4) + "-" + this.assetnumber + "-TO-" + (this.newcstcntr).substring(0,4)
      console.log(this.textvalue)
      this.assetdetails1.get('Text')?.setValue(this.textvalue);
     }
     else{
      this.assetdetails1.get('Text')?.setValue("");
     }
   }

    get formBuilder() { 
      return this.assetdetails1.controls; 
    }

  getassedtdetailsbyid(assid: string) {
      this.common.getAPI('amd-get-assetbyid/' + assid).subscribe({
        next: (data: any) => {
          console.log(data[0]);
          //this.dataCount = data[0].length
          //console.log(this.dataCount)
          this.assetClassCode = data[0]['Asset_Class_Code']
          this.transferstatus = data[0]['Transfer/Retirement_Status']
          this.tempassetclass = data[0]['New_Asset_Class_Code']
          this.retirementType = data[0]['Retirement_Type']
          this.company_Code = data[0]['Company_Code']
          this.assetnumber = data[0]['Main_Asset_Number']
          //this.loading = false;
          this.common.loader.next(false);
          this.assetdetails1.get("MODIFIED_Username")?.setValue(data[0]['Last_Modified_User'])
          //console.log(data[0]['Transfer/Retirement_Status'])
          this.assetdetails1.get("Retirement_Status")?.setValue(data[0]['Transfer/Retirement_Status'])
          this.assetdetails1.get("Comment")?.setValue(data[0]['Asset_Comment'])
          this.assetdetails1.patchValue(data[0]);
          this.assetdetails1.get("Retirement_Status")?.setValue(data[0]['Transfer/Retirement_Status'])
          this.formControlDate = new FormControl(new Date(moment(data[0]['Retirement_Date'], 'MM-DD-YYYY').format('YYYY-MM-DD')));
          this.formControlDate1 = new FormControl(new Date(moment(data[0]['Depreciation_Terms_Start_Date'], 'MM-DD-YYYY').format('YYYY-MM-DD')));
          this.formControlDate2 = new FormControl(new Date(moment(data[0]['Asset_Value_Date_of_the_First_Posting_Supplementary_Text'], 'MM-DD-YYYY').format('YYYY-MM-DD')));
          this.formControlDate3 = new FormControl(new Date(moment(data[0]['Asset_Last_Inventory_Date'], 'MM-DD-YYYY').format('YYYY-MM-DD')));
          if(this.userRole! = "Admin") {
            this.editable = false;
            this.formControlDate.disable();
            this.formControlDate1.disable();
            this.formControlDate2.disable();
            this.formControlDate3.disable();
        }
        if (this.tempassetclass == "NULL"){
          this.assetdetails1.get('New_Asset_Class_Code')?.setValue("")
        }
        if(data[0]['Transfer/Retirement_Status']=="NULL"){
          this.assetdetails1.get("Retirement_Status")?.setValue("")
        }
      },
      error: (error) => {
        this.common.loader.next(false);
        this.common.show('error', 'Something went wrong', error.message, error.status);
      }
    });
  

    this.assetdetails1.get('New_Cost_Center_Code')?.valueChanges.subscribe(data => {
      if((data=="NULL" || data=="")&&(["NULL",""].includes(this.assetdetails1.get('New_Building')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Cost_Manager')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_LabFab')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Owner')?.value))&&(["NULL",""].includes(this.assetdetails1.get('Retirement_Type')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Asset_Class_Code')?.value))){
        console.log("empty")
        this.assetdetails1.get('Retirement_Status')?.setValue("");
      } else{
        this.assetdetails1.get('Retirement_Status')?.setValue("Pending Analyst Review");
      }
    });
    this.assetdetails1.get('New_Building')?.valueChanges.subscribe(data => {
      if((data=="NULL" || data=="")&&(["NULL",""].includes(this.assetdetails1.get('New_Cost_Center_Code')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Cost_Manager')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_LabFab')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Owner')?.value))&&(["NULL",""].includes(this.assetdetails1.get('Retirement_Type')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Asset_Class_Code')?.value))){
        this.assetdetails1.get('Retirement_Status')?.setValue("");
      }else{
        this.assetdetails1.get('Retirement_Status')?.setValue("Pending Analyst Review");
      }
    });
    this.assetdetails1.get('New_Cost_Manager')?.valueChanges.subscribe(data => {
      if((data=="NULL" || data=="")&&(["NULL",""].includes(this.assetdetails1.get('New_Building')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Cost_Center_Code')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_LabFab')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Owner')?.value))&&(["NULL",""].includes(this.assetdetails1.get('Retirement_Type')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Asset_Class_Code')?.value))){
        this.assetdetails1.get('Retirement_Status')?.setValue("");
      }else{
        this.assetdetails1.get('Retirement_Status')?.setValue("Pending Analyst Review");
      }
    });
    this.assetdetails1.get('New_LabFab')?.valueChanges.subscribe(data => {
      if((data=="NULL" || data=="")&&(["NULL",""].includes(this.assetdetails1.get('New_Building')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Cost_Manager')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Cost_Center_Code')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Owner')?.value))&&(["NULL",""].includes(this.assetdetails1.get('Retirement_Type')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Asset_Class_Code')?.value))){
        this.assetdetails1.get('Retirement_Status')?.setValue("");
      }else{
        this.assetdetails1.get('Retirement_Status')?.setValue("Pending Analyst Review");
      }
    });
    this.assetdetails1.get('New_Owner')?.valueChanges.subscribe(data => {
      if((data=="NULL" || data=="")&&(["NULL",""].includes(this.assetdetails1.get('New_Building')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Cost_Manager')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_LabFab')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Cost_Center_Code')?.value))&&(["NULL",""].includes(this.assetdetails1.get('Retirement_Type')?.value))&&(["NULL",""].includes(this.assetdetails1.get('New_Asset_Class_Code')?.value))){
        this.assetdetails1.get('Retirement_Status')?.setValue("");
      } else{
        this.assetdetails1.get('Retirement_Status')?.setValue("Pending Analyst Review");
      }
    });
    
  }

  
  Changeassetclass(){
    this.tempassetclass = this.assetdetails1.get('New_Asset_Class_Code')?.value
    this.tempRetirementType = this.assetdetails1.controls['Retirement_Type'].value
    //console.log("New Asset Class Code")
    //console.log(this.tempassetclass)
    if(this.tempassetclass == this.assetClassCode){
      this.assetdetails1.get('Retirement_Status')?.setValue(this.transferstatus)
      //this.assetdetails1.get('Retirement_Status').enable();
    }
    else if(this.tempRetirementType == "NULL" && this.tempassetclass == "NULL"){
      this.assetdetails1.get('Retirement_Status')?.setValue(this.transferstatus)
    }
    else if(this.tempassetclass != this.assetClassCode){
      this.assetdetails1.get('Retirement_Status')?.setValue("Pending Analyst Review")
      //this.assetdetails1.get('Retirement_Status').disable();
    }
    else if(this.tempassetclass == ""){
      this.assetdetails1.get('Retirement_Status')?.setValue(this.transferstatus)
      //this.assetdetails1.get('Retirement_Status').enable();
    }
  }
  changeRetirement(){
    this.tempRetirementType = this.assetdetails1.controls['Retirement_Type'].value
    this.tempassetclass = this.assetdetails1.get('New_Asset_Class_Code')?.value
    if(this.tempRetirementType == this.retirementType){
      console.log("1")
      this.assetdetails1.get('Retirement_Status')?.setValue(this.transferstatus)
    }
    else if(this.tempRetirementType == ""){
      console.log("2")
      this.assetdetails1.get('Retirement_Status')?.setValue(this.transferstatus)
    }
    else if(this.tempRetirementType == "NULL" && this.tempassetclass == "NULL"){
      console.log("3")
      this.assetdetails1.get('Retirement_Status')?.setValue(this.transferstatus)
    }
    else if(this.tempRetirementType != this.retirementType){
      this.assetdetails1.get('Retirement_Status')?.setValue("Pending Analyst Review")
    }
  }

  onSubmit() {
    console.log(this.assetdetails1.value)
    this.submitted = true;
    if (this.assetdetails1.invalid) {
      window.scroll(0,0);
    } else {

    this.UserName = atob(this.common.currentUser);
    this.assetdetails1.get('MODIFIED_Username')?.setValue(this.UserName)
    this.assetdetails1.get('Company_Code')?.setValue(this.company_Code)

    Object.keys(this.assetdetails1.controls).forEach(key => {
      if(this.assetdetails1.get(key)?.value==""){
        this.assetdetails1.get(key)?.setValue("NULL");
      }
    });
    console.log("Data Going to submit")
    console.log(this.assetdetails1.value)
    this.common.postAPI('amd-add-asset-verification/', this.assetdetails1.value).subscribe({
      next: (data: any) => {
        console.log("Data submitteed")
        console.log(data.body)
        console.log(data.statusCode)
        if (data.statusCode == 200) {
          // alert('Data Submitted Successfully')
          this.toastr.success('Data Submitted Successfully', 'Success');
          this.route.navigate(['/assetRequestChange/data-verification']);
        }
      },
      error: (err: any) => {
        console.error('An error occurred:', err);
        this.toastr.error('Failed to submit data','Error')
        //alert('Failed to submit data');
      }
    });
  }
}

  public onChangeRetirementDate(event:any): void {
    this.assetdetails1.controls['Retirement_Date'].setValue(moment(event.value).format('MM/DD/YYYY').toString());
  }
  public onChangeDepreciationTermsStartDate(event:any): void {
    this.assetdetails1.controls['Depreciation_Terms_Start_Date'].setValue(moment(event.value).format('MM/DD/YYYY').toString());
  }
  public onChangeAssetValueDateoftheFirstPostingSupplementaryText(event:any): void {
    this.assetdetails1.controls['Asset_Value_Date_of_the_First_Posting_Supplementary_Text'].setValue(moment(event.value).format('MM/DD/YYYY').toString());
  }
  public onChangeAssetLastInventoryDate(event:any): void {
    this.assetdetails1.controls['Asset_Last_Inventory_Date'].setValue(moment(event.value).format('MM/DD/YYYY').toString());
  }
  close(){
    //this.route.navigate(['/asset']);
    this.location.back()
  }
}
