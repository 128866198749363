import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-cap-action-plan',
  templateUrl: './cap-action-plan.component.html',
  styleUrls: ['./cap-action-plan.component.css']
})
export class CapActionPlanComponent implements OnInit {
  PORNum: any;
  FAOriginalName: any;
  sendFormVar: any;
  assessmentAction: any = [];
  rtoAction: any = [];
  rteAction: any = [];
  commitAction: any = [];
  actionItemData: any = []
  reviewData: any;
  loading: boolean = true;
  capActionData: any;
  loggedInUser!: string;
  LabfabTeams: any;
  FA_Available: any = [];
  focusAreaName: any;
  SHArray: any;
  sendStatusUpdate!: { por_number: any; };
  filteredData: any = [];
  commonInfo: any;
  userRole: any;
  enableForm!: boolean;
  pageVisibility: any;
  mergedReviewData: any;
  mergedStakeholderRequester: any = [];
  emailDataDetails = {}
  today: Date = new Date();
  formattedToday: any;
  mergePopUpMsg: boolean = false;
  showCancelBtn: boolean = false;
  selectedQuestionId: any;
  allEntriesMeetCondition: any;
  assessmentCondition: any;

  constructor(private activatedRoute: ActivatedRoute, private common: CommonService,
    private dialog: MatDialog, private router: Router, private datePipe: DatePipe) {
    this.formattedToday = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    this.valueFromChildCapInfo = this.valueFromChildCapInfo.bind(this);
    this.save = this.save.bind(this);
  }

  ngOnInit(): void {
    this.PORNum = this.activatedRoute.snapshot.queryParams['POR']
    this.FAOriginalName = this.activatedRoute.snapshot.queryParams['FA']
    this.userRole = this.activatedRoute.snapshot.queryParams['actionPlan']
    this.loggedInUser = atob(this.common.currentUser);
    this.pageVisibility = this.activatedRoute.snapshot.queryParams['mode']
  }

  // async valueFromChildCapInfo(data: { [x: string]: any; }) {
  //   this.loading = true
  //   this.common.loader.next(true);
  //   console.log("child data in assement", data)
  //   this.commonInfo = data['commonInfo']
  //   this.sendFormVar = {
  //     POR: this.activatedRoute.snapshot.queryParams['POR']
  //   }
  //   this.common.postCapAPI('itg/cap-action-plan-itg', this.sendFormVar).subscribe({
  //     next: async response => {
  //       console.log('assessment API response', response)
  //       this.reviewData = response.body.Answers;
  //       await this.mergeActionItems(this.reviewData);
  //       console.log("Review Data", this.mergedReviewData);
  //       this.checkUserRole();
  //     },
  //     error: (error) => {
  //       console.error("Error occurred:", error);
  //       alert("Please connect with Asset Admin");
  //     }
  //   });
  // }

  // valueFromChildCapInfo = async (data: { [x: string]: any; }) => {
  //   this.loading = true;
  //   this.common.loader.next(true);
  //   console.log("child data in assessment", data);
  //   this.commonInfo = data['commonInfo'];
  //   this.sendFormVar = {
  //     POR: this.activatedRoute.snapshot.queryParams['POR']
  //   };
  
  //   this.common.postCapAPI('itg/cap-action-plan-itg', this.sendFormVar).subscribe({
  //     next: async (response) => {
  //       console.log('Assessment API response', response);
  //       if (response.body && response.body.Answers) {
  //         this.reviewData = response.body.Answers; // Set reviewData if Answers exist
  //         await this.mergeActionItems(this.reviewData); // Await the mergeActionItems function
  //         console.log("Review Data", this.mergedReviewData);
  //         this.checkUserRole(); // Check user role after merging
  //       } else {
  //         console.error("Response body is missing 'Answers'", response.body);
  //       }
  //     },
  //     error: (err) => {
  //       console.error('Error occurred:', err);
  //     }
  //   });
  // }

  valueFromChildCapInfo = async (data: { [x: string]: any; }) => {
    try {
      this.loading = true;
      this.common.loader.next(true);
      console.log("child data in assessment", data);
      this.commonInfo = data['commonInfo'];
      this.sendFormVar = {
        POR: this.activatedRoute.snapshot.queryParams['POR']
      };
  
      const response = await this.common.postCapAPI('itg/cap-action-plan-itg', this.sendFormVar).toPromise();
      
      console.log('Assessment API response', response);
      if (response.body && response.body.Answers) {
        this.reviewData = response.body.Answers;
        await this.mergeActionItems(this.reviewData);
        console.log("Review Data", this.mergedReviewData);
        this.checkUserRole();
      } else {
        console.error("Response body is missing 'Answers'", response.body);
      }
    } catch (err) {
      console.error('Error occurred:', err);
    } finally {
      this.loading = false;
      this.common.loader.next(false);
    }
  }
  

  async mergeActionItems(dataActionItem: any) {
    let dataReceived = dataActionItem
    this.mergedReviewData = dataReceived.flatMap((item: { SH_ResponseArray: any[]; Focus_Area: any; }) => {
      if (item.SH_ResponseArray && Array.isArray(item.SH_ResponseArray)) {
        return item.SH_ResponseArray?.map(mergedData => {
          mergedData.focusAreaName = item.Focus_Area
          return mergedData
        })
      }
      else {
        return []
      }
    })
  }

  async checkUserRole() {
    this.enableForm = (this.pageVisibility == 'edit') ? true : false
    if (this.userRole == "Stakeholder") {
      this.mergedReviewData.filter((ele: { [x: string]: any; questionId: string; }) => {
        if (ele.questionId?.startsWith('S') && ele['isCheckBoxClicked']) {
          this.filteredData.push(ele)
        }
      })
    }
    if (this.userRole == "Requester") {
      this.mergedReviewData.filter((ele: { [x: string]: any; questionId: string; }) => {
        if (ele.questionId?.startsWith('R') && ele['isCheckBoxClicked']) {
          this.filteredData.push(ele)
        }
      })
    }
    //disable Cancel button if there is only Assessment question checked & other section are unmarked
    this.showCancelBtn = this.filteredData.some((item: { answer: string; isActionPlanCheckBoxDone: boolean; }) =>
      item.answer != 'Assessment' && item.isActionPlanCheckBoxDone == true);

    this.loading = false
    this.common.loader.next(false);

    if (this.filteredData) {
      this.filteredData.filter((ele: { answer: string; }) => {
        if (ele.answer == 'Assessment') {
          this.assessmentAction.push(ele)
        }
        if (ele.answer == 'RTE') {
          this.rteAction.push(ele)
        }
        if (ele.answer == 'RTO') {
          this.rtoAction.push(ele)
        }
        if (ele.answer == 'Commit') {
          this.commitAction.push(ele)
        }
      });
    }
    this.actionItemData.push(this.assessmentAction, this.commitAction, this.rteAction, this.rtoAction)
    console.log('Print Data', this.actionItemData, this.rteAction, this.rtoAction)
  }

  checkBoxChange(ischecked: any, selectedData: { [x: string]: any; }) {
    selectedData['isActionPlanCheckBoxDone'] = ischecked
    this.showCancelBtn = this.filteredData.some((item: { answer: string; isActionPlanCheckBoxDone: boolean; }) =>
      item.answer != 'Assessment' && item.isActionPlanCheckBoxDone == true);
  }


  commentBoxVal(event: any, selectedData: { [x: string]: any; }) {
    console.log("Print commentBox", event, selectedData)
    selectedData['actionPlanComment'] = event.target.value
  }

  groupBy(arr: any[], key: string) {
    return arr.reduce((acc, obj) => {
      const propertyValue = obj[key];
      (acc[propertyValue] = acc[propertyValue] || []).push(obj);
      return acc;
    }, {});
  }

  async save() {
    //let formValidation = this.saveValidations();
    // console.log("formValidation",formValidation);
    const groupedData = this.groupBy(this.mergedReviewData, 'focusAreaName');
    this.filteredData = Object.entries(groupedData).map(([Focus_Area, SH_ResponseArray]) => {
      return { Focus_Area, SH_ResponseArray };
    });
    // if(formValidation){
    //   return
    // }
    // else{
    //cont form saving
    this.sendFormVar['SH_ResponseArray'] = this.filteredData
    this.sendFormVar['Status'] = 'Action Plan'
    this.common.postCapAPI('itg/cap-action-plan-itg', this.sendFormVar).subscribe({
      next: async data => {
        this.reviewData = data.body.Answers
        await this.mergeActionItems(this.reviewData);
      },
      error: (error: any) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    })
    this.openDialogMessage('Data has been saved', 'Save');
    //alert("Saved the data")
    //}
  }

  async submit() {
    let formValidation = this.saveValidations();
    let submitMessage
    console.log("formValidation", formValidation);
    const groupedData = this.groupBy(this.mergedReviewData, 'focusAreaName');
    this.filteredData = Object.entries(groupedData).map(([Focus_Area, SH_ResponseArray]) => {
      return { Focus_Area, SH_ResponseArray };
    });

    console.log("Filtered Data", this.filteredData);
    console.log('Print groupedData', groupedData);
    if (formValidation) {
      return
    }
    else {
      //cont form saving
      this.sendFormVar['SH_ResponseArray'] = this.filteredData
      this.sendFormVar['Status'] = 'Action Plan'

      //check whether if all assessment questions has been completed. If yes, show diff popup msg on submission
      //this.assessmentCondition = this.assessmentAction.every((record: { answer: string; disable: boolean; }) => record.answer == "Assessment" && record.disable == true)

      
      // // Filter assessment questions based on the selected action plan
      //Fix
      let filteredAssessmentAction;
      if (this.userRole === 'Requester') {
        filteredAssessmentAction = this.assessmentAction.filter((record: { questionId: string }) => record.questionId.startsWith('R'));
      } else if (this.userRole === 'Stakeholder') {
        filteredAssessmentAction = this.assessmentAction.filter((record: { questionId: string }) => record.questionId.startsWith('S'));
      }
      //Check whether all filtered assessment questions have been completed
      this.assessmentCondition = filteredAssessmentAction.every((record: { answer: string; disable: boolean; }) => record.answer == "Assessment" && record.disable == true);

      //check whether all action plan items are marked done      
      this.allEntriesMeetCondition = this.actionItemData.every((object: any[]) => {
        return object.every(innerelement => {
          return innerelement.hasOwnProperty('isActionPlanCheckBoxDone') && innerelement['isActionPlanCheckBoxDone'] === true;
        });
      });

      if (this.mergePopUpMsg) {
        submitMessage = 'Data has been Submitted' + '\n' + 'but, To Proceed with WBS, Please complete Assessment of Stakeholder & Requester Action plan'
      }
      else if (this.allEntriesMeetCondition) {
        submitMessage = 'Congratulations all action items are complete. The Asset Administrator will initiate the Asset Setup';
        this.disableActionPlanCheckBox();
      }
      else if (this.assessmentCondition) {
        submitMessage = 'Data has been submitted. Please continue to work through the action plan.' + '\n' + 'The Asset Administrator has already been notified to initiate the WBS';
        this.disableActionPlanCheckBox();
      }
      else {
        //submitMessage = 'Data has been Submitted' + '\n' + 'Proceed with WBS'; 
        submitMessage = 'Data has been submitted. Please continue to work through the action plan.' + '\n' + 'The Asset Administrator has been notified to initiate the WBS';
        this.disableActionPlanCheckBox();

        // this.sendFormVar['SH_ResponseArray'].forEach((object: { [x: string]: any[]; }) => {
        //   object['SH_ResponseArray'].forEach(innerobject => {
        //     if (innerobject.answer == 'Assessment') {
        //       innerobject.disable = true
        //     }
        //   })
        // })
        console.log("Print SH Response Array", this.sendFormVar['SH_ResponseArray'])
      }

      this.common.postCapAPI('itg/cap-action-plan-itg', this.sendFormVar).subscribe({
        next: async data => {
          this.reviewData = data.body.Answers
          await this.mergeActionItems(this.reviewData);
        },
        error: (error: any) => {
          console.error("Error occurred:", error);
          alert("Please connect with Asset Admin");
        }
      });
      this.openDialogMessage(submitMessage, "Submit");
      //alert("Saved the data")
    }
  }

  disableActionPlanCheckBox(){
    this.sendFormVar['SH_ResponseArray'].forEach((object: { [x: string]: any[]; }) => {
      object['SH_ResponseArray'].forEach(innerobject => {
        if (this.userRole === 'Requester' && innerobject.questionId.startsWith('R')) {
          if ( innerobject.isActionPlanCheckBoxDone) {
            innerobject.disable = true;
          }
        } else if (this.userRole === 'Stakeholder' && innerobject.questionId.startsWith('S')) {
          if (innerobject.isActionPlanCheckBoxDone) {
            innerobject.disable = true;
          }
        }
      });
    });
  }

  cancel() {
    let updatedMergedData = this.mergedReviewData.map((ele: { questionId: string; isActionPlanCheckBoxDone: boolean; answer: string; disable: boolean; actionPlanComment: string; }) => {
      if ((this.userRole == 'Requester' && ele.questionId.startsWith('R')) ||
        (this.userRole == 'Stakeholder' && ele.questionId.startsWith('S'))) {
        if ('isActionPlanCheckBoxDone' in ele && ele.isActionPlanCheckBoxDone && !(ele.answer == 'Assessment' && ele.disable == true)) {
          ele.isActionPlanCheckBoxDone = false
          ele.actionPlanComment = ''
          ele.disable = false
        }
      }
      return ele;
    })

    const groupedData = this.groupBy(updatedMergedData, 'focusAreaName');
    this.filteredData = Object.entries(groupedData).map(([Focus_Area, SH_ResponseArray]) => {
      return { Focus_Area, SH_ResponseArray };
    });
    this.sendFormVar['SH_ResponseArray'] = this.filteredData;
    this.sendFormVar['Status'] = 'Action Plan';
    this.common.postCapAPI('itg/cap-action-plan-itg', this.sendFormVar).subscribe({
      next: async (data: { body: { Answers: any; }; }) => {
        // this.reviewData = data.body['Answers']['SH_ResponseArray']
        console.log("before Cancel", data)
        this.reviewData = data.body.Answers
        await this.mergeActionItems(this.reviewData);
        console.log("After cancel", this.mergedReviewData)
      },
      error: (error: any) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
    this.openDialogMessage('Responses has been reverted', "Cancel");
  }

  back() {
    this.router.navigate(['cap/focusArea'], { queryParams: { POR: this.PORNum } })
    // this.router.navigate(['cap/stakeHolderReview'], {queryParams: {POR: this.PORNum, FA: this.FAOriginalName}})
  }

  // form checkbox validations
  saveValidations() {
    let verify = false;
    let reviewPopupMsg = '';
    let assessmentPopupMsg = '';
    this.reviewData.some((ele: { Status: string; }) => {
      if (!(ele.Status == 'Waive' || ele.Status == 'SH Review - Complete' || ele.Status == 'SH Review - Waive')) {
        // reviewPopupMsg = '--> Complete Stakeholder Review for all of the focus area.'; 
        reviewPopupMsg = `--> Please note, <b>ALL</b> Focus Area Review <b>MUST</b> be <b>Completed</b> before the POR can be setup as an Asset.`
        // this.openDialogMessage('Complete Stakeholder Review for all of the focus area');
      }
    })
    this.actionItemData.some((items: any[]) => {
      // verify = items.some((item) => {
      items.some((item) => {
        if ((item?.isActionPlanCheckBoxDone == undefined || item?.isActionPlanCheckBoxDone == false) && item?.answer == "Assessment") {
          // assessmentPopupMsg = '--> Select all the ' + item.answer + ' Done fields are mandatory.'
          assessmentPopupMsg = `--> Please note, <b>ALL</b> Action Items under the Assessment phase, <b>MUST</b> be marked <b>DONE</b> before the WBS funding may be requested.`;
          // this.openDialogMessage('Select all the ' + item.answer + ' Done fields are mandatory');
          //break the inner loop if condition satifices 
          // return true
        }
      })
    })

    if (reviewPopupMsg != '' || assessmentPopupMsg != '') {
      this.openDialogMessage('Please continue to work through the Action Plan until all items are marked Done.' + "\n" + reviewPopupMsg + "\n" + assessmentPopupMsg, "")
      verify = true
    }
    if (verify) {
      //break the outer current loop if inner loop satifices
      return verify
    }
    else {
      this.mergedReviewData.filter((ele: { [x: string]: any; questionId: string; }) => {
        if (ele.questionId?.startsWith('S') && ele['isCheckBoxClicked']) {
          this.filteredData.push(ele)
        }
      })

      //Below code is to check whether all SH & Requester under Assessment option has submitted the action item
      this.mergedReviewData.filter((ele: { [x: string]: any; questionId: string; answer: string; }) => {
        if (ele['isCheckBoxClicked'] && (ele.questionId?.startsWith('S') || ele.questionId?.startsWith('R'))) {
          if (ele.answer == "Assessment") {
            this.mergedStakeholderRequester.push(ele)
          }
        }
      });

      this.emailDataDetails = {
        por_number: this.PORNum,
        functionName: 'WBS',
        description: this.commonInfo['Description'],
        projectStartDate: this.formattedToday,
        projectEndDate: '',
        projectType: '',
        assetOwnerName: this.commonInfo['ProjectManager'],
        projectCostCenter: '',
        projectAssetId: '',
        assetDescLocation: '',
        assetAdminEmail: 'tom.mayes2@hp.com; krista.lively@hp.com',
        financeEmail: '',
        projectCost: this.commonInfo['TotalCost($K)'],
        status: 'Open',
        LabName: this.commonInfo['LabFab_Install_Location'],
        PrimaryRequester: this.commonInfo['RequesterName'],
        SecondaryRequester: this.commonInfo['SecondaryReq'],
        ToolName: this.commonInfo['ToolName']
      }
      const initiateWBSMail = this.mergedStakeholderRequester.every((element: { isActionPlanCheckBoxDone: boolean; }) => element.isActionPlanCheckBoxDone == true)
      if (initiateWBSMail) {
        // this.openDialogMessage('Proceed with WBS',"Success");
        this.mergePopUpMsg = false
        // this.emailDataDetails['disable'] = true
        //Uncomment
        this.common.postCapAPI('itg/cap-email-notification-itg', this.emailDataDetails).subscribe({
          next: (ele2: null) => {
            console.log('Print data', ele2);
            if (ele2 == null) {
              this.mergedReviewData.filter((ele: any) => {
                if (ele.answer == "Assessment") {
                  ele['disable'] = true
                }
              });
            }
          },
          error: (error: any) => {
            console.error("Error occurred:", error);
            alert("Please connect with Asset Admin");
          }
        });
        // alert('Proceed with WBS')
      }
      else {
        this.mergePopUpMsg = true
        //this.openDialogMessage('To Proceed with WBS, Please complete Assessment of Stakeholder & Requester Action plan', "PendingActionPlan");       
        // alert()
      }
      console.log('Print', this.mergedStakeholderRequester)
    }
    return verify
  }

  openDialogMessage(message: string, funcName: string) {
    let dialogRefSubmit
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = "multiline-text"
    dialogConfig.data = {
      message: message,
      tabletemplate: false
    }
    dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
    dialogRefSubmit.afterClosed().subscribe({
      next: result => {
        console.log("Result", result);
        if (funcName == 'Cancel') {
          window.location.reload();
        }
        if (funcName == 'Submit') {
          //Redirect to focus area table, instaed of page reloading, merge both while testing in future
          // window.location.reload();
          this.router.navigate(['cap/focusArea'], { queryParams: { POR: this.sendFormVar['POR'] } })
        }
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    })
  }

}
