import { Component } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  footerData: any;
  constructor(private common: CommonService) { }

  ngOnInit(): void {
    this.getFooterData();
  }
  
  async getFooterData()  {
   const data: any = await lastValueFrom(this.common.getText());
   this.footerData = data.footer;
 }
}
