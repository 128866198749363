import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-file-download-from-s4',
  templateUrl: './file-download-from-s4.component.html',
  styleUrls: ['./file-download-from-s4.component.css']
})
export class FileDownloadFromS4Component implements OnInit {
  @Input() dowloadItemFileList: any;
  @Input() ShowDeleteIcon: any; // To show or hide Delete icon
  @Input() Asset_Number: any;
  @Input() Sortkey!: string;

  @Input() tableName: any;
  // the attribute /colName in DB need to be updated with remaining Files after Deletion in DB
  @Input() DeletedFile_UpdateInDB_ColName: any

  progressPercent: number = 0;
  progressDisplay = false;
  DeletingFileLoading = false;
  DownloadingFileLoading = false;

  PreviewPath: any = {}
  constructor(private common: CommonService, private dialog: MatDialog,) { }

  ngOnInit(): void {
  }

  getPreviewStatus(document: any) {
    if (document.Name.includes(".pdf")) {
      if (document.IsPreviewable == 'Yes') {
        this.PreviewPath[document.Name] = document.Site + document.Path + "/" + document.Name;
        return true;
      }
    } else {
      let fileParts = document.Name.split('.')
      let fileExt = ''
      if (fileParts.at(-1) == 'Draft') {
        fileExt = fileParts.at(-2)
      }
      else if (fileParts.at(-2).includes('v')) {
        fileExt = fileParts.at(-3)
      }
      let docType = '.' + fileExt
      let searchData = document.Name.replace(docType, ".pdf")
      if (document.IsPreviewable == 'Yes') {
        this.PreviewPath[document.Name] = document.Site + document.Path + "/" + searchData;
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  fileDownload(data: any) {
    this.DownloadingFileLoading = true;
    this.progressPercent = 0;
    this.progressDisplay = true;
    //this.fromPoint = 500;
    let documentPath: any;
    let fileName = data.Name.split(".");

    let filepath = data['Path'] + "/" + data['Name']

    if (data['Site']) {
      let filepath = data['Site'] + data['Path'] + "/" + data['Name']
    }
    documentPath = { "filePath": filepath };
    if (documentPath !== undefined) {
      this.common.postAPI('amd-download-selectedfile-s3', documentPath).subscribe((resp: any) => {
        //switch(event.type){
        //  case HttpEventType.Response:
        if (resp.body != "") {
          let data = resp.body;
          const link = data

          var element = document.createElement('a');
          element.setAttribute('href', link);

          element.style.display = 'none';
          document.body.appendChild(element);
          element.setAttribute("target", "_blank");
          element.setAttribute("rel", "noopener");
          element.setAttribute("download", filepath);
          element.click();
          document.body.removeChild(element);
          this.DownloadingFileLoading = false;
        }
        //}

      });
    } else {
      this.progressDisplay = false;
      this.DownloadingFileLoading = false;
    }
  }


  //Selected File is deleted from the dowloadItemFileList list
  fileDelete(data: any, DeleteFileIndex: any) {

    this.DeletingFileLoading = true;
    this.progressPercent = 0;
    this.progressDisplay = true;
    let documentPath: any;
    let filepath = data['Path'] + "/" + data['Name']

    if (data['Site']) {
      let filepath = data['Site'] + data['Path'] + "/" + data['Name']
    }
    let copyListDocument = this.dowloadItemFileList;
    copyListDocument.splice(DeleteFileIndex, 1)
    documentPath = {
      'SortKey': this.Sortkey,
      'table': this.tableName,
      'updatedListDocuments': JSON.stringify(copyListDocument),
      "filePath": filepath,
      "Key": this.DeletedFile_UpdateInDB_ColName,
      'Asset_Number': this.Asset_Number,
      "Cost_Calculation_Worksheet_has_been_attached": copyListDocument.length == 0 ? false : true
    };
    if (documentPath !== undefined) {
      this.common.postAPI('amd-delete-file-from-s3-bucket', documentPath).subscribe((resp: any) => {
        if (resp.body.DeleteMarker) {
          let data = resp.body;

          // this.dowloadItemFileList.splice(DeleteFileIndex,1)
          this.DeletingFileLoading = false;
          this.openDialogMessage('File Deleted Successfully', 'Deleted');

        }
        else {
          this.openDialogMessage('Try after some times File Deleting ', 'Deleted');
        }
        //}

      });
    }
    else {
      this.progressDisplay = false;
      this.DeletingFileLoading = false;
    }
  }


  openDialogMessage(message: string, assetID: string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      message: message,
      tabletemplate: false
    }

    const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
    dialogRefSubmit.afterClosed().subscribe(result => {
      if (assetID == 'Deleted' && this.Sortkey != 'CAP') {
        window.location.reload()
      }
    });
  }
}
