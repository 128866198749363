import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ThemeSetting } from 'src/app/theme-settings/theme-setting';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  UserName = atob(this.common.currentUser);
  userRole = this.common.getUserRole();
  envName = environment.environmentName;
  version = environment.appVersion;

  constructor(public theme: ThemeSetting, private elRef: ElementRef, private common: CommonService,
    private router: Router) { }
  toggleSidebar() {
    this.theme.sidebar = !this.theme.sidebar;
    this.elRef.nativeElement = document.getElementsByTagName('body')[0];
    this.elRef.nativeElement.classList.toggle('slide')
  }

  logOut() {
    sessionStorage.setItem('logout', 'You have been logged out.')
    this.common.loggedOutMsg.next('You have been logged out.');
    localStorage.setItem('tokenValidity', 'expired');
    this.common.logout();
  }

  onRoleChange(event: any){
    this.common.postCapexAPI('capex-update-user-role-itg', { 'userId': this.UserName, 'userRole': event.value }).subscribe((data: any) => {
      if (data != undefined) {
        localStorage.setItem('userRole', btoa(data['body']));
        this.common.show('success', 'User Role Updated Successfully');
        this.userRole = data['Role']
        window.location.reload();
      //   this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      //     this.router.navigate([this.router.routerState.snapshot.url]);
      // });
      } else {
        this.common.show('error', 'User Role Not Updated');
      }
    });
  }

  
}
