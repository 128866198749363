import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './main/login/login.component';
import { AuthGuard } from './services/auth.gaurds';
import { AssetModule } from './main/AssetDetails/asset.module';
import { ReportModule } from '../app/main/report/report.module';
import { PageNotFoundComponent } from './main/page-not-found/page-not-found.component';
import { CapexModule } from '../app/main/Capex/capex.module';
import { OrganizationModule } from './main/organization/organization.module';
import { HomePageComponent } from './main/homePage/homePage.component';
import { CapModule } from './main/cap/cap.module';
import { HelpModule } from './main/help/help.module';
import { HomePageModule } from './main/homePage/home-page.module';
import { StakeholderModule } from './main/stakeholder/stakeholder.module';
import { UploadReportModule } from './main/upload-report/upload-report.module';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren: () => import('./main/homePage/home-page.module').then(m => HomePageModule)
      },
      {
        path: 'asset',
        loadChildren: () => import('./main/AssetDetails/asset.module').then(m => AssetModule)
      },
      {
        path: 'report',
        loadChildren: () => import('./main/report/report.module').then(m => ReportModule)
      },
      {
        path: 'capex',
        loadChildren: () => import('./main/Capex/capex.module').then(m => CapexModule)
      },
      {
        path: 'cap',
        loadChildren: () => import('./main/cap/cap.module').then(m => CapModule)
      },
      {
        path: 'stakeholder',
        loadChildren: () => import('./main/stakeholder/stakeholder.module').then(m => StakeholderModule)
      },
      {
        path: 'organization',
        loadChildren: () => import('./main/organization/organization.module').then(m => OrganizationModule)
      },
      {
        path: 'assetRequestChange',
        loadChildren:() => import('../app/main/AssetDetails/asset-routing.module').then(m => AssetModule),
        data: { extraParameter: 'asset' }
      },
      {
        path: 'help',
        loadChildren:() => import('./main/help/help.module').then(m => HelpModule),
        data: { extraParameter: 'help' }
      },
      {
        path: 'reportsupload',
        loadChildren:() => import('./main/upload-report/upload-report.module').then(m => UploadReportModule),
        data: { extraParameter: 'reportsupload' }
      }
    ]
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomePageComponent

  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
