<mat-card class="my-3 mat-elevation-z3 p-3" *ngIf="validUser">
    <div>
        <div style="display: flex; align-items: center;">
            <div style="margin-right: 10px;">
                <label for="formFile" class="form-label">Select a file to upload</label>
                <input #fileUploader class="form-control" type="file" id="formFile" accept=".xlsx, .xls" (change)="enableUploadButton($event)">
            </div>
            <div class="btnUpload">
                <label for="uploadButton" class="form-label">Click the button to upload the file</label>
                <button type="submit" class="form-control btn-primary " id="uploadButton" [disabled]="!showUploadFile"
                    (click)="onUpload($event)"><i class="fas fa-spinner fa-spin" *ngIf="loading"></i>
                    {{loading?'Preparing file for upload':'Upload File'}}
                </button>
                <div class="fileProgress" *ngIf="this.showProgressBar">
                    <div style="position:relative;font-size:13px">File upload is in progress...</div>
                    <div class="progress fieldBorderBottom fileProgressBar">
                        <div id="progressBar" class="progress-bar progress-bar-striped progress-bar-animated appColor"
                            role="progressbar" value="5" max="100" style="width:1%">
                            <span id="status" class="fileProgressSpan"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>
<div *ngIf="!validUser" class="text-center">
    <p>You do not have a permission to view this page</p>
</div>