
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { historyDetailsData } from './historyDetailsData'
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})

export class HistoryComponent implements OnInit {
  loading = true;
  historyDetailsArray: any = [];

  displayedColumns: string[] = ["Requester_ID", "Asset_Active_Listing", "Company_Code", "Original_Asset_Number", "Asset_Class_Code",
    "Asset_Description", "Owner", "Serial_Number", "LabFab_Name",
    "Building", "Ship_to_Customer_Name1", "Asset_Capitalization_Date", "Cost_Center_Code",
    "Cost_Object_Manager", "MRU_Code", "Profit_Center_Code", "Cost_Center_Hier_Desc_Level1",
    "Asset_Super_Number", "Depreciation_Terms_Start_Date", "Depreciation_Terms_Useful_Life_in_Months",
    "Acquisition_Value", "PE_Net_Book_Value", "PE_Depreciation_For_Posted_Period", "Period_Posted_Ordinary_Depreciation_Including_Value_Adjustments",
    "WBS_Element_Maintenance_Project", "Action_Plan_Status", "Is_Cost_Associated_To_Asset", "Estimated_Cost", "PickUp_Cost_to_move_asset_and_demo_utilities",
    "Is_Utilities_Need_Demo_in_Asset_Moved_From", "Demo_utilities_of_asset_Status", "planned_Completion_Date_Action_Plan", "Is_receiving_area_Prepped", "receiving_area_Prepped_Status",
    "Notifications_to_listed_tool_owner_Action_Plan",
    "Asset_Moved_Status_in_Action_plan", "Action_Plan_Email_Approvers_List",
    "INSERT_DATETIME", "MODIFIED_DATETIME", "LAST_MODIFIED_USER", "Change_Type", "Change_Status"];

  ELEMENT_DATA: historyDetailsData[] = [];
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  
  constructor(
    private common: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  ngOnInit(): void {
    this.getHistoryData();
  }
  applyFilter(filterValue: any) {
    // filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.target.value.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue.target.value;
  }
  getHistoryData() {
    this.loading = true;
    const data = { "start": 0, "end": 0, 'assetID': this.data?.assetID }
    this.common.postAPI('amd-history/', data).subscribe((res: any) => {

      this.loading = false;
      let arr1 = res.body.filter((item: { SortKey: string | string[]; }) => (item.SortKey.includes('AMDVersion')));
      let arr2 = res.body.filter((item: { SortKey: string; }) => (item.SortKey == 'S4'));

      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i]['Asset_Number'] === arr2[0]['Asset_Number']) {
          const loc: historyDetailsData = {
            Requester_ID: arr1[i]['Requestor ID'],
            Asset_Active_Listing: arr2[0]['Asset Active Listing'],
            Company_Code: arr1[i]['Company Code'],
            // Asset_Number: arr1[i]['Asset_Number'],
            Original_Asset_Number: arr2[0]['Original Asset Number'],
            Asset_Class_Code: arr1[i]['Asset Class Code'],
            Asset_Description: arr1[i]['Asset Description'],
            Owner: arr1[i]['Additional Description'],
            Serial_Number: arr1[i]['Serial Number'],
            LabFab_Name: arr1[i]['Asset Supplementary Inventory Specifications Name'],
            Building: arr1[i]['Asset Location'],
            Change_Status: arr1[i]['Change Status'],
            Change_Type: arr1[i]['Change Type'],
            Ship_to_Customer_Name1: arr2[0]['Ship-to Customer Name1'],
            Asset_Capitalization_Date: arr2[0]['Asset Capitalization Date'],
            Cost_Center_Code: arr1[i]['Cost Center Code'],
            Cost_Object_Manager: arr1[i]['Cost Object Manager Email ID'],
            MRU_Code: arr2[0]['MRU Code'],
            Profit_Center_Code: arr2[0]['Profit Center Code'],
            Cost_Center_Hier_Desc_Level1: arr2[0]['Cost Center Hier Desc Level1'],
            Asset_Super_Number: arr2[0]['Asset Super Number'],
            Depreciation_Terms_Start_Date: arr2[0]['Depreciation Terms Start Date'],
            Depreciation_Terms_Useful_Life_in_Years: arr2[0]['Depreciation Terms Useful Life in Years'],
            Depreciation_Terms_Useful_Life_in_Months: arr2[0]['Depreciation Terms Useful Life in Months'],
            Acquisition_Value: arr2[0]['Acquisition Value'],
            PE_Net_Book_Value: arr2[0]['PE Net Book Value'],
            PE_Depreciation_For_Posted_Period: arr2[0]['PE Depreciation For Posted Period'],
            Period_Posted_Ordinary_Depreciation_Including_Value_Adjustments: arr2[0]['Period Posted Ordinary Depreciation Including Value Adjustments'],
            WBS_Element_Maintenance_Project: arr2[0]['WBS Element Maintenance Project'],
            Action_Plan_Email_Approvers_List: arr1[i]['Action Plan Email Approvers List'],
            Action_Plan_Status: arr1[i]['Action Plan Status'],
            PickUp_Cost_to_move_asset_and_demo_utilities: arr1[i]['PickUp Cost to move asset and demo utilities'],
            Demo_utilities_of_asset_Status: arr1[i]['Demo utilities of asset Status'],
            Estimated_Cost: arr1[i]['estimatedCost'],
            planned_Completion_Date_Action_Plan: arr1[i]['planned Completion Date Action Plan'],
            receiving_area_Prepped_Status: arr1[i]['receiving area Prepped Status'],
            Is_Cost_Associated_To_Asset: arr1[i]['Is Cost Associated To Asset'],
            Is_Utilities_Need_Demo_in_Asset_Moved_From: arr1[i]['Is Utilities Need Demo in Asset Moved From'],
            Is_receiving_area_Prepped: arr1[i]['Is receiving area Prepped'],
            Notifications_to_listed_tool_owner_Action_Plan: arr1[i]['Notifications to listed tool owner Action Plan'],
            Asset_Moved_Status_in_Action_plan: arr1[i]['Asset Moved Status in Action plan'],
            INSERT_DATETIME: arr2[0]['InsertDm'],
            MODIFIED_DATETIME: arr1[i]['ModifyDm'],
            LAST_MODIFIED_USER: arr1[i]['Last Modified User']
          }
          this.historyDetailsArray.push(loc);
        }

      }
      this.ELEMENT_DATA = this.historyDetailsArray;
      this.dataSource = new MatTableDataSource<historyDetailsData>(this.ELEMENT_DATA);


      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });
  }

}
