import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadReportRoutingModule } from './upload-report-routing.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { UploadReportComponent } from './upload-report.component';
import { MaterialModule } from 'src/app/modules/material.module';


@NgModule({
  declarations: [UploadReportComponent],
  imports: [
    CommonModule,
    UploadReportRoutingModule,
    CommonModule,
    SharedModule,
    MaterialModule
  ]
})
export class UploadReportModule { }
