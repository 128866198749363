import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { StakeholderAssessmentComponent } from '../stakeholder-assessment/stakeholder-assessment.component';
import { AssessmentFormComponent } from '../assessment-form-functionality/assessment-form-functionality';
import { CommonService } from 'src/app/services/common.service';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';

@Component({
  selector: 'app-stake-holder-review',
  templateUrl: './stake-holder-review.component.html',
  styleUrls: ['./stake-holder-review.component.css']
})
export class StakeHolderReviewComponent implements OnInit {
  
  statusVal: any;
  PORData: any;
  fieldData: any;
  PORNum: any;
  focusArea!: string;
  arr=['Assessment', 'Commit', 'RTE', 'RTO']
  dropdownOptions: string[] = ['Phase_Selection(?)', 'Assessment', 'Commit', 'RTE', 'RTO', 'Setup'];
  focusAreaName: any;
  SH_QAArray: any;
  newQueArray: any= {};
  SHQuestionsArray1 = [];
  sendFormVar : any;
  loading!: boolean;
  SHAnswersArray: any;
  FAOriginalName: any;
  selectedOption: any;
  userResponse = []
  questionsArray!: { };
  Array1: any=[];
  showQuestions!: boolean;
  abc!: string[];
  selectedIndex: any;
  displayOptionList!: { name: string; checked: boolean; }[];
  hideActionPlan: string="None";
  popUpTxt!: string;
 commonInfo: any;
  markWaiveOption: boolean = false;
  disableButton: boolean = false;
  myForm!: FormGroup;
  // submitLoading = false;
  LabfabTeams: any;
  SHNameData: any;
  emailDataDetails={};
  options = [
    { label: 'Assessment', selected: false },
    { label: 'Commit (Concept/WBS)', selected: false },
    { label: 'RTE (Design)', selected: false },
    { label: 'RTO (Acceptance/Startup)', selected: false },
    { label: 'Setup (Asset/Project End)', selected: false }
  ]; 

  // options = [
  //   { label: 'Option 1', value: 'option1', selected: false },
  //   { label: 'Option 2', value: 'option2', selected: false },
  //   { label: 'Option 3', value: 'option3', selected: true }
  // ];

  // options = [
  //   { label: 'Option 1',  selected: false },
  //   { label: 'Option 2',  selected: false },
  //   { label: 'Option 3',  selected: true }
  // ];
  // selectedOptionsArray: string[] = [];
  selectedOptionsArray: { [key: string]: boolean } = {};
  selectedCheckbox: any;
  wbsComment: any;
  wbsMailSent: boolean = false;
  constructor(private common: CommonService, private fb: FormBuilder,
    private activatedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.displayOptionList= [
      { "name": "Assessment", "checked": false},
      { "name": "RTE", "checked": false}
    ]
    this.userResponse = []

    //Array is for questionId.startsWith(S)
    this.Array1 = []

    this.PORNum = this.activatedRoute.snapshot.queryParams['POR']
    this.FAOriginalName = this.activatedRoute.snapshot.queryParams['FA']

    //To fetch Focus Area name 
    this.common.focusAreaStatus.subscribe({
      next: (ele) => {
        this.focusAreaName = ele['focusAreaName']|| '';
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });

    this.loading = true
    this.common.loader.next(true);
    this.myForm = this.fb.group({});
    
  }

  valueFromChildCapInfo(data: { [x: string]: never[]; }){
    console.log("child data in assement",data)
    this.commonInfo = data['commonInfo']
    this.sendFormVar = {
      labName:  this.commonInfo['InitiateLabFab'],
      focusArea: this.FAOriginalName,
      // POR: this.activatedRoute.snapshot.queryParams['POR']
      POR: this.activatedRoute.snapshot.queryParams['POR']
    }
    this.common.postCapAPI('itg/cap-assessment-review-itg',this.sendFormVar).subscribe({
      next: (response) => {
        this.loading = false
        this.common.loader.next(false);
        console.log('assessment API response', response)
        let assessmentData = response.body
        let assessmentResponse = assessmentData['Answers']['UserResponse_Array'];
        let checkSHResponseStatus = assessmentData['Answers']['SHResponse_Status'] != undefined ? assessmentData['Answers']['SHResponse_Status'] : 0;
        this.SH_QAArray = (checkSHResponseStatus == 0) ? assessmentData['Questions']['SH_Question_Array'] : assessmentData['Answers']['SH_ResponseArray']

        //Below variable will check WBS mail has alrady been sent. If yes, 
        this.wbsMailSent = this.SH_QAArray.some((ele: { answer: string; disable: boolean; }) => ele.answer == 'Assessment' && ele.disable == true)

        this.SH_QAArray?.filter((findCheckbox:any) => {
          //A1 question id is for Review participation Multiselect
          if (findCheckbox['questionId'] == 'A1' && findCheckbox['checkedVal'].length > 0) {
            this.options = findCheckbox.checkedVal
            this.options.forEach(option => {
              const control = new FormControl(option.selected);
              control.valueChanges.subscribe({
                next: (value) => {
                  option.selected = value !== null ? value : false;
                },
                error: (error) => {
                  console.error("Error occurred:", error);
                  alert("Please connect with Asset Admin");
                }
              });
              this.myForm.addControl(option.label, control);
            });
          }
          //A1 question id is for Review participation Multiselect
          if (findCheckbox['questionId'] == 'A1' && findCheckbox['checkedVal'].length == 0) {
            // this.options = findCheckbox.answer
            this.options.forEach(option => {
              const control = new FormControl(option.selected);
              control.valueChanges.subscribe({
                next: (value) => {value !== null ? value : false;
                  option.selected = value !== null ? value : false;
                },
                error: (error) => {
                  console.error("Error occurred:", error);
                  alert("Please connect with Asset Admin");
                }
              });
              this.myForm.addControl(option.label, control);
            });
          }
          if (findCheckbox['questionId'] == 'O1' && findCheckbox['checkedVal'] == "Yes") {
            this.hideActionPlan = "false"
          }
        })

        //this.markWaiveOption = !assessmentResponse?.some(ele => ele.answer == 'Yes');
        this.markWaiveOption = !(assessmentResponse?.some((ele: { answer: string | undefined; type: string; }) => ele.answer == 'Yes' || (ele.type == 'textarea' && ele.answer != undefined)));

        //markWaiveOption will be true only when all assessment set to "NO" -- set O1 as waived
        if (this.markWaiveOption) {
          this.SH_QAArray?.filter((ele:any) => {
            if (ele['questionId'] == 'O1') {
              ele['checkedVal'] = 'Waive';
              ele['answer'] = true
              this.hideActionPlan = "false"
              this.disableButton = true
            }
          });
        }

        this.SH_QAArray?.forEach((ele: any)=> {
          this.Array1?.push(ele);
        });

      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });

    // this.capService.actionPlanForm(this.sendFormVar).subscribe(async data=>{
    //   // this.reviewData = data.body['Answers']['SH_ResponseArray']
    //   console.log("before Cancel", data)
    //   let filteredArray = [];
    //   let actionplanData = data.body.Answers
    //   actionplanData.filter(ele => {
    //     if(ele.Focus_Area == this.FAOriginalName && ele.ActionStatus == "Action Plan"){
    //        filteredArray = ele.SH_ResponseArray.filter(
    //         item => item.isActionPlanCheckBoxDone === true && item.answer === "Assessment");
    //     }
    //   });
    //   console.log("Print filterarray,", filteredArray)
      

    // })
  }

  onCheckboxChange(option: any, data: any, options1: any) {
    console.log(`Checkbox ${option} changed:`, this.selectedOptionsArray[option], data, options1);
    this.selectedCheckbox = options1
    this.onSelected(options1, data)
    // Perform additional logic based on the checkbox state change
    
  }

  onSelected(selectedOpt:any, data:any):void {
    console.log('Print', this.selectedOptionsArray)
		this.selectedOption = data;
    if(selectedOpt == 'Assessment' && this.wbsMailSent){
      this.popUpTxt = "You are attempting to select the 'Assessment' phase for which a WBS email has already been generated. Please choose other options.";
      this.openDialogMessage(this.popUpTxt , '');
      //Update below code with error class- Reshu Jain
      this.selectedOption['answer'] = "";
      //selectedOpt = "Commit"
      return
    }
    if (data['questionId']?.startsWith('S')) {
      this.Array1.forEach((ele: any) => {
        if (ele['questionId'] == this.selectedOption['questionId']) {
          this.selectedOption['answer'] = selectedOpt
        }
      });
    }
    //A1 question id is for Review participation Multiselect
    else if(data['questionId'] == 'A1'){
      this.Array1.forEach((ele: any) => {
        if (ele['questionId'] == this.selectedOption['questionId']) {
          this.selectedOption['answer'] = true
          this.selectedOption['checkedVal'] = selectedOpt
        }
      });
    }
    else{
      this.Array1.forEach((ele: any) => {
        if (ele['questionId'] == this.selectedOption['questionId']) {
          this.selectedOption['answer'] = (this.selectedOption?.options) ? selectedOpt?.target?.checked : selectedOpt
          this.selectedOption['checkedVal'] = selectedOpt?.target?.value
          if (selectedOpt?.target?.value == "No" && this.selectedOption['answer']) {
            this.hideActionPlan = "true"
          }
          else if(selectedOpt?.target?.value == "Yes" && this.selectedOption['answer']){
            this.hideActionPlan = "false"
          }
          else if(selectedOpt?.target?.value == "Waive" && this.selectedOption['answer']){
            this.hideActionPlan = "false"
          }
          else{
            if(!this.wbsMailSent)
            this.hideActionPlan = "None"
          }
        }

        if(ele['questionId'] == 'A5' && this.hideActionPlan == "false"){
          ele['answer'] = ''
        }
      });
    }
    console.log('Print', this.Array1)
	}

  //for question checkbox- questionId.startsWith S & O
  onChecked(event: any, data: any) {
    let searchedData = data
    let checkActionPlanStatus = this.Array1.some((ele: { isActionPlanCheckBoxDone: boolean; })=> ele.isActionPlanCheckBoxDone == true)
    this.Array1.forEach((ele: any)=> {
      if (ele['questionId'] == searchedData['questionId']) {
        ele['isCheckBoxClicked'] = event.target.checked;
        ele['isActionPlanCheckBoxDone'] = event.target.checked == false ? false :  ele['isActionPlanCheckBoxDone'];
        if(checkActionPlanStatus && ( ele['isCheckBoxClicked'] && ele['answer'] == 'Assessment')){
          this.popUpTxt = "You are attempting to select the 'Assessment' phase for which a WBS email has already been generated. Please choose other options.";
          this.openDialogMessage(this.popUpTxt , '');

          //Update below code with error class- Reshu Jain
          ele['answer'] = ""
        }
        //isNewQue is a variable which will available in new question entry
        if(ele['isNewQue'] != undefined)
          ele['isNewQue'] = !event.target.checked;
      }
    });
    console.log('Print', this.Array1)
  }

  enterText(event: any, data: any){
    this.newQueArray = {
      "questionId": "",
      "question": "",
      "answer": "Phase_Selection(?)",
      "isCheckBoxClicked": false,
      "isNewQue": true
    }
      let searchedData = data
      let fetchQueId = searchedData.questionId[0];
      let fetchQueIndex = this.Array1.findIndex((e: { questionId: any; })=>
                          e.questionId === searchedData.questionId) + 1
      const filteredArray = this.Array1.filter((e: { questionId: any; })=>
                            (e.questionId).startsWith(fetchQueId))

      filteredArray.filter((ele: { [x: string]: string; }) => {      
        if (ele['questionId'] == searchedData['questionId']) {
          if(ele['options'] == "textBox" || ele['options'] == "TextFree" || ele['options'] == "WbsComment"){
            ele['answer'] = event.trim();
          }
          else{
            ele['question'] = event;
          }      
          if(!searchedData['questionId'].startsWith('A') && (filteredArray.length == Number(searchedData['questionId'].split(fetchQueId).at(-1)))){
            this.newQueArray['questionId'] = fetchQueId+(filteredArray.length+1)
            this.Array1.splice(fetchQueIndex,0,this.newQueArray)
          }
          return
        }
      });
      
    this.sendFormVar['SH_ResponseArray']= this.Array1
  }

  async saveReviewData(ActionClickedOn: string){
    this.SH_QAArray = [];
    let hasPassedFilter = false
    this.Array1.filter((ele: { [x: string]: any; }) => {
      if(ele['questionId']=='O1' && ele['answer']==false){
        //alert('Please select the options first');
        this.popUpTxt = 'Please select the options first';
        this.openDialogMessage(this.popUpTxt , '');
        console.log("inside IF...", ele['answer'])
        hasPassedFilter = true
        return 
      }
      if(ActionClickedOn=='AddActionPlan' && ele['questionId']=='O1' && ele['answer']==true && ele['isCheckBoxClicked']==false){
        this.popUpTxt = 'Do you approve assignment of the WBS allocation of funds to purchase the tool? Please Select';
        this.openDialogMessage(this.popUpTxt , '');
        hasPassedFilter = true
        return
      }
      if (ele['questionId'] == 'A5') {
        if (ele['answer'].length > 0) {
          this.wbsComment = ele['answer']
          this.sendEmail('AllocationFund')
        }
        else {
          if(this.hideActionPlan == "true"){
            this.popUpTxt ="Please add comments before Save as you have selected 'No'";
            this.openDialogMessage(this.popUpTxt , '');
            hasPassedFilter = true
          }
        }
      }
      console.log("inside IF...", hasPassedFilter)     
      return 
    });

    if (hasPassedFilter) {
      return;
    }

    this.SH_QAArray = this.Array1;
    this.sendFormVar['SH_ResponseArray'] = [...new Set(this.SH_QAArray)]
    this.sendFormVar['ActionStatus'] =  (this.sendFormVar['SHResponse_Status'] == 1 ? 'Action Plan' : '')
    // this.sendFormVar['ActionStatus'] = 'Action Plan'
    this.common.loader.next(true);
    this.loading = true;
    this.common.loader.next(true);
    await this.common.postCapAPI('itg/cap-assessment-review-itg',this.sendFormVar).subscribe({
      next: (response) => {
        this.loading = false
        this.common.loader.next(false);
        console.log('assessment API response', response);
        this.SH_QAArray = response.body['SH_ResponseArray'];

        if (this.sendFormVar['SHResponse_Status'] == 2) {
          this.common.loader.next(false);
          this.popUpTxt = 'Data has been Saved';
          this.openDialogMessage(this.popUpTxt, 'loadPage');
        }
        else if (this.sendFormVar['SHResponse_Status'] == 1) {
          this.common.loader.next(false);
          this.popUpTxt = 'SH Review has been submitted & Action Items are ready';
          this.openDialogMessage(this.popUpTxt, 'ActionPlan');
        }
        else {
          this.common.loader.next(false);
          this.openErrorDilaogeMessage('Something went wrong, try after sometime', '');
        }

      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }

  saveDraft(checkboxType: any){
    this.sendFormVar['SHResponse_Status']=2;
    this.saveReviewData("Saved");
  }

  addToActionPlan(){
    this.sendFormVar['SHResponse_Status']=1;
    this.saveReviewData("AddActionPlan");
    //to do
  }

  // submit(){
  //   this.sendFormVar['SHResponse_Status']=2;
  //   this.saveReviewData("Submit");
  // }


  openDialogMessage(message: string, assetID: string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    
    dialogConfig.data = {
      message: message,
      tabletemplate: false
    }

    const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
    dialogRefSubmit.afterClosed().subscribe({
      next: (result) => {
        console.log(`Dialog result: ${result}`);
        if (assetID == 'loadPage') {
          //Redirect to focus area table, instaed of page reloading, merge both while testing in future
          // window.location.reload();
          this.router.navigate(['cap/focusArea'], { queryParams: { POR: this.sendFormVar['POR'] } })
        }
        if (assetID == 'ActionPlan') {
          this.router.navigate(['cap/focusArea'], { queryParams: { POR: this.sendFormVar['POR'] } })
          // this.router.navigate(['cap/capActionPlan'],{queryParams: { POR: this.PORNum, FA: this.FAOriginalName, mode: 'edit', actionPlan: 'Stakeholder' } })
        }
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }

  openErrorDilaogeMessage(message: string, assetID: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      message: message,
      tabletemplate: false
    }
    const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
  }

  sendEmail(funcName: string){
    this.common.getById('amd-get-labfabbyid/',this.sendFormVar['labName'].replace('/', '&#47;')).subscribe({
      next: (data) => {
        console.log("LABFAB data", data);
        if (data) {
          this.LabfabTeams = data[0];
          let focusAreaName = this.FAOriginalName
          this.SHNameData = this.LabfabTeams[focusAreaName.replace(/ /g, '_')];
          console.log('SHNameData', this.SHNameData)
          this.emailDataDetails = {
            FAPrimary: this.SHNameData['Primary'],
            FASecondary: this.SHNameData['Secondary'],
            FAWatcher: this.SHNameData['Watcher'],
            PrimaryRequester: this.commonInfo['RequesterName'],
            SecondaryRequester: this.commonInfo['SecondaryReq'],
            comments: this.wbsComment,
            LabName: this.commonInfo['InitiateLabFab'],
            FocusArea: this.FAOriginalName,
            por_number: this.commonInfo['PORNumber'],
            functionName: funcName,
            assetAdminEmail: 'tom.mayes2@hp.com; krista.lively@hp.com',
            ToolName: this.commonInfo['ToolName']
          }
        }
        this.common.postCapAPI('itg/cap-email-notification-itg',this.emailDataDetails).subscribe({
          next: (ele2) => {
            console.log('Print data', ele2);
          },
          error: (error) => {
            console.error("Error occurred:", error);
            alert("Please connect with Asset Admin");
          }
        });
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }

  openAssessmentdialogBox(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = '80vw'; // Set the width as per your requirement
    dialogConfig.maxHeight = '90vh'; // Set the maximum height as a percentage of the viewport height
    dialogConfig.data = 'openpopup';
    const dialogRefSubmit = this.dialog.open(AssessmentFormComponent, dialogConfig);
    dialogRefSubmit.afterClosed().subscribe(result => {
      console.log('Dialog closed with result:', result);
    });
  }
  
  // closeDialog(){
  //   this.dialog.closeAll();
  // }
}
