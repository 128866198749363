import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  loader = false;
  subscriptions: Subscription[] = [];
  constructor(private common: CommonService) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.common.loader.subscribe(res => {
        setTimeout(() => {
          this.loader = res
        }, 50);
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
