<div *ngIf="assetDetails">
  <span>Row Count : <b>{{rowCount}}</b></span>
  <ng-container *ngIf="assetDetails && pageLength.length != 1">
    <span>Page</span>
    <span *ngFor="let page of pageLength">
      <button class="btnPagination" (click)="pageNext(page)">
        <b *ngIf="page===currentPage" class="btnPaginationHover">{{page}}</b>
        <span *ngIf="page!==currentPage">{{page}}</span>
      </button>
    </span>
  </ng-container>
</div>


  <mat-card *ngIf="show" class="my-3 mat-elevation-z3 p-3">
    <form [formGroup]="assetdetails1" (ngSubmit)="onSubmit()">
      <div class="row">

        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>Building</mat-label>
              <mat-select formControlName="Asset_Location" (selectionChange)="SearchProduct()">
                <mat-option *ngFor="let val of arr1" [value]="val">{{val}}</mat-option>
              </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>LabFab Name</mat-label>
              <mat-select formControlName="Asset_Supplementary_Inventory_Specifications_Name">
                <mat-option *ngFor="let val of arr2" [value]="val">{{val}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>Asset Number</mat-label>
            <input matInput formControlName="Main_Asset_Number" placeholder="">
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="w-100">
            <mat-label>Owner</mat-label>
            <input matInput formControlName="Additional_Description" placeholder="">
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="w-100">
              <mat-label>Cost Object Manager</mat-label>
              <input matInput formControlName="Cost_Object_Manager_Email_ID" placeholder="">
          </mat-form-field>
        </div>

        <div class="col-12">
          <div class="text-center">
            <button type="submit" class="btn btn-primary mr-2">
              <i class="fa fa-search"></i>
              Search
            </button>
          </div>
        </div>

      </div>
     </form>
   </mat-card>

   <div class="spinLoader" *ngIf="loading else loaded">
    <i class="fa fa-spinner fa-spin"></i>&nbsp;
    <span>Generating report....downloading data....({{progress}}%)</span>
  </div>


   <div class="mat-elevation-z8">
    <div class="table-responsive dataTable">
      <table id="dtHorizontalExample" class="table table-striped table-responsive-md btn-table bs-select" *ngIf="assetDetails && showGrid">
        <thead class="custfont text-white">
          <tr class="appTableHeader">
  
            <th class="bg-primary">
              <div class="d-flex align-items-center">
                Print 
                <input type="checkbox" class="ml-2" [(ngModel)]="tableCheckAllBoxprint" (change)="checkUncheckAllprint()">
              </div>
            </th>
  
            <th class="bg-primary">
              <div class="d-flex align-items-center">Found</div>
            </th>
  
            <th class="bg-primary"></th>
  
            <th class="table-head bg-primary" *ngFor="let title of tableHeaderArray; index as i">
              <div *ngIf="title !== 'Checkbox_Data'" class="d-flex align-items-center cursor-pointer" (click)="sortData(i, title)">
                <!-- {{ title.split('_').join(' ') }} -->
                <span>{{title.split("_").join(" ")}}</span>&nbsp;
                <!-- <i *ngIf="i === columnindex && ascendingsort" class="fa fa-arrow-up ml-2"></i>
                <i *ngIf="i === columnindex && !ascendingsort" class="fa fa-arrow-down ml-2"></i> -->
                <i *ngIf="i==columnindex && ascendingsort==true" class="fa fa-arrow-up"></i>
                <i *ngIf="i==columnindex && ascendingsort==false" class="fa fa-arrow-down"></i>
              </div>
              <div *ngIf="title!='Checkbox_Data'" style="padding-top: 5px;">
                <input type="text" class="form-control" [(ngModel)]="filterText[i]" (input)="filterValue(filterText, i, title)" placeholder="Enter Keyword">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="!noRecord">
            <tr *ngFor="let item of FinalAdminAssetData">
              <td><input type="checkbox" [(ngModel)]="item.Checkbox_PrintData"></td>
              <td><input type="checkbox" [(ngModel)]="item.Checkbox_Data"></td>
              <td>
                <button *ngIf="IsdisableEdit" class="btn btn-sm btn-primary" (click)="edit(item['Asset_Number'])">Edit</button>
              </td>
              <td *ngFor="let data of item | keyvalue: originalOrder">
                <span *ngIf="data.key !== 'Checkbox_Data' && data.key !== 'Checkbox_PrintData'">{{ data.value }}</span>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="noRecord">
            <td colspan="100%" class="text-center">No AMD Records to Display!</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
    
    <br>
    <div class="row">
      <div  class="col-3">
        <button  class="btn btn-primary form-control"  (click)="saveAs(FinalAdminAssetData)">
          <i class="fa fa-download"></i>&nbsp;&nbsp;&nbsp;Download Labels for Printing
        </button>&nbsp;&nbsp;
      </div>
      <div class="col-3">
        <button class="btn btn-primary form-control" (click)="updateFound(FinalAdminAssetData)">
          <i class="fa fa-search"></i>&nbsp;&nbsp;&nbsp;Found
        </button>&nbsp;&nbsp;
      </div>
      <div class="col-3">
        <button class="btn btn-primary form-control" (click)="exportFound(FinalAdminAssetData)">
          <i class="fa fa-download"></i>&nbsp;&nbsp;&nbsp;Download Spreadsheet
        </button>&nbsp;&nbsp;
      </div>
      <div class="col-3">
        <button class="btn btn-primary form-control" (click)="files.click()">
          <i class="fa fa-upload"></i>&nbsp;&nbsp;&nbsp;Upload Spreadsheet
        </button>&nbsp;&nbsp;
        <input class="btn btn-primary" id="files" style="display: none" type="file" accept=".xlsx, .xls" multiple="false" (change)="uploadSpreadsheet($event)" #files>&nbsp;&nbsp;
      </div>
      <div class="col-3">
        <button class="btn btn-primary form-control" (click)="exportFoundAndNotFoundReport()">
          <i class="fa fa-download"></i>&nbsp;&nbsp;&nbsp;Asset Verification Report
        </button>&nbsp;&nbsp;
      </div>
      <div class="col-3">
        <app-export-history></app-export-history>
      </div>
    </div>
  
    <br>
    <br>
  