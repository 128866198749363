<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="container">
            <mat-table [dataSource]="tableData" #table class="mat-elevation-z8">
                <ng-container *ngFor="let column of displayedColumns ; let j = index" [matColumnDef]="column" >  
                    <th mat-header-cell class="custom-header-cell" *matHeaderCellDef >{{ column }} </th>
                    <td mat-cell class="custom-cell" *matCellDef="let element; let i = index">
                        <input *ngIf="column!='Action'" class="custom-input" [(ngModel)]="element[j]" matInput placeholder="Enter"  #input (change)="textchange()">
                        <button class="btn btn-primary" (click)="addRow()" *ngIf="(i==0 && displayedColumns.length==j+1)">Add Row</button> 
                        <button *ngIf="column=='Action' && (i > 0)" mat-icon-button color="warn" (click)="deleteRow(element)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row class="bg-primary custom-header-row" *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </mat-table>
            </div>
        </div>
    </div>
</div>

