import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-approvers',
  templateUrl: './approvers.component.html',
  styleUrls: ['./approvers.component.scss']
})
export class ApproversComponent implements OnInit, OnChanges {
  @Input() actionPlanApproversData: any;
  //sending the data to parent component action plan
  @Output() sendChildValue: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup = this.fb.group({
    approversList: this.fb.array([]),
  });
  showLabel: boolean = false;
  arrayEmail = [];
  data = {};
  assetID = '';
  submitLoading = false
  user = atob(this.common.currentUser);
  userRole = this.common.getUserRole();
  ApprovalPageCheck = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private common: CommonService,
    private dialog: MatDialog,
    private route: Router,
  ) { }


  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'actionPlanApproversData'**************/
    if (this.actionPlanApproversData['Action_Plan_Email_Approvers_List']) {
      this.arrayEmail = this.actionPlanApproversData['Action_Plan_Email_Approvers_List'].split(';');
    }

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.assetID = params['assetid'];
      this.ApprovalPageCheck = ((this.route.url).includes('action-plan-approverList')) || ((this.route.url).includes('retire-approverList'))
    });


    this.arrayEmail?.map(email => {
      const creds = this.form.controls['approversList'] as FormArray;
      creds.push(this.fb.group({
        status: 'Pending',
        comments: '',
        email: email,
        date: '',
        approvedBy: '',
        commentList: [],
        name: ''
      }));
    }
    );

    this.initialDatamergeFinal()
  }

  async initialDatamergeFinal() {
    // if (this.actionPlanApproversData['action_Plan_AssetCost_View_Status_Of_Approvals']) {

    let arr1 = this.form?.value?.approversList;
    let arr2 = this.actionPlanApproversData['action_Plan_AssetCost_View_Status_Of_Approvals'] ? (JSON.parse(this.actionPlanApproversData['action_Plan_AssetCost_View_Status_Of_Approvals'])?.approversList) : [];
    const mergedArray = [...arr2, ...arr1];
    // mergedArray have duplicates, lets remove the duplicates using Set
    let set = new Set();
    let unionArray = await mergedArray.filter(item => {
      if (!set.has(item?.email)) {
        set.add(item?.email);
        return true;
      }
      return false;
    }, set);
    this.form.patchValue({ "approversList": unionArray })
    this.sendChildValue.emit({ "approversList": unionArray });
    //}
  }

  ApprovalStatus(status: any, index: any) {
    let Currentdate = new Date().toDateString()
    const myForm = (<FormArray>this.form.get("approversList")).at(index);
    myForm.patchValue({
      status: status,
      date: Currentdate,
      approvedBy: this.user,
      commentList: (myForm.get('commentList') ?.value ? myForm.get('commentList')?.value : '') + '\n' + (myForm.get('comments')?.value) + " Status: " + status + " Date: " + Currentdate
    });

    if (myForm.get('comments')?.value == "") {
      this.openDilaogeMessage('Please Enter the comments to ' + (status == 'Approved' ? 'Approve' : 'Reject'), '');
      return
    }

    this.submitLoading = true;
    //Request Params Starts
    const RequestData: any = { "approversList": JSON.stringify(this.form?.value) };
    RequestData["Main_Asset_Number"] = this.assetID;
    RequestData["AcceptedBy"] = this.user;
    RequestData["Status"] = status;
    RequestData["ApprovalsEmails"] = this.actionPlanApproversData['Action_Plan_Email_Approvers_List'];
    RequestData["action_Plan_pendingApprovals"] = this.form?.value.approversList.some((item: any) => { return item.status === 'Pending' })
    //Request Params ENDS
    this.common.postAPI('amd-action-plan-approvers', RequestData).subscribe((data: any) => {
      if (data?.errorType) {
        this.submitLoading = false;
        this.openDilaogeMessage('Please wait, try after sometime', this.assetID);
      }
      if (data == null) {
        this.submitLoading = false;
        this.openDilaogeMessage(this.assetID + ' Asset Move Cost ' + myForm.get('status')?.value, this.assetID);
        myForm.get('comments')?.setValue('');
      }
    });
  }

  //Message dialog 
  openDilaogeMessage(message: any, assetID: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      message: message,
      tabletemplate: false,
      title: assetID ? 'Thank You' : "Action Plan"
    }

    const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
    dialogRefSubmit.afterClosed().subscribe(result => {
      if (assetID) {
        window.location.reload()
        //this.route.navigate(['/assetRequestChange/action-plan-approverList'], { queryParams: { assetid: assetID } });
      }

    });
  }

}
