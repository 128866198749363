import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';

interface Stakeholder {
  Primary: string;
  Secondary: string;
  Watchers: string;
}

interface PassedData {
  [key: string]: Stakeholder;
}

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {
  labFabName: string | null = null;
  labBuilding: any;
  building!: string;
  site!: string;
  passedData: PassedData | null = null;
  editForm!: FormGroup;
  displayedColumns: string[] = ['name', 'primary', 'secondary', 'watchers'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private common: CommonService,
    private fb: FormBuilder
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.common.bannerData.next({ title: 'AMD', subtitle: 'Edit Stakeholders' });
    if (navigation?.extras.state) {
      this.passedData = navigation.extras.state['passedData'] as PassedData;
      this.labBuilding = navigation.extras.state['labBuildingData'];      
    } else {
      console.log('No data received');
    }
  }

  ngOnInit() {
    this.building = this.labBuilding['Building'];
    this.site = this.labBuilding['Site'];
    this.labFabName = this.route.snapshot.paramMap.get('id');
    this.editForm = this.fb.group({
      site: [this.site],
      building: [this.building],
      labFabName: [this.labFabName],
      data: this.fb.array([]),
    });
    console.log("LABFAB NAME:", this.labFabName);
    this.populateForm();
  }

  private populateForm() {
    const stakeholdersArray = this.editForm.get('data') as FormArray;
    if (this.passedData) {
      for (const [key, value] of Object.entries(this.passedData)) {
        stakeholdersArray.push(this.fb.group({
          name: [key],
          primary: [value.Primary],
          secondary: [value.Secondary],
          watchers: [value.Watchers]
        }));
      }
      
    }
    console.log('main',this.editForm.get('data'));
    }

  get stakeholdersArray(): FormArray {
    return this.editForm.get('data') as FormArray;
  }

  onSubmit() {
    console.log(this.editForm.value);
    this.common.postCapAPI('itg/cap-update-stakeholder-itg',this.editForm.value).subscribe({
      next: (data) => {
        if (data['message']) {
          this.common.show('success','successfully Updated Stakeholder');
          this.router.navigate(['stakeholder/capStakeholder'])
        }
      },
      error: (error: any) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }
}
