
export const environment = {
  production: false,
  fileUploadURL: "https://fa-amd-storage-itg.s3.amazonaws.com/",
  URL: 'https://nm0s9v75t9.execute-api.us-west-2.amazonaws.com/dev/',
  // CapURL: 'https://a0pmdh6rdk.execute-api.us-west-2.amazonaws.com/',
  CapURL: 'https://36fpui5ah5.execute-api.us-west-2.amazonaws.com/',
  CapexURL: 'https://j2q3s223ph.execute-api.us-west-2.amazonaws.com/itg/',
  current_user_email: '',
  token: '',
  environmentName: 'Development',
  appVersion: 'v4.0',
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_ypkT7JxQ0',
    userPoolWebClientId: '2ioed3gh7pepchhesj04b0qvb3',
    mandatorySignIn: true,
    identityProvider : 'AMDUID-ITG',
    oauth: {
      domain: 'amd-itg.auth.us-west-2.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: 'https://amd-dev.hpcloud.hp.com/',
      redirectSignOut: 'https://amd-dev.hpcloud.hp.com/',
      // redirectSignIn: 'http://localhost:4200/',
      // redirectSignOut: 'http://localhost:4200/',
      responseType: 'token',
      options: {
        AdvancedSecurityDataCollectionFlag: true
      }
    }
  },
  options:{
    login: true
  },
};
