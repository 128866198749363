import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-retired-reclassified-assets',
  templateUrl: './retired-reclassified-assets.component.html',
  styleUrls: ['./retired-reclassified-assets.component.scss']
})
export class RetiredReclassifiedAssetsComponent implements OnInit {

  exportRetiredAssets = false;

  constructor(
    private common: CommonService,
    private router: Router,
    private dialog: MatDialog
  ) {this.common.bannerData.next({ title: 'Report', subtitle: 'All Retired and Reclassified Assets' }); }

  ngOnInit() {

  }

  exportAllRetiredReclassified() {
    this.exportRetiredAssets = true;
    this.common.getAPI('amd-export-retired-reclassified/').subscribe({
      next: ((data: any) => {
        if (data.length != 0) {
          let columnOrder = ["Company code", "Asset Number", "", "Sub Number", "Transaction Type",
            "Accounting principle", "Depreciation area", "Document Date", "Posting Date", "Asset value date",
            "Amount Posted", "Quantity", "Unit", "Text", "Trading Partner", "Posting Period", "Document Type",
            "Offsetting accounting", "Reference", "Assignment", "Partner Business area", "Amount (Dep 01)",
            "Ord Dep 01 (PY)", "Ord Dep 01 (CY)", "Unplanned Dep 01 (PY)", "Unplanned Dep 01 (CY)", "Amount (Dep 02)",
            "Ord Dep 02 (PY)", "Ord Dep 02 (CY)", "Unplanned Dep 02 (PY)", "Unplanned Dep 02 (CY)", "Amount (Dep 66)",
            "Ord Dep 66 (PY)", "Ord Dep 66 (CY)", "Unplanned Dep 66 (PY)", "Unplanned Dep 66 (CY)", "Amount (Dep 31)",
            "Ord Dep 31 (PY)", "Ord Dep 31 (CY)", "Unplanned Dep 31 (PY)", "Unplanned Dep 31 (CY)", "Amount (Dep 32)",
            "Ord Dep 32 (PY)", "Ord Dep 32 (CY)", "Unplanned Dep 32 (PY)", "Unplanned Dep 32 (CY)", "Amount (Dep 40)",
            "Ord Dep 40 (PY)", "Ord Dep 40 (CY)", "Unplanned Dep 40 (PY)", "Unplanned Dep 40 (CY)", "Amount (Dep 41)",
            "Ord Dep 41 (PY)", "Ord Dep 41 (CY)", "Unplanned Dep 41 (PY)", "Unplanned Dep 41 (CY)"];

          let workbook = new Excel.Workbook();

          //let worksheet = workbook.addWorksheet('Asset Creation Templ1');
          let worksheet = workbook.addWorksheet('Disposition');


          let titleValues1 = [""];
          let titleValues2 = [""];

          worksheet.spliceRows(1, 0, titleValues1, titleValues2);

          let headerRow = worksheet.addRow(columnOrder);
          for (let x1 of data) {
            let x2 = columnOrder;
            let temp = []
            for (let y of x2) {
              temp.push(x1[y])
            }
            worksheet.addRow(temp)
          }

          worksheet.getCell('A1').value = 'Initial Screen';
          worksheet.getCell('B1').value = 'To be filled in by Requestor';
          worksheet.getCell('F1').value = 'Transaction Data';
          worksheet.getCell('J1').value = 'BI report date';
          worksheet.getCell('O1').value = 'Additional Details';
          worksheet.getCell('V1').value = 'Line item';
          worksheet.getCell('A2').value = 'This row is the BI report headers';
          worksheet.getCell('B2').value = 'Main Asset Number';
          worksheet.getCell('C2').value = 'Asset Class Code';

          worksheet.getRow(2).height = 32;
          worksheet.getRow(3).font = {
            name: 'Calibri',
            size: 11,
          };
          worksheet.getRow(3).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ffe685' }
          };
          ['B2', 'C2'].map(key => {
            worksheet.getCell(key).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '007bff' },
            };
          });

          ['B2', 'C2'].map(key => {
            worksheet.getCell(key).font = {
              name: 'HP Simplified',
              size: 9,
              bold: true,
              color: { argb: 'ffffff' }
            };
          });
          ['B1', 'C1', 'E1', 'H1', 'J1', 'N1'].map(key => {
            worksheet.getCell(key).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'ffff00' },
            };
          });

          workbook.xlsx.writeBuffer().then((buffer) => {
            let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'All Fixed Asset Retirements and Reclassifications.xlsx');
          });
        }
        else {
          alert("No retired or reclassified assets");
        }
        this.exportRetiredAssets = false; 
      }),
      error: (
        error => {
          console.error('Error fetching data', error);
        }
      )
    })
  }
}