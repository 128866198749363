import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-retirement-request',
  templateUrl: './retirement-request.component.html',
  styleUrls: ['./retirement-request.component.scss']
})
export class RetirementRequestComponent {
  retirementForm!: FormGroup;
  assetid!: string;
  loading = false;
  submitted = false;
  submitLoading = false;
  actionPlanClicked = false;
  Retirement_Type_List = ["2Z0 Retirement by Scrap", "2Z2 Retirement by Lost", "2ZA Retirement by Donation", "2Z3 Retirement by Stolen", "2Z8 Retirement by Trade IN", "2ZD Retirement By Inventory Lost", "2ZE Retirement By Inventory Scrap", "2ZF Retirement By Inventory Stolen", "260 Retirement with Revenue/Sale"]
  //Reason for request to back to draft
  reason_Back_to_Draft: string[] = [
    '$ are not available for the move at this time, work with cost owner to reset the action date',
    'The resources are not available to perform the move at this time, work with cost and tool owners to reset the action date',
    'Technical/safety issues with the move, work with Hookup, EHS, tool and cost owner to rectify and reset the action date'
  ];

  reason_Cancel: string[] = ['The organization has decided to not approve of the move. The asset will be moved to the pre-request state', 'Cancelled due to no activity (x months)']


  userRole = this.common.getUserRole();
  UserName = atob(this.common.currentUser);



  constructor(private activatedRoute: ActivatedRoute, private common: CommonService, private fb: FormBuilder, private route: Router, private dialog: MatDialog) {
    this.common.bannerData.next({ title: 'AMD', subtitle: 'Asset Retirement Request' });
  }

  ngOnInit() {
    this.retirementForm = this.fb.group({
      Main_Asset_Number: [{ value: '', disabled: false }],
      Change_Status: [{ value: '', disabled: true }],
      Change_Type: [''],
      Requester_ID: [atob(this.common.currentUser)],
      Retirement_Type: ['', Validators.required],
      Old_Retirement_Type: [''],
      New_Retirement_Type: [''],
      Retirement_Date: [''],
      Retirement_Status: [''],
      Old_Retirement_Status: [''],
      New_Retirement_Status: [''],
      Admin_Comment: [''],
      INSERT_DATETIME: [''],
      MODIFIED_DATETIME: [''],
      MODIFIED_Username: [''],
      reason_Back_To_Draft_Status: [''],
      action_plan_reason_Cancel_Status: ['']
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.assetid = params['assetid'];
      this.getAssetDetailsbyId(this.assetid);
    });
    this.loading = true;
  }
  getAssetDetailsbyId(assid: string) {
    this.common.loader.next(true);
    this.common.getAPI('amd-get-assetbyid/' + assid).subscribe(
      {
        next: (
          (data: any) => {
            this.retirementForm.patchValue(data[0]);
            if (data[0]['Change_Status'] == 'Approved' || data[0]['Change_Status'] == 'Draft' || data[0]['Change_Status'] == 'Pending Analyst Review') {
              if (data[0]['Change_Status'] != 'NULL' || data[0]['Change_Status'] != '' || data[0]['Change_Status'] != 'null') {

                this.retirementForm.controls['Retirement_Status']?.setValue(data[0]['Change_Status']);
              }
              if (data[0]['Retirement_Type'] == 'NULL' || data[0]['Retirement_Type'] == '' || data[0]['Retirement_Type'] == 'null') {
                this.retirementForm.controls['Old_Retirement_Type']?.setValue(data[0]['Retirement_Type']);
                this.retirementForm.controls['New_Retirement_Type']?.setValue(data[0]['Retirement_Type']);
              }
              if (data[0]['Retirement_Status'] == 'NULL' || data[0]['Retirement_Status'] == '' || data[0]['Retirement_Status'] == 'null') {
                this.retirementForm.controls['Old_Retirement_Status']?.setValue(data[0]['Retirement_Status']);
                this.retirementForm.controls['New_Retirement_Status']?.setValue(data[0]['Retirement_Status']);
              }
            }
            this.common.loader.next(false);
          }
        ),
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      })
  }

  close() {
    this.route.navigate(['/asset']);
  }

  retirementType_Change() {
    let basic_change = '';
    this.retirementForm.get('Retirement_Status')?.setValue('Draft');
    this.retirementForm.get('Change_Status')?.setValue(basic_change)
    if (this.retirementForm.get('Retirement_Status')?.value == 'Draft') {
      basic_change = this.retirementForm.get('Retirement_Status')?.value
      this.retirementForm.get('Change_Status')?.setValue(basic_change)
    }

    //already labfab request submitted which is draft status and again trying to edit the asset descp or serial no. it will be in draft
    if (this.retirementForm.get('Change_Status')?.value == 'Draft')
      basic_change = 'Draft'

    if (this.retirementForm.get('Change_Status')?.value == 'Pending Analyst Review')
      basic_change = 'Pending Analyst Review'

    this.retirementForm.get('Change_Type')?.setValue('Retired');
    this.retirementForm.controls['Retirement_Type']?.setValue(this.retirementForm.get('Retirement_Type')?.value);
    this.retirementForm.controls['Retirement_Status']?.setValue(basic_change);
    this.retirementForm.controls['New_Retirement_Type']?.setValue(this.retirementForm.get('Retirement_Type')?.value);


  }

  //back to draft / cancel

  request_Status_change() {
    if (this.retirementForm.get('Retirement_Status')?.value == 'Back to Draft') {
      this.retirementForm.get('reason_Back_To_Draft_Status')?.setValidators([Validators.required])
      this.retirementForm.get('reason_Back_To_Draft_Status')?.updateValueAndValidity();
      this.retirementForm.get('action_plan_reason_Cancel_Status')?.clearValidators();
      this.retirementForm.get('action_plan_reason_Cancel_Status')?.updateValueAndValidity();
      this.retirementForm.get("action_plan_reason_Cancel_Status")?.setValue('');
    }

    if (this.retirementForm.get('Retirement_Status')?.value == 'Cancel') {
      this.retirementForm.get('action_plan_reason_Cancel_Status')?.setValidators([Validators.required])
      this.retirementForm.get('action_plan_reason_Cancel_Status')?.updateValueAndValidity();
      this.retirementForm.get('reason_Back_To_Draft_Status')?.clearValidators();
      this.retirementForm.get('reason_Back_To_Draft_Status')?.updateValueAndValidity();
      this.retirementForm.get("reason_Back_To_Draft_Status")?.setValue('');
    }
    if (this.retirementForm.get('Retirement_Status')?.value != 'Cancel') {
      this.retirementForm.get('action_plan_reason_Cancel_Status')?.clearValidators();
      this.retirementForm.get('action_plan_reason_Cancel_Status')?.updateValueAndValidity();
      this.retirementForm.get("action_plan_reason_Cancel_Status")?.setValue('');
    }
    if (this.retirementForm.get('Retirement_Status')?.value != 'Back to Draft') {
      this.retirementForm.get('reason_Back_To_Draft_Status')?.clearValidators();
      this.retirementForm.get('reason_Back_To_Draft_Status')?.updateValueAndValidity();
      this.retirementForm.get("reason_Back_To_Draft_Status")?.setValue('');
    }

  }

  edit(assetnumber: number) {
    this.actionPlanClicked = true;
    this.onSubmit('actionPlanBtn', assetnumber)
    sessionStorage['flag'] = true
  }


  onSubmit(BtnClicked: any, assetnumber: any) {

    this.retirementForm.get('MODIFIED_Username')?.setValue(this.UserName);

    // Retirement_Date Starts
    let DateObj = new Date();
    let date = ('0' + (DateObj.getMonth() + 1)).slice(-2) + '/' + ('0' + DateObj.getDate()).slice(-2) + '/' + DateObj.getFullYear();
    this.retirementForm.get('Retirement_Date')?.setValue(date)

    if (this.retirementForm.invalid) {
      window.scroll(0, 0);
      this.openDilaogeMessage("Please fill all the required fields *     ", '');
      return;
    }
    this.submitted = true;
    this.common.loader.next(true);

    this.common.postAssetRequestDetails([this.retirementForm.value], 'Retirement').subscribe(
      {
        next: (
          (data: any) => {
            if (data.statusCode == 200 && data.body) {
              // when there is no cost associated direct approvaed on SNS or descption changes
              if ((this.retirementForm.get('Retirement_Status')?.value == 'Approved')) {
                this.emailNoActionReqConfirmation()
              }

              //"AMD NOTIFICATION ACTION REQUIRED: Administrator moved to draft"
              //Admin submitted the request 'Back TO Draft'
              if ((this.retirementForm.get('Retirement_Status')?.value == 'Back to Draft') || (this.retirementForm.get('Retirement_Status')?.value == 'Cancel')) {
                this.emailNotifyBack_To_Draft_Cancel();
              }

              this.openDilaogeMessage(data.body, assetnumber);
              // If the action Form link is clicked
              this.actionPlanClicked = false;

              //If the submit btn clicked
              if (BtnClicked == 'SubmitBtn') {
                this.submitLoading = false;
              }

              else {
                this.submitLoading = false
              }


              this.submitted = false;
              // this.submitLoading = false;
              this.actionPlanClicked = false;
            }
            this.common.loader.next(false);
          }
        ),
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });
  }


  openDilaogeMessage(message: any, assetID: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '350px';
    dialogConfig.data = {
      message: message,
      tabletemplate: false
    }
    
    const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
    dialogRefSubmit.afterClosed().subscribe(result => {
      if (this.retirementForm.get('Retirement_Status')?.value == 'Draft' && assetID) {
        this.route.navigate(['/assetRequestChange/action-plan-retire'], { queryParams: { assetid: assetID } });
      }

      if (this.retirementForm.get('Retirement_Status')?.value == 'Approved' && assetID) {
        this.route.navigate(['/asset']);
      }

      if ((this.retirementForm.get('Retirement_Status')?.value == 'Back to Draft' || this.retirementForm.get('Retirement_Status')?.value == 'Cancel' || this.retirementForm.get('Retirement_Status')?.value == 'Pending Analyst Review') && assetID) {
        window.location.reload();
      }

    }
    )
  }

  //approved confirmation Email notifications for amd-email-no-action-req-confirmation
  //AMD Email NOTIFICATION:  NO ACTION REQUIRED: Asset Move Confirmation 
  emailNoActionReqConfirmation() {
    this.common.postAPI('amd-email-no-action-req-confirmation', { 'Main_Asset_Number': this.assetid }).subscribe(
      {
        next: (
          (data: any) => {

            this.common.loader.next(false);
          }
        ),
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      })
  }

  //AMD ACTION REQUIRED: Administrator moved to draft or //Email Notification for Admin cancelled request
  emailNotifyBack_To_Draft_Cancel() {
    this.common.postAPI(
      'amd-emails-back-to-draft-cancel',
      { 'Main_Asset_Number': this.assetid, 'basic_change_Status': this.retirementForm.get('Retirement_Status')?.value }).subscribe(
        {
          next: (
            (data: any) => {

              this.common.loader.next(false);
            }
          ),
          error: (
            error => {
              this.common.errorHandler(error);
              this.common.loader.next(false);
            }
          )
        })
  }
}
