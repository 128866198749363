<form [formGroup]="assetdetails1" (ngSubmit)="onSubmit()">
    <mat-card>
        <!-- <mat-toolbar color="primary" class="mb-3">
            <span class="text-white">
                <h3><strong>Edit Data for a Fixed Asset</strong></h3>
            </span>
        </mat-toolbar> -->

        <div class="row mb-4">
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <mat-label>Asset Number</mat-label>
                    <input matInput type="text" formControlName="Main_Asset_Number" readonly
                        [ngClass]="{ 'is-invalid': submitted && assetdetails1.get('Main_Asset_Number')?.invalid }" />
                    <mat-error *ngIf="submitted && assetdetails1.get('Main_Asset_Number')?.invalid">
                        Main Asset Number is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <mat-label>Status</mat-label>
                    <input matInput type="text" formControlName="Change_Status" readonly />
                </mat-form-field>
            </div>
        </div>

        <!-- <div class="spinLoader" *ngIf="loading else loaded">
            <i class="fa fa-spinner fa-spin"></i>&nbsp;
            <span>Fetching Data...</span>
        </div>

        <hr size="30" noshade> -->

        <div class="row mb-4">

            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Asset Description</mat-label>
                    <input matInput formControlName="Asset_Description"
                        [ngClass]="{ 'is-invalid': submitted && assetdetails1.get('Asset_Description')?.invalid }"
                        placeholder="Enter asset description">
                    <mat-error *ngIf="submitted && assetdetails1.get('Asset_Description')?.invalid">
                        Asset Description is required
                    </mat-error>
                </mat-form-field>
            </div> 

            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Owner</mat-label>
                    <input matInput formControlName="Additional_Description"
                        [ngClass]="{ 'is-invalid': submitted && assetdetails1.get('Additional_Description')?.invalid }"
                        placeholder="Enter owner">
                    <mat-error *ngIf="submitted && assetdetails1.get('Additional_Description')?.invalid">
                        Owner is required
                    </mat-error>
                </mat-form-field>
            </div>
            
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Serial Number</mat-label>
                    <input matInput formControlName="Serial_Number"
                        [ngClass]="{ 'is-invalid': submitted && assetdetails1.get('Serial_Number')?.invalid }"
                        placeholder="Enter serial number">
                    <mat-error *ngIf="submitted && assetdetails1.get('Serial_Number')?.invalid">
                        Serial Number is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
            

        <div class="row mb-4">
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>LabFab Name</mat-label>
                    <input matInput formControlName="Asset_Supplementary_Inventory_Specifications_Name"
                        [ngClass]="{ 'is-invalid': submitted && assetdetails1.get('Asset_Supplementary_Inventory_Specifications_Name')?.invalid }"
                        placeholder="Enter LabFab Name">
                    <mat-error *ngIf="submitted && assetdetails1.get('Asset_Supplementary_Inventory_Specifications_Name')?.invalid">
                        LabFab Name is required
                    </mat-error>
                </mat-form-field>
            </div>
            
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Building</mat-label>
                    <input matInput formControlName="Asset_Location"
                        [ngClass]="{ 'is-invalid': submitted && assetdetails1.get('Asset_Location')?.invalid }"
                        placeholder="Enter Building">
                    <mat-error *ngIf="submitted && assetdetails1.get('Asset_Location')?.invalid">
                        Building is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
            

        <div class="row mb-4">

            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Comment</mat-label>
                    <textarea matInput formControlName="Comment" (keydown.enter)="prevent($event)" class="custom-textarea"></textarea>
                </mat-form-field>
            </div>
        </div>

        <mat-card class="mt-4">
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Insert Date/Time</mat-label>
                        <input matInput type="text" formControlName="INSERT_DATETIME" placeholder="" readonly />
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Last Modified Date/Time</mat-label>
                        <input matInput type="text" formControlName="MODIFIED_DATETIME" placeholder="" readonly />
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Last Modified User Name</mat-label>
                        <input matInput type="text" formControlName="MODIFIED_Username" placeholder="" readonly />
                    </mat-form-field>
                </div>
            </div>
        </mat-card>

        <div class="row mt-4">
            <div class="col-2">
                <div class="form-group">
                    <input type="button" class="btn btn-primary form-control" value="Save" (click)="onSubmit()" />
                </div>
            </div>

            <div class="col-2">
                <div class="form-group">
                    <input type="button" class="btn btn-primary form-control" value="Cancel" (click)="close()" />
                </div>
            </div>
        </div>
        
    </mat-card>
</form>
