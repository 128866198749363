import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {
  constructor(private common: CommonService, private activatedRoute: ActivatedRoute) {
    this.common.currentModule.next(this.activatedRoute.snapshot.data);
    this.common.bannerData.next({ title: 'Asset Details' });
  }
}
