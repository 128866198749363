<div class="container" *ngIf="!showReports">
    <mat-card class="">
        <form [formGroup]="assetcountdetails" (ngSubmit)="onSubmit()">
            <div class="list-group">
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-6">
                            <input type="button" (click)="openReport('New')" class="btn btn-primary mr-2 vertical-align-unset"
                                value="Display" />
                            <input type="submit" class="btn btn-primary mr-2 vertical-align-unset" value="Action All" />
                            <input type="text" class="form-control d-inline-block w-auto " formControlName="Asset_Total_Count" readonly />
                        </div>
                        <div class="col-6">
                            <label class="mb-0">Number Of <span class="text-success"><b>"New"</b></span> Fixed Assets
                                Found In The Imported S4 Data</label>
                        </div>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-6">
                            <input type="button" (click)="openReport('Missing')" class="btn btn-primary mr-2 vertical-align-unset"
                                value="Display" />
                            <input type="button" class="btn btn-primary mr-2 vertical-align-unset" disabled value="Action All" />
                            <input type="text" class="form-control d-inline-block w-auto " formControlName="Asset_Missing_Count" readonly />
                        </div>
                        <div class="col-6">
                            <label class="mb-0">Number Of The <span class="greenColor"><b>Active</b></span> Fixed Assets
                                Which Are
                                <span class="redColor"><b>"Missing"</b></span> From The S4 Data</label>
                        </div>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-6">
                            <input type="button" (click)="openReport('Inactive')" class="btn btn-primary mr-2 vertical-align-unset"
                                value="Display" />
                            <input type="button" (click)="actionAllInactiveAssets()" class="btn btn-primary mr-2 vertical-align-unset"
                                value="Action All" />
                            <input type="text" class="form-control d-inline-block w-auto " formControlName="Asset_Inactive_Count" readonly />
                        </div>
                        <div class="col-6">
                            <label class="mb-0">Number Of The
                                <span class="text-warning"><b>Inactive</b></span>
                                Fixed Assets Which Are In The S4 Data
                            </label>
                        </div>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-6">
                            <input type="button" (click)="openReport('Discrepency')" class="btn btn-primary mr-2 vertical-align-unset"
                                value="Display" />
                            <input type="button" class="btn btn-primary mr-2 vertical-align-unset" (click)="actionAllDiscrepancyAssets()"
                                disabled value="Action All" />
                            <input type="text" class="form-control d-inline-block w-auto " formControlName="Asset_Discrepancies_Count"
                                readonly />
                        </div>
                        <div class="col-6">
                            <label class="mb-0">Number Of Fixed Assets Which Have
                                <span class="text-warning"><b>Discrepancies</b></span>
                                Between What Has Been
                                <span class="text-danger"><b>Imported</b></span>
                                From S4 and What
                                <span class="text-danger"><b>Exists</b></span>
                                In AMD
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </mat-card>
</div>

<app-display-comparison-report *ngIf="showReports" [datavalue]="reportType"
    [dataType]="reportType === 'Discrepency' ? reportsData[0] : null" (backClick)="hideReport($event)">
</app-display-comparison-report>