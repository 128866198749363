import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as XLSX from 'xlsx';
import * as Excel from 'exceljs';
import { amdasset } from 'src/app/models/amdasset'
import * as fs from 'file-saver';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-data-verification',
  templateUrl: './data-verification.component.html',
  styleUrls: ['./data-verification.component.scss']
})

export class DataVerificationComponent implements OnInit {

  userName: any;
  assetDetails: any;
  FinalAdminAssetData: any;
  AllFinalAdminAssetData: any;
  showGrid!: boolean;
  noRecord: boolean = false;
  headers: any;
  rowCount: any
  dataSource: any;
  assetdetails1!: FormGroup;
  IsdisableEdit!: boolean;
  displayedColumns = ['userId']
  ascendingsort: boolean | null = true;
  columnindex: any
  currentPage: any
  tableCheckAllBox = false
  tableCheckAllBoxprint = false
  checkItem = []
  buildings: any
  asset: any = []
  saveAsset: any = []
  assetdata: any
  arr1: any[] = [];
  arr2: any[] = [];
  arr3: any[] = [];
  gridData: any = [];
  excelData: any = [];
  excelData1: any = [];
  loading = false;
  dataloading = false;
  loaded: any
  popupbox = false
  show: any
  foundText = 'Asset Updated and available'
  pageLength: any = []
  filterText: any = []
  foundAsset: any
  filterKey: any = []
  tableHeaderArray: any = []
  progressUpload = false
  updateTable = false
  progressIcon = "fa fa-circle-o-notch fa-spin"
  progressText = "Uploading File"
  tableProgressIcon = ""
  tableProgressText = ""
  filter: any = {}
  fileName = 'Verfication-Sheet.xlsx';
  allAssets: any = [];
  chunkSaved: boolean = false;
  chunkCount = 0;
  chunksCompleted = 0;
  progress: any;
  subscriptions: Subscription[] = [];
  constructor(private fb: FormBuilder, private common: CommonService, private router: Router, private toastr: ToastrService) {
    this.common.bannerData.next({ title: 'AMD', subtitle: 'Fixed Asset Verification' });
  }


  ngOnInit() {
    // if (sessionStorage.getItem('flag') === 'true') {
    //     this.locloading = false;
    // }
    sessionStorage.removeItem('flag');
    this.IsdisableEdit = true;
    this.showGrid = true;
    this.show = false;
    this.assetdetails1 = this.fb.group({
      Main_Asset_Number: [''],
      Asset_Location: [''],
      Additional_Description: [''],
      Asset_Supplementary_Inventory_Specifications_Name: [''],
      Cost_Object_Manager_Email_ID: ['']
    });

    if (this.common.getUserRole() === 'User') {
      this.IsdisableEdit = false;
    }
    if (this.common.getUserRole() === 'Admin') {
      this.showGrid = true;
      this.show = true;
      this.getAMDbuildings();

      this.showGrid = false;
      this.show = true;

      if (this.common.assetFilter != null) {
        this.showGrid = true;
        this.common.loader.next(true);
        for (let i in this.common.assetFilter) {
          if (this.common.assetFilter[i] !== "null") {
            this.filter[i] = this.common.assetFilter[i];
          } else {
            this.filter[i] = '';
          }
        }

        this.common.postAPI('amd-get-admin-asset/', this.assetdetails1.value).subscribe({
          next: (data: any) => {
            this.asset = data['body'];
            this.common.loader.next(false);

            let arr1 = this.asset.filter((amd: any) => amd.SortKey === 'AMD');
            let arr2 = this.asset.filter((amd: any) => amd.SortKey === 'Found');
            let arr3 = this.asset.filter((amd: any) => amd.SortKey === 'S4');

            let finalAdminAssetArray = [];
            for (let i = 0; i < arr1.length; i++) {
              for (let j = 0; j < arr3.length; j++) {
                if (arr1[i]['Asset_Number'] === arr3[j]['Asset_Number']) {
                  const loc: amdasset = {
                    Asset_Number: arr1[i]['Asset_Number'],
                    Asset_Description: arr1[i]['Asset Description'],
                    Original_Asset_Number: arr3[j]['Original Asset Number'],
                    Asset_Location: arr1[i]['Building'],
                    Serial_Number: arr1[i]['Serial Number'],
                    LabFab_Name: arr1[i]['LabFab_Name'],
                    Comments: arr1[i]['Asset Comment'],
                    FA_Owner: arr1[i]['Owner'],
                    Found_Date: arr1[i]['Found Date'],
                    Found_Person: arr1[i]['Found Person']
                  };
                  finalAdminAssetArray.push(loc);
                }
              }
            }
            for (let i = 0; i < finalAdminAssetArray.length; i++) {
              for (let j = 0; j < arr2.length; j++) {
                if (finalAdminAssetArray[i]['Asset_Number'] === arr2[j]['Asset_Number']) {
                  finalAdminAssetArray[i]['Found_Date'] = arr2[j]['Found Date'];
                  finalAdminAssetArray[i]['Found_Person'] = arr2[j]['Found Person'];
                }
              }
            }

            this.AllFinalAdminAssetData = finalAdminAssetArray;
            this.FinalAdminAssetData = finalAdminAssetArray;
            this.asset = this.getUpdatedArray(this.FinalAdminAssetData);

            this.rowCount = this.FinalAdminAssetData.length;

            if (this.rowCount === 0) {
              this.noRecord = true;
            } else {
              this.noRecord = false;
            }
            this.paginationData(this.asset);
          }
        });
      }
    }
    else {
      this.common.loader.next(true);
      const data = { "Username": atob(this.common.currentUser), "Role": this.common.getUserRole() };
      this.common.postAPI('amd-get-user-data/', data).subscribe({
        next: ((data: any) => {
          this.asset = this.getUpdatedArray(data);
          this.rowCount = data.length
          this.paginationData(this.asset);
          this.common.loader.next(false);
        }),
        error: (
          error => {
            //alert("Error in API call");
            this.toastr.error('Something went wrong', 'Error')
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });
    }
  }

  getAMDbuildings() {
    this.common.loader.next(true);
    this.subscriptions.push(
      this.common.getAPI('amd-get-building/').subscribe(
        {
          next: (
            (data: any) => {
              this.buildings = data;
              this.common.loader.next(false);
              for (let i = 0; i < this.buildings.length; i++) {
                let tmp: any = this.buildings[i]['Asset_Location'];
                this.arr1.push(tmp);
              }
              this.arr1 = this.arr1.filter(function (elem: any, index: any, self: any) {
                return index === self.indexOf(elem);
              })

              this.arr1.sort();
              this.common.loader.next(false);
            }
          ),
          error: (
            error => {
              this.common.errorHandler(error);
              this.common.loader.next(false);
            }
          )
        })
    );
  }


  GetSortOrder(prop: any) {
    return function (a: any, b: any) {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    }
  }


  SearchProduct() {
    let value = this.assetdetails1.get("Asset_Location")?.value;

    this.arr2 = []
    {

      for (let i = 0; i < this.buildings.length; i++) {
        if (this.buildings[i]['Asset_Location'] == this.assetdetails1.get("Asset_Location")?.value) {
          let tmp = this.buildings[i]['Lab_name'];
          this.arr2.push(tmp);
        }
      }
      this.arr2 = this.arr2.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      })
      this.arr2.sort();
      this.assetdetails1.get("Asset_Supplementary_Inventory_Specifications_Name")?.setValue("")
    }
  }
  edit(assetnumber: number) {
    console.log(assetnumber);
    sessionStorage['flag'] = true;
    this.router.navigate(['/assetRequestChange/edit-fixed-asset-verification'], { queryParams: { assetid: assetnumber } });
  }

  onSubmit() {
    this.assetDetails = null;
    this.showGrid = false;

    if (this.assetdetails1.get('Main_Asset_Number')?.value == "" &&
      this.assetdetails1.get('Asset_Location')?.value == "" &&
      this.assetdetails1.get('Additional_Description')?.value == "" &&
      this.assetdetails1.get('Asset_Supplementary_Inventory_Specifications_Name')?.value == "" &&
      this.assetdetails1.get('Cost_Object_Manager_Email_ID')?.value == "") {
      //alert("Please fill atleast one field to continue");
      this.toastr.warning('Please fill atleast one field to continue', 'Warning');
      return false;
    }
    else {
      Object.keys(this.assetdetails1.controls).forEach(key => {
        if (this.assetdetails1.get(key)?.value == "") {
          this.assetdetails1.get(key)?.setValue("null");
        }
      });
      this.common.loader.next(true);
      this.common.assetFilter = this.assetdetails1.value
      this.getAdminAsset();

      Object.keys(this.assetdetails1.controls).forEach(key => {
        if (this.assetdetails1.get(key)?.value == "null") {
          this.assetdetails1.get(key)?.setValue("");
        }
      });
      this.show = true;
      this.showGrid = true;
    }
    return false;
  }

  getAdminAsset() {
    this.common.loader.next(false);
    this.common.postAPI('amd-get-admin-asset/', this.assetdetails1.value).subscribe({
      next: (data: any) => {
        this.asset = data['body'];
        let arr1 = this.asset.filter((amd: any) => amd.SortKey === 'AMD');
        let arr2 = this.asset.filter((amd: any) => amd.SortKey === 'Found');
        let arr3 = this.asset.filter((amd: any) => amd.SortKey === 'S4');
        let finalAdminAssetArray: amdasset[] = [];

        for (let i = 0; i < arr1.length; i++) {
          for (let j = 0; j < arr3.length; j++) {
            if (arr1[i]['Asset_Number'] === arr3[j]['Asset_Number']) {
              const loc: amdasset = {
                Asset_Number: arr1[i]['Asset_Number'],
                Asset_Description: arr1[i]['Asset Description'],
                Original_Asset_Number: arr3[j]['Original Asset Number'],
                Asset_Location: arr1[i]['Building'],
                Serial_Number: arr1[i]['Serial Number'],
                LabFab_Name: arr1[i]['LabFab_Name'],
                Comments: arr1[i]['Asset Comment'],
                FA_Owner: arr1[i]['Owner'],
                Found_Date: arr1[i]['Found Date'],
                Found_Person: arr1[i]['Found Person'],
              };
              console.log("Loc: ", loc);
              finalAdminAssetArray.push(loc);
            }
          }
        }

        for (let i = 0; i < finalAdminAssetArray.length; i++) {
          for (let j = 0; j < arr2.length; j++) {
            if (finalAdminAssetArray[i]['Asset_Number'] === arr2[j]['Asset_Number']) {
              finalAdminAssetArray[i]['Found_Date'] = arr2[j]['Found Date'];
              finalAdminAssetArray[i]['Found_Person'] = arr2[j]['Found Person'];
            }
          }
        }

        this.AllFinalAdminAssetData = finalAdminAssetArray;
        this.FinalAdminAssetData = finalAdminAssetArray;
        console.log("Final Admin asset data",this.FinalAdminAssetData);

        this.asset = this.getUpdatedArray(this.FinalAdminAssetData);
        console.log(this.asset);

        this.rowCount = this.FinalAdminAssetData.length;
        this.noRecord = this.rowCount === 0;

        this.paginationData(this.asset);
      },
    });
  }

  pageNext(pageNumber: any) {
    this.currentPage = pageNumber
    let assetData = this.gridData;
    if (pageNumber === this.pageLength.length) {
      let len = pageNumber * 500
      let index = len - 500
      this.assetDetails = assetData.slice(index, assetData.length);
      this.FinalAdminAssetData = assetData.slice(index, assetData.length);
    } else {
      let len = pageNumber * 500
      let index = len - 500
      this.assetDetails = assetData.slice(index, len);
      this.FinalAdminAssetData = assetData.slice(index, assetData.length);
    }

  }

  paginationData(element: any) {
    this.pageLength = []
    this.gridData = element;
    let length = element.length / 500;
    if (length == 0) {
      this.pageLength[0] = 1;
    } else {
      for (let index = 0; index < length; index++) {
        this.pageLength[index] = index + 1;
      }
    }
    this.pageNext(1)
  }

  sortData(index: any, value: any) {
    let columnData: any = []
    console.log("Sorting")
    for (let i = 0; i < this.gridData.length; i++) {
      columnData.push(this.gridData[i][value])
    }
    console.log(columnData);
    if (this.columnindex === index) {
      this.ascendingsort = !this.ascendingsort
    } else {
      this.columnindex = index
      this.ascendingsort = true
    }
    this.switchSortMethod(columnData, value)
  }

  switchSortMethod(columnData: any = [], value: any) {
    if (this.ascendingsort) {
      columnData.sort((a: any, b: any) => {
        if (typeof a === 'string' && typeof b === 'string') {
          return a.toLowerCase().localeCompare(b.toLowerCase(), 'en', { numeric: true })
        } else {
          return a - b;
        }
      });
      let sortedAsset = this.getSortedData(columnData, value)
      this.paginationData(sortedAsset)
    } else {
      columnData.sort((a: any, b: any) => {
        if (typeof a === 'string' && typeof b === 'string') {
          return b.toLowerCase().localeCompare(a.toLowerCase(), 'en', { numeric: true })
        } else {
          return b - a;
        }
      });
      let sortedAsset = this.getSortedData(columnData, value)
      this.paginationData(sortedAsset)
    }
  }

  getSortedData(sortedData: any, value: any) {
    sortedData.forEach((mainData: any, index: any) => {
      for (let i = index + 1; i < sortedData.length; i++) {
        if (sortedData[index] === sortedData[i]) {
          sortedData.splice(i, 1)
          i = i - 1
        }
      }
    });
    let finalAssetDetails: any = []
    sortedData.forEach((data: any) => {
      for (let i = 0; i < this.gridData.length; i++) {
        if (this.gridData[i][value] === data) {
          finalAssetDetails.push(this.gridData[i])
        }
      }
    });
    return finalAssetDetails;
  }

  filterValue(inputData: any, indexValue: any, keyValue: any) {
    let filterData = this.asset;
    this.filterKey[indexValue] = keyValue;
    for (let index = 0; index < inputData.length; index++) {
      let tempFilterData = []
      if (inputData[index] != undefined && inputData[index] != '') {
        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i][this.filterKey[index]] != undefined) {
            if (filterData[i][this.filterKey[index]].toLowerCase().includes(inputData[index].toLowerCase())) {
              tempFilterData.push(filterData[i]);
            }
          }
        }
        filterData = tempFilterData;
      }
    }
    this.ascendingsort = null;
    this.paginationData(filterData);
  }

  checkUncheckAllprint() {
    let len = this.asset.length;
    for (let index = 0; index < len; index++) {
      this.asset[index].Checkbox_PrintData = this.tableCheckAllBoxprint
    }
  }

  checkUncheckAll() {
    let len = this.asset.length;
    for (let index = 0; index < len; index++) {
      this.asset[index].Checkbox_Data = this.tableCheckAllBox
    }
  }

  originalOrder = (a: any, b: any) => { return 0 }

  getUpdatedArray(assetArray: any) {
    console.log("Data in getUpdatedArray: ", assetArray);
    let columnOrder = ["Asset_Number", "Original_Asset_Number", "Asset_Description", "Serial_Number", "Asset_Location", "LabFab_Name", "Comments", "FA_Owner", "Found_Date", "Found_Person"]
    this.tableHeaderArray = columnOrder;
    let finalAssetArray = []
    for (let i = 0; i < assetArray.length; i++) {
      let tempAssetArray: any = {}
      for (let j = 0; j < columnOrder.length; j++) {
        tempAssetArray[columnOrder[j]] = assetArray[i][columnOrder[j]];
      }
      finalAssetArray.push(tempAssetArray);
      //console.log(tempAssetArray);
    }
    console.log(finalAssetArray);
    return finalAssetArray
  }

  downloadExcelFile() {
    let data = this.asset
    let workBook = XLSX.utils.book_new()
    let group = data.reduce((acc: any, item: any) => {
      item['Status'] = "Not Found"
      item['Found_Comments'] = "Asset available and updated"
      if (!acc[item.Asset_Supplementary_Inventory_Specifications_Name]) {
        acc[item.Asset_Supplementary_Inventory_Specifications_Name] = [];
      }
      acc[item.Asset_Supplementary_Inventory_Specifications_Name].push(item);
      return acc;
    }, {})
    for (let [key] of Object.entries(group)) {
      let workSheet = XLSX.utils.json_to_sheet(group[key])
      XLSX.utils.book_append_sheet(workBook, workSheet, String(key).replace(/\//g, '|').replace(/\\/g, '|'))
    }
    XLSX.writeFile(workBook, "FoundAsset.xlsx")
  }

  uploadExcelFile(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    this.progressUpload = true
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      console.log(wb.SheetNames);
      let progress = 0
      let length = wb.SheetNames.length
      const data = reader.result;
      let jsonData = null;
      jsonData = wb.SheetNames.reduce((initial, name) => {
        const sheet = wb.Sheets[name];
        this.excelData = this.excelData.concat(XLSX.utils.sheet_to_json(sheet));
        progress = progress + 1
        if (progress === length) {
          this.setProgressUploadData()
        }
        return initial;
      }, {});
      var arr = this.excelData.filter(function (item: any) {
        return item.Status == "Found" || item.Status == "found";
      });

      console.log("File Uploaded");
      //console.log(JSON.stringify(this.excelData,['Main_Asset_Number','Asset_Supplementary_Inventory_Specifications_Name','Asset_Location','Found_Comments','Status']));

      const requestData = JSON.stringify(arr, [
        'Main_Asset_Number',
        'Asset_Supplementary_Inventory_Specifications_Name',
        'Asset_Location',
        'Found_Comments',
        'Status'
      ]);

      this.common.postAPI('amd-add-found-assets', { "Data": requestData }).subscribe({
        next: (data: any) => {
          if (data.statusCode === 200) {
            this.setTableUpdateData();
          } else {
            console.log(data);
          }
        },
        error: (err) => {
          // Handle errors if necessary
          console.error('Error in API call:', err);
        }
      });
    },
      reader.readAsBinaryString(target.files[0]);
  }



  getFoundPopup(assetItem: any) {
    this.foundAsset = assetItem;
    this.popupbox = true
  }

  closePopup() {
    this.popupbox = false
  }

  setFoundData() {
    console.log(this.foundAsset);
    const assetData = {
      "FA_Asset_Number": this.foundAsset['Main_Asset_Number'],
      "FA_Found_Commants": this.foundText,
      "FA_Found_LabName": this.foundAsset['Asset_Supplementary_Inventory_Specifications_Name'],
      "FA_Found_LocationID": this.foundAsset['Asset_Location'],
      "FA_Found_UserName": atob(this.common.currentUser)
    };
    this.common.postAPI('amd-add-found-asset', { "Data": assetData }).subscribe({
      next: (data: any) => {
        console.log(data);

        window.location.reload();
      },
      error: (err) => {
        console.error('Error adding found asset:', err);
      }
    });
  }

  setProgressUploadData() {
    this.progressIcon = "fa fa-check"
    this.progressText = "File Uploaded"
    this.updateTable = true
    this.tableProgressIcon = "fa fa-circle-o-notch fa-spin"
    this.tableProgressText = "Updating Table"
  }

  setTableUpdateData() {
    this.tableProgressIcon = "fa fa-check"
    this.tableProgressText = "Table Updated"
    window.location.reload();
  }

  uploadSpreadsheet(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
      console.log(wb.SheetNames);
      let length = wb.SheetNames.length;
      // console.log(length);
      const data = reader.result;
      let jsonData = null;
      // var sheet1 = wb.SheetNames[1];
      jsonData = wb.SheetNames.reduce((initial, name) => {
        console.log(name);
        const sheet = wb.Sheets[name];
        this.excelData1 = this.excelData.concat(XLSX.utils.sheet_to_json(sheet, { range: 1, defval: "null" }));
        return initial;
      }, {});
      this.userName = atob(this.common.currentUser);
      for (let i in this.excelData1) {
        // console.log(this.excelData[i]);
        this.excelData1[i]['userName'] = this.userName;
        replace(this.excelData1[i])
        function replace(myObj: any) {
          Object.keys(myObj).forEach(function (key) {

            if (myObj[key] instanceof Date) {
              if (myObj[key]) {
                let date = new Date(myObj[key]);
                // date = new Date(date.getTime()-(myObj[key].getTimezoneOffset()*60*1000));
                // myObj[key] = String((date.getMonth()+1)+"/"+date.getDate()+"/"+date.getFullYear());
                let offSet = myObj[key].getTimezoneOffset();
                if (offSet > 0) {
                  date = new Date(date.getTime() + (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
                else {
                  date = new Date(date.getTime() - (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
              }
              else {
                myObj[key] = "";
              }
            }
            else {
              typeof myObj[key] == 'object' ? replace(myObj[key]) : myObj[key] = String(myObj[key]);
            }
          });
        }
      }
      console.log(this.excelData1);

      this.common.postAPI('amd-upload-found-asset', { "Data": this.asset }).subscribe({
        next: (data: any) => {
          if (data['statusCode'] === 200) {
            //alert('File Imported Successfully');
            this.toastr.success('File Imported Successfully', 'Success');
            let currentUrl = this.router.url;
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([currentUrl]);
          } else {
            //alert('Failed to import asset');
            this.toastr.error('Failed to import asset', 'Error');
          }
          reader.readAsBinaryString(target.files[0]);
        },
        error: (err) => {
          // Handle potential errors here
          console.error('Error during file import:', err);
          //alert('An error occurred while importing the asset');
          this.toastr.error('An error occurred while importing the asset', 'Error');
        }
      });
    }
  }


  saveAs(assetData: any) {
    console.log(this.AllFinalAdminAssetData);
    var printasset = []
    for (let i = 0; i < assetData.length; i++) {
      for (let j = 0; j < this.AllFinalAdminAssetData.length; j++) {
        if (assetData[i]['Asset_Number'] === this.AllFinalAdminAssetData[j]['Asset_Number']) {
          if (assetData[i]['Checkbox_PrintData']) {
            printasset.push(this.AllFinalAdminAssetData[j]);
          }
        }
      }
    }
    console.log(printasset);

    //console.log(this.saveAsset);
    if (printasset.length === 0) {
      //alert("Please select atleast one data to Download Labels for Print")
      this.toastr.warning('Please select atleast one data to Download Labels for Print', 'Warning')
    }
    else {

      this.common.setAssetData(printasset);
      this.router.navigate(['/assetRequestChange/print-verification']);
    }

  }

  updateFound(assetdata: any) {
    let userName = atob(this.common.currentUser);
    let DateObj = new Date();
    let date = ('0' + (DateObj.getMonth() + 1)).slice(-2) + '/' + ('0' + DateObj.getDate()).slice(-2) + '/' + DateObj.getFullYear();
    console.log(assetdata);
    this.saveAsset = [];
    assetdata.forEach((element: any) => {
      if (element.Checkbox_Data) {
        element.Found_Date = new Date();
        element.Found_Person = "";
        this.saveAsset.push({ "Asset Number": element.Asset_Number, "Found Date": date, "Found Person": userName })
      }
    });
    console.log(this.saveAsset);
    if (this.saveAsset.length == 0) {
      //alert("Please select atleast one data for found")
      this.toastr.warning('Please select atleast one data for found', 'Warning');
    }
    else {
      this.common.postAPI('amd-edit-found-asset', { "Data": this.saveAsset }).subscribe({
        next: (data: any) => {
          console.log('Call Successful');
          if (data['statusCode'] === 200) {
            //alert('Update Found Successfully');
            this.toastr.success('Update Found Successfully', 'Success')
            let currentUrl = this.router.url;
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([currentUrl]);
          } else {
            //alert('Failed');
            this.toastr.error('There has been an error', 'Error')

          }
        },
        error: (err: any) => {
          console.error('API call failed', err);
          //alert('An error occurred while updating.');
          this.toastr.error('An error occurred while updating.', 'Error')
        }
      });
    }
  }

  exportFoundAndNotFoundReport() {
    this.loading = true
    this.callAPI(507000000000, 513000000000);
  }

  callAPI(start: any, end: any) {
    console.log(start);
    console.log(end);
    let tempData: any = [];
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      this.common.postAPI('amd-export-found-and-not-found-asset/', { start: start, end: end }).subscribe({
        next: (data: any) => {
          tempData = data['body'];
          console.log(data['statusCode']);
          console.log(tempData.length);
          this.chunkSaved = false;

          if (data['statusCode'] == 200) {
            if (tempData.length != 0) {
              this.allAssets = this.allAssets.concat(data['body']);
              this.callAPI(end, end + 1000);
              this.chunkCount = data['assetCount'];
              this.chunksCompleted = this.chunksCompleted + data['assetImported'];
              console.log(this.chunkCount);
              console.log(this.chunksCompleted);
              this.progress = ((this.chunksCompleted / this.chunkCount) * 100).toFixed(2);
            } else {
              this.createFoundAndNotFoundReport(this.allAssets);
              this.chunkCount = 0;
              this.chunksCompleted = 0;
              this.progress = 0;
            }
          } else {
            //alert('Failed to download file');
            this.toastr.error('Failed to download file', 'Error')
          }
        },
        error: (err: any) => {
          console.error('API call failed', err);
          this.chunkSaved = false;
          //alert('An error occurred while processing the API call.');
          this.toastr.error('An error occurred while processing the API call.', 'Error')
        }
      });
    }
  }

  createFoundAndNotFoundReport(data: any) {

    console.log(this.allAssets);
    let columnOrder = ["", "Company Code", "PartitionKey", "Original Asset Number", "Asset Class Code", "Asset Description", "Additional Description",
      "Serial Number", "Asset Supplementary Inventory Specifications Name", "Asset Location", "Ship-to Customer Name1",
      "Asset Capitalization Date", "Cost Center Code", "Cost Object Manager Email ID", "MRU Code", "Profit Center Code",
      "Cost Center Hier Desc Level1", "Asset Super Number", "WBS Element Maintenance Project", "Depreciation Terms Start Date",
      "Depreciation Terms Useful Life in Years", "Acquisition Value", "Period Posted total Depreciation Including Value Adjustments",
      "PE Net Book Value", "PE Depreciation For Posted Period", "Evaluation Group1 Code", "Asset Inventory Number",
      "Asset Value Date of the First Posting (Supplementary_Text)", "Asset Type Name", "Real Depreciation Area",
      "Asset Active Listing", "Depreciation Terms Useful Life in Months", "Depreciation Method Code",
      "Asset Last Inventory Date", "Asset Manufacturer of Asset Description", "Agreement Number", "Asset Active Listing",
      "Personnel Number", "Purchase Order Currency Key", "Asset Scrap Value Currency Code", "Asset Comment",
      "Found Date", "Found Person", "Retirement Type", "Method of Verification", "Verification Status"];

    let workbook = new Excel.Workbook();

    let worksheet = workbook.addWorksheet('Assets Valuation');

    let DateObj = new Date();
    let date = ('0' + (DateObj.getUTCMonth() + 1)).slice(-2) + ('0' + DateObj.getUTCDate()).slice(-2) + DateObj.getUTCFullYear();
    let docname = "Asset Verification " + date;

    let titleValues1 = [""];
    worksheet.addRow(titleValues1)

    let headerRow = worksheet.addRow(columnOrder);
    let data1 = [];
    for (let d1 of data) {

      if (d1['Found Person'] == "null" || d1['Found Person'] == "NULL" || d1['Found Person'] == "" || d1['Found Person'] == null) {
        d1['Found Person'] = "";
        d1['Found Date'] = "";
      }
      else {
        d1['Method of Verification'] = "Physical Verification";
        d1['Verification Status'] = "Verified"
        if (d1['Change Type'] == "Retired" && d1['Change Status'] == "Approved") {
          // d1['Found Person'] = "";
          // d1['Found Date'] = "";
        }
        else {
          d1['Retirement Type'] = "Found – Still in Use"
        }
      }
      if (d1['Change Type'] == "Retired" && d1['Change Status'] == "Approved") {
        // d1['Found Person'] = "";
        // d1['Found Date'] = "";
      }

      data1.push(d1);
    }

    for (let x1 of data1) {
      let x2 = columnOrder;
      let temp = []
      for (let y of x2) {
        if (x1[y] == 'null' || x1[y] == 'NULL' || x1[y] == 'Null') {
          x1[y] = ''
        }
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    worksheet.getCell('C2').value = 'Main Asset Number';
    worksheet.getCell('K2').value = 'Ship-to Customer Name1';
    worksheet.getCell('L2').value = 'Asset Capitalization Date';
    worksheet.getCell('O2').value = 'MRU Code';
    worksheet.getCell('P2').value = 'Profit Center Code';
    worksheet.getCell('Q2').value = 'Cost Center Hier Desc Level1';
    worksheet.getCell('R2').value = 'Asset Super Number';
    worksheet.getCell('S2').value = 'WBS Element Maintenance Project';
    worksheet.getCell('T2').value = 'Depreciation Terms Start Date';
    worksheet.getCell('U2').value = 'Depreciation Terms Useful Life in Years';
    worksheet.getCell('V2').value = 'Acquisition Value';
    worksheet.getCell('W2').value = 'Period Posted total Depreciation Including Value Adjustments';
    worksheet.getCell('X2').value = 'PE Net Book Value';
    worksheet.getCell('Y2').value = 'PE Depreciation For Posted Period';
    worksheet.getCell('Z2').value = 'Evaluation Group1 Code';
    worksheet.getCell('AA2').value = 'Asset Inventory Number';
    worksheet.getCell('AB2').value = 'Asset Value Date of the First Posting (Supplementary_Text)';
    worksheet.getCell('AC2').value = 'Asset Type Name';
    worksheet.getCell('AD2').value = 'Real Depreciation Area';
    worksheet.getCell('AE2').value = 'Asset Active Listing';
    worksheet.getCell('AF2').value = 'Depreciation Terms Useful Life in Months';
    worksheet.getCell('AG2').value = 'Depreciation Method Code';
    worksheet.getCell('AH2').value = 'Asset Last Inventory Date';
    worksheet.getCell('AI2').value = 'Asset Manufacturer of Asset Description';
    worksheet.getCell('AJ2').value = 'Agreement Number';
    worksheet.getCell('AK2').value = 'Asset Active Listing';
    worksheet.getCell('AL2').value = 'Personnel Number';
    worksheet.getCell('AM2').value = 'Purchase Order Currency Key';
    worksheet.getCell('AN2').value = 'Asset Scrap Value Currency Code';
    worksheet.getCell('AO2').value = 'Comment';
    worksheet.getCell('AR2').value = 'Asset Status';
    worksheet.getCell('B1').value = 'File Name : ' + docname;

    worksheet.getRow(2).height = 50;
    worksheet.getRow(2).font = {
      name: 'HP Simplified',
      size: 9,
      bold: true,
      color: { argb: 'ffffff' }
    };
    worksheet.getRow(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C65911' }
    };

    ['K2', 'L2', 'Q2', 'R2', 'Y2', 'Z2', 'AA2',
      'AB2', 'AC2', 'AD2', 'AE2', 'AF2', 'AG2', 'AH2',
      'AI2', 'AL2', 'AM2', 'AN2'].map(key => {
        worksheet.getCell(key).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '0096D6' },
        };
      });

    ['AO2', 'AP2', 'AQ2', 'AR2', 'AS2', 'AT2'].map(key => {
      worksheet.getCell(key).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00B050' },
      };
    });

    ['B1', 'C1', 'D1'].map(key => {
      worksheet.getCell(key).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' },
      };
    });

    data = [];
    data1 = [];
    this.allAssets = [];
    this.loading = false

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, docname + '.xlsx');
    });
  }

  exportFound(data: any) {
    console.log(data);

    let DateObj = new Date();

    let time = ('0' + (DateObj.getUTCHours())).slice(-2) + "-" + ('0' + (DateObj.getUTCMinutes())).slice(-2)
    let date = ('0' + (DateObj.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + DateObj.getUTCDate()).slice(-2) + '-' + DateObj.getUTCFullYear();

    let building = data[0]["LabFab_Name"]
    for (let d of data) {
      console.log(d["LabFab_Name"]);
      if (d["LabFab_Name"] == building) {
        building = d["LabFab_Name"]
      }
      else {
        building = "";
        break;
      }
    }

    let fileName = "Verification_" + data[0]["Asset_Location"] + "_" + building + "_" + date + "_" + time;
    console.log(fileName)

    let columnOrder = ["Asset_Number", "Original_Asset_Number", "Asset_Description", "Serial_Number", "Asset_Location", "LabFab_Name", "Comments", "FA_Owner", "Found_Date", "Found_Person"];

    let workbook = new Excel.Workbook();

    //let worksheet = workbook.addWorksheet('Asset Creation Templ1');
    let worksheet = workbook.addWorksheet('Verfication Assets');


    let titleValues1 = [""];
    worksheet.addRow(titleValues1)

    let headerRow = worksheet.addRow(columnOrder);
    for (let x1 of data) {
      let x2 = columnOrder;
      let temp = []
      for (let y of x2) {
        if (x1[y] == "NULL" || x1[y] == "null") {
          x1[y] = ""
        }
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    worksheet.getCell('A2').value = 'Asset Number';
    worksheet.getCell('B2').value = 'Original Asset Number';
    worksheet.getCell('C2').value = 'Asset Description';
    worksheet.getCell('D2').value = 'Serial Number';
    worksheet.getCell('E2').value = 'Asset Location';
    worksheet.getCell('F2').value = 'LABFAB Name';
    worksheet.getCell('G2').value = 'Comments';
    worksheet.getCell('H2').value = 'FA Owner';
    worksheet.getCell('I2').value = 'Found Date';
    worksheet.getCell('J2').value = 'Found Person';


    workbook.xlsx.writeBuffer().then((buffer: any) => {
      let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName + '.xlsx');
    });

  }
  uploadAssetVerification() {

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
