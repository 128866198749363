import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, FormsModule, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-comparison-report',
  templateUrl: './comparison-report.component.html',
  styleUrls: ['./comparison-report.component.css']
})
export class ComparisonReportComponent implements OnInit {
  show!: boolean;
  showGrid!: boolean;
  IsdisableEdit!: boolean;
  assetid!: string;
  tableCheckAllBox = false;
  ascendingsort = true;
  loading: any;
  dataloading = true;
  columnindex: any;
  rowCount: any;
  excel!: number;
  total!: number;
  count!: number;
  tableHeaderArray: any[] = [];
  assetcountdetails: FormGroup = this.fb.group({
    Asset_Total_Count: [''],
    Asset_Missing_Count: [''],
    Asset_Inactive_Count: [''],
    Asset_Discrepancies_Count: ['']
  });
  assetDetails: any;
  assetdata: any;
  noRecord = false
  progressUpload = false;
  chunkCount: number = 0;
  chunkSaved: boolean = false;
  chunkSize: number = 200;
  progress: any;
  chunkCompleted = 0;
  startedImporting = false;
  reportType!: string;
  showReports: boolean = false;
  reportsData: any[] = [];
  constructor(private common: CommonService, private fb: FormBuilder, private router: Router) {
    this.common.bannerData.next({ title: 'Report', subtitle: 'Comparison Report For The Import - Summary' });
  }
  ngOnInit() {

    this.common.loader.next(true);

    forkJoin(
      [
        this.common.getAPI('amd-get-coparison-asset-count/'),
        this.common.getAPI('amd-get-inactive-asset-count/'),
        this.common.getAPI('amd-display-discrepancies/')
      ]).subscribe({
        next: ([res1, res2, res3]) => {
          this.reportsData.push(res3);

          // //response 1
          this.assetcountdetails.controls['Asset_Total_Count'].setValue(res1[1]);
          if (this.common.importedAsset == null) {
            this.assetcountdetails.controls['Asset_Missing_Count'].setValue("0");
          } else {
            this.getMissingData();
          }

          //response 2
          if (res2['statusCode'] == 200) {
            this.assetcountdetails.controls['Asset_Inactive_Count'].setValue(res2['body']);
          }
          else {
            this.common.show('error', "Oops!...Something Went Wrong In Getting Inactive Count.");
          }

          //response 3
          if (res3['statusCode'] == 200) {
            this.assetcountdetails.controls['Asset_Discrepancies_Count'].setValue(res3['body']?.length)
          }
          else {
            this.common.show('error', "Oops!...Something Went Wrong In Getting Discrepancies Count.");
          }
          this.dataloading = false;
          this.common.loader.next(false);
        },
        error: (
          error => {
            this.common.errorHandler(error);
            this.common.loader.next(false);
          }
        )
      });

  }

  getMissingData() {
    this.common.postAPI('dev/amd-check-missing/', this.common.importedAsset).subscribe({
      next: (data: any) => {
        this.common.displayMissingAsset = data['amd'];
        if (data['statusCode'] == 200) {
          this.assetcountdetails.controls['Asset_Missing_Count'].setValue(data['body']);
        }
        else {
          this.common.show('error', "Oops!...Something Went Wrong In Getting Missing Count.");
        }
      },
      error: (
        error => {
          this.common.errorHandler(error);
          this.common.loader.next(false);
        }
      )
    });
  }
  onSubmit() {
    this.common.loader.next(true);
    this.common.getAPI('amd-getting-new-s4data/').subscribe({
      next: (data: any) => {
        this.assetdata = data['body'];
        this.chunkSize = this.assetdata.length;
        this.chunkCount = (this.chunkSize % 200 === 0 ? (this.chunkSize / 200) : (Math.floor(this.chunkSize / 200) + 1));
        this.chunkSaved = false;
        this.callApi(this.assetdata, 0, 200);
        this.common.loader.next(false);
      },
      error: (
        error => {
          this.common.errorHandler(error);
          this.common.loader.next(false);
        }
      )
    });
    this.common.show('info' ,"You Clicked To Perform Action.. Wait For The Response..");
    this.startedImporting = true;
  };

  callApi(given: any, start: any, end: any) {
    if ((this.chunkSize - start) < 200) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = given.slice(start, end);
      this.common.postAPI('amd-update-s4-data-to-amd/', param).subscribe((data: any) => {
        this.chunkSaved = false;
        if (data['statusCode'] == 200) {
          if (end < this.chunkSize) {
            this.callApi(given, start + 200,
              (this.chunkSize - start) < 200 ? ((this.chunkSize - start) + start) : (end + 200));
          } else {
            this.startedImporting = false;
            this.common.show('success', 'Action performed successfully! ');
            this.router.navigate(['asset']);
          }
        } else {
          this.startedImporting = false;
          this.common.show('error', 'Failed to perform action');
        }
      });
      this.chunkCompleted++;
      this.progress = ((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
    }
  }
  openReport(report: string) {
    this.showReports = true;
    this.reportType = report;
  }

  actionAllInactiveAssets() {
    this.startedImporting = true
    this.common.getAPI('amd-get-inactive-assets-for-actionall/').subscribe((data: any) => {
      this.assetdata = data['body']
      this.chunkSize = this.assetdata.length;
      this.chunkCount = (this.chunkSize % 200 === 0 ?
        (this.chunkSize / 200) :
        (Math.floor(this.chunkSize / 200) + 1));
      this.chunkSaved = false;
      this.callApiActive(this.assetdata, 0, 200);
    });
  }
  callApiActive(given: any, start: any, end: any) {
    if ((this.chunkSize - start) < 200) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = { "Data": given.slice(start, end) };
      this.common.postAPI('amd-action-all-inactive-assets/', param).subscribe((data: any) => {
        this.chunkSaved = false;
        if (data['statusCode'] == 200) {
          if (end < this.chunkSize) {
            this.callApiActive(given, start + 200,
              (this.chunkSize - start) < 200 ? ((this.chunkSize - start) + start) : (end + 200));
          } else {
            this.startedImporting = false;
            this.common.show('success', 'Action performed successfully! ');
            this.router.navigate(['asset']);
          }
        } else {
          this.startedImporting = false;
          this.common.show('error', 'Failed to perform action');
        }
      });
      this.chunkCompleted++;
      this.progress = ((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
    }
  }
  actionAllDiscrepancyAssets() {
    this.startedImporting = true
    this.common.getAPI('amd-get-discrepancy-asset-for-actionall/').subscribe((data: any) => {
      let tmp = data['body']
      let arr = []
      for (let index = 0, len = tmp.length; index < len; index++) {
        arr.push({ "partitionkey": tmp[index]['PartitionKey'] });
      }
      this.assetdata = arr
      this.chunkSize = this.assetdata.length;
      this.chunkCount = (this.chunkSize % 200 === 0 ?
        (this.chunkSize / 200) :
        (Math.floor(this.chunkSize / 200) + 1));
      this.chunkSaved = false;
      this.callApiDiscrepancy(this.assetdata, 0, 200);
    });
  }
  callApiDiscrepancy(given: any, start: any, end: any) {
    if ((this.chunkSize - start) < 200) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = { "Data": given.slice(start, end) };
      this.common.postAPI('amd-converting-discrepancies/', param).subscribe((data: any) => {
        this.chunkSaved = false;
        if (data['statusCode'] == 200) {
          if (end < this.chunkSize) {
            this.callApiActive(given, start + 200,
              (this.chunkSize - start) < 200 ? ((this.chunkSize - start) + start) : (end + 200));
          } else {
            this.startedImporting = false;
            this.common.show('success', 'Action performed successfully! ');
            this.router.navigate(['asset']);
          }
        } else {
          this.startedImporting = false;
          this.common.show('error', 'Failed to perform action');
        }
      });
      this.chunkCompleted++;
      this.progress = ((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
    }
  }

  hideReport(data: any) {
    this.showReports = data;
  }
}
