<mat-toolbar class="bg-primary">
    <p class="mb-0 text-white">Export Basic Changes S4</p>
</mat-toolbar>
<form class="container-fluid mt-3" [formGroup]="assetdetails1">
    <div class="row">
        <div class="col">
            
            <mat-form-field class="w-100">
                <mat-label>Modified Date</mat-label>
                <input matInput [formControl]="formControlDate" #input [matDatepicker]="picker" [max]="maxDate"
                    (focus)="picker.open()" (dateInput)="onChangeModifiedDate($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker (closed)="input.blur()" class="custom-datepicker-position"></mat-datepicker>
                <input class="d-none" matInput formControlName="Modified_Date">
            </mat-form-field>
            <label></label>
        </div>
    </div>
</form>
<mat-dialog-actions class="justify-content-end" style="margin-top: 21px;">
    <button type="submit" class="bg-primary text-white" mat-raised-button (click)="onSubmit()">Export</button>
    <button mat-raised-button (click)="close()">Cancel</button>
</mat-dialog-actions>
