<div class="row justify-content-center white-text orange-gradient text-white text-center font-weight-bold">
  <p class="mr-4">SITE : {{site}}</p>
  <p>BUILDING : {{building}}</p>
  <p>LABFAB NAME : {{labFabName}}</p>
</div>
<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
  <div class="table-container">
    <div class="table-header card-body white-text orange-gradient text-white text-center">
      <div>Name</div>
      <div>Primary</div>
      <div>Secondary</div>
      <div>Watchers</div>
    </div>
    <div class="table-body" formArrayName="data">
      <div *ngFor="let stakeholder of stakeholdersArray.controls; let i = index" [formGroupName]="i"
        class="table-row text-center">
        <div class="name-heading">
          {{ stakeholder.get('name')?.value || 'N/A' }}
        </div>
        <div>
          <mat-form-field class="col-md-3 input-field">
            <input matInput formControlName="primary" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="col-md-3 input-field">
            <input matInput formControlName="secondary" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="col-md-3 input-field">
            <input matInput formControlName="watchers" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt-4">
    <button class="btn btn-primary mr-2 mb-2" type="submit">Save</button>
    <button class="btn btn-primary mr-2 mb-2">Cancel</button>
  </div>
</form>