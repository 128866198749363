<mat-toolbar color="primary" class="orange-gradient text-white text-center">
    <mat-icon class="orange-gradient">laptop</mat-icon>
    <span class="titleFont">{{data.title}}</span>
    <button mat-icon-button class="ml-auto" [mat-dialog-close]="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </mat-toolbar>
  
  <div class="mat-elevation-z8">
    <div class="table-container" style="overflow: auto;">
      <table class="table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="UserName">
          <th mat-header-cell *matHeaderCellDef class="pd-0 w-22"> Name </th>
          <td mat-cell *matCellDef="let element" class="pd-0">
            <div *ngIf="!element.editing">
              {{element.UserName}}
            </div>
            <div *ngIf="element.editing">
              <mat-form-field appearance="fill" class="custom-form-field">
                <input matInput class="font-15" [(ngModel)]="element.UserName" (ngModelChange)="editField(element, 'UserName')" [disabled]="!element.Active">
                <button mat-icon-button matSuffix *ngIf="element.UserName" (click)="element.UserName=''">
                  <mat-icon class="iconAlign">clear</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef style="padding-left:2%;"> Actions </th>
          <td mat-cell *matCellDef="let element" class="pd-0 w-15 pL-1">
            <button *ngIf="element?.Active" mat-menu-item class="btn bg-primary text-white" (click)="edit(element)">Edit</button>
            <button *ngIf="element?.Active" mat-menu-item class="btn bg-primary text-white" (click)="deactivate(element, 'deactivate')" style="margin-left:1%; margin-top:1%;">Deactivate</button>
            <button *ngIf="!element?.Active" mat-menu-item class="btn bg-primary text-white" (click)="deactivate(element, 'activate')" style="margin-left:1%; margin-top:1%;">Activate</button>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="noData">
          <td mat-footer-cell *matFooterCellDef class="pd-0" colspan="2">
            <div style="display: flex;">
              <button class="nav-link btn btn-primary m-1" (click)="addRow()">Add Row</button>
              <button [disabled]="!submitEnable" class="nav-link btn btn-primary m-1" (click)="onSubmit()">Submit</button>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        <tr mat-footer-row *matFooterRowDef="['noData']; sticky: true"></tr>
      </table>
    </div>
  </div>
  