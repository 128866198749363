<div class="">
  <div class="">
    <button mat-mini-fab (click)="cancel()" class="backButton btn mr-2">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <ng-container *ngIf="assetDetails && showGrid">
      <app-export-bulk-data [Sheet_lineStart_Row]="'0'" [exportFileName]="'Export_Comparison_Report'" [apiPath]="' '"
        [Sheet_Coloumns_Headers]="exportHeaderArray" [Sheet_rows_data]="exportDataArray"
        [Button_Name]="'Export Comparison Report'" [Sheet_Name]="'Comparison_Report'">
      </app-export-bulk-data>
    </ng-container>
    <div class="my-3 d-md-flex" *ngIf="assetDetails && showGrid">
      <div class="col-6 p-0">
        <mat-card class="h-100">
          <mat-toolbar>
            <span>AMD Data</span>
          </mat-toolbar>
          <mat-card-content>
            <div class="form-group d-none">
              <input type="button" (click)="Converttoamd(assetDetails)"
                class="ml-auto btn btn-primary" value="Convert" />
            </div>
            <div class="table-responsive dataTable">
              <table id="dtHorizontalExample" class="mt-3 table table-bordered">
                <thead class="custfont">
                  <tr>
                    <th class="table-head" *ngFor="let title of tableHeaderArray; index as i">
                      <div *ngIf="title!='Checkbox_Data'" (click)="sortData1(i,title)">
                        <span>{{title.split("_").join(" ")}}</span>
                        <i *ngIf="i==columnindex && ascendingsort==true" class="fa fa-arrow-up"></i>
                        <i *ngIf="i==columnindex && ascendingsort==false" class="fa fa-arrow-down"></i>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="!noRecord">
                    <tr *ngFor="let item of amdassetDetails">
                      <td *ngFor="let data of item | keyvalue: originalOrder">
                        <span *ngIf="data.key!='Checkbox_Data'">{{data.value}}</span>
                      </td>
                    </tr>
                  </ng-container>
                  <span *ngIf="noRecord">No Records to Display!</span>
                </tbody>
              </table>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-6 p-0">
        <mat-card class="h-100">
          <mat-toolbar>
            <span>S4 Data</span>
            <button type="button" [disabled]="!isConvert" (click)="Converttoamd(asset)"
              class="ml-auto w-50 btn btn-primary">
              <i class="fa fa-refresh"></i>
              Convert </button>
          </mat-toolbar>
          <mat-card-content>
            <div class="table-responsive dataTable">
              <table id="dtHorizontalExample" class="mt-3 table table-bordered">
                <thead class="custfont">
                  <tr>
                    <th>
                      <div><input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="tableCheckAllBox"
                          (change)="checkUncheckAll()"></div>
                    </th>
                    <th class="table-head" *ngFor="let title of tableHeaderArray; index as i">
                      <div *ngIf="title!='Checkbox_Data'" (click)="sortData(i,title)">
                        <span>{{title.split("_").join(" ")}}</span>
                        <i *ngIf="i==columnindex && ascendingsort==true" class="fa fa-arrow-up"></i>
                        <i *ngIf="i==columnindex && ascendingsort==false" class="fa fa-arrow-down"></i>
                      </div>
                      <div *ngIf="title!='Checkbox_Data'">
                        <input type="text" class="form-cntrl table-input" [(ngModel)]="filterText[i]"
                          (input)="filterValue(filterText, i, title)" placeholder="Enter Keyword">
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="!noRecord">
                    <tr *ngFor="let item of asset">
                      <td>
                        <input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.Checkbox_Data">
                      </td>
                      <td *ngFor="let data of item | keyvalue: originalOrder">
                        <span *ngIf="data.key!='Checkbox_Data'">{{data.value}}</span>
                      </td>
                    </tr>
                  </ng-container>
                  <span *ngIf="noRecord">No Records to Display!</span>
                </tbody>
              </table>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>