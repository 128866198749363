<div class="row">
    <div class="col-md-8 content-area">
        <p>
            The Asset Management Database (AMD) is a multi-module application that supports the Capital Lifecycle
            Process (CLP). Visit the <span><U><a
                        href="https://hp.sharepoint.com/sites/ww/technical-learning-center/Pages/amd.aspx"
                        target="_blank">AMD Learning Center</a></U></span> to find out more.
        </p>

        <h6>AMD Modules:</h6>
        <ul>
            <li>
                <span class="font-weight-bold">CAPEX (Capital/Expense):</span> Used for long (LTP) and short (POR)
                capital planning. Used to manage a
                capital purchase LTP/POR requests, including funding assignment (WBS) and tracking of cash flow
                until the asset is fully setup and being depreciated.
            </li>
            <li>
                <span class="font-weight-bold">CAP (Capital Acquisition Process):</span>
                Used to ensure approved capital requests (PORs) that are also
                M&E go through a Capital Stakeholder review process for tool placement, infrastructure, and
                safety parameters. Outcome of the CAP is an action plan that guides the requester on actions
                required during equipment procurement and installation.
            </li>
            <li>
                <span class="font-weight-bold">Asset Management:</span>
                Used to manage the life of an asset, including tracking depreciation,
                requesting asset dispositions (move, transfer, retirement), and supports the annual asset
                verification.
            </li>
            <li>
                <span class="font-weight-bold">Lookup Tables:</span>
                Provided to support looking up key reference data (Cost Centers-Organizational
                Hierarchy, LabFab, Stakeholders) often used in drop downs throughout the AMD application.
            </li>
            <li>
                <span class="font-weight-bold">Reports:</span>
                Provides a variety of excel-based reports that provide specific insights. Within each
                module are data grids that provide views to all data within a given module.
            </li>
            <li>
                <span class="font-weight-bold">Help:</span>
                Provides useful information for users of the AMD application.
            </li>
        </ul>

        <h6 class="blue-text">Contacts:</h6>
        <ul>
            <li>
                <a href="mailto:tom.mayes2@hp.com" class="email-link">
                    <i class="fas fa-envelope"></i> Tom Mayes </a> [AMD Administrator: CAPEX, CAP, Asset Management]

            </li>
            <li>
                <a href="mailto:krista.lively@hp.com" class="email-link">
                    <i class="fas fa-envelope"></i> Krista Lively </a> [AMD Administrator: CAPEX, Asset Management, MTO
                Capital planner]

            </li>
            <li>
                <a href="mailto:gcole@hp.com" class="email-link">
                    <i class="fas fa-envelope"></i> Gary Cole </a> [AMD Administrator: CAPEX]

            </li>
            <li>
                <a href="mailto:greg.kniebuehler@hp.com" class="email-link">
                    <i class="fas fa-envelope"></i> Greg Kniebuehler </a> [AMD Administrator: CAPEX and Capital
                Reporting]

            </li>
        </ul>
    </div>

    <div class="col-md-4">
        <img src="{{imgPath + 'homePage.png'}}" class="homepage-image" alt="Homepage Image">
    </div>

</div>