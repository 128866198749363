<app-common-assessment-form hidden (updatedTableData) = "emitUpdatedTableData($event)"></app-common-assessment-form>

<form [formGroup]="detailsForm">
<!-- <mat-spinner *ngIf="loading"></mat-spinner> -->
<div class="row" style="margin-bottom:5%;">
    <div class="col-12">
        <div class="card-body white-text orange-gradient text-white text-center font-weight-bold">Tool Details</div>
        <mat-tab-group (selectedTabChange)="tabChange1($event)">
            <mat-tab label="Tool Information">
                <div *ngIf="this.tabSelected1 == 'Tool Information'">
                  <div class="row" style="margin-top: 2%;">
                    
                    <!-- Tool Name -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Tool Name
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Tool_Name" placeholder="">
                      </mat-form-field>
                    </div>
                    
                    <!-- Tool Description -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Tool Description
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Tool_Description" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- Tool Intent -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Tool Intent
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Tool_Intent">
                      </mat-form-field>
                    </div>
                    
                  </div>
              
                  <div class="row">
                    
                    <!-- Purpose -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Purpose
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Purpose">
                      </mat-form-field>
                    </div>
              
                    <!-- Tool History -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Tool History
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Tool_History">
                      </mat-form-field>
                    </div>
              
                    <!-- Tool Condition -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Tool Condition
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Tool_Condition">
                      </mat-form-field>
                    </div>
                    
                  </div>
              
                  <div class="row">
                    
                    <!-- Build Option -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Build Option
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Build_Option">
                      </mat-form-field>
                    </div>
                    
                  </div>
                </div>
              </mat-tab>
              
              <mat-tab label="Tool Location"> 
                <div *ngIf="this.tabSelected1 == 'Tool Location'">
                  <div class="row" style="margin-top: 2%;">
              
                    <!-- Site -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Site
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Initiate_Site">
                      </mat-form-field>
                    </div>
              
                    <!-- Building -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Building
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Initiate_Building">
                      </mat-form-field>
                    </div>
              
                    <!-- Lab Name -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Lab Name
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Initiate_LabFab">
                      </mat-form-field>
                    </div>
                    
                  </div>
                </div>
              </mat-tab>
              
              <mat-tab label="Proposed Supplier Information"> 
                <div *ngIf="this.tabSelected1 == 'Proposed Supplier Information'">
                  <div class="row" style="margin-top: 2%;">
                    
                    <!-- Supplier Name and Website -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Supplier Name and Website (list all suppliers)
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Tool_Name" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- List of Supplier Responsibilities -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>List of supplier responsibilities and expectations
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Tool_Description" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- List of Equipment to be Purchased -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>List all equipment to be purchased
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Tool_Intent">
                      </mat-form-field>
                    </div>
                    
                  </div>
              
                  <div class="row">
                    
                    <!-- Purpose -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Purpose
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Purpose">
                      </mat-form-field>
                    </div>
              
                  </div>
                </div>
              </mat-tab>
              
              <mat-tab label="Upload Document"> 
                <div *ngIf="this.tabSelected1 == 'Upload Document'">
                  <div class="row" style="margin-top: 2%;">
                    <div class="col-sm-8">
                      <mat-label class="col-md-12" style="padding-left:0px">
                        Upload Supporting files (i.e. RFQ, SOW, Specifications, etc)
                      </mat-label>
              
                      <!-- File Upload Component -->
                      <app-file-upload-amd 
                        uploadFilePath="cap" 
                        [uploadFileName]="sendFormVar['POR'] + '/'"
                        [uploadFileNameMust]="'any'"
                        [uploadFileRequired]="false"
                        (childFormReady)="addChildForm('capUpload', $event)"
                        [ParentBtnSubmitted]="false">
                      </app-file-upload-amd>
              
                      <!-- View Attached Files Section -->
                      <label style="padding-top: 15px;" *ngIf="this.viewAttachedFiles">View attached files</label>
                      <div class="col-sm-6 attachedFileOutline">
                        <ng-container>
                          <app-file-download-from-s4 
                            [dowloadItemFileList]="viewAttachedFiles"
                            [tableName]="'CAP_AllPOR_ITG'"
                            [Sortkey]="'CAP'"
                            [Asset_Number]="this.sendFormVar['POR']"
                            [ShowDeleteIcon]="true"
                            [DeletedFile_UpdateInDB_ColName]="'capUpload'">
                          </app-file-download-from-s4><br>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
              
              <mat-tab label="Action Plan" *ngIf="this.showActionPlanTab"> 
                <div *ngIf="this.tabSelected1 == 'Action Plan'">
                  <div class="row" style="margin-top: 2%;">
                    <div class="col-sm-8">
                      <table class="table table-striped table-responsive-md btn-table bs-select"
                        style="max-height: 60vh; overflow:auto; margin-left:1%;">
                        <thead class="custfont">
                          <tr class="appTableHeader">
                            <th class="table-head">
                              <div>
                                <span>Requester</span>&nbsp;
                              </div>
                            </th>
                            <th class="table-head">
                              <div>
                                <span>Stakeholder</span>&nbsp;
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <ng-container>
                              <td>
                                <div class="left-div">
                                  <ng-container>
                                    <a (click)="actionPlanClick('Requester ActionPlan')">
                                      <ng-container *ngIf="this.showRequester; else showEyeIcon">
                                        <i class="fa fa-edit"></i>
                                      </ng-container>
                                      <ng-template #showEyeIcon>
                                        <i class="fa fa-eye"></i>
                                      </ng-template>
                                      Action Plan
                                    </a>
                                  </ng-container>       
                                </div>
                              </td>
                              <td>
                                <div class="right-div">
                                  <ng-container>
                                    <a (click)="actionPlanClick('Stakeholder ActionPlan')">
                                      <ng-container *ngIf="this.editMode else showEyeIcon">
                                        <i class="fa fa-edit"></i>
                                      </ng-container>
                                      <ng-template #showEyeIcon>
                                        <i class="fa fa-eye"></i>
                                      </ng-template>
                                      Action Plan
                                    </a>
                                  </ng-container>
                                </div>
                              </td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </mat-tab>
        </mat-tab-group>
    </div>
</div>

<div class="row" style="margin-bottom:5%;">
    <div class="col-12">
        <div class="card-body white-text orange-gradient text-white text-center font-weight-bold">POR Details</div>
        <mat-tab-group (selectedTabChange)="tabChange2($event)">
            <mat-tab label="POR Information"> 
                <div *ngIf="this.tabSelected2 == 'POR Information'">
                  <div class="row" style="margin-top: 2%;">
                    
                    <!-- POR# -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>POR#
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="PORNumber" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- POR Status -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>POR Status
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="POR_Status" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- POR Project Manager -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>POR Project Manager
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Proj_Manager">
                      </mat-form-field>
                    </div>
                  </div>
              
                  <div class="row">
                    
                    <!-- Planner -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Planner</mat-label>
                        <input matInput type="text" formControlName="Capital_Planner">
                      </mat-form-field>
                    </div>
              
                    <!-- Description -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Description
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Description">
                      </mat-form-field>
                    </div>
              
                    <!-- POR Driver -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>POR Driver
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="POR_Driver">
                      </mat-form-field>
                    </div>
              
                    <!-- Requester -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Requester
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Requester_Email">
                      </mat-form-field>
                    </div>
              
                    <!-- Secondary Requester -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Secondary Requester
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Secondary_Requester">
                      </mat-form-field>
                    </div>
              
                  </div>
                </div>
              </mat-tab>
              
              <mat-tab label="Organizational Hierarchy"> 
                <div *ngIf="this.tabSelected2 == 'Organizational Hierarchy'">
                  <div class="row" style="margin-top: 2%;">
                    
                    <!-- POR Commit Manager -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>POR Commit Manager
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="POR_Commit_Manager" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- Commit Location -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Commit Location
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="Commit_Location" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- Cost Object -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Cost Object
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="cost_object">
                      </mat-form-field>
                    </div>
                  </div>
              
                  <div class="row">
                    
                    <!-- MRU -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>MRU
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="MRU">
                      </mat-form-field>
                    </div>
              
                    <!-- Level2 -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Level2
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="level2">
                      </mat-form-field>
                    </div>
              
                    <!-- Level3 -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Level3
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="level3">
                      </mat-form-field>
                    </div>
                  </div>
              
                  <div class="row">
                    
                    <!-- Business Unit -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Business Unit
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="business_unit">
                      </mat-form-field>
                    </div>
              
                    <!-- Organization -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Organization
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="organization">
                      </mat-form-field>
                    </div>
              
                    <!-- Section -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Section
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="section">
                      </mat-form-field>
                    </div>
                  </div>
              
                  <div class="row">
                    
                    <!-- Rollup -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Rollup
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="rollup">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-tab>
              
              <mat-tab label="Schedule Information"> 
                <div *ngIf="this.tabSelected2 == 'Schedule Information'">
                  <div class="row" style="margin-top: 2%;">
                    
                    <!-- Commit (QTR) -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Commit (QTR)
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="commit" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- RTE -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>RTE
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="RTE" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- RTO -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>RTO
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="RTO">
                      </mat-form-field>
                    </div>
                  </div>
              
                  <div class="row">
                    
                    <!-- Setup Due Date -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Setup Due Date
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="setupDueDate">
                      </mat-form-field>
                    </div>
              
                    <!-- Actual Commit -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Actual Commit
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="actualCommit">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-tab>
              
              <mat-tab label="Finance Information"> 
                <div *ngIf="this.tabSelected2 == 'Finance Information'">
                  <div class="row" style="margin-top: 2%;">
                    
                    <!-- Item Cost -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Item Cost
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="itemCost" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- Hookup Cost -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Hookup Cost
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="hookUpCost" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- NRE Cost -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>NRE Cost
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="NRE_cost">
                      </mat-form-field>
                    </div>
                  </div>
              
                  <div class="row">
                    
                    <!-- Total Cost -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>Total Cost
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="totalCost">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-tab>
              
              <mat-tab label="WBS# Information"> 
                <div *ngIf="this.tabSelected2 == 'WBS# Information'">
                  <div class="row" style="margin-top: 2%;">
                    
                    <!-- WBS# -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>WBS#
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="WBS" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- WBS Status -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>WBS Status
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="WBS_status" placeholder="">
                      </mat-form-field>
                    </div>
              
                    <!-- WBS Amount -->
                    <div class="col-4">
                      <mat-form-field class="w-100">
                        <mat-label>WBS Amount
                          <span style="color: #FF0000;" aria-required="true">*</span>
                        </mat-label>
                        <input matInput type="text" formControlName="WBS_amount">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-tab>
              
        </mat-tab-group>
    </div>
</div>
</form>

<!-- Focus Area table -->
<div class="row" style="margin-bottom:5%;">
    <div class="col-12">
        <div class="card-body white-text orange-gradient text-white text-center font-weight-bold">Stakeholder Review</div>
    </div>
 
    <table class="table  table-responsive-md btn-table bs-select"
        style="max-height: 60vh; overflow:auto; margin-left:1%; margin-top: 2%;">

        <thead class="custfont">
            <tr class="appTableHeader">

                <th class="table-head">
                    <div class="text-center">
                        <span>Focus Areas - Assessment</span>&nbsp;
                    </div>
                </th>
                <th class="table-head">
                    <div class="text-center">
                        <span>Status</span>&nbsp;
                    </div>
                </th>
                <th class="table-head">
                    <div>
                        <div class="ActionPlanColumn">Action Plan</div>
                        <span style="padding-right:45%">Requester</span>
                        <span>Stakeholder</span>
                    </div>
                </th>
            </tr>
        </thead>

        <!-- <div class="spinLoader" *ngIf="tableloading">
            <i class="fa fa-spinner fa-spin"></i>&nbsp;
            <span>Fetching Data...</span>
        </div> -->
        <tbody *ngIf="!tableloading">
            <tr *ngFor='let name of this.SHArray' >
         
                <ng-container *ngIf = "name['showFocusArea']">
                    <td>
                        <a class="row alignLink text-center" (click)="focusArea(name['key'])">{{name['key']}}</a>
                    </td>
                    <td>
                        <!-- Remove 606 (click)="statusClickReview(name['key'])" -->
                        <span class="row alignLink hover-icon text-center"
                         *ngIf="(name['value'].startsWith('Pending'))">{{name['value']}}</span>

                         <span class="row alignLink text-center"
                         *ngIf="(name['value'] == 'Waived')">Waive</span>

                        <span class="row alignLink text-center"
                            *ngIf="!(name['value'].startsWith('SH Review')) && !(name['value'].startsWith('Waive')) && !(name['value'].startsWith('Pending'))">
                            {{name['value']}}</span>
                
                        <a class="row alignLink text-center"
                            *ngIf="(name['value'].startsWith('SH Review') || name['value'] == 'Waive')"
                            (click)="statusClickReview(name, 'Review')">{{name['value']}} -></a>
                    </td>
                    <td>
                        <!-- Requester Action plan -->
                        <div class="left-div" >
                            <ng-container *ngIf="name['actionPlan'].startsWith('Action Plan')">
                                <a (click)="actionPlanClick('Requester ActionPlan')">
                                    <ng-container *ngIf="this.showRequester; else showEyeIcon">
                                      <i class="fa fa-edit"></i>
                                    </ng-container>
                                  
                                    <ng-template #showEyeIcon>
                                      <i class="fa fa-eye"></i>
                                    </ng-template>
                                  
                                    {{ name['actionPlan'] }}
                                </a>
                            </ng-container>       
                        </div>

                        <!-- Stakeholder Action Plan -->
                        <div class="right-div">
                            <!-- <span *ngIf="!name['showSHActionPlan']" (click)="statusClickReview(name)">{{name['actionPlan']}}
                            </span> -->
                            <!-- <a *ngIf="name['showSHActionPlan']" (click)="statusClickReview(name)">{{name['actionPlan']}}
                            </a> -->
                            <ng-container *ngIf="name['actionPlan'].startsWith('Action Plan')">
                            <a (click)="actionPlanClick('Stakeholder ActionPlan')">
                                <ng-container *ngIf="this.editMode; else showEyeIcon">
                                  <i class="fa fa-edit"></i>
                                </ng-container>
                              
                                <ng-template #showEyeIcon>
                                  <i class="fa fa-eye"></i>
                                </ng-template>
                              
                                {{ name['actionPlan'] }}
                            </a>
                            </ng-container>
                            <!-- <a (click)="statusClickReview(name)">
                                <i class="fa fa-eye"></i>{{name['actionPlan']}}
                            </a> -->
                        </div>

                    </td>
                    
                </ng-container>
            </tr>
        </tbody>
    </table>
    <div class="mb-5"></div>
</div>

<div class="text-center">
    <button mat-raised-button class="itg-gradient font-size" (click)="goBack()">Back
    </button>
</div>
