import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.css']
})
export class DialogBoxComponent implements OnInit {
  inputValue: any;
  onSubmit = new EventEmitter();
  constructor(
    private dialogRef: MatDialogRef<DialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ngOnInit(): void {

  }

  valuechange(event: any) {
    this.data['errorMessage'] = "";
    this.inputValue = event.target.value;
  }

  submit() {
    this.onSubmit.emit(this.inputValue)
  }

  submitResponse(event: string){
    this.dialogRef.close(event);
  }
}



