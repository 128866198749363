import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import html2canvas from 'html2canvas';
import { Location } from '@angular/common';

@Component({
  selector: 'app-print-verification',
  templateUrl: './print-verification.component.html',
  styleUrls: ['./print-verification.component.scss']
})
export class PrintVerificationComponent implements OnInit{

  saveAsset= []
  asset: any
  capturedImage:any; 
  i:any
  
  constructor(private common: CommonService, activatedroute: ActivatedRoute, private route: Router, private location: Location) { 
    this.common.bannerData.next({ title: 'AMD', subtitle: 'Print Verification' });
  }
    
  ngOnInit() {
    console.log("save")
    this.saveAsset = this.common.getAssetData()
  }  

  public downloadImage() {
    this.download(0, this.saveAsset);
  }
  
  back() {
    //this.route.navigate(['/comparisonreport']);
    this.location.back()
  }

  private download(index:any, array:any) {
    if (index >= array.length) {
      console.log("Done!")
    } else {
      let docElem:any = document.getElementById(array[index]['Asset_Number'].toString());
        html2canvas(docElem).then((canvas) => {
          let generatedImage = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
          let a = document.createElement('a');
          a.href = generatedImage;
          a.download = "AssetLabel_"+`${array[index]['Asset_Number']}.png`;
          a.click();
          this.download(index + 1, this.saveAsset)
        });
    }
  }
}

