 <!-- WBS Details accordion-->
 <form [formGroup]="wbsCashFlowForm">
    <div class="container-fluid pd-0">
        <div class="row">
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Estimated Total Cost</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="estimatedTotalCost">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Current WBS Amount</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="currentWbsAmount">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Total Spend From Reconcillation Report</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="totalReconcillation">
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Approved PO Commits</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="approvedPoCommits">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Total Amount of Invoiced POs</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="totalInvoicedPo">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Total Amount of JVs</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="totalAmountJvs">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Total Cash Flow</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="TotalCashFlow">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Estimated Total Spend</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="estimatedTotalSpend">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Estimated Remaining Spend</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="estimatedRemainingSpend">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">#Months Static</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="monthsStatic">
            </mat-form-field>
        </div>

        <div class="row border-container">
            <span class="text-over-border">NRE Costs</span>
        </div>

        <div class="row">
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Engr NRE Cost</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="engrNreCost">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Tech NRE Cost</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="techNreCost">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Total NRE Cost</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="totalNreCost">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Engr NRE Billed</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="engrNreBilled">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Tech NRE Billed</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="techNreBilled">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Total NRE Billed</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="totalNreBilled">
            </mat-form-field>
        </div>
        <div class="row">
            <div class="col-md-8"></div>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Remaining NRE</mat-label>
            <input matInput placeholder="" class="disabledColor" formControlName="remainingNre">
            </mat-form-field> 
        </div>

        <div class="row border-container">
            <span class="text-over-border">Hookup Costs</span>
        </div>

        <div class="row">
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Hookup-Capitalized Install Cost</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="hookupCapitalized">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Hookup-Billed</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="hookupBiiled">
            </mat-form-field>
            <mat-form-field class="col-md-4">
                <mat-label class="label-text">Remaining Hookup</mat-label>
                <input matInput placeholder="" class="disabledColor" formControlName="remainingHookup">
            </mat-form-field>
        </div>
    </div>
</form>


