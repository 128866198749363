import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CapexComponent } from './capex.component';
import { CapexDetailsComponent } from './capex-details/capex-details.component';
import { CapexSearchComponent } from './capex-search/capex-search.component';

const routes: Routes = [
  {
    path: '', component: CapexComponent,
    children: [
      { path: '', redirectTo: 'capexSearch', pathMatch: 'full' },
      { path: 'capexSearch', component: CapexSearchComponent },
      { path: 'capexDetails', component: CapexDetailsComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CapexRoutingModule { }
