<div class="full-loader" *ngIf="loader"><mat-spinner></mat-spinner></div>
<div class="" id="container">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="app-main mcw" id="body">
        <app-title></app-title>
        <div class="p-3">
            <router-outlet></router-outlet>
        </div>
        <app-footer id="footer" #footer></app-footer>
    </div>
</div>