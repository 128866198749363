<form [formGroup]="toolInfoForm">
    <mat-card>
      <div class="container-fluid">
        <div class="row" *ngIf="this.tabSelected == 'Tool Information'">
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Tool Name<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <input matInput type="text" formControlName="Tool_Name" placeholder="" readonly>
              <mat-error>Tool Name is required</mat-error>
            </mat-form-field>
          </div>
  
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Tool Description<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <input matInput type="text" formControlName="Tool_Description" placeholder="" readonly>
              <mat-error>Tool Description is required</mat-error>
            </mat-form-field>
          </div>
  
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Tool Intent<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <input matInput type="text" formControlName="Tool_Intent" placeholder="" readonly>
              <mat-error>Tool Intent is required</mat-error>
            </mat-form-field>
          </div>
        </div>
  
        <div class="row">
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Purpose<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <mat-select formControlName="Purpose">
                <mat-option value="" disabled selected>--Select--</mat-option>
                <mat-option value="ABC">ABC</mat-option>
              </mat-select>
              <mat-error>Purpose is required</mat-error>
            </mat-form-field>
          </div>
  
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Tool History<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <mat-select formControlName="Tool_History">
                <mat-option value="" disabled selected>--Select--</mat-option>
                <mat-option value="toolHistory">Tool History</mat-option>
              </mat-select>
              <mat-error>Tool History is required</mat-error>
            </mat-form-field>
          </div>
  
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Tool Condition<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <mat-select formControlName="Tool_Condition">
                <mat-option value="" disabled selected>--Select--</mat-option>
                <mat-option value="Tool_Condition">Tool Condition</mat-option>
              </mat-select>
              <mat-error>Tool Condition is required</mat-error>
            </mat-form-field>
          </div>
        </div>
  
        <div class="row">
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Build Option<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <mat-select formControlName="Build_Option">
                <mat-option value="" disabled selected>--Select--</mat-option>
                <mat-option value="Build Option">Build Option</mat-option>
              </mat-select>
              <mat-error>Build Option is required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
  
      <!-- Tool Location Tab -->
      <div class="container-fluid" *ngIf="this.tabSelected == 'Tool Location'">
        <div class="row">
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Site<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <mat-select formControlName="Initiate_Site">
                <mat-option value="" disabled selected>--Select--</mat-option>
                <mat-option value="ABC">ABC</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Building<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <mat-select formControlName="Initiate_Building">
                <mat-option value="" disabled selected>--Select--</mat-option>
                <mat-option value="Building">Building</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
  
      <!-- Proposed Supplier Information Tab -->
      <div class="container-fluid" *ngIf="this.tabSelected == 'Proposed Supplier Information'">
        <div class="row" style="margin-top: 2%;">
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Supplier Name and Website<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <input matInput type="text" formControlName="Supplier_Name" placeholder="" readonly>
            </mat-form-field>
          </div>
  
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>List of Supplier Responsibilities<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <input matInput type="text" formControlName="Supplier_Responsibilities" placeholder="" readonly>
            </mat-form-field>
          </div>
  
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>List of Equipment to be Purchased<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <input matInput type="text" formControlName="Equipment_List" placeholder="" readonly>
            </mat-form-field>
          </div>
        </div>
  
        <div class="row">
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Purpose<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
              <mat-select formControlName="Purpose">
                <mat-option value="" disabled selected>--Select--</mat-option>
                <mat-option value="ABC">ABC</mat-option>
              </mat-select>
              <mat-error>Purpose is required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card>
  </form>

  <div *ngIf="tabChange">
    <!-- POR Information Tab -->
    <div *ngIf="tabSelected1 === 'POR Information'">
      <div class="row mt-2">
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>POR# <span class="required">*</span></mat-label>
            <input matInput formControlName="POR" placeholder="">
          </mat-form-field>
        </div>
  
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>POR Status <span class="required">*</span></mat-label>
            <input matInput formControlName="POR_Status" placeholder="">
          </mat-form-field>
        </div>
  
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>POR Project Manager <span class="required">*</span></mat-label>
            <input matInput formControlName="Proj_Manager">
          </mat-form-field>
        </div>
      </div>
  
      <div class="row">
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>Capital Planner <span class="required">*</span></mat-label>
            <input matInput formControlName="Capital_Planner">
          </mat-form-field>
        </div>
  
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>Description <span class="required">*</span></mat-label>
            <input matInput formControlName="Description">
          </mat-form-field>
        </div>
  
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>POR Driver <span class="required">*</span></mat-label>
            <input matInput formControlName="POR_Driver">
          </mat-form-field>
        </div>
      </div>
    </div>
  
    <!-- Organizational Hierarchy Tab -->
    <div *ngIf="tabSelected1 === 'Organizational Hierarchy'">
      <div class="row mt-2">
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>POR Commit Manager <span class="required">*</span></mat-label>
            <input matInput formControlName="POR_Commit_Manager" placeholder="">
          </mat-form-field>
        </div>
  
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>Commit Location <span class="required">*</span></mat-label>
            <input matInput formControlName="Commit_Location" placeholder="">
          </mat-form-field>
        </div>
  
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>Cost Object <span class="required">*</span></mat-label>
            <input matInput formControlName="cost_object">
          </mat-form-field>
        </div>
      </div>
  
      <div class="row">
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>MRU <span class="required">*</span></mat-label>
            <input matInput formControlName="MRU">
          </mat-form-field>
        </div>
  
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>Level 2 <span class="required">*</span></mat-label>
            <input matInput formControlName="level2">
          </mat-form-field>
        </div>
  
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>Level 3 <span class="required">*</span></mat-label>
            <input matInput formControlName="level3">
          </mat-form-field>
        </div>
      </div>
  
      <div class="row">
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>Business Unit <span class="required">*</span></mat-label>
            <input matInput formControlName="business_unit">
          </mat-form-field>
        </div>
  
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>Organization <span class="required">*</span></mat-label>
            <input matInput formControlName="organization">
          </mat-form-field>
        </div>
  
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>Section <span class="required">*</span></mat-label>
            <input matInput formControlName="section">
          </mat-form-field>
        </div>
      </div>
  
      <div class="row">
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>Rollup <span class="required">*</span></mat-label>
            <input matInput formControlName="rollup">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  

  
  