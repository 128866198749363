import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import * as Excel from 'exceljs';
import * as fs from 'file-saver';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';

@Component({
  selector: 'app-export-bulk-data',
  templateUrl: './export-bulk-data.component.html',
  styleUrls: ['./export-bulk-data.component.css']
})
export class ExportBulkDataComponent implements OnInit {

  @ViewChild('Import_Bulk_Data') Import_Bulk_Data!: ElementRef;

  //File formate and downloading filname must be from parent component
  @Input() exportFileName: string = '';

  // API path for calling 
  @Input() apiPath: string = '';

  //Button Label Name 
  @Input() Button_Name: string = '';

  //Worksheet Name of excell file in first tab
  @Input() Sheet_Name: string = '';

  //worksheet column headers name
  @Input() Sheet_Coloumns_Headers:string [] = [];

  //Worksheet row data
  @Input() Sheet_rows_data: [] = [];

  //The row line where the headers are taken as keys in object
  @Input() Sheet_lineStart_Row: string = '';

  //to add the css to button from parent
  @Input() cssClass: string = '';

  // passing Sucess / Failure value to parent component
  @Output() childMessage: EventEmitter<boolean> = new EventEmitter();

  @Input() disabled: boolean | undefined;

  chunkSaved: boolean = false;
  chunkCount = 0;
  chunksCompleted = 0;
  locloading = true;
  progress: any;
  loading = false;
  allRecords: any = [];
  constructor(private commonService: CommonService, private dialog: MatDialog) { }

  ngOnInit() {
    if (sessionStorage.getItem('flag') == 'true') {
      this.locloading = false
    }
    sessionStorage.removeItem('flag')
  }


  exportBulkData() {
    this.loading = true
    console.log("Header", this.Sheet_Coloumns_Headers)
    console.log("Rows", this.Sheet_rows_data)

    // Existing data array from parent components
    if (this.Sheet_rows_data.length) {
      this.TableData(0, 100);
    }

    // to get the data from API calls
    if (this.apiPath != ' ') {
      this.callAPI(0, 1000);
    }
  }

  TableData(start: any, end: any) {
    console.log(start)
    console.log(end)
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      //exportBulkData
      this.chunkSaved = false;
      if (this.Sheet_rows_data?.length) {
        this.chunkCount = 0;
        this.chunksCompleted = 0
        this.progress = 100;
        this.generateReport(this.Sheet_rows_data);
      }
      else {
        this.loading = false;
        this.openDilaogeMessage('Failed to download file', '')
      }
    }
  }

  callAPI(start: any, end: any) {
    console.log(start)
    console.log(end)
    let tempData: any = [];
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      //exportBulkData
      this.commonService.getAPI(this.apiPath + 'start=' + start + '&end=' + end).subscribe((data: any) => {
        tempData = data['body'];
        console.log(data['statusCode']);
        // console.log(tempData.length)
        this.chunkSaved = false;
        if (data['statusCode'] == 200) {
          if (tempData.length != 0) {
            this.allRecords = this.allRecords.concat(data['body']);
            this.callAPI(end, end + 1000); // change to 1000
            this.chunkCount = data['assetCount'];
            this.chunksCompleted = this.chunksCompleted + data['assetImported'];
            console.log(this.chunkCount);
            console.log(this.chunksCompleted);
            this.progress = Math.round((100 * this.chunksCompleted) / this.chunkCount);
          }
          else {
            this.generateReport(this.allRecords);
            this.chunkCount = 0;
            this.chunksCompleted = 0;
            this.progress = 0;
            this.loading = false;
            this.allRecords = [];
          }
        }
        else {
          this.loading = false;
          this.openDilaogeMessage('Failed to download file', '');
          this.childMessage.emit(true);
        }
      });
    }
  }

  // generates the excel file 
  generateReport(data: any) {
    console.log(this.allRecords);
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet(this.Sheet_Name);
    let DateObj = new Date();
    let date = ('0' + (DateObj.getUTCMonth() + 1)).slice(-2) + ('0' + DateObj.getUTCDate()).slice(-2) + DateObj.getUTCFullYear();
    let docname = this.exportFileName + "_" + date;
    let titleValues1 = [""];
    worksheet.addRow(titleValues1)
    let headerRow = worksheet.addRow(this.Sheet_Coloumns_Headers);
    let data1 = [];
    for (let d1 of data) {
      data1.push(d1);
    }

    for (let x1 of data1) {
      let x2 = this.Sheet_Coloumns_Headers;
      let temp = []
      for (let y of x2) {
        if (x1[y] == 'null' || x1[y] == 'NULL' || x1[y] == 'Null') {
          x1[y] = ''
        }
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }

    worksheet.getRow(2).height = 40;
    worksheet.getRow(2).font = {
      name: 'HP Simplified',
      size: 9,
      bold: true,
      color: { argb: 'ffffff' }
    };
    worksheet.getRow(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0096d6' }
    };

    workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
      let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, docname + '.xlsx');
    });

    this.chunkCount = 0;
    this.chunksCompleted = 0;
    this.progress = 0;
    this.loading = false;
    this.childMessage.emit(true);
  }

  openDilaogeMessage(message: string, assetID: string) {

    const dialogConfig = new MatDialogConfig();
    if (window.innerWidth > 1440) {
      dialogConfig.maxWidth = '20vw';
      dialogConfig.width = '25%';
      dialogConfig.maxHeight = '20vw';
      dialogConfig.height = '25%';
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: message,
      tabletemplate: false,
      title: "Export"
    }
    this.dialog.open(DialogBoxComponent, dialogConfig);
  }
}