import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-organization',
  templateUrl: './edit-organization.component.html',
  styleUrls: ['./edit-organization.component.scss']
})
export class EditOrganizationComponent implements OnInit {
  formData: FormGroup;
  isProDriverForm: boolean = false; 
  editFlag: boolean = false;

  constructor(
    private common: CommonService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditOrganizationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isProDriverForm = data?.isProDriver || false;

    if (!this.isProDriverForm) {
      this.editFlag = !!data?.element;
    // Initialize form with default values or existing data
    this.formData = this.fb.group({
      'Cost Center Code': [{ value: data?.element ? data?.element['Cost Center Code'] : '', disabled: this.editFlag }],
      'Cost Center Owner': [data?.element ? data?.element['Cost Center Owner'] : ''],
      'Cost Center Description': [data?.element ? data?.element['Cost Center Description'] : ''],
      'Company Code': [data?.element ? data?.element['Company Code'] : ''],
      'Cost Center Name': [data?.element ? data?.element['Cost Center Name'] : ''],
      'Region': [data?.element ? data?.element['Region'] : ''],
      'MRU Code': [data?.element ? data?.element['MRU Code'] : ''],
      'Sub MRU': [data?.element ? data?.element['Sub_MRU'] : ''],
      'Level 2': [data?.element ? data?.element['Level2'] : ''],
      'Level 3': [data?.element ? data?.element['Level3'] : ''],
      'Level 4': [data?.element ? data?.element['Level4'] : ''],
      'Section': [data?.element ? data?.element['Section'] : ''],
      'ExpType': [data?.element ? data?.element['ExpType'] : ''],
      'Commit': [data?.element ? data?.element['Commit'] : ''],
      'Inactive': [data?.element ? data?.element['Inactive'] : ''],
      'Depreciation': [data?.element ? data?.element['Depreciation'] : ''],
      'editFlag' : this.editFlag,
      'indexValue' : [data?.element ? data?.element['i']: ''],
    });
    }

    else{
      this.formData = this.fb.group({
        'bUnit': '',
        'ENRELoc': '',
        'rollUp': '',
        'inactive': '',
        'level': '',
        'techNreLoc': '',
        'category': '',
        'programDriver':'',
      });
    

    }
  }

  ngOnInit(): void {}

  onSave() {
    this.common.postAPI('amd-update-cost-center', this.formData.getRawValue()).subscribe({
      next: (
        (res) => {
          console.log(res);
          this.dialogRef.close(true); 
          
        }),
      error: (
        error => {
          if ('message' in error.error) {
            this.toastr.error(error.error['message']);
          }
          else {
            this.toastr.error('Internal Server Error, please logout and try again. If issue persists, contact Admin')
          }
          console.log(error);
        })
        
    })
  }

  onPDSave(){
    console.log(this.formData.value);
    this.common.postCapexAPI('program-drivers', this.formData.value).subscribe({
      next: (
        (res) => {
          console.log(res);
          this.dialogRef.close(true); 
          
        }),
      error: (
        error => {
          if ('message' in error.error) {
            this.toastr.error(error.error['message']);
          }
          else {
            this.toastr.error('Internal Server Error, please logout and try again. If issue persists, contact Admin')
          }
          console.log(error);
        })
        
    })
  }

  onCancel() {
    this.dialogRef.close();
  }
}
