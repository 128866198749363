<!-- <a (click)="exportAllRetiredReclassified()">
    <i class="fa fa-file-text"></i>&nbsp;&nbsp;
    All Retired and Reclassified Assets
    <ng-container *ngIf="exportRetiredAssets">
      <i class="fa fa-spinner fa-spin"></i>&nbsp;
    </ng-container>
  </a> -->


    <div style="text-align: center; padding: 20px;">
      <p style="font-size: 18px; color: #333; margin-bottom: 10px;">
        Click 
        <a (click)="exportAllRetiredReclassified()" 
           class="download-link">
          <i>here</i>
        </a> 
        to download &nbsp;<b><i class="fa fa-file-text" style="color: #555;"></i>&nbsp; All Retired and Reclassified Assets</b>
      </p>
    
      <ng-container *ngIf="exportRetiredAssets">
        <div style="display: flex; justify-content: center; align-items: center; margin-top: 10px;">
          <i class="fa fa-spinner fa-spin" style="color: #d66000; font-size: 24px;"></i>
          <span style="font-size: 16px; color: #d66000; margin-left: 10px;">Downloading...</span>
        </div>
      </ng-container>
    </div>
    