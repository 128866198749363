import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
import { CommonAssessmentFormComponent } from '../common-assessment-form/common-assessment-form.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-assessment-form-functionality',
  templateUrl: './assessment-form-functionality.html',
  styleUrls: ['./assessment-form-functionality.scss']
})
export class AssessmentFormComponent implements OnInit {
  // @Input() questions: any[];
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  questionArray2!: string[];
  SHReviewName!: string;
  actionItemArray!: string[];
  toggleRes!: string;
  SHReviewStatus!: FormGroup;
  formResponse: any;
  sendFormVar : any;
  loading!: boolean;
  uniqueId: string | undefined;
  userResponse! : any;
  // answerArray: any;
  tableData: any;
  QAArray: any;
  fieldDisable: boolean | undefined;
  showBtn: boolean = true;
  FAOriginalName: any;
  helpText: string | undefined;
  LabfabTeams: any;
  SHNameData: any;
  commonInfo :any;
  emailDataDetails={};
  popUpTxt: string | undefined;
  disableResetBtn: boolean = true;
  abbreviatedSHName: any;
  DBResponseData: any;
  DBQuestionData: any;
  DBResponseDataLength: any;
  showStatusWaive: boolean | undefined;
  submitLoading = false
  loggedInUser: string | undefined;
  userRole: string | undefined;
  todayDate: Date = new Date();
  disableCancelBtn: boolean = true;
  assessmentPopupClick: any;
  
  constructor(private common: CommonService, private fb: FormBuilder, private router: Router,
    private activatedRoute: ActivatedRoute, private dialog: MatDialog,private toastr: ToastrService,
    public dialogRef: MatDialogRef<AssessmentFormComponent>,
    @Inject(MAT_DIALOG_DATA) public popupdata: any) {}

  ngOnInit(): void {
    this.userResponse = []
    console.log('Printtt data matdialogref', this.popupdata)
    this.assessmentPopupClick = this.popupdata == "openpopup" ? true : false
    this.FAOriginalName = this.activatedRoute.snapshot.queryParams['FA'];
    this.loggedInUser = atob(this.common.currentUser);
    this.userRole = this.common.getUserRole();

    this.SHReviewStatus = this.fb.group({
      focusAreaName: '',
      focusAreaStatus: ''
    })

    let fetchFAfromUrl = this.activatedRoute.snapshot.queryParams['FA'];

    this.abbreviatedSHName = [
      {key: 'Asset Administration', value: 'AA'},
      {key: 'Automated Equipment Control', value: 'AEC'},
      {key: 'Cybersecurity', value: 'Cybersecurity'},
      {key: 'EHS Equipment Design', value: 'EHS'},
      {key: 'Electrical', value: 'Elec'},
      {key: 'Ergonomics', value: 'Not Erg'},
      {key: 'FAB Environmental', value: 'FAB'},
      {key: 'Information Technology(IT)', value: 'IT'},
      {key: 'Mechanical and HVAC', value: 'MECH'},
      {key: 'Micro-contamination and ESD', value: 'ESD'},
      {key: 'Operations', value: 'OS'},
      {key: 'Process Systems', value: 'Process Systems'},
      {key: 'SCADA Instramental Control', value: 'SCADA'},
      {key: 'Site EHS', value: 'SEHS'},
      {key: 'Space Layout (Non-office)', value: 'SL'}
    ]

      this.abbreviatedSHName.filter((ele: { [x: string]: any; }) =>{
        if(ele['key'] == fetchFAfromUrl){
          fetchFAfromUrl = ele['value']
        }
      });
  }

  emitUpdatedTableData(updatedTableData:any){
    this.userResponse = updatedTableData['userResponse']
    this.commonInfo = updatedTableData['commonInfo']
    this.disableResetBtn = this.userResponse.length > 0 ? false : true
    console.log("Print table Data updated", updatedTableData);
    this.sendFormVar = {
      labName: this.commonInfo['LabFab_Install_Location'],
      ToolName: this.commonInfo['ToolName'],
      focusArea: this.FAOriginalName,
      POR: this.activatedRoute.snapshot.queryParams['POR'],
      date: this.todayDate.toISOString().split('T')[0]
    }
    if (!this.tableData) {
      this.common.loader.next(true);
      this.common.postCapAPI('itg/cap-assessment-review-itg',this.sendFormVar).subscribe({
        next: (response: { body: any; }) => {
          this.common.loader.next(false);
          console.log('assessment API response', response)
          this.tableData = response.body
          //this.DBResponseDataLength = Object.keys(this.tableData['Answers']);
          this.DBResponseData = this.tableData['Answers'];
          this.DBQuestionData = this.tableData['Questions'];
          if (this.tableData) {
            // response_status=1 is for Submit
            if (this.DBResponseData && (this.tableData['Answers']['Response_Status'] == 1 || this.tableData['Answers']['Response_Status'] == 4)) {
                this.QAArray = this.tableData['Answers']['UserResponse_Array'];
        
                this.QAArray.forEach((ele: { isDisable?: boolean; internalQuestions?: any[] }) => {
                    ele.isDisable = true; // Set isDisable to true
                    if (ele.internalQuestions) {
                        this.disableInternalQuestions(ele.internalQuestions);
                    }
                });
              this.fieldDisable = false;
              this.showBtn = false;
              // this.QAArray = this.tableData['Question_Array']['questions'];
            }
            //response_status= 2 is for Save
            else if (this.DBResponseData && this.tableData['Answers']['Response_Status'] == 2) {
              let answerData = this.tableData['Answers']['UserResponse_Array'];
              let questionData = this.tableData['Questions']['Question_Array']['questions'];
              let unsavedQue = []
              for (let i = 0; i < answerData.length; i++) {
                for (let j = 0; j < questionData.length; j++) {
                  if (questionData[j]['questionId'] == answerData[i]['questionId']) {
                    answerData[i]['question'] = questionData[j]['question'];
                    questionData.splice(j, 1, answerData[i]);
                    console.log('Print', questionData);
                    this.QAArray = questionData
                    break;
                  }
                }
              }
              this.fieldDisable = true;
            }
            //only Questions to be visible on new request
            else {
              this.QAArray = this.tableData['Questions']['Question_Array']['questions'];
              this.fieldDisable = true
              // if(this.loggedInUser != this.commonInfo['RequesterName'] && (this.loggedInUser != this.commonInfo['SecondaryReq'])){
              //   this.QAArray.forEach(ele => ele['isDisable'] = true)
              //   this.showBtn = false;
              // }
            }
          }
          if (this.userRole != 'Admin' && this.loggedInUser != this.commonInfo['RequesterName'] && (this.loggedInUser != this.commonInfo['SecondaryReq']) && (this.userRole)) {
            this.QAArray.forEach((ele: { [x: string]: boolean; }) => ele['isDisable'] = true)
            this.showBtn = false;
          }

          if (this.tableData['Answers']['UserResponse_Array'] != undefined) {
            this.disableCancelBtn = (this.tableData['Answers']['UserResponse_Array'].length > 0) ? false : true;
          }
        },
        error: (error: any) => {
          console.error("Error occurred:", error);
          alert("Please connect with Asset Admin");
        }
      });
    }
  }
  
  toggleChanges($event: MatSlideToggleChange, name: any) {
    let arrName = name;
    arrName['answer'] = $event.checked
    arrName['showSubQue'] = true
    this.userResponse.push(arrName);
    this.userResponse = [...new Set(this.userResponse)]
  }


  textBoxVal($event: { target: { value: any; }; }, name: any) {
    let arrName = name;
    arrName['answer'] = $event.target.value;
    arrName['showSubQue'] = true
    this.userResponse.push(arrName);
    this.userResponse = [...new Set(this.userResponse)]
  }


submitAssessment(name: any) {
  if (this.userResponse != undefined) {
    this.userResponse = this.userResponse?.length == 0 ? this.tableData['Answers']['UserResponse_Array'] : this.userResponse;

    if (this.userResponse?.length > 0) {
      let assessmentQuestion = this.tableData['Questions']['Question_Array']['questions'];
      let uniquePOR = new Set<string>();

      assessmentQuestion.filter((item: { type: string; questionId: string; }) => {
        if (item.type == 'statement' && !uniquePOR.has(item.questionId)) {
          uniquePOR.add(item.questionId)
          this.userResponse.push(item);
        }
      })

      this.userResponse.sort((a: { questionId: { match: (arg0: RegExp) => string[]; }; }, b: { questionId: { match: (arg0: RegExp) => string[]; }; }) => {
        const numA = parseInt(a.questionId.match(/\d+/)[0])
        const numB = parseInt(b.questionId.match(/\d+/)[0])
        return numA - numB;
      });

      // this.userResponse = assessmentQuestion
    }
  }

 let formValidation = this.formCheckValidation()
    console.log("formValidation",formValidation);
    if(formValidation){
      return
    }
    console.log('dddd', this.userResponse)
    this.SHReviewStatus.value['focusAreaName'] = this.FAOriginalName;
    this.SHReviewStatus.value['focusAreaStatus'] = 'Available';
    let queArray = this.DBQuestionData['Question_Array']['questions'];
    let ansArray;

    if (this.userResponse?.length == 0 || this.userResponse == undefined) {
      this.openErrorDilaogeMessage('Fill any of the fields to Submit Data', '');
    }
    else if (this.userResponse?.length > 0 && this.tableData?.Answers?.UserResponse_Array) {
      this.userResponse = [...new Set((this.tableData.Answers.UserResponse_Array || []).concat(this.userResponse))] as [];
      ansArray = this.userResponse;
  }
  
    else if (this.userResponse.length > 0){
      ansArray = this.userResponse
    }
    else{
       ansArray = this.tableData?.Answers?.UserResponse_Array
    }
     
    if(!formValidation){
      // Set 'isSubmit = 4' for Marking FA status as Waived
      this.userResponse.some((ele: { type: string; answer: string; required: boolean; })=>{
        if(ele.type == "radiogroup" && ele.answer == "No" && ele.required == true){
          this.sendFormVar['isSubmit'] = 4;
          this.showStatusWaive = true
        }
        // else if(ele.required == true && (ele.answer == "" || ele.answer == undefined)){
        //   this.openErrorDilaogeMessage('Fill all the mandatory fields before Submit', '');
        //   return true;
        // }
        else {
          if (ele.required != false && ele.type != "statement") {
            this.sendFormVar['isSubmit'] = 1;
            this.showStatusWaive = false
            return true;
          }
        }
        return false;
      })

      this.sendFormVar['userResponseData'] = this.userResponse     
      this.sendFormVar['userName'] = atob(this.common.currentUser);
      this.sendFormVar['date'] = this.todayDate.toISOString().split('T')[0]
      this.common.loader.next(true);
      this.common.postCapAPI('itg/cap-assessment-review-itg',this.sendFormVar).subscribe({
        next: (data: { statusCode: number; errorType: any; }) => {
          console.log('assessment API response', data)
          this.formResponse = data

          if (data.statusCode == 200) {
            this.showBtn = false;
            this.common.focusAreaStatusFunction(this.SHReviewStatus.value);
            if (this.showStatusWaive) {
              //this.sendEmail('Waive')
            }
            else {
              //Uncomment
              this.sendEmail('ReviewEmail')
            }
            this.openSuccessDilaogeMessage('Data has been Submitted for SH Review', 'Submit');
            this.common.loader.next(false);
          } else if (data.errorType) {
            this.common.loader.next(false);
            this.openErrorDilaogeMessage('Something went wrong, try after sometime', '');
          }
        },
        error: (error: any) => {
          console.error("Error occurred:", error);
          alert("Please connect with Asset Admin");
        }
      });
    }
  }

 //form validation for mandotry fields
 formCheckValidation() {
    let formcheckedVal = false;
    console.log("child method formcheck called", this.userResponse);
    console.log("this.questions", this.tableData);
    let ArrayQuest = this.tableData?.Questions?.Question_Array?.questions;

    // Loop over the array of questions
    ArrayQuest.forEach((field: { required: any; answer: string | undefined; type: string; internalQuestions: any[]; choices: any[]; }) => {
        // Check for required fields that have no value
        if (field.required) {
            if ((!field.answer && field.answer === '' || field.answer === undefined) && field.type !== 'checkbox') {
                formcheckedVal = true;
                this.toastr.warning('Please fill all the mandatory fields before Submit', 'warning')
                //this.openErrorDilaogeMessage('Please fill all the mandatory fields before Submit', '');
            } else if (field?.answer !== 'No' && field.type !== 'checkbox') {
                field?.internalQuestions?.forEach((innerField: { answer: string | any[] | undefined; required: any; conditionalQues: any; conditionalShow: any; type: string; }) => {
                    if ((innerField?.answer === undefined || !innerField?.answer || innerField?.answer === '') && innerField.required && !innerField.conditionalQues) {
                        formcheckedVal = true;
                        this.toastr.warning('Please fill all the mandatory sub fields before Submit', 'Warning')
                        //this.openErrorDilaogeMessage('Please fill all the mandatory sub fields before Submit', '');
                    }

                    if ((innerField?.answer === undefined || !innerField?.answer || innerField?.answer === '') && innerField.required && innerField.conditionalQues && innerField.conditionalShow === field.answer) {
                        formcheckedVal = true;
                        this.toastr.warning('Please fill all the mandatory sub fields before Submit', 'Warning')
                       // this.openErrorDilaogeMessage('Please fill all the mandatory sub fields before Submit', '');
                    }

                    // Multi-text fields validation
                    if (innerField.type === 'multiText' && innerField.required && innerField.answer) {
                        if (Array.isArray(innerField.answer)) {
                            if (innerField.answer.length > 0 && innerField.answer.every((item: any) => !item)) {
                                formcheckedVal = true;
                                this.toastr.warning('Please fill all the mandatory sub fields before Submit', 'Warning')
                                //this.openErrorDilaogeMessage('Please fill all the mandatory sub fields before Submit', '');
                            }
                        } else if (typeof innerField.answer === 'string' && innerField.answer.length === 0) {
                            formcheckedVal = true;
                            this.toastr.warning('Please fill all the mandatory sub fields before Submit', 'Warning')
                           // this.openErrorDilaogeMessage('Please fill all the mandatory sub fields before Submit', '');
                        }
                    }
                });
            } else if (Array.isArray(field.choices) && field.type === 'checkbox') {
                let isNamePresent = false;
                if ((!field.answer || field?.answer?.length == 0)) {
                    formcheckedVal = true;
                    this.toastr.warning('Select the mandatory checkbox fields before Submit', 'Warning')
                  //  this.openErrorDilaogeMessage('Select the mandatory checkbox fields before Submit', '');
                }

                if (field?.internalQuestions?.length > 0) {
                    const targetInternalQue = field?.internalQuestions.map((ele: { conditionalShow: any; }) => ele?.conditionalShow);
                    for (const name of targetInternalQue) {
                        isNamePresent = field?.choices.some((item: { label: any; checked: any; }) => item?.label === name && item?.checked);
                    }
                    if (isNamePresent) {
                        field?.internalQuestions?.forEach((innerField: { answer: string | undefined; required: any; }) => {
                            if ((innerField?.answer === undefined || !innerField?.answer || innerField?.answer === '') && innerField?.required) {
                                formcheckedVal = true;
                                this.toastr.warning('Please fill all the mandatory sub fields before Submit', 'Warning')
                               // this.openErrorDilaogeMessage('Please fill all the mandatory sub fields before Submit', '');
                            }
                        });
                    }
                }
            }
        }
    });

    // Return the final validation status
    return formcheckedVal;
}


  back() {
    this.router.navigate(['cap/focusArea'], {queryParams : {POR: this.sendFormVar['POR']}})
  }
  
  isAnsElement(data: object): boolean {
     if (data) {
      return Object.keys(data).length != 0
    }
    else {
      return false
    }
  }
  
  save(focusAreaName: any){
    console.log('dddd', this.userResponse)
    this.SHReviewStatus.value['focusAreaName'] = focusAreaName;
    this.SHReviewStatus.value['focusAreaStatus'] = 'Available';   
    this.sendFormVar['isSubmit'] = 2;
    this.sendFormVar['userName'] = atob(this.common.currentUser);
    //this.tableData['UserResponse_Array'].length != 0
    if(this.isAnsElement(this.tableData['Answers'])){
      this.userResponse = [... new Set(this.tableData['Answers']['UserResponse_Array'].concat(this.userResponse))]
      // this.userResponse.slice(0).forEach(element => {
      //   if(element['answer'] == false || element['answer'] == ""){
      //     this.userResponse.splice(this.userResponse.indexOf(element), 1);
      //   }
      // })
    }

    // make it && later once functionality implemented
    if ((this.userResponse?.length == 0 || this.userResponse == undefined)) {
      this.openErrorDilaogeMessage('Fill any of the fields to Save Data', '');
    }
    else {
      this.common.loader.next(true);
      this.sendFormVar['userResponseData'] = this.userResponse;
      this.common.postCapAPI('itg/cap-assessment-review-itg',this.sendFormVar).subscribe({
        next: (data: { statusCode: number; }) => {
          console.log('assessment API response', data)
          this.formResponse = data
          this.common.loader.next(false);
          if (data.statusCode == 200) {
            this.common.focusAreaStatusFunction(this.SHReviewStatus.value);
            this.openSuccessDilaogeMessage('Data has been Saved to Draft', 'Save');
          }
        },
        error: (error: any) => {
          console.error("Error occurred:", error);
          alert("Please connect with Asset Admin");
        }
      });
    }
  }
  
  cancel(){
    console.log('dddd', this.userResponse)
    // this.SHReviewStatus.value['focusAreaName'] = name;
    // this.SHReviewStatus.value['focusAreaStatus'] = 'Available';
    // this.sendFormVar['labName'] = 'FAB PHOTO';
    // this.sendFormVar['focusArea'] = 'AA';
   
    this.sendFormVar['userResponseData'] = "";
    this.sendFormVar['isSubmit'] = 3;
    this.sendFormVar['userName'] = atob(this.common.currentUser);
    this.common.loader.next(true);
    this.common.postCapAPI('itg/cap-assessment-review-itg',this.sendFormVar).subscribe({
      next: (data: { statusCode: number; errorType: any; }) => {
        this.common.loader.next(false);
        console.log('assessment API response', data)
        this.formResponse = data
        if (data.statusCode == 200) {
          this.common.focusAreaStatusFunction(this.SHReviewStatus.value);
          this.openSuccessDilaogeMessage('Data has been Cancelled', 'Cancel');
          //this.router.navigate(['cap/focusArea'], {queryParams : {POR: this.sendFormVar['POR']}})
        }
        else if (data.errorType) {
          this.openErrorDilaogeMessage('Something went wrong, try after sometime', '');
        }
      },
      error: (error: any) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }

  disableInternalQuestions(questionsArray: { 
    forEach: (arg0: (ele: any) => void) => void; 
    internalQuestions?: { 
        isDisable: boolean; 
        internalQuestions?: any
    }[] 
}) {
    console.log('Internal Question', questionsArray);

    // Disable the current questions
    questionsArray.forEach((ele: { isDisable: boolean }) => {
        ele.isDisable = true;
    });

    // If there are internal questions, process them
    if (questionsArray.internalQuestions && questionsArray.internalQuestions.length > 0) {
        questionsArray.internalQuestions.forEach((obj) => {
            obj.isDisable = true;

            // Recursively disable internal questions if they exist
            if (obj.internalQuestions) {
                this.disableInternalQuestions({
                    forEach: (callback: (ele: any) => void) => obj.internalQuestions.forEach(callback),
                    internalQuestions: obj.internalQuestions,
                });
            }
        });


        this.disableInternalQuestions(questionsArray.internalQuestions);
      }
  }
  
  clearInternalQuestions(questionsArray: any){
    console.log('Internal Question', questionsArray);
      questionsArray.answer = questionsArray.type == "radiogroup" ? '' : [{}]
      questionsArray.showSubQue = false
      questionsArray.isDisable = false;
      if(questionsArray.internalQuestions && questionsArray.internalQuestions.length > 0){
         let selectedQue = questionsArray.internalQuestions
         selectedQue.forEach( (obj: { answer: string | {}[]; type: string; showSubQue: boolean; isDisable: boolean; hasOwnProperty: (arg0: string) => any; }) => {
          obj.answer = obj.type == "radiogroup" ? '' : [{}]
          obj.showSubQue = false
          obj.isDisable = false
          if(obj.hasOwnProperty('internalQuestions')){
            this.clearInternalQuestions(obj);
          }
         })
        this.clearInternalQuestions(questionsArray.internalQuestions);
      }
  }

  reset(){
    // window.location.reload();
    let questionArray = this.QAArray;
    console.log(this.userResponse);
    console.log(this.QAArray);
    this.common.loader.next(true);
    if (this.userResponse.length == 0) {
      this.disableResetBtn = true;
      //this.loading = false;
    }
    else {
      this.disableResetBtn = false;
      for(let i = 0; i < this.userResponse.length; i++){
        this.clearInternalQuestions(this.userResponse[i]);
      }    
      this.common.loader.next(false);
      this.QAArray = [...new Set(this.QAArray.concat(this.userResponse))]
    }

    this.openSuccessDilaogeMessage('Data has been Reset','Reset');
  }
  
  help(){
    this.openDialogInputField();
  }
  
  // openDialogInputField() {
  //   this.helpText='';
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = false;
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.ariaLabelledBy = "text"
  //   dialogConfig.maxWidth = '35vw',
  //   dialogConfig.maxHeight = '55vh',
  //   dialogConfig.height = '85%',
  //   dialogConfig.width = '85%',
  //   dialogConfig.data = {
  //     showInput: true,
  //     title: 'Help Notification',
  //     // errorMessage: 'Please enter the assessment before click on Submit',
  //     commentText: this.helpText,
  //     inputLabel: 'Assessment Question',
  //     textHeading: 'Enter assessment question for which help is required'
  //   }
  //   const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
  //   dialogRefSubmit.componentInstance.onSubmit.subscribe({
  //     next: (result: string | undefined) => {
  //       if (result == undefined || result == "") {
  //         dialogConfig.data['errorMessage'] = 'Please enter the assessment before click on Submit';
  //       }
  //       else {
  //         dialogRefSubmit.close();
  //         this.helpText = result
  //         this.openDialogMessage('The Focus Area Stakeholder has been notified that you need help with this assessment. Please look for that notification in your email.', 'Help')
  //       }
  //     },
  //     error: (error: any) => {
  //       console.error("Error occurred:", error);
  //       alert("Please connect with Asset Admin");
  //     }
  //   })
 
  // }
  openDialogInputField() {
    this.helpText = '';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true; // Autofocus improves usability.
    dialogConfig.ariaLabelledBy = 'text';
    dialogConfig.maxWidth = '35vw'; // Set a specific width for better control.
    dialogConfig.maxHeight = '95vh';
    dialogConfig.panelClass = 'custom-dialog-container'; // Custom class for styling.
  
    dialogConfig.data = {
      showInput: true,
      title: 'Help Notification',
      commentText: this.helpText,
      inputLabel: 'Assessment Question',
      textHeading: 'Enter the assessment question for which you need help.',
    };
  
    const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
  
    dialogRefSubmit.componentInstance.onSubmit.subscribe({
      next: (result: string | undefined) => {
        if (!result?.trim()) {
          dialogConfig.data['errorMessage'] = 'Please enter a valid question before submitting.';
        } else {
          dialogRefSubmit.close();
          this.helpText = result;
          this.openDialogMessage(
            'The Focus Area Stakeholder has been notified that you need help with this assessment. Please check your email for the notification.',
            'Help Notification'
          );
        }
      },
      error: (error: any) => {
        console.error('Error occurred:', error);
        alert('An error occurred. Please contact the Asset Admin.');
      },
    });
  }
  
 openErrorDilaogeMessage(message: string, assetID: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      message: message,
      tabletemplate: false
    }
    const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig); 
  }

  openSuccessDilaogeMessage(message: string, sucessType: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      message: message,
      tabletemplate: false
    }

    const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
    dialogRefSubmit.afterClosed().subscribe({
      next: (result) => {
        if (sucessType == 'Save') {
          this.router.navigate(['cap/focusArea'], { queryParams: { POR: this.sendFormVar['POR'] } })
        }
        if (sucessType == 'Cancel') {
          window.location.reload();
        }

        //Final Submit for review
        if (sucessType == 'Submit') {
          this.router.navigate(['cap/focusArea'], { queryParams: { POR: this.sendFormVar['POR'] } })
        }
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    })
  }

  openDialogMessage(message: string, funcName: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.ariaLabelledBy = "text"
    dialogConfig.maxWidth = '35vw',
      dialogConfig.maxHeight = '40vh',
      dialogConfig.height = '85%',
      dialogConfig.width = '85%',
      dialogConfig.data = {
        showInput: false,
        message: message,
        tabletemplate: true,
      }
    // this.dialog.open(DialogBoxComponent, dialogConfig);
    const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
    dialogRefSubmit.afterClosed().subscribe({
      next: (result) => {
        console.log(`Dialog result: ${result}`);
        this.sendEmail(funcName);
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }

  sendEmail(funcName: string){
    this.common.getById('amd-get-labfabbyid/',this.sendFormVar['labName'].replace('/', '&#47;')).subscribe({
      next: (data: any[]) => {
        console.log("LABFAB data", data);
        if (data) {
          this.LabfabTeams = data[0];
          let focusAreaName = 'Process Systems'
          this.SHNameData = this.LabfabTeams[focusAreaName.replace(/ /g, '_')];
          console.log('SHNameData', this.SHNameData)
          this.emailDataDetails = {
            FAPrimary: this.SHNameData['Primary'],
            FASecondary: this.SHNameData['Secondary'],
            FAWatcher: this.SHNameData['Watcher'],
            PrimaryRequester: this.commonInfo['RequesterName'],
            SecondaryRequester: this.commonInfo['SecondaryReq'],
            comments: this.helpText,
            LabName: this.commonInfo['LabFab_Install_Location'],
            FocusArea: this.activatedRoute.snapshot.queryParams['FA'],
            por_number: this.commonInfo['POR'],
            functionName: funcName,
            ToolName: this.commonInfo['ToolName'],
            assetAdminEmail: 'tom.mayes2@hp.com; krista.lively@hp.com'
          }
        }
        console.log("EmailDataDetails", this.emailDataDetails, "CommonInfo", this.commonInfo)
        this.common.postCapAPI('itg/cap-email-notification-itg',this.emailDataDetails).subscribe({
          next: (ele2: any) => {
            console.log('Print data', ele2);
          },
          error: (error: any) => {
            console.error("Error occurred:", error);
            alert("Please connect with Asset Admin");
          }
        });
      },
      error: (error: any) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }
}
