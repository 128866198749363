import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapexRoutingModule } from './capex-routing.module';
import { CapexComponent } from './capex.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { MaterialModule } from 'src/app/modules/material.module';
import { CapexDetailsComponent } from './capex-details/capex-details.component';
import { CapexSearchComponent } from './capex-search/capex-search.component';
import { TableDialogComponent } from './table-dialog/table-dialog.component';
import { WbsDetailsComponent } from './wbs-details/wbs-details.component';
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { ExternalTableDialogComponent } from './external-table-dialog/external-table-dialog.component';
import { WbsCashFlowComponent } from './wbs-cashflow-summary/wbs-cashflow-summary.component';


@NgModule({
  declarations: [
    CapexComponent,
    CapexDetailsComponent,
    CapexSearchComponent,
    TableDialogComponent,
    ExternalTableDialogComponent,
    WbsDetailsComponent,
    WbsCashFlowComponent,
    AssetDetailsComponent
  ],
  imports: [
    CommonModule,
    CapexRoutingModule,
    SharedModule,
    MaterialModule
  ]
})
export class CapexModule {
}
