import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StakeholderComponent } from './stakeholder.component';
import { CapStakeholderComponent } from './cap-stakeholder/cap-stakeholder.component';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';

const routes: Routes = [
  {
    path: '', component: StakeholderComponent,
    children: [
      { path: '', redirectTo: 'capStakeholder', pathMatch: 'full' },
      { path: 'capStakeholder', component: CapStakeholderComponent },
      { path: 'edit-page/:id', component: EditDialogComponent },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StakeholderRoutingModule { }
