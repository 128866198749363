import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.css']
})
export class AssetComponent {

  constructor(private common: CommonService, private activatedRoute: ActivatedRoute) {
    this.common.currentModule.next(this.activatedRoute.snapshot.data);
    this.common.bannerData.next({ title: 'AMD' });
  }
}
