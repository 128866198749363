import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
//import * as $ from 'jquery';

@Component({
  selector: 'app-initiate-cap-request',
  templateUrl: './initiate-cap-request.component.html',
  styleUrls: ['./initiate-cap-request.component.scss']
})

export class InitiateCapRequestComponent implements OnInit {

  //initiateForm!: FormGroup;
  filledData!:FormGroup
  arr1: any = [];
  arr2: any = [];
  arr3: any = [];
  buildings: any;
  loading!: boolean;
  submitLoading!: boolean;
  showGrid!: boolean;
  show!: boolean;
  asset: any;
  rowCount: any;
  noRecord!: boolean;
  gridData: any;
  IsdisableEdit!: boolean;
  getUpdatedArray: any
  userVerifyLoading = false
  userVerify = false;
  userInvalid = false;
  upload_File!: FormGroup
  //Ldap Request
  RequestLdapValidation = {
    //Email: atob(this.common.currentUser),
    //Password: atob(this.common.getItem('AMDPS')),
    validateEmailDetails: "null",
    level:0
  }
 
  userName!: string;
  tempFileName: any;
  siteName: any;
  buildingName: any;
  showUploadFile!: boolean;
  callSubmit!: boolean;
  uploadFileData: any;
  showProgressBar!: boolean;
  testData: any;
  testFileData: any;
  loader!: boolean;
  popUpTxt!: string;
  showViewPopup!: boolean;


  callformSubmitNew!: boolean;
  hideUploadPopup: any;
  testClick: any;
  showChooseFile!: boolean;
  hidePopup: any;
  popUpTxtMsg: any;
  popupmsg!: string;
  purposeList!: string[];
  toolHistory!: string[];
  toolCondition!: string[];
  buildOption!: string[];
  // modalRef: BsModalRef;
  // modalRef1: BsModalRef;
  @ViewChild('msgPopup') msgPopup!: TemplateRef<any>;
  _isDisabled!: boolean;
  initiateReqClick!: boolean;
  selected_POR!: any;
  description!: any;
  status!: any;
  inititateFormData: any;
  keynames!: { BuildOption: string; LabFab_Install_Location: string; Site: string; Building: string; 
              SecondaryReq: string; SupplierName: string; ListSuppliers: string; ListEquipment: string; Purpose: string; Tool_Name_Description: string;
              Tool_Description: string;ToolIntent: string; ToolCondition: string; ToolName: string; ToolHistory: string; OtherLab: string; POR: string;  CAP_Status: string};
  subscription: any;
  showValidation!: boolean;
  formData: any;
  disableInitiatebtn: boolean = false;
  disableFields: boolean = false;
  //sendStatusUpdate!: {} ;
  sendStatusUpdate:any ;
  viewAttachedFiles:any = [];

  initiateForm: FormGroup = this.fb.group({
    POR: [''],//this.selected_POR ,
    //requesterName: this.userName,
    requesterName: this.common.userName,
    Description: this.description,
    CAP_Status: [''], //this.status,
    POR_Status: [''],
    Planner: 'NA',
    Build_Option: [''],
    Other_Lab: [''],
    Initiate_LabFab: [''],
    Initiate_Site: [''],
    Initiate_Building: [''],
    Primary_Lab: [''],
    Secondary_Req: [''],
    Supplier_Name: [''],
    List_Suppliers: [''],
    List_Equipment: [''],
    Tool_Name: [''],
    Tool_Name_Description: [''],
    Tool_Description: [''],
    Tool_Intent: [''],
    Purpose: [''],
    Tool_History: [''],
    Tool_Condition: [''],
    Requester_Email: this.common.userName,
    capUpload: ['']
  });

  constructor(
    private fb: FormBuilder, 
    private common: CommonService,
    private router: Router, 
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {   this.common.bannerData.next({ title: 'Initiate Capital Acquisition Request'});
     }

  ngOnInit() {
    this.userName = atob(this.common.currentUser);

    this.sendStatusUpdate = {
      POR: this.selected_POR == undefined ? this.activatedRoute.snapshot.queryParams['POR'] : this.selected_POR,
      PORStatus: this.status
    }

    this.common.loader.next(true);
        this.common.postCapAPI('itg/cap-update-por-status-itg',this.sendStatusUpdate).subscribe({
          next: (data: any) => {
            // this.capDetails.getDataFromDB1(this.selected_POR,this.status).subscribe(data => {
            console.log("Print data", data)
            let modifiedData = data.body
            this.inititateFormData = modifiedData;
            this.setFormValue(this.inititateFormData);
          },
          error: (error: any) => {
            console.error("Error occurred:", error);
            alert("Please connect with Asset Admin");
          }
        });

    // this.common.documentData.subscribe({
    //   next: (msg: any) => {
    //     if (msg.capItemVal) {
    //       this.selected_POR = msg.capItemVal.POR
    //       this.status = msg.status
    //     }
    //     else {
    //       this.selected_POR = msg.PORNumber
    //       this.status = msg.PORStatus
    //       //this.disableFields = true
    //     }
    //     // this.sendStatusUpdate = {
    //     //   POR: this.selected_POR == undefined ? this.activatedRoute.snapshot.queryParams['POR'] : this.selected_POR,
    //     //   PORStatus: this.status
    //     // }
    //     //this.loading = true
    //     // this.common.loader.next(true);
    //     // this.common.postCapAPI('itg/cap-update-por-status-itg',this.sendStatusUpdate).subscribe({
    //     //   next: (data: any) => {
    //     //     // this.capDetails.getDataFromDB1(this.selected_POR,this.status).subscribe(data => {
    //     //     console.log("Print data", data)
    //     //     let modifiedData = data.body
    //     //     this.inititateFormData = modifiedData;
    //     //     this.setFormValue(this.inititateFormData);
    //     //   },
    //     //   error: (error: any) => {
    //     //     console.error("Error occurred:", error);
    //     //     alert("Please connect with Asset Admin");
    //     //   }
    //     // });
    //   },
    //   error: (error: any) => {
    //     console.error("Error occurred:", error);
    //     alert("Please connect with Asset Admin");
    //   }
    // });

    if(this.selected_POR == undefined || ''){
      this.selected_POR = localStorage.getItem('POR') 
    }

    this.subscription = this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          let browserRefresh = !this.router.navigated;
          console.log('Browser', browserRefresh)
        }
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });

    this.filledData = this.fb.group({
      POR: this.initiateForm.controls['POR'].value, 
      requesterName: this.common.userName,
      Tool_Name_Description: this.initiateForm.controls['Tool_Name_Description'].value,
      POR_Status: this.initiateForm.controls['POR_Status'].value,
      CAP_Status: this.initiateForm.controls['CAP_Status'].value,
      Planner: this.initiateForm.controls['Planner'].value,
    })

    

      this.keynames = {
        'POR': 'POR',
        'CAP_Status': 'CAP_Status',
        'BuildOption': 'Build_Option',
        'LabFab_Install_Location': 'Initiate_LabFab',
        'Site': 'Initiate_Site',
        'Building': 'Initiate_Building',
        'SecondaryReq': 'Secondary_Req',
        'SupplierName': 'Supplier_Name',
        'ListSuppliers': 'List_Suppliers',
        'ListEquipment': 'List_Equipment',
        'Purpose': 'Purpose',
        'Tool_Name_Description': 'Tool_Name_Description',
        'Tool_Description': 'Tool_Description',
        'ToolIntent': 'Tool_Intent',
        'ToolCondition': 'Tool_Condition',
        'ToolName': 'Tool_Name',
        'ToolHistory': 'Tool_History',
        'OtherLab': 'Other_Lab',
        // 'RequesterEmail': 'Requester_Email',
    }
   
    this.upload_File = this.fb.group({
      Name: [''],
      Path: ['']
    });
 
    this.purposeList = ['Baseline', 'Development', 'Research']
    this.toolHistory = ['First of its kind', 'Replication', 'Replication w/modification', 
                        'Upgrade existing tool']

    this.toolCondition = ['New', 'Used', 'Leased', 'Transferred from another HP site'];
    this.buildOption = ['Off the shelf', 'Off the shelf w/modifications', 'Custom'];

    this.common.getAPI('amd-get-labsite/').subscribe({
      next: (data: any) => {
        this.buildings = data;
        console.log(this.buildings);
        this.common.loader.next(false);
       // this.loading = false;
        for (let i = 0; i < this.buildings?.length; i++) {
          let tmp = this.buildings[i]['Location'];
          this.arr1.push(tmp);
        }
        this.arr1 = this.arr1?.filter(function (elem:any, index:any, self:any) {
          return index === self.indexOf(elem);
        })
        this.arr1.sort();
        if (this.initiateForm.get('Initiate_Building')?.value) {
          this.SearchProduct();
          this.searchLabFabName();
        }
      },
      error: (error: any) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }
   
  setFormValue(data: any){
    // this.userName = this.initiateForm.get("Secondary_Req").value
    this.initiateForm.patchValue(data)
    this.filledData.patchValue(data);
    if (data['capUpload']) {
      //this.viewAttachedFiles = typeof(data['capUpload']) == 'string' ? JSON.parse(data['capUpload']) : [data['capUpload']]
      this.viewAttachedFiles = JSON.parse(data['capUpload'])
      this.viewAttachedFiles = this.viewAttachedFiles?.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj?.Name === o?.Name)) {
          unique.push(o);
        }
        return unique;
      }, []);
      console.log(this.viewAttachedFiles)
    }
    for (const [key, value] of Object.entries(this.keynames)) {
      this.initiateForm.controls[value].setValue(data[key])
    }
    //this.loading= false;
    this.common.loader.next(false);
  }


  SearchProduct() {
    console.log(this.initiateForm.get("Initiate_Site")?.value);
    this.siteName = this.initiateForm.get("Initiate_Site")?.value;
    console.log("Value=" + this.siteName)
    this.arr2 = []
    this.arr3 = []
    console.log("Before Lop")
    for (let i = 0; i < this.buildings?.length; i++) {
      if (this.buildings[i]['Location'] == this.initiateForm.get("Initiate_Site")?.value) {
        let tmp: any = this.buildings[i]['Lab_name'];
        this.arr2.push(tmp);
      }
    } 
    this.arr2 = this.arr2?.filter(function(elem: any, index: any, self: any) {
      return index === self.indexOf(elem);
    })
    this.arr2.sort();
  };

  searchLabFabName(){
    this.buildingName = this.initiateForm?.get("Initiate_Building")?.value
    this.arr3 = this.buildings?.filter((e:any)=>e.Location ==  this.siteName && e.Lab_name == this.buildingName).map((a:any)=> a.LabFab_Name)
    console.log("Array 3", this.arr3)
     this.arr3 = this.arr3?.filter(function(elem: any, index: any, self: any) {
          return index === self.indexOf(elem);
        })
    this.arr3?.sort();
  }

  verifyName(event: any){
    let value = event.target.value;
    if(value == ''){
      this.userVerify = false
      this.userInvalid = false;
    }
  }

   //Ldap validation with user name / email ID 
   validUserLdap(user: any) {
    this.common.loader.next(true);
    //this.userVerifyLoading = true;
    this.userVerify = false
    this.userInvalid = false;
    if(user == ""){
      this.openDialogMessage('Please enter the Username/ Email id', '');
      //this.userVerifyLoading = false;
      this.common.loader.next(false);
    }
    this.RequestLdapValidation['validateEmailDetails'] = user.includes('.') ? (user.includes('@hp.com') ? user : user + '@hp.com') : user //if email tom.gary
  // if(this.RequestLdapValidation['Email'] && this.RequestLdapValidation['Password'] && this.RequestLdapValidation['validateEmailDetails']!=''){
    if (this.RequestLdapValidation['validateEmailDetails'] != '') {
    this.common.postAPI('amd-ldap-email-validation',this.RequestLdapValidation).subscribe({
      next: (data: any) => {
        console.log(data)
        //this.userVerifyLoading = false;
        this.common.loader.next(false);
        if (data?.statusCode == 200 && data?.body && data?.body?.length) {
          this.userVerify = true;


          let emailString = "";
          let Additional_DescriptionLdap = ''
          for (let x of data?.body) {
            if (x['email']) {
              emailString += x['email'] + ";"
            }
          }
          if (emailString.charAt(emailString.length - 1) == ';') {
            Additional_DescriptionLdap = emailString.slice(0, -1)
          }

          this.initiateForm.get('Secondary_Req')?.setValue(Additional_DescriptionLdap)

        }
        // if user is not valid in LDAP
        if (data?.errorMSg || data.body?.length == 0 || data.body?.userNotFound) {
          this.userInvalid = true;
        }

        if (data.body?.userNotFound?.length == 0) {
          this.userInvalid = false;
          this.userVerify = true;
        }
      },
      error: (error: any) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }
  }

    // add the child files 
    addChildForm(FileData: string, group: any) {
      group['Path'] = "cap";
      console.log("group", group)
      this.initiateForm.addControl(FileData, group);
      if (group['Name'] != "" && group['Name']) {

        const fileName = group['Name'].split("\\").pop(); 
        group['Name'] = fileName; // Update the group with the actual file name
    
  
        this.viewAttachedFiles.push(group)
        console.log("array check", this.viewAttachedFiles, Array.isArray(this.viewAttachedFiles))
        this.viewAttachedFiles = this.viewAttachedFiles?.reduce((unique: any, o: any) => {
          if (!unique.some((obj: any) => obj?.Name === o?.Name)) {
            unique.push(o);
          }
          return unique;
        }, []);
        console.log("this.viewAttachedFiles", Array.isArray(this.viewAttachedFiles), this.viewAttachedFiles)
        this.initiateForm.controls['capUpload'].setValue(this.viewAttachedFiles)
      }
    }
  
  saveCapRequest(btnName:any){
    console.log(this.initiateForm.value);
    //this.submitLoading = true;
    this.common.loader.next(true);
    for (const [key, value] of Object.entries(this.keynames)) {
      console.log("Key Value", key, value);
      if (this.initiateForm.value[value] == undefined) {
        this.initiateForm.value[value] = ' ';
      }
    }
    // Set capstatus to 'Draft'
    this.initiateForm.value['CapStatus'] = 'Draft';

    this.filledData.get('CAP_Status')?.setValue('Draft');
    
    if (this.initiateForm.controls['capUpload'].value && Array.isArray(this.initiateForm.controls['capUpload'].value)) {
      this.initiateForm.value['capUpload'] = JSON.stringify(this.initiateForm.controls['capUpload'].value)
    }
    this.common.postCapAPI('itg/cap-add-initiate-data-itg',this.initiateForm.value).subscribe({
      next: (data: any) => {
        //this.submitLoading = false;
        this.common.loader.next(false);
        this.formData = data
        this.inititateFormData = this.formData;
        console.log('Print', data);
        this.openDialogMessage('Draft has been saved', '');
      },
      error: (error: any) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }

  submitCapRequest(btnName:any){
    console.log(this.initiateForm.value);
    this.showValidation = false;
    this.common.loader.next(true);
    //this.submitLoading = true;
    for (const [key, value] of Object.entries(this.keynames)) {
      console.log("Key Value", key, value);
      if (this.initiateForm.value[value] == undefined) {
        this.initiateForm.value[value] = ' ';
      }
    }

    // Set capstatus to 'Draft'
    this.initiateForm.value['CapStatus'] = 'Active';

    if (this.initiateForm.controls['capUpload'].value && Array.isArray(this.initiateForm.controls['capUpload'].value)) {
      this.initiateForm.value['capUpload'] = JSON.stringify(this.initiateForm.controls['capUpload'].value)
    }
    this.common.postCapAPI('itg/cap-add-initiate-data-itg',this.initiateForm.value).subscribe({
      next: (data: any) => {
        //this.submitLoading = false;
        this.common.loader.next(false);
        this.formData = data
        this.inititateFormData = this.formData;
        console.log('Print', data);
        if (this.formData) {
          for (const [key, value] of Object.entries(this.keynames)) {
            console.log("Key Value", key, value);
            if (!(value == 'Other_Lab' || value == 'CAP_Status' || value == 'Secondary_Req' ) && this.initiateForm.value[value].trim() == '') {
              this.showValidation = true;
              break;
            }
          }
        }

        if (this.showValidation) {
          this.toastr.warning('Please fill all the mandatory fields', 'Warning')
         // this.openDialogMessage('Please fill all the mandatory fields', '');
        }
        else {
          if (btnName == 'Submit') {
            this.initiateReqClick = true;
            if (this.initiateReqClick) {
              localStorage.setItem('initiateReqClicked', 'InitiateClicked')
              this.initiateForm.get('Other_Lab')?.disable()
            }
            localStorage.setItem('InitiateBuilding', this.inititateFormData['InitiateBuilding']);
            localStorage.setItem('InitiateLabFab', this.inititateFormData['LabFab_Install_Location']);
            let passInitiateFormData = Object.assign(this.initiateForm.value, this.inititateFormData)
            this.common.passDataFunction(this.inititateFormData);
            this.router.navigate(['cap/focusArea'], { queryParams: { POR: this.initiateForm.controls['POR'].value } })
          }
          else {
            this.openDialogMessage('Draft has been saved', '');
          }
        }
      },
      error: (error: any) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
   
  
  }

  onSubmit() {
    this.initiateReqClick = true;
    if(this.initiateReqClick){
      localStorage.setItem('initiateReqClicked', 'InitiateClicked')
      this.initiateForm.get('Other_Lab')?.disable()
    }
    localStorage.setItem('InitiateBuilding', this.inititateFormData['InitiateBuilding']);
    localStorage.setItem('InitiateLabFab', this.inititateFormData['LabFab_Install_Location']);
    let passInitiateFormData = Object.assign(this.initiateForm.value, this.inititateFormData)
    this.common.passDataFunction(this.inititateFormData);
    this.router.navigate(['cap/focusArea'], {queryParams: { POR: this.selected_POR}})
  }

  cancel() {  
    let arrVal = ['PORNumber', 'requesterName', 'Description', 'PORStatus', 'capitalPlanner'];
    this.initiateForm.reset();
  }

  back(){
    this.router.navigate(['cap/capDetail'], {queryParams: { porStatus: this.status}})
  }

  openDialogMessage(message: any, assetID: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    
    dialogConfig.data = {
      message: message,
      tabletemplate: false
    }

    this.dialog.open(DialogBoxComponent, dialogConfig);
  }
}

// function progressHandler(event:any) {
//   const app:any = document.getElementById("progressBar");
//   var percent = (event.loaded / event.total) * 100;
//   if (percent < 6) {
//     percent = 5;
//   }
//   $("#progressBar").css("width", percent + "%");
//   app.innerHTML = '1%';
//   app.innerHTML = '<span id="status" style="font-size:12px;color:white;font-weight:500;">' + (Math.round(percent) + "%") + '</span>';
// }

function completeHandler(event:any) {
  const status:any = document.getElementById("status");
  status.innerHTML = event.target.responseText;
}