
// Uploading the files to S3 buket from the parent component path (uploadFilePath)


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
declare var $: any;
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-file-upload-amd',
  templateUrl: './file-upload-amd.component.html',
  styleUrls: ['./file-upload-amd.component.css']
})
export class FileUploadAMDComponent implements OnInit {
  @Input() uploadFilePath: string = "amd";

  // Uploading file is required or not uploadFileRequired from th eparent component
  @Input() uploadFileRequired: boolean = false;

  // uploading file name to have specified document Name
  @Input() uploadFileName: string = '';

  // uploading file name must be in the  given formate name
  // for all type of uploads will b 'any'
  @Input() uploadFileNameMust: string = '';

  @Input() uploadFolderName: string = '';
  @Input() disableOnSubmit: boolean | undefined;

  @Input('ParentBtnSubmitted') ParentBtnSubmitted: boolean = false;
  @Output() childFormReady = new EventEmitter<FormGroup>();

  testData: any;
  testFileData: any;
  tempFileName: any;
  showUploadFile!: boolean;
  upload_File: any;
  callSubmit!: boolean;
  callformSubmitNew!: boolean;
  showProgressBar!: boolean;
  loader!: boolean;
  popUpTxt!: string;
  showViewPopup!: boolean;
  showChooseFile!: boolean;
  hidePopup: any;
  popupmsg!: string;
  testClick: any;
  BasedonParentRequired: any
  loading = false;

  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.BasedonParentRequired = this.uploadFileRequired == true ? Validators.required : ''

    this.upload_File = this.fb.group({
      Name: ['', this.BasedonParentRequired],
      Path: ['']
      // Folder: ['']
    });

    this.childFormReady.emit(this.upload_File);
  }
  uploadFile(event: any) {
    this.testFileData = event.target.files
    this.tempFileName = event.target.files;
    this.showUploadFile = false
    this.loading = false;
    let document = this.tempFileName.item(0)

    // if(this.uploadFilePath == 'cap'){
    //   this.upload_File.setValue({
    //     Name: document.name,
    //     Path: this.uploadFilePath,
    //     Folder: this.uploadFileName
    //   })
    // }
    // else{
    // this.upload_File.setValue({
    //   Name: this.uploadFileName != '' ? this.uploadFileName + document?.name : document?.name,
    //   Path: this.uploadFilePath
    // })
    //}
    let fileFormate = event.target?.files[0]?.name?.split('.')
    if (this.uploadFileNameMust == event?.target?.files[0]?.name || this.uploadFileNameMust == 'any' || this.uploadFileNameMust == fileFormate[1]) {
      let fileData = {"Name": document?.name,"Path": this.uploadFilePath}
      // let fileData = {"Name": this.uploadFileName != '' ? this.uploadFileName + document?.name : document?.name,"Path": this.uploadFilePath}
      this.loading = true;
      this.common.postAPI('amd-cap-upload-file', fileData).subscribe((data: any) => {
        this.showUploadFile = true
        this.loading = false
        this.callSubmit = true;
        this.testData = data;
      })
    }

    else {
      let fileUploadErr = 'Upload File must be ' + `"${this.uploadFileNameMust}"`
      this.openDialogMessage(fileUploadErr, '')
    }

  }

  // async formSubmitNew(event: any) {
    formSubmitNew = async (event: any) => { 
    this.callSubmit = false;
    event.preventDefault();
    let disableUpload = document.getElementById("uploadBtn");
    this.callformSubmitNew = false;
    // this.modalRef.hide();

    if (this.testData) {
      this.tempFileName = '';
      this.showProgressBar = true;
      disableUpload?.setAttribute('disabled', "");
      disableUpload?.classList.add("removePointer");

      await this.sendDataDup("POST", this.testData.url)
         .then((datums) => {
          window.localStorage.setItem("reqData", "success");

        })
        .catch(function (err) {
          window.localStorage.setItem("reqData", "fail");
        });
      let reqdata1 = window.localStorage.getItem("reqData");
      if (reqdata1 == 'success') {
        this.loader = false;
        this.showProgressBar = false;
        disableUpload?.removeAttribute("disabled");
        disableUpload?.setAttribute('enabled', "");
        disableUpload?.classList.add("addPointer");
        this.upload_File.value['IsFileUploaded'] = true
        this.childFormReady.emit(this.upload_File?.value);
        this.popUpTxt = ' File uploaded Successfully';
        this.openDialogMessage(this.popUpTxt, '');
        //alert('File uploaded Scccessfully')
        //this.modalRef2 = this.modalService.show(this.uploadMsg, { ignoreBackdropClick: true, class: 'modal-sm' });
        this.showViewPopup = true;
      }
      else {
        this.loader = false;
        this.showProgressBar = false;
        disableUpload?.removeAttribute("disabled");
        disableUpload?.setAttribute('enabled', "");
        disableUpload?.classList.add("addPointer");
        this.popUpTxt = ' File not uploaded, please upload it again!!!';
        this.openDialogMessage(this.popUpTxt, '');
        //alert('File not uploaded, please upload it again!!!')
        //this.modalRef1 = this.modalService.show(this.popUpTxtMsg, { ignoreBackdropClick: true, class: 'modal-sm' });
        this.showViewPopup = false;
      }
      this.testClick = false;
      this.showChooseFile = false;

    }
    else if (this.tempFileName) {
      this.callformSubmitNew = true;
      this.showViewPopup = false;
      this.showProgressBar = true;
      this.popUpTxt = 'Please wait, uploading the file.';
      this.openDialogMessage('Please wait, uploading the file', '');
      //alert('Please wait, uploading the file.')
      if (this.hidePopup) {
        // this.modalRef1 = this.modalService.show(this.popUpTxtMsg, { ignoreBackdropClick: true, class: 'modal-sm' });
      }
    }
    else {
      this.showProgressBar = false;
      this.showViewPopup = false;
      this.popupmsg = 'Please choose the file to upload.';
      this.openDialogMessage('Please choose the file to upload', '');
      //alert('Please choose the file to upload.')
      //this.modalRef1 = this.modalService.show(this.msgPopup, { ignoreBackdropClick: true, class: 'modal-sm' });
      // alert("pls upload the file first.")
    }
  }

  async sendDataDup(method: any, url: any) {
    return await new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(this.testData.fields).forEach(key => {
        formData.append(key, this.testData?.fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("file", this.testFileData[0]);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.onload = function () {
        if (xhr.status == 204) {
          window.localStorage.setItem("reqData", "success");

          resolve((xhr.response));
        } else {
          window.localStorage.setItem("reqData", "");
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.upload.addEventListener("progress", progressHandler);
      xhr.send(formData);
    });
  }

  openDialogMessage(message: any, assetID: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      message: message,
      tabletemplate: false
      //title: assetID ? 'Thank You' : "Action Plan"
    }

    this.dialog.open(DialogBoxComponent, dialogConfig);
  }

}

function progressHandler(event: any) {
  const app = document.getElementById("progressBar");
  if (app) {
    var percent = (event.loaded / event.total) * 100;
    if (percent < 6) {
      percent = 5;
    }
    $("#progressBar").css("width", percent + "%");
    app.innerHTML = '1%';
    app.innerHTML = '<span id="status" style="font-size:12px;color:white;font-weight:500;">' + (Math.round(percent) + "%") + '</span>';
  }
}

function completeHandler(event: any) {
  const status = document.getElementById("status");
  if (status) {
    status.innerHTML = event?.target?.responseText;
  }
}
