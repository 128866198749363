<div [formGroup]="upload_File">
    <input id="files" type="file" multiple="false"
        [ngClass]="{ 'is-invalid': ParentBtnSubmitted==true && upload_File.controls?.Name?.status=='INVALID' }"
        class="btn box-padding field-border form-control estCostFile" (change)="uploadFile($event)"
        formControlName="Name">
    <div *ngIf="ParentBtnSubmitted==true && uploadFileRequired == true" class="invalid-feedback">
        <div *ngIf="ParentBtnSubmitted==true && uploadFileRequired == true">
            File upload is required </div>
    </div>

    <button id="uploadBtn" type="submit" class="btn btn-primary form-control btnUpload" (click)="formSubmitNew($event)"
        [disabled]="!showUploadFile" style="margin-left:2%">
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>&nbsp;
        <i class="fa fa-upload" *ngIf="!loading"></i> {{loading?'Fetching File':'Upload File'}}
    </button>
    <div *ngIf="callSubmit" class="text-danger">Please click on Upload File</div>
    <div class="fileProgress" *ngIf="this.showProgressBar">
        <div style="position:relative;font-size:13px">File upload is in progress...</div>
        <div class="progress fieldBorderBottom fileProgressBar">
            <div id="progressBar" class="progress-bar progress-bar-striped progress-bar-animated appColor"
                role="progressbar" value="5" max="100" style="width:1%">
                <span id="status" class="fileProgressSpan"></span>
            </div>
        </div>
    </div>
</div>