import { Component, OnInit, HostListener, ElementRef, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { Location } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//declare let html2canvas: any;
@Component({
  selector: 'app-save-as-asset',
  templateUrl: './save-as-asset.component.html',
  styleUrls: ['./save-as-asset.component.css']
})
export class SaveAsAssetComponent implements OnInit {
  saveAsset: any[] = []
  asset: any[] = [];
  capturedImage: any;

  constructor(private location: Location, private common: CommonService, @Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<SaveAsAssetComponent>) { }

  ngOnInit() {
    this.saveAsset = this.data;
      if (!this.saveAsset || this.saveAsset?.length === 0) {
        this.common.show('error', 'No item selected');
        this.location.go('asset/asset-detail');
      }
  }

  public downloadImage() {
    this.download(0, this.saveAsset);
  }

  private download(index: any, array: any) {
    if (index >= array.length) {
    } else {
      let docElem = document.getElementById(array[index]['Asset_Number'].toString());
      if (docElem) {
        html2canvas(docElem).then((canvas: any) => {
          let generatedImage = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
          let a = document.createElement('a');
          a.href = generatedImage;
          a.download = "AssetLabel_" + `${array[index]['Asset_Number']}.png`;
          a.click();
          this.download(index + 1, this.saveAsset)
        });
      }

    }
  }

  close() {
    this.dialogRef.close();
  }

}
