
<mat-accordion multi="true">
    
    <!-- Request Details accordion-->
    <form [formGroup] = "requestDetailsForm">
    <mat-expansion-panel class="panel-spacing">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="border-text">Request Details</div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        
            <div class="container-fluid pd-0"  (opened)="onPanelClick('Request Details')">
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Request Type</mat-label>
                        <mat-select formControlName="requestType">
                            <mat-option *ngFor="let option of requestTypeOptions" [value]="option">{{option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Project Type</mat-label>
                        <mat-select formControlName="projectType" (selectionChange)="changeProjectType($event)">
                            <mat-option *ngFor="let option of projectTypeOptions" [value]=option>{{option}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Project Manager Email</mat-label>
                        <input matInput type="text" class="placeholderfont" placeholder="'example@hp.com'/First Last Name(Dale L Marr)"
                            formControlName="projectManagerEmail" (input)="enterEmail($event)" [ngClass]="{ 'is-invalid':requestDetailsForm.controls['projectManagerEmail'].errors }">
                        <mat-hint *ngIf="requestDetailsForm.controls['projectManagerEmail'].errors">
                            <span class="text-danger" *ngIf="requestDetailsForm.controls['projectManagerEmail'].errors['required']">
                                Project Manager Email is required
                            </span>
                            <span class="text-danger" *ngIf="requestDetailsForm.controls['projectManagerEmail'].errors['pattern']">
                                Should Be 'example@hp.com'
                            </span>
                        </mat-hint>
                        <div matSuffix class="pdright-18">
                            <button type="button" class="btn btn-primary btn-sm"  matTooltip="verify Changes"
                                (click)="validUserLdap(requestDetailsForm.controls['projectManagerEmail'].value)"
                                style="margin-bottom:10px">{{userVerify ? 'Verified' : 'Verify'}}
                                <i class="fa fa-spinner fa-spin" *ngIf="userVerifyLoading"></i>
                            </button>
                            <button mat-icon-button class="material-icons app-toolbar-menu save-button validUser" matTooltip="valid User"
                                *ngIf="userVerify">
                                <mat-icon>check_circle</mat-icon>
                            </button>
                            <button mat-icon-button class="material-icons app-toolbar-menu cancel-button" matTooltip="Invalid User" color="warn"
                                *ngIf="userInvalid">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                            <mat-label class="label-text">Planner</mat-label>                         
                        <mat-select formControlName="planner" (click)="showPlannerFinanceDD()">
                            <mat-option *ngFor="let option of plannerOptions" [value]="option">{{option}}</mat-option>
                            <mat-option class="matIconColor" (click)="showTable($event)">Planner/Finance Table <mat-icon  class="matIconColor" style="margin-right: 3px;">table_view</mat-icon></mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                            <mat-label class="label-text">Finance </mat-label>
                        <mat-select formControlName="finance" (click)="showPlannerFinanceDD()">
                            <mat-option *ngFor="let option of financeOptions" [value]="option">{{option}}</mat-option>
                            <mat-option class="matIconColor" (click)="showTable($event)">Planner/Finance Table <mat-icon  class="matIconColor"  style="margin-right: 3px;">table_view</mat-icon></mat-option>
                          </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Tool/Line Item Description</mat-label>
                        <input matInput formControlName="itemDescription" placeholder="" value="">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Justification</mat-label>
                        <mat-select formControlName="justification">
                            <mat-option *ngFor="let option of justificationOptions" [value]="option">{{option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Impact of Not Doing</mat-label>
                        <mat-select formControlName="impact">
                          <mat-option *ngFor="let option of impactOptions" [value]="option">{{option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">External Funding  <mat-icon class="info-icon custom-icon" (click)="downloadPdf('extFunding')">info</mat-icon></mat-label>
                        <mat-select formControlName="external" multiple (click)="showExtFinanceDD()">
                          <mat-option *ngFor="let option of externalOptions" [value]="option">{{option}}</mat-option>
                          <mat-option class="matIconColor" (click)="showExternalTable($event)">External Funding Table <mat-icon  class="matIconColor" style="margin-right: 3px;">table_view</mat-icon></mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Build Site (select if shipping to another site)</mat-label>
                        <mat-select formControlName="buildSite" (selectionChange)="onBuildSiteChange($event)">
                            <mat-option *ngFor="let option of buildSiteOptions" [value]="option">{{option}}</mat-option>
                          </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Site</mat-label>
                        <mat-select formControlName="site" (selectionChange) = "SearchBuilding()">
                            <mat-option *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-option>
                            <mat-option *ngFor="let option of allSites" [value]="option">{{option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Building</mat-label>
                        <mat-select formControlName="building" (selectionChange) = "SearchLabFabName()">
                            <mat-option *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-option>
                            <mat-option matInput *ngFor="let option of filteredBuilding" [value]="option">{{option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">LabFab Install Location</mat-label>
                        <mat-select formControlName="labfabInstallLocation">
                            <mat-option *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-option>
                            <mat-option matInput *ngFor="let option of filteredLabFabName" [value]="option">{{option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text"># of Assets</mat-label>
                        <input matInput formControlName="assets" placeholder="" value="">
                    </mat-form-field>
                    <mat-form-field class="col-md-4" hideRequiredMarker="true">
                            <mat-label class="label-text">Asset Useful Life (yrs)  <span [class.red]="requestDetailsForm.get('assetUsefulLife')?.touched && requestDetailsForm.get('assetUsefulLife')?.hasError('required')">*</span>
                                <mat-icon class="info-icon custom-icon" (click)="downloadPdf('usefulLife')">info</mat-icon></mat-label>
                        <input matInput formControlName="assetUsefulLife" placeholder="" value="10" (click)="changeAssetLifeVal($event)">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Comment</mat-label>
                        <input matInput formControlName="comment" placeholder="" value="">
                    </mat-form-field>
                </div>
            </div>
    </mat-expansion-panel>
    </form>

    <!-- Financial Responsibilties Details  accordion-->
    <form [formGroup] = "financialDetailsForm">
    <mat-expansion-panel class="panel-spacing" (opened)= "onFinancialPanelOpen()">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="border-text">Financial Responsibilities Details</div>
            </mat-panel-title>
        </mat-expansion-panel-header>

            <div class="container-fluid pd-0">
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Commit Cost Center</mat-label>
                        <input type="text" matInput [matAutocomplete]="auto1" formControlName="commitCostCenter" (click)="showCommitCostCenter()"
                            #trigger1="matAutocompleteTrigger" (keydown.enter)="trigger1.closePanel()">
                        <mat-autocomplete #auto1="matAutocomplete">
                          <mat-option *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-option>
                          <mat-option  (click)="showCommitCostCenter()" *ngFor="let option of filteredCommitCCOptions | async" [value]="option">{{option}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Depreciation Cost Center</mat-label>
                        <input type="text" matInput [matAutocomplete]="auto2" formControlName="depreciateCostCenter" (click)="showDepreciateCostCenter()"
                            #trigger2="matAutocompleteTrigger" (keydown.enter)="trigger2.closePanel()">
                        <mat-autocomplete #auto2="matAutocomplete">
                          <mat-option *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-option>
                          <mat-option  (click)="showDepreciateCostCenter()" *ngFor="let option of filteredDepreciateCCOptions | async" [value]="option">{{option}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Program Driver</mat-label>
                        <input type="text" matInput [matAutocomplete]="autoPD" formControlName="programDriver" (click) = "showProgramDriversOptions()"
                             #triggerPD="matAutocompleteTrigger" (keydown.enter)="triggerPD.closePanel()">
                        <mat-autocomplete #autoPD="matAutocomplete">
                            <mat-option *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-option>
                            <mat-option (click) = "showProgramDriversOptions()" *ngFor="let option of filteredProgramDriverOptions | async"
                                [value]="option">{{option}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Commit Cost Center Manager</mat-label>
                        <input matInput class="disabledColor" placeholder="" value="" formControlName = "commitCCManager">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Depreciation Cost Center Manager</mat-label>
                        <input matInput class="disabledColor" placeholder=""  value="" formControlName = "deprCCManager">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Category</mat-label>
                        <input matInput class="disabledColor" placeholder=""  value="" formControlName = "category">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Commit Organization - L2</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "commitOrgL2">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Depreciation Organization - L2</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "deprOrgL2">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Rollup</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "rollUp">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Commit Organization - L3</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "commitOrgL3">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Depreciation Organization - L3</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "deprOrgL3">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Business Unit</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "businessUnit">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Commit Organization - L4</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "commitOrgL4">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Depreciation Organization - L4</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "deprOrgL4">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Company Code</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "companyCode">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Commit Section</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "commitSection">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Depreciation Section</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "deprSection">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Region</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "region">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Commit MRU</mat-label>
                        <input matInput placeholder="" class="disabledColor" value="" formControlName = "commitMRU">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Depreciation MRU</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "deprMRU">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Commit Sub-MRU</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "commitSubMRU">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Depreciation Sub-MRU</mat-label>
                        <input matInput placeholder="" class="disabledColor"  value="" formControlName = "deprSubMRU">
                    </mat-form-field>
                </div>
            </div>
    </mat-expansion-panel>
    </form>

    <!-- Project Cost Details accordion-->
    <form [formGroup] = "projectCostDetailsForm">
    <mat-expansion-panel class="panel-spacing" (opened)="onProjectCostPanelOpen()">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="border-text">Project Cost Details</div>
            </mat-panel-title>
        </mat-expansion-panel-header>

            <div class="container-fluid pd-0">
                <div class="row">
                    <mat-form-field class="col-md-4 my-error-wrapper">
                        <mat-label class="label-text">Item Cost ($) <mat-icon class="info-icon custom-icon" (click)="downloadPdf('itemCost')">info</mat-icon></mat-label>
                        <input matInput type="number" min = "{{ getMinItemCost() }}" placeholder="" formControlName = "itemCost" (change)="formatCost()" (blur)="validateItemCostControl()" (click)="validateItemCostControl()">
                        <mat-error  class="pd-0" *ngIf="this.projectCostDetailsForm.controls['itemCost'].errors?.['min']">
                            The minimum value should be {{ getMinItemCost() }}.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-4 placeholderClass" floatLabel="always">
                        <mat-label class="label-text">Hookup - Capitalized Install Costs ($)</mat-label>
                        <input matInput type="number" min = "0" placeholder="default val is 10% of item cost" formControlName = "hookCapitalCost" (blur)="validateHookCapitalCostControl()" (click)="validateHookCapitalCostControl()">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Hookup - Expensed Install Costs ($)</mat-label>
                        <input matInput type="number" min = "0" placeholder="" formControlName = "hookExpenseCost" (change)="formatCost()">
                    </mat-form-field>
                </div>
                <div class="row">
                    <div style="margin-left:1%">
                        <p class="margin0">Do you want default NRE FTE values calculated and entered? 

                        <mat-radio-group formControlName="defaultNRE" (change)="onCheckboxChange($event)">
                            <mat-radio-button value="Yes">
                                <span class="margin0">Yes</span>
                            </mat-radio-button>
                            <mat-radio-button value="No">
                                <span class="margin0">No, will input own NRE FTE days</span>
                            </mat-radio-button>
                            <mat-radio-button value="No NRE">
                                <span class="margin0">No, there will be No NRE charges</span>
                            </mat-radio-button>
                            <mat-radio-button value="No Option Selected" hidden>
                                <span class="margin0">No Option Selected</span>
                            </mat-radio-button>
                        </mat-radio-group>

                         <div>
                            <p>If Yes, the NRE will be calculated at .12 Engineering FTE Days and .04 FTE Days per $1000 of item cost.</p>
                            <p>If No, the NRE will be calculated based on the entered FTE Days.</p>
                            <p>If No NRE, there will be no NRE charges.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Engr NRE Cost Center</mat-label>
                        <input  matInput [matAutocomplete]="auto3" formControlName="engrNRECostCenter" (click)="showEngrNRECostObj()"
                            #trigger3="matAutocompleteTrigger" (keydown.enter)="trigger3.closePanel()">
                        <mat-autocomplete #auto3="matAutocomplete">
                          <mat-option *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-option>
                          <mat-option  (click)="showEngrNRECostObj()" *ngFor="let option of filteredEngrNRECostObjOptions | async" [value]="option">{{option}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Engr NRE (FTE Days)</mat-label>
                        <input matInput type="number" min="0" placeholder="" formControlName = "engrNREFTE" (input) = "enterFTEDays($event, 'EngrNREFTE')">
                        <mat-error *ngIf="projectCostDetailsForm.get('engrNREFTE')?.hasError('min')">
                            Value must be greater than or equal to 0.
                          </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Engr NRE Cost ($)</mat-label>
                        <input matInput placeholder="" class="disabledColor" formControlName = "engrNRECost">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Tech NRE Cost Center</mat-label>
                        <input type="text" matInput [matAutocomplete]="auto4" formControlName="techNRECostCenter" (click)="showTechNRECostObj()"
                            #trigger4="matAutocompleteTrigger" (keydown.enter)="trigger4.closePanel()">
                        <mat-autocomplete #auto4="matAutocomplete">
                          <mat-option *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-option>
                          <mat-option  (click)="showTechNRECostObj()" *ngFor="let option of filteredTechNRECostObjOptions | async" [value]="option">{{option}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Tech NRE (FTE Days)</mat-label>
                        <input matInput type="number" min="0" placeholder="" formControlName = "techNREFTE" (input) = "enterFTEDays($event, 'TechNREFTE')">
                        <mat-error *ngIf="projectCostDetailsForm.get('techNREFTE')?.hasError('min')">
                            Value must be greater than or equal to 0.
                          </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Tech NRE Cost ($)</mat-label>
                        <input matInput placeholder="" class="disabledColor" formControlName = "techNRECost">
                    </mat-form-field>
                </div>
                <div class = "row border-container">
                    <span class="text-over-border">Estimated Totals</span>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">NRE Total Cost ($)</mat-label>
                        <input matInput type="number" placeholder="" class="disabledColor" formControlName = "NREtotalCost">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Total Cost ($)</mat-label>
                        <input matInput type="number"  placeholder="" class="disabledColor" formControlName = "totalCost">
                    </mat-form-field>
                    <mat-form-field class="col-md-4">
                        <mat-label class="label-text">Estimated Quarterly Depreciation</mat-label>
                        <input matInput type="number"  placeholder="" class="disabledColor" formControlName = "estimatedQatrDepr">
                    </mat-form-field>
                </div>
            </div>
    </mat-expansion-panel>
    </form>

    <!-- Cash Flow Details accordion-->
    <!-- <form [formGroup]="cashFlowDetailsForm"> -->
        <mat-expansion-panel class="panel-spacing"  (opened)="onCashFlowPanelOpen()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="border-text">Cash Flow Details (Activity by Quarter)</div>
                </mat-panel-title>
            </mat-expansion-panel-header>
                <div class="container-fluid pd-0">
                    <form [formGroup]="eventQuarterForm">
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">Commit Quarter</mat-label>
                                <input matInput [matDatepicker]="picker1" [min]="minDate" [max]="minFactoryQuarterDate" placeholder="Choose a date"
                                    class="disabledColor offscreen" formControlName="commitQuarterMatDate"
                                    (dateChange)="onDateChange($event, 'commitQuarter', picker1)">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                                <input matInput placeholder="Choose a date" formControlName="commitQuarter" class="disabledColor" readonly>
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label>Factory Acceptance Quarter</mat-label>
                                <!-- <input matInput placeholder="Choose a date" [value]="formattedDateFactory" class="disabledColor" readonly> -->
                                <input matInput [matDatepicker]="picker2" [min]="minFactoryQuarterDate" [max]="maxFactoryQuarterDate" placeholder="Choose a date"
                                    class="disabledColor offscreen" formControlName="factoryQuarterMatDate"
                                    (dateChange)="onDateChange($event, 'factoryQuarter', picker2)">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2 ></mat-datepicker>
                                <input matInput placeholder="Choose a date" formControlName="factoryQuarter" class="disabledColor" readonly>
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label>Site Acceptance Quarter (RTE)</mat-label>
                                <!-- <input matInput placeholder="Choose a date" [value]="formattedDateSite" class="disabledColor" readonly> -->
                                <input matInput [matDatepicker]="picker3" [min]="minSiteQuarterDate" [max]="maxSiteQuarterDate" placeholder="Choose a date"
                                    class="disabledColor offscreen" formControlName="siteQuarterMatDate"
                                    (dateChange)="onDateChange($event,'siteQuarter', picker3)">
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                                <input matInput placeholder="Choose a date" formControlName="siteQuarter" class="disabledColor" readonly>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label>Final Acceptance Quarter (RTO)</mat-label>
                                <input matInput [matDatepicker]="picker4" [min]="minFinalQuarterDate" placeholder="Choose a date"
                                    class="disabledColor offscreen" formControlName="finalQuarterMatDate"
                                    (dateChange)="onDateChange($event, 'finalQuarter', picker4)">
                                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                <mat-datepicker #picker4></mat-datepicker>
                                <input matInput placeholder="Choose a date" formControlName="finalQuarter" class="disabledColor" readonly>
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">Estimated Project End/Asset Setup Date</mat-label>
                                <!-- <input matInput placeholder="" class="disabledColor" formControlName="estimatedProjectEnd"> [matDatepickerFilter]="dateFilter"-->
                                <input matInput [matDatepicker]="pickerEstimatedProject" [min]="minEstimatedProjectEndDate" [max]="minEstimatedProjectEndDate" 
                                    placeholder="Choose a date" class="disabledColor offscreen" formControlName="estimatedProjectEndMatDate"
                                    (dateChange)="onDateChange($event, 'estimatedProjectEnd', pickerEstimatedProject)" >
                                <mat-datepicker-toggle matSuffix [for]="pickerEstimatedProject"></mat-datepicker-toggle>
                                <mat-datepicker #pickerEstimatedProject></mat-datepicker>
                                <input matInput placeholder="Choose a date" formControlName="estimatedProjectEnd" class="disabledColor" readonly>
                            </mat-form-field>
                        </div>
                    </form>

                    <form [formGroup]="adjustQuarterForm" [hidden]="!isWBSSectionFilled">
                        <div class="row border-container">
                            <span class="text-over-border">Adjusted Event Quarters</span>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">Commit Quarter</mat-label>
                                <input matInput placeholder="" class="disabledColor" formControlName="adjustedCommitQuarter">
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label>Adjusted Factory Acceptance Quarter</mat-label>
                                <input matInput [matDatepicker]="picker5" [min]="formattedDateAdjustedFactory" placeholder="Choose a date"
                                    class="disabledColor offscreen" formControlName="adjustedFactoryQuarterMatDate"
                                    (dateChange)="onDateChange($event, 'adjustedFactoryQuarter', picker5)">
                                <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                                <mat-datepicker #picker5></mat-datepicker>
                                <input matInput placeholder="Choose a date" formControlName="adjustedFactoryQuarter" class="disabledColor" readonly>
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label>Adjusted Site Acceptance Quarter (RTE)</mat-label>
                                <input matInput [matDatepicker]="picker6" [min]="formattedDateAdjustedSite" placeholder="Choose a date"
                                    class="disabledColor offscreen" formControlName="adjustedSiteQuarterMatDate"
                                    (dateChange)="onDateChange($event, 'adjustedSiteQuarter', picker6)">
                                <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                                <mat-datepicker #picker6></mat-datepicker>
                                <input matInput placeholder="Choose a date" formControlName="adjustedSiteQuarter" class="disabledColor" readonly>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label>Adjusted Final Acceptance Quarter (RTO)</mat-label>
                                <input matInput [matDatepicker]="picker7" [min]="formattedDateAdjustedFinal" placeholder="Choose a date"
                                    class="disabledColor offscreen" formControlName="adjustedFinalQuarterMatDate"
                                    (dateChange)="onDateChange($event, 'adjustedFinalQuarter', picker7)">
                                <mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
                                <mat-datepicker #picker7></mat-datepicker>
                                <input matInput placeholder="Choose a date" formControlName="adjustedFinalQuarter" class="disabledColor" readonly>
                            </mat-form-field>

                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">Adjusted Estimated Project End/Asset Setup Date</mat-label>
                                <input matInput [matDatepicker]="pickerAdjEstimatedProject" [min]="minAdjEstimatedProjectEndDate"
                                    [max]="minAdjEstimatedProjectEndDate" placeholder="Choose a date" class="disabledColor offscreen"
                                    formControlName="adjustedEstimatedProjectEndMatDate"
                                    (dateChange)="onDateChange($event, 'adjustedEstimatedProjectEnd', pickerAdjEstimatedProject)">
                                <mat-datepicker-toggle matSuffix [for]="pickerAdjEstimatedProject"></mat-datepicker-toggle>
                                <mat-datepicker #pickerAdjEstimatedProject></mat-datepicker>
                                <input matInput placeholder="Choose a date" formControlName="adjustedEstimatedProjectEnd" class="disabledColor"
                                    readonly>
                            </mat-form-field>

                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">Months Overdue</mat-label>
                                <input matInput placeholder="" class="disabledColor" formControlName="monthsOverdue">
                            </mat-form-field>
                        </div>
                    </form>

                    <!-- Item Cash Flow PO Terms -->
                    <span><span style="color: red;">Please note -</span> cash flow is associated with the selection of quarters above. Default values will only be provided if all 4 quarters above have a date value. You may adjust the terms as needed. Use this document for <a href="" (click)="downloadPdf('paymentTerms')" style="color: rgb(26, 161, 218);">Payment Terms</a> to learn more. </span>
                    <form [formGroup]="itemCashFlowForm">
                        <div class="row border-container">
                            <span class="text-over-border">Item Cash Flow PO Terms</span>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">Item Cash Flow Method</mat-label>
                                <mat-select formControlName="itemCashFlowMethod">
                                    <mat-option (click)="showCashFlowMethod(option, 'itemCashFlowMethod')" *ngFor="let option of cashFlowMethodOptions"
                                        [value]="option">{{option}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">Item Check Sum (Validates %)</mat-label>
                                <input matInput placeholder="" class="disabledColor" formControlName="itemCheckSum">
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">Item Cash Flow Terms</mat-label>
                                <input matInput placeholder="" class="disabledColor" formControlName="itemCashFlowTerms">
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">
                                    <span [ngSwitch]="itemCashFlowForm.controls['itemCashFlowMethod'].value">
                                        <span *ngSwitchCase="'Standard Terms (%)'">%</span>
                                        <span *ngSwitchCase="'Straight-Line ($)'">$</span>
                                    </span>
                                    Cash Flow Commit Qtr</mat-label>
                                <input matInput type="number" placeholder="" class="disabledColor" formControlName="itemCashFlowCommit"
                                (input)="onValueChange($event, 'itemCashFlowCommit')">
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">
                                    <span [ngSwitch]="itemCashFlowForm.controls['itemCashFlowMethod'].value">
                                        <span *ngSwitchCase="'Standard Terms (%)'">%</span>
                                        <span *ngSwitchCase="'Straight-Line ($)'">$</span>
                                    </span>
                                    Cash Flow Factory Acceptance Qtr
                                </mat-label>
                                <input matInput type="number" placeholder="" class="disabledColor" formControlName="itemCashFlowFactory"
                                (input)="onValueChange($event, 'itemCashFlowFactory')">
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">
                                    <span [ngSwitch]="itemCashFlowForm.controls['itemCashFlowMethod'].value">
                                        <span *ngSwitchCase="'Standard Terms (%)'">%</span>
                                        <span *ngSwitchCase="'Straight-Line ($)'">$</span>
                                    </span>
                                    Cash Flow Site Acceptance Qtr (RTE)
                                </mat-label>
                                <input matInput type="number" placeholder="" class="disabledColor" formControlName="itemCashFlowSite"
                                (input)="onValueChange($event, 'itemCashFlowSite')">
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">
                                    <span [ngSwitch]="itemCashFlowForm.controls['itemCashFlowMethod'].value">
                                        <span *ngSwitchCase="'Standard Terms (%)'">%</span>
                                        <span *ngSwitchCase="'Straight-Line ($)'">$</span>
                                    </span>
                                    Cash Flow Final Acceptance Qtr (RTO)
                                </mat-label>
                                <input matInput type="number" placeholder="" class="disabledColor" formControlName="itemCashFlowFinal"
                                (input)="onValueChange($event, 'itemCashFlowFinal')">
                            </mat-form-field>
                        </div>
                    </form>

                    <!-- NRE Cash Flow Parameters -->
                    <form [formGroup]="NRECashFlowForm">
                        <div class="row border-container">
                            <span class="text-over-border">NRE Cash Flow Parameters (for billing)</span>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">NRE Cash Flow Method</mat-label>
                                <!-- <input matInput placeholder="" class="disabledColor" formControlName="NRECashFlowMethod"> -->
                                <mat-select formControlName="NRECashFlowMethod">
                                    <mat-option (click)="showCashFlowMethod(option, 'NRECashFlowMethod')" *ngFor="let option of cashFlowMethodOptions"
                                        [value]="option">{{option}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">NRE Check Sum (Validates %)</mat-label>
                                <input matInput placeholder="" class="disabledColor" formControlName="NRECheckSum">
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">NRE Cash Flow Terms</mat-label>
                                <input matInput placeholder="" class="disabledColor" formControlName="NRECashFlowTerms">
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">% NRE Cash Flow Commit Qtr</mat-label>
                                <input matInput  type="number" placeholder="" class="disabledColor" formControlName="NRECashFlowCommit"
                                (input)="onValueChange($event, 'NRECashFlowCommit')">
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">% NRE Cash Flow Factory Acceptance Qtr</mat-label>
                                <input matInput  type="number" placeholder="" class="disabledColor" formControlName="NRECashFlowFactory"
                                (input)="onValueChange($event, 'NRECashFlowFactory')">
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">% NRE Cash Flow Site Acceptance Qtr (RTE)</mat-label>
                                <input matInput type="number" placeholder="" class="disabledColor" formControlName="NRECashFlowSite"
                                (input)="onValueChange($event, 'NRECashFlowSite')">
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">% NRE Cash Flow Final Acceptance Qtr (RTO)</mat-label>
                                <input matInput type="number" placeholder="" class="disabledColor" formControlName="NRECashFlowFinal"
                                (input)="onValueChange($event, 'NRECashFlowFinal')">
                            </mat-form-field>
                        </div>
                    </form>

                    <!-- Hookup - Capitalized Install Costs Cash Flow PO Terms-->
                    <form [formGroup]="hookUpCashFlowForm">
                        <div class="row border-container">
                            <span class="text-over-border">Hookup - Capitalized Install Costs Cash Flow PO Terms</span>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">Hookup Cash Flow Method</mat-label>
                                <!-- <input matInput placeholder="" class="disabledColor" formControlName="hookUpCashFlowMethod"> -->
                                <mat-select formControlName="hookUpCashFlowMethod">
                                    <mat-option (click)="showCashFlowMethod(option, 'hookUpCashFlowMethod')" *ngFor="let option of cashFlowMethodOptions"
                                        [value]="option">{{option}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">Hookup Check Sum (Validates %)</mat-label>
                                <input matInput placeholder="" class="disabledColor" formControlName="hookUpCheckSum">
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">Hookup Cash Flow Terms</mat-label>
                                <input matInput placeholder="" class="disabledColor" formControlName="hookUpCashFlowTerms">
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">% Hookup Cash Flow Commit Qtr</mat-label>
                                <input matInput type="number" placeholder="" class="disabledColor" formControlName="hookUpCashFlowCommit"
                                (input)="onValueChange($event, 'hookUpCashFlowCommit')">
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">% Hookup Cash Flow Factory Acceptance Qtr</mat-label>
                                <input matInput type="number" placeholder="" class="disabledColor" formControlName="hookUpCashFlowFactory"
                                (input)="onValueChange($event, 'hookUpCashFlowFactory')">
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">% Hookup Cash Flow Site Acceptance Qtr (RTE)</mat-label>
                                <input matInput type="number" placeholder="" class="disabledColor" formControlName="hookUpCashFlowSite"
                                (input)="onValueChange($event, 'hookUpCashFlowSite')">
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <mat-label class="label-text">% Hookup Cash Flow Final Acceptance Qtr (RTO)</mat-label>
                                <input matInput type="number" placeholder="" class="disabledColor" formControlName="hookUpCashFlowFinal"
                                (input)="onValueChange($event, 'hookUpCashFlowFinal')">
                            </mat-form-field>
                        </div>
                    </form>                           
                </div>
    
        </mat-expansion-panel>
    <!-- </form> -->

    <!-- WBS Details accordion-->
    <mat-expansion-panel class="panel-spacing" (opened)="onWBSPanelOpen()">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="border-text">WBS Details (Administator Use Only)</div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <wbs-details #wbsDetails (wbsFormDataChange)="onWbsFormDataChange($event)"></wbs-details>       
    </mat-expansion-panel>

    <mat-expansion-panel class="panel-spacing" (opened)="onWBSCashPanelOpen()">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="border-text">WBS Cash Flow Summary</div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <wbs-cashflow-summary #wbsCashFlow (wbsCashFlowDataChange)="onWbsCashFlowDataChange($event)"></wbs-cashflow-summary>       
    </mat-expansion-panel>

    <mat-expansion-panel class="panel-spacing" (opened)="onAssetPanelOpen()">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="border-text">Asset Setup Details</div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <asset-details #assetDetails (assetFormDataChange)="onAssetFormDataChange($event)"></asset-details>       
    </mat-expansion-panel>
</mat-accordion>

<div class="row">
    <div class="col-md-12">
        <button mat-raised-button class="itg-gradient font-size mr-2"   (click)="back()">Back</button>   
        <button mat-raised-button class="itg-gradient font-size mr-2" (click)="onSave()">Save Draft</button>
        <button mat-raised-button class="itg-gradient font-size" (click)="onSave()">Submit</button>
    </div>
</div>
