import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'stakeholder-assessment',
  templateUrl: './stakeholder-assessment.component.html',
  styleUrls: ['./stakeholder-assessment.component.scss']
})
export class StakeholderAssessmentComponent implements OnInit {
  z:any;
  stakeHolderName!: any[];
  tabSelected: any;
  tabData:any;
  tabSelected1: any;
  tabSelected2: any;
  fieldData: any;
  detailsForm!: FormGroup;
  labFabDetails!: FormGroup;
  // capDetails: FormGroup;
  PORData: any;
  bstrData: any;
  matrixData: any[] = [];
  labFabMatrixData: any;
  matrixHeaders: any;
  indexArray: any[] = [];
  stakeHolderNamePath!: string;
  statusChange!: string;
  SHArray: {key: string, value: string, actionPlan: string, showSHActionPlan: boolean, showFocusArea: boolean}[] = [];
  SHArray_Count=0
  procurementFlag:boolean=true;
  buildingLabFab!: FormGroup;
  dbFAName!: string;
  sendFormVar! :any;
  loading!: boolean;
  tableloading!: boolean
  questionArray1!: string[];
  answerArray: any;
  statusUpdate: any;
  stakeHolderData: any;
  focusAreaName: any;
  abbreviatedSHName!: { key: string; value: string; }[];
  sendStatusUpdate = {}
  capDetailsData: any;
  // fetchFAStatusData: { por_number: number; };
  viewAttachedFiles: any;
  showProgressBar!: boolean;
  LabfabTeams: any;
  FA_Available: any = [];
  FAOriginalName: any;
  assessmentData: any;
  combinedArray!: Array<string>
  loggedInUser!: string;
  hideOriginalStatus!: boolean;
  hideOriginalFAStatus: boolean = false;
  FAStatus: any;
  originalStatusVal: any;
  showRequester!: boolean;
  editMode!: boolean;
  FAArray!: Array<string>
  allStakeholders!: Set<string>;
  showActionPlanTab: boolean = false;
  LabFabRenaming!: { labfabFA: string; dbName: string;}[];
  showWaiveLink: boolean = false;
  userResponse: any;
  commonInfo: any;
  
  constructor(private fb: FormBuilder, private common: CommonService, 
    private router: Router, private httpClient: HttpClient, private activatedRoute: ActivatedRoute) {
      this.common.bannerData.next({ title: 'StakeHolder Details'});
     }

  async ngOnInit(): Promise<void> {
    this.common.loader.next(true);
    this.z=2;
    this.tabSelected1 = 'Tool Information';
    this.tabSelected2 = 'POR Information';
    this.statusChange = 'Not Available'
    this.FAOriginalName = this.activatedRoute.snapshot.queryParams['FA'];
    this.loggedInUser = atob(this.common.currentUser);
    this.stakeHolderName = [
      'Asset Administration', 'Automated Equipment Control (AEC)', 'Cybersecurity', 'Environmental Health and Safety', 'Electrical', 
      'Ergonomics', 'FAB Environmental', 'Information Technology(IT)', 'Mechanical and HVAC', 
      'Micro-contamination and ESD', 'Operations', 'Process Systems', 'Procurement', 'SCADA Instramental Control', 'Site EHS',
      'Space Layout (Non-office)','Procurement'
    ]

    this.SHArray = [
      {key: 'Asset Administration', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Automated Equipment Control', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Cybersecurity', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Environmental Health and Safety', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Electrical', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Ergonomics', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'FAB Environmental', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Information Technology', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Mechanical and HVAC', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Microcontamination and ESD', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Operations', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Process Systems', value: 'Not Available', actionPlan: '',showSHActionPlan: false, showFocusArea: false},
      {key: 'SCADA Instramental Control', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Site EHS', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Space Layout Non-office', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false},
      {key: 'Procurement', value: 'Not Available', actionPlan: '', showSHActionPlan: false, showFocusArea: false}
      // ,{key:'Sample_Question_Fields', value: 'Not Available'}
    ]

    this.labFabDetails = this.fb.group({
      LabName: [''],
      Building: [''],
      FocusArea: [''],
      FAPrimary: [''],
      FASecondary: [''],
      FAWatcher: [''],
      por_number: ['']
    });

    this.common.passData.subscribe({
      next: (data) => {
        console.log('Function Data', data)
        this.capDetailsData = data;
        this.capDetailsData['PORStatus'] = 'Active'
        console.log("inside passData", this.capDetailsData)
        this.formData(this.capDetailsData)
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    })

    this.sendStatusUpdate = {
      POR: this.capDetailsData['PORNumber'] == undefined ? this.activatedRoute.snapshot.queryParams['POR'] : this.capDetailsData['PORNumber'],
      PORStatus: this.capDetailsData['PORStatus']  
    }

    this.sendFormVar = {
      labName: localStorage.getItem('InitiateLabFab'),
      focusArea: this.FAOriginalName,
      POR: this.activatedRoute.snapshot.queryParams['POR']
    }
    
     //To fetch Focus Area name 
    this.common.focusAreaStatus.subscribe({
      next: (data) => {
        if (data && data.focusAreaName) {
        this.focusAreaName = data['focusAreaName']
        }
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });

    //To update POR status in db
    await this.common.postCapAPI('itg/cap-update-por-status-itg',this.sendStatusUpdate).subscribe({
      next: async ele => {
        this.capDetailsData = ele['body'];
        console.log("inside updateStatus", this.capDetailsData)
        this.labFabDetails.value['LabName'] = this.capDetailsData['InitiateLabFab']
        this.labFabDetails.value['Building'] = this.capDetailsData['InitiateBuilding']
        localStorage.setItem('InitiateLabFab', this.capDetailsData['InitiateLabFab']);
        localStorage.setItem('InitiateBuilding', this.capDetailsData['InitiateBuilding']);
        if (this.capDetailsData['RequesterName'] == this.loggedInUser || this.capDetailsData['SecondaryReq'] == this.loggedInUser) {
          this.showRequester = true
        }
        else {
          this.showRequester = false
        }
        await this.formData(this.capDetailsData);
        console.log("Print ele", ele)
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });

    //To fetch FA Status
    this.tableloading = true
    await this.common.postCapAPI('itg/cap-fetch-fa-status-itg',this.sendStatusUpdate).subscribe({
      next: async ele1 => {
        this.FAStatus = ele1['body']
        console.log("Print ele", ele1)

        //this.callFAStatus(FAStatus);
        for (let i = 0; i < this.FAStatus.length; i++) {
          this.focusAreaName = this.FAStatus[i]['Focus_Area']
          this.showActionPlanTab = this.FAStatus.some((ele: { [x: string]: string; }) => {
            return ele['actionPlan_Status'] == 'Action Plan'
          })
          //SHArray map focusAreaName & status to show in UI
          this.SHArray.forEach(val => {
            if (val['key'] == this.focusAreaName) {
              if (this.FAStatus[i]['Status'] != 'Action Plan') {
                val['value'] = this.FAStatus[i]['Status'];
              }
              val['actionPlan'] = this.FAStatus[i]['actionPlan_Status']
              let valueToCompare = this.FAStatus[i]['Status']
              //after submitting assessment form, mapped SH will see stats as SH Review
              if (valueToCompare == 'SH Review' || valueToCompare == 'Waive' || valueToCompare == 'Action Plan' || valueToCompare == 'SH Review - Draft' || valueToCompare == 'SH Review - Complete') {
                this.FA_Available.push(this.focusAreaName)
                // this.checkSHReview();
              }
            }
          });


        }
        await this.checkSHReview();
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  
    this.common.documentData.subscribe({
      next: (data) => {
        //this.statusVal = data.status;
        this.PORData = data.capItemVal;
      },
      error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    })

  } // init ENDS


  formData(data:any){
    this.fieldData = data
    if (data['capUpload']) {
      //this.viewAttachedFiles = typeof(data['capUpload']) == 'string' ? JSON.parse(data['capUpload']) : [data['capUpload']]
       const parsedFiles: FileAttachment[] = JSON.parse(data['capUpload']);
        this.viewAttachedFiles = parsedFiles?.reduce<FileAttachment[]>((unique, o) => {
            if (!unique.some(obj => obj['Name'] === o['Name'])) {
                unique.push(o);
        }
        return unique;
      }, []);
      console.log(this.viewAttachedFiles)
    }
    this.detailsForm = this.fb.group({
      Tool_Name: [{ value: this.fieldData['ToolName'], disabled: true }],
      Tool_Description: [{ value: this.fieldData['ToolDescription'], disabled: true }],
      Tool_Intent: [{ value: this.fieldData['ToolIntent'], disabled: true }],
      Purpose: [{ value: this.fieldData['Purpose'], disabled: true }],
      Tool_History: [{ value: this.fieldData['ToolHistory'], disabled: true }],
      Tool_Condition: [{ value: this.fieldData['ToolCondition'], disabled: true }],
      Build_Option: [{ value: this.fieldData['BuildOption'], disabled: true }],
      Initiate_Site: [{ value: this.fieldData['InitiateSite'], disabled: true }],
      Initiate_Building: [{ value: this.fieldData['InitiateBuilding'], disabled: true }],
      Initiate_LabFab: [{ value: this.fieldData['InitiateLabFab'], disabled: true }],
      PORNumber: [{ value: this.fieldData['PORNumber'], disabled: true }],
      POR_Status: [{ value: this.fieldData['PORStatus'], disabled: true }],
      Proj_Manager: [{ value: this.fieldData['ProjectManager'], disabled: true }],
      Capital_Planner: [{ value: this.fieldData['Planner'], disabled: true }],
      Description: [{ value: this.fieldData['Description'], disabled: true }],
      POR_Driver: [{ value: this.fieldData['PORDriver'], disabled: true }],
      POR_Commit_Manager: [{ value: this.fieldData['CommitManager'], disabled: true }],
      Commit_Location: [{ value: this.fieldData[''], disabled: true }],
      cost_object: [{ value: this.fieldData[''], disabled: true }],
      MRU: [{ value: this.fieldData[''], disabled: true }],
      level2: [{ value: this.fieldData['Level2'], disabled: true }],
      level3: [{ value: this.fieldData['Level3'], disabled: true }],
      business_unit: [{ value: this.fieldData[''], disabled: true }],
      organization: [{ value: this.fieldData[''], disabled: true }],
      section: [{ value: this.fieldData[''], disabled: true }],
      rollup: [{ value: this.fieldData[''], disabled: true }],
      commit: [{ value: '', disabled: true }],
      RTE: [{ value: '', disabled: true }],
      RTO: [{ value: '', disabled: true }],
      setupDueDate: [{ value: '', disabled: true }],
      actualCommit: [{ value: '', disabled: true }],
      itemCost: [{ value: '', disabled: true }],
      hookUpCost: [{ value: '', disabled: true }],
      NRE_cost: [{ value: '', disabled: true }],
      totalCost: [{ value: '', disabled: true }],
      WBS: [{ value: this.fieldData['WBS#'], disabled: true }],
      WBS_status: [{ value: '', disabled: true }],
      WBS_amount: [{ value: '', disabled: true }],
      capUpload: [{ value: [''], disabled: true }],
      pageName: [{ value: 'focusArea', disabled: true }],
      Requester_Email: [{ value: this.fieldData['RequesterName'], disabled: true }],
      Secondary_Requester: [{ value: this.fieldData['SecondaryReq'], disabled: true }]
    });    
  }

  async checkSHReview(): Promise<void>{
    let SHNameData:any = [];
    let SHNameArray:any = [];
    let combinedSHData:any = [];
    this.tableloading = true
    //this.allStakeholders = []
    this.hideOriginalFAStatus = false
      this.labFabDetails.value['LabName'] = this.capDetailsData['InitiateLabFab']
      this.labFabDetails.value['Building'] = this.capDetailsData['InitiateBuilding']
      this.labFabDetails.value['FocusArea'] = this.focusAreaName;
      let labfabname = this.capDetailsData['InitiateLabFab']
      let building = this.capDetailsData['InitiateBuilding']
      console.log("Check labfabName:", labfabname)
      console.log("CapDetailsData", this.capDetailsData)

    this.common.loader.next(true);
    this.common.getById('amd-get-labfabbyid/', labfabname?.replace('/', '&#47;')).subscribe({
                  next: (data) => {
              console.log("LABFAB data", data);
              if (data) {
                this.tableloading = false;
                  this.LabfabTeams = data[0];
                  // ENDABLE the Focus AREA
                  this.FocusAreaVisibility();
                  if (this.LabfabTeams['BUILDING'] == building) {
                      let arr = ['Primary', 'Secondary'];
                      for (const focusArea of this.FA_Available) {
                          this.focusAreaName = focusArea;
                          console.log("After API", this.focusAreaName);

                          // this.LabFabRenaming.filter(obj=> {
                          //   if(obj['labfabFA'] ==  this.focusAreaName){
                          //     this.focusAreaName = obj['dbName']
                          //   }
                          // })
                          let SHNameData = this.LabfabTeams[this.focusAreaName.replace(/ /g, '_')];

                          // Save stakeholderName in localstorage
                          localStorage.setItem('StakeHolderName', SHNameData);

                          const SHNameArray = arr.map((item) => SHNameData[item]?.split(';')).filter(Boolean);

                          this.combinedArray = [].concat(...SHNameArray);
                          combinedSHData = combinedSHData.concat(...SHNameArray);


                          //below variable will hide original status as logged in user doesn't match with SH
                          this.hideOriginalFAStatus = this.combinedArray.every(
                              (ele1) => ele1.trim() !== this.loggedInUser);

                          const foundItem = this.SHArray.find((val) => val['key'] === this.focusAreaName);
                          // if (foundItem && foundItem['value'] != 'Pending') {
                          if (foundItem && (!(foundItem['value'] == 'Pending' || foundItem['value'] == 'Waive'))) {
                              if (this.hideOriginalFAStatus) {
                                  foundItem['value'] = "Available";
                                  foundItem['showSHActionPlan'] = false;
                                  console.log("Print status foundItem", foundItem);
                                  // this.FAArray = [].push(foundItem)
                                  // this.editMode = false ;
                              } else {
                                  const originalStatusVal = this.FAStatus.find((ele: { [x: string]: any; }) => ele['Focus_Area'] === this.focusAreaName);
                                  foundItem['value'] = originalStatusVal?.['Status'] || '';
                                  foundItem['showSHActionPlan'] = true;
                                  console.log("Print status foundItem", foundItem);
                                  //this.editMode = true ;           
                              }
                          }
                          else {
                            if(foundItem){
                                if (!this.hideOriginalFAStatus && foundItem['value'] == 'Waive') {
                                    foundItem['value'] = 'Waive';
                                }
                                else {
                                    foundItem['value'] = 'Waived';
                                }
                            }
                              
                          }
                      }
                  }
                  // // ENDABLE the Focus AREA
                  // this.FocusAreaVisibility()
                  this.allStakeholders = new Set(combinedSHData);

                  //If editMode is true, loggedinuser is Stakeholder & can access any Stakeholder form
                  this.editMode = Array.from(this.allStakeholders).some((ele) => {
                      return ele.trim() == this.loggedInUser;
                  });
                  this.common.loader.next(false)
              }
              else {
                this.tableloading = false;
                this.common.loader.next(false)
              }
          },
          error: (error) => {
              console.error("Error occurred:", error);
              alert("Please connect with Asset Admin");
          }
      });
    console.log('data to sets', this.labFabDetails.value)
  }
 
  
  tabChange1(tabName: { tab: { textLabel: any; }; }){
    console.log("Print-------------", tabName)
    this.tabSelected1 = tabName.tab.textLabel;
    this.common.changeTab1(this.tabSelected1);
  }

  tabChange2(tabName: { tab: { textLabel: any; }; }){
    console.log("Print-------------", tabName)
    this.tabSelected2 = tabName.tab.textLabel;
    this.common.changeTab2(this.tabSelected2);
  }

  statusClickReview(focusAreaName: any, statusValue: any){
    let checkClickedAction = statusValue;
    let originalSHName = focusAreaName['key']
    let currentFAStatus = focusAreaName['value']
    let url ;
    localStorage.setItem('PorStatus', this.capDetailsData['PORStatus'])
    localStorage.setItem('focusAreaName', focusAreaName)
    
    if(checkClickedAction == 'Review' ){
      url = 'cap/stakeHolderReview'+'?'+'POR='+this.capDetailsData['PORNumber']+'&'+'FA='+originalSHName;
    }
    if(checkClickedAction == 'Requester ActionPlan'){
      let action = (this.showRequester == true) ? 'edit' : 'view';
      url = 'cap/capActionPlan'+'?'+'POR='+this.capDetailsData['PORNumber']+'&'+'FA='+originalSHName+'&'+'mode='+action+'&'+'actionPlan='+'Requester';
    }

    if(checkClickedAction == 'Stakeholder ActionPlan'){
      let SHAction = (this.editMode == true ) ? 'edit' : 'view';
      url = 'cap/capActionPlan'+'?'+'POR='+this.capDetailsData['PORNumber']+'&'+'FA='+originalSHName+'&'+'mode='+SHAction+'&'+'actionPlan='+'Stakeholder';
    }
    window.open(url, "_blank");
  }

  actionPlanClick(statusValue: string){
    let checkClickedAction = statusValue;
    let url ;
    localStorage.setItem('PorStatus', this.capDetailsData['PORStatus'])

    if(checkClickedAction == 'Requester ActionPlan'){
      let action = (this.showRequester == true) ? 'edit' : 'view';
      url = 'cap/capActionPlan'+'?'+'POR='+this.capDetailsData['PORNumber']+'&'+'mode='+action+'&'+'actionPlan='+'Requester';
    }

    if(checkClickedAction == 'Stakeholder ActionPlan'){
      let SHAction = (this.editMode == true ) ? 'edit' : 'view';
      url = 'cap/capActionPlan'+'?'+'POR='+this.capDetailsData['PORNumber']+'&'+'mode='+SHAction+'&'+'actionPlan='+'Stakeholder';
    }
    window.open(url, "_blank");
  }

  async fileReader() {
    return await new Promise(() => {
      this.common.getLabFabMatrix().subscribe({
        next: (data) => {
          let list = data.split('\r\n');
          this.matrixHeaders = list[0].split(',');
          list.forEach(e => {
            this.matrixData.push(e.split(','));
          });
          for (let i = 1; i < this.matrixData.length; i++) {
            if (this.matrixData[i][1] == this.capDetailsData['InitiateBuilding'] &&
              this.matrixData[i][3] == this.capDetailsData['InitiateLabFab']) {
              this.labFabMatrixData = this.matrixData[i];
            }
          }
          console.log('Headers', this.matrixHeaders)
          console.log("Print matrix", this.matrixData)
        },
        error: (error) => {
          console.error("Error occurred:", error);
          alert("Please connect with Asset Admin");
        }
      });
    })
  }

  emitUpdatedTableData(updatedTableData: { [x: string]: any; }){
    this.common.bannerData.next({ title: 'StakeHolder Details'});
    this.userResponse = updatedTableData['userResponse']
    this.commonInfo = updatedTableData['commonInfo']
    this.capDetailsData = updatedTableData['commonInfo']
    console.log("Print table Data updated", updatedTableData);
  }

  async focusArea(stakeHolderName: string){
    if(stakeHolderName == 'Automated Equipment Control (AEC)'){
       stakeHolderName = 'Automated Equipment Control'
    }
    this.stakeHolderFunc(stakeHolderName);
  }

  stakeHolderFunc(SHName: string){
    console.log('TestCAP6');
    let originalSHName = SHName
    if(SHName == 'Automated Equipment Control'){
      SHName = 'AEC'
    }
    if(SHName == 'Asset Administration'){
      SHName = 'AA'
    }
    if(SHName == 'EHS Equipment Design'){
      SHName = 'EHS'
    }
    if(SHName == 'FAB Environmental'){
      SHName = 'fabEnvironmental'
    }
    if(SHName == 'Information Technology'){
      SHName = 'IT'
    }
    if(SHName == 'Mechanical and HVAC'){
      SHName = 'Mechanical'
    }
    if(SHName == 'Micro-contamination and ESD'){
      SHName = 'microContamination'
    }
    if(SHName == 'Process Systems'){
      SHName = 'Process Systems'
    }
    if(SHName == 'instramental'){
      SHName = 'SCADA Instramental Control'
    }
    if(SHName == 'Site EHS'){
      SHName = 'siteEHS'
    }
    if(SHName == 'Space Layout (Non-office)'){
      SHName = 'spaceLayout'
    }
    localStorage.setItem('POR', this.activatedRoute.snapshot.queryParams['POR'])
    localStorage.setItem('focusAreaName', SHName)
    let url = 'cap/assessmentForm'+'?'+'POR='+this.capDetailsData['PORNumber']+'&'+'FA='+originalSHName;
    window.open(url, "_blank");
  }

  // add the child files 
  addChildForm(FileData: string, group: FormGroup) {
    console.log("group", group)
    this.detailsForm.addControl(FileData, group);
    const groupWithName = group as { Name?: string }; 

    if (groupWithName.Name && groupWithName.Name !== "") {
      this.viewAttachedFiles.push(group)
      console.log("array check", this.viewAttachedFiles, Array.isArray(this.viewAttachedFiles))
      this.viewAttachedFiles = this.viewAttachedFiles?.reduce((unique: any[], o: { Name: any; }) => {
        if (!unique.some(obj => obj?.Name === o?.Name)) {
          unique.push(o);
        }
        return unique;
      }, []);
      console.log("this.viewAttachedFiles", Array.isArray(this.viewAttachedFiles), this.viewAttachedFiles)
      this.detailsForm.controls['capUpload'].setValue(this.viewAttachedFiles)

      if (this.detailsForm.controls['capUpload'].value && Array.isArray(this.detailsForm.controls['capUpload'].value)) {
        this.detailsForm.value['capUpload'] = JSON.stringify(this.detailsForm.controls['capUpload'].value)
      }
      this.common.postCapAPI('itg/cap-add-initiate-data-itg',this.detailsForm.value).subscribe({
        next: (data) => {     
        this.capDetailsData= data;
        console.log("inside addInitiateData", this.capDetailsData)
      },
       error: (error) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
      });
    }
   
    //when file uploaded successfully in S3
    // if (FileData == 'capUpload' && group['IsFileUploaded'] == true) {
    //   // alert('test upload')
    // }
  }

  goBack(){
    console.log('Back');
    this.common.changeMessage(this.capDetailsData);
    this.router.navigate(['cap/capDetail'], {queryParams: { POR: this.capDetailsData['PORNumber']}});
  }

//To show or hide the specific / ALL focus AREA based on labfab primary or secondary
  FocusAreaVisibility() {
    if (this.SHArray) {
      console.log(this.LabfabTeams)
      this.SHArray.forEach((val, index) => {
        if (this.LabfabTeams) {
          for (let key in this.LabfabTeams) {
            let FA = key?.split('_').join(' ');
            if (FA.includes(val['key'])) {
              if ((this.LabfabTeams[key]?.Primary && this.LabfabTeams[key]?.Primary!='Null') || (this.LabfabTeams[key]?.Secondary && this.LabfabTeams[key]?.Secondary!='Null')) {
                  if(val['key']){
                    this.SHArray[index]['showFocusArea'] = true
                    this.SHArray_Count+=1
                    }
              } 
              else {
                console.log("NOOO FA", FA)
              }
            }
          }
        }
      });
      
      let ProcurementEnabled = this.check_FocusAreaStatus()
      if(ProcurementEnabled == 'showPending'){
        this.SHArray.filter(ele=>{
         ele.value = ele.key == "Procurement" ? 'Pending' : ele.value
         if(ele.value == "Pending"){
          ele.actionPlan = ''
         }
        })
      }
      else if(ProcurementEnabled == 'showOriginalVal'){
       return
      }
      else{
        this.SHArray.filter(ele=>{
          return
          // ele.value = ele.key == "Procurement" ? (ele.value != 'SH Review - Complete' ? 'Pending' : ele.value) : ele.value
       })
      }

     
    }
    console.log("this.SHArray_Count",this.SHArray_Count)

  }
  
  check_FocusAreaStatus(){
    const existingFA = new Set(this.FAStatus.map((ele: { Focus_Area: any; }) => ele.Focus_Area));
    for (const item of this.SHArray) {
      // Check if the value property is true and the key is not already in dataarray
      if (item.showFocusArea === true && !existingFA.has(item.key)) {
        // Create a new object and add it to dataarray with the key from abc
        this.FAStatus.push({ Focus_Area: item.key, Status: item.value, actionPlan_Status: item.actionPlan });
        existingFA.add(item.key); // Update the set of existing keys
      }
    }
    
    let completeReview = 0
    let checkProcurement = this.FAStatus.some((obj: { Focus_Area: string; }) => obj.Focus_Area == 'Procurement');
    if (checkProcurement) {
      for (const item of this.FAStatus) {
        if (item.Status == 'SH Review - Complete') {
          completeReview++
        }
        else if((item.Focus_Area == "Procurement" && (item.Status.startsWith('SH Review'))) && completeReview == (this.FAStatus.length - 1)){
          return 'showPending'
        }
        else{
          return
        }
      }
      
    }
    else{
      return 'showOriginalVal'
    }
    return ; 
}
}

type FileAttachment = {
    [key: string]: any; // Allow any properties
};



