import { Component } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { CommonService } from './services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'startup';
  checkTokenSubscription!: Subscription;

  constructor(private common: CommonService) { }

  ngOnInit() {
    this.checkTokenSubscription = interval(60000) // Check every minute
      .subscribe(() => this.checkTokenExpiration());
  }

  ngOnDestroy() {
    this.checkTokenSubscription.unsubscribe(); // Clean up subscription
  }

  checkTokenExpiration() {
    const token = environment.token; // Get the token from your AuthService
    const expiryDate = new Date(0); // The epoch time
    const expTime = JSON.parse(atob(token.split('.')[1])).exp;
    expiryDate.setUTCSeconds(expTime);

    if (expiryDate < new Date()) {
      localStorage.setItem('tokenValidity', 'expired');
      this.common.logout(); // If the token is expired, log out the user
    }
  }
}
