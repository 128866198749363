import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-labfab-mismatch',
  templateUrl: './labfab-mismatch.component.html',
  styleUrls: ['./labfab-mismatch.component.scss']
})

export class LabfabMismatchComponent implements OnInit {

  buildings: any
  loading = true;
  loaded: any
  amddata: any
  labdata: any
  fileName = 'Labfab-Mismatch.xlsx';
  subscriptions: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    private router: Router) { this.common.bannerData.next({ title: 'Report', subtitle: 'Labfab Mismatch Report Summary' }); }

  ngOnInit() {
    this.common.loader.next(true);
    this.subscriptions.push(
      this.common.getAPI('amd-report-fa-labfab-mismatch/').subscribe((data: any) => {
        this.buildings = data['body'];
        this.amddata = data['amd'];
        this.labdata = data['lab']
        this.common.loader.next(false);
      })
    );
  }

  Download() {
    /* table id is passed over here */
    let element = document.getElementById('dtHorizontalExample');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
