import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, private common: CommonService
  ) { }
  loggedOutMsg = '';
  imgPath = this.common.imgPath;
  ngOnInit() {
    this.checkLogin();
    this.common.loggedOutMsg.subscribe(res => {
      const msg = sessionStorage.getItem('logout');
      this.loggedOutMsg = msg ? msg : res;
    });
  }
  async checkLogin() {
    const val = await this.common.checkLogin();
    // const returnUrl = localStorage.getItem('returnUrl');
    if (val) {
      // this.router.navigateByUrl(returnUrl ? returnUrl : 'home');
      this.router.navigateByUrl('home');
    }
    else {
      localStorage.clear();
    }
  }

  login() {
    sessionStorage.removeItem('logout');
    this.common.login();
  }
}
