<mat-toolbar color="primary" class="mb-3 custom-text-color">
  <mat-icon>laptop</mat-icon>
  <span>{{data.title}}</span>
  <button mat-icon-button class="ml-auto" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</mat-toolbar>

<div class="col-12 my-3">
  <mat-form-field class="w-100">
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
  </mat-form-field>
</div>

<div class="mat-elevation-z8">
  <div class="table-responsive">
    <table class="table" mat-table [dataSource]="dataSource" matSort>

      <!-- Requester_ID-->
      <ng-container matColumnDef="Requester_ID" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Requester ID</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Requester_ID}} </mat-cell>
      </ng-container>

      <!-- Asset Active Listing -->
      <ng-container matColumnDef="Asset_Active_Listing">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Asset Active Listing </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Asset_Active_Listing}} </mat-cell>
      </ng-container>

      <!--  Company Code -->
      <ng-container matColumnDef="Company_Code">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Company Code </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Company_Code}} </mat-cell>
      </ng-container>

      <!-- Asset Number -->
      <!-- <ng-container matColumnDef="Asset_Number">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Asset Number </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Asset_Number}} </mat-cell>
        </ng-container> -->

      <!-- Original Asset Number -->
      <ng-container matColumnDef="Original_Asset_Number">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Original Asset Number </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Original_Asset_Number}} </mat-cell>
      </ng-container>

      <!-- Asset Class Code -->
      <ng-container matColumnDef="Asset_Class_Code">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Asset Class Code </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Asset_Class_Code}} </mat-cell>
      </ng-container>

      <!-- Asset_Description -->
      <ng-container matColumnDef="Asset_Description">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Asset Description </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Asset_Description}} </mat-cell>
      </ng-container>

      <!-- Owner -->
      <ng-container matColumnDef="Owner">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Owner</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Owner}} </mat-cell>
      </ng-container>

      <!-- Serial_Number -->
      <ng-container matColumnDef="Serial_Number">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Serial Number </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Serial_Number}} </mat-cell>
      </ng-container>

      <!-- LabFab_Name -->
      <ng-container matColumnDef="LabFab_Name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> LabFab Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.LabFab_Name}} </mat-cell>
      </ng-container>

      <!-- Building -->
      <ng-container matColumnDef="Building">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Building </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Building}} </mat-cell>
      </ng-container>

      <!-- Ship_to_Customer_Name1 -->
      <ng-container matColumnDef="Ship_to_Customer_Name1">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Ship to Customer Name1</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Ship_to_Customer_Name1}} </mat-cell>
      </ng-container>

      <!-- Asset_Capitalization_Date -->
      <ng-container matColumnDef="Asset_Capitalization_Date">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Asset Class Code </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Asset_Capitalization_Date}} </mat-cell>
      </ng-container>

      <!-- Cost_Center_Code -->
      <ng-container matColumnDef="Cost_Center_Code">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Cost Center Code </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Cost_Center_Code}} </mat-cell>
      </ng-container>

      <!-- Cost_Object_Manager -->
      <ng-container matColumnDef="Cost_Object_Manager">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Cost Object Manager </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Cost_Object_Manager}} </mat-cell>
      </ng-container>

      <!-- MRU_Code -->
      <ng-container matColumnDef="MRU_Code">
        <mat-header-cell *matHeaderCellDef mat-sort-header> MRU Code </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.MRU_Code}} </mat-cell>
      </ng-container>

      <!-- Profit_Center_Code -->
      <ng-container matColumnDef="Profit_Center_Code">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Profit Center Code </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Profit_Center_Code}} </mat-cell>
      </ng-container>

      <!-- Cost_Center_Hier_Desc_Level1 -->
      <ng-container matColumnDef="Cost_Center_Hier_Desc_Level1">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Cost Center Hier Desc Level1 </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Cost_Center_Hier_Desc_Level1}} </mat-cell>
      </ng-container>

      <!-- Asset_Super_Number -->
      <ng-container matColumnDef="Asset_Super_Number">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Asset Super Number </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Asset_Super_Number}} </mat-cell>
      </ng-container>

      <!-- Depreciation_Terms_Start_Date -->
      <ng-container matColumnDef="Depreciation_Terms_Start_Date">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Depreciation Terms Start Date </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Depreciation_Terms_Start_Date}} </mat-cell>
      </ng-container>

      <!-- Depreciation_Terms_Useful_Life_in_Months -->
      <ng-container matColumnDef="Depreciation_Terms_Useful_Life_in_Months">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Depreciation Terms Useful Life in Months </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Depreciation_Terms_Useful_Life_in_Months}} </mat-cell>
      </ng-container>

      <!-- Acquisition_Value -->
      <ng-container matColumnDef="Acquisition_Value">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Acquisition Value </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Acquisition_Value}} </mat-cell>
      </ng-container>

      <!-- PE_Net_Book_Value -->
      <ng-container matColumnDef="PE_Net_Book_Value">
        <mat-header-cell *matHeaderCellDef mat-sort-header> PE Net Book Value </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.PE_Net_Book_Value}} </mat-cell>
      </ng-container>

      <!-- PE_Depreciation_For_Posted_Period -->
      <ng-container matColumnDef="PE_Depreciation_For_Posted_Period">
        <mat-header-cell *matHeaderCellDef mat-sort-header> PE Depreciation For Posted Period </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.PE_Depreciation_For_Posted_Period}} </mat-cell>
      </ng-container>

      <!-- Period_Posted_Ordinary_Depreciation_Including_Value_Adjustments -->
      <ng-container matColumnDef="Period_Posted_Ordinary_Depreciation_Including_Value_Adjustments">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Period Posted Ordinary Depreciation Including Value
          Adjustments </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Period_Posted_Ordinary_Depreciation_Including_Value_Adjustments}}
        </mat-cell>
      </ng-container>

      <!-- WBS_Element_Maintenance_Project -->
      <ng-container matColumnDef="WBS_Element_Maintenance_Project">
        <mat-header-cell *matHeaderCellDef mat-sort-header> WBS Element Maintenance Project </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.WBS_Element_Maintenance_Project}} </mat-cell>
      </ng-container>

      <!-- Action_Plan_Status -->
      <ng-container matColumnDef="Action_Plan_Status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Action Plan Status </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Action_Plan_Status}} </mat-cell>
      </ng-container>

      <!-- Is_Cost_Associated_To_Asset -->
      <ng-container matColumnDef="Is_Cost_Associated_To_Asset">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Is Cost Associated To Asset </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Is_Cost_Associated_To_Asset}} </mat-cell>
      </ng-container>

      <!-- Estimated_Cost -->
      <ng-container matColumnDef="Estimated_Cost">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Estimated Cost </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Estimated_Cost}} </mat-cell>
      </ng-container>

      <!-- PickUp_Cost_to_move_asset_and_demo_utilities -->
      <ng-container matColumnDef="PickUp_Cost_to_move_asset_and_demo_utilities">
        <mat-header-cell *matHeaderCellDef mat-sort-header> PickUp Cost to move asset and demo utilities
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.PickUp_Cost_to_move_asset_and_demo_utilities}} </mat-cell>
      </ng-container>

      <!-- Demo_utilities_of_asset_Status -->
      <ng-container matColumnDef="Demo_utilities_of_asset_Status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Demo utilities of asset Status </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Demo_utilities_of_asset_Status}} </mat-cell>
      </ng-container>

      <!-- planned_Completion_Date_Action_Plan -->
      <ng-container matColumnDef="planned_Completion_Date_Action_Plan">
        <mat-header-cell *matHeaderCellDef mat-sort-header> planned Completion Date Action Plan</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.planned_Completion_Date_Action_Plan}} </mat-cell>
      </ng-container>

      <!-- Is_receiving_area_Prepped -->
      <ng-container matColumnDef="Is_receiving_area_Prepped">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Is receiving area Prepped </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Is_receiving_area_Prepped}} </mat-cell>
      </ng-container>

      <!-- receiving_area_Prepped_Status -->
      <ng-container matColumnDef="receiving_area_Prepped_Status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> receiving area Prepped Status </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.receiving_area_Prepped_Status}} </mat-cell>
      </ng-container>

      <!-- Is_Utilities_Need_Demo_in_Asset_Moved_From -->
      <ng-container matColumnDef="Is_Utilities_Need_Demo_in_Asset_Moved_From">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Is Utilities Need Demo in Asset Moved From
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Is_Utilities_Need_Demo_in_Asset_Moved_From}} </mat-cell>
      </ng-container>

      <!-- Asset_Moved_Status_in_Action_plan -->
      <ng-container matColumnDef="Asset_Moved_Status_in_Action_plan">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Asset Moved Status in Action plan </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Asset_Moved_Status_in_Action_plan}} </mat-cell>
      </ng-container>

      <!-- Action_Plan_Email_Approvers_List -->
      <ng-container matColumnDef="Action_Plan_Email_Approvers_List">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Action Plan Email Approvers List </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Action_Plan_Email_Approvers_List}} </mat-cell>
      </ng-container>

      <!-- Notifications_to_listed_tool_owner_Action_Plan -->
      <ng-container matColumnDef="Notifications_to_listed_tool_owner_Action_Plan">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Notifications to listed tool owner Action Plan
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Notifications_to_listed_tool_owner_Action_Plan}} </mat-cell>
      </ng-container>

      <!-- Approved_request_Id_Action_Plan -->
      <ng-container matColumnDef="Approved_request_Id_Action_Plan">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Approved request Id Action Plan </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Approved_request_Id_Action_Plan}} </mat-cell>
      </ng-container>

      <!-- Email_To_EHS_StakeHolders_standup_permit_or_the_green_sticker -->
      <ng-container matColumnDef="Email_To_EHS_StakeHolders_standup_permit_or_the_green_sticker">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Email To EHS StakeHolders standup permit or the green
          sticker
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Email_To_EHS_StakeHolders_standup_permit_or_the_green_sticker}}
        </mat-cell>
      </ng-container>

      <!-- INSERT_DATETIME -->
      <ng-container matColumnDef="INSERT_DATETIME">
        <mat-header-cell *matHeaderCellDef mat-sort-header> INSERT DATETIME </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.INSERT_DATETIME}} </mat-cell>
      </ng-container>

      <!-- MODIFIED_DATETIME -->
      <ng-container matColumnDef="MODIFIED_DATETIME">
        <mat-header-cell *matHeaderCellDef mat-sort-header> MODIFIED DATETIME </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.MODIFIED_DATETIME}} </mat-cell>
      </ng-container>

      <!-- LAST_MODIFIED_USER -->
      <ng-container matColumnDef="LAST_MODIFIED_USER">
        <mat-header-cell *matHeaderCellDef mat-sort-header> LAST MODIFIED USER </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.LAST_MODIFIED_USER}} </mat-cell>
      </ng-container>

      <!-- Change_Type -->
      <ng-container matColumnDef="Change_Type">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Change Type </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Change_Type}} </mat-cell>
      </ng-container>

      <!-- Change_Status -->
      <ng-container matColumnDef="Change_Status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Change Status </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Change_Status}} </mat-cell>
      </ng-container>

      <!-- No Records Found -->
      <ng-container matColumnDef="noData">
        <mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <div>No History Found.</div>
        </mat-footer-cell>
      </ng-container>


      <mat-header-row class="appBackground" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <ng-container *ngIf="!loading">
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </ng-container>

      <!-- when no record or no history is found -->
      <ng-container>
        <mat-footer-row *matFooterRowDef="['noData']"
          [ngClass]="{'hide-not-found-msg':!(dataSource !== null && dataSource?.filteredData?.length === 0)}">
        </mat-footer-row>
      </ng-container>

    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[5,10, 25, 100]" showFirstLastButtons aria-label="Select page of Requester">
  </mat-paginator>
</div>