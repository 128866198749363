import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
import { CommonService } from 'src/app/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-action-plan-retire',
  templateUrl: './action-plan-retire.component.html',
  styleUrls: ['./action-plan-retire.component.scss']
})
export class ActionPlanRetireComponent implements OnInit {
  user = atob(this.common.currentUser);
  userRole = this.common.getUserRole();
  uploadFileNameMust = ''
  visibleApprovals = null
  approversChildCompData: any = {};
  actionplanForm: FormGroup = this.fb.group({

      updatedUtilities: [''],
      isAssetDemo: ['', Validators.required],
      Old_Building: [''],
      INSERT_DATETIME: [''],
      Change_Status: [''],
      Main_Asset_Number: [''],
      Old_Additional_Description: [''],
      New_Building: [''],
      Old_LabFab_Name: [''],
      New_LabFab: [''],
      New_Serial_Number: [''],
      Old_Serial_Number: [''],
      New_Asset_Description: [''],
      Old_Owner: [''],
      New_Owner: [''],
      Old_Cost_Center_Manager: [''],
      Old_Cost_Center_Code: [''],
      New_Cost_Manager: [''],
      Cost_Center_Code: [''],
      New_Cost_Center_Code: [''],
      Acquisition_Value: [''],
      PE_Net_Book_Value: [''],
      Change_Type: [''],
      planned_Completion_Date_Action_Plan: ['', Validators.required],
      actionPlanStatus: [''],
      approversEmailList: [''],
      emailToEHSStakeHolders: [''],
      notificationsToolOwners: [''],
      estimatedCost: ['', Validators.required],
      Action_plan_viewAttachedFiles: [''],
      Depreciation_Terms_Useful_Life_in_Months: [''],
      approvedRequestId: [''],
      action_Plan_AssetCost_View_Status_Of_Approvals: [''],
      action_plan_EHS_ready_to_be_notified: [false, Validators.requiredTrue],
      Cost_Calculation_Worksheet_has_been_attached: [false],
      action_Plan_pendingApprovals: [false],
      actionPlan_Is_anyWork_Req_ewaste_CyberSecurity: ['', Validators.required],
      action_plan_other_Asset_Part_Of_Move: ['', Validators.required],
      action_plan_List_Addition_Assets_Moved_with_Asset: ['']

  });
  Submitted = false;
  loading = false;
  submitLoading = false;
  loadingText = "";
  enableSubmit = true;
  approvalsSubmitBtn = true;
  AllSubmitBtnDisabled = false;
  assetID = '';
  costCalcFile = window.location.origin + '/Media/Basic_Change_Cost_Calculation_Worksheet.xlsx'
  sanDiegoFile = window.location.origin + '/Media/costCalculationWorksheet.pptx'
  corvallisFile = window.location.origin + '/Media/costCalculationWorksheet.pptx'
  emailPattern = '^[a-z0-9._%+-]+@[h.-]+[p.-]\.[com]{3,4}$';
 //Fields not shown for Lost and Stone retirement assets
  IsLostStoneAsset = true;
  //Ldap Email list verify check flags
  approversEmailList = ''
  verifyEmailsLoading = false

  // 5 level managers 
  userInvalid!: boolean | null;

  // 0 level mananger
  steps: any;
  steps1!: boolean | null;
  steps2!: boolean | null;
  steps3!: boolean | null;

  //Ldap Request
  RequestLdapValidation: any = {
    validateEmailDetails: "null"
  }
  // resp of the LDap with list of managers
  ApprovalMangersBasedOnCOst: any = [];
  ApprovalPageCheck = false;

  //View_Status_Of_Approvals
  View_Status_Of_Approvals: any = [];
  All_Notifications_Status_received: any = {};
  //{'approversEmailTrigger':false,'ownersEmailTrigger':false,'EHSEmailTrigger':false};

  //EHS EMAIL TRIGGER
  emailTriggerReqEHS = false;

  // Notify OWNERS Planned move of assets Triggers
  emailTriggerReqOwners = false;

  //NOtify approvers for Approvals of asset cost move
  emailTriggerReqApprovals = false;
  viewAttachedFiles: any = [];

  // View status of notifications of owners and EHS
  Action_plan_viewStatusOfNotifiations_Owner_EHS: any = []

  LabfabTeams: any;
  Retirement_Type = ""

  constructor(
    private location: Location, 
    private activatedRoute: ActivatedRoute, 
    private common: CommonService,
    private fb: FormBuilder, 
    private route: Router, 
    private dialog: MatDialog
  ) {this.common.bannerData.next({
    title: 'AMD', subtitle:'Action Plan for Retiring an Asset'
  });}
  
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.assetID = params['assetid'];
      this.ApprovalPageCheck = (this.route.url).includes('retire-approverList')

    });
    //this.loading = true;
    if (this.assetID) {
      this.getassedtdetailsbyid(this.assetID)
    }
  }

  otherAssetPartOfMove() {
    if (this.actionplanForm.controls['action_plan_other_Asset_Part_Of_Move'].value == ('Yes')) {
      this.actionplanForm.controls['action_plan_List_Addition_Assets_Moved_with_Asset'].setValue("");
      this.actionplanForm.get('action_plan_List_Addition_Assets_Moved_with_Asset')?.setValidators([Validators.required])
      this.actionplanForm.get('action_plan_List_Addition_Assets_Moved_with_Asset')?.updateValueAndValidity()
    }
    else {
      this.actionplanForm.controls['action_plan_List_Addition_Assets_Moved_with_Asset'].setValue("NA");
      this.actionplanForm.get('action_plan_List_Addition_Assets_Moved_with_Asset')?.clearValidators()
      this.actionplanForm.get('action_plan_List_Addition_Assets_Moved_with_Asset')?.updateValueAndValidity()
    }
  }

  isAssetDemoChange() {
    if (this.actionplanForm.controls['isAssetDemo'].value == ('Yes')) {
      this.actionplanForm.controls['updatedUtilities'].setValue("Utilities awaiting demo");
      this.actionplanForm.get('updatedUtilities')?.setValidators([Validators.required])
      this.actionplanForm.get('updatedUtilities')?.updateValueAndValidity()
    } else {
      this.actionplanForm.controls['updatedUtilities'].setValue("NA");
      this.actionplanForm.get('updatedUtilities')?.clearValidators()
      this.actionplanForm.get('updatedUtilities')?.updateValueAndValidity()
    }
  }
  // Change on the Cyber security DropDown if yes add the EHS emails and cybersecurity of primary + secondary
  change_ewaste_CyberSecurity() {
    let notifyEHSStakeHolders = ''
    let MoveTOTeam = ''

    if (this.actionplanForm.controls?.['New_Building']?.value.includes('COR') || this.actionplanForm.controls?.['New_Building']?.value.includes('SDG')) {
      MoveTOTeam = this.actionplanForm.controls?.['New_Building']?.value.substring(0, 3)
    }
    else if (this.actionplanForm.controls?.['Old_Building']?.value.includes('COR') || this.actionplanForm.controls?.['Old_Building']?.value.includes('SDG')) {
      MoveTOTeam = this.actionplanForm.controls?.['Old_Building']?.value.substring(0, 3)
    }
    // EHS Primary
    if (this.LabfabTeams?.Environmental_Health_and_Safety?.Primary != 'Null' && this.LabfabTeams?.Environmental_Health_and_Safety?.Primary != '' && this.LabfabTeams?.Environmental_Health_and_Safety?.Primary != undefined) {
      notifyEHSStakeHolders = this.LabfabTeams?.Environmental_Health_and_Safety?.Primary
    }
    // EHS Secondary
    if (this.LabfabTeams?.Environmental_Health_and_Safety?.Secondary != 'Null' && this.LabfabTeams?.Environmental_Health_and_Safety?.Secondary != '' && this.LabfabTeams?.Environmental_Health_and_Safety?.Secondary != undefined) {
      if (notifyEHSStakeHolders) {
        notifyEHSStakeHolders = notifyEHSStakeHolders + ';' + this.LabfabTeams?.Environmental_Health_and_Safety?.Secondary
      }
      else {
        notifyEHSStakeHolders = this.LabfabTeams?.Environmental_Health_and_Safety?.Secondary
      }
    }
    //Default EHS email id will be used, if no primary or secondary EHS 
    if (this.LabfabTeams == null || (this.LabfabTeams?.Environmental_Health_and_Safety?.Primary == 'Null' || !this.LabfabTeams?.Environmental_Health_and_Safety?.Primary) && (this.LabfabTeams?.Environmental_Health_and_Safety?.Secondary == 'Null' || !this.LabfabTeams?.Environmental_Health_and_Safety?.Secondary)) {
      if (this.LabfabTeams?.SITE == 'COR' || MoveTOTeam == 'COR') {
        notifyEHSStakeHolders = 'robert.brooks1@hp.com;tyler.forrest@hp.com'
      }
      if (this.LabfabTeams?.SITE == "SDG" || MoveTOTeam == "SDG") {
        notifyEHSStakeHolders = 'vu.lam@hp.com'
      }

    }
    console.log("notifyEHSStakeHolders ..... ", notifyEHSStakeHolders)
    if (this.actionplanForm.controls['actionPlan_Is_anyWork_Req_ewaste_CyberSecurity'].value == ('Yes')) {
      if (this.LabfabTeams?.Cybersecurity?.Primary && this.LabfabTeams?.Cybersecurity?.Primary != 'Null' && this.LabfabTeams?.Cybersecurity?.Primary != undefined) {
        if (notifyEHSStakeHolders) {
          notifyEHSStakeHolders = notifyEHSStakeHolders + ';' + this.LabfabTeams?.Cybersecurity?.Primary
        }
      }
      if (this.LabfabTeams?.Cybersecurity?.Secondary && this.LabfabTeams?.Cybersecurity?.Secondary != 'Null' && this.LabfabTeams?.Cybersecurity?.Secondary != undefined) {
        if (notifyEHSStakeHolders) {
          notifyEHSStakeHolders = notifyEHSStakeHolders + ';' + this.LabfabTeams?.Cybersecurity?.Secondary
        }
      }
      //Default Cybersecurity will be used, if no primary or secondary Cybersecurity 
      console.log("csec ..... ", notifyEHSStakeHolders)
      if (this.LabfabTeams == null || (!this.LabfabTeams?.Cybersecurity?.Primary || this.LabfabTeams?.Cybersecurity?.Primary == 'Null') && (!this.LabfabTeams?.Cybersecurity?.Secondary || this.LabfabTeams?.Cybersecurity?.Secondary == 'Null')) {
        if(this.LabfabTeams?.SITE=='COR'  || MoveTOTeam == 'COR'){
          notifyEHSStakeHolders = notifyEHSStakeHolders ? (notifyEHSStakeHolders + ';gordon.d.current@hp.com;win.zaw@hp.com'):'gordon.d.current@hp.com;win.zaw@hp.com'
        }
        if(this.LabfabTeams?.SITE=="SDG"  || MoveTOTeam == 'SDG'){
          notifyEHSStakeHolders =notifyEHSStakeHolders ? (notifyEHSStakeHolders + ';gordon.d.current@hp.com;michael.madison1@hp.com'):'gordon.d.current@hp.com;michael.madison1@hp.com'
        }
      }
    }
    else {
      if (this.LabfabTeams?.Environmental_Health_and_Safety?.Primary != 'Null' && this.LabfabTeams?.Environmental_Health_and_Safety?.Primary != '' && this.LabfabTeams?.Environmental_Health_and_Safety?.Primary != undefined) {
        notifyEHSStakeHolders = this.LabfabTeams?.Environmental_Health_and_Safety?.Primary
      }
      // EHS Secondary
      if (this.LabfabTeams?.Environmental_Health_and_Safety?.Secondary != 'Null' && this.LabfabTeams?.Environmental_Health_and_Safety?.Secondary != '' && this.LabfabTeams?.Environmental_Health_and_Safety?.Secondary != undefined) {
        if (notifyEHSStakeHolders) {
          notifyEHSStakeHolders = notifyEHSStakeHolders + ';' + this.LabfabTeams?.Environmental_Health_and_Safety?.Secondary
        }
        else {
          notifyEHSStakeHolders = this.LabfabTeams?.Environmental_Health_and_Safety?.Secondary
        }
      }

      this.actionplanForm.controls['emailToEHSStakeHolders'].setValue(notifyEHSStakeHolders);
    }
    if (this.actionplanForm.controls['emailToEHSStakeHolders'].value) {
      let EHSEmailList = [...new Set([notifyEHSStakeHolders].concat(this.actionplanForm.controls['emailToEHSStakeHolders'].value ? this.actionplanForm.controls['emailToEHSStakeHolders'].value.split(';') : ''))].filter((item) => item).join(';')
      let setEHSEmailList = [... new Set(EHSEmailList.replace(/@hp.com/g, '').split(";"))]?.join(';')

      this.actionplanForm.controls['emailToEHSStakeHolders'].setValue(setEHSEmailList);
    }
    else {
      this.actionplanForm.controls['emailToEHSStakeHolders'].setValue(notifyEHSStakeHolders);
    }
    if (this.actionplanForm.controls['emailToEHSStakeHolders'].value) {
      this.validUserLdap(this.actionplanForm.controls['emailToEHSStakeHolders'].value, 0, 3)
    }
  }

  UtilitiesOfAsset() {
    if (this.actionplanForm.controls['updatedUtilities'].value == ('NA')) {
      this.actionplanForm.controls['isAssetDemo'].setValue("No");
      this.actionplanForm.get('updatedUtilities')?.clearValidators()
      this.actionplanForm.get('updatedUtilities')?.updateValueAndValidity()
    } else {
      this.actionplanForm.controls['isAssetDemo'].setValue("Yes")
      this.actionplanForm.get('updatedUtilities')?.setValidators([Validators.required])
      this.actionplanForm.get('updatedUtilities')?.updateValueAndValidity()
      }
  }

  getassedtdetailsbyid(assetID: string) {
    this.common.loader.next(true);
    this.common.getAPI('amd-get-assetbyid/' + assetID).subscribe({
      next: ((data: any) => {
      //this.loading = false;
      this.actionplanForm.patchValue(data[0]);
      this.common.loader.next(false);
      // Based on the change type the cost calc worksheet template get updated
      // Format for the file must be uploaded with below given name and extension .xlsx
      if (data[0]['Change_Type']) {
        console.log("data[0]['Change_Type']", data[0]['Change_Type'])
        if (data[0]['Change_Type'] == 'Basic Change' || data[0]['Change_Type'] == 'Transfer') {
          this.costCalcFile = window.location.origin + '/Media/' + data[0]['Change_Type'].replace(' ', '_') + '_Cost_Calculation_Worksheet.xlsx'
          this.uploadFileNameMust = data[0]['Change_Type']?.replaceAll(" ", "_") + '_Cost_Calculation_Worksheet.xlsx'
        }
        if (data[0]['Change_Type'] == 'Retired' && data[0]['Retirement_Type']) {
          let retireType = ""
          console.log("retirement type", data[0]['Retirement_Type'])
          if (data[0]['Retirement_Type'] == "260 Retirement with Revenue/Sale") {
            retireType = "Sale"
          }

          if ((data[0]['Retirement_Type'] == "2Z0 Retirement by Scrap") || (data[0]['Retirement_Type'] == "2ZE Retirement By Inventory Scrap")) {
            retireType = "Scrap"
          }

          if (data[0]['Retirement_Type'] == "2ZA Retirement by Donation") {
            retireType = "Donation"
          }

          if ((data[0]['Retirement_Type'] == "2Z3 Retirement by Stolen") || (data[0]['Retirement_Type'] == "2ZF Retirement By Inventory Stolen")) {
            retireType = "Stolen"
            this.Retirement_Type = "Stolen"
            this.IsLostStoneAsset = false;
          }
          if ((data[0]['Retirement_Type'] == "2Z2 Retirement by Lost") || (data[0]['Retirement_Type'] == "2ZD Retirement By Inventory Lost")) {
            retireType = "Lost"
            this.Retirement_Type = "Lost"
            this.IsLostStoneAsset = false;
          }

          if (data[0]['Retirement_Type'] == "2Z8 Retirement by Trade IN") {
            retireType = "TradeIn"
          }
          if (data[0]['Retirement_Type'] == "2ZA Retirement by Donation") {
            retireType = "Donation"
          }

          if(!(this.Retirement_Type=='Lost' || this.Retirement_Type=='Stolen')){
            this.actionplanForm.get('estimatedCost')?.setValidators([Validators.required])
          }else{
            // if the asset is retired and retriment_type is lost or stolen 
            // then no need of estmated cost or uploading the files so set to true , no ehs email ,no view file attachements
            this.actionplanForm.controls['estimatedCost'].setValue("")
            this.actionplanForm.get('estimatedCost')?.clearValidators()
            this.actionplanForm.controls['Cost_Calculation_Worksheet_has_been_attached'].setValue(true)
            this.actionplanForm.controls['action_plan_EHS_ready_to_be_notified'].setValue(true)
            this.actionplanForm.get('estimatedCost')?.clearValidators()
            this.actionplanForm.get('actionPlan_Is_anyWork_Req_ewaste_CyberSecurity')?.clearValidators()
            this.actionplanForm.get('action_plan_other_Asset_Part_Of_Move')?.clearValidators()
            this.actionplanForm.get('planned_Completion_Date_Action_Plan')?.clearValidators()
            this.actionplanForm.get('isAssetDemo')?.clearValidators()
            this.actionplanForm.get('estimatedCost')?.updateValueAndValidity()
            this.actionplanForm.get('actionPlan_Is_anyWork_Req_ewaste_CyberSecurity')?.updateValueAndValidity()
            this.actionplanForm.get('action_plan_other_Asset_Part_Of_Move')?.updateValueAndValidity()
            this.actionplanForm.get('planned_Completion_Date_Action_Plan')?.updateValueAndValidity()
            this.actionplanForm.get('isAssetDemo')?.updateValueAndValidity()
          }
          
          this.actionplanForm.get('estimatedCost')?.updateValueAndValidity()
           
          console.log(data[0]['Retirement_Type'], retireType)
          this.costCalcFile = window.location.origin + '/Media/' + retireType + '_Cost_Calculation_Worksheet.xlsx'
          this.uploadFileNameMust = retireType + '_Cost_Calculation_Worksheet.xlsx'
        }

        console.log("this.costCalcFile", this.costCalcFile)
      }

      if (data[0]['Old_Additional_Description'] == '' || !('Old_Additional_Description' in data[0]) || data[0]['Old_Additional_Description'] == 'null') {
        this.actionplanForm.controls['Old_Additional_Description'].setValue(data[0]['Asset_Description']);
      }
      if (data[0]['Old_Serial_Number'] == '' || !('Old_Serial_Number' in data[0]) || data[0]['Old_Serial_Number'] == 'null') {
        this.actionplanForm.controls['Old_Serial_Number'].setValue(data[0]['Serial_Number']);
      }
      if (data[0]['Old_Building'] == '' || !('Old_Building' in data[0]) || data[0]['Old_Building'] == 'null') {
        this.actionplanForm.controls['Old_Building'].setValue(data[0]['Asset_Location']);
      }
      if (data[0]['Old_LabFab_Name'] == '' || !('Old_LabFab_Name' in data[0]) || data[0]['Old_LabFab_Name'] == 'null') {
        this.actionplanForm.controls['Old_LabFab_Name'].setValue(data[0]['Asset_Supplementary_Inventory_Specifications_Name']);
      }
      if (data[0]['Old_Owner'] == '' || !('Old_Owner' in data[0]) || data[0]['Old_Owner'] == 'null') {
        this.actionplanForm.controls['Old_Owner'].setValue(data[0]['Additional_Description']);
      }
      if (data[0]['Old_Cost_Center_Manager'] == '' || !('Old_Cost_Center_Manager' in data[0]) || data[0]['Old_Cost_Center_Manager'] == 'null') {
        this.actionplanForm.controls['Old_Cost_Center_Manager'].setValue(data[0]['Cost_Object_Manager_Email_ID']);
      }
      if (data[0]['Old_Cost_Center_Code'] != '' || !('Old_Cost_Center_Code' in data[0]) || data[0]['Old_Cost_Center_Code'] != 'Null' || data[0]['Old_Cost_Center_Code'] != 'null') {
        this.actionplanForm.controls['Old_Cost_Center_Code'].setValue(data[0]['Cost Center Code'] ? data[0]['Cost Center Code'] : data[0]['Cost_Center_Code']);
      }
      if (data[0]['Cost_Calculation_Worksheet']) {
        this.actionplanForm.controls['costCalculationWorksheet'].setValue(data[0]['Cost_Calculation_Worksheet']);
      }
      if (data[0]['Approved_request_Id_Action_Plan']) {
        this.actionplanForm.controls['approvedRequestId'].setValue(data[0]['Approved_request_Id_Action_Plan'])
      }
  
      if (data[0]['Email_To_EHS_StakeHolders_standup_permit_or_the_green_sticker']) {
        this.actionplanForm.controls['emailToEHSStakeHolders'].setValue(data[0]['Email_To_EHS_StakeHolders_standup_permit_or_the_green_sticker'])

        if (this.actionplanForm.controls['emailToEHSStakeHolders'].value) {
          this.validUserLdap(this.actionplanForm.controls['emailToEHSStakeHolders'].value, 0, 3)
        }
      }
      if (data[0]['Is_Utilities_Need_Demo_in_Asset_Moved_From']) {
        this.actionplanForm.controls['isAssetDemo'].setValue(data[0]['Is_Utilities_Need_Demo_in_Asset_Moved_From'])
      }

      if (data[0]['Action_Plan_Email_Approvers_List']) {
        this.approversChildCompData['Action_Plan_Email_Approvers_List'] = data[0]['Action_Plan_Email_Approvers_List']
      }

      if (data[0]['action_Plan_AssetCost_View_Status_Of_Approvals']) {
        this.approversChildCompData['action_Plan_AssetCost_View_Status_Of_Approvals'] = data[0]['action_Plan_AssetCost_View_Status_Of_Approvals']

      }
      // add the default cost location managers i,e cost object mangers

      this.approversEmailList = [...new Set([this.actionplanForm.get('Old_Cost_Center_Manager')?.value, data[0]['New_Cost_Manager']].concat(data[0]['Action_Plan_Email_Approvers_List'] ? data[0]['Action_Plan_Email_Approvers_List'].split(';') : ''))].filter((item) => item != null).join(';')
      let setApproverEmailList = [... new Set(this.approversEmailList.replace(/@hp.com/g, '').split(";"))]?.join(';')
      if (this.approversEmailList && setApproverEmailList) {
        if(setApproverEmailList.charAt(setApproverEmailList.length - 1) == ';'){
          setApproverEmailList = setApproverEmailList.slice(0, -1); // removes ; from last
        }
        this.actionplanForm.controls['approversEmailList'].setValue(setApproverEmailList)
        this.validUserLdap(setApproverEmailList, 0, 1)
      }

      if (data[0]['Demo_utilities_of_asset_Status']) {
        this.actionplanForm.controls['updatedUtilities'].setValue(data[0]['Demo_utilities_of_asset_Status'])
      }

      let AllOwners = [...new Set([(data[0]['Old_Owner'] ? data[0]['Old_Owner'] : this.actionplanForm.controls?.['Old_Owner']?.value), data[0]['New_Owner']].concat(data[0]['Notifications_to_listed_tool_owner_Action_Plan'] ? data[0]['Notifications_to_listed_tool_owner_Action_Plan'].split(';') : ''))].filter((item) => item).join(';')

      let OwnersList = [... new Set(AllOwners.replace(/@hp.com/g, '').split(";"))]?.join(';')
      if (AllOwners) {
        this.actionplanForm.controls['notificationsToolOwners'].setValue(OwnersList);
        this.validUserLdap(this.actionplanForm.controls['notificationsToolOwners'].value, 0, 2)
      }

      if (data[0]['New_LabFab'] && data[0]['New_LabFab'] != 'NULL' && data[0]['New_LabFab'] != 'null') {

        this.LabfabData(this.actionplanForm.controls['New_LabFab'].value)
      } else {
        this.LabfabData(this.actionplanForm.controls['Old_LabFab_Name']?.value)
      }

      if (data[0]['action_Plan_AssetCost_View_Status_Of_Approvals']) {
        this.View_Status_Of_Approvals = JSON.parse(data[0]['action_Plan_AssetCost_View_Status_Of_Approvals'])?.approversList
      }
      if (data[0]['action_Plan_All_Notifications_Status']) {
        this.All_Notifications_Status_received = JSON.parse(data[0]['action_Plan_All_Notifications_Status'])
      }
      if (data[0]['Action_plan_viewStatusOfNotifiations_Tool_Owners'] || data[0]['Action_plan_viewStatusOfNotifiations_EHS_Stakeholders']) {
        let InitialData: any = {};
        if ('Action_plan_viewStatusOfNotifiations_Tool_Owners' in data[0]) {
          InitialData = (JSON.parse(data[0]['Action_plan_viewStatusOfNotifiations_Tool_Owners'])[0])
        }

        if ('Action_plan_viewStatusOfNotifiations_EHS_Stakeholders' in data[0]) {
          InitialData = (JSON.parse(data[0]['Action_plan_viewStatusOfNotifiations_EHS_Stakeholders'])[0])
        }
        if ('Action_plan_viewStatusOfNotifiations_Tool_Owners' in data[0] && 'Action_plan_viewStatusOfNotifiations_EHS_Stakeholders' in data[0]) {
          InitialData = { ...(JSON.parse(data[0]['Action_plan_viewStatusOfNotifiations_Tool_Owners'])[0]), ...(JSON.parse(data[0]['Action_plan_viewStatusOfNotifiations_EHS_Stakeholders'])[0]) }
        }


        if ('notification_EHS_Path' in InitialData && InitialData['notification_EHS_Path']) {
          this.Action_plan_viewStatusOfNotifiations_Owner_EHS?.push({ 
            "Name": InitialData['notification_EHS_Path']?.replace('amd/', ""), 
            "Path": 'amd' 
          })
        }
        if ('notification_Tool_Owners_Path' in InitialData && InitialData['notification_Tool_Owners_Path']) {
          console.log(InitialData['notification_Tool_Owners_Path'])
          this.Action_plan_viewStatusOfNotifiations_Owner_EHS?.push({ "Name": InitialData['notification_Tool_Owners_Path']?.replace('amd/', ""), "Path": 'amd' })
        }
        console.log(this.Action_plan_viewStatusOfNotifiations_Owner_EHS)
      }

      if (data[0]['Action_plan_viewAttachedFiles']) {
        this.viewAttachedFiles = (JSON.parse(data[0]['Action_plan_viewAttachedFiles']))
        this.viewAttachedFiles = this.viewAttachedFiles?.reduce((unique: any, o: any) => {
          if (!unique.some((obj: any) => obj?.Name === o?.Name)) {
            unique.push(o);
          }
          return unique;
        }, []);
        console.log(this.viewAttachedFiles)
      }
      this.EnableSubmitBtn();
      this.EnableApprovalsSubmitBtn();
      //if requestor and status is Pending for Analyst review all the buttons are greyed out
      if (this.userRole == 'Requester' && this.actionplanForm.controls['Change_Status'].value == 'Pending Analyst Review') {
        this.AllSubmitBtnDisabled = true;
      }
    }),
    error: (error)=>{
      this.common.loader.next(false);
      this.common.show('error', 'Something went wrong', error.message, error.status)
    }
  })
}

  //Form submit on save or submit

  // Action Plan Save to Draft
  saveToDraft() {
    if (this.actionplanForm.controls['Change_Status'].value == '')
      return

    if (this.steps1 == undefined || this.steps1 || this.steps1 == null) {
      this.openDilaogeMessage('Please verify Approvals Email ID / First & Last Name', '')
      return;
    }

    this.loadingText = 'Saving the action plan request...'
    this.actionplanForm.get('actionPlanStatus')?.setValue("Saved");

    let Submit_SucessMessage = 'Action Plan for ' + this.assetID + ' Asset Request Change has been Save to Draft. You may continue to edit the Action Plan until all fields are updated and the request is ready for approval by the Cost Object Owner(s).'
    this.ActionPlan_ServiceCall(Submit_SucessMessage)
    this.EnableApprovalsSubmitBtn()
  }

  //Action plan submitted for Approvals for Cost object managers
  submitForApprovals() {

    if (this.actionplanForm.controls['Change_Status'].value == '')
      return
    if (this.actionplanForm.invalid) {
      this.Submitted = true;
      console.log('Fill all the required fields', this.actionplanForm.controls);
      this.openDilaogeMessage('Please fill all the required fields *     ', '')
      return
    }

    // If email IDs are not valid then user cannt submit the form
    if (this.userInvalid) {
      this.submitLoading = false
      this.openDilaogeMessage('Please verify Email ID / First & Last Name', '')
      return
    }
    if (this.steps1 == undefined || this.steps1 || this.steps1 == null) {
      this.openDilaogeMessage('Please verify Approvals Email ID / First & Last Name', '')
      return;
    }
    if (this.steps2 == undefined || this.steps2 || this.steps2 == null) {
      this.openDilaogeMessage('Please verify Tool Owners Email ID / First & Last Name', '')
      return;
    }
    if ((this.steps3 == undefined || this.steps3 == null || this.steps3) && this.actionplanForm.controls['emailToEHSStakeHolders'].value != '') {
      this.openDilaogeMessage('Please verify EHS Email ID / First & Last Name', '')
      return;
    }


    if (this.actionplanForm.controls['action_plan_EHS_ready_to_be_notified'].value == false) {
      this.openDilaogeMessage('Select the Approval Email ready to send checkbox', '')
      return;
    }
    // flag for the email trigger EHS email ,NO Plan Email only once STARTS
    //action_Plan_All_Notifications_Status
    this.actionplanForm.get('actionPlanStatus')?.setValue("Saved");
    //ON Submit for approvals NOtify the Approvers
    this.emailTriggerReqApprovals = true;
    if (this.emailTriggerReqApprovals) {
      //this.actionplanForm.controls.action_Plan_pendingApprovals.setValue(true)
      this.submitLoading = true
      this.PlannedEmailTrigger();
      this.EHSEmailTrigger();
      console.log("check the 'action_Plan_All_Notifications_Status'", this.actionplanForm.value['action_Plan_All_Notifications_Status'])
    }
    else {
      this.actionplanForm.value['action_Plan_All_Notifications_Status'] = ''
    }

    this.loadingText = 'Submitting the action plan request for Approvals...'
    let Submit_SucessMessage = ''
    if(!this.IsLostStoneAsset){
      //Lost Or Stolen
      Submit_SucessMessage = 'Action Plan for ' + this.assetID + ' Asset Request Change has Submitted for Approval. You will be notified when the approval is complete'
    }
    else{
      Submit_SucessMessage = 'Action Plan for ' + this.assetID + ' Asset Request Change has Submitted for Approval. The request will now be routed for approval. You will be notified when the approval is complete, and work may be started to initiate the move.'
    }
    

    console.log("check the 'action_Plan_All_Notifications_Status'", this.actionplanForm.value['action_Plan_All_Notifications_Status'])
    this.ActionPlan_ServiceCall(Submit_SucessMessage)
  }


  //Action plan when submitted for Analyst Review
  submitForAnlyst() {

    if ((this.actionplanForm.controls['estimatedCost'].value == '' && this.actionplanForm.controls['estimatedCost'].value == 0) && !(this.Retirement_Type == 'Lost' || this.Retirement_Type == 'Stolen')) {
      this.openDilaogeMessage('Enter the estimated cost ', '')
      return
    }
    if (this.actionplanForm.controls['isAssetDemo'].value == 'Yes' && this.actionplanForm.controls['updatedUtilities'].value != "All utilities have been demo'd") {
      this.openDilaogeMessage('Select the  "All utilities have been demo d" ', '')
      return;
    }

    if ((this.steps2 == undefined || this.steps2 == null || this.steps2) || ((this.steps3 == undefined || this.steps3 == null || this.steps3) && this.actionplanForm.controls['emailToEHSStakeHolders'].value != '')) {
      this.openDilaogeMessage('Please verify Email ID / First & Last Name', '')
      return;
    }
    if (this.steps1 == undefined || this.steps1) {
      this.openDilaogeMessage('Please verify Email ID / First & Last Name', '')
      return;
    }

    this.actionplanForm.get('actionPlanStatus')?.setValue("Completed");
    this.loadingText = 'Submitting the action plan request for Analyst Review...'
    let Submit_SucessMessage = 'Action Plan for ' + this.assetID + ' Asset Request Change has been flagged as Tool Moved/Ready for Analyst Review. The Analyst will verify the details and update AMD to reflect the tool move along with printing the Asset Label.'
    this.ActionPlan_ServiceCall(Submit_SucessMessage)
  }



  // Action Plan Service call
  ActionPlan_ServiceCall(SucessMessage: string) {
    this.submitLoading = true
    if (this.actionplanForm.controls['Action_plan_viewAttachedFiles'].value && Array.isArray(this.actionplanForm.controls['Action_plan_viewAttachedFiles'].value)) {
      this.actionplanForm.value['Action_plan_viewAttachedFiles'] = JSON.stringify(this.actionplanForm.controls['Action_plan_viewAttachedFiles'].value)
    }
    // flag for the email trigger EHS email ,NO Plan Email only once ENDS
    console.log("request  Data", this.actionplanForm.value);
    this.common.postAPI('amd-basic-action-plan/', this.actionplanForm.value).subscribe((data: any) => {
      console.log("Data submitted")
      console.log(data.body)
      console.log(data.statusCode)
      if (data.statusCode == 200) {

        if (this.emailTriggerReqEHS) {
          this.emailActionReqEHS();
          console.log('emailTriggerReqEHS triggered')
          this.emailTriggerReqEHS = false;
        }
        if (this.emailTriggerReqOwners) {
          this.emailNoActionReqPlanned()
          console.log('emailNoActionReqPlanned triggered')
          this.emailTriggerReqOwners = false;
        }
        if (this.emailTriggerReqApprovals) {
          console.log("Cost email triggered")
          //keep the check for lost and stolen
          this.emailNotifyToCostApprovers();
          this.emailTriggerReqApprovals = false;
        }
        this.submitLoading = false;
        this.openDilaogeMessage(SucessMessage, this.assetID)
      }
    });
  }

  //cancel button
  close() {

    if (this.actionplanForm.controls['Change_Type'].value == 'Basic Change' || this.actionplanForm.controls['Change_Type'].value == 'Transfer') {

      this.route.navigate(['/assetRequestChange/request-change-asset'], { queryParams: { assetid: this.assetID } });
    }
    if (this.actionplanForm.controls['Change_Type'].value == 'Retired') {

      this.route.navigate(['/assetRequestChange/retrimentRequest'], { queryParams: { assetid: this.assetID } });
    }

  }

  //back to previous page

  backButton() {
    this.location.back();
  }

  // Enable the Approvals submit Btn
  EnableApprovalsSubmitBtn() {

    if (this.actionplanForm.valid && this.actionplanForm.controls['Cost_Calculation_Worksheet_has_been_attached'].value && this.actionplanForm.controls['action_plan_EHS_ready_to_be_notified'].value == true && this.actionplanForm.controls['isAssetDemo'].value != '') {
      this.approvalsSubmitBtn = false;
    }

    //For assets whihc are lost and stolen
    if(!this.IsLostStoneAsset && this.actionplanForm.controls['action_plan_EHS_ready_to_be_notified'].value == true && this.actionplanForm.controls['approversEmailList'].value!='' && this.actionplanForm.controls['notificationsToolOwners'].value!='' && this.actionplanForm.controls['emailToEHSStakeHolders'].value!=''){
      this.approvalsSubmitBtn = false;
    }
  }
  //enable Analyst submit button
  EnableSubmitBtn() {
    if (this.View_Status_Of_Approvals.length && this.actionplanForm.valid && this.actionplanForm.controls['Cost_Calculation_Worksheet_has_been_attached'].value && this.actionplanForm.controls['action_plan_EHS_ready_to_be_notified'].value == true && this.actionplanForm.controls['updatedUtilities'].value != '' && this.actionplanForm.controls['action_Plan_pendingApprovals'].value == false) {
      this.enableSubmit = this.View_Status_Of_Approvals?.some((item: any) => { return item.status === 'Pending' })
    } 
    //For assets whihc are lost and stolen
    else if(this.View_Status_Of_Approvals.length && !this.IsLostStoneAsset && this.actionplanForm.controls['action_plan_EHS_ready_to_be_notified'].value == true && this.actionplanForm.controls['approversEmailLis'].value!='' && this.actionplanForm.controls['notificationsToolOwners'].value!='' && this.actionplanForm.controls['emailToEHSStakeHolders+'].value!=''){
      this.enableSubmit = this.View_Status_Of_Approvals?.some((item: any) => { return item.status === 'Pending' })
    }
    else {
      this.enableSubmit = true
    }
  }

  //Message dialog 
  openDilaogeMessage(message: string, assetID: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      message: message,
      tabletemplate: false,
      title: "Action Plan"
    }

    const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
    dialogRefSubmit.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (this.actionplanForm.controls['actionPlanStatus'].value == 'Completed' && assetID) {
        if (this.actionplanForm.controls['Change_Type'].value == 'Basic Change' || this.actionplanForm.controls['Change_Type'].value == 'Transfer') {
          this.route.navigate(['/assetRequestChange/request-change-asset'], { queryParams: { assetid: this.assetID } });
        }
        if (this.actionplanForm.controls['Change_Type'].value == 'Retired') {
          this.route.navigate(['/assetRequestChange/retrimentRequest'], { queryParams: { assetid: this.assetID } });
        }
      }
      if (this.actionplanForm.controls['actionPlanStatus'].value == 'Saved' && assetID) {
        window.location.reload()
        //this.route.navigate(['/assetRequestChange/action-plan'], { queryParams: { assetid: this.assetID } });
      }
    });
  }

  isEmailChange(steps: any) {
    if (steps == 1) {
      this.steps1 = null;
    }
    if (steps == 2) {
      this.steps2 = null;
    }
    if (steps == 3) {
      this.steps3 = null;
    }
  }

  // STARTs Ldap validation with user name / email ID  
  // level is used to get the above mangers ex:level=5 to get the 5 level mangers of xyz user
  validUserLdap(user: any, levels: any, steps?: number) {
    this.steps = steps;
    this.userInvalid = null;
    this.steps1, this.steps2, this.steps3;
    this.verifyEmailsLoading = true;
    if (user.includes('NULL' || 'NULL;' || ';NULL')) {
      user = user.replace('NULL;', '')
      user = user.replace(';NULL', '')
    }

    this.RequestLdapValidation['validateEmailDetails'] = user
    //this.RequestLdapValidation['validateEmailDetails'] = user.includes('.' || '_' || '-') ? (user.includes('@hp.com') ? user : user + '@hp.com') : user //if email tom.gary

    this.RequestLdapValidation['level'] = levels
    //if (this.RequestLdapValidation['Email'] && this.RequestLdapValidation['Password'] && this.RequestLdapValidation['validateEmailDetails'] != '') {
    if (this.RequestLdapValidation['validateEmailDetails'] != '') {
      //removes depulicate emails or names
      let request = [...new Set(this.RequestLdapValidation['validateEmailDetails'].split(";"))].join(";")

      //removes the ';' at the last char of the string ex1;ex2;
      //console.log("request Ldap", request)
      if (request.charAt(request.length - 1) == ';') {
              request = request.slice(0, -1)
      }
      this.RequestLdapValidation['validateEmailDetails'] = request
      this.common.postAPI('amd-ldap-email-validation', this.RequestLdapValidation).subscribe((data: any) => {
        this.verifyEmailsLoading = false;
        // console.log(data)
        if (data?.statusCode == 200 && data?.body && data?.body?.length) {
          if (levels == 5) { // level 5 means getting the higher level mangers used for est cost
            console.log(levels)
            this.ApprovalMangersBasedOnCOst = data?.body
            // console.log("cost objects managers 5 higher level managers", this.ApprovalMangersBasedOnCOst);
          }

          let notFound = data?.body[0]['userNotFound'].filter((e: any) => e)

          if (notFound?.length || data?.errorMSg || data?.body?.length == 0) {
            let NotFoundverify = notFound.join('  /  ')
            this.invalidEmail(steps, NotFoundverify)
          }

          if (notFound?.length == 0) {
            this.userInvalid = false;
            //console.log("Valid Email ID / First & Last Name")
            if (steps == 1) {
              // console.log("steps", steps)
              this.actionplanForm.controls['approversEmailList'].setValue(this.emails(data?.body))
              this.steps1 = false;
            }
            if (steps == 2) {
              // console.log("steps", steps)
              this.actionplanForm.controls['notificationsToolOwners'].setValue(this.emails(data?.body))
              this.steps2 = false;
            }
            if (steps == 3) {
              // console.log("steps", steps)
              this.actionplanForm.controls['emailToEHSStakeHolders'].setValue(this.emails(data?.body))
              this.steps3 = false;
            }
          }
        }
        // if user is not valid in LDAP
        if (data?.errorMSg || data.body?.length == 0 || data.body?.userNotFound) {
          this.invalidEmail(steps, '')
        }
      });
    }
  }

  emails(data: any) {
    let resp: any = []
    let emailString = "";
    let emailRespLdap = ''

    for (let x of data) {
      if (x['email']) {
        resp.push(x['email'])
      }
    }
    data = [...new Set(resp)]
    for (let x of data) {
      if (x) {
        emailString += x + ";"
      }
    }
    if (emailString.charAt(emailString.length - 1) == ';') {
      emailRespLdap = emailString.slice(0, -1)
    }
    return emailRespLdap
  }

  invalidEmail(steps: any, NotFoundverify: any) {
    this.openDilaogeMessage("Invalid Email ID / First & Last Name::    " + NotFoundverify, '')
    this.userInvalid = true;
    console.log("Invalid Email ID / First & Last Name")
    if (steps == 1) {
      // console.log("steps", steps)
      this.steps1 = true;
    }
    if (steps == 2) {
      this.steps2 = true;
      // console.log("steps", steps)
    }
    if (steps == 3) {
      this.steps3 = true;
      //  console.log("steps", steps)
    }
  }
  // END Ldap validation with user name / email ID  

  //Email notifications for amd-notification-no-action-req-planned
    emailNoActionReqPlanned() {
      this.common.postAPI('amd-ldap-email-validation', this.assetID).subscribe((data: any) => {
      })
    }
  
    //EHS Asset Move Notification
    emailActionReqEHS() {
      this.common.postAPI('amd-emails-action-req-ehs-asset-move', this.assetID).subscribe((data: any) => {
      })
    }
  
    //EHS Asset Move Notification
    emailNotifyToCostApprovers() {
      this.common.postAPI('amd-email-action-req-approve-asset-move-cost-eod', { 'Main_Asset_Number': this.assetID }).subscribe((data: any) => {
        this.emailTriggerReqApprovals = false
      })
    }


  //Check the approvals status from managers
  viewStatus(index: any) {
    if (this.visibleApprovals == index) {
      this.visibleApprovals = null
    }
    else {
      this.visibleApprovals = index
    }
  }

  valueFromChildapprovers(event: any) {
    this.View_Status_Of_Approvals = event?.approversList;
    console.log(event, "event child data received")
    //enable the submit btn for Analyst Review 
    this.EnableSubmitBtn();
  }

  EHSEmailTrigger() {
    if (this.actionplanForm?.value?.Change_Status == 'Draft'
      && this.actionplanForm?.value?.action_plan_EHS_ready_to_be_notified === true) {
      if (Object.keys(this.All_Notifications_Status_received).length) {
        if (typeof (this.All_Notifications_Status_received) == 'string') {
          this.All_Notifications_Status_received = JSON.parse(this.All_Notifications_Status_received)
        }
        if (this.All_Notifications_Status_received['EHSEmailTrigger'] == false) {
          this.All_Notifications_Status_received['EHSEmailTrigger'] = true
          this.actionplanForm.value['action_Plan_All_Notifications_Status'] = JSON.stringify(this.All_Notifications_Status_received);
          //this.All_Notifications_Status_received = JSON.stringify(this.All_Notifications_Status_received);
          this.emailTriggerReqEHS = true;
        }
        else {
          console.log("JSON.stringify(this.All_Notifications_Status_received)", JSON.stringify(this.All_Notifications_Status_received))
          this.actionplanForm.value['action_Plan_All_Notifications_Status'] = JSON.stringify(this.All_Notifications_Status_received)
          console.log("this.actionplanForm.value['action_Plan_All_Notifications_Status']", this.actionplanForm.value)
        }
      }
      else {
        if (Object.keys(this.All_Notifications_Status_received).length == 0) {
          let ehsflag = JSON.parse(this.actionplanForm.value['action_Plan_All_Notifications_Status'])
          ehsflag['EHSEmailTrigger'] = true;
          this.actionplanForm.value['action_Plan_All_Notifications_Status'] = JSON.stringify(ehsflag)
          this.emailTriggerReqEHS = true;

        }
      }
    }
    else {
      console.log("NOT CHECKED EHS CHECKBOX");
      if (this.actionplanForm.value['action_Plan_All_Notifications_Status'] != '') {
        console.log("758", this.actionplanForm.value['action_Plan_All_Notifications_Status'])
      }
      else {
        this.actionplanForm.value['action_Plan_All_Notifications_Status'] = (Object.keys(this.All_Notifications_Status_received).length == 0) ? '' : JSON.stringify(this.All_Notifications_Status_received)
        console.log("762", this.actionplanForm.value['action_Plan_All_Notifications_Status'])

      }
    }
  }

  PlannedEmailTrigger() {
    if (this.actionplanForm?.value?.Change_Status == 'Draft') {
      if (Object.keys(this.All_Notifications_Status_received).length) {
        if (this.All_Notifications_Status_received['ownersEmailTrigger'] == false) {
          this.All_Notifications_Status_received['ownersEmailTrigger'] = true
          this.actionplanForm.value['action_Plan_All_Notifications_Status'] = JSON.stringify(this.All_Notifications_Status_received)
          this.All_Notifications_Status_received = JSON.stringify(this.All_Notifications_Status_received)
          this.emailTriggerReqOwners = true;
        }
        else {
          this.actionplanForm.value['action_Plan_All_Notifications_Status'] = JSON.stringify(this.All_Notifications_Status_received)
        }
      }
      else {
        if (Object.keys(this.All_Notifications_Status_received).length == 0) {
          this.actionplanForm.value['action_Plan_All_Notifications_Status'] = JSON.stringify({ 'approversEmailTrigger': false, 'ownersEmailTrigger': true, 'EHSEmailTrigger': false })
          this.emailTriggerReqOwners = true;
        }
      }
    }
  }

  // add the child files 
  addChildForm(FileData: string, group: any) {
    // console.log("group", group)
    this.actionplanForm.addControl(FileData, group);
    if (group['Name'] != "" && group['Name']) {

      this.viewAttachedFiles.push(group)
      //console.log("array check", this.viewAttachedFiles, Array.isArray(this.viewAttachedFiles))
      this.viewAttachedFiles = this.viewAttachedFiles?.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any)  => obj?.Name === o?.Name)) {
          unique.push(o);
        }
        return unique;
      }, []);
      //console.log("this.viewAttachedFiles", Array.isArray(this.viewAttachedFiles), this.viewAttachedFiles)
      this.actionplanForm.controls['Action_plan_viewAttachedFiles'].setValue(this.viewAttachedFiles)
    }
    //this.data[0]['viewAttachedFiles']
    //when file uploaded successfull in S3
    if (FileData == 'ActionPlan_costCalcFileData' && group['IsFileUploaded'] == true) {
      this.actionplanForm.controls['Cost_Calculation_Worksheet_has_been_attached'].setValue(true)
    }
  }

  ngAfterViewChecked() {
    this.EnableSubmitBtn();
    this.EnableApprovalsSubmitBtn();
  }
  // Get the specific Teams persons for the Labfab Name
  // LabfabData(labfabname: any) {
  //   this.assetDetailsService.getLabFabDetailsbyId(labfabname.replace('/', '&#47;')).subscribe(data => {
  //     console.log("LABFAB data", data);
  //     if (data) {
  //       this.LabfabTeams = data[0];
  //     }
  //     this.change_ewaste_CyberSecurity();
  //   });
  // }

  LabfabData(labfabname: any) {
    this.common.getAPI('amd-get-labfabbyid/' + labfabname.replace('/', '&#47;')).subscribe((data: any) => {
      if (data) {
        this.LabfabTeams = data[0];
        this.change_ewaste_CyberSecurity();
      }
    });
  }

  onChanges() {
    this.actionplanForm.controls['approversEmailList'].valueChanges.subscribe(values => {
      //Disbale the submit Btn for Anylst review
      this.enableSubmit = true;
    });
  }
}
