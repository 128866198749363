import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-export-active-inactive-assets',
  templateUrl: './export-active-inactive-assets.component.html',
  styleUrls: ['./export-active-inactive-assets.component.scss']
})
export class ExportActiveInactiveAssetsComponent implements OnInit {
  paramsExport!: string;
  tableHeaderArray: string[] = ["Asset Active Listing", "Company Code", "Asset Number", "Original Asset Number", "Asset Class Code",
    "Asset Description", "Requester","LabFab Owner", "Serial Number", "LabFab Name",
    "Building", "Ship to Customer Name1", "Asset Capitalization Date", "Cost Center Code",
    "Cost Center Owner", "MRU Code", "Profit Center Code", "Cost Center Hier Desc Level1",
    "Asset Super Number", "Depreciation Terms Start Date", "Depreciation Terms Useful Life in Months",
    "Acquisition Value", "PE Net Book Value", "PE Depreciation For Posted Period", "Period Posted total Depreciation Including Value Adjustments",
    "WBS Element Maintenance Project", "INSERT DATETIME", "MODIFIED DATETIME", "LAST MODIFIED USER","Retirement Date","Retirement Type","Transfer/Retirement Status", "Change Type", "Change Status"]  

  constructor(private common: CommonService,private toastr: ToastrService) {
    this.common.bannerData.next({ title: 'Report', subtitle: 'Export Active Inactive Assets' });
  }

  ngOnInit() { }

  exportProp = {
    // title: 'Export Active Inactive Assets',
    subTitle: 'Please choose the options below',
    Data: [
      {
        type: 'radio',
        options: ['Active', 'Inactive', 'Active,Inactive'],
        value: null
      },
      {
        type: 'date',
        value: null
      }
    ]
  };

  submit(result:any) {
    if(result.Data[0].value != '' && result.Data[0].value != null || result.Data[1].value!=''  && result.Data[1].value!=null){
    // if (result != '' && result != null) {
      this.exportProp = result;
      console.log(this.exportProp, "exportProp result from dialog");
      
      if (this.exportProp.Data[0].value === 'Active' || this.exportProp.Data[0].value === 'Inactive' || this.exportProp.Data[0].value === 'Active,Inactive') {
        this.paramsExport = 'amd-export-active-inactive-assets?'+'searchKey=Asset Active Listing&searchValue='+(this.exportProp.Data[0].value)+'&';
      }

      if (this.exportProp.Data[1].value != '' && this.exportProp.Data[1].value != null){
        let modifiedDate = moment(this.exportProp.Data[1].value, 'MM/DD/YYYY').format('MM/DD/YYYY')
         this.paramsExport = 'amd-export-active-inactive-assets?'+'searchKey=ModifyDm&searchValue='+modifiedDate+'&';
         //searchKey=ModifyDm&searchValue=03/06/2023& 
       }

       if(this.paramsExport && this.paramsExport!='null'){
        setTimeout(() => {
          this.generateReport();
        }, 100);

      }
    } else{
      this.paramsExport = ''
      this.exportProp.Data[1].value = null
      this.exportProp.Data[0].value = null
      this.toastr.warning('Please fill atleast one field to continue', 'Warning');
    }
  }

  generateReport() {
    let triggerChildExportBtn:any = document.getElementsByClassName('MenuActivebtnID')[0];
    if (triggerChildExportBtn) {
      triggerChildExportBtn['click']();
      console.log("Triggered Exporting excel");
    }
  }

  childMessage($event: any) {
    if ($event) {
      this.reset(); 
    }
  }

  reset() { 
    this.exportProp.Data.forEach(item => {
      if (item.type === 'radio') {
        item.value = null; 
      } else if (item.type === 'date') {
        item.value = null; 
      }
    });
    this.paramsExport = ''; 
  }
}
