import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-tool-details',
  templateUrl: './tool-details.component.html',
  styleUrls: ['./tool-details.component.scss']
})

export class ToolDetailsComponent implements OnInit {

  @Input('tabData') TabData : any;
  //toolInfoForm!: FormGroup;
  tabSelected: any;
  tabSelected1: any;
  tabChange!: boolean;

  toolInfoForm: FormGroup = this.fb.group({
    Tool_Name:'',
      Tool_Description:'',
      Tool_Intent: '',
      Purpose: '',
      Tool_History: '',
      Tool_Condition: '',
      Build_Option: '',
      Initiate_Site: '',
      Initiate_Building: ''
    });
  constructor(
    private fb: FormBuilder, 
    private common: CommonService
  ) {this.common.bannerData.next({ title: 'Cap', subtitle: 'Tool Details'}); }

  ngOnInit(): void {

    this.common.tabDetailsData.subscribe({
      next: (data:any) => {
        this.tabChange = false;
        this.tabSelected = data;
      },
      error: (error:any) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });

    this.common.tabDetailsData1.subscribe({
      next: (data:any) => {
        this.tabChange = true;
        this.tabSelected1 = data;
      },
      error: (error:any) => {
        console.error("Error occurred:", error);
        alert("Please connect with Asset Admin");
      }
    });
  }

}
