<form [formGroup]="retirementForm" class="form-horizontal">

    <div class="row">
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-label>Asset Number</mat-label>
                <input matInput placeholder="Asset Number" formControlName="Main_Asset_Number" readonly>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="w-100">
                <mat-label>Status</mat-label>
                <input matInput placeholder="Status" formControlName="Change_Status" readonly>
            </mat-form-field>
        </div>
    </div>

    <!-- Basic Details -->
    <mat-card>
        <mat-toolbar color="primary" class="mb-3 custom-text-color">
            <span>Retirement</span>
        </mat-toolbar>        

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Retirement Type</mat-label>
                        <mat-select name="Retirement_Type" id="Retirement_Type" formControlName="Retirement_Type"
                            (change)="retirementType_Change()">
                            <mat-option *ngFor="let options of Retirement_Type_List"
                                value={{options}}>{{options}}</mat-option>
                        </mat-select>
                        <mat-hint *ngIf="submitted && retirementForm.controls['Retirement_Type'].errors">
                            <span *ngIf="retirementForm.controls['Retirement_Type'].errors['required']"
                                class="text-danger">
                                Retirement Type is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Retirement Status</mat-label>
                        <mat-select name="Retirement_Status" id="Retirement_Status" #requestStatus
                            formControlName="Retirement_Status" [attr.disabled]="userRole!='Admin'?true:null"
                            (change)="request_Status_change()">
                            <mat-option value='Draft'>Draft</mat-option>
                            <mat-option value='Pending Analyst Review'>Pending Analyst Review</mat-option>
                            <mat-option value='Back to Draft'>Back to Draft</mat-option>
                            <mat-option value='Approved'>Approved</mat-option>
                            <mat-option value='Cancel'>Cancel</mat-option>
                        </mat-select>
                        <mat-hint *ngIf="submitted && retirementForm.controls['Retirement_Type'].errors">
                            <span *ngIf="retirementForm.controls['Retirement_Type'].errors['required']"
                                class="text-danger">
                                Retirement Type is required
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-md-12" *ngIf="userRole === 'Admin'">
                    <mat-form-field class="w-100">
                        <mat-label>Comment</mat-label>
                        <textarea matInput formControlName="Admin_Comment"></textarea>
                      </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="form-group">
                        <input type="submit"
                            [disabled]="retirementForm.value=='' || userRole!='Admin' && (retirementForm.controls['Change_Status'].value=='Pending Analyst Review' || retirementForm.controls['Change_Status'].value=='Approved')"
                            class="btn btn-primary form-control" value="Submit"
                            (click)="onSubmit('SubmitBtn',retirementForm.controls['Main_Asset_Number'].value)" />
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <input type="button" class="btn btn-primary form-control" value="Back" (click)="close()" />
                    </div>
                </div>
            </div>
        </div>
    </mat-card>


    <div class="row">

        <div class="col-12">
            <!-- ADMIN  Section of reason comments for Back to Draft Starts -->
            <ng-container *ngIf="retirementForm.controls['Retirement_Status'].value=='Back to Draft'">
                <ng-container *ngIf="userRole=='Admin'">
                    <label class="radioLable">Select the reason for request to move back to draft <span class="redColor"
                            aria-required="true">*</span></label>
                    <div class="form-check">
                        <label class="form-check-label" *ngFor="let reason of reason_Back_to_Draft let i=index;">
                            <input type="radio" [value]="reason" class="checkBoxColor form-check-input"
                                formControlName="reason_Back_To_Draft_Status"
                                [ngClass]="{ 'is-invalid': submitted && retirementForm.controls['reason_Back_To_Draft_Status'].errors }">
                            {{reason}}

                            <div *ngIf="i==2 && submitted && retirementForm.controls['reason_Back_To_Draft_Status'].errors"
                                class="invalid-feedback">
                                <div *ngIf="retirementForm.controls['reason_Back_To_Draft_Status'].errors['required']">
                                    Please select the reason </div>
                            </div>
                        </label>

                    </div>
                </ng-container>
                <!-- ADMIN Section of reason comments for Back to Draft ENDS -->
            </ng-container>

            <!-- Requestor view comments for Back to Draft Starts -->

            <ng-container
                *ngIf="retirementForm.controls['reason_Back_To_Draft_Status'].value!='' && retirementForm.controls['reason_Back_To_Draft_Status'].value && retirementForm.controls['Retirement_Status'].value=='Draft'">
                <label class="radioLable">Your request to move the asset has been moved back to draft
                </label>
                <div class="form-check">
                    <label class="form-check-label">
                        <input type="radio" class="form-check-input" disabled>
                        {{retirementForm.controls['reason_Back_To_Draft_Status'].value}}
                    </label>
                </div>
            </ng-container>
            <!-- Requestor view comments for Back to Draft ENDS -->

            <!-- Cancel of the request STARTS-->
            <!-- ADMIN view comments for CANCEL Starts -->
            <ng-container *ngIf="retirementForm.controls['Retirement_Status'].value=='Cancel'">
                <ng-container *ngIf="userRole=='Admin'">
                    <label class="radioLable">Select the reason for request to Cancel <span class="redColor"
                            aria-required="true">*</span></label>
                    <div class="form-check">
                        <label class="form-check-label" *ngFor="let reason of reason_Cancel;let i=index;">
                            <input type="radio" [value]="reason" class="checkBoxColor form-check-input"
                                formControlName="action_plan_reason_Cancel_Status"
                                [ngClass]="{ 'is-invalid': retirementForm.controls['action_plan_reason_Cancel_Status'].errors }">
                            {{reason}}
                            <div *ngIf="i==1 && submitted && retirementForm.controls['action_plan_reason_Cancel_Status'].errors"
                                class="invalid-feedback">
                                <div
                                    *ngIf="retirementForm.controls['action_plan_reason_Cancel_Status'].errors['required']">
                                    Please select the reason
                                </div>
                            </div>
                        </label>

                    </div>
                </ng-container>
                <!-- ADMIN view comments for CANCEL ENDS -->
            </ng-container>

            <!-- Requestor view comments for CANCEL Starts -->
            <ng-container
                *ngIf="retirementForm.controls['action_plan_reason_Cancel_Status'].value!='' && retirementForm.controls['action_plan_reason_Cancel_Status'].value && retirementForm.controls['Change_Status'].value=='Cancel'">
                <label class="radioLable">Your request to move the asset has been cancelled</label>
                <div class="form-check">
                    <label class="form-check-label">
                        <input type="radio" class="form-check-input" disabled>
                        {{retirementForm.controls['action_plan_reason_Cancel_Status'].value}}
                    </label>
                </div>
            </ng-container>
            <!-- Requestor view comments for CANCEL ENDS -->
            <!-- Cancel of the request ENDS-->
        </div>
    </div>
    <div class="col actionPlanLink" *ngIf="requestStatus.value=='Draft'">
        <button (click)="edit(retirementForm.controls['Main_Asset_Number'].value)" value="Action Plan">
            <i class="fa fa-link"></i>
            Asset Change Action Plan</button>
        <div class="spinLoader" *ngIf="actionPlanClicked">
            <i class="fa fa-spinner fa-spin"></i>
            <span>Redirecting to Action Plan...</span>
        </div>
    </div>
    <div class="spinLoader" *ngIf="submitLoading">
        <i class="fa fa-spinner fa-spin"></i>
        <span>Submitting the request...</span>
    </div>


</form>