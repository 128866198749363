import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationRoutingModule } from './organization-routing.module';
import { OrganizationComponent } from './organization.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { MaterialModule } from 'src/app/modules/material.module';
import { EditOrganizationComponent } from './edit-organization/edit-organization.component';

@NgModule({
  declarations: [
    OrganizationComponent,
    EditOrganizationComponent
  ],
  imports: [
    CommonModule,
    OrganizationRoutingModule,
    SharedModule,
    MaterialModule
  ]
})
export class OrganizationModule { }
