import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { CommonService } from 'src/app/services/common.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-export-s4',
  templateUrl: './export-s4.component.html',
  styleUrls: ['./export-s4.component.css']
})
export class ExportS4Component implements OnInit {
  assetdetails1: FormGroup = this.fb.group({
    Modified_Date: ['']
  });
  formControlDate = new FormControl();
  tableHeaderArray: any[] = [];
  maxDate!: Date;
  excelSummary!: Array<any>;
  loading = false;

  constructor(private common: CommonService, private fb: FormBuilder,
    private dialogRef: MatDialogRef<ExportS4Component>) { }

  ngOnInit() {
    this.maxDate = new Date();

    this.excelSummary = [
      { Report_Name: 'Asset Valuation - Foundation' }

    ]
  }
  public onChangeModifiedDate(event: any): void {
    this.assetdetails1.controls['Modified_Date'].setValue(moment(event.value).format('MM/DD/YYYY HH:mm').toString());
  }
  onSubmit() {
    this.common.loader.next(true);
    const data = { "Modified_Date": this.assetdetails1.controls['Modified_Date'].value };
    this.common.postAPI('amd-get-asset-modified/', data).subscribe({
      next: async (data: any) => {
        if (data && data.length > 0 ) {
          let columnOrder = ["Sr.No.", "Company Code", "Asset Class", "Asset No.",
            "No of similar assets", "Reference-Asset", "Reference-Asset sub number", "Reference-Asset company code", "Post Capitalization",
            "Asset Description 1", "Asset Description 2", "Serial No", "Inventory Number",
            "Quantity", "Quantity Unit", "Manage Historically", "Last Inventory On", "Include asset in inventory list",
            "Inventory Note", "Capitalized On", "Deactivation On", "Planned Retirement On",
            "Business Area", "Cost Center", "Activity Type", "Internal Order", "Personnel number", "Maintenance Order", "Plant",
            "Location", "Room", "Shift Factor", "Asset Shutdown", "Evaluation Group1",
            "Evaluation Group2", "Evaluation Group3", "Evaluation Group4", "Investment Reason", "Asset Super Number",
            "Vendor", "Manufacture", "Asset Purchased New", "Purchased Used", "Trading Partner", "Country of Origin",
            "Type Name", "License Plate", "WBS element", "OriginalAsset", "Capitalised on", "Original Value",
            "In-House Production Percentage", "Classification Key", "Property Indicator", "Manual Valuation Reason",
            "Manual Net Worth Value", "Leasing company", "Agreement Number", "Agreement Date", "Notice date", "Lease Start Date",
            "Lease Length", "Periods", "Type", "Base value as new", "Purchase Price", "Supplementary text", "No. lease payments",
            "Payment Cycle", "Present Value", "Lease Payment", "Annual Int. Rate", "Dep start date (01)", "Dep Area 01 Dep Key",
            "Dep Area 01 Useful Life", "Dep Area 01 Period", "Dep start date (31)", "Dep Area 31 Dep Key", "Dep Area 31 Useful Life",
            "Dep Area 31 Period", "Dep start date (40)", "Dep Area 40 Dep Key", "Dep Area 40 Useful Life", "Dep Area 40 Period",
            "Link Lease asset to Equpment", "Accounting Principle", "Depreciation Area", "Document Date", "Posting Date",
            "Value Date", "Amount", "Quantity", "Unit", "Text", "Transaction Type", "Trading Partner", "Posting Period",
            "Document Type", "Partner Business area", "Reference", "Assignment", "Amount Dep 01 (LC)", "Amount Dep 02 (GC)",
            "Amount Dep 66", "Amount Dep 31", "Amount Dep 32", "Amount Dep 40", "Amount Dep 41"];
  
          let amdColumns = ["Company Code", "Asset No.", "Asset Description 1", "Asset Description 2", "Serial No",
            "Inventory Note", "Location"]
          let workbook = new Excel.Workbook();
  
          let worksheet = workbook.addWorksheet('Asset Creation Templ');
  
  
          let titleValues1 = [""];
          let titleValues2 = [""];
  
          worksheet.spliceRows(1, 0, titleValues1, titleValues2);
  
          for (let x1 of data) {
            let x2 = columnOrder;
            let temp = []
            for (let y of x2) {
              if (y != "Text") {
                temp.push(x1[y])
              }
            }
            worksheet.addRow(temp)
          }
  
  
          worksheet.getRow(2).height = 35;
          worksheet.mergeCells('A1');
          let titleRow = worksheet.getCell('A1');
          titleRow.value = 'ASSET MASTER CREATION AND UPDATION (AS01/AS02)'
          titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
          worksheet.getCell('CG1').value = 'Asset Capitalization(ABZON)';
          worksheet.getCell('CF1').value = 'Equipment Synchronization';
          worksheet.getCell('B2').value = 'Company Code';
          worksheet.getCell('C2').value = 'Asset Class Code';
          worksheet.getCell('D2').value = 'Main Asset Number';
          worksheet.getCell('I2').value = 'General Data';
          worksheet.getCell('J2').value = 'Asset Description';
          worksheet.getCell('K2').value = 'Additional Description';
          worksheet.getCell('L2').value = 'Serial Number';
          worksheet.getCell('S2').value = 'Asset Supplementary Inventory Specifications Name';
          worksheet.getCell('V2').value = 'Time dependent data';
          worksheet.getCell('AD2').value = 'Asset Location';
          worksheet.getCell('AG2').value = 'Allocations/Assignment';
          worksheet.getCell('AM2').value = 'Origin';
          worksheet.getCell('BD2').value = 'Leasing';
          worksheet.getCell('BT2').value = 'Depreciation Areas';
          worksheet.getCell('CW2').value = 'Line Item Details';
  
  
          ['B2', 'C2', 'D2', 'J2', 'K2', 'L2', 'M2', 'N2', 'O2', 'P2', 'Q2'
            , 'R2', 'S2', 'T2', 'U2', 'V2', 'W2', 'X2', 'Y2', 'AA2', 'AB2', 'AC2', 'AD2', 'Z2'].map(key => {
              worksheet.getCell(key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '007bff' },
  
              };
            });
          ['B2', 'C2', 'D2', 'J2', 'K2', 'L2', 'M2', 'N2', 'O2', 'P2', 'Q2'
            , 'R2', 'S2', 'T2', 'U2', 'V2', 'W2', 'X2', 'Y2', 'AA2', 'AB2', 'AC2', 'AD2', 'Z2'].map(key => {
              worksheet.getCell(key).font = {
                name: 'HP Simplified',
                size: 9,
                bold: true,
                color: { argb: 'ffffff' }
              };
            });
          ['B3', 'D3', 'J3', 'K3', 'L3', 'AD3'].map(key => {
            worksheet.getCell(key).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'ffff00' },
  
            };
          });
          ['S3'].map(key => {
            worksheet.getCell(key).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'ffa500' },
  
            };
          });
  
          workbook.xlsx.writeBuffer().then((buffer) => {
            let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'Fixed Asset Basic Changes.xlsx');
          });
  
  
          this.common.loader.next(false);
        }
        else {
          this.common.loader.next(false);
          this.common.show('warning', data.errorMessage ? data.errorMessage.replace(/\%/g,"") : "No recently modified assets");
          this.loading = false
        }
      },
      error:
      (error) =>{
        this.common.loader.next(false);
        this.common.show('error', error.error.message);
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

}
