import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from 'src/app/modules/material.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { CapRoutingModule } from './cap-routing.module';
import { CapComponent } from './cap.component';
import { CapDetailComponent } from './cap-detail/cap-detail.component';
import { CommonCapInfoComponent } from './common-cap-info/common-cap-info.component';
import { ToolDetailsComponent } from './tool-details/tool-details.component';
import { AssessmentAddLabelsComponent } from './assessment-add-labels/assessment-add-labels.component';
import { InitiateCapRequestComponent } from './initiate-cap-request/initiate-cap-request.component';
import { CommonAssessmentFormComponent } from './common-assessment-form/common-assessment-form.component';
import { AssessmentFormComponent } from './assessment-form-functionality/assessment-form-functionality';
import { StakeholderAssessmentComponent } from './stakeholder-assessment/stakeholder-assessment.component';
import { StakeHolderReviewComponent } from './stake-holder-review/stake-holder-review.component';
import { CapActionPlanComponent } from './cap-action-plan/cap-action-plan.component';

@NgModule({
  declarations: [
    CapComponent,
    CapActionPlanComponent,
    CommonAssessmentFormComponent,
    AssessmentFormComponent,
    CapDetailComponent,
    CommonCapInfoComponent,
    ToolDetailsComponent,
    AssessmentAddLabelsComponent,
    InitiateCapRequestComponent,
    StakeholderAssessmentComponent,
    StakeHolderReviewComponent
  ],
  imports: [
    CommonModule,
    CapRoutingModule,
    MaterialModule,
    SharedModule,
  ],
  providers: [DatePipe],
})
export class CapModule { }
