import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportComponent } from './report.component';
import { ComparisonReportComponent } from './comparison-report/comparison-report.component';
import { LtpPorReportComponent } from './ltp-por-report/ltp-por-report.component';
import { LabfabMismatchComponent } from './labfab-mismatch/labfab-mismatch.component';
import { ExportActiveInactiveAssetsComponent } from './export-active-inactive-assets/export-active-inactive-assets.component';
import { RetiredReclassifiedAssetsComponent } from './retired-reclassified-assets/retired-reclassified-assets.component';


const routes: Routes = [
  {
    path: '', component: ReportComponent,
    children: [
      { path: '', redirectTo: 'comparisonreport', pathMatch: 'full' },
      { path: 'comparisonreport', component: ComparisonReportComponent },
      { path: 'ltpporreport', component: LtpPorReportComponent },
      { path: 'labfab-mismatch', component: LabfabMismatchComponent },
      { path: 'Export-Active-Inactive-Assets', component:ExportActiveInactiveAssetsComponent},
      { path: 'All-Retired-and-Reclassified-Assets', component: RetiredReclassifiedAssetsComponent}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
