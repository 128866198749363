import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'wbs-cashflow-summary',
  templateUrl: './wbs-cashflow-summary.component.html',
  styleUrls: ['./wbs-cashflow-summary.component.scss']
})
export class WbsCashFlowComponent {
  wbsCashFlowForm: FormGroup = this.fb.group({
    estimatedTotalCost: [{value: '', disabled: true}],
    currentWbsAmount: [{value: '', disabled: true}],
    totalReconcillation: [''],
    approvedPoCommits: [''],
    totalInvoicedPo: [''],
    totalAmountJvs: [''],
    TotalCashFlow: [''],
    estimatedTotalSpend: [''],
    estimatedRemainingSpend: [''],
    monthsStatic: [''],
    engrNreCost: [{value: '', disabled: true}],
    techNreCost: [{value: '', disabled: true}],
    totalNreCost: [{value: '', disabled: true}],
    engrNreBilled: [''],
    techNreBilled: [''],
    totalNreBilled: [''],
    remainingNre: [''],
    hookupCapitalized: [''],
    hookupBiiled: [''],
    remainingHookup: [''],
  });

  userRole = this.common.getUserRole();
  isRequester: boolean = this.userRole === 'Requester'? true : false;


  constructor(private common: CommonService, private fb: FormBuilder, 
    private router: Router, private toastr: ToastrService, private dialog: MatDialog) {
    }

    capexFormData: any;

    ngOnInit(){
      if(this.isRequester){
        this.wbsCashFlowForm.disable();
      }
    }

    accessWbsCashFlow(data: any){
      this.capexFormData = data;
      console.log(data);
      let totalCost = Number(this.capexFormData?.projectCostDetails?.itemCost) + Number(this.capexFormData?.projectCostDetails?.hookCapitalCost);
      let engrCost = Number(this.capexFormData?.projectCostDetails?.engrNRECost);
      let techCost = Number(this.capexFormData?.projectCostDetails?.techNRECost);
      let totalNre = engrCost + techCost;
      this.wbsCashFlowForm.controls['estimatedTotalCost']?.setValue(totalCost, { emitEvent: false });
      this.wbsCashFlowForm.controls['engrNreCost']?.setValue(engrCost, { emitEvent: false });
      this.wbsCashFlowForm.controls['techNreCost']?.setValue(techCost, { emitEvent: false });
      this.wbsCashFlowForm.controls['totalNreCost']?.setValue(totalNre, { emitEvent: false });
      this.wbsCashFlowForm.controls['currentWbsAmount']?.setValue(totalCost, { emitEvent: false });

      

    }

}