<div class="row" *ngIf="isadmin">
    <div class="col-12 text-center">
        <button class="btn btn-primary mr-2 mb-2" (click)="submitNewRequest()">
            <i class="fa fa-paper-plane"></i>Submit New LTP/POR
        </button>

        <!-- <button class="btn btn-primary mr-2 mb-2" (click)="convertRecords()">
            <i class="fa fa-exchange"></i>Convert LTP to POR
        </button> -->
    </div>
</div>
<hr>

<mat-card class="my-3 mat-elevation-z3 p-3">
    <form [formGroup]="searchRecords">
        <div class="row">
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Request Type</mat-label>
                    <mat-select formControlName="requestType" (selectionChange)="onRequestOptionSelected($event.value)">
                        <mat-option *ngFor="let option of requestTypeOptions" [value]="option"
                        >{{option}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                        <mat-option *ngFor="let option of this.status" [value]="option"
                        >{{option}}</mat-option>
                        <mat-option *ngIf="this.status.length === 0">
                            No options available
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100 autocomplete autoComplete-full-width" appearance="fill">
                    <mat-label>LTP/POR Number</mat-label>
                    <input type="text" matInput formControlName="ltpPorNumber">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Planner</mat-label>
                    <!-- <input type="text" matInput formControlName="planner" placeholder="" value="" (input)="valueChange('planner')"> -->
                    <mat-select formControlName="planner" multiple>
                        <mat-option *ngFor="let option of plannerOptions" [value]="option">
                          {{option}}
                        </mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Section</mat-label>
                    <mat-select formControlName="section" multiple>
                        <mat-option *ngFor="let option of sectionOptions" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Requesting Site</mat-label>
                    <!-- <input type="text" matInput formControlName="requestingSite" placeholder="" value="" (input)="valueChange('requestingSite')"> -->
                    <mat-select formControlName="requestingSite">
                        <mat-option *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-option>
                        <mat-option *ngFor="let option of allSites" [value]="option">{{option}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>      
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Project Manager</mat-label>
                    <input type="text" matInput formControlName="projectManager" placeholder="" value="" (input)="valueChange('projectManager')">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Commit Manager</mat-label>
                    <input type="text" matInput formControlName="commitManager" placeholder="" value="" (input)="valueChange('commitManager')">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Depreciation Manager</mat-label>
                    <input type="text" matInput formControlName="depreciationManager" placeholder="" value="" (input)="valueChange('depreciationManager')">
                </mat-form-field>
            </div>       
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field class="w-100">
                    <mat-label>Commit Quarter</mat-label>
                    <input type="text" matInput formControlName="commitQuarter" placeholder="" value="" (input)="valueChange('commitQuarter')">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Request Date</mat-label>
                    <input matInput [matDatepicker] = "picker" formControlName="requestDate" (input)="valueChange('requestDate')">
                    <mat-datepicker-toggle matSuffix [for]="picker" (input)="valueChange('requestDate')"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>           
        </div>
            <div class="col-12">
                <div class="center-align">
                    <button mat-raised-button class="itg-gradient font-size" (click)="onSearch()">Search</button>
                    <!-- <div *ngIf="loading" style="margin-left: 10px;">
                      <mat-spinner diameter="20"></mat-spinner>
                    </div> -->
                  </div>
            </div>
    </form>
</mat-card>

<!-- show table on search click -->
<div class="col-12 btnAlign">
    <div *ngIf = "this.searchClicked" class="mR-1">
        <button mat-raised-button class="itg-gradient font-size" (click)="collapse()">Collapse All</button>
    </div>
    <div  *ngIf = true>
        <button mat-raised-button class="itg-gradient font-size">Export</button>
    </div>
</div>

<div *ngIf="searchClicked" class="mat-elevation-z8 addScroll">
    <table mat-table [dataSource]="dataSource" class="custom-table">

        <!-- Dynamic Columns -->
        <ng-container *ngFor="let column of columnHeaders" [matColumnDef]="column.header">
            <th mat-header-cell *matHeaderCellDef class="text-white">
                <div *ngIf="!column.header.startsWith('hide')" class="header-container">
                    <span>{{ column.header }}</span>
                    <button 
                        mat-icon-button 
                        *ngIf="column.isExtendable" 
                        (click)="toggleColumn1(column.header)">
                        <mat-icon class="icon-size">{{ expandedColumns[column.header] ? 'remove' : 'add' }}</mat-icon>
                    </button>
                </div>
                <div class="table-header">
                    <ng-container *ngIf="column.expandColumns.length">
                        <span class="expand-column">{{ column.expandColumns[0] }}</span>
                        <ng-container *ngIf="expandedColumns[column.header]">
                            <ng-container *ngFor="let expandColumn of column.expandColumns.slice(1)">
                                <span class="expand-column">{{ expandColumn }}</span>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </th>

            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="column.header === 'Action'">
                    <button mat-icon-button [matMenuTriggerFor]="rowMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #rowMenu="matMenu">
                        <ng-template matMenuContent>
                            <div class="btn text-center text-danger w-100">
                                <i class="fa fa-laptop" aria-hidden="true"></i> {{ element['LTP#'] }}
                            </div>
                            <div>
                                <button mat-menu-item (click)="recordHistory(element)">History</button>
                                <button mat-menu-item (click)="recordHistory(element)">Edit</button>
                                <button mat-menu-item (click)="recordHistory(element)">View</button>
                                <button *ngIf="element['LTP Status'] === 'In Queue'" mat-menu-item (click)="convertRecordsLTPtoPROR(element)">Convert LTP to POR</button>
                            </div>
                        </ng-template>
                    </mat-menu>
                </ng-container>

                <ng-container *ngIf="column.isExtendable">
                    <!-- Always show the first extended column's value -->
                    <span class="expand-column adjustRowData">
                        {{ element[column.expandColumns[0]] }}
                    </span>
                    
                    <ng-container *ngIf="expandedColumns[column.header]">
                        <ng-container *ngFor="let subColumn of column.expandColumns.slice(1)">
                            <span class="expand-column adjustRowData">{{ element[subColumn] }}</span>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="!column.isExtendable">
                    <!-- Display non-extendable column values -->
                    <span class="expand-column adjustRowData">{{ element[column.header] }}</span>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnNames" class="bg-primary headerAtTop"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
    </table>

    <div *ngIf="dataSource.length === 0" class="no-records-message">
        No CAPEX records to display
    </div>
</div>



  
  


  
  
  
<!-- end -->

<!-- <div *ngIf = "this.searchClicked" class="mat-elevation-z8 addScroll">
    <table mat-table [dataSource]="dataSource" class="custom-table">
        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
            <ng-container *ngIf="column === 'headerCheckbox'">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox class="checkbox-bg-color"(change)="toggleSelectAll($event.checked)"></mat-checkbox>
                </th>           
            </ng-container>
            <ng-container *ngIf="column !== 'headerCheckbox'">
                <th mat-header-cell *matHeaderCellDef class="text-white">
                    <ng-container *ngIf="columnMapping[column]">
                        <div class="table-header">
                            <span>{{ column }}</span>
                        <button mat-icon-button (click)="onAddButtonClick(column)">
                            <mat-icon class="icon-size" *ngIf="displayedColumns.includes(columnMapping[column][0]); else plusIcon">remove</mat-icon>
                            <ng-template #plusIcon><mat-icon class="icon-size">add</mat-icon></ng-template>
                        </button>
                        </div>
                    </ng-container>

                    <div [ngClass]="{'mT-40': !columnMapping[column] }" class="table-header">{{ column }}</div>
                </th>
            </ng-container>
            
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="column === 'headerCheckbox'">
                    <mat-checkbox [(ngModel)]="element.selected" (change)="toggleRowSelection(element, $event.checked)"></mat-checkbox>
                    <button mat-icon-button [matMenuTriggerFor]="rowMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #rowMenu="matMenu">
                        <ng-template matMenuContent>
                            <div class="btn text-center text-danger w-100"><i class="fa fa-laptop" aria-hidden="true"></i>
                                {{element['LTP#']}}</div>
                            <div>
                                <button mat-menu-item class="btn bg-primary text-white" (click)="recordHistory(element)">History</button>
                            </div>
                        </ng-template>
                    </mat-menu>
                </ng-container>
                <ng-container *ngIf="column !== 'headerCheckbox'">
                    {{element[column]}}
                </ng-container>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="this.displayedColumns" class="bg-primary headerAtTop"></tr>
        <tr mat-row *matRowDef="let row; columns: this.displayedColumns"></tr>        
    </table>
    <div *ngIf="dataSource.length === 0" style="padding:10px;">No CAPEX records to display</div>
</div> -->

