import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'src/app/services/common.service';
import { ExternalData } from './externalTableDialog';

@Component({
  selector: 'app-external-table-dialog',
  templateUrl: './external-table-dialog.component.html',
  styleUrls: ['./external-table-dialog.component.scss']
})
export class ExternalTableDialogComponent {
  loading = true;
  tableDetailsArray: any = [];
  displayedColumns: string[] = ["UserName", "Action"];
  ELEMENT_DATA: ExternalData[] = [];
  dataSource = new MatTableDataSource<ExternalData>(this.ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  editedRows: any[] = [];
  userVerifyLoading = false;
  userVerify = false;
  userInvalid = false;
  submitEnable = false;
  selectOptions: any;

  constructor(
    private common: CommonService,
    private dialogRef: MatDialogRef<ExternalTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getTableData();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    if (this.dataSource) {
      this.dataSource.filter = filterValue;
    }
  }

  getTableData() {
    this.loading = true;
    this.common.getCapexAPI('ltp-por/external-funding').subscribe({
      next: (data) => {
        this.selectOptions = data;
        console.log("options", this.selectOptions);
        this.loading = false;

        this.tableDetailsArray = data.map((item: any) => {
          return {
            UserName: item['UserName'],
            Active: item['Active']
          };
        });

        this.ELEMENT_DATA = this.tableDetailsArray;
        this.dataSource.data = this.ELEMENT_DATA;
        if (this.paginator) {
          this.dataSource.paginator = this.paginator;
        }
        if (this.sort) {
          this.dataSource.sort = this.sort;
        }
      },
      error: (err) => {
        this.loading = false;
        console.error("Error occurred:", err);
      }
    });
  }

  edit(element: any) {
    element.editing = !element.editing;
  }

  editField(element: any, fieldName: any) {
    console.log(element,fieldName);
    this.submitEnable = true;
    const index = this.editedRows.findIndex((x: any) => x.UserName === element.UserName);

    if (element.UserName) {
      if (index === -1) {
        this.editedRows.push(element);
      } else {
        this.editedRows[index] = element;
      }
    } else {
      this.editedRows = this.editedRows.filter(row => row.UserName);
    }
  }

  delete(row: any) {
    // Implement delete logic if needed
  }

  onSubmit() {
    if (this.editedRows.length === 0) {
      this.common.show('error', 'Please enter row data before submitting.');
    } else if (this.editedRows.some(row => row.userVerified === false)) {
      this.common.show('error', 'Some users have not been verified. Please verify before submitting.');
    } else {
      this.common.postCapexAPI('ltp-por/external-funding', this.selectOptions).subscribe({
        next: (data: any) => {
          this.common.show('success', data.message);
          this.loading = false;
          this.dialogRef.close();
        },
        error: (err) => {
          this.loading = false;
          console.error("Error occurred:", err);
        }
      });
    }
  }

  addRow() {
    const newRow = {
      UserName: '',
      editing: true,
      Active: true
    };
    this.selectOptions.push(newRow);
    this.tableDetailsArray.push(newRow);
    this.ELEMENT_DATA = this.tableDetailsArray;
    this.dataSource.data = this.ELEMENT_DATA;
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  deactivate(element: any, buttonName: any) {
    if (buttonName === "deactivate") {
      element.Active = false;
    } else {
      element.Active = true;
    }
    const index = this.editedRows.findIndex((x: any) => x.UserName === element.UserName);
    if (index !== -1) {
      this.editedRows[index] = element;
    } else {
      this.editedRows.push(element);
    }
    this.onSubmit();
  }
}
