import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-stakeholder',
  templateUrl: './stakeholder.component.html',
  styleUrls: ['./stakeholder.component.scss']
})
export class StakeholderComponent {
  constructor(private common: CommonService, private activatedRoute: ActivatedRoute) {
    this.common.currentModule.next(this.activatedRoute.snapshot.data);
    this.common.bannerData.next({ title: 'AMD', subtitle: 'CAP Stakeholders'});
  }
}
