import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'wbs-cashflow-details',
  templateUrl: './wbs-cashflow-details.component.html',
  styleUrls: ['./wbs-cashflow-details.component.scss'],
})
export class WbsCashflowDetailsComponent implements OnInit {
  wbsCashFlowDetailsForm: FormGroup;
  PurchaseOrdersHeaders: string[] = [];
  JournalVouchersHeaders: string[] = [];
  InvoicesHeaders: string[] = [];
  purchaseOrders: any[] = [];
  JournalVouchers: any[] = [];
  Invoices: any[] = [];
  purchaseOrderEditableColumns: string[] = [];
  invoicesEditableColumns: string[] = [];
  journalVoucherEditableColumns: string[] = [];
  capexFormData: any;
  FetchedcapexFormData: any;
  wbs: any;
  totalPOAmount: number = 0;
  totalInvoicedPOAmount: number = 0;
  totalJVAmount: number = 0;
  totalcashflow: number = 0;
  ServiceTypeOptions: string[] = ['Cost Estimate', 'Design', 'Inspection/Permits', 'Install', 'Move/Demo', 'PM Fee', 'Tool Cost', 'Other'];

  userRole: string;
  isRequester: boolean = false;

  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private currencyPipe: CurrencyPipe 
  ) {
    this.wbsCashFlowDetailsForm = this.fb.group({
      purchaseOrders: this.fb.array([]),
      journalVouchers: this.fb.array([]),
      invoices: this.fb.array([]),
    });

    this.userRole = this.common.getUserRole();
    this.isRequester = this.userRole === 'Requester';
  }

  ngOnInit() {
    if (this.isRequester) {
      this.wbsCashFlowDetailsForm.disable();
    }

    this.purchaseOrdersFormArray.valueChanges.subscribe(() => {
      this.recalculateTotalPOAmount();
      this.addNewRow('purchaseOrder');
    });

    this.invoicesFormArray.valueChanges.subscribe(() => {
      this.recalculateTotalInvoicedPOAmount();
      this.addNewRow('invoice');
    });

    this.journalVouchersFormArray.valueChanges.subscribe(() => {
      this.calulatetotalJV();
    });
  }

  get purchaseOrdersFormArray(): FormArray {
    return this.wbsCashFlowDetailsForm.get('purchaseOrders') as FormArray;
  }

  get journalVouchersFormArray(): FormArray {
    return this.wbsCashFlowDetailsForm.get('journalVouchers') as FormArray;
  }

  get invoicesFormArray(): FormArray {
    return this.wbsCashFlowDetailsForm.get('invoices') as FormArray;
  }

  accessCashFlowDetails(data: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.common.loader.next(true);
      this.capexFormData = data;
      this.wbs = this.capexFormData?.wbsDetailsForm?.value?.wbs;
  
      if (this.userRole === 'Planner' && data.wbsDetailsForm.value.wbsStatus === 'Closed') {
        this.wbsCashFlowDetailsForm.disable();
      }
  
      if (this.wbs) {
        this.common
          .getCapexAPI('ltp-por/get-wbs-amount-from-reports?wbs=' + this.wbs)
          .subscribe({
            next: (data) => {
              this.populatePurchaseOrders(data.purchase_orders);
              this.populateJournalVouchers(data.journal_vouchers);
              this.populateInvoices(data.invoices);
  
              if (this.FetchedcapexFormData) {
                this.FetchedcapexFormData.WBS_Cash_Flow_Details.purchaseOrders.forEach((po:any, index:any) => {
                    if (this.purchaseOrdersFormArray.at(index)) {
                        this.purchaseOrdersFormArray.at(index).patchValue(po);
                    }
                });
                
                this.FetchedcapexFormData.WBS_Cash_Flow_Details.invoices.forEach((po:any, index:any) => {
                  if (this.invoicesFormArray.at(index)) {
                      this.invoicesFormArray.at(index).patchValue(po);
                  }
              });
              }
            },
            error: (err) => {
              console.error('Error occurred:', err);
              reject(err);
            },
            complete: () => {
              this.common.loader.next(false);
              resolve();
          }
          });
      }
      else {
        this.common.loader.next(false); 
        resolve();
    }
    });
  }

  
  accessCashFlowDetails1(data: any): void {
    this.common.loader.next(true);
    this.capexFormData = data;
    this.wbs = this.capexFormData?.wbsDetailsForm?.value?.wbs;

    if (this.userRole === 'Planner' && data.wbsDetailsForm.value.wbsStatus === 'Closed') {
      this.wbsCashFlowDetailsForm.disable();
    }

    if (this.wbs) {
      this.common
        .getCapexAPI('ltp-por/get-wbs-amount-from-reports?wbs=' + this.wbs)
        .subscribe({
          next: (data) => {
            this.populatePurchaseOrders(data.purchase_orders);
            this.populateJournalVouchers(data.journal_vouchers);
            this.populateInvoices(data.invoices);

            if (this.FetchedcapexFormData) {
              this.FetchedcapexFormData.WBS_Cash_Flow_Details.purchaseOrders.forEach((po:any, index:any) => {
                  if (this.purchaseOrdersFormArray.at(index)) {
                      this.purchaseOrdersFormArray.at(index).patchValue(po);
                  }
              });
              
              this.FetchedcapexFormData.WBS_Cash_Flow_Details.invoices.forEach((po:any, index:any) => {
                if (this.invoicesFormArray.at(index)) {
                    this.invoicesFormArray.at(index).patchValue(po);
                }
            });
            }
          },
          error: (err) => {
            console.error('Error occurred:', err);
          },
          complete: () => {
            this.common.loader.next(false);
        }
        });
    }
    else {
      this.common.loader.next(false); 
  }
  }

  updateTotalPOAmount() {
    this.totalPOAmount = 0;
  
    this.purchaseOrdersFormArray.controls.forEach((control) => {
      const poAmount = parseFloat(control.get('PO Amoun($)')?.value?.replace(/[^0-9.-]+/g, '') || '0');
      const poAmountEarlyEntry = parseFloat(control.get('PO Amount($)[Early Entry]')?.value?.replace(/[^0-9.-]+/g, '') || '0');
  
      this.totalPOAmount += poAmount + poAmountEarlyEntry;
    });
  
    //this.common.updateTotalPOAmount(this.totalPOAmount);
    console.log('Updated Total PO Amount:', this.totalPOAmount);
  }
  

  populatePurchaseOrders(PurchaseOrders: any[]) {
    const columnMapping: { [key: string]: string } = {
      'PO Num': 'po_num',
      'Req Num': 'req_num',
      'PO Date': 'po_date',
      'Vendor': 'vendor',
      'PO Amoun($)': 'po_amount',
      'Invoiced Amount($)': 'invoiced_amount',
      'PO Num[Early Entry]': 'po_num_early_entry',
      'Req Num[Early Entry]': 'req_num_early_entry',
      'PO date[Early Entry]': 'po_date_early_entry',
      'Vendor[Early Entry]': 'vendor_early_entry',
      'PO Amount($)[Early Entry]': 'po_amount_early_entry',
    };
  
    this.PurchaseOrdersHeaders = Object.keys(columnMapping); 
    this.purchaseOrdersFormArray.clear(); 

    
    if (PurchaseOrders && PurchaseOrders.length > 0) { 
      PurchaseOrders.forEach((po) => {
        console.log('Processing PO:', po); 
        const poFormGroup = this.fb.group({}); 
  
        for (const header of this.PurchaseOrdersHeaders) {
          const dataKey = columnMapping[header];
         // poFormGroup.addControl(header, new FormControl(po[dataKey] !== undefined ? po[dataKey] : '')); 
        //  const value = po[dataKey] !== undefined ? po[dataKey] : '';
        let value = po[dataKey] !== undefined ? po[dataKey] : '';

         if (header === 'PO date[Early Entry]' && value) {
          value = new Date(value).toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
        }
        // if (header.includes('PO date[Early Entry]')) { 
        //   value = value ? new Date(value).toISOString().split('T')[0] : ''; // Convert to YYYY-MM-DD
        // }
      

      //   this.updateTotalPOAmount(dataKey, value);

          if (header.includes('$')) {
            const formattedValue = this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2');
            poFormGroup.addControl(header, new FormControl(formattedValue));
          } else {
            poFormGroup.addControl(header, new FormControl(value));
          }
        }
        this.purchaseOrdersFormArray.push(poFormGroup); 
      });

      // Recalculate Total PO Amount after populating data
       // this.updateTotalPOAmount();
    }
     // Add an empty row
      const emptyFormGroup = this.fb.group({
        isNewRow: new FormControl(true)
      } as { [key: string]: AbstractControl
      });
      for (const header of this.PurchaseOrdersHeaders) {
        emptyFormGroup.addControl(header, new FormControl(''));
      }
      this.purchaseOrdersFormArray.push(emptyFormGroup);
  
    console.log('Form array after population:', this.purchaseOrdersFormArray.value); 

      this.purchaseOrderEditableColumns = [
      'PO Num[Early Entry]',
      'Req Num[Early Entry]',
      'PO date[Early Entry]',
      'Vendor[Early Entry]',
      'PO Amount($)[Early Entry]',
    ];
  }

  populateJournalVouchers(JournalVouchers: any[]) {
    const columnMapping: { [key: string]: string } = {
      'Rec Type': 'rec_type',
      'Posting Date': 'posting_date',
      'Doc Num': 'doc_num',
      'Doc Item': 'doc_item',
      'SIC Doc Num': 'src_doc_num',
      'Description': 'description',
      'Total($)': 'total',
    };
  
    this.JournalVouchersHeaders = Object.keys(columnMapping); 
    this.journalVouchersFormArray.clear(); 
  
    if (JournalVouchers && JournalVouchers.length > 0) { 
      JournalVouchers.forEach((jv) => {
        console.log('Processing JV:', jv); 
        const jvFormGroup = this.fb.group({});
  
        for (const header of this.JournalVouchersHeaders) {
          const dataKey = columnMapping[header];
         // jvFormGroup.addControl(header, new FormControl(jv[dataKey] !== undefined ? jv[dataKey] : '')); 
         const value = jv[dataKey] !== undefined ? jv[dataKey] : '';

         if (header.includes('$')) {
           const formattedValue = this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2');
           jvFormGroup.addControl(header, new FormControl(formattedValue));
         } else {
          jvFormGroup.addControl(header, new FormControl(value));
         }
        }
  
        this.journalVouchersFormArray.push(jvFormGroup); 
      });
    }
          // // Add an empty row
          // const emptyFormGroup = this.fb.group({});
          // for (const header of this.PurchaseOrdersHeaders) {
          //   emptyFormGroup.addControl(header, new FormControl(''));
          // }
          // this.purchaseOrdersFormArray.push(emptyFormGroup);
  
    console.log('Journal vouchers form array after population:', this.journalVouchersFormArray.value); 
  }

  populateInvoices(Invoices: any[]) {
    const columnMapping: { [key: string]: string } = {
      'Rec Type': 'rec_type',
      'Posting Date': 'posting_date',
      'Doc Num': 'doc_num',
      'Doc Item': 'doc_item',
      'Invoice Num': 'invoice_num',
      'SIC Doc Num': 'src_doc_num',
      'PO Num': 'po_num',
      'Vendor': 'vendor',
      'Description': 'description',
      'Total($)': 'total',
      'Service Type': 'service_type',
      'Posting Date[Early Entry]': 'posting_date_early_entry',
      'Invoice Num[Early Entry]': 'invoice_num_early_entry',
      'PO Num[Early Entry]': 'po_num_early_entry',
      'Vendor[Early Entry]': 'vendor_early_entry',
      'Total($)[Early Entry]': 'total_early_entry',
    };
  
    this.InvoicesHeaders = Object.keys(columnMapping); 
    this.invoicesFormArray.clear(); 
  
    if (Invoices && Invoices.length > 0) { 
      Invoices.forEach((invoice) => {
        console.log('Processing Invoice:', invoice); 
        const invoiceFormGroup = this.fb.group({}); 
  
       
        for (const header of this.InvoicesHeaders) {
          const dataKey = columnMapping[header]; 
          //invoiceFormGroup.addControl(header, new FormControl(invoice[dataKey] !== undefined ? invoice[dataKey] : '')); 
          const value = invoice[dataKey] !== undefined ? invoice[dataKey] : '';

          if (header === 'Service Type') {
            invoiceFormGroup.addControl(header, new FormControl(value || this.ServiceTypeOptions[0]));
          }

         if (header.includes('$')) {
           const formattedValue = this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2');
           invoiceFormGroup.addControl(header, new FormControl(formattedValue));
         } else {
          invoiceFormGroup.addControl(header, new FormControl(value));
         }
        }
  
        this.invoicesFormArray.push(invoiceFormGroup); 
      });
    }

    // Add an empty row
    const emptyFormGroup = this.fb.group({
      isNewRow: new FormControl(true)
    } as { [key: string]: AbstractControl
     });
    for (const header of this.InvoicesHeaders) {
      emptyFormGroup.addControl(header, new FormControl(''));
    }
    this.invoicesFormArray.push(emptyFormGroup);  
  
    console.log('Invoices form array after population:', this.invoicesFormArray.value); 
  
    this.invoicesEditableColumns = [
      'Service Type',
      'Posting Date[Early Entry]',
      'Invoice Num[Early Entry]',
      'PO Num[Early Entry]',
      'Vendor[Early Entry]',
      'Total($)[Early Entry]',
    ];
  }
  

  isColumnEditable(column: string, table: string): boolean {
    if (table === 'purchaseOrders') {
      return this.purchaseOrderEditableColumns.includes(column);
    } else if (table === 'journalVouchers') {
      return this.journalVoucherEditableColumns.includes(column);
    } else if (table === 'invoices') {
      return this.invoicesEditableColumns.includes(column);
    }
    return false;
  }

  getFormGroup(control: AbstractControl): FormGroup {
    if (control instanceof FormGroup) {
      return control;
    }
    throw new Error('Control is not a FormGroup');
  }

  setFormValue(capexAllFields: any) {
    this.FetchedcapexFormData = capexAllFields;
    console.log('Form values after setting:', this.FetchedcapexFormData.WBS_Cash_Flow_Details);
  }
  
  getFormValue() {
    return this.wbsCashFlowDetailsForm.getRawValue();
  }

  closeCashFlowDetails(){
    this.totalPOAmount = 0;
    this.totalInvoicedPOAmount = 0;
    this.totalJVAmount = 0;
  }

  // recalculateTotalPOAmount() {
  //   let total = 0;
  //   this.purchaseOrdersFormArray.controls.forEach((control) => {
  //     //const poAmount = Number(control.get('PO Amoun($)')?.value) || 0;
  //     const poAmount = parseFloat(control.get('PO Amoun($)')?.value?.replace(/[^0-9.-]+/g, '') || '0');
  //     const poAmountEarlyEntry = Number(control.get('PO Amount($)[Early Entry]')?.value) || 0;
  //     total += poAmountEarlyEntry + poAmount;
  //   });
  //   this.totalPOAmount = total;
  //   this.common.updateTotalPOAmount(this.totalPOAmount);
  //   console.log('Updated Total PO Amount:', this.totalPOAmount);
  // }

  recalculateTotalPOAmount() {
    let total = 0;

    this.purchaseOrdersFormArray.controls.forEach((control) => {
        const poAmount = parseFloat(control.get('PO Amoun($)')?.value?.replace(/[^0-9.-]+/g, '') || '0');
        const poAmountEarlyEntry = Number(control.get('PO Amount($)[Early Entry]')?.value) || 0;

        const poNumber = control.get('PO Num')?.value;
        const reqType = control.get('Req Num')?.value;

        if (poNumber == "" && reqType == "") { 
            total += poAmountEarlyEntry;
        }

        total += poAmount; 
    });

    this.totalPOAmount = total;
    this.common.updateTotalPOAmount(this.totalPOAmount);
    console.log('Updated Total PO Amount:', this.totalPOAmount);
}


  // recalculateTotalInvoicedPOAmount() {
  //   let InvoicedPOtotal = 0;
  //   this.invoicesFormArray.controls.forEach((control) => {
  //     //const poAmount = Number(control.get('PO Amoun($)')?.value) || 0;
  //     const total = parseFloat(control.get('Total($)')?.value?.replace(/[^0-9.-]+/g, '') || '0');

  //     const totalEarlyEntry = Number(control.get('Total($)[Early Entry]')?.value) || 0;
  //     InvoicedPOtotal += total + totalEarlyEntry;
  //   });
  //   this.totalInvoicedPOAmount = InvoicedPOtotal;
  //   this.common.updateTotalInvoicedPOAmount(this.totalInvoicedPOAmount);
  //   console.log('Updated Total PO Invoiced Amount:', this.totalInvoicedPOAmount);
  // }

  recalculateTotalInvoicedPOAmount() {
    let InvoicedPOtotal = 0;

    this.invoicesFormArray.controls.forEach((control) => {
        const total = parseFloat(control.get('Total($)')?.value?.replace(/[^0-9.-]+/g, '') || '0');
        const totalEarlyEntry = Number(control.get('Total($)[Early Entry]')?.value) || 0;

        const poNumber = control.get('PO Num')?.value;
        const recType = control.get('Rec Type')?.value;

        if (poNumber == "" && recType == "") {
            InvoicedPOtotal += totalEarlyEntry;
        }

        InvoicedPOtotal += total; 
    });

    this.totalInvoicedPOAmount = InvoicedPOtotal;
    this.common.updateTotalInvoicedPOAmount(this.totalInvoicedPOAmount);
    this.updatetotalcashflow();
    console.log('Updated Total PO Invoiced Amount:', this.totalInvoicedPOAmount);
}


  calulatetotalJV(){
    let JVtotal = 0;
    this.journalVouchersFormArray.controls.forEach((control) => {
      //const poAmount = Number(control.get('PO Amoun($)')?.value) || 0;
      const total = parseFloat(control.get('Total($)')?.value?.replace(/[^0-9.-]+/g, '') || '0');
      JVtotal += total;
    });
    this.totalJVAmount = JVtotal;
    this.common.updateTotalJVAmount(this.totalJVAmount);
    this.updatetotalcashflow();
    console.log('Updated Total JV Amount:', this.totalJVAmount);
  }

  updatetotalcashflow(){
    this.totalcashflow = this.totalInvoicedPOAmount + this.totalJVAmount;
    this.common.updateTotalCashFlow(this.totalcashflow);
  }

//   addnewrow() {
//     if(this.purchaseOrdersFormArray){
//     if (this.purchaseOrdersFormArray.length > 0) {
//       const lastRow = this.purchaseOrdersFormArray.at(this.purchaseOrdersFormArray.length - 1);
//       const earlyEntryFields = [
//         'PO Num[Early Entry]',
//         'Req Num[Early Entry]',
//         'PO date[Early Entry]',
//         'Vendor[Early Entry]',
//         'PO Amount($)[Early Entry]'
//       ];
//       const isLastRowFilled = earlyEntryFields.every(field => lastRow.get(field)?.value);
      
//       if (!isLastRowFilled) {
//         return;
//       }
//     }
    
//     // Add an empty row
//     const emptyFormGroup = this.fb.group({});
//     for (const header of this.PurchaseOrdersHeaders) {
//       emptyFormGroup.addControl(header, new FormControl(''));
//     }
//     this.purchaseOrdersFormArray.push(emptyFormGroup);
//   }
  
//   if(this.invoicesFormArray){
//     if (this.invoicesFormArray.length > 0) {
//       const lastRow = this.invoicesFormArray.at(this.invoicesFormArray.length - 1);
//       const earlyEntryFields = [
//         'Service Type',
//         'Posting Date[Early Entry]',
//         'Invoice Num[Early Entry]',
//         'PO Num[Early Entry]',
//         'Vendor[Early Entry]',
//         'Total($)[Early Entry]'
//       ];
//       const isLastRowFilled = earlyEntryFields.every(field => lastRow.get(field)?.value);
      
//       if (!isLastRowFilled) {
//         return;
//       }
//     }
//     // Add an empty row
//     const emptyrowFormGroup = this.fb.group({});
//     for (const header of this.InvoicesHeaders) {
//       emptyrowFormGroup.addControl(header, new FormControl(''));
//     }
//     this.invoicesFormArray.push(emptyrowFormGroup);
//   }
// }
  
addNewRow(type: 'purchaseOrder' | 'invoice') {
  if (type === 'purchaseOrder' && this.purchaseOrdersFormArray) {
    if (this.purchaseOrdersFormArray.length > 0) {
      const lastRow = this.purchaseOrdersFormArray.at(this.purchaseOrdersFormArray.length - 1);
      const earlyEntryFields = [
        'PO Num[Early Entry]',
        'Req Num[Early Entry]',
        'PO date[Early Entry]',
        'Vendor[Early Entry]',
        'PO Amount($)[Early Entry]'
      ];
      const isLastRowFilled = earlyEntryFields.every(field => lastRow.get(field)?.value);

      if (!isLastRowFilled) {
        return;
      }
    }

   // Add an empty row to Purchase Orders
   if (this.purchaseOrdersFormArray.length > 0){
    const emptyFormGroup = this.fb.group({
      isNewRow: new FormControl(true)
    } as { [key: string]: AbstractControl
     });
    for (const header of this.PurchaseOrdersHeaders) {
      emptyFormGroup.addControl(header, new FormControl(''));
    }
    this.purchaseOrdersFormArray.push(emptyFormGroup);
  }
}

  if (type === 'invoice' && this.invoicesFormArray) {
    if (this.invoicesFormArray.length > 0) {
      const lastRow = this.invoicesFormArray.at(this.invoicesFormArray.length - 1);
      const earlyEntryFields = [
        'Service Type',
        'Posting Date[Early Entry]',
        'Invoice Num[Early Entry]',
        'PO Num[Early Entry]',
        'Vendor[Early Entry]',
        'Total($)[Early Entry]'
      ];
      const isLastRowFilled = earlyEntryFields.every(field => lastRow.get(field)?.value);

      if (!isLastRowFilled) {
        return;
      }
    }

    // Add an empty row to Invoices
    if (this.invoicesFormArray.length > 0){
    const emptyrowFormGroup = this.fb.group({
      isNewRow: new FormControl(true)
      } as { [key: string]: AbstractControl
    });
    for (const header of this.InvoicesHeaders) {
      emptyrowFormGroup.addControl(header, new FormControl(''));
    }
    this.invoicesFormArray.push(emptyrowFormGroup);
  }
}
}

  deleteRow(section: string, index: number): void {
    if (section === 'purchaseOrders') {
      this.purchaseOrdersFormArray.removeAt(index);
    } else if (section === 'journalVouchers') {
      this.journalVouchersFormArray.removeAt(index);
    } else if (section === 'invoices') {
      this.invoicesFormArray.removeAt(index);
    }
  }
}