import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-capex',
  templateUrl: './capex.component.html',
  styleUrls: ['./capex.component.scss']
})
export class CapexComponent {
  constructor(private common: CommonService, private activatedRoute: ActivatedRoute){
    this.common.currentModule.next(this.activatedRoute.snapshot.data)
    this.common.bannerData.next({title: 'CAPEX'})
  }
}
