import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StakeholderRoutingModule } from './stakeholder-routing.moudle';
import { StakeholderComponent } from './stakeholder.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { MaterialModule } from 'src/app/modules/material.module';
import { CapStakeholderComponent } from './cap-stakeholder/cap-stakeholder.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    StakeholderComponent,
    CapStakeholderComponent,
    EditDialogComponent
  ],
  imports: [
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatTooltipModule,
    CommonModule,
    StakeholderRoutingModule,
    SharedModule,
    MaterialModule
  ]
})
export class StakeholderModule { }
