<footer class="w-100" *ngIf="footerData" id="mainFooter">
    <div class="d-block text-center py-3">
        <ul class="list-unstyled mb-0">
            <li class="d-inline-block" *ngFor="let links of footerData; let i = index">
                <a class="text-white" [href]="links.link" *ngIf="links.link">{{links.text}}</a>
                <a class="text-white" href="javascript:void(0)" *ngIf="!links.link">{{links.text}}</a>
                <span class="px-1 text-white" *ngIf="i < (footerData.length - 1)">|</span>
            </li>
        </ul>
    </div>
</footer>