import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { Subscription, lastValueFrom } from 'rxjs';
import { NavItem } from 'src/app/interfaces/interfaces.main';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public activePage: any;
  navItems: NavItem[] = [];
  currentActiveModule = '';
  subscriptions: Subscription[] = [];

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private common: CommonService) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.common.currentModule.subscribe((param) => {
        this.currentActiveModule = param.extraParameter;
        this.getMenus();
        this.activePage = this.currentActiveModule;
      })
    )
    this.subscriptions.push(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.setfooterHeight();
        }
      })
    )
  }


  loopInChild(children: any) {
    var val;
    for (let i = 0; i < children.length; i++) {
      if (children[0].children.length === 1) {
        this.loopInChild(children[0].children)
      } else {
        val = children[0].data['extraParameter'];
        break
      }
    }
    return val;
  }

  async getMenus() {
    const data: any = await lastValueFrom(this.common.getDummyData());
    this.navItems = [];
    data.menu.forEach((item:any) => {
      if (!item.hide) {
        // Check if the item has children, and filter hidden children if they exist
        const visibleChildren = item.children ? item.children.filter((child:any) => !child.hide) : [];
        
        // Add the item to navItems along with its visible children
        this.navItems.push({ ...item, children: visibleChildren });
      }
    });
    //this.navItems = data.menu;
    this.setfooterHeight();
  }

  setfooterHeight() {
    setTimeout(() => {
      const main = document.getElementsByClassName('app-main')[0];
      const footer = document.getElementById('mainFooter');
      if (footer) {
        main.setAttribute("style", "padding-bottom:" + footer?.offsetHeight + "px")
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
