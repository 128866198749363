import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable, startWith } from 'rxjs';
//import { CapDetailsService } from 'src/app/Shared/Services/cap-details.service';
import * as XLSX from 'xlsx';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { CapDetailData , CapData} from 'src/app/main/cap/cap-detail/cap-detail';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';

export interface CapArray {
  PORNum: string,
  PORStatus: string,
  CapAcq: string,
  AMR: string,
  Priority: string,
  LnB: string,
  Level2: string,
}

@Component({
  selector: 'app-cap-detail',
  templateUrl: './cap-detail.component.html',
  styleUrls: ['./cap-detail.component.scss']
})


export class CapDetailComponent implements OnInit {
  @ViewChild('inputAutoComplete', { static: false }) inputAutoComplete: any;
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');
  loadPORDetails: boolean = false;

  capDetails!: FormGroup;
  capData: any[] = [];
  capDetailsArray: any = [];

  tableHeaderArray: any;
  FinalCapData: any = {};
  tableResult: any[] =[];
  allPORNumber: any[] = [];
  allPORStatus: any[] = ['Active', 'In Queue'];
  RequestType: any[] = ['POR(Current Fiscal Year)', 'Expense (CAP SH Review)'];
  statusClicked: any;
  showAction: boolean = false;
  showName = true;
  PORStatus = true;
  POR = true;
  CapStatus = true;
  Description = true;
  PORDriver = true;
  ToolName = true;
  RequesterName = true;
  SecondaryReqEmail = true;
  RequestingSite = true;
  RequestDate = true;
  PORProjectManager = true;
  PORCommitManager = true;
  CommitLocation = true;
  CostObject = true;
  MRU = true;
  Level2 = true;
  Level3 = true;
  BusinessUnit = true;
  Organization = true;
  Section = true;
  Rollup = true;
  ItemCost = true;
  HookupCost = true;
  NRECost = true;
  TotalCost = true;
  AdditionalCost = true;
  Commit = true;
  Payment1 = true;
  Payment2 = true;
  SetupDueDate = true;
  ActualCommit = true;
  MonthsOverdue = true;
  hideColumnName: any;
  FinalCapDataCopy: any;
  checkboxClicked: boolean = false;
  numSelected: any;
  hideSpecificColumn!: boolean;
  searchData:any = [];
  PORInfo: boolean = true;
  ToolInformation: boolean = true;
  WBS: boolean = true
  Asset: boolean = true;
  OrgHierarchy: boolean = true;
  ScheduleInformation: boolean = true;
  FinanceInformation: boolean = true;
  sendStatusUpdate: any= { };
  capDetailsData: any;
  filterInQueueData: any;
  optionsLoading!: boolean;
  userRole!: string;
  userName!: string;
  showActiveFields!: boolean;
  status: any[] = [];
  plannerOptions: any[] = [];
  allSites: any[] = [];
  buildings: any;

  constructor(
    private fb: FormBuilder, 
    private common: CommonService, 
    private httpClient: HttpClient, 
    private router: Router, 
    private dialog: MatDialog
  ) {
    this.common.bannerData.next({ title: 'Capital Acquisition'});
   }

  columnHeader = {
    'btnName': 'Action', 'POR_Status': 'POR Status', 'POR_Num': 'POR Number', 'description': 'Description',
    'POR_Driver': 'POR Driver', 'POR_ProjManager': 'POR Project Manager', 'POR_CommitManager': 'POR Commit Manager',
    'commitLocation': 'Commit Location', 'cost': 'Cost Object', 'MRU': 'MRU', 'level2': 'Level2',
    'level3': 'Level3', 'businessUnit': 'Business Unit', 'organisation': 'Organisation',
    'section': 'Section', 'rollUp': 'Roll Up', 'itemCost': 'Item Cost', 'hookUpCost': 'Hook Up Cost',
    'NRECost': 'NRE Cost', 'totalCost': 'Total Cost', 'commit': 'Commit', 'payment1': 'Payment1', 'payment2': 'Payment2',
    'dueDate': 'Due Date', 'actualDate': 'Actual Date', 'overDate': 'Over Date'
  };

  capPORData1: CapDetailData[] = []

  options!: string[];
 // options: string[] = ['One', 'Two', 'Three'];;
  filteredOptions!: Observable<string[]>;

  ngOnInit(): void {
    this.common.loader.next(true);
    this.capDetails = this.fb.group(
      {
        Request_Type: [''],
        POR_Status: [''],
        POR_Num: [''],
        requestor: [''],
        WBS: [''],
        planner: [''],
        requestingSite: [''],
        projectManager: [''],
        commitManager: [''],
        depreciationManager: [''],
        commitQuarter: [''],
        requestdate: [''],
        initiateSite: [''],
        building: [''],
        LabFabInstallLocation: [''],
      });

      this.capDetailsArray = [
          'Request_Type','POR_Status', 'POR_Num', 'requestor', 'WBS', 'planner', 'requestingSite',
          'projectManager', 'commitManager', 'depreciationManager', 'commitQuarter', 'requestdate', 'requestingSite','building',
          'LabFabInstallLocation'
      ]
    
    this.router.navigate(['/cap/capDetail'], {queryParams: { porStatus: 'In Queue'}});
    this.userRole = this.common.getUserRole();
    this.userName = atob(this.common.currentUser);
    //updateEvent == 0 to fetch only records from db based on status 
    this.sendStatusUpdate = {
      PORStatus: "In Queue",
      POR: "",
      updateEvent: 0
    }
    
    //To update POR status in db
    this.common.postCapAPI('itg/cap-update-por-status-itg',this.sendStatusUpdate).subscribe({
      next: (ele:any) => {
        this.showAction = true;
        this.filterInQueueData = ele['body'];
        let modifiedData = this.filterInQueueData;
        this.iterateColumns(modifiedData);
        //   let searchData = modifiedData.filter(e =>
        //     e['PORStatus'] == this.capDetails.controls.POR_Status.value)
        //     this.iterateColumns(searchData);
      },
      error: (error:any) => {
        console.error("Error occurred:", error);
        this.common.show('error', 'Please connect with Asset Admin');
      }
    });

    this.tableResult = [];
    // this.getPORData();

    this.common.getAPI('amd-get-labsite').subscribe({
      next: (
        (data) => {
          this.buildings = data;
          // console.log(this.buildings);
          for (let i = 0; i < this.buildings?.length; i++) {
            let tmp = this.buildings[i]['Location'];
            this.allSites.push(tmp);
          }
          this.allSites = this.allSites?.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          this.allSites.sort();
        }),
      error: (
        (error) => {
          console.error("Error occurred:", error);
          alert("Please connect with Asset Admin");
        })
    })

    this.common.getCapexAPI('capex-request-role').subscribe({
      next: (
        (data) => {
         // this.financeOptions = data['Finance'].filter((a: any) => a['Capex_Request_Role'] == 'Finance' && a['Active'] == true).map((a: any) => a['Capex_Request_Role_Name']);
          this.plannerOptions = data['Planner'].filter((a: any) => a['Capex_Request_Role'] == 'Planner' && a['Active'] == true).map((a: any) => a['Capex_Request_Role_Name']);
        }),
      error: (
        (err) => {
          console.error("Error occurred:", err);
        })
    });
  }

  onEnter(evt: any){
    if (evt.source.selected) {
    // alert("hello ");
    }
  }

  originalOrder = (a: any, b: any) => { return 0 }

  btnClick(data1:any, item:any) {
    let existingCapVal = this.fb.group({
      status: data1,
      capItemVal: item
    })
    this.common.changeMessage(existingCapVal.value);
    this.common.passDataFunction(item);
    
    if (data1 == 'In Queue' || data1 == 'Draft' || item.CapStatus == 'Draft') {
      if(item.CapStatus == 'Active' || item.CapStatus == 'Complete' || item.CapStatus == 'Canceled'){
        this.router.navigate(['cap/focusArea'], {queryParams: { POR: item['POR']}});
      }
      else{
      this.router.navigate(['cap/capInitiateRequest'], {queryParams: { POR: item['POR']}});
      }
    }
    else if(data1 == 'Active' || data1 == 'Complete' || item.CapStatus == 'Active') {
      if(item.CapStatus == 'Active' || item.CapStatus == 'Complete' || item.CapStatus == 'Canceled'){
        this.router.navigate(['cap/focusArea'], {queryParams: { POR: item['POR']}});
      }
      else{
        this.router.navigate(['cap/capInitiateRequest'], {queryParams: { POR: item['POR']}});
      }
    }
    else {
      this.openDialogMessage('Please connect with Asset Admin.', '');
    }
  }

  sendNewValue(fieldName:any ,event:any){
    let fieldValue = '';
    if(fieldName == 'planner'){
      fieldValue = event.value;
    }
    else{
      fieldValue = event.target.value;
    }
    for (let i = 0; i < this.capDetailsArray.length; i++) {
      if (this.capDetailsArray[i] != fieldName){
        if ((fieldName) && (this.capDetails.controls['POR_Status'].value)){
         // if(this.capDetailsArray[i] != 'POR_Status'){
            this.capDetails.controls[this.capDetailsArray[i]].reset();
        //  }
        }
        else{
          this.capDetails.controls[this.capDetailsArray[i]].reset();
        }
      }
    } 
    console.log(this.capDetails.value)
  }

  submitPOR(data: any) {
    if (data === 'submit') {
      this.common.loader.next(true);
      const payload = {
        //por: this.capDetails.get('POR_Num')?.value || '',
        por: this.capDetails.get('POR_Num')?.value?.trim() || '',
        //commitManager: this.capDetails.get('commitManager')?.value || '',
        // commitManager: (this.capDetails.get('commitManager')?.value || '').endsWith('@hp.com')
        // ? this.capDetails.get('commitManager')?.value.slice(0, -'@hp.com'.length)
        // : this.capDetails.get('commitManager')?.value || '',
        // commitQuarter: this.capDetails.get('commitQuarter')?.value || '',
        // //depreciationManager: this.capDetails.get('depreciationManager')?.value || '',
        // depreciationManager: (this.capDetails.get('depreciationManager')?.value || '').endsWith('@hp.com')
        // ? this.capDetails.get('depreciationManager')?.value.slice(0, -'@hp.com'.length)
        // : this.capDetails.get('depreciationManager')?.value || '',
        planner: this.capDetails.get('planner')?.value || '',
        projectManager: this.capDetails.get('projectManager')?.value || '',
        requester: this.capDetails.get('requestor')?.value || '',
        status: this.capDetails.get('POR_Status')?.value || '',
        Site: this.capDetails.get('requestingSite')?.value || '',
        building: this.capDetails.get('building')?.value || '',
        LabFabInstallLocation: this.capDetails.get('LabFabInstallLocation')?.value || '',
        requestdate: this.capDetails.get('requestdate')?.value || '',
        requesttype: this.capDetails.get('Request_Type')?.value || '',
      };
  
      this.common.postCapAPI('itg/cap-search-por', payload).subscribe({
        next: (output: any) => {
          console.log('Data fetched successfully:', output);
  
          this.showAction = true;
          this.filterInQueueData = output.message;
          this.iterateColumns(this.filterInQueueData);
          
          // if (this.filterInQueueData.length === 0) {
          //   console.log('No records found');
          //   this.noRecordsFound = true; 
          // } else {
          //   this.noRecordsFound = false; 
          //   this.iterateColumns(this.filterInQueueData);
          // }
        },
        error: (error: any) => {

          console.error('Error fetching data:', error);
         // this.noRecordsFound = false; 
        },
        complete: () => {
          this.common.loader.next(false);
        }
      });
    }
  }
  


  // submitPOR(data:any) {
  //   this.showAction = true;
  //   let finalCapArray = [];
  //   this.FinalCapData = [];
  //   this.Level2 = true;
  //   if (data == 'submit') {
  //     this.common.loader.next(true);
  //    //To fetch data from db based on PORNumber, PORStatus, & Requester & updateEvent= 1 which will scan all table records
  //    this.sendStatusUpdate['updateEvent'] = 1 ;
  //     this.common.postCapAPI('itg/cap-update-por-status-itg',this.sendStatusUpdate).subscribe({
  //     next: (ele: any) => {
  //     let modifiedData  = ele['body'];
  //       if (this.capDetails.controls['POR_Num'].value) {
  //         this.searchData = modifiedData.filter((e:any) =>
  //           e['PORNumber'] == this.capDetails.controls['POR_Num'].value)
  //       }
  //       else if(this.capDetails.controls['requestor'].value){
  //         if (this.capDetails.controls['requestor'].value && this.capDetails.controls['POR_Status'].value) {
  //           this.searchData = modifiedData.filter((e:any) =>
  //             e['PORStatus'] == this.capDetails.controls['POR_Status'].value)
  //           this.searchData = this.searchData.filter((e1:any) =>
  //             e1['RequesterName'] == this.capDetails.controls['requestor'].value)
  //         }
  //         else{
  //           this.searchData = modifiedData.filter((e:any) =>
  //             e['RequesterName'] == this.capDetails.controls['requestor'].value)
  //         }
  //         // else {
  //         //   this.searchData = modifiedData.filter(e =>
  //         //     e['PORStatus'] == this.capDetails.controls.POR_Status.value)
  //         // }
  //       }        
  //       else if(this.capDetails.controls['planner'].value) {
  //         if (this.capDetails.controls['planner'].value && this.capDetails.controls['POR_Status'].value) {
  //           this.searchData = modifiedData.filter((e:any) =>
  //             e['PORStatus'] == this.capDetails.controls['POR_Status'].value)
  //           this.searchData = this.searchData.filter((e1:any) =>
  //             e1['Planner'] == this.capDetails.controls['planner'].value)
  //         }
  //         else{
  //         this.searchData = modifiedData.filter((e:any) =>
  //           e['Planner'] == this.capDetails.controls['planner'].value)
  //         }
  //       }
  //       else if(this.capDetails.controls['requestingSite'].value) {
  //         if (this.capDetails.controls['requestingSite'].value && this.capDetails.controls['POR_Status'].value) {
  //           this.searchData = modifiedData.filter((e:any) =>
  //             e['PORStatus'] == this.capDetails.controls['POR_Status'].value)
  //           this.searchData = this.searchData.filter((e1:any) =>
  //             e1['InitiateSite'] == this.capDetails.controls['requestingSite'].value)
  //         }
  //         else{
  //         this.searchData = modifiedData.filter((e:any) =>
  //           e['InitiateSite'] == this.capDetails.controls['requestingSite'].value)
  //         }
  //       }
  //       else if(this.capDetails.controls['projectManager'].value) {
  //         if (this.capDetails.controls['projectManager'].value && this.capDetails.controls['POR_Status'].value) {
  //           this.searchData = modifiedData.filter((e:any) =>
  //             e['PORStatus'] == this.capDetails.controls['POR_Status'].value)
  //           this.searchData = this.searchData.filter((e1:any) =>
  //             e1['ProjectManager'] == this.capDetails.controls['projectManager'].value)
  //         }
  //         else{
  //           this.searchData = modifiedData.filter((e:any) =>
  //           e['ProjectManager'] == this.capDetails.controls['projectManager'].value)
  //         }
  //       }
  //       else if (this.capDetails.controls['commitManager'].value) {
  //         if (this.capDetails.controls['commitManager'].value && this.capDetails.controls['POR_Status'].value) {
  //           this.searchData = modifiedData.filter((e:any) =>
  //             e['PORStatus'] == this.capDetails.controls['POR_Status'].value)
  //           this.searchData = this.searchData.filter((e1:any) =>
  //             e1['CommitManager'] == this.capDetails.controls['commitManager'].value)
  //         }
  //         else {
  //           this.searchData = modifiedData.filter((e:any) =>
  //             e['CommitManager'] == this.capDetails.controls['commitManager'].value)
  //         }
  //       }
  //       else if (this.capDetails.controls['depreciationManager'].value) {
  //         if (this.capDetails.controls['depreciationManager'].value && this.capDetails.controls['POR_Status'].value) {
  //           this.searchData = modifiedData.filter((e :any)=>
  //             e['PORStatus'] == this.capDetails.controls['POR_Status'].value)
  //           this.searchData = this.searchData.filter((e1:any) =>
  //             e1['DepreciationManager'] == this.capDetails.controls['depreciationManager'].value)
  //         }
  //         else {
  //           this.searchData = modifiedData.filter((e:any) =>
  //             e['DepreciationManager'] == this.capDetails.controls['depreciationManager'].value)
  //         }
  //       }
  //       else if(this.capDetails.controls['commitQuarter'].value) {
  //         if (this.capDetails.controls['commitQuarter'].value && this.capDetails.controls['POR_Status'].value) {
  //           this.searchData = modifiedData.filter((e:any) =>
  //             e['PORStatus'] == this.capDetails.controls['POR_Status'].value)
  //           this.searchData = this.searchData.filter((e1:any) =>
  //             e1['Commit(QTR)'] == this.capDetails.controls['commitQuarter'].value)
  //         }
  //         else{
  //         this.searchData = modifiedData.filter((e:any) =>
  //           e['Commit(QTR)'] == this.capDetails.controls['commitQuarter'].value)
  //         }
  //       }   
  //       else{
  //           this.searchData = modifiedData.filter((e:any) =>
  //           e['PORStatus'] == this.capDetails.controls['POR_Status'].value)
  //       }     
  //       this.iterateColumns(this.searchData);
  //       this.common.loader.next(false);
  //     },
  //     error: (error:any) => {
  //       this.common.loader.next(false);
  //       console.error("Error occurred:", error);
  //       this.common.show('error', 'Please connect with Asset Admin');
  //     }
  //   });
  //   }
  //   if(data == 'showPOR'){
  //     this.common.loader.next(true);
  //     this.router.navigate(['/cap/capDetail'], {queryParams: { porStatus: ''}});
  //     this.common.getDataFromDB().subscribe({
  //       next: (data:any) => {
  //         console.log("Print data", data)
  //         let modifiedData = data.body;
  //         this.searchData = modifiedData.filter((e:any) => e['PORStatus'] != 'Canceled')
  //         this.iterateColumns(this.searchData);
  //         this.common.loader.next(false);
  //       },
  //       error: (error:any) => {
  //         this.common.loader.next(false);
  //         console.error("Error occurred:", error);
  //         this.common.show('error', 'Please connect with Asset Admin');
  //       }
  //     });     
  //   }

  //   if(data == 'showRecords'){
  //     this.common.loader.next(true);
  //     this.common.getDataFromDB().subscribe({
  //       next: (data:any) => {
  //         let modifiedData = data.body;
  //         this.searchData = modifiedData.filter((e:any) =>
  //           e['PORStatus'] == ('In Queue'))
  //         this.iterateColumns(this.searchData);
  //         this.common.loader.next(false);
  //       },
  //       error: (error:any) => {
  //         this.common.loader.next(false);
  //         console.error("Error occurred:", error);
  //         this.common.show('error', 'Please connect with Asset Admin');
  //       }
  //     }); 
  //   }
      
  // }

  iterateColumns(tableData:any){
    let finalCapArray = [];
    let searchData = tableData
    for (let i = 0; i < searchData.length; i++) {

    const isPOR = searchData[i]["Request_Type"] === "POR(Current Fiscal Year)";

    const loc: CapData = {
      LTP : isPOR ? null : searchData[i]["LTP"],
      LTPStatus: isPOR ? null : searchData[i]["LTP_Status"],
      PORStatus: searchData[i]["POR_Status"],
      POR: searchData[i]["POR"], 
      // CAP: searchData[i]["CAP"],
      CAP: searchData[i]["POR"],
      CapStatus: searchData[i]["CAP_Status"],
      WBS: searchData[i]["WBS"],
      WBSStatus: searchData[i]["WBS_Status"],

      AssignedFocusAreas: searchData[i]["Assigned_Focus_Areas"] || '',
      WaivedSHReviews: searchData[i]["Waived_SH_Reviews"] || '',
      RequesterActionPlan: searchData[i]["Requester_Action_Plan"] || '',
      StakeholderActionPlan: searchData[i]["Stakeholder_Action_Plan"] || '',
      projecttype: searchData[i]["Project_Type"],
      POR_ToolDescription: searchData[i]["Tool_Name_Description"],
      CAP_ToolName: searchData[i]["ToolName"],
      CAP_ToolDescription: searchData[i]["Tool_Description"],
      Planner: searchData[i]["Planner"],
      PORProjectManager: searchData[i]["Project_Manager_Email"],
      CAP_RequesterName: searchData[i]["RequesterName"],
      CAP_SecondaryReqEmail: searchData[i]["SecondaryReq"],
      RequestingSite: searchData[i]["Site"],
      building: searchData[i]["Building"],
      LabFabInstallLocation: searchData[i]["LabFab_Install_Location"],
      BuildSite: searchData[i]["Build_Site_or_shipping_to_another_site"],
      CommitQtr: searchData[i]["Commit_Qtr"],
      AdjustedFactoryAcceptanceQuarter: searchData[i]["Adjusted_Fctory_Acceptance_Qtr"],
      RTE: searchData[i]["Delivery_RTE_Qtr"],
      RTO: searchData[i]["Acceptance_RTO_Qtr"],
      ReasonForRTOdateChange: searchData[i]["RTO_Date_Change_Reason"],
      RTODateChangeComment: searchData[i]["RTO_Date_Change_Comment"],

      
   
      //POR Info
      // Planner:  searchData[i]["Planner"],
      // Description: searchData[i]["Description"],
      // PORDriver: searchData[i]["Program_Driver"],
      // PORProjectManager: searchData[i]["Project_Manager_Email"],

      // Tool Information
      // ToolName: searchData[i]["Tool_Name_Description"],
      // RequesterName: searchData[i]["RequesterName"],
      // SecondaryReqEmail: searchData[i]["SecondaryRequesterName"],
      // RequestingSite: searchData[i]["Site"],
      // RequestDate: searchData[i]["Request_Date"],

      //WBS
      // WBS: searchData[i]["WBS"],
      // WBSStatus: searchData[i]["WBS_Status"],
      // //WBSAmount: searchData[i]["WBS_Amount"],

      // //Asset
      // DepreciationManager: searchData[i]["Depreciation_Cost_Center_Manager"],
      // DepreciationLocation: searchData[i]["Depreciation_Sub_MRU"],
      // Asset: searchData[i]["Number_of_Assets"],
      // AssetLife: searchData[i]["Asset_Useful_Life_in_yrs"],

      // Org Hierarchy
      // PORCommitManager: searchData[i]["Commit_Cost_Center_Manager"],
      // CommitLocation: searchData[i]["Commit_Sub_MRU"],
      // CostObject: searchData[i]["Commit_Cost_Center"],
      // MRU: searchData[i]["Commit_MRU"],
      // Level2: searchData[i]["Commit_Org_L2"],
      // Level3: searchData[i]["Commit_Org_L3"],
      // BusinessUnit: searchData[i]["Business_Unit"],
      // Organization: searchData[i]["Organization"],
      // Section: searchData[i]["Commit_Section"],
      // Rollup: searchData[i]["Rollup"],
    
      //Schedule Information
      // Commit: searchData[i]["Commit_Qtr"],
      // RTE: searchData[i]["Delivery_RTE_Qtr"],
      // RTO: searchData[i]["Acceptance_RTO_Qtr"],
      // SetupDueDate: searchData[i]["Estimated_Asset_Setup"],

      //Finance Information
      // TotalCost: searchData[i]["Total_Cost_$K"], 
      // ItemCost: searchData[i]["Item_Cost_$K"],
      // HookupCost: searchData[i]["Hookup_Capitalized_Install_Costs_$K"],
      // NRECost: searchData[i]["NRE_Total_Cost_$K"], 

      //Additional Cost
      // AdditionalCost: searchData[i]["Additional_Cost_$K"]

    }
    finalCapArray.push(loc);
   } 
   
this.FinalCapData = finalCapArray;
// if(this.FinalCapData){
// for(let i=0; i< this.FinalCapData.length; i++){
//   this.FinalCapDataCopy[i]['active'] = this.FinalCapData[i]; 
// }
// }
this.common.loader.next(false);
}

collapse(){
  this.PORInfo= true;
  this.ToolInformation= true;
  this.WBS= true;
  this.Asset= true;
  this.OrgHierarchy= true;
  this.ScheduleInformation= true;
  this.FinanceInformation= true;
}

changeIcon(option:any){
  this.hideSpecificColumn =true;
  if(option == 'PORInfo'){
    this.PORInfo=!this.PORInfo;
    this.hideSpecificColumn =false;
  }

  if(option == 'ToolInformation'){
    this.ToolInformation=!this.ToolInformation;
    this.hideSpecificColumn =false;
  }

  if(option == 'WBS'){
    this.WBS=!this.WBS;
  }

  if(option == 'Asset'){
    this.Asset=!this.Asset;
  }

  if(option == 'OrgHierarchy'){
    this.OrgHierarchy=!this.OrgHierarchy;
  }

  if(option == 'ScheduleInformation'){
    this.ScheduleInformation=!this.ScheduleInformation;
  }

  if(option == 'FinanceInformation'){
    this.FinanceInformation=!this.FinanceInformation;
  }
  
}
  hideColumn(columnName:any){
    this.checkboxClicked = true
    this.hideColumnName = columnName; 
    this.FinalCapDataCopy = this.FinalCapData
    for(let i=0; i< this.FinalCapDataCopy.length; i++){
      this.FinalCapDataCopy[i]['hidden'] = [];
      this.FinalCapDataCopy[i]['active'] = this.FinalCapDataCopy[i]; 
      this.FinalCapDataCopy[i]['hidden'][columnName] = this.FinalCapDataCopy[i][columnName];
      delete(this.FinalCapDataCopy[i][columnName])
    }
  }

  async getPORData(){
    this.optionsLoading = true;
    this.sendStatusUpdate['updateEvent']=1;
    this.common.postCapAPI('itg/cap-update-por-status-itg',this.sendStatusUpdate).subscribe({
      next: (ele:any) => {
        this.showAction = true;
        this.capDetailsData = ele['body'];
        let tableColumns = Object.keys(this.capDetailsData[0])
        this.tableHeaderArray = tableColumns.filter(val =>
          val != "UUID");
        //get all POR Status
        this.allPORStatus = [...new Set(this.capDetailsData.map((e:any) => e.PORStatus))]

        //get all POR Numbers
        this.allPORNumber = this.capDetailsData.map((q:any) => q.PORNumber)
        this.optionsLoading = false;
      },
      error: (error:any) => {
        console.error("Error occurred:", error);
        this.common.show('error', 'Please connect with Asset Admin');
      }
    });      
  }

  showPORList(data: any) {
    this.common.changeMessage(this.capDetails.controls['POR_Num']);
    localStorage.setItem('por_number', this.capDetails.controls['POR_Num'].value);
    this.filteredOptions = this.capDetails.controls['POR_Num'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  statusSelected(event:any) {
    this.allPORNumber = [];
    this.statusClicked = event.target.value;
    this.showActiveFields = this.statusClicked == "Active" ? true : false
    let porNumVal = this.capDetails.get("POR_Num")?.setValue('');
    //localStorage.setItem('por_number', '');
    //this.allPORNumber = this.tableResult.filter(e => e.get("PORStatus") == this.statusClicked).map(q => q.get("PORNum"))
    this.allPORNumber = this.capDetailsData.filter((e:any) => e.PORStatus == this.statusClicked).map((q:any) => q.PORNumber)
    this.router.navigate(['/cap/capDetail'], {queryParams: { porStatus: this.statusClicked}});
  }

  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    // evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else trigger.openPanel();
  }

  clearInput(evt: any): void {
    evt.stopPropagation();
    this.capDetails.get("POR_Num")?.setValue('');
    //this.inputAutoComplete?.nativeElement.focus();
  }

  numberChange($event:any) {
    //this.numSelected = event.target.value;
    localStorage.setItem('por_number', this.numSelected);
    //this.router.navigate(['/cap/capDetail'], {queryParams: { porNumber: this.numSelected}});
  }

  _filter(value: string): string[] {
    // for (let i = 0; i < this.capDetailsArray.length; i++) {
    //   if (this.capDetailsArray[i] != 'POR_Num' && this.capDetailsArray[i] != 'POR_Status') {
    //     this.capDetails.controls[this.capDetailsArray[i]].reset();
    //   }
    // }
    const filterValue = value.toLowerCase();
    return this.allPORNumber.filter(option => option.toLowerCase().includes(filterValue));
  }

  exportData() {
      if (this.FinalCapData.length != 0) {
        const headerMapping: { [key: string]: string } = {
          "PORStatus": "PORStatus",
          "PORNumber": "POR", 
          "Level2": "Level2",
          "Level3": "Level3",
          "Organization": "Organization",  
          "Section": "Section",
          "MRU": "MRU",
          "PORDriver": "PORDriver",
          "BusinessUnit": "BusinessUnit",
          "Rollup": "Rollup",
          "CommitLoc": "CommitLocation",         
          "CommitCostObj": "CostObject",   
          "CommitMgr": "PORCommitManager",            
          "ProjectManager": "PORProjectManager",    
          "Commit": "Commit",
          "Cost ($K)": "ItemCost",              
          "Hookup ($K)": "HookupCost",           
          "TotalCost": "TotalCost",
          "CapStatus": "CapStatus",            
          "Description": "Description",       
          "ToolName": "ToolName",
          "WBS": "WBS",
          "WBSStatus": "WBSStatus",
          "WBSAmount": "WBSAmount",
          "RequesterName": "RequesterName",             
          "SecondaryReqEmail": "SecondaryReqEmail",            
          "RequestingSite": "RequestingSite",                 
          "RequestDate": "RequestDate",
          "AdditionalCost": "AdditionalCost",                    
          "Payment1": "Payment1",                         
          "Payment2": "Payment2",                     
          "ActualCommit": "ActualCommit",                        
          "SetupDueDate": "SetupDueDate",
          "MonthsOverdue": "MonthsOverdue",                     
          "NRECost": "NRECost"
      };
        // let columnOrder = ["PORStatus", "POR", "Level2", "Level3", "Organization", "Section", 
        // "MRU", "PORDriver", "BusinessUnit", "Rollup", "CommitLoc", "CommitCostObj", "CommitMgr",
        // "ProjectManager", "Commit", "Cost ($K)", "Hookup ($K)", "TotalCost", "CapStatus", 
        // "Description", "ToolName", "WBS", "WBSStatus", "WBSAmount", "RequesterName", "SecondaryReqEmail",
        // "RequestingSite", "RequestDate", "AdditionalCost", "Payment1", "Payment2", "ActualCommit",
        // "SetupDueDate", "MonthsOverdue", "NRECost"];

        let columnOrder = Object.keys(headerMapping);

        let workbook = new Excel.Workbook();

        let worksheet = workbook.addWorksheet('POR Sheet');


        // let titleValues1 = [""];
        // let titleValues2 = [""];

        // worksheet.spliceRows(1, 0, titleValues1,titleValues2);

        // let headerRow = worksheet.addRow(columnOrder);
        // for (let x1 of this.FinalCapData) {
        //   let x2 = columnOrder;
        //   let temp = []
        //   for (let y of x2) {
        //     temp.push(x1[y])
        //   }
        //   worksheet.addRow(temp)
        // }

         // Add headers to the worksheet
         let headerRow = worksheet.addRow(columnOrder);
        
         // Populate data rows
         for (let x1 of this.FinalCapData) {
             let temp = [];
             for (let header of columnOrder) {
                 let propertyName = headerMapping[header]; 
                 temp.push(x1[propertyName]); 
             }
             worksheet.addRow(temp); 
         }


        workbook.xlsx.writeBuffer().then((buffer) => {
          let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, 'Export POR Data.xlsx');
        });
      }

      else {
        this.common.show('info', 'No Pending Approval Assets');
      }

  }

  openDialogMessage(message: any, assetID: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.ariaLabelledBy = "text"
    dialogConfig.maxWidth = '35vw',
      dialogConfig.maxHeight = '40vh',
      dialogConfig.height = '85%',
      dialogConfig.width = '85%',
      dialogConfig.data = {
        showInput: false,
        message: message,
        tabletemplate: true,
      }
    // this.dialog.open(DialogBoxComponent, dialogConfig);
    const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
  }

  onRequestOptionSelected(selectedValue: string) {
    if (selectedValue == 'POR(Current Fiscal Year)') {
      this.status = ['In Queue', 'Active'];
    }
    else if (selectedValue == 'Expense (CAP SH Review)') {
      this.status = ['In Queue', 'Active'];
    }
  }
}
