<div class="msb shadow" id="msb">
    <nav class="navbar navbar-default px-0 pt-0" role="navigation">
        <!-- Main Menu -->
        <div class="side-menu-container w-100">
            <ul class="nav navbar-nav">
                <ng-container *ngFor="let item of navItems">
                    <ng-template #elseTemplate>
                        <li routerLinkActive="active" *ngIf="!item.hide">
                            <a *ngIf="item.route !='#'" [routerLink]="item.route" [matTooltip]="item.displayName"
                                [matTooltipPosition]="'right'"> <i class="fa {{item.iconName}}"></i> 
                                <span class="link-text">{{item.displayName}}</span> </a>
                            <a *ngIf="item.route == '#'" href="javascript:void(0)" [matTooltip]="item.displayName"
                                [matTooltipPosition]="'right'"> <i class="fa {{item.iconName}}"></i> <span
                                    class="link-text">{{item.displayName}}</span> </a>
                        </li>
                    </ng-template>
                    <ng-container *ngIf="item.children; else elseTemplate">
                        <li class="panel panel-default" id="dropdown" [ngClass]="{'active': (activePage === item.id)}">
                            <a data-toggle="collapse" href="#{{item.id}}" [matTooltip]="item.displayName"
                                [matTooltipPosition]="'right'">
                                <i class="fa" ngClass="{{item.iconName}}"></i>
                                <span class="link-text">{{item.displayName}}</span><span class="caret"></span>
                            </a>
                            <!-- Dropdown level 1 -->
                            <div [id]="item.id" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <ul class="nav navbar-nav">
                                        <ng-container *ngFor="let child of item.children">
                                            <ng-template #childElseTemplate>
                                                <li routerLinkActive="active" *ngIf="!child.hide">
                                                    <a *ngIf="child.route !='#'" [routerLink]="child.route"
                                                        [matTooltip]="child.displayName" [matTooltipPosition]="'right'"> 
                                                        <i class="fa" ngClass="{{child.iconName}}"></i> 
                                                        <span class="link-text">{{child.displayName}}</span> 
                                                    </a>
                                                    <a *ngIf="child.route == '#'" href="javascript:void(0)"
                                                        [matTooltip]="child.displayName" [matTooltipPosition]="'right'"> 
                                                        <i class="fa" ngClass="{{child.iconName}}"></i> 
                                                        <span class="link-text">{{child.displayName}}</span> 
                                                    </a>
                                                </li>
                                            </ng-template>
                                            <ng-container *ngIf="child.children; else childElseTemplate">
                                                <li class="panel panel-default" id="dropdown" [ngClass]="{'active': (activePage === child.id)}">
                                                    <a data-toggle="collapse" href="#{{child.id}}" [matTooltip]="child.displayName"
                                                        [matTooltipPosition]="'right'">
                                                        <i class="fa" ngClass="{{child.iconName}}"></i>
                                                        <span class="link-text">{{child.displayName}}</span><span class="caret"></span>
                                                    </a>
                                                    <!-- Dropdown level 2 -->
                                                    <div [id]="child.id" class="panel-collapse collapse">
                                                        <div class="panel-body">
                                                            <ul class="nav navbar-nav">
                                                                <li *ngFor="let grandChild of child.children" routerLinkActive="active">
                                                                    <a *ngIf="grandChild.route !='#'" [routerLink]="grandChild.route"
                                                                        [matTooltip]="grandChild.displayName" [matTooltipPosition]="'right'"> 
                                                                        <i class="fa" ngClass="{{grandChild.iconName}}"></i> 
                                                                        <span class="link-text">{{grandChild.displayName}}</span> 
                                                                    </a>
                                                                    <a *ngIf="grandChild.route == '#'" href="javascript:void(0)"
                                                                        [matTooltip]="grandChild.displayName" [matTooltipPosition]="'right'"> 
                                                                        <i class="fa" ngClass="{{grandChild.iconName}}"></i> 
                                                                        <span class="link-text">{{grandChild.displayName}}</span> 
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </nav>
</div>