<mat-toolbar color="primary" class="orange-gradient text-white text-center">
    <mat-icon class="orange-gradient">laptop</mat-icon>
    <span class="titleFont">{{data.title}}</span>
    <button mat-icon-button class="ml-auto" [mat-dialog-close]="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </mat-toolbar>
  

  <div class="mat-elevation-z8">
    <div class="table-container" style="overflow: auto;">
      <table class="table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef style="padding-left:2%;"> Actions </th>
            <td mat-cell *matCellDef="let element" class="pd-0 w-15 pL-1">
                <!-- <button mat-icon-button class="icon-button" (click)="edit(element)">
                    <mat-icon color="primary" class="icon-size">edit</mat-icon>
                </button> -->
                <button *ngIf="element?.Active" mat-menu-item class="btn bg-primary text-white" (click)="edit(element)">Edit</button>
                <button *ngIf="element?.Active" mat-menu-item class="btn bg-primary text-white" (click)="deactivate(element, 'deactivate')" style="margin-left:1%; margin-top:1%;">Deactivate</button>
                <button *ngIf="!element?.Active" mat-menu-item class="btn bg-primary text-white" (click)="deactivate(element, 'activate')" style="margin-left:1%; margin-top:1%;">Activate</button>
                <!-- <button mat-icon-button (click)="delete(element)">
                    <mat-icon color="warn" class="icon-size">delete</mat-icon>
                </button> -->
            </td>
        </ng-container>

        <ng-container matColumnDef="UserName">
            <th mat-header-cell *matHeaderCellDef class="pd-0 w-22"> Name </th>
            <td mat-cell *matCellDef="let element" class="pd-0">
                <div *ngIf="!element.editing">
                    {{element.UserName}}
                </div>
                <div *ngIf="element.editing">
                    <mat-form-field appearance="fill" class="custom-form-field"> <!-- Increase width to 100% -->
                        <input matInput class="font-15" [(ngModel)]="element.UserName" (ngModelChange)="editField(element, 'UserName')" [disabled]="!element.Active">
                        <button mat-icon-button matSuffix *ngIf="element.UserName" (click)="element.UserName=''">
                            <mat-icon class="iconAlign">clear</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef class="pd-0 w-25"> Email </th>
            <td mat-cell *matCellDef="let element" class="pd-0 w-25"> 
                <div *ngIf="!element.editing">
                    {{element.Email}} 
                </div>
                <div *ngIf="element.editing" class="pd-0" >
                    <mat-form-field appearance="fill" class="custom-form-field" style="width:86%"> <!-- Increase width to 100% -->
                        <input matInput class="font-15" [(ngModel)]="element.Email" required
                            (ngModelChange)="editField(element, 'Email')" matTooltip="{{element.Email}}" [disabled]="!element.Active">
                            <mat-hint *ngIf="!element.Email">
                                <span class="text-danger">
                                    Email Id is required
                                </span>
                            </mat-hint>
                            <mat-hint *ngIf="element.userVerified">
                                <span class="text-success">
                                   Verified
                                </span>
                            </mat-hint>
                            <mat-hint *ngIf="element.userInvalid">
                                <span class="text-danger">
                                    Invalid
                                </span>
                            </mat-hint>
                        <div matSuffix class="alignVerifyBtn" >
                            <button type="button" class="btn btn-primary btn-sm mT-2"  matTooltip="verify Changes"
                                (click)="validUserLdap(element)"
                                style="margin-bottom:10px; display: flex; align-items: center; justify-content: space-between;">{{element.userVerified ? 'Verified' : 'Verify'}}
                                <i class="fa fa-spinner fa-spin spinnerAlign" *ngIf="element.userVerifyLoading"></i>
                            </button> 
                           
                            <!-- <button mat-icon-button class="material-icons app-toolbar-menu save-button validUser" matTooltip="valid User"
                                *ngIf="this.userVerify">
                                <mat-icon>check_circle</mat-icon>
                            </button>
                            <button mat-icon-button class="material-icons app-toolbar-menu cancel-button" matTooltip="Invalid User" color="warn"
                                *ngIf="userInvalid">
                                <mat-icon>cancel</mat-icon>
                            </button> -->
                        </div>
                        <!-- <button mat-icon-button matSuffix *ngIf="element.Email" (click)="element.Email=''">
                            <mat-icon class="iconAlign">clear</mat-icon>
                        </button> -->
                    </mat-form-field>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="Role">
            <th mat-header-cell *matHeaderCellDef class="pd-0 w-15"> Role </th>
            <!-- <td mat-cell *matCellDef="let element" class="pd-0 w-22"> 
                {{element.Capex_Request_Role}} </td> -->
                <!-- *ngIf="!element.editing && !element.newRow" -->
            <td mat-cell *matCellDef="let element">
                <div class="pd-0 w-15" *ngIf="!element.newRow">
                    {{element.Capex_Request_Role}} 
                </div>
                <div class="pd-0 w-15" *ngIf="element.editing && element.newRow">
                    <mat-form-field appearance="fill" class="custom-form-field">
                        <!-- <input matInput class="font-15" [(ngModel)]="element.Capex_Request_Role"  (ngModelChange)="editField(element, 'Capex_Request_Role')" [disabled]="element.Active">
                        <button mat-icon-button matSuffix *ngIf="element.Capex_Request_Role" (click)="element.Capex_Request_Role=''">
                            <mat-icon class="iconAlign">clear</mat-icon>
                        </button> -->
                        <mat-select [(value)]="element.Capex_Request_Role" (selectionChange)="editField(element, 'Capex_Request_Role')" [disabled]="!element.Active">
                            <mat-option *ngFor="let role of capexRole" [value]="role">{{role}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="Section">
            <th mat-header-cell *matHeaderCellDef class="pd-0 w-15"> Area of Responsibilities </th>
            <td mat-cell *matCellDef="let element" class="pd-0 w-15">
                <div *ngIf="!element.editing">
                    {{element.Section}} 
                </div>
                <div *ngIf="element.editing">
                    <mat-form-field appearance="fill" class="custom-form-field"> <!-- Increase width to 100% -->
                        <input matInput class="font-15" [(ngModel)]="element.Section"  (ngModelChange)="editField(element, 'Section')" [disabled]="!element.Active">
                        <button mat-icon-button matSuffix *ngIf="element.Section" (click)="element.Section=''">
                            <mat-icon class="iconAlign">clear</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="noData">
            <td mat-footer-cell *matFooterCellDef class="pd-0" colspan="5">
                <div style="display: flex;">
                    <button class="nav-link btn btn-primary m-1" (click)="addRow()">Add Row</button>
                    <button [disabled]="!submitEnable" class="nav-link btn btn-primary m-1" (click)="onSubmit()">Submit</button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr mat-footer-row *matFooterRowDef="['noData']; sticky: true"></tr>
  
      </table>
    </div>
  </div>

