import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-assessment-add-labels',
  templateUrl: './assessment-add-labels.component.html',
  styleUrls: ['./assessment-add-labels.component.scss']
})

export class AssessmentAddLabelsComponent implements OnInit {

  // Sending the updated array to parent
  @Output() childLabelFormReady: EventEmitter<string[]> = new EventEmitter<string[]>();

  // Get the all ready existing labels and values for multitext fields from parent array
  @Input('parentDatamultiFeild') parentDatamultiFeild = [];
  @Input('tableSource') tableSource = [];
  @Input('column') column = [];

  @ViewChild('table') table: any;
  UserName: string = 'Enter Label Name';

  userlist : any[] = [];
  tableData: string[]  = [];
  displayedColumns: string[] = [];
  columns!: string[];
  constructor() {  }

  ngOnInit(): void {
    console.log("parentDatamultiFeild",this.parentDatamultiFeild);
    this.userlist = this.parentDatamultiFeild ? this.parentDatamultiFeild : []
    this.tableData = this.tableSource

  this.displayedColumns= this.column;

  //this.columns = ['gas', 'peakFlow', 'delete'];

  console.log(this.tableData,"this.tableData")
  }
  
  addRow() {
    let addData: any[] = [];
      addData?.push({})
    this.userlist.push(...addData)
    this.table.renderRows();
    this.tableData = this.userlist 
    console.log(this.tableData);
  }

  getRowData(row: any,col:any) {
    console.log(row,col);
  }

  // delete Row method
  deleteRow(row: any) {
    const row_index = this.tableData.indexOf(row);
    if (row_index !== -1) {
      this.tableData.splice(row_index, 1);
    }
    this.table.renderRows();
  }

  textchange() {
    this.table.renderRows();
    console.log(this.tableData);
    this.childLabelFormReady.emit(this.tableData);
  }

}
