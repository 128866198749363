<mat-toolbar>
     <button mat-raised-button color="warning" (click)="close()">Close</button>
     <button mat-raised-button color="primary" (click)="downloadImage()">Download All</button>
</mat-toolbar>


<div id="{{asset['Asset_Number']}}" class="col-md-6 printasset shadow" *ngFor="let asset of saveAsset; let i = index">
     <div class="saveText">-HPASSET-</div>
     <div class="saveText"><u>{{asset['Asset_Description']}}</u></div>
     <div class="saveText">SN:{{asset['Serial_Number']}}</div>
     <div class="saveText">LOC:{{asset['Building']}}</div>
     <div class="saveText">LAB:{{asset['LabFab_Name']}}</div>
     <div class="saveText">OLD:{{asset['Original_Asset_Number']}}</div>
     <ngx-barcode6 [bc-value]="asset['Asset_Number']" [bc-display-value]="true" [bc-font-size]="40"
          [bc-width]="3"></ngx-barcode6>
</div>