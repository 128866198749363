import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-cap',
  templateUrl: './cap.component.html',
  styleUrls: ['./cap.component.scss']
})
export class CapComponent {
  constructor(private common: CommonService, private activatedRoute: ActivatedRoute){
    this.common.currentModule.next(this.activatedRoute.snapshot.data)
    this.common.bannerData.next({ title: 'CAP' })
  }
}