import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatRowDef, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, map, startWith } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import * as XLSX from 'xlsx';
import * as fs from 'file-saver';
import { MatSelectChange } from '@angular/material/select';

interface Column {
  header: string;
  expandColumns: string[];
  isExtendable?: boolean;
}

interface Element {
  [key: string]: any; // Using index signature for dynamic keys
}

@Component({
  selector: 'app-capex-search',
  templateUrl: './capex-search.component.html',
  styleUrls: ['./capex-search.component.scss']
})
export class CapexSearchComponent {
  isadmin!: boolean;
  isplanner!: boolean;
  requestTypeOptions: string[] = ['LTP (>1 year out)', 'POR(Current Fiscal Year)', 'Expense (CAP SH Review)'];
  sectionOptions = ['PWP Ops', 'PSI', 'CNPI', 'BPI', 'PFPP', 'MEMS'];
  // plannerOptions: string[] = ['krista.lively@hp.com', 'reshu.jain@hp.com', 'michael.moffit@hp.com'];
  plannerOptions: string[] = [];
  //ltpPorNumberList: string[] = ['1111', '1122', '1133', '1144', '1155', '1166', '1177', '1188', '1199', '1200'];
  ltpPorNumberList: string[] = [];
  searchRecords: FormGroup;
  searchResults: any[] = [];
  filteredLTPPORNumber!: Observable<string[]>;
  status: any[] = [];
  tableHeaderArray: any = [];
  capexSearchDetails: any;
  showGrid!: boolean;
  columnOrder: any[] = [];
  dataSource: any;
  plannerDataSource = [];
  searchClicked = false;
  filters: any;
  initiallyVisibleColumns: string[] = [];
  columnVisibility: { [key: string]: boolean } = {};
  columnMapping: { [key: string]: string[] } = {};
  headers: string[] = [];
  loadingExport: boolean = false;
  dataForWorksheet: any;
  ExrequestType!: string;
  depccmanager!: string;
  approvedBy: string = '';
  por_number: string = '';
  viewStatusOfDepreciation_Approval: any = [];
  ApprovalComment:string = '';
  ApprovalStatus:string = '';
  ApprovalDate:string = '';
  ApprovedByEmail:string = '';

  columnHeaders: Column[] = [
    {
      header: 'Action',
      expandColumns: [''],
      isExtendable: false
    },
    {
      header: 'LTP#',
      expandColumns: [],
      isExtendable: false
    },
    {
      header: 'LTP Status',
      expandColumns: [],
      isExtendable: false
    },
    {
      header: 'POR#',
      expandColumns: []
    },
    {
      header: 'POR Status',
      expandColumns: []
    },
    {
      header: 'CAP#',
      expandColumns: []
    },
    {
      header: 'CAP Status',
      expandColumns: []
    },
    // {
    //   header: 'AMR#',
    //   expandColumns: []
    // },
    {
      header: 'WBS#',
      expandColumns: []
    },
    {
      header: 'WBS Status',
      expandColumns: []
    },
    {
      header: 'WBS Details',
      expandColumns: ['Adjusted WBS Amount', 'Original WBS Amount', 'WBS Status Comments', 'Receiving Project Start Date', 'Receiving WBS Amount', 'WBS Disposition'],
      isExtendable: true
    },
    {
      header: 'Depreciation Start Date',
      expandColumns: []
    },
    {
      header: 'WBS Closure Comments',
      expandColumns: []
    },
    {
      header: 'Project Type',
      expandColumns: []
    },
    {
      header: 'Site',
      expandColumns: []
    },
    {
      header: 'Tool/Line Item Description',
      expandColumns: []
    },
    {
      header: 'Planner',
      expandColumns: []
    },
    {
      header: 'Request Details',
      expandColumns: ['Project Manager Email', 'Finance', 'External Fundings', 'Building', 'LabFab Install Location', 'Build Site (select if shipping to another site)', 'Request Type', 'Justification', 'Impact of Not Doing', 'Program Driver', 'Category', 'Rollup', 'Business Unit', 'Comment'],
      isExtendable: true
    },
    {
      header: 'Asset Setup Details',
      expandColumns: ['# of Assets', 'Asset Useful Life (yrs)', 'Asset Class', 'Asset Class Description', 'Active Date'],
      isExtendable: true
    },
    {
      header: 'Actual Setup Date',
      expandColumns: []
    },
    {
      header: 'Asset Setup Action',
      expandColumns: []
    },
    {
      header: 'Asset Setup Comment',
      expandColumns: []
    },
    {
      header: 'Financial - Commit',
      expandColumns: ['Commit Cost Center Manager', 'Commit Cost Center', 'Commit Organization - L2', 'Commit Organization - L3', 'Commit Organization - L4', 'Commit Section', 'Commit MRU', 'Commit Sub-MRU', 'Company Code', 'Region'],
      isExtendable: true
    },
    {
      header: 'Financial - Depreciation',
      expandColumns: ['Depreciation Cost Center Manager', 'Depreciation Cost Center', 'Depreciation Organization - L1', 'Depreciation Organization - L2', 'Depreciation Organization - L3', 'Depreciation Organization - L4', 'Depreciation Section', 'Depreciation MRU', 'Depreciation Sub-MRU'],
      isExtendable: true
    },
    {
      header: 'Project Cost Details',
      expandColumns: ['Total Cost', 'Item Cost', 'Estimated Quarterly Depreciation', 'Hookup - Capitalized Install Costs', 'NRE Total Cost', 'Engr NRE Cost', 'Engr NRE (FTE Days)', 'Engr NRE Cost Center', 'Tech NRE Cost', 'Tech NRE (FTE Days)', 'Tech NRE Cost Center'],
      isExtendable: true
    },
    { header: 'Activity By Quarter', 
      expandColumns: ['# of Assets', 'Asset Useful Life (yrs)', 'Commit Quarter', 'Adjusted Factory Acceptance Quarter', 'Adjusted Site Acceptance Quarter (RTE)', 'Adjusted Final Acceptance Quarter (RTO)', 'Factory Acceptance Quarter', 'Site Acceptance Quarter (RTE)', 'Final Acceptance Quarter (RTO)', 'Estimated Project End/Asset Setup Date'], 
      isExtendable: true 
    },
    {
      header: 'Key Project Dates (WBS & Asset Setup)', 
      expandColumns: ['Adjusted Estimated Project End/Asset Setup Date', 'Months Overdue (Estimated - Adjusted Project End)', 'Actual Commit Date/Project Start Date', 'Asset Setup Date', 'Depreciation Start Date', 'RTO Project Status', 'RTO Project Note', 'Reason for RTO Date Change', 'RTO Date Change Comment'], 
      isExtendable: true
    },
    {
      header: 'Item Cash Flow Terms', 
      expandColumns: ['Item Cash Flow Terms', 'Item Cash Flow Method', '% Item Cash Flow Commit Qtr', '% Item Cash Flow Factory Acceptance Qtr', '% Item Cash Flow Site Acceptance QTR (RTE)', '% Cash Flow Final Acceptance Qtr (RTO)', 'NRE Cash Flow Method', '% NRE Cash Flow Commit Qtr', '% NRE Cash Flow Factory Acceptance Qtr', '% NRE Cash Flow Site Acceptance QTR (RTE)', '% NRE Cash Flow Final Acceptance Qtr (RTO)', 'Hookup Cash Flow Method', '% Hookup Cash Flow Commit Qtr', '% Hookup Cash Flow Factory Acceptance Qtr', '% Hookup Cash Flow Site Acceptance QTR (RTE)', '% Hookup Cash Flow Final Acceptance Qtr (RTO)'], 
      isExtendable: true
    },
    {
      header: 'Total Spend from Reconciliation Report',
      expandColumns: []
    },
    {
      header: 'Estimated Total Spend (Total Cash Flow + NRE + Hookup)',
      expandColumns: []
    },
    { header: 'Cash Flow Summary', 
      expandColumns: ['Estimated Remaining Spend (WBS Amount - Estimated Total Spend)', 'Approved PO Commits', 'Total Amount of Invoiced POs', 'Total Amount of JVs', 'Total Cash Flow (PO +JV)', '# Months Static', 'Total NRE Billed', 'Remaining NRE', 'Engr NRE Billed', 'Tech NRE Billed', 'Hookup - Billed', 'Remaining Hookup'], 
      isExtendable: true
    },
    // Add other columns similarly
  ];

  tableColumns: string[] = []
  expandedColumns: { [key: string]: boolean } = {};
  columnNames!: string[];
  buildings: any;
  loading: boolean = false;
  allSites: any[] = [];

  // @ViewChild(MatCheckbox) headerCheckBox!: MatCheckbox;
  @ViewChild('approvalDialog') approvalDialog!: TemplateRef<any>;
  constructor(private common: CommonService, private fb: FormBuilder, private router: Router, private http: HttpClient, private cdr: ChangeDetectorRef, private dialog: MatDialog) {
    this.common.bannerData.next({ title: 'CAPEX', subtitle: 'Search LTP/POR Records' })
    this.searchRecords = this.fb.group({
      requestType: [''],
      status: [''],
      ltpPorNumber: [''],
      planner: [''],
      section: [''],
      requestingSite: [''],
      projectManager: [''],
      commitManager: [''],
      depreciationManager: [''],
      commitQuarter: [''],
      requestDate: [''],
      CommitOrganization: [''],
      wbsNumber: ['']
    })
  }

  ngOnInit() {
    this.isadmin = (this.common.getUserRole()) == 'Admin';
    this.isplanner = (this.common.getUserRole()) == 'Planner';
    this.showGrid = false;
    this.capexSearchDetails = null;
    this.expandedColumns = {};

    //dont need datakey for requestType and status
    this.filters = [
      { filterKey: 'requestType' },
      { filterKey: 'status' },
      { filterKey: 'ltpPorNumber', dataKey: 'LTP#' },
      { filterKey: 'planner', dataKey: 'Planner' },
      { filterKey: 'section', dataKey: 'Section' },
      { filterKey: 'requestingSite', dataKey: 'Site' },
      { filterKey: 'projectManager', dataKey: 'Project Manager Email' },
      { filterKey: 'commitManager', dataKey: 'Commit Cost Center Manager' },
      { filterKey: 'depreciationManager', dataKey: 'Depreciation Cost Center Manager' },
      { filterKey: 'commitQuarter' },
      { filterKey: 'requestDate' }
    ]


    this.tableColumns = ['select']; // Select All Checkbox Column
    this.columnHeaders.forEach(column => {
      this.expandedColumns[column.header] = false;
    });

    this.columnNames = this.columnHeaders.map(column => column.header);
    // end

    this.common.getAPI('amd-get-labsite').subscribe({
      next: (
        (data) => {
          this.buildings = data;
          // console.log(this.buildings);
          this.loading = false;
          for (let i = 0; i < this.buildings?.length; i++) {
            let tmp = this.buildings[i]['Location'];
            this.allSites.push(tmp);
          }
          this.allSites = this.allSites?.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          this.allSites.sort();
        }),
      error: (
        (error) => {
          console.error("Error occurred:", error);
          alert("Please connect with Asset Admin");
        })
    })

    this.common.getCapexAPI('capex-request-role').subscribe({
      next: (
        (data) => {
         // this.financeOptions = data['Finance'].filter((a: any) => a['Capex_Request_Role'] == 'Finance' && a['Active'] == true).map((a: any) => a['Capex_Request_Role_Name']);
          this.plannerOptions = data['Planner'].filter((a: any) => a['Capex_Request_Role'] == 'Planner' && a['Active'] == true).map((a: any) => a['Capex_Request_Role_Name']);
        }),
      error: (
        (err) => {
          console.error("Error occurred:", err);
        })
    });
  }

  selectAllNewToggle(event: any) {
    // Implement select all logic here
  }

  submitNewRequest() {
    this.router.navigate(['/capex/capexDetails'], { state: { action: 'Submit' } });
  }

  convertRecords() {

  }

  showLtpPorNumber() {
    this.filteredLTPPORNumber = this.searchRecords.controls['ltpPorNumber'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.ltpPorNumberList.filter(option => option.toLowerCase().includes(filterValue));
  }

  onRequestOptionSelected(selectedValue: string) {
    console.log("Option Selected", selectedValue);
    this.ExrequestType = selectedValue; 
    if (selectedValue == 'POR(Current Fiscal Year)') {
      this.status = ['In Queue', 'Active', 'Complete'];


    }
    else if (selectedValue == 'LTP (>1 year out)') {
      this.status = ['Converted', 'In Queue', 'Draft'];

    }

  }









  valueChange(formName: any) {
    console.log("Form Value", formName);
    // Clear other fields
    this.filters.forEach((otherFilter: any) => {
      if (otherFilter.filterKey != 'status' && otherFilter.filterKey != 'ltpPorNumber') {
        this.status = [];
        this.ltpPorNumberList = [];
      }
      if (otherFilter.filterKey != formName) {
        this.searchRecords.controls[otherFilter.filterKey].setValue('');
      }
    })
  }


  onSearch() {
    this.common.loader.next(true);
    // const trimmedValues = Object.fromEntries(
    //   Object.entries(this.searchRecords.value).map(([key, value]) => 
    //     [key, typeof value === 'string' ? value.trim() : value]
    //   )
    // );
    const trimmedValues = Object.fromEntries(
      Object.entries(this.searchRecords.value).map(([key, value]) => {
        if (key === 'requestDate' && value) {
          // Convert to Date and remove timestamp
          const dateOnly = new Date(this.searchRecords.value.requestDate).toLocaleDateString('en-CA');
          return [key, dateOnly]; 
        }
        if (key === 'ltpPorNumber' && typeof value === 'string' && value) {
          value = value.toUpperCase(); 
        }
        return [key, typeof value === 'string' ? value.trim() : value];
      })
    );
    this.common.postCapexAPI('search', trimmedValues).subscribe({
      next: (data) => {
        const output = data.message;
        this.loading = true;
        this.capexSearchDetails = null;
        this.showGrid = false;
        this.searchClicked = true;    
        if (Array.isArray(output) || (typeof output === 'object' && output !== null)) {
          this.dataSource = this.mapResponseToColumns(Array.isArray(output) ? output : [output]);
          this.searchResults = Array.isArray(output) ? output : [output];    
          // console.log(this.searchResults);
          this.common.loader.next(false);
        } else {
          console.error('Unexpected output format:', output);
        }
      },
      error: (err) => {
        console.error("Error occurred:", err);
      }
    });
    
  }


  // mapResponseToColumns(data: Element[]): Element[] {
  //   return data.map(item => ({

  //     'LTP#': isPOR ? '' : item['LTP'], // Adjust these keys as necessary
  //     'LTP Status': isPOR ? '':  item['LTP_Status'],
  //     'POR#': item['POR'],
  //     'CAP#': item['CAP'],
  //     'CAP Status': item['CAP_Status'],
  //     'POR Status': item['POR_Status'],
  //     'Site': item['Site'],
  //     'Building': item['Building'],
  //     'LabFab Install Location': item['LabFab_Install_Location'],
  //     'Company Code': item['Company_Code'],
  //     'Region': item['Region'],
  //     'Comments': item['Comments'],
  //     'Actual Commit Date': item['Actual_Commit_Date'],
  //     'Project Type': item['Project_Type'],
  //     'Tool Name/Description': item['Tool_Name_Description'],
  //     'Project Manager Email': item['Project_Manager_Email'],
  //     'Planner': item['Planner'],
  //     'Justification': item['Justification'],
  //     'Impact of Not Doing': item['Impact_of_Not_Doing'],
  //     'Program Driver': item['Program_Driver'],
  //     'Category': item['Category'],
  //     'Rollup': item['Rollup'],
  //     'Businesss Unit': item['Business_Unit'],
  //     'Build Site': item['Build_Site_or_shipping_to_another_site'],
  //     'Depreciation Cost Center Manager': item['Depreciation_Cost_Center_Manager'],
  //     'Depreciation Cost Center': item['Depreciation_Cost_Center'],
  //     'Depreciation Org - L2': item['Depreciation_Org_L2'],
  //     'Depreciation Org - L3': item['Depreciation_Org_L3'],
  //     'Depreciation Org - L4': item['Depreciation_Org_L4'],
  //     'Depreciation Section': item['Depreciation_Section'],
  //     'Depreciation MRU': item['Depreciation_MRU'],
  //     'Depreciation Sub-MRU': item['Depreciation_Sub_MRU'],
  //     '# of Assets': item['Number_of_Assets'],
  //     '23Q1': item['23Q1'],
  //     '23Q2': item['23Q2'],
  //     '23Q3': item['23Q3'],
  //     '23Q4': item['23Q4'],
  //     'Actual QTD (23Q1)': item['Actual_QTD_23Q1'],
  //     'Current_Qtr_NRE': item['Current_Qtr_NRE'],
  //     'Q1': item['Q1'],
  //     'Q2': item['Q2'],
  //     'Q3': item['Q3'],
  //     'Q4': item['Q4'],
  //     'NRE Charged': item['NRE_Charged'],
  //     'Remaining NRE': item['Remaining_NRE'],
  //     'Est Remaining Cash Flow': item['Est_Remaining_Cash_Flow'],
  //     'Item Cash Flow Method': item['Item_Cash_Flow_Method'],
  //     'Item Cash Flow PO Terms': item['Item_Cash_Flow_PO_Terms'],
  //     'NRE Cash Flow Method': item['NRE_Cash_Flow_Method'],
  //     'NRE Cash Flow Parameters': item['NRE_Cash_Flow_Parameters'],
  //     'Total Cost ($K)': item['Total_Cost_$K'],
  //     'NRE Total Cost ($K)': item['NRE_Total_Cost_$K'],
  //     'Additional Cost ($K)': item['Additional_Cost_$K'],
  //     'Item Cost ($K)': item['Item_Cost_$K'],
  //     'Hookup - Capitalized Install Costs ($K)': item['Hookup_Capitalized_Install_Costs_$K'],
  //     'Hookup - Expensed Install Costs ($K)': item['Hookup_Expensed_Install_Costs_$K'],
  //     'Eng NRE Cost Location': item['Eng_NRE_Cost_Location'],
  //     'Engr NRE (FTE Days)': item['Engr_NRE_FTE_Days'],
  //     'Eng NRE Cost ($K)': item['Eng NRE Cost ($K)'],
  //     'Tech NRE Cost Location': item['Tech_NRE_Cost_Location'],
  //     'Tech NRE (FTE Days)': item['Tech_NRE_FTE_Days'],
  //     'Tech NRE Cost ($K)': item['Tech_NRE_Cost_$K'],
  //     'Months Overdue': item['Months_Overdue'],
  //     'Est TTL Spend': item['Est_TTL_Spend'],
  //     'PO Commits': item['PO_Commits'],
  //     'PO Invoiced': item['PO_Invoiced'],
  //     'Total JV': item['Total_JV'],
  //     'Total Cash Flow': item['Total_Cash_Flow'],
  //     'Recon Total': item['Recon_Total'],
  //     'Down Payment (Commit) Qtr': item['Commit_Qtr'],
  //     'Delivery (RTE) Qtr': item['Delivery_RTE_Qtr'],
  //     'Acceptance (RTO) Qtr': item['Acceptance_RTO_Qtr'],
  //     'Final Payment Qtr': item['Final_Payment_Qtr'],
  //     'Asset Setup Due Date (2 qtrs from RTO)': item['Asset_Setup_Due_Date_2_qtrs_from_RTO'],
  //     'Current Asset Setup Due Date (adjusted from the Original)': item['Current_Asset_Setup_Due_Date_adjusted_from_the_Original'],
  //     'Actual Asset Setup Date': item['Actual_Asset_Setup_Date'],
  //     'Commit Cost Center': item['Commit_Cost_Center'],
  //     'Commit Org - L2': item['Commit_Org_L2'],
  //     'Commit Org - L3': item['Commit_Org_L3'],
  //     'Commit Org - L4': item['Commit_Org_L4'],
  //     'Commit Section': item['Commit_Section'],
  //     'Commit MRU': item['Commit_MRU'],
  //     'Commit Sub-MRU': item['Commit_Sub_MRU'],
  //     'Asset Useful Life (yrs)': item['Asset_Useful_Life_in_yrs'],
  //     'WBS#': item['WBS'],
  //     'WBS Status': item['WBS_Status'],
  //     'WBS Amount': item['WBS_Amount'],
  //     'Receiving WBS#': item['Receiving_WBS'],
  //     'Receiving WBS Status': item['Receiving_WBS_Status'],
  //     'Receiving WBS Amount': item['Receiving_WBS_Amount'],
  //     'Project Start Date (Date WBS Received)': item['Project_Start_Date_or_Date_WBS_Received'], 
  //   }));
  // }

  mapResponseToColumns(data: Element[]): Element[] {
    return data.map(item => {
        let isPOR = false;

        // Check if requesttype exists in item and matches the expected value
        if (item['Request_Type'] === 'POR(Current Fiscal Year)') {
            isPOR = true;
        }
        //let isCapActive = item['CAP_Status'] === 'Active' || item['CAP_Status'] === 'Draft';
        let isConditionMet = (item['CAP_Status'] === 'Active' || item['CAP_Status'] === 'Draft' || item['CAP_Status'] === 'Complete' || item['CAP_Status'] === 'Canceled') || (item['Project_Type'] === 'M&E' && item['POR_Status'] === 'In Queue');

        return {
            'LTP#': isPOR ? '' : item['LTP'], 
            'LTP Status': isPOR ? '' : item['LTP_Status'],
            'POR#': item['POR'],
            //'CAP#': item['CAP'],
            //'CAP#': isCapActive ? item['POR'] : '',
            'CAP#': isConditionMet ? item['POR'] : '',
            'CAP Status': item['CAP_Status'],
            'POR Status': item['POR_Status'],
            'Site': item['Site'],
            'Building': item['Building'],
            'LabFab Install Location': item['LabFab_Install_Location'],
            'Company Code': item['Company_Code'],
            'Region': item['Region'],
            //'Comments': item['Comments'],
            'Actual Commit Date': item['Actual_Commit_Date'],
            'Project Type': item['Project_Type'],
            //'Tool Name/Description': item['Tool_Name_Description'],
            'Project Manager Email': item['Project_Manager_Email'],
            'Planner': item['Planner'],
            'Justification': item['Justification'],
            'Impact of Not Doing': item['Impact_of_Not_Doing'],
            'Program Driver': item['Program_Driver'],
            'Category': item['Category'],
            'Rollup': item['Rollup'],
            //'Businesss Unit': item['Business_Unit'],
            //'Build Site': item['Build_Site_or_shipping_to_another_site'],
           // 'Depreciation Cost Center Manager': item['Depreciation_Cost_Center_Manager'],
            'Depreciation Cost Center': item['Depreciation_Cost_Center'],
           // 'Depreciation Org - L2': item['Depreciation_Org_L2'],
          //  'Depreciation Org - L3': item['Depreciation_Org_L3'],
          //  'Depreciation Org - L4': item['Depreciation_Org_L4'],
            'Depreciation Section': item['Depreciation_Section'],
            'Depreciation MRU': item['Depreciation_MRU'],
            'Depreciation Sub-MRU': item['Depreciation_Sub_MRU'],
            '# of Assets': item['Number_of_Assets'],
          //  '23Q1': item['23Q1'],
          //  '23Q2': item['23Q2'],
          //  '23Q3': item['23Q3'],
          //  '23Q4': item['23Q4'],
          //  'Actual QTD (23Q1)': item['Actual_QTD_23Q1'],
          //   'Current_Qtr_NRE': item['Current_Qtr_NRE'],
          //   'Q1': item['Q1'],
          //   'Q2': item['Q2'],
          //   'Q3': item['Q3'],
          //   'Q4': item['Q4'],
          //  'NRE Charged': item['NRE_Charged'],
           // 'Remaining NRE': item['Remaining_NRE'],
          //  'Est Remaining Cash Flow': item['Est_Remaining_Cash_Flow'],
            'Item Cash Flow Method': item['Item_Cash_Flow_Method'],
          //  'Item Cash Flow PO Terms': item['Item_Cash_Flow_PO_Terms'],
            'NRE Cash Flow Method': item['NRE_Cash_Flow_Method'],
          //  'NRE Cash Flow Parameters': item['NRE_Cash_Flow_Parameters'],
          //  'Total Cost ($K)': item['Total_Cost_$K'],
          //  'NRE Total Cost ($K)': item['NRE_Total_Cost_$K'],
          //  'Additional Cost ($K)': item['Additional_Cost_$K'],
          //  'Item Cost ($K)': item['Item_Cost_$K'],
          //  'Hookup - Capitalized Install Costs ($K)': item['Hookup_Capitalized_Install_Costs_$K'],
          //  'Hookup - Expensed Install Costs ($K)': item['Hookup_Expensed_Install_Costs_$K'],
          //  'Eng NRE Cost Location': item['Eng_NRE_Cost_Location'],
            'Engr NRE (FTE Days)': item['Engr_NRE_FTE_Days'],
          // 'Eng NRE Cost ($K)': item['Eng NRE Cost ($K)'],
          //  'Tech NRE Cost Location': item['Tech_NRE_Cost_Location'],
            'Tech NRE (FTE Days)': item['Tech_NRE_FTE_Days'],
          //  'Tech NRE Cost ($K)': item['Tech_NRE_Cost_$K'],
          //  'Months Overdue': item['Months_Overdue'],
          //  'Est TTL Spend': item['Est_TTL_Spend'],
          //   'PO Commits': item['PO_Commits'],
          //   'PO Invoiced': item['PO_Invoiced'],
          //   'Total JV': item['Total_JV'],
          //   'Total Cash Flow': item['Total_Cash_Flow'],
          //  'Recon Total': item['Recon_Total'],
          //  'Down Payment (Commit) Qtr': item['Commit_Qtr'],
          //  'Delivery (RTE) Qtr': item['Delivery_RTE_Qtr'],
          //  'Acceptance (RTO) Qtr': item['Acceptance_RTO_Qtr'],
          //  'Final Payment Qtr': item['Final_Payment_Qtr'],
          //  'Asset Setup Due Date (2 qtrs from RTO)': item['Asset_Setup_Due_Date_2_qtrs_from_RTO'],
          //  'Current Asset Setup Due Date (adjusted from the Original)': item['Current_Asset_Setup_Due_Date_adjusted_from_the_Original'],
          //  'Actual Asset Setup Date': item['Actual_Asset_Setup_Date'],
            'Commit Cost Center': item['Commit_Cost_Center'],
            // 'Commit Org - L2': item['Commit_Org_L2'],
            // 'Commit Org - L3': item['Commit_Org_L3'],
            // 'Commit Org - L4': item['Commit_Org_L4'],
            'Commit Section': item['Commit_Section'],
            'Commit MRU': item['Commit_MRU'],
            'Commit Sub-MRU': item['Commit_Sub_MRU'],
            'Asset Useful Life (yrs)': item['Asset_Useful_Life_in_yrs'],
            'WBS#': item['WBS'],
            'WBS Status': item['WBS_Status'],
            'Adjusted WBS Amount': item['Adjusted_WBS_Amount'],
            'Original WBS Amount': item['Current_Wbs_Amount'],
            'WBS Status Comments': item['WBS_Comments'],
            //'WBS Amount': item['WBS_Amount'],
            'Receiving WBS#': item['Receiving_WBS'],
            //'WBS Status Comments': item['Receiving_WBS_Status'],
            'WBS Disposition': item['WBS_Disposition'],
            'Receiving WBS Amount': item['Receiving_WBS'],
            'WBS Closure Comments': item['Closure_Comments'],
            'Receiving Project Start Date': item['Project_Start_Date_or_Date_WBS_Received'], 
            'Depreciation Start Date': item['Depreciation_Start_Date'],
            'Tool/Line Item Description': item['Tool_Name_Description'],
            'Finance': item['Finance'],
            'External Fundings': item['External_Funding'],
            'Build Site (select if shipping to another site)': item['Build_Site_or_shipping_to_another_site'],
            'Request Type': item['Request_Type'],
            'Business Unit': item['Business_Unit'],
            'Comment': item['Comments'],
            'Asset Class': item['Asset_Class'],
            'Asset Class Description': item['Asset_Class_Description'],
            'Active Date': item['Active_Date'],
            'Asset Setup Action': item['Asset_Setup_Action'],
            'Asset Setup Comment': item['Asset_Setup_Comment'],
            'Commit Cost Center Manager': item['Commit_Cost_Center_Manager'],
            'Commit Organization - L2': item['Commit_Org_L2'],
            'Commit Organization - L3': item['Commit_Org_L3'],
            'Commit Organization - L4': item['Commit_Org_L4'],
            'Depreciation Cost Center Manager': item['Depreciation_Cost_Center_Manager'],
            'Depreciation Organization - L1': item['Depreciation_Org_L1'],
            'Depreciation Organization - L2': item['Depreciation_Org_L2'],
            'Depreciation Organization - L3': item['Depreciation_Org_L3'],
            'Depreciation Organization - L4': item['Depreciation_Org_L4'],
            'Item Cost': item['Item_Cost_$K'],
            'Total Cost': item['Total_Cost_$K'],
            'Estimated Quarterly Depreciation': item['Estimated_Quarterly_Depreciation'],
            'Hookup - Capitalized Install Costs': item['Hookup_Capitalized_Install_Costs_$K'],
           // 'Hookup - Expensed Install Costs': item['Hookup_Expensed_Install_Costs_$K'],
            'NRE Total Cost': item['NRE_Total_Cost_$K'],
            'Engr NRE Cost': item['Engr_Nre_Cost'],
            'Tech NRE Cost': item['Tech_Nre_Cost'],
            'Tech NRE Cost Center': item['Tech_NRE_Cost_Location'],
            'Engr NRE Cost Center': item['Eng_NRE_Cost_Location'],
            'Commit Quarter': item['Commit_Qtr'],
            'Adjusted Factory Acceptance Quarter': item['Adjusted_Fctory_Acceptance_Qtr'],
            'Adjusted Site Acceptance Quarter (RTE)': item['Adjusted_Site_Acceptance_Qtr'],
            'Adjusted Final Acceptance Quarter (RTO)': item['Adjusted_Final_Acceptance_Qtr'],
            'Factory Acceptance Quarter': item['Acceptance_RTO_Qtr'],
            'Site Acceptance Quarter (RTE)': item['Delivery_RTE_Qtr'],
            'Final Acceptance Quarter (RTO)': item['Final_Payment_Qtr'],
            'Estimated Project End/Asset Setup Date': item['Estimated_Asset_Setup_Date'],

            'Adjusted Estimated Project End/Asset Setup Date': item['Adjusted_Estimated_Asset_Setup_Date'],
            'Months Overdue (Estimated - Adjusted Project End)': item['Months_Overdue'],
            'Actual Commit Date/Project Start Date': item['Actual_Commit_Date'],
            'Actual Setup Date': item['Actual_Asset_Setup_Date'],
            'RTO Project Status': item['RTO_Project_Status'],
            'RTO Project Note': item['RTO_Project_Note'],
            'Reason for RTO Date Change': item['RTO_Date_Change_Reason'],
            'RTO Date Change Comment': item['RTO_Date_Change_Comment'],
            '% Item Cash Flow Commit Qtr': item['Item_Cash_Flow_Commit_Qtr'],
            '% Item Cash Flow Factory Acceptance Qtr': item['Item_Cash_Flow_Factory_Acceptance_Qtr'],
            '% Item Cash Flow Site Acceptance QTR (RTE)': item['Item_Cash_Flow_Site_Acceptance_Qtr'],
            '% Cash Flow Final Acceptance Qtr (RTO)': item['Item_Cash_Flow_Final_Acceptance_Qtr'],
            '% NRE Cash Flow Commit Qtr': item['NRE_Cash_Flow_Commit_Qtr'],
            '% NRE Cash Flow Factory Acceptance Qtr': item['NRE_Cash_Flow_Factory_Acceptance_Qtr'],
            '% NRE Cash Flow Site Acceptance QTR (RTE)': item['NRE_Cash_Flow_Site_Acceptance_Qtr'],
            '% NRE Cash Flow Final Acceptance Qtr (RTO)': item['NRE_Cash_Flow_Final_Acceptance_Qtr'],
            'Hookup Cash Flow Method': item['Hookup_Cash_Flow_Method'],
            '% Hookup Cash Flow Commit Qtr': item['Hookup_Cash_Flow_Commit_Qtr'],
            '% Hookup Cash Flow Factory Acceptance Qtr': item['Hookup_Cash_Flow_Factory_Acceptance_Qtr'],
            '% Hookup Cash Flow Site Acceptance QTR (RTE)': item['Hookup_Cash_Flow_Site_Acceptance_Qtr'],
            '% Hookup Cash Flow Final Acceptance Qtr (RTO)': item['Hookup_Cash_Flow_Final_Acceptance_Qtr'],
            'Item Cash Flow Terms': item['Item_Cash_Flow_Method'],
            'Total Spend from Reconciliation Report': item['Total_Reconcillation'],
            'Estimated Total Spend (Total Cash Flow + NRE + Hookup)': item['Estimated_Total_Spend'],
            'Estimated Remaining Spend (WBS Amount - Estimated Total Spend)': item['Estimated_Remaining_Spend'], 
            'Approved PO Commits' : item['Approved_Po_Commits'],   
            'Total Amount of Invoiced POs' : item['Total_Invoiced_Po'],
            'Total Amount of JVs' : item['Total_Amount_Jvs'],         
            'Total Cash Flow (PO +JV)': item['Total_Cash_Flow'], 
            '# Months Static' : item['Months_Static'],           
            'Total NRE Billed' : item['Total_Nre_Billed'],
            'Remaining NRE': item['Remaining_Nre'],
            'Engr NRE Billed' : item['Engr_Nre_Billed'],
            'Tech NRE Billed': item['Tech_Nre_Billed'],
            'Hookup - Billed': item['Hookup_Biiled'],
            'Remaining Hookup' : item['Remaining_Hookup'],
            'Asset Setup Date': item['Estimated_Asset_Setup_Date'],
        };
    });
}



  toggleColumn1(column: string) {
    this.expandedColumns[column] = !this.expandedColumns[column];
  }

  toggleColumn(column: string): void {
    // this.columnVisibility[column] = !this.columnVisibility[column];
    switch (column) {
      case 'LTP#':
        this.columnVisibility['LTP Status'] = !this.columnVisibility['LTP Status'];
        break;

      case 'POR#':
        this.columnVisibility['POR Status'] = !this.columnVisibility['POR Status'];
        break;

      case 'WBS#':
        this.columnVisibility['WBS Status'] = !this.columnVisibility['WBS Status'];
        this.columnVisibility['WBS Amount'] = !this.columnVisibility['WBS Amount'];
        this.columnVisibility['Project Start Date'] = !this.columnVisibility['Project Start Date'];
        break;

      default:
        break;
    }
  }


  collapse() {
    //this.displayedColumns = [...this.initiallyVisibleColumns]
    for (let key in this.expandedColumns) {
      this.expandedColumns[key] = false;
    }
  }

  recordHistory(row: any) {
    let ltp = row['LTP#'];

    if (!ltp) {
      ltp = row['POR#'].replace('POR','LTP')
    }
    // const reqNum = row['LTP#'];
    const ltpStatus = row['LTP Status'];
    //this.router.navigate(['/capex/capexDetails'], { state: { ltp, ltpStatus} });

    // this.router.navigate(['/capex/capexDetails'], { queryParams: { reqNum } });

    this.router.navigate(['/capex/capexDetails'], { 
        queryParams: { reqNum: ltp }
        // state: { ltp, ltpStatus }
      });
  }

  convertRecordsLTPtoPROR(row: any) {
  const ltpNumber = row['LTP#'];

  // Find the specific record from the search results
  const ltpRecord = this.searchResults?.find(item => item['LTP'] === ltpNumber);
  console.log(ltpRecord);

  // Configure the dialog
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = false;
  dialogConfig.width = '100%';
  dialogConfig.data = {
    title: `Confirm Conversion for LTP# ${ltpNumber}`,
    message: `Are you sure you want to convert LTP# ${ltpNumber} to POR?`
  };

  // Open the dialog
  const dialogRef = this.dialog.open(DialogBoxComponent, dialogConfig);

  // Handle the dialog result
  dialogRef.afterClosed().subscribe((result) => {
    if (result === 'Ok') {
      // User confirmed the action
      this.common.loader.next(true);

      this.common.getCapexAPI(`ltp-por/convert-ltp-to-por?ltp=${ltpNumber}`).subscribe({
        next: (data) => {
          console.log('Conversion successful:', data);
          const porNumber = data.message;
          this.common.show('success', `LTP# ${ltpNumber} converted successfully!`);
          this.router.navigate(['/capex/capexSearch']);
          this.common.loader.next(false);

          const estimatedProjectEndQtr = ltpRecord.Estimated_Project_End_Qtr ? this.common.getQuarterFromDate(ltpRecord.Estimated_Project_End_Qtr) : '';

          // Call the notification API to send email to AMD Admin
          const notificationBody = {
            notificationType: 'POR Generated',
            ltp: ltpNumber,
            por: porNumber,
            planner: ltpRecord.Planner,
            projectManager: ltpRecord.Project_Manager_Email,
            toolDescription: ltpRecord.Tool_Name_Description,
            commitQuarter: ltpRecord.Commit_Qtr,
            // building: ltpRecord.Building,
            // labFabInstallLocation: ltpRecord.LabFab_Install_Location,
             commitManager: ltpRecord.Commit_Cost_Center_Manager,
             estimatedProjectEnd: estimatedProjectEndQtr,
             depreciationManager: ltpRecord.Depreciation_Cost_Center_Manager,
            // depreciationStartDate: ltpRecord.Depreciation_Start_Date,
            estimatedDepreciationCost: ltpRecord.Estimated_Quarterly_Depreciation,
            assets: ltpRecord.Number_of_Assets,
            assetLife: ltpRecord.Asset_Useful_Life_in_yrs,
            totalCost: ltpRecord.Total_Cost_$K,
            // depreciationSubMRU: ltpRecord.Depreciation_Sub_MRU,
            // commitSubMRU: ltpRecord.Commit_Sub_MRU
          };
          console.log(notificationBody);
          this.common.postCapexAPI('capex-notify', notificationBody).subscribe({
            next: (data) => {
              this.common.show('success', 'Email notification sent successfully.');
            },
            error: (err) => {
              console.error("Error sending email notification:", err);
              this.common.show('error', 'Failed to send email notification.');
            }
          });

          this.onSearch();
        },
        error: (err) => {
          this.common.loader.next(false);
          this.common.show('error', `Error occurred while converting LTP# ${ltpNumber}`);
          console.error("Error occurred:", err.message);
        }
      });
    } else {
      // User canceled the action
      console.log('Conversion canceled. No changes made.');
      this.common.show('info', `No changes made to LTP# ${ltpNumber}`);
    }
  });
}

  secondAction(row: any) {
    // Implement your second action here...
  }

  trackApproval(row:any){
    this.por_number = row['POR#'];
    const record = this.searchResults?.find(item => item['POR'] == this.por_number);
    if (record.Depreciation_Manager_Approval) {
    this.ApprovalComment = record.Depreciation_Manager_Approval.Depreciation_Manager_Approval_Comment;
    this.ApprovalDate = record?.Depreciation_Manager_Approval?.Depreciation_Manager_Approval_Date?.split('T')[0] ?? null;
    this.ApprovalStatus = record.Depreciation_Manager_Approval.Depreciation_Manager_Approval_Status;
    this.ApprovedByEmail = record.Depreciation_Manager_Approval.Depreciation_Manager_Email;
    }
    //this.approvedBy = atob(this.common.currentUser);
    this.depccmanager = row['Depreciation Cost Center Manager'] ? row['Depreciation Cost Center Manager'] + '@hp.com' : '';
    this.dialog.open(this.approvalDialog, { width: '800px' });
    this.viewStatusOfDepreciation_Approval?.push({"Name": `email_notifications/Depreciation_Approval_${this.por_number}.html`,
    "Path": 'capex'})
  }
  

  closeDialog() {
    this.dialog.closeAll(); 
    this.ApprovalComment = '';
    this.ApprovalDate = '';
    this.ApprovalStatus = '';
    this.ApprovedByEmail = ''; 
    this.depccmanager = ''; 
    this.viewStatusOfDepreciation_Approval = [];
  }

  export(): void {
    console.log('exported data ');
   if (!this.searchResults || this.searchResults.length === 0) {
     alert('No data available to export');
     return;
   }
  //  const dataForExport = this.mapResponseToColumns(this.searchResults);
      let dataForExport = this.mapResponseToColumns(this.searchResults).map(item => ({
        ...item,
        'External Fundings': Array.isArray(item['External Fundings']) 
            ? item['External Fundings'].join(', ') 
            : item['External Fundings'],
        // 'Depreciation Cost Center Manager': item['Depreciation Cost Center Manager']?.includes('@hp.com')
        //     ? item['Depreciation Cost Center Manager']
        //     : item['Depreciation Cost Center Manager'] + '@hp.com',
        'Depreciation Cost Center Manager': item['Depreciation Cost Center Manager']
        ? (item['Depreciation Cost Center Manager'].includes('@hp.com')
            ? item['Depreciation Cost Center Manager']
            : item['Depreciation Cost Center Manager'] + '@hp.com')
        : '',
        'Commit Cost Center Manager': item['Commit Cost Center Manager']
        ? (item['Commit Cost Center Manager'].includes('@hp.com')
            ? item['Commit Cost Center Manager']
            : item['Commit Cost Center Manager'] + '@hp.com')
        : '',
    })); 
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataForExport);

   const workbook: XLSX.WorkBook = {
     Sheets: { 'CAPEX Search Results': worksheet },
     SheetNames: ['CAPEX Search Results']
   };

   XLSX.writeFile(workbook, 'CapexSearchResults.xlsx');
 }

}
