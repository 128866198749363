<app-common-cap-info [FocusAreaName]="FAOriginalName" (sendChildcapInfoValue)="valueFromChildCapInfo($event)"></app-common-cap-info>

<span class="text-center">
    <h3 class="card-body white-text orange-gradient text-white text-center">
        <strong><u>Stakeholder Review</u></strong>
    </h3>
</span>

<p style="padding-bottom: 2%; margin: 0">
    <a mat-button (click)="openAssessmentdialogBox()">View Completed Assessment Questions</a>
</p>

<div class="container paddingbottom-cust">
    <form [formGroup]="myForm">
        <!-- <div class="spinLoader" *ngIf="loading">
            <mat-spinner diameter="20"></mat-spinner>&nbsp;<span>Fetching Data...</span>
        </div>
        <mat-spinner *ngIf="submitLoading"></mat-spinner> -->

        <div class="container mT-10 rowStyle" *ngFor="let name of Array1;">
            <div class="row" style="margin: 0px">
                <div *ngIf="name.heading" class="col-12 card-body white-text orange-gradient text-white">
                    <mat-label><b>{{ name.heading }}</b></mat-label>
                </div>

                <div *ngIf="!name.heading && !name.options" class="textBorder col-8" style="margin-right: 5px;">
                    <mat-checkbox id="dateInitiated{{name.questionId}}" 
                        [checked]="name.isCheckBoxClicked" 
                        [disabled]="name.disable && name.isActionPlanCheckBoxDone" 
                        (change)="onChecked($event, name)">
                        {{ name.isNewQue ? '' : name.question }}
                    </mat-checkbox>
                    <mat-form-field *ngIf="name.isNewQue" appearance="fill">
                        <mat-label>{{ name.question }}</mat-label>
                        <input matInput [value]="name.question" (input)="enterText($event.target, name)">
                    </mat-form-field>
                    <span *ngIf="!name.isNewQue" [innerHTML]="name.question"></span>
                </div>

                <div *ngIf="!name.heading && !name.options" class="textBorder" style="width: 32.8%; padding: 0px">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Option</mat-label>
                        <mat-select [value]="name.answer" 
                            [disabled]="name.disable && name.isActionPlanCheckBoxDone" 
                            (selectionChange)="onSelected($event.value, name)">
                            <mat-option value="Phase_Selection(?)">Phase_Selection(?)</mat-option>
                            <mat-option value="Assessment">Assessment</mat-option>
                            <mat-option value="Commit">Commit</mat-option>
                            <mat-option value="RTE">RTE</mat-option>
                            <mat-option value="RTO">RTO</mat-option>
                            <mat-option value="Setup">Setup</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf="name.options == 'MCQ'" class="col-12" style="padding: 0px;">
                    <div class="textBorder col-12" style="margin-right: 5px;">
                        <label class="labelText" [innerHTML]="name.question"></label>
                    </div>
                    <div class="container mT-10" style="padding: 0px !important; margin: 0;">
                        <div fxLayout="row" style="width: 100%;">
                            <div class="textBorder" style="width: 100%;">
                                <mat-checkbox *ngFor="let option of options" style="margin-right: 2%" 
                                    [formControlName]="option.label"
                                    [checked]="option.selected" 
                                    (change)="onCheckboxChange(option, name, options)">
                                    {{ option.label }}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="name.options == 'textBox'" class="col-12" style="padding: 0px;">
                    <div class="textBorder col-12" style="margin-right: 5px;">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ name.question }}</mat-label>
                            <input matInput [value]="name.answer" (input)="enterText($event.target, name)">
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="name.options == 'TextFree'" class="textBorder col-12 mT-10">
                    <mat-form-field appearance="fill">
                        <mat-label>Enter text here...</mat-label>
                        <textarea matInput style="width: 60%;" 
                            [value]="name.answer" 
                            (input)="enterText($event.target, name)">
                        </textarea>
                    </mat-form-field>
                </div>

                <div *ngIf="name.options == 'option'" class="textBorder col-8" style="margin-right: 5px;">
                    <mat-checkbox id="dateInitiated4" [checked]="name.isCheckBoxClicked" (change)="onChecked($event, name)" style="display: inline;" required>
                        {{ name.question }}<span style="color: #FF0000;" aria-required="true">*</span>
                    </mat-checkbox>
                </div>
                
                <div *ngIf="name.options == 'option'" class="textBorder" style="width: 32.8%; padding: 0px">
                    <mat-checkbox class='mL-2' name="Yes" 
                        [checked]="name.checkedVal == 'Yes' ? name.answer : false" 
                        [disabled]="disableButton">
                        Yes
                    </mat-checkbox>
                    <mat-checkbox class='mL-2' name="No" 
                        [checked]="name.checkedVal == 'No' ? name.answer : false" 
                        [disabled]="disableButton">
                        No
                    </mat-checkbox>
                    <mat-checkbox class='mL-2' name="Waive" 
                        [checked]="name.checkedVal == 'Waive' ? name.answer : false">
                        Waive
                    </mat-checkbox>
                </div>

                <div *ngIf="(name.options =='WbsComment' && (name.answer.length > 0 || hideActionPlan == 'true'))" class="col-12" style="padding: 0px;">
                    <div class="textBorder col-12" style="margin-right: 5px;">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ name.question }}</mat-label>
                            <input matInput [value]="name.answer" (input)="enterText($event.target, name)" required>
                        </mat-form-field>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-12" style="margin-top: 1%;" *ngIf="Array1?.length; else noStakeHolderForm">
            <div class="form-group appDetailSearch">
                <button mat-raised-button color="primary" style="margin-left: 1%" (click)="saveDraft(hideActionPlan)">Save Draft</button>
                <button mat-raised-button color="primary" *ngIf="hideActionPlan == 'false'" style="margin-left: 1%" (click)="addToActionPlan()">Add to Action Plan</button>
            </div>
        </div>

        <ng-template #noStakeHolderForm>
            <div *ngIf="!loading" class="textBorder col-12 headingColor">
                <label class="labelText"><b>No Stakeholder Review Available</b></label>
            </div>
        </ng-template>
    </form>
</div>
