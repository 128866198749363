import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { assetDetails } from 'src/app/models/assetDetails';
// import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
import { CommonService } from 'src/app/services/common.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
@Component({
  selector: 'app-asset-request-change',
  templateUrl: './asset-request-change.component.html',
  styleUrls: ['./asset-request-change.component.scss']
})
export class AssetRequestChangeComponent {
  assetid!: string;
  UserName: any;
  submitted = false;
  userVerifyLoading = false
  userVerify = false;
  userInvalid = false;
  editable = true;
  isReadOnly = true;
  loaded: any
  textvalue!: String;
  assetnumber!: String;
  dataCount: any
  assetClassCode: any
  assetDetails = new assetDetails();
  actionPlanClicked = false;
  loading = false;
  submitLoading = false;
  formControlDate = new FormControl();
  formControlDate1 = new FormControl();
  formControlDate2 = new FormControl();
  formControlDate3 = new FormControl();
  formControlTDate = new FormControl();
  emailPattern: string = '^[a-z0-9._%+-]+@[h.-]+[p.-]\.[com]{3,4}$';
  // userRole = atob(this.common.userRole);
  userRole = (this.common.userRole);
  labFabData: any = [];
  assetLocationData: any = [];
  buildins: any = [];
  ownerData: any = [];
  getBuildingResult: any = [];
  getCostCenterResult: any = [];
  costcenterData: any = [];
  costcenterOwnerData: any = [];
  costcenterList: any = [];
  nonStandardLabfad = true;
  StandardLabfad = false;
  isCostCenterChanged = false
  getassedtdetails: any = [];
  @ViewChild('inputAutoComplete', { static: false }) inputAutoComplete: any;
  @ViewChild('inputAutoCompleteCCCode', { static: false }) inputAutoCompleteCCCode: any;
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');
  options: any[] = [];
  costcenterOptions: any[] = [];
  filteredOptions!: Observable<any[]>;
  costCenter_filteredOptions!: Observable<any[]>;
  RequestLdapValidation = {
    validateEmailDetails: "null",
    level: 0
  }
  reason_Back_to_Draft: string[] = [
    '$ are not available for the move at this time, work with cost owner to reset the action date',
    'The resources are not available to perform the move at this time, work with cost and tool owners to reset the action date',
    'Technical/safety issues with the move, work with Hookup, EHS, tool and cost owner to rectify and reset the action date'
  ];
  reason_Cancel: string[] = ['The organization has decided to not approve of the move. The asset will be moved to the pre-request state', 'Cancelled due to no activity (x months)']
  transfer_Request_Data = {}
  transfer_Request_Data_Ready: EventEmitter<any> = new EventEmitter();
  cstcntr: any;
  newcstcntr: any;
  assetdetails1: FormGroup = this.fb.group({
    Main_Asset_Number: [{value: '', disabled: true}, Validators.required],
    Asset_Description: ['', Validators.required],
    Additional_Description: [{value: '', disabled: true}, Validators.required],
    Serial_Number: ['', Validators.required],
    Asset_Supplementary_Inventory_Specifications_Name: ['', Validators.required],
    // Asset_Location: ['', Validators.required],
    Asset_Location: new FormControl({ value: '', disabled: true }, Validators.required),
    basic_change_Status: [''],
    Change_Status: [{value: '', disabled: true}],
    Change_Type: [''],
    Old_Building: [''],
    Old_LabFab_Name: [''],
    Old_Owner: [''],
    Old_Serial_Number: [''],
    Old_Asset_Description: [''],
    Old_Cost_Center_Manager: [''],
    Old_Cost_Center_Code: [''],
    Old_Company_Code: [''],
    New_LabFab_Name: [''],
    New_Building: [''],
    New_Owner: [''],
    New_Serial_Number: [''],
    New_Asset_Description: [''],
    New_Cost_Manager: [''],
    New_Cost_Center_Code: ['NULL'],
    Requester_ID: [atob(this.common.currentUser)],
    INSERT_DATETIME: [''],
    MODIFIED_DATETIME: [''],
    MODIFIED_Username: [''],
    Text: [''],
    Last_Modified_User: [''],
    reason_Back_To_Draft_Status: [''],
    action_plan_reason_Cancel_Status: [''],
    Cost_Object_Manager_Email_ID: [''],
    Company_Code: [''],
    New_Company_Code: [''],
    Cost_Center_Code: [''],
    Region: [''],
    // New_Region: [''],
    // Old_Region: [''],
    // Transfer_Type: [''],
    New_Region: new FormControl({ value: '', disabled: true }),
    Old_Region: new FormControl({ value: '', disabled: true }),
    Transfer_Type: new FormControl({ value: '', disabled: true }),
    Admin_Comment: [''],
    requester_Comment: ['']
  });
  subscriptions: Subscription[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private common: CommonService,
    private fb: FormBuilder,
    private route: Router,
    private dialog: MatDialog
  ) {
    // Request Change For a Fixed Asset
    this.common.bannerData.next({ title: 'AMD', subtitle: 'Request Change For a Fixed Asset' });
  }
  ngOnInit() {
    //this.loading = true;
    this.common.loader.next(true);
    this.activatedRoute.queryParams.subscribe(params => {
      this.assetid = params['assetid'];
      this.getassedtdetailsbyid(this.assetid);
    });
    this.gettingBuildings();
    this.gettingCostCenterCode();
    this.filteredOptions = this.assetdetails1.controls["Asset_Supplementary_Inventory_Specifications_Name"].valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(), map((value) => (typeof value === 'string' ? value : value?.Lab_name)), map((name) =>
        name
          ? this.getBuildingResult.filter(
            (option: any) => {
              return option?.Lab_name.toLowerCase().includes(name.toLowerCase())
            }
          )
          : this.getBuildingResult.slice()
      )
    );
    this.costCenter_filteredOptions = this.assetdetails1.controls["New_Cost_Center_Code"].valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(), map((value) => (typeof value === 'string' ? value : value.LAB_ID)), map((name) =>
        name
          ? this.getCostCenterResult.filter(
            (option: any) => {
              return option['LAB_ID'].toLowerCase().includes(name.toLowerCase())
            }
          )
          : this.getCostCenterResult.slice()
      )
    );
  }
  getassedtdetailsbyid(assid: string) {
    this.subscriptions.push(
    this.common.getAPI('amd-get-assetbyid/' + assid).subscribe((data: any) => {
      this.getassedtdetails = data[0];
      //this.loading = false;
      this.common.loader.next(false);
      this.assetdetails1.patchValue(data[0]);
      console.log("assetdetail control:",this.assetdetails1.controls);
      console.log("assetdetail value:",this.assetdetails1.value);
      console.log("data:",data[0])
      if (data[0]['Main_Asset_Number'])
        this.assetdetails1.controls['Main_Asset_Number'].setValue(data[0]['Main_Asset_Number']);
      this.assetdetails1.controls['Old_Building'].setValue(data[0]['Asset_Location']);
      this.assetdetails1.controls['Old_LabFab_Name'].setValue(data[0]['Asset_Supplementary_Inventory_Specifications_Name']);
      this.assetdetails1.controls['Old_Owner'].setValue(data[0]['Additional_Description']);
      this.assetdetails1.controls['Old_Serial_Number'].setValue(data[0]['Serial_Number']);
      this.assetdetails1.controls['Old_Region']?.setValue(data[0]['Region']);
      this.assetdetails1.controls['Old_Cost_Center_Code'].setValue(data[0]['Cost_Center_Code']);
      this.assetdetails1.controls['Old_Cost_Center_Manager'].setValue(data[0]['Cost_Object_Manager_Email_ID']);
      this.assetdetails1.controls['Old_Company_Code'].setValue(data[0]['Company_Code']);
      this.assetdetails1.controls['Old_Asset_Description'].setValue(data[0]['Asset_Description']);
      if (data[0]['Change_Status'] == 'Approved' || data[0]['Change_Status'] == 'Draft' || data[0]['Change_Status'] == 'Pending Analyst Review') {
        this.assetdetails1.controls['basic_change_Status'].setValue(data[0]['Change_Status']);
        if (data[0]['Old_Region'] != '' && data[0]['Old_Region'] && data[0]['Old_Region'] != 'NULL') {
          this.assetdetails1.controls['Old_Region']?.setValue(data[0]['Old_Region']);
        }
        if (data[0]['Old_Company_Code'] != '' && data[0]['Old_Company_Code'] && data[0]['Old_Company_Code'] != 'NULL') {
          this.assetdetails1.controls['Old_Company_Code']?.setValue(data[0]['Old_Company_Code']);
        }
        if (data[0]['Old_Cost_Center_Manager'] != '' && data[0]['Old_Cost_Center_Manager'] != 'null' && data[0]['Old_Cost_Center_Manager'] != 'Null') {
          this.assetdetails1.controls['Old_Cost_Center_Manager'].setValue(data[0]['Old_Cost_Center_Manager']);
        }
        if (data[0]['Old_Cost_Center_Code'] != '' && data[0]['Old_Cost_Center_Code'] != 'null' && data[0]['Old_Cost_Center_Code']) {
          this.assetdetails1.controls['Cost_Center_Code'].setValue(data[0]['Old_Cost_Center_Code']);
        }
        if (data[0]['New_Serial_Number'] && data[0]['New_Serial_Number'] != 'NULL' && data[0]['New_Serial_Number']) {
          this.assetdetails1.controls['Serial_Number'].setValue(data[0]['New_Serial_Number'].trim());
        }
        if (data[0]['New_Asset_Description'] && data[0]['New_Asset_Description'] != "NULL" && data[0]['New_Asset_Description'] != "null") {
          this.assetdetails1.controls['Asset_Description'].setValue(data[0]['New_Asset_Description'].trim());
        }
        if (data[0]['New_LabFab'] && data[0]['New_LabFab'] != 'NULL' && data[0]['New_LabFab'] != "null") {
          this.assetdetails1.controls['Asset_Supplementary_Inventory_Specifications_Name'].setValue(data[0]['New_LabFab']);
          this.assetdetails1.controls?.['New_LabFab_Name'].setValue(data[0]['New_LabFab']);
        }
        if (data[0]['New_Owner'] && data[0]['New_Owner'] != 'NULL') {
          this.assetdetails1.controls['Additional_Description'].setValue(data[0]['New_Owner']);
        }
        if (data[0]['New_Building'] && data[0]['New_Building'] != 'NULL') {
          this.assetdetails1.controls['Asset_Location'].setValue(data[0]['New_Building']);
        }
      }
      if (data[0]['Old_Cost_Center_Code'] && data[0]['Old_Cost_Center_Code'] != 'NULL' && data[0]['Old_Cost_Center_Code']) {
        this.regionCode(data[0]['Old_Cost_Center_Code'])
      }
      else {
        this.regionCode(data[0]['Cost_Center_Code']);
      }
      if (this.userRole != "Admin") {
        this.editable = false;
        this.formControlDate.disable();
        this.formControlTDate.disable();
      }
      this.validUserLdap(this.assetdetails1.controls['Additional_Description'].value)
    })
  );
  }
  regionCode(code: any) {
    let region = code?.substr(0, 2)
    if (['ES', 'IL', 'DE'].includes(region)) {
      this.assetdetails1.controls['Old_Region'].setValue('EMEA')
    }
    if (['IN', 'SG', 'MY'].includes(region)) {
      this.assetdetails1.controls['Old_Region'].setValue('APJ')
    }
    if (['US', 'PR'].includes(region)) {
      this.assetdetails1.controls['Old_Region'].setValue('AMS')
    }
    this.assetdetails1.get('Old_Company_Code')?.setValue((code)?.substring(0, 4));
  }
  gettingBuildings() {
    this.subscriptions.push(
    this.common.getAPI('amd-get-building/').subscribe((data: any) => {
      this.getBuildingResult = data;
      this.getBuildingResult.sort((a: any, b: any) => (a.Lab_name > b.Lab_name) ? 1 : -1)
      if (data.length) {
        for (let i = 0; i < data.length; i++) {
          this.labFabData.push(data[i].Lab_name);
          this.assetLocationData.push(data[i].Asset_Location);
          this.ownerData.push(data[i].Owner);
        }
        this.buildins = [...new Set(this.assetLocationData)].sort();
        this.options = [...new Set(this.labFabData)].sort();
      }
    })
  );
  }
  gettingCostCenterCode() {
    this.loading = true
    this.subscriptions.push(
    this.common.getAPI('amd-get-cost-center-code/').subscribe((data: any) => {
      this.getCostCenterResult = data;
      this.getCostCenterResult.sort((a: any, b: any) => (a.LAB_ID > b.LAB_ID) ? 1 : -1)
      this.loading = false
      if (data?.length) {
        for (let i = 0; i < data?.length; i++) {
          this.costcenterData.push(data[i].LAB_ID);
          this.costcenterOwnerData.push(data[i]['Cost Center Owner'])
        }
        this.costcenterOptions = [...new Set(this.costcenterData)].sort();
      }
    })
  );
  }
  clearInput(evt: any): void {
    evt.stopPropagation();
    this.assetdetails1.controls["Asset_Supplementary_Inventory_Specifications_Name"].setValue('');
  }
  clearInputCCCode(evt: any): void {
    evt.stopPropagation();
    this.assetdetails1.controls["New_Cost_Center_Code"].setValue('');
  }
  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    if (trigger.panelOpen)
      trigger.closePanel();
    else trigger.openPanel();
  }
  openOrClosePanel_cOstcenter(evt: any, trigger: MatAutocompleteTrigger): void {
    if (trigger.panelOpen)
      trigger.closePanel();
    else trigger.openPanel();
  }
  displayFn(item: string): string {
    return item ? item : '';
  }
  request_Status_change() {
    if (this.assetdetails1.controls['basic_change_Status'].value == 'Back to Draft') {
      this.assetdetails1.controls['reason_Back_To_Draft_Status'].setValidators([Validators.required])
      this.assetdetails1.controls['reason_Back_To_Draft_Status'].updateValueAndValidity();
      this.assetdetails1.controls['action_plan_reason_Cancel_Status'].clearValidators();
      this.assetdetails1.controls['action_plan_reason_Cancel_Status'].updateValueAndValidity();
      this.assetdetails1.controls["action_plan_reason_Cancel_Status"].setValue('');
    }
    if (this.assetdetails1.controls['basic_change_Status'].value == 'Cancel') {
      this.assetdetails1.controls['action_plan_reason_Cancel_Status'].setValidators([Validators.required])
      this.assetdetails1.controls['action_plan_reason_Cancel_Status'].updateValueAndValidity();
      this.assetdetails1.controls['reason_Back_To_Draft_Status'].clearValidators();
      this.assetdetails1.controls['reason_Back_To_Draft_Status'].updateValueAndValidity();
      this.assetdetails1.controls["reason_Back_To_Draft_Status"].setValue('');
    }
    if (this.assetdetails1.controls['basic_change_Status'].value != 'Cancel') {
      this.assetdetails1.controls['action_plan_reason_Cancel_Status'].clearValidators();
      this.assetdetails1.controls['action_plan_reason_Cancel_Status'].updateValueAndValidity();
      this.assetdetails1.controls["action_plan_reason_Cancel_Status"].setValue('');
    }
    if (this.assetdetails1.controls['basic_change_Status'].value != 'Back to Draft') {
      this.assetdetails1.controls['reason_Back_To_Draft_Status'].clearValidators();
      this.assetdetails1.controls['reason_Back_To_Draft_Status'].updateValueAndValidity();
      this.assetdetails1.controls["reason_Back_To_Draft_Status"].setValue('');
    }
  }
  costCenterCodeChange(assetdetails1: FormGroup, event: any, type: any) {
    let inputCCCode = '';
    if (type == 'input') {
      inputCCCode = event.srcElement.value;
    }
    if (type == 'inputAutoCompleteCCCode') {
      inputCCCode = event.option.value;
    }
    this.getCostCenterResult.forEach((item: any) => {
      let region = item['LAB_ID'].substr(0, 2)
      if (inputCCCode == item['LAB_ID']) {
        if (['ES', 'IL', 'DE'].includes(region)) {
          item['Region'] = 'EMEA'
        }
        if (['IN', 'SG', 'MY'].includes(region)) {
          item['Region'] = 'APJ'
        }
        if (['US', 'PR'].includes(region)) {
          item['Region'] = 'AMS'
        }
        assetdetails1.controls["New_Company_Code"].setValue(item['Company Code']);
        assetdetails1.controls['New_Region'].setValue(item['Region']);
        assetdetails1.controls['New_Cost_Manager'].setValue(item['Cost Center Owner']);
        assetdetails1.controls['New_Cost_Center_Code'].setValue(inputCCCode);
      }
    });
    this.ChangeCostCenterCode(event)
  }
  labfabChange(assetdetails1: FormGroup, event: any, type: any) {
    this.userInvalid = false;
    this.userVerify = false;
    let inputLabfab = '';
    if (type == 'input') {
      inputLabfab = event.srcElement.value;
    }
    if (type == 'inputAutoComplete') {
      inputLabfab = event.option.value;
    }
    this.getBuildingResult.forEach((item: any) => {
      if (inputLabfab == item['Lab_name']) {
        assetdetails1.controls["Asset_Location"].setValue(item['Asset_Location']);
        assetdetails1.controls['Additional_Description'].setValue(item['Owner']);
        this.StandardLabfad = true;
        assetdetails1.controls['New_Building'].setValue(item['Asset_Location']);
        assetdetails1.controls['New_Owner'].setValue(item['Owner']);
        assetdetails1.controls['New_LabFab_Name'].setValue(inputLabfab);
      }
    });
    if (this.getassedtdetails['Asset_Supplementary_Inventory_Specifications_Name'] == inputLabfab)
      this.StandardLabfad = false;
    this.nonStandardLabfad = this.labFabData.includes(inputLabfab);
    if (!this.nonStandardLabfad && this.nonStandardLabfad != null) {
      assetdetails1.controls["Asset_Location"].setValue('');
      assetdetails1.controls['Additional_Description'].setValue('');
      let asset_sup = (assetdetails1.controls['Asset_Supplementary_Inventory_Specifications_Name'].value).includes('NS::');
      assetdetails1.controls['Asset_Supplementary_Inventory_Specifications_Name'].setValue((asset_sup ? '' : 'NS::') + inputLabfab);
    }
    this.isBasicChange();
    this.ChangeTransfer();
  }
  isBasicChange() {
    this.AllChanges();
  }
  ChangeTransfer() {
    if (this.assetdetails1.get('Transfer_Type')?.value != "") {
      if (this.assetdetails1.get('New_Cost_Center_Code')?.value == "NULL") {
        this.assetdetails1.get('New_Cost_Center_Code')?.setValue("");
      }
      if (this.assetdetails1.get('New_Building')?.value == "NULL") {
        this.assetdetails1.get('New_Building')?.setValue("");
      }
      if (this.assetdetails1.get('New_Cost_Manager')?.value == "NULL") {
        this.assetdetails1.get('New_Cost_Manager')?.setValue("");
      }
      if (this.assetdetails1.get('New_LabFab_Name')?.value == "NULL") {
        this.assetdetails1.get('New_LabFab_Name')?.setValue("");
      }
      if (this.assetdetails1.get('New_Owner')?.value == "NULL") {
        this.assetdetails1.get('New_Owner')?.setValue("");
      }
      this.assetdetails1.get('New_Cost_Center_Code')?.setValidators([Validators.required])
      this.assetdetails1.get('New_Cost_Center_Code')?.updateValueAndValidity()
      this.assetdetails1.get('New_Cost_Manager')?.setValidators([Validators.required])
      this.assetdetails1.get('New_Cost_Manager')?.updateValueAndValidity()
      this.assetdetails1.get('New_Region')?.setValidators([Validators.required])
      this.assetdetails1.get('New_Region')?.updateValueAndValidity()
      let DateObj = new Date();
      let date = ('0' + (DateObj.getMonth() + 1)).slice(-2) + '/' + ('0' + DateObj.getDate()).slice(-2) + '/' + DateObj.getFullYear();
      this.assetdetails1.get('Transfer_Date')?.setValue(date);
    }
    else {
      this.assetdetails1.get('New_Cost_Center_Code')?.clearValidators()
      this.assetdetails1.get('New_Cost_Center_Code')?.updateValueAndValidity()
      this.assetdetails1.get('New_Cost_Manager')?.clearValidators()
      this.assetdetails1.get('New_Cost_Manager')?.updateValueAndValidity()
      this.assetdetails1.get('New_Region')?.clearValidators()
      this.assetdetails1.get('New_Region')?.updateValueAndValidity()
      this.assetdetails1.get('Transfer_Date')?.setValue("")
    }
    this.AllChanges();
  }
  ChangeCostCenterCode(event: any) {
    this.ChangeTransfer();
    this.AllChanges()
    this.cstcntr = this.assetdetails1.get('Cost_Center_Code')?.value
    this.newcstcntr = this.assetdetails1.get('New_Cost_Center_Code')?.value
    if (this.newcstcntr != "" && this.newcstcntr != "NULL") {
      this.textvalue = "TR-FR-" + (this.cstcntr)?.substring(0, 4) + "-" + this.assetid + "-TO-" + (this.newcstcntr)?.substring(0, 4)
      this.assetdetails1.get('Text')?.setValue(this.textvalue);
      this.assetdetails1.get('New_Company_Code')?.setValue((this.newcstcntr)?.substring(0, 4));
    }
    else {
      this.assetdetails1.get('Text')?.setValue("");
      this.assetdetails1.get('New_Company_Code')?.setValue("");
    }
  }
  AllChanges() {
    let oldvalueAsset_Des = this.getassedtdetails['Asset_Description'];
    let newValueAsset_Des = this.assetdetails1.get('Asset_Description')?.value.trim();
    let oldvalueSerial_Number = this.getassedtdetails['Serial_Number']
    let newValueSerial_Number = this.assetdetails1.get('Serial_Number')?.value.trim();
    let isLabfabChanged = (!this.StandardLabfad && !this.StandardLabfad != null) && (this.nonStandardLabfad && this.nonStandardLabfad != null)
    if (oldvalueSerial_Number != newValueSerial_Number)
      this.assetdetails1.controls['New_Serial_Number'].setValue(this.assetdetails1.get('Serial_Number')?.value.trim());
    if ((oldvalueAsset_Des != newValueAsset_Des))
      this.assetdetails1.controls['New_Asset_Description'].setValue(this.assetdetails1.get('Asset_Description')?.value.trim());
    if ((this.assetdetails1.controls['Cost_Center_Code'].value != this.assetdetails1.controls['New_Cost_Center_Code'].value) && this.assetdetails1.controls['New_Cost_Center_Code'].value != '' && this.assetdetails1.controls['New_Cost_Center_Code'].value != 'NULL') {
      if (this.assetdetails1.controls?.['New_Cost_Center_Code']?.value.includes('US31')) {
        this.assetdetails1.get('Transfer_Type')?.setValue('Transfer')
      } else {
        this.assetdetails1.get('Transfer_Type')?.setValue('275 Retirement by Transfer Out')
      }
      this.assetdetails1.get('Change_Type')?.setValue('Transfer');
    }
    else if ((this.assetdetails1.controls['Cost_Center_Code'].value == this.assetdetails1.controls['New_Cost_Center_Code'].value) && (this.assetdetails1.controls['New_Cost_Center_Code'].value != '' && this.assetdetails1.controls['New_Cost_Center_Code'].value != 'NULL') || ((oldvalueSerial_Number != newValueSerial_Number || (oldvalueAsset_Des != newValueAsset_Des))
      || (oldvalueSerial_Number != newValueSerial_Number && (oldvalueAsset_Des != newValueAsset_Des)) || (this.getassedtdetails['New_LabFab'] != this.assetdetails1.controls?.['New_LabFab_Name']?.value && this.getassedtdetails['New_LabFab'] != '' && this.assetdetails1.controls?.['New_LabFab_Name']?.value && this.assetdetails1.controls?.['New_LabFab_Name']?.value != this.assetdetails1.controls?.['Old_LabFab_Name']?.value))) {
      this.assetdetails1.get('Transfer_Type')?.setValue('Basic Change');
      this.assetdetails1.get('Change_Type')?.setValue('Basic Change');
    } else {
      this.assetdetails1.get('Transfer_Type')?.setValue('');
      this.assetdetails1.get('Change_Type')?.setValue('');
    }
    if ((this.getassedtdetails['New_LabFab'] != this.assetdetails1.controls?.['New_LabFab_Name']?.value && this.getassedtdetails['New_LabFab'] != '' && this.assetdetails1.controls?.['New_LabFab_Name']?.value && this.assetdetails1.controls?.['New_LabFab_Name']?.value != this.assetdetails1.controls?.['Old_LabFab_Name']?.value) || (this.assetdetails1.controls['New_Cost_Center_Code'].value != this.getassedtdetails['New_Cost_Center_Code'] && (this.assetdetails1.controls['Cost_Center_Code'].value != this.assetdetails1.controls['New_Cost_Center_Code'].value) && this.assetdetails1.controls['New_Cost_Center_Code'].value != '' && this.assetdetails1.controls['New_Cost_Center_Code'].value != 'NULL')) {
      this.assetdetails1.get('basic_change_Status')?.setValue('Draft');
    }
    else if (this.getassedtdetails['Change_Status'] && this.getassedtdetails['Change_Status'] != '' && this.getassedtdetails['Change_Status'] != 'NULL' && this.getassedtdetails['Change_Status'] != 'null') {
      if (this.assetdetails1.controls['basic_change_Status'].touched) {
      }
      else {
        this.assetdetails1.get('basic_change_Status')?.setValue(this.getassedtdetails['Change_Status']);
      }
    }
    else if (((oldvalueSerial_Number != newValueSerial_Number || (oldvalueAsset_Des != newValueAsset_Des))
      || (oldvalueSerial_Number != newValueSerial_Number && (oldvalueAsset_Des != newValueAsset_Des)) && (this.getassedtdetails['New_LabFab'] == this.assetdetails1.controls?.['New_LabFab_Name']?.value) || (this.assetdetails1.controls['Cost_Center_Code'].value == this.assetdetails1.controls['New_Cost_Center_Code'].value))
    ) {
      if ((isLabfabChanged && !this.isCostCenterChanged) || (isLabfabChanged && (this.assetdetails1.controls['Cost_Center_Code'].value == this.assetdetails1.controls['New_Cost_Center_Code'].value) && this.assetdetails1.controls['New_Cost_Center_Code'].value != '' && this.assetdetails1.controls['New_Cost_Center_Code'].value != 'NULL')) {
        this.assetdetails1.get('basic_change_Status')?.setValue('Approved');
      }
    }
    else {
      this.assetdetails1.get('basic_change_Status')?.setValue('');
      this.assetdetails1.controls['New_Serial_Number'].setValue('');
      this.assetdetails1.controls['New_Asset_Description'].setValue('');
    }
  }
  edit(assetnumber: number) {
    console.log(assetnumber)
    if (!this.userVerify) {
      this.submitLoading = false
      return
    }
    this.actionPlanClicked = true;
    this.onSubmit('actionPlanBtn', assetnumber)
    sessionStorage['flag'] = true
  }
  addTransferChildForm(group: FormGroup) {
  }
  onSubmit(BtnClicked: any, ActionPlanAssetNo: any) {
    if (!this.userVerify) {
      this.submitLoading = false;
      return
    }
    console.log(this.submitted);

    if (this.assetdetails1.invalid) {
      window.scroll(0, 0);
    }
    else {
      if (BtnClicked == 'SubmitBtn') {
        this.submitLoading = true
      }
      if (this.StandardLabfad || !this.nonStandardLabfad) {
        this.assetdetails1.get('New_Building')?.setValue(this.assetdetails1.get('Asset_Location')?.value)
        this.assetdetails1.get('New_Owner')?.setValue(this.assetdetails1.get('Additional_Description')?.value)
        this.assetdetails1.get('New_LabFab_Name')?.setValue(this.assetdetails1.get('Asset_Supplementary_Inventory_Specifications_Name')?.value)
      }
      if (this.assetdetails1.get('basic_change_Status')?.value == 'Approved') {
        if (this.getassedtdetails['New_LabFab']) {
          let labfabName = this.getassedtdetails['New_LabFab'].includes('NS::') ? this.getassedtdetails['New_LabFab'].slice(4) : this.getassedtdetails['New_LabFab']
          this.assetdetails1.controls['New_LabFab_Name'].setValue(labfabName);
        }
        if (this.getassedtdetails['New_Owner'] && this.getassedtdetails['New_Owner'] != 'NULL' && this.getassedtdetails['New_Owner'] != 'null') {
          this.assetdetails1.controls['New_Owner'].setValue(this.getassedtdetails['New_Owner']);
        }
        else {
          this.assetdetails1.controls['Old_Owner'].setValue(this.assetdetails1.controls['Additional_Description'].value);
        }
        if (this.getassedtdetails['New_Building']) {
          this.assetdetails1.controls['New_Building'].setValue(this.getassedtdetails['New_Building']);
        }
      }
      //this.assetdetails1.controls['AssetNo'].setValue(this.getassedtdetails['Main_Asset_Number']);
      this.UserName = atob(this.common.currentUser);
      this.assetdetails1.get('MODIFIED_Username')?.setValue(this.UserName);
      //enabling Main_Asset_Number, New_region, Transfer_Type to fetch it value to make redirect work.
      this.assetdetails1.get('Main_Asset_Number')?.enable({emitEvent: false})
      this.assetdetails1.get('New_Region')?.enable({emitEvent: false})
      this.assetdetails1.get('Transfer_Type')?.enable({emitEvent: false})
      this.common.postAssetRequestDetails([this.assetdetails1.value], 'Basic / Transfer').subscribe((data: any) => {
        if (data.statusCode == 200 && data.body) {
          if ((this.assetdetails1.get('basic_change_Status')?.value == 'Approved')) {
            this.emailNoActionReqConfirmation()
          }
          if ((this.assetdetails1.get('basic_change_Status')?.value == 'Back to Draft') || (this.assetdetails1.get('basic_change_Status')?.value == 'Cancel')) {
            this.emailNotifyBack_To_Draft_Cancel()
          }
          this.openDilaogeMessage(data.body,ActionPlanAssetNo);
          this.actionPlanClicked = false;
          if (BtnClicked == 'SubmitBtn') {
            this.submitLoading = false;
          }
        }
        else {
          this.submitLoading = false
        }
      this.assetdetails1.get('Main_Asset_Number')?.disable({emitEvent: false})
      this.assetdetails1.get('New_Region')?.disable({emitEvent: false})
      this.assetdetails1.get('Transfer_Type')?.disable({emitEvent: false})
      });
    }
  }
  close() {
    this.route.navigate(['/asset']);
  }
  openDilaogeMessage(message: any, assetID: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      message: message,
      tabletemplate: false,
      title: "Request Change"
    }
    const dialogRefSubmit = this.dialog.open(DialogBoxComponent, dialogConfig);
    dialogRefSubmit.afterClosed().subscribe(result => {
      if (this.assetdetails1.get('basic_change_Status')?.value == 'Draft' && assetID) {
        this.route.navigate(['assetRequestChange/action-plan'], { queryParams: { assetid: assetID } });
      }
      if (this.assetdetails1.get('basic_change_Status')?.value == 'Approved' && assetID) {
        this.route.navigate(['/asset']);
      }
      if ((this.assetdetails1.get('basic_change_Status')?.value == 'Back to Draft' || this.assetdetails1.get('basic_change_Status')?.value == 'Cancel' || this.assetdetails1.get('basic_change_Status')?.value == 'Pending Analyst Review') && assetID) {
        window.location.reload()
      }
    });
  }
  validUserLdap(user: any) {
    this.userVerifyLoading = true;
    this.userVerify = false
    this.userInvalid = false;
    this.RequestLdapValidation['validateEmailDetails'] = user.includes('.') ? (user.includes('@hp.com') ? user : user + '@hp.com') : user
    if (this.RequestLdapValidation['validateEmailDetails'] != '') {
      this.common.postAPI('amd-ldap-email-validation', this.RequestLdapValidation).subscribe((data: any) => {
        this.userVerifyLoading = false;
        if (data?.statusCode == 200 && data?.body && data?.body?.length) {
          this.userVerify = true;
          let emailString = "";
          let Additional_DescriptionLdap = ''
          for (let x of data?.body) {
            if (x['email']) {
              emailString += x['email'] + ";"
            }
          }
          if (emailString.charAt(emailString.length - 1) == ';') {
            Additional_DescriptionLdap = emailString.slice(0, -1)
          }
          this.assetdetails1.get('Additional_Description')?.setValue(Additional_DescriptionLdap)
        }
        if (data?.errorMSg || data.body?.length == 0 || data.body?.userNotFound) {
          this.userInvalid = true;
        }
        if (data.body?.userNotFound?.length == 0) {
          this.userInvalid = false;
          this.userVerify = true;
        }
      });
    }
  }
  emailNoActionReqConfirmation() {
    this.common.postAPI('amd-email-no-action-req-confirmation', { 'Main_Asset_Number': this.assetid }).subscribe((data: any) => {
    })
  }
  emailNotifyBack_To_Draft_Cancel() {
    this.common.postAPI('amd-emails-back-to-draft-cancel', { 'Main_Asset_Number': this.assetid, 'basic_change_Status': this.assetdetails1.get('basic_change_Status')?.value }).subscribe((data: any) => {
    })
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
