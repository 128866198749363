import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

  bannerData: any;
  subscriptions: Subscription[] = [];
  constructor(private common: CommonService) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.common.bannerData.subscribe(
        {
          next: (
            (res) => {
              this.bannerData = res;
            }
          ),
          error: (
            error => {
              this.common.errorHandler(error);
            }
          )
        }
      )
    )
  }
  
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
