<div class="col-12 btnAlign">
    <div class="mR-1">
        <button mat-raised-button class="itg-gradient font-size" (click)="collapse()">Collapse All</button>
    </div>
    <div *ngIf = "this.showExportToExcel">
        <button mat-raised-button class="itg-gradient font-size" (click)="exportExcel()">Export</button>
    </div>
</div>

<div class="mat-elevation-z8 addScroll">
    <table mat-table [dataSource]="dataSource" class="custom-table">
        <ng-container *ngFor="let column of columnHeaders" [matColumnDef]="column.header">
            <ng-container *ngIf="column['header'] === 'headerCheckbox'">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox class="checkbox-bg-color"(change)="toggleSelectAll($event.checked)"></mat-checkbox>
                </th>           
            </ng-container>
            <ng-container *ngIf="column['header'] !== 'headerCheckbox'">
                <th mat-header-cell *matHeaderCellDef class="text-white">
                    <div *ngIf="!column.header.startsWith('hide')" class="header-container">
                        <span>{{ column.header }}</span>
                        <button mat-icon-button (click)="toggleColumn(column.header)">
                            <mat-icon class="icon-size">{{ expandedColumns[column.header] ? 'remove' : 'add' }}</mat-icon>
                        </button>
                    </div>
                    <div  *ngIf="column.header.startsWith('hide')" style="height: 50px;"></div>
                   
                    <div class="table-header">
                        <span class="expand-column">{{ column.expandColumns[0] }}</span>
                        <ng-container *ngIf="expandedColumns[column.header]">
                            <ng-container *ngFor="let expandColumn of column.expandColumns; let i = index">
                                <ng-container  *ngIf="i !== 0">
                                    <span class="expand-column">{{ expandColumn }}</span>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </th>
            </ng-container>
            <td mat-cell *matCellDef="let element" class="expand-row">
                <ng-container *ngIf="column['header'] === 'headerCheckbox'">
                    <mat-checkbox [(ngModel)]="element.selected" (change)="toggleRowSelection(element, $event.checked)"></mat-checkbox>
                    <button mat-icon-button [matMenuTriggerFor]="rowMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #rowMenu="matMenu">
                        <ng-template matMenuContent>
                            <div class="btn text-center text-danger w-100"><i class="fa fa-laptop" aria-hidden="true"></i>
                                {{element['LTP#']}}</div>
                            <div>
                                <button mat-menu-item class="btn bg-primary text-white" (click)="recordHistory(element)">History</button>
                                <button mat-menu-item class="btn bg-primary text-white" (click)="recordHistory(element)">Edit</button>
                                <button mat-menu-item class="btn bg-primary text-white" (click)="recordHistory(element)">View</button>
                            </div>
                        </ng-template>
                    </mat-menu>
                </ng-container>
                <ng-container *ngIf="column.expandColumns[0]">
                        <span class="expand-column adjustRowData">{{ element[column.expandColumns[0]] }}
                            <span class="tooltip">{{ element[column.expandColumns[0]] }}</span>
                        </span> 
                </ng-container>
                <ng-container *ngIf="expandedColumns[column.header]">
                    <ng-container *ngFor="let subColumn of column.expandColumns.slice(1)">
                        <span class="expand-column adjustRowData">{{ element[subColumn] }}</span>
                    </ng-container>
                </ng-container>        
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="columnNames" class="bg-primary headerAtTop"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
    </table>
    <div *ngIf="dataSource.length === 0" style="padding:10px;">No CAPEX records to display</div>
    </div>